<template>
  <div class="content_head">
    <el-breadcrumb class="app-breadcrumb" separator="/">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="index">
        <span v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect">{{ item.meta.title }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import Bus from '@/utils/Bus'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      title: '',
      levelList: null
    }
  },
  watch: {
    $route (route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith('/redirect/')) {
        return
      }
      this.getBreadcrumb()
    }
  },
  mounted () {
    this.get_titleInfo()
    Bus.$on('*_title', () => this.get_titleInfo())
  },
  methods: {
    ...mapActions(['getSystemInfo']),

    async get_titleInfo () {
      const { data } = await this.getSystemInfo()
      this.title = data.left_nav_name
      this.getBreadcrumb()
    },
    getBreadcrumb () {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      const first = matched[0]
      const route = [{ path: '/', meta: { title: this.title }}]

      if (!this.isDashboard(first)) {
        const pr = [{ redirect: 'noRedirect', meta: { title: matched[0].meta.parentTitle }}]
        matched = [...route].concat(...pr, ...matched)
      }

      this.levelList = matched.filter(item => item.meta && item.meta.title)
    },
    isDashboard (route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim() === 'Index'
    },
    handleLink (item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
.content_head {
  align-self: auto;
  box-sizing: border-box;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0px 24px;

  .el-breadcrumb {
    font-size: 12px;
    color: #666 !important;
  }
  .no-redirect{
    color: #cccccc !important;
  }
  .el-breadcrumb__inner a{
    color: #666;
    font-weight: inherit;
  }
}
</style>
