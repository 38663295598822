<template>
  <div class="header">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <span class="title_logo">
      <img
        :src="BASE_URL + systemImage.TopNavigationIcon"
        class="sc-su70ez-0 CB-cXQsxq">
      {{ title }}
    </span>

    <div class="sc-o3xipm-0 CB-gCcMxU">
      <el-dropdown style="margin-left: 10px">
        <span class="el-dropdown-link">
          <button
            loading="0"
            href=""
            class="
              sc-2fc5kz-0 sc-181i1az-2
              CB-kzdFfD CB-llUgoI
              sc-1dkoud0-0
              CB-bLgZVZ
            "
            data-spm-click="gostr=/aliyun;locaid=daccount"
          >
            <span style="margin-right:8px">{{ rolesName }}</span>
            <img
              src="../assets/img/WechatIMG135.jpeg"
              alt=""
              class="sc-su70ez-0 CB-cXQsxq"
            >
          </button>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Hamburger from '@/components/Hamburger'
import Bus from '@/utils/Bus'
import config from '@/config'

export default {
  components: {
    Hamburger
  },
  data () {
    return {
      title: '',
      drawer: false,
      BASE_URL: config.img_base_url
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'systemText',
      'systemImage'
    ]),
    userInfo () {
      return this.$store.state.userInfo
    },
    rolesName () {
      const isMain = this.$store.state.userInfo.user_type === 1
      const name = this.$config.IF_SHOW_MAIN ? this.userInfo.user_name : ((this.userInfo.user_name.split('@').length > 0 && this.userInfo.user_name.split('@')[0]) || '')

      return (isMain ? '主用户' : '子用户') + ' ' + name
    }
  },
  mounted () {
    this.get_titleInfo()
    Bus.$on('*_title', () => this.get_titleInfo())
  },
  methods: {
    ...mapActions(['getSystemInfo']),

    async get_titleInfo () {
      const { data } = await this.getSystemInfo()
      this.title = data.top_nav_name
    },
    toggleSideBar () {
      this.$store.dispatch('toggleSideBar')
    },
    // 切换主题
    themeChange () {
      this.drawer = true
    },
    // 退出登录
    async logout () {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.dispatch('LogOut', true).then(() => {
            location.href = '/view'
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  height: 100%;
  padding: 0 24px;
  padding-left: 0;
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    .hamburger {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
    }
  }
  .sc-o3xipm-0 {
    float: right;
  }
  .CB-gCcMxU {
    display: inline-block;
    position: relative;
  }
  .CB-bLgZVZ {
    position: relative;
    padding: 0px 10px;
    border: 0px;
    height: 50px;
    line-height: 50px;
    display: block !important;
  }
  .CB-llUgoI {
    display: inline-block;
    border-width: initial;
    border-style: none;
    border-image: initial;
    border-radius: 0px;
    width: auto;
    max-width: 100%;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--cb-color-button-text-brand-secondary-text, #666);
    background-color: var(
      --cb-color-button-text-brand-secondary-bg,
      transparent
    );
    border-color: var(
      --cb-color-button-text-brand-secondary-border,
      transparent
    );
  }
  .CB-kzdFfD {
    padding: 0px;
    border: 1px solid transparent;
    box-sizing: border-box;
    outline: none;
    background: none;
    line-height: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    text-align: center;
    text-decoration: none;
    color: inherit;
    transition: all 0.3s ease-out 0s;
  }
  .CB-bLgZVZ img {
    display: inline;
    max-width: 160px;
    max-height: 36px;
  }
  .CB-cXQsxq {
    display: inline-block;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    vertical-align: middle;
    background-color: var(--cb-color-bg-secondary, #f4f6f7);
  }

  .title_logo {
    line-height: 50px;
    font-size: 18px;
    img {
      border-style: none;
      margin-right: 10px;
    }
  }
}
</style>
