<template>
  <div class="home_page">
    <header
      id="home_header"
      class="header_bg"
      :style="{ 'background-color': '#fff' }"
    >
      <v-head />
    </header>
    <section id="home_section">
      <div id="home_nav" class="sidebar_bg">
        <v-sideBar class="sideBar" :class="{ opened: !sidebar.opened }" />
      </div>
      <div id="home_contain">
        <div class="tag-nav-wrapper">
          <Breadcrumb v-if="$route.meta.title" />
        </div>
        <div id="show">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import vHead from './header'
import vSideBar from './sideBar'
import Breadcrumb from './Breadcrumb'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    vHead,
    vSideBar,
    Breadcrumb
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      device: (state) => state.device,
      sidebar: (state) => state.sidebar
    })
  },
  watch: {},
  methods: {
    handleClickOutside () {
      this.$store.dispatch('closeSideBar', { withoutAnimation: false })
    }
  }
}
</script>
<style lang="less">
.home_page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  #home_header {
    height: 50px;
    font-size: 12px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: var(
      --cb-typo-font-family-base,
      -apple-system,
      BlinkMacSystemFont,
      "PingFang SC",
      "Helvetica Neue",
      Helvetica,
      Arial,
      sans-serif
    );
    background-color: var(--cb-color-bg-primary, #fff);
    z-index: 999;
    box-shadow: 0 2px 4px 0 var(--cb-color-shadow, rgba(0, 0, 0, 0.16));
    flex-shrink: 0;
  }
  #home_section {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    #home_contain {
      height: 100%;
      position: relative;
      // overflow: scroll;
      overflow-x: hidden;
      flex: 1;
      background: #fff;
      display: flex;
      flex-direction: column;
      .tag-nav-wrapper {
        height: 32px;
        overflow: hidden;
        border-radius: 5px;
        overflow: hidden;
      }

      #show {
        flex: 1;
        padding: 15px 24px;
        height: calc(100% - 80px);
        overflow: auto;
        background: #fff;
        margin-top: 0;
        border-radius: 5px;
      }
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
  .sideBar {
    min-width: 208px;
    transition:.1s;
  }
   .opened {
    width: 0;
    min-width: 0;
  }
}
</style>

