<template>
  <div class="sideBar" onselectstart="return false">
    <div class="sc-iBPTik fJrxuO">
      <div class="sc-dlfnuX iUAQYC windcc-app-layout__nav">
        <ul
          role="listbox"
          class="next-menu next-ver next-menu-selectable-single next-nav next-normal next-active next-right sc-1qpuga2-0 cboYpJ"
        >
          <li class="next-menu-header">
            <h2 id="container" class="sc-141mtuf-0 kGQtov">
              <div class="header-text">{{ title }}</div>
            </h2>
          </li>
          <ul class="next-menu-content">
            <template v-for="(item,index) in menuList">
              <li
                v-if="!item.children.length"
                :key="index"
                role="option"
                tabindex="0"
                :class="{'next-selected': ($route.path.indexOf(item.path))>-1}"
                class="next-menu-item next-nav-item sc-1kxwfk-0 gBEOnn"
              >
                <div class="next-menu-item-inner">
                  <span class="next-menu-item-text">
                    <a tabindex="-1" @click="href(item.path,index,item)">{{ item.name }}</a>
                  </span>
                </div>
              </li>
              <el-collapse v-else :key="index" v-model="collapse">
                <li
                  role="option"
                  class="next-menu-sub-menu-wrapper next-nav-sub-nav-item sc-1mvpd2x-0 qfHJS"
                  title="身份管理"
                >
                  <div
                    role="listitem"
                    aria-expanded="true"
                    tabindex="-1"
                    class="next-menu-item next-nav-item"
                  />
                  <el-collapse-item :title="item.name" :name="index">
                    <ul role="listbox" class="next-menu-sub-menu">
                      <li
                        v-for="(j,i) in item.children"
                        :key="i"
                        role="option"
                        :class="{'next-selected': $route.path.indexOf(j.path)>-1}"
                        tabindex="-1"
                        class="next-menu-item next-nav-item sc-1kxwfk-0 gBEOnn"
                        style="padding-left: 40px"
                      >
                        <div class="next-menu-item-inner">
                          <span class="next-menu-item-text">
                            <a tabindex="-1" @click="href(j.path,`${i}-${index}`,item)">{{ j.name }}</a>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </el-collapse-item>
                </li>
              </el-collapse>
              <template />
            </template>
          </ul>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Bus from '@/utils/Bus'

export default {
  data () {
    return {
      title: '',
      brandList: []
    }
  },
  computed: {
    menuList () {
      return this.$store.state.menuList
    },
    // 菜单默认展示
    collapse () {
      return [0, 1, 2]
    }
  },
  mounted () {
    this.get_titleInfo()
    Bus.$on('*_title', () => this.get_titleInfo())
  },
  methods: {
    ...mapActions(['getSystemInfo']),

    async get_titleInfo () {
      const { data } = await this.getSystemInfo()
      this.title = data.left_nav_name
    },
    href (path, index, to) {
      path && this.$router.push(path)
      this.activeIndex = index
    }
  }
}
</script>

 <style>

.iconReturn:before{
  width: 12px;
  font-size: 12px;
}

.fJrxuO {
    z-index: 1;
    overflow-x: visible;
    position: relative;
    height: 100%;
    transition: width 0.3s ease-in-out 0s, min-width 0.3s ease-in-out 0s;
}
.iUAQYC {
    position: relative;
    overflow: hidden auto;
    height: 100%;
    background-color: var(--console-layout-nav-bg,#f5f5f5);
    border-right: var(--console-layout-nav-border,none);
    left: 0px;
    transition: left 0.3s ease-in-out 0s;
}
.jWcBhp {
    z-index: 100;
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 0px;
    height: 100%;
}

.ezjZos {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    width: var(--console-layout-nav-trigger-clickable-width,24px);
    height: var(--console-layout-nav-trigger-clickable-height,100px);
    cursor: pointer;
}
.hNswiJ {
    width: 16px;
    height: 34px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    cursor: pointer;
    color: rgb(191, 191, 191);
    background-color: #fff;
    box-shadow:  0 2px 4px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-left: none;
    border-radius: 0px 2px 2px 0px;
    transition: border 0.1s ease 0s;
}
.hNswiJ .sc-hKgJUU {
    position: relative;
    left: var(--console-layout-nav-trigger-icon-left,-8px);
    line-height: 12px;
    color: var(--console-layout-nav-trigger-icon-color,#c1c1c1);
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out 0s, left 0.1s ease-in-out 0s;
}

.header-text{
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.el-collapse-item__arrow{
  font-size:14px;
}

.el-collapse-item__content{
  padding-bottom: 0px;
}

.el-collapse-item__wrap,.el-collapse-item__arrow {
  transition: .15s;
}

.el-collapse-item__header{
  padding:0 24px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  padding-right:10px;
}

.el-collapse{
  padding-left:0px;
  padding-right:0px;
  border-top: none;
  border-bottom: none;
}
.el-collapse-item__header{
    font-weight: inherit;
    border: none;
}

.next-nav.next-normal .next-nav-item.next-menu-item:before {
    background-color: #0064c8;
    background-color: var(--nav-normal-item-selected-active-color, #0064c8);
}
.next-menu-header{
    padding: 24px 24px 16px;
}

.next-menu,.sc-dlfnuX,.sc-iBPTik,.sideBar{
    height: 100%;
}

.kGQtov.kGQtov {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: var(--console-menu-header-color,#333333);
    margin: 0px;
    line-height: 18px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

 .next-badge {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.next-badge,
.next-badge *,
.next-badge :after,
.next-badge :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-badge .next-badge-count {
  color: #fff;
  background: #c80000;
  background: var(--badge-color-bg, #c80000);
  text-align: center;
  white-space: nowrap;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  -webkit-border-radius: var(--badge-size-count-border-radius, 16px);
  -moz-border-radius: var(--badge-size-count-border-radius, 16px);
  border-radius: var(--badge-size-count-border-radius, 16px);
  position: absolute;
  width: auto;
  height: 18px;
  height: var(--badge-size-count-config-height, 18px);
  min-width: 18px;
  min-width: var(--badge-size-count-config-height, 18px);
  padding: 0 4px;
  padding: 0 var(--badge-size-count-padding-lr, 4px) 0
    var(--badge-size-count-padding-lr, 4px);
  font-size: 12px;
  font-size: var(--badge-size-count-font, 12px);
  line-height: 18px;
  line-height: var(--badge-size-count-config-height, 18px);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -0.5em;
}

.next-badge .next-badge-count a,
.next-badge .next-badge-count a:hover {
  color: #fff;
}

.next-badge .next-badge-dot {
  color: #fff;
  background: #c80000;
  background: var(--badge-dot-color-bg, #c80000);
  text-align: center;
  white-space: nowrap;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-border-radius: var(--badge-size-dot-border-radius, 8px);
  -moz-border-radius: var(--badge-size-dot-border-radius, 8px);
  border-radius: var(--badge-size-dot-border-radius, 8px);
  position: absolute;
  width: 8px;
  width: var(--badge-size-dot-width, 8px);
  height: 8px;
  height: var(--badge-size-dot-width, 8px);
  min-width: 8px;
  min-width: var(--badge-size-dot-width, 8px);
  padding: 0;
  font-size: 1px;
  line-height: 1;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -0.5em;
}

.next-badge .next-badge-dot a,
.next-badge .next-badge-dot a:hover {
  color: #fff;
}

.next-badge .next-badge-custom {
  line-height: 1.166667;
  white-space: nowrap;
  font-size: 12px;
  font-size: var(--font-size-caption, 12px);
  padding-left: 4px;
  padding-left: var(--badge-size-custom-padding-lr, 4px);
  padding-right: 4px;
  padding-right: var(--badge-size-custom-padding-lr, 4px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--badge-size-custom-border-radius, 2px);
  -moz-border-radius: var(--badge-size-custom-border-radius, 2px);
  border-radius: var(--badge-size-custom-border-radius, 2px);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.next-badge .next-badge-custom > * {
  line-height: 1;
}

.next-badge .next-badge-custom > .next-icon:before,
.next-badge .next-badge-custom > i:before {
  font-size: inherit;
  width: auto;
  vertical-align: top;
}

.next-badge .next-badge-scroll-number {
  position: absolute;
  top: -4px;
  z-index: 10;
  overflow: hidden;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.next-badge-scroll-number-only {
  position: relative;
  display: inline-block;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  min-width: 8px;
  min-width: var(--badge-size-dot-width, 8px);
}

.next-badge-scroll-number-only span {
  display: block;
  height: 18px;
  height: var(--badge-size-count-config-height, 18px);
  line-height: 18px;
  line-height: var(--badge-size-count-config-height, 18px);
  font-size: 12px;
  font-size: var(--badge-size-count-font, 12px);
}

.next-badge-not-a-wrapper .next-badge-count,
.next-badge-not-a-wrapper .next-badge-custom,
.next-badge-not-a-wrapper .next-badge-dot {
  position: relative;
  display: block;
  top: auto;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.next-badge-list-wrapper {
  margin-left: 0;
}

.next-badge-list-wrapper li {
  margin-bottom: 0;
  margin-bottom: var(--badge-size-list-margin, 0);
  list-style: none;
}

.next-badge[dir="rtl"] .next-badge-custom {
  padding-right: 4px;
  padding-right: var(--badge-size-custom-padding-lr, 4px);
  padding-left: 4px;
  padding-left: var(--badge-size-custom-padding-lr, 4px);
}

.next-badge[dir="rtl"] .next-badge-scroll-number {
  left: 0;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
}

.next-overlay-wrapper .next-overlay-inner {
  z-index: 1001;
}

.next-overlay-wrapper .next-overlay-backdrop {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.next-overlay-wrapper.opened .next-overlay-backdrop {
  opacity: 1;
}

.next-icon[dir="rtl"]:before {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

@font-face {
  font-family: NextIcon;
  src: url(//at.alicdn.com/t/font_1899989_d0f4v52x62a.eot);
  src: url(//at.alicdn.com/t/font_1899989_d0f4v52x62a.eot#iefix)
      format("embedded-opentype"),
    url(//at.alicdn.com/t/font_1899989_d0f4v52x62a.woff2) format("woff2"),
    url(//at.alicdn.com/t/font_1899989_d0f4v52x62a.woff) format("woff"),
    url(//at.alicdn.com/t/font_1899989_d0f4v52x62a.ttf) format("truetype"),
    url(//at.alicdn.com/t/font_1899989_d0f4v52x62a.svg#NextIcon) format("svg");
}

.next-icon {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.next-icon:before {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.next-icon-smile:before {
  content: "\E65F";
  content: var(--icon-content-smile, "\E65F");
}

.next-icon-cry:before {
  content: "\E65D";
  content: var(--icon-content-cry, "\E65D");
}

.next-icon-success:before {
  content: "\E60A";
  content: var(--icon-content-success, "\E60A");
}

.next-icon-warning:before {
  content: "\E60B";
  content: var(--icon-content-warning, "\E60B");
}

.next-icon-prompt:before {
  content: "\E60C";
  content: var(--icon-content-prompt, "\E60C");
}

.next-icon-error:before {
  content: "\E60D";
  content: var(--icon-content-error, "\E60D");
}

.next-icon-help:before {
  content: "\E673";
  content: var(--icon-content-help, "\E673");
}

.next-icon-clock:before {
  content: "\E621";
  content: var(--icon-content-clock, "\E621");
}

.next-icon-success-filling:before {
  content: "\E63A";
  content: var(--icon-content-success-filling, "\E63A");
}

.next-icon-delete-filling:before {
  content: "\E623";
  content: var(--icon-content-delete-filling, "\E623");
}

.next-icon-favorites-filling:before {
  content: "\E60E";
  content: var(--icon-content-favorites-filling, "\E60E");
}

.next-icon-add:before {
  content: "\E655";
  content: var(--icon-content-add, "\E655");
}

.next-icon-minus:before {
  content: "\E601";
  content: var(--icon-content-minus, "\E601");
}

.next-icon-arrow-up:before {
  content: "\E625";
  content: var(--icon-content-arrow-up, "\E625");
}

.next-icon-arrow-down:before {
  content: "\E63D";
  content: var(--icon-content-arrow-down, "\E63D");
}

.next-icon-arrow-left:before {
  content: "\E61D";
  content: var(--icon-content-arrow-left, "\E61D");
}

.next-icon-arrow-right:before {
  content: "\E619";
  content: var(--icon-content-arrow-right, "\E619");
}

.next-icon-arrow-double-left:before {
  content: "\E659";
  content: var(--icon-content-arrow-double-left, "\E659");
}

.next-icon-arrow-double-right:before {
  content: "\E65E";
  content: var(--icon-content-arrow-double-right, "\E65E");
}

.next-icon-switch:before {
  content: "\E6B3";
  content: var(--icon-content-switch, "\E6B3");
}

.next-icon-sorting:before {
  content: "\E634";
  content: var(--icon-content-sorting, "\E634");
}

.next-icon-descending:before {
  content: "\E61F";
  content: var(--icon-content-descending, "\E61F");
}

.next-icon-ascending:before {
  content: "\E61E";
  content: var(--icon-content-ascending, "\E61E");
}

.next-icon-select:before {
  content: "\E632";
  content: var(--icon-content-select, "\E632");
}

.next-icon-semi-select:before {
  content: "\E633";
  content: var(--icon-content-semi-select, "\E633");
}

.next-icon-search:before {
  content: "\E656";
  content: var(--icon-content-search, "\E656");
}

.next-icon-close:before {
  content: "\E626";
  content: var(--icon-content-close, "\E626");
}

.next-icon-ellipsis:before {
  content: "\E654";
  content: var(--icon-content-ellipsis, "\E654");
}

.next-icon-picture:before {
  content: "\E631";
  content: var(--icon-content-picture, "\E631");
}

.next-icon-calendar:before {
  content: "\E607";
  content: var(--icon-content-calendar, "\E607");
}

.next-icon-ashbin:before {
  content: "\E639";
  content: var(--icon-content-ashbin, "\E639");
}

.next-icon-upload:before {
  content: "\E7EE";
  content: var(--icon-content-upload, "\E7EE");
}

.next-icon-download:before {
  content: "\E628";
  content: var(--icon-content-download, "\E628");
}

.next-icon-set:before {
  content: "\E683";
  content: var(--icon-content-set, "\E683");
}

.next-icon-edit:before {
  content: "\E63B";
  content: var(--icon-content-edit, "\E63B");
}

.next-icon-refresh:before {
  content: "\E677";
  content: var(--icon-content-refresh, "\E677");
}

.next-icon-filter:before {
  content: "\E627";
  content: var(--icon-content-filter, "\E627");
}

.next-icon-attachment:before {
  content: "\E665";
  content: var(--icon-content-attachment, "\E665");
}

.next-icon-account:before {
  content: "\E608";
  content: var(--icon-content-account, "\E608");
}

.next-icon-email:before {
  content: "\E605";
  content: var(--icon-content-email, "\E605");
}

.next-icon-atm:before {
  content: "\E606";
  content: var(--icon-content-atm, "\E606");
}

.next-icon-loading:before {
  content: "\E646";
  content: var(--icon-content-loading, "\E646");
  -webkit-animation: loadingCircle 1s linear infinite;
  -moz-animation: loadingCircle 1s infinite linear;
  -o-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s linear infinite;
}

.next-icon-eye:before {
  content: "\E7ED";
  content: var(--icon-content-eye, "\E7ED");
}

.next-icon-copy:before {
  content: "\E80A";
  content: var(--icon-content-copy, "\E80A");
}

.next-icon-toggle-left:before {
  content: "\E602";
  content: var(--icon-content-toggle-left, "\E602");
}

.next-icon-toggle-right:before {
  content: "\E60F";
  content: var(--icon-content-toggle-right, "\E60F");
}

.next-icon-eye-close:before {
  content: "\E610";
  content: var(--icon-content-eye-close, "\E610");
}

.next-icon-unlock:before {
  content: "\E7FB";
  content: var(--icon-content-unlock, "\E7FB");
}

.next-icon-lock:before {
  content: "\E7FC";
  content: var(--icon-content-lock, "\E7FC");
}

.next-icon-exit:before {
  content: "\E616";
  content: var(--icon-content-exit, "\E616");
}

.next-icon-chart-bar:before {
  content: "\E791";
  content: var(--icon-content-chart-bar, "\E791");
}

.next-icon-chart-pie:before {
  content: "\E613";
  content: var(--icon-content-chart-pie, "\E613");
}

.next-icon-form:before {
  content: "\E869";
  content: var(--icon-content-form, "\E869");
}

.next-icon-detail:before {
  content: "\E852";
  content: var(--icon-content-detail, "\E852");
}

.next-icon-list:before {
  content: "\E859";
  content: var(--icon-content-list, "\E859");
}

.next-icon-dashboard:before {
  content: "\E868";
  content: var(--icon-content-dashboard, "\E868");
}

@-webkit-keyframes loadingCircle {
  0% {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-moz-keyframes loadingCircle {
  0% {
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-o-keyframes loadingCircle {
  0% {
    -webkit-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(1turn);
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.next-icon.next-xxs .next-icon-remote,
.next-icon.next-xxs:before {
  width: 8px;
  font-size: 8px;
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-icon.next-xxs {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -4px;
    margin-right: -4px;
  }

  .next-icon.next-xxs:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-icon.next-xs .next-icon-remote,
.next-icon.next-xs:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-icon.next-small .next-icon-remote,
.next-icon.next-small:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-icon.next-medium .next-icon-remote,
.next-icon.next-medium:before {
  width: 20px;
  font-size: 20px;
  line-height: inherit;
}

.next-icon.next-large .next-icon-remote,
.next-icon.next-large:before {
  width: 24px;
  font-size: 24px;
  line-height: inherit;
}

.next-icon.next-xl .next-icon-remote,
.next-icon.next-xl:before {
  width: 32px;
  font-size: 32px;
  line-height: inherit;
}

.next-icon.next-xxl .next-icon-remote,
.next-icon.next-xxl:before {
  width: 48px;
  font-size: 48px;
  line-height: inherit;
}

.next-icon.next-xxxl .next-icon-remote,
.next-icon.next-xxxl:before {
  width: 64px;
  font-size: 64px;
  line-height: inherit;
}

.next-icon.next-inherit .next-icon-remote,
.next-icon.next-inherit:before {
  width: inherit;
  font-size: inherit;
  line-height: inherit;
}

.next-icon .next-icon-remote,
.next-icon.next-inherit .next-icon-remote {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
}

.next-balloon {
  position: absolute;
  max-width: 400px;
  max-width: var(--balloon-size-max-width, 400px);
  border-style: solid;
  border-style: var(--balloon-border-style, solid);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--balloon-corner, 2px);
  -moz-border-radius: var(--balloon-corner, 2px);
  border-radius: var(--balloon-corner, 2px);
  font-size: 12px;
  font-size: var(--balloon-content-font-size, 12px);
  font-weight: 400;
  font-weight: var(--balloon-content-font-weight, normal);
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  word-wrap: break-all;
  word-wrap: break-word;
  z-index: 0;
}

.next-balloon,
.next-balloon *,
.next-balloon :after,
.next-balloon :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-balloon:focus,
.next-balloon :focus {
  outline: 0;
}

.next-balloon-primary {
  color: #333;
  color: var(--balloon-primary-color, #333);
  border-color: #c0c6cc;
  border-color: var(--balloon-primary-color-border, #c0c6cc);
  background-color: #fff;
  background-color: var(--balloon-primary-color-bg, #fff);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --balloon-primary-shadow,
    0 4px 8px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(
    --balloon-primary-shadow,
    0 4px 8px 0 rgba(0, 0, 0, 0.16)
  );
  box-shadow: var(--balloon-primary-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
  border-width: 1px;
  border-width: var(--balloon-primary-border-width, 1px);
}

.next-balloon-primary .next-balloon-close {
  position: absolute;
  top: 12px;
  top: var(--balloon-size-close-margin-top, 12px);
  right: 12px;
  right: var(--balloon-size-close-margin-right, 12px);
  font-size: 12px;
  font-size: var(--balloon-size-close, 12px);
  cursor: pointer;
  color: #333;
  color: var(--balloon-primary-color-close, #333);
}

.next-balloon-primary .next-balloon-close .next-icon,
.next-balloon-primary .next-balloon-close .next-icon:before {
  width: 12px;
  width: var(--balloon-size-close, 12px);
  height: 12px;
  height: var(--balloon-size-close, 12px);
  line-height: 1em;
}

.next-balloon-primary .next-balloon-close .next-icon:before {
  font-size: 12px;
  font-size: var(--balloon-size-close, 12px);
}

.next-balloon-primary .next-balloon-close :hover {
  color: #333;
  color: var(--balloon-primary-color-close-hover, #333);
}

.next-balloon-primary:after {
  position: absolute;
  width: 8px;
  width: var(--balloon-size-arrow-size, 8px);
  height: 8px;
  height: var(--balloon-size-arrow-size, 8px);
  content: "";
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
  border: 1px solid #c0c6cc;
  border: var(--balloon-primary-border-width, 1px)
    var(--balloon-border-style, solid)
    var(--balloon-primary-color-border, #c0c6cc);
  background-color: #fff;
  background-color: var(--balloon-primary-color-bg, #fff);
  z-index: -1;
}

.next-balloon-primary.next-balloon-top:after {
  top: -5px;
  top: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  top: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  top: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: -webkit-calc(50% - 5px);
  left: -moz-calc(50% - 5px);
  left: calc(50% - 5px);
  left: -webkit-calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: -moz-calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  border-right: none;
  border-bottom: none;
}

.next-balloon-primary.next-balloon-right:after {
  top: -webkit-calc(50% - 5px);
  top: -moz-calc(50% - 5px);
  top: calc(50% - 5px);
  top: -webkit-calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  top: -moz-calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  top: calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  right: -5px;
  right: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  right: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  right: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  border-left: none;
  border-bottom: none;
}

.next-balloon-primary.next-balloon-bottom:after {
  bottom: -5px;
  bottom: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  bottom: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  bottom: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: -webkit-calc(50% - 5px);
  left: -moz-calc(50% - 5px);
  left: calc(50% - 5px);
  left: -webkit-calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: -moz-calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  border-top: none;
  border-left: none;
}

.next-balloon-primary.next-balloon-left:after {
  top: -webkit-calc(50% - 5px);
  top: -moz-calc(50% - 5px);
  top: calc(50% - 5px);
  top: -webkit-calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  top: -moz-calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  top: calc(
    50% - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
}

.next-balloon-primary.next-balloon-left-top:after,
.next-balloon-primary.next-balloon-left:after {
  left: -5px;
  left: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  border-top: none;
  border-right: none;
}

.next-balloon-primary.next-balloon-left-top:after {
  top: 12px;
  top: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-primary.next-balloon-left-bottom:after {
  bottom: 12px;
  bottom: var(--balloon-size-arrow-margin, 12px);
  left: -5px;
  left: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  border-top: none;
  border-right: none;
}

.next-balloon-primary.next-balloon-right-top:after {
  top: 12px;
  top: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-primary.next-balloon-right-bottom:after,
.next-balloon-primary.next-balloon-right-top:after {
  right: -5px;
  right: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  right: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  right: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  border-bottom: none;
  border-left: none;
}

.next-balloon-primary.next-balloon-right-bottom:after {
  bottom: 12px;
  bottom: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-primary.next-balloon-top-left:after {
  left: 12px;
  left: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-primary.next-balloon-top-left:after,
.next-balloon-primary.next-balloon-top-right:after {
  top: -5px;
  top: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  top: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  top: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  border-right: none;
  border-bottom: none;
}

.next-balloon-primary.next-balloon-top-right:after {
  right: 12px;
  right: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-primary.next-balloon-bottom-left:after {
  left: 12px;
  left: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-primary.next-balloon-bottom-left:after,
.next-balloon-primary.next-balloon-bottom-right:after {
  bottom: -5px;
  bottom: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  bottom: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  bottom: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  border-top: none;
  border-left: none;
}

.next-balloon-primary.next-balloon-bottom-right:after {
  right: 12px;
  right: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-normal {
  color: #333;
  color: var(--balloon-normal-color, #333);
  border-color: #c0c6cc;
  border-color: var(--balloon-normal-color-border, #c0c6cc);
  background-color: #fff;
  background-color: var(--balloon-normal-color-bg, #fff);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --balloon-normal-shadow,
    0 4px 8px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(
    --balloon-normal-shadow,
    0 4px 8px 0 rgba(0, 0, 0, 0.16)
  );
  box-shadow: var(--balloon-normal-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
  border-width: 1px;
  border-width: var(--balloon-normal-border-width, 1px);
}

.next-balloon-normal .next-balloon-close {
  position: absolute;
  top: 12px;
  top: var(--balloon-size-close-margin-top, 12px);
  right: 12px;
  right: var(--balloon-size-close-margin-right, 12px);
  font-size: 12px;
  font-size: var(--balloon-size-close, 12px);
  cursor: pointer;
  color: #333;
  color: var(--balloon-normal-color-close, #333);
}

.next-balloon-normal .next-balloon-close .next-icon,
.next-balloon-normal .next-balloon-close .next-icon:before {
  width: 12px;
  width: var(--balloon-size-close, 12px);
  height: 12px;
  height: var(--balloon-size-close, 12px);
  line-height: 1em;
}

.next-balloon-normal .next-balloon-close .next-icon:before {
  font-size: 12px;
  font-size: var(--balloon-size-close, 12px);
}

.next-balloon-normal .next-balloon-close :hover {
  color: #333;
  color: var(--balloon-normal-color-close-hover, #333);
}

.next-balloon-normal:after {
  position: absolute;
  width: 8px;
  width: var(--balloon-size-arrow-size, 8px);
  height: 8px;
  height: var(--balloon-size-arrow-size, 8px);
  content: "";
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
  border: 1px solid #c0c6cc;
  border: var(--balloon-normal-border-width, 1px)
    var(--balloon-border-style, solid)
    var(--balloon-normal-color-border, #c0c6cc);
  background-color: #fff;
  background-color: var(--balloon-normal-color-bg, #fff);
  z-index: -1;
}

.next-balloon-normal.next-balloon-top:after {
  top: -5px;
  top: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  top: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  top: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: -webkit-calc(50% - 5px);
  left: -moz-calc(50% - 5px);
  left: calc(50% - 5px);
  left: -webkit-calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: -moz-calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  border-right: none;
  border-bottom: none;
}

.next-balloon-normal.next-balloon-right:after {
  top: -webkit-calc(50% - 5px);
  top: -moz-calc(50% - 5px);
  top: calc(50% - 5px);
  top: -webkit-calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  top: -moz-calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  top: calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  right: -5px;
  right: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  right: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  right: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  border-left: none;
  border-bottom: none;
}

.next-balloon-normal.next-balloon-bottom:after {
  bottom: -5px;
  bottom: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  bottom: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  bottom: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: -webkit-calc(50% - 5px);
  left: -moz-calc(50% - 5px);
  left: calc(50% - 5px);
  left: -webkit-calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: -moz-calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  border-top: none;
  border-left: none;
}

.next-balloon-normal.next-balloon-left:after {
  top: -webkit-calc(50% - 5px);
  top: -moz-calc(50% - 5px);
  top: calc(50% - 5px);
  top: -webkit-calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  top: -moz-calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
  top: calc(50% - 1px - var(--balloon-size-arrow-size, 8px) / 2);
}

.next-balloon-normal.next-balloon-left-top:after,
.next-balloon-normal.next-balloon-left:after {
  left: -5px;
  left: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  border-top: none;
  border-right: none;
}

.next-balloon-normal.next-balloon-left-top:after {
  top: 12px;
  top: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-normal.next-balloon-left-bottom:after {
  bottom: 12px;
  bottom: var(--balloon-size-arrow-margin, 12px);
  left: -5px;
  left: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  border-top: none;
  border-right: none;
}

.next-balloon-normal.next-balloon-right-top:after {
  top: 12px;
  top: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-normal.next-balloon-right-bottom:after,
.next-balloon-normal.next-balloon-right-top:after {
  right: -5px;
  right: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  right: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  right: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  border-bottom: none;
  border-left: none;
}

.next-balloon-normal.next-balloon-right-bottom:after {
  bottom: 12px;
  bottom: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-normal.next-balloon-top-left:after {
  left: 12px;
  left: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-normal.next-balloon-top-left:after,
.next-balloon-normal.next-balloon-top-right:after {
  top: -5px;
  top: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  top: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  top: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  border-right: none;
  border-bottom: none;
}

.next-balloon-normal.next-balloon-top-right:after {
  right: 12px;
  right: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-normal.next-balloon-bottom-left:after {
  left: 12px;
  left: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon-normal.next-balloon-bottom-left:after,
.next-balloon-normal.next-balloon-bottom-right:after {
  bottom: -5px;
  bottom: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  bottom: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  bottom: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  border-top: none;
  border-left: none;
}

.next-balloon-normal.next-balloon-bottom-right:after {
  right: 12px;
  right: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon.visible {
  display: block;
}

.next-balloon.hidden {
  display: none;
}

.next-balloon-medium {
  padding: 12px;
  padding: var(--balloon-size-padding-top, 12px)
    var(--balloon-size-padding-right, 12px)
    var(--balloon-size-padding-top, 12px)
    var(--balloon-size-padding-right, 12px);
}

.next-balloon-closable {
  padding: 12px 32px 12px 12px;
  padding: var(--balloon-size-padding-top, 12px)
    var(--balloon-size-padding-closable-right, 32px)
    var(--balloon-size-padding-top, 12px)
    var(--balloon-size-padding-right, 12px);
}

.next-balloon-tooltip {
  position: absolute;
  max-width: 400px;
  max-width: var(--balloon-size-max-width, 400px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--balloon-corner, 2px);
  -moz-border-radius: var(--balloon-corner, 2px);
  border-radius: var(--balloon-corner, 2px);
  font-size: 12px;
  font-size: var(--balloon-tooltip-content-font-size, 12px);
  font-weight: 400;
  font-weight: var(--balloon-tooltip-content-font-weight, normal);
  z-index: 0;
  word-wrap: break-all;
  word-wrap: break-word;
  color: #333;
  color: var(--balloon-tooltip-color, #333);
  background-color: #fff;
  background-color: var(--balloon-tooltip-color-bg, #fff);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --balloon-tooltip-shadow,
    0 4px 8px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(
    --balloon-tooltip-shadow,
    0 4px 8px 0 rgba(0, 0, 0, 0.16)
  );
  box-shadow: var(--balloon-tooltip-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
  border: 1px solid #c0c6cc;
  border: var(--balloon-tooltip-border-width, 1px)
    var(--balloon-tooltip-border-style, solid)
    var(--balloon-tooltip-color-border, #c0c6cc);
}

.next-balloon-tooltip,
.next-balloon-tooltip *,
.next-balloon-tooltip :after,
.next-balloon-tooltip :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-balloon-tooltip .next-balloon-arrow {
  position: absolute;
  display: block;
  width: 16px;
  width: -webkit-calc(var(--balloon-size-arrow-size, 8px) * 2);
  width: -moz-calc(var(--balloon-size-arrow-size, 8px) * 2);
  width: calc(var(--balloon-size-arrow-size, 8px) * 2);
  height: 16px;
  height: -webkit-calc(var(--balloon-size-arrow-size, 8px) * 2);
  height: -moz-calc(var(--balloon-size-arrow-size, 8px) * 2);
  height: calc(var(--balloon-size-arrow-size, 8px) * 2);
  overflow: hidden;
  background: 0 0;
  pointer-events: none;
}

.next-balloon-tooltip .next-balloon-arrow .next-balloon-arrow-content {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 8px;
  width: var(--balloon-size-arrow-size, 8px);
  height: 8px;
  height: var(--balloon-size-arrow-size, 8px);
  margin: auto;
  background-color: #fff;
  background-color: var(--balloon-tooltip-color-bg, #fff);
  border: 1px solid #c0c6cc;
  border: var(--balloon-tooltip-border-width, 1px)
    var(--balloon-tooltip-border-style, solid)
    var(--balloon-tooltip-color-border, #c0c6cc);
  pointer-events: auto;
}

.next-balloon-tooltip-top .next-balloon-arrow {
  top: -16px;
  top: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  top: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  top: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
  left: -webkit-calc(50% - 8px);
  left: -moz-calc(50% - 8px);
  left: calc(50% - 8px);
  left: -webkit-calc(50% - var(--balloon-size-arrow-size, 8px));
  left: -moz-calc(50% - var(--balloon-size-arrow-size, 8px));
  left: calc(50% - var(--balloon-size-arrow-size, 8px));
}

.next-balloon-tooltip-top .next-balloon-arrow .next-balloon-arrow-content {
  -webkit-transform: translateY(8px) rotate(45deg);
  -webkit-transform: translateY(var(--balloon-size-arrow-size, 8px))
    rotate(45deg);
  -moz-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
  -moz-transform: translateY(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  -o-transform: translateY(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  transform: translateY(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
}

.next-balloon-tooltip-right .next-balloon-arrow {
  top: -webkit-calc(50% - 8px);
  top: -moz-calc(50% - 8px);
  top: calc(50% - 8px);
  top: -webkit-calc(50% - var(--balloon-size-arrow-size, 8px));
  top: -moz-calc(50% - var(--balloon-size-arrow-size, 8px));
  top: calc(50% - var(--balloon-size-arrow-size, 8px));
  right: -16px;
  right: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-right .next-balloon-arrow .next-balloon-arrow-content {
  -webkit-transform: translateX(-8px) rotate(45deg);
  -webkit-transform: translateX(
      -webkit-calc(0 - var(--balloon-size-arrow-size, 8px))
    )
    rotate(45deg);
  -moz-transform: translateX(-8px) rotate(45deg);
  -o-transform: translateX(-8px) rotate(45deg);
  transform: translateX(-8px) rotate(45deg);
  -moz-transform: translateX(-moz-calc(0 - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  -o-transform: translateX(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  transform: translateX(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
}

.next-balloon-tooltip-bottom .next-balloon-arrow {
  left: -webkit-calc(50% - 8px);
  left: -moz-calc(50% - 8px);
  left: calc(50% - 8px);
  left: -webkit-calc(50% - var(--balloon-size-arrow-size, 8px));
  left: -moz-calc(50% - var(--balloon-size-arrow-size, 8px));
  left: calc(50% - var(--balloon-size-arrow-size, 8px));
  bottom: -16px;
  bottom: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  bottom: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  bottom: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-bottom .next-balloon-arrow .next-balloon-arrow-content {
  -webkit-transform: translateY(-8px) rotate(45deg);
  -webkit-transform: translateY(
      -webkit-calc(0 - var(--balloon-size-arrow-size, 8px))
    )
    rotate(45deg);
  -moz-transform: translateY(-8px) rotate(45deg);
  -o-transform: translateY(-8px) rotate(45deg);
  transform: translateY(-8px) rotate(45deg);
  -moz-transform: translateY(-moz-calc(0 - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  -o-transform: translateY(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  transform: translateY(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
}

.next-balloon-tooltip-left .next-balloon-arrow {
  top: -webkit-calc(50% - 8px);
  top: -moz-calc(50% - 8px);
  top: calc(50% - 8px);
  top: -webkit-calc(50% - var(--balloon-size-arrow-size, 8px));
  top: -moz-calc(50% - var(--balloon-size-arrow-size, 8px));
  top: calc(50% - var(--balloon-size-arrow-size, 8px));
  left: -16px;
  left: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-left .next-balloon-arrow .next-balloon-arrow-content {
  -webkit-transform: translateX(8px) rotate(45deg);
  -webkit-transform: translateX(var(--balloon-size-arrow-size, 8px))
    rotate(45deg);
  -moz-transform: translateX(8px) rotate(45deg);
  -o-transform: translateX(8px) rotate(45deg);
  transform: translateX(8px) rotate(45deg);
  -moz-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  -o-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
}

.next-balloon-tooltip-left-top .next-balloon-arrow {
  top: 8px;
  top: -webkit-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  top: -moz-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  top: calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  left: -16px;
  left: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-left-top .next-balloon-arrow .next-balloon-arrow-content {
  -webkit-transform: translateX(8px) rotate(45deg);
  -webkit-transform: translateX(var(--balloon-size-arrow-size, 8px))
    rotate(45deg);
  -moz-transform: translateX(8px) rotate(45deg);
  -o-transform: translateX(8px) rotate(45deg);
  transform: translateX(8px) rotate(45deg);
  -moz-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  -o-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
}

.next-balloon-tooltip-left-bottom .next-balloon-arrow {
  bottom: 8px;
  bottom: -webkit-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  bottom: -moz-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  bottom: calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  left: -16px;
  left: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-left-bottom
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateX(8px) rotate(45deg);
  -webkit-transform: translateX(var(--balloon-size-arrow-size, 8px))
    rotate(45deg);
  -moz-transform: translateX(8px) rotate(45deg);
  -o-transform: translateX(8px) rotate(45deg);
  transform: translateX(8px) rotate(45deg);
  -moz-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  -o-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
}

.next-balloon-tooltip-right-top .next-balloon-arrow {
  top: 8px;
  top: -webkit-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  top: -moz-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  top: calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  right: -16px;
  right: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-right-top
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateX(-8px) rotate(45deg);
  -webkit-transform: translateX(
      -webkit-calc(0 - var(--balloon-size-arrow-size, 8px))
    )
    rotate(45deg);
  -moz-transform: translateX(-8px) rotate(45deg);
  -o-transform: translateX(-8px) rotate(45deg);
  transform: translateX(-8px) rotate(45deg);
  -moz-transform: translateX(-moz-calc(0 - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  -o-transform: translateX(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  transform: translateX(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
}

.next-balloon-tooltip-right-bottom .next-balloon-arrow {
  bottom: 8px;
  bottom: -webkit-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  bottom: -moz-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  bottom: calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  right: -16px;
  right: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-right-bottom
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateX(-8px) rotate(45deg);
  -webkit-transform: translateX(
      -webkit-calc(0 - var(--balloon-size-arrow-size, 8px))
    )
    rotate(45deg);
  -moz-transform: translateX(-8px) rotate(45deg);
  -o-transform: translateX(-8px) rotate(45deg);
  transform: translateX(-8px) rotate(45deg);
  -moz-transform: translateX(-moz-calc(0 - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  -o-transform: translateX(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  transform: translateX(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
}

.next-balloon-tooltip-top-left .next-balloon-arrow {
  left: 8px;
  left: -webkit-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  left: -moz-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  left: calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  top: -16px;
  top: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  top: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  top: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-top-left .next-balloon-arrow .next-balloon-arrow-content {
  -webkit-transform: translateY(8px) rotate(45deg);
  -webkit-transform: translateY(var(--balloon-size-arrow-size, 8px))
    rotate(45deg);
  -moz-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
  -moz-transform: translateY(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  -o-transform: translateY(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  transform: translateY(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
}

.next-balloon-tooltip-top-right .next-balloon-arrow {
  right: 8px;
  right: -webkit-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  right: -moz-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  right: calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  top: -16px;
  top: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  top: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  top: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-top-right
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateY(8px) rotate(45deg);
  -webkit-transform: translateY(var(--balloon-size-arrow-size, 8px))
    rotate(45deg);
  -moz-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
  -moz-transform: translateY(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  -o-transform: translateY(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  transform: translateY(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
}

.next-balloon-tooltip-bottom-left .next-balloon-arrow {
  left: 8px;
  left: -webkit-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  left: -moz-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  left: calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  bottom: -16px;
  bottom: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  bottom: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  bottom: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-bottom-left
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateY(-8px) rotate(45deg);
  -webkit-transform: translateY(
      -webkit-calc(0 - var(--balloon-size-arrow-size, 8px))
    )
    rotate(45deg);
  -moz-transform: translateY(-8px) rotate(45deg);
  -o-transform: translateY(-8px) rotate(45deg);
  transform: translateY(-8px) rotate(45deg);
  -moz-transform: translateY(-moz-calc(0 - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  -o-transform: translateY(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  transform: translateY(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
}

.next-balloon-tooltip-bottom-right .next-balloon-arrow {
  right: 8px;
  right: -webkit-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  right: -moz-calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  right: calc(
    var(--balloon-size-arrow-margin, 12px) - var(--balloon-size-arrow-size, 8px) /
      2
  );
  bottom: -16px;
  bottom: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  bottom: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  bottom: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
}

.next-balloon-tooltip-bottom-right
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateY(-8px) rotate(45deg);
  -webkit-transform: translateY(
      -webkit-calc(0 - var(--balloon-size-arrow-size, 8px))
    )
    rotate(45deg);
  -moz-transform: translateY(-8px) rotate(45deg);
  -o-transform: translateY(-8px) rotate(45deg);
  transform: translateY(-8px) rotate(45deg);
  -moz-transform: translateY(-moz-calc(0 - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  -o-transform: translateY(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
  transform: translateY(calc(0px - var(--balloon-size-arrow-size, 8px)))
    rotate(45deg);
}

.next-balloon-tooltip.visible {
  display: block;
}

.next-balloon-tooltip.hidden {
  display: none;
}

.next-balloon-tooltip-medium {
  padding: 12px;
  padding: var(--balloon-tooltip-size-padding-top, 12px)
    var(--balloon-tooltip-size-padding-right, 12px)
    var(--balloon-tooltip-size-padding-bottom, 12px)
    var(--balloon-tooltip-size-padding-left, 12px);
}

.next-balloon[dir="rtl"].next-balloon-primary .next-balloon-close {
  left: 12px;
  left: var(--balloon-size-close-margin-right, 12px);
  right: auto;
}

.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-right:after {
  left: -5px;
  left: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  right: auto;
  border-right: none;
  border-top: none;
  border-left: inherit;
  border-bottom: inherit;
}

.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-left-bottom:after,
.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-left-top:after,
.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-left:after {
  right: -5px;
  right: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  right: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  right: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: auto;
  border-left: none;
  border-bottom: none;
  border-right: inherit;
  border-top: inherit;
}

.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-right-bottom:after,
.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-right-top:after {
  left: -5px;
  left: -webkit-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: -moz-calc(
    0 - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  left: calc(
    0px - var(--balloon-size-arrow-size, 8px) / 2 -
      var(--balloon-primary-border-width, 1px)
  );
  right: auto;
  border-right: none;
  border-top: none;
  border-bottom: inherit;
  border-left: inherit;
}

.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-top-left:after {
  right: 12px;
  right: var(--balloon-size-arrow-margin, 12px);
  left: auto;
}

.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-top-right:after {
  right: auto;
  left: 12px;
  left: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-bottom-left:after {
  right: 12px;
  right: var(--balloon-size-arrow-margin, 12px);
  left: auto;
}

.next-balloon[dir="rtl"].next-balloon-primary.next-balloon-bottom-right:after {
  left: 12px;
  left: var(--balloon-size-arrow-margin, 12px);
  right: auto;
}

.next-balloon[dir="rtl"].next-balloon-normal .next-balloon-close {
  left: 12px;
  left: var(--balloon-size-close-margin-right, 12px);
  right: auto;
}

.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-right:after {
  left: -5px;
  left: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  right: auto;
  border-right: none;
  border-top: none;
  border-left: inherit;
  border-bottom: inherit;
}

.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-left-bottom:after,
.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-left-top:after,
.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-left:after {
  right: -5px;
  right: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  right: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  right: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: auto;
  border-left: none;
  border-bottom: none;
  border-right: inherit;
  border-top: inherit;
}

.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-right-bottom:after,
.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-right-top:after {
  left: -5px;
  left: -webkit-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: -moz-calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  left: calc(-1px - var(--balloon-size-arrow-size, 8px) / 2);
  right: auto;
  border-right: none;
  border-top: none;
  border-bottom: inherit;
  border-left: inherit;
}

.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-top-left:after {
  right: 12px;
  right: var(--balloon-size-arrow-margin, 12px);
  left: auto;
}

.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-top-right:after {
  right: auto;
  left: 12px;
  left: var(--balloon-size-arrow-margin, 12px);
}

.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-bottom-left:after {
  right: 12px;
  right: var(--balloon-size-arrow-margin, 12px);
  left: auto;
}

.next-balloon[dir="rtl"].next-balloon-normal.next-balloon-bottom-right:after {
  left: 12px;
  left: var(--balloon-size-arrow-margin, 12px);
  right: auto;
}

.next-balloon[dir="rtl"].next-balloon-closable {
  padding: 12px 12px 12px 32px;
  padding: var(--balloon-size-padding-top, 12px)
    var(--balloon-size-padding-right, 12px)
    var(--balloon-size-padding-top, 12px)
    var(--balloon-size-padding-closable-right, 32px);
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-right
  .next-balloon-arrow {
  left: -16px;
  left: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
  right: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-right
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateX(8px) rotate(45deg);
  -webkit-transform: translateX(var(--balloon-size-arrow-size, 8px))
    rotate(45deg);
  -moz-transform: translateX(8px) rotate(45deg);
  -o-transform: translateX(8px) rotate(45deg);
  transform: translateX(8px) rotate(45deg);
  -moz-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  -o-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-left .next-balloon-arrow {
  right: -16px;
  right: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
  left: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-left
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateX(-8px) rotate(45deg);
  -moz-transform: translateX(-8px) rotate(45deg);
  -o-transform: translateX(-8px) rotate(45deg);
  transform: translateX(-8px) rotate(45deg);
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-left-top
  .next-balloon-arrow {
  right: -16px;
  right: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
  left: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-left-top
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateX(-8px) rotate(45deg);
  -moz-transform: translateX(-8px) rotate(45deg);
  -o-transform: translateX(-8px) rotate(45deg);
  transform: translateX(-8px) rotate(45deg);
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-left-bottom
  .next-balloon-arrow {
  right: -16px;
  right: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  right: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
  left: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-left-bottom
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateX(-8px) rotate(45deg);
  -moz-transform: translateX(-8px) rotate(45deg);
  -o-transform: translateX(-8px) rotate(45deg);
  transform: translateX(-8px) rotate(45deg);
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-right-top
  .next-balloon-arrow {
  left: -16px;
  left: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
  right: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-right-top
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateX(8px) rotate(45deg);
  -webkit-transform: translateX(var(--balloon-size-arrow-size, 8px))
    rotate(45deg);
  -moz-transform: translateX(8px) rotate(45deg);
  -o-transform: translateX(8px) rotate(45deg);
  transform: translateX(8px) rotate(45deg);
  -moz-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  -o-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-right-bottom
  .next-balloon-arrow {
  left: -16px;
  left: -webkit-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: -moz-calc(0 - var(--balloon-size-arrow-size, 8px) * 2);
  left: calc(0px - var(--balloon-size-arrow-size, 8px) * 2);
  right: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-right-bottom
  .next-balloon-arrow
  .next-balloon-arrow-content {
  -webkit-transform: translateX(8px) rotate(45deg);
  -webkit-transform: translateX(var(--balloon-size-arrow-size, 8px))
    rotate(45deg);
  -moz-transform: translateX(8px) rotate(45deg);
  -o-transform: translateX(8px) rotate(45deg);
  transform: translateX(8px) rotate(45deg);
  -moz-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  -o-transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
  transform: translateX(var(--balloon-size-arrow-size, 8px)) rotate(45deg);
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-top-left
  .next-balloon-arrow {
  right: 10px;
  right: -webkit-calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  right: -moz-calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  right: calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  left: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-top-right
  .next-balloon-arrow {
  left: 10px;
  left: -webkit-calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  left: -moz-calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  left: calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  right: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-bottom-left
  .next-balloon-arrow {
  right: 10px;
  right: -webkit-calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  right: -moz-calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  right: calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  left: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-bottom-right
  .next-balloon-arrow {
  left: 10px;
  left: -webkit-calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  left: -moz-calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  left: calc(var(--balloon-size-arrow-margin, 12px) - 2px);
  right: auto;
}

.next-balloon-tooltip[dir="rtl"].next-balloon-tooltip-medium {
  padding: 12px;
  padding: var(--balloon-tooltip-size-padding-top, 12px)
    var(--balloon-tooltip-size-padding-left, 12px)
    var(--balloon-tooltip-size-padding-bottom, 12px)
    var(--balloon-tooltip-size-padding-right, 12px);
}

.next-breadcrumb {
  display: block;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  height: 16px;
  line-height: 16px;
}

.next-breadcrumb .next-breadcrumb-item {
  display: inline-block;
}

.next-breadcrumb .next-breadcrumb-item .next-breadcrumb-text {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-breadcrumb .next-breadcrumb-item .next-breadcrumb-text > b {
  font-weight: 400;
}

.next-breadcrumb .next-breadcrumb-item .next-breadcrumb-separator {
  display: inline-block;
  vertical-align: top;
}

.next-breadcrumb .next-breadcrumb-text {
  height: 16px;
  min-width: 16px;
  font-size: 12px;
  font-size: var(--breadcrumb-size-m-font-size, 12px);
  line-height: 16px;
}

.next-breadcrumb .next-breadcrumb-separator {
  height: 16px;
  margin: 0 8px;
  font-size: 12px;
  line-height: 16px;
}

.next-breadcrumb .next-breadcrumb-separator .next-icon:before {
  display: block;
}

.next-breadcrumb .next-breadcrumb-separator .next-icon .next-icon-remote,
.next-breadcrumb .next-breadcrumb-separator .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-breadcrumb .next-breadcrumb-text-ellipsis {
  font-size: 12px;
  font-size: var(--breadcrumb-size-ellipsis-font-size, 12px);
}

.next-breadcrumb .next-breadcrumb-text {
  color: #666;
  color: var(--breadcrumb-text-color, #666);
}

.next-breadcrumb .next-breadcrumb-text > b {
  color: #0064c8;
  color: var(--breadcrumb-text-keyword-color, #0064c8);
}

.next-breadcrumb .next-breadcrumb-text > a {
  color: #666;
  color: var(--breadcrumb-text-color, #666);
  text-decoration: none;
  text-align: center;
}

.next-breadcrumb .next-breadcrumb-text.activated,
.next-breadcrumb .next-breadcrumb-text.activated > a {
  color: #ccc;
  color: var(--breadcrumb-text-current-color, #ccc);
  font-weight: 400;
  font-weight: var(--breadcrumb-text-current-weight, normal);
}

.next-breadcrumb .next-breadcrumb-text-ellipsis {
  color: #555;
  color: var(--breadcrumb-text-ellipsis-color, #555);
  cursor: default;
}

.next-breadcrumb .next-breadcrumb-separator {
  color: #e3e4e6;
  color: var(--breadcrumb-icon-color, #e3e4e6);
}

.next-breadcrumb
  .next-breadcrumb-text:not(.next-breadcrumb-text-ellipsis):hover
  > a {
  color: #333;
  color: var(--breadcrumb-text-color-hover, #333);
}

.next-breadcrumb a.next-breadcrumb-text.activated:hover > a {
  color: #ccc;
  color: var(--breadcrumb-text-current-color-hover, #ccc);
}

.next-breadcrumb
  a.next-breadcrumb-text:not(.next-breadcrumb-text-ellipsis):hover {
  color: #333;
  color: var(--breadcrumb-text-color-hover, #333);
}

.next-breadcrumb
  a.next-breadcrumb-text:not(.next-breadcrumb-text-ellipsis):hover
  > b {
  color: #0064c8;
  color: var(--breadcrumb-text-keyword-color-hover, #0064c8);
}

.next-breadcrumb a.next-breadcrumb-text.activated:hover {
  color: #ccc;
  color: var(--breadcrumb-text-current-color-hover, #ccc);
  font-weight: 400;
  font-weight: var(--breadcrumb-text-current-weight, normal);
}

.next-breadcrumb-icon-sep:before {
  content: "\E617";
}

.next-btn,
.next-btn *,
.next-btn :after,
.next-btn :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.next-btn,
.next-btn:active,
.next-btn:focus,
.next-btn:hover {
  outline: 0;
}

@keyframes loadingCircle {
  0% {
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.next-btn {
  position: relative;
  display: inline-block;
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--btn-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--btn-shadow, none);
  box-shadow: var(--btn-shadow, none);
  text-decoration: none;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  line-height: 1;
  cursor: pointer;
}

.next-btn:after {
  text-align: center;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}

.next-btn:before {
  content: "";
  height: 100%;
  width: 0;
}

.next-btn .next-icon,
.next-btn:before {
  display: inline-block;
  vertical-align: middle;
}

.next-btn .next-icon {
  font-size: 0;
}

.next-btn > .next-btn-helper,
.next-btn > div,
.next-btn > span {
  display: inline-block;
  vertical-align: middle;
}

.next-btn > .next-btn-helper {
  text-decoration: inherit;
}

.next-btn.hover,
.next-btn:hover {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.next-btn.next-small {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--btn-size-s-corner, 2px);
  -moz-border-radius: var(--btn-size-s-corner, 2px);
  border-radius: var(--btn-size-s-corner, 2px);
  padding: 0 8px;
  padding: 0 var(--btn-size-s-padding, 8px);
  height: 24px;
  height: var(--btn-size-s-height, 24px);
  font-size: 12px;
  font-size: var(--btn-size-s-font, 12px);
  border-width: 1px;
  border-width: var(--btn-size-s-border-width, 1px);
}

.next-btn.next-small > .next-btn-icon.next-icon-first {
  margin-left: 0;
  margin-right: 8px;
}

.next-btn.next-small > .next-btn-icon.next-icon-first .next-icon-remote,
.next-btn.next-small > .next-btn-icon.next-icon-first:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn.next-small > .next-btn-icon.next-icon-last {
  margin-left: 8px;
  margin-right: 0;
}

.next-btn.next-small > .next-btn-icon.next-icon-alone .next-icon-remote,
.next-btn.next-small > .next-btn-icon.next-icon-alone:before,
.next-btn.next-small > .next-btn-icon.next-icon-last .next-icon-remote,
.next-btn.next-small > .next-btn-icon.next-icon-last:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn.next-small.next-btn-loading:before {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  left: 8px;
  left: var(--btn-size-s-padding, 8px);
  top: 50%;
  text-align: center;
  margin-right: 8px;
}

.next-btn.next-small.next-btn-loading > .next-icon {
  display: none;
}

.next-btn.next-small > .next-btn-custom-loading-icon {
  opacity: 0;
  width: 0;
}

.next-btn.next-small > .next-btn-custom-loading-icon.show {
  width: 12px;
  margin-right: 8px;
  opacity: 1;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-btn.next-medium {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--btn-size-m-corner, 2px);
  -moz-border-radius: var(--btn-size-m-corner, 2px);
  border-radius: var(--btn-size-m-corner, 2px);
  padding: 0 16px;
  padding: 0 var(--btn-size-m-padding, 16px);
  height: 32px;
  height: var(--btn-size-m-height, 32px);
  font-size: 12px;
  font-size: var(--btn-size-m-font, 12px);
  border-width: 1px;
  border-width: var(--btn-size-m-border-width, 1px);
}

.next-btn.next-medium > .next-btn-icon.next-icon-first {
  margin-left: 0;
  margin-right: 8px;
}

.next-btn.next-medium > .next-btn-icon.next-icon-first .next-icon-remote,
.next-btn.next-medium > .next-btn-icon.next-icon-first:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn.next-medium > .next-btn-icon.next-icon-last {
  margin-left: 8px;
  margin-right: 0;
}

.next-btn.next-medium > .next-btn-icon.next-icon-alone .next-icon-remote,
.next-btn.next-medium > .next-btn-icon.next-icon-alone:before,
.next-btn.next-medium > .next-btn-icon.next-icon-last .next-icon-remote,
.next-btn.next-medium > .next-btn-icon.next-icon-last:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn.next-medium.next-btn-loading:before {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  left: 16px;
  left: var(--btn-size-m-padding, 16px);
  top: 50%;
  text-align: center;
  margin-right: 8px;
}

.next-btn.next-medium.next-btn-loading > .next-icon {
  display: none;
}

.next-btn.next-medium > .next-btn-custom-loading-icon {
  opacity: 0;
  width: 0;
}

.next-btn.next-medium > .next-btn-custom-loading-icon.show {
  width: 12px;
  margin-right: 8px;
  opacity: 1;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-btn.next-large {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--btn-size-l-corner, 2px);
  -moz-border-radius: var(--btn-size-l-corner, 2px);
  border-radius: var(--btn-size-l-corner, 2px);
  padding: 0 16px;
  padding: 0 var(--btn-size-l-padding, 16px);
  height: 36px;
  height: var(--btn-size-l-height, 36px);
  font-size: 14px;
  font-size: var(--btn-size-l-font, 14px);
  border-width: 1px;
  border-width: var(--btn-size-l-border-width, 1px);
}

.next-btn.next-large > .next-btn-icon.next-icon-first {
  margin-left: 0;
  margin-right: 8px;
}

.next-btn.next-large > .next-btn-icon.next-icon-first .next-icon-remote,
.next-btn.next-large > .next-btn-icon.next-icon-first:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-btn.next-large > .next-btn-icon.next-icon-last {
  margin-left: 8px;
  margin-right: 0;
}

.next-btn.next-large > .next-btn-icon.next-icon-alone .next-icon-remote,
.next-btn.next-large > .next-btn-icon.next-icon-alone:before,
.next-btn.next-large > .next-btn-icon.next-icon-last .next-icon-remote,
.next-btn.next-large > .next-btn-icon.next-icon-last:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-btn.next-large.next-btn-loading:before {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  left: 16px;
  left: var(--btn-size-l-padding, 16px);
  top: 50%;
  text-align: center;
  margin-right: 8px;
}

.next-btn.next-large.next-btn-loading > .next-icon {
  display: none;
}

.next-btn.next-large > .next-btn-custom-loading-icon {
  opacity: 0;
  width: 0;
}

.next-btn.next-large > .next-btn-custom-loading-icon.show {
  width: 16px;
  margin-right: 8px;
  opacity: 1;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-btn.next-btn-normal {
  border-style: solid;
  border-style: var(--btn-pure-normal-border-style, solid);
  background-color: #fff;
  background-color: var(--btn-pure-normal-bg, #fff);
  border-color: #c0c6cc;
  border-color: var(--btn-pure-normal-border-color, #c0c6cc);
}

.next-btn.next-btn-normal,
.next-btn.next-btn-normal.visited,
.next-btn.next-btn-normal:link,
.next-btn.next-btn-normal:visited {
  color: #333;
  color: var(--btn-pure-normal-color, #333);
}

.next-btn.next-btn-normal.hover,
.next-btn.next-btn-normal:focus,
.next-btn.next-btn-normal:hover {
  color: #333;
  color: var(--btn-pure-normal-color-hover, #333);
  background-color: #f7f9fa;
  background-color: var(--btn-pure-normal-bg-hover, #f7f9fa);
  border-color: #c0c6cc;
  border-color: var(--btn-pure-normal-border-color-hover, #c0c6cc);
  text-decoration: none;
}

.next-btn.next-btn-normal.active,
.next-btn.next-btn-normal:active {
  color: #333;
  color: var(--btn-pure-normal-color-active, #333);
  background-color: #eff3f8;
  background-color: var(--btn-pure-normal-bg-active, #eff3f8);
  border-color: #c0c6cc;
  border-color: var(--btn-pure-normal-border-color-active, #c0c6cc);
  text-decoration: none;
}

.next-btn.next-btn-primary {
  border-style: solid;
  border-style: var(--btn-pure-primary-border-style, solid);
  background-color: #0064c8;
  background-color: var(--btn-pure-primary-bg, #0064c8);
  border-color: transparent;
  border-color: var(--btn-pure-primary-border-color, transparent);
}

.next-btn.next-btn-primary,
.next-btn.next-btn-primary.visited,
.next-btn.next-btn-primary:link,
.next-btn.next-btn-primary:visited {
  color: #fff;
  color: var(--btn-pure-primary-color, #fff);
}

.next-btn.next-btn-primary.hover,
.next-btn.next-btn-primary:focus,
.next-btn.next-btn-primary:hover {
  color: #fff;
  color: var(--btn-pure-primary-color-hover, #fff);
  background-color: #0064c8;
  background-color: var(--btn-pure-primary-bg-hover, #0064c8);
  border-color: transparent;
  border-color: var(--btn-pure-primary-border-color-hover, transparent);
  text-decoration: none;
}

.next-btn.next-btn-primary.active,
.next-btn.next-btn-primary:active {
  color: #fff;
  color: var(--btn-pure-primary-color-active, #fff);
  background-color: #003c78;
  background-color: var(--btn-pure-primary-bg-active, #003c78);
  border-color: transparent;
  border-color: var(--btn-pure-primary-border-color-active, transparent);
  text-decoration: none;
}

.next-btn.next-btn-secondary {
  border-style: solid;
  border-style: var(--btn-pure-secondary-border-style, solid);
  background-color: transparent;
  background-color: var(--btn-pure-secondary-bg, transparent);
  border-color: #c0c6cc;
  border-color: var(--btn-pure-secondary-border-color, #c0c6cc);
}

.next-btn.next-btn-secondary,
.next-btn.next-btn-secondary.visited,
.next-btn.next-btn-secondary:link,
.next-btn.next-btn-secondary:visited {
  color: #333;
  color: var(--btn-pure-secondary-color, #333);
}

.next-btn.next-btn-secondary.hover,
.next-btn.next-btn-secondary:focus,
.next-btn.next-btn-secondary:hover {
  color: #333;
  color: var(--btn-pure-secondary-color-hover, #333);
  background-color: #f7f9fa;
  background-color: var(--btn-pure-secondary-bg-hover, #f7f9fa);
  border-color: #c0c6cc;
  border-color: var(--btn-pure-secondary-border-color-hover, #c0c6cc);
  text-decoration: none;
}

.next-btn.next-btn-secondary.active,
.next-btn.next-btn-secondary:active {
  color: #333;
  color: var(--btn-pure-secondary-color-active, #333);
  background-color: #eff3f8;
  background-color: var(--btn-pure-secondary-bg-active, #eff3f8);
  border-color: #c0c6cc;
  border-color: var(--btn-pure-secondary-border-color-active, #c0c6cc);
  text-decoration: none;
}

.next-btn.disabled,
.next-btn[disabled] {
  cursor: not-allowed;
}

.next-btn.disabled.next-btn-normal,
.next-btn[disabled].next-btn-normal {
  background-color: #fff;
  background-color: var(--btn-pure-normal-bg-disabled, #fff);
  border-color: #d1d5d9;
  border-color: var(--btn-pure-normal-border-color-disabled, #d1d5d9);
}

.next-btn.disabled.next-btn-normal,
.next-btn.disabled.next-btn-normal.visited,
.next-btn.disabled.next-btn-normal:link,
.next-btn.disabled.next-btn-normal:visited,
.next-btn[disabled].next-btn-normal,
.next-btn[disabled].next-btn-normal.visited,
.next-btn[disabled].next-btn-normal:link,
.next-btn[disabled].next-btn-normal:visited {
  color: #ccc;
  color: var(--btn-pure-normal-color-disabled, #ccc);
}

.next-btn.disabled.next-btn-normal.active,
.next-btn.disabled.next-btn-normal.hover,
.next-btn.disabled.next-btn-normal:active,
.next-btn.disabled.next-btn-normal:focus,
.next-btn.disabled.next-btn-normal:hover,
.next-btn[disabled].next-btn-normal.active,
.next-btn[disabled].next-btn-normal.hover,
.next-btn[disabled].next-btn-normal:active,
.next-btn[disabled].next-btn-normal:focus,
.next-btn[disabled].next-btn-normal:hover {
  color: #ccc;
  color: var(--btn-pure-normal-color-disabled, #ccc);
  background-color: #fff;
  background-color: var(--btn-pure-normal-bg-disabled, #fff);
  border-color: #d1d5d9;
  border-color: var(--btn-pure-normal-border-color-disabled, #d1d5d9);
  text-decoration: none;
}

.next-btn.disabled.next-btn-primary,
.next-btn[disabled].next-btn-primary {
  background-color: #d1d5d9;
  background-color: var(--btn-pure-primary-bg-disabled, #d1d5d9);
  border-color: transparent;
  border-color: var(--btn-pure-primary-border-color-disabled, transparent);
}

.next-btn.disabled.next-btn-primary,
.next-btn.disabled.next-btn-primary.visited,
.next-btn.disabled.next-btn-primary:link,
.next-btn.disabled.next-btn-primary:visited,
.next-btn[disabled].next-btn-primary,
.next-btn[disabled].next-btn-primary.visited,
.next-btn[disabled].next-btn-primary:link,
.next-btn[disabled].next-btn-primary:visited {
  color: #fff;
  color: var(--btn-pure-primary-color-disabled, #fff);
}

.next-btn.disabled.next-btn-primary.active,
.next-btn.disabled.next-btn-primary.hover,
.next-btn.disabled.next-btn-primary:active,
.next-btn.disabled.next-btn-primary:focus,
.next-btn.disabled.next-btn-primary:hover,
.next-btn[disabled].next-btn-primary.active,
.next-btn[disabled].next-btn-primary.hover,
.next-btn[disabled].next-btn-primary:active,
.next-btn[disabled].next-btn-primary:focus,
.next-btn[disabled].next-btn-primary:hover {
  color: #fff;
  color: var(--btn-pure-primary-color-disabled, #fff);
  background-color: #d1d5d9;
  background-color: var(--btn-pure-primary-bg-disabled, #d1d5d9);
  border-color: transparent;
  border-color: var(--btn-pure-primary-border-color-disabled, transparent);
  text-decoration: none;
}

.next-btn.disabled.next-btn-secondary,
.next-btn[disabled].next-btn-secondary {
  background-color: #fff;
  background-color: var(--btn-pure-secondary-bg-disabled, #fff);
  border-color: #d1d5d9;
  border-color: var(--btn-pure-secondary-border-color-disabled, #d1d5d9);
}

.next-btn.disabled.next-btn-secondary,
.next-btn.disabled.next-btn-secondary.visited,
.next-btn.disabled.next-btn-secondary:link,
.next-btn.disabled.next-btn-secondary:visited,
.next-btn[disabled].next-btn-secondary,
.next-btn[disabled].next-btn-secondary.visited,
.next-btn[disabled].next-btn-secondary:link,
.next-btn[disabled].next-btn-secondary:visited {
  color: #ccc;
  color: var(--btn-pure-secondary-color-disabled, #ccc);
}

.next-btn.disabled.next-btn-secondary.active,
.next-btn.disabled.next-btn-secondary.hover,
.next-btn.disabled.next-btn-secondary:active,
.next-btn.disabled.next-btn-secondary:focus,
.next-btn.disabled.next-btn-secondary:hover,
.next-btn[disabled].next-btn-secondary.active,
.next-btn[disabled].next-btn-secondary.hover,
.next-btn[disabled].next-btn-secondary:active,
.next-btn[disabled].next-btn-secondary:focus,
.next-btn[disabled].next-btn-secondary:hover {
  color: #ccc;
  color: var(--btn-pure-secondary-color-disabled, #ccc);
  background-color: #fff;
  background-color: var(--btn-pure-secondary-bg-disabled, #fff);
  border-color: #d1d5d9;
  border-color: var(--btn-pure-secondary-border-color-disabled, #d1d5d9);
  text-decoration: none;
}

.next-btn-warning {
  border-style: solid;
  border-style: var(--btn-warning-border-style, solid);
}

.next-btn-warning.next-btn-primary {
  background-color: #c80000;
  background-color: var(--btn-warning-primary-bg, #c80000);
  border-color: #f8efef;
  border-color: var(--btn-warning-primary-border-color, #f8efef);
}

.next-btn-warning.next-btn-primary,
.next-btn-warning.next-btn-primary.visited,
.next-btn-warning.next-btn-primary:link,
.next-btn-warning.next-btn-primary:visited {
  color: #fff;
  color: var(--btn-warning-primary-color, #fff);
}

.next-btn-warning.next-btn-primary.hover,
.next-btn-warning.next-btn-primary:focus,
.next-btn-warning.next-btn-primary:hover {
  color: #fff;
  color: var(--btn-warning-primary-color-hover, #fff);
  background-color: #c80000;
  background-color: var(--btn-warning-primary-bg-hover, #c80000);
  border-color: #f5e6e6;
  border-color: var(--btn-warning-primary-border-color-hover, #f5e6e6);
  text-decoration: none;
}

.next-btn-warning.next-btn-primary.active,
.next-btn-warning.next-btn-primary:active {
  color: #fff;
  color: var(--btn-warning-primary-color-active, #fff);
  background-color: #c80000;
  background-color: var(--btn-warning-primary-bg-active, #c80000);
  border-color: #f5e6e6;
  border-color: var(--btn-warning-primary-border-color-active, #f5e6e6);
  text-decoration: none;
}

.next-btn-warning.next-btn-primary.disabled,
.next-btn-warning.next-btn-primary[disabled] {
  background-color: #d1d5d9;
  background-color: var(--btn-warning-primary-bg-disabled, #d1d5d9);
  border-color: #fff;
  border-color: var(--btn-warning-primary-border-color-disabled, #fff);
}

.next-btn-warning.next-btn-primary.disabled,
.next-btn-warning.next-btn-primary.disabled.visited,
.next-btn-warning.next-btn-primary.disabled:link,
.next-btn-warning.next-btn-primary.disabled:visited,
.next-btn-warning.next-btn-primary[disabled],
.next-btn-warning.next-btn-primary[disabled].visited,
.next-btn-warning.next-btn-primary[disabled]:link,
.next-btn-warning.next-btn-primary[disabled]:visited {
  color: #fff;
  color: var(--btn-warning-primary-color-disabled, #fff);
}

.next-btn-warning.next-btn-primary.disabled.active,
.next-btn-warning.next-btn-primary.disabled.hover,
.next-btn-warning.next-btn-primary.disabled:active,
.next-btn-warning.next-btn-primary.disabled:focus,
.next-btn-warning.next-btn-primary.disabled:hover,
.next-btn-warning.next-btn-primary[disabled].active,
.next-btn-warning.next-btn-primary[disabled].hover,
.next-btn-warning.next-btn-primary[disabled]:active,
.next-btn-warning.next-btn-primary[disabled]:focus,
.next-btn-warning.next-btn-primary[disabled]:hover {
  color: #fff;
  color: var(--btn-warning-primary-color-disabled, #fff);
  background-color: #d1d5d9;
  background-color: var(--btn-warning-primary-bg-disabled, #d1d5d9);
  border-color: #fff;
  border-color: var(--btn-warning-primary-border-color-disabled, #fff);
  text-decoration: none;
}

.next-btn-warning.next-btn-normal {
  background-color: #fff;
  background-color: var(--btn-warning-normal-bg, #fff);
  border-color: #c80000;
  border-color: var(--btn-warning-normal-border-color, #c80000);
}

.next-btn-warning.next-btn-normal,
.next-btn-warning.next-btn-normal.visited,
.next-btn-warning.next-btn-normal:link,
.next-btn-warning.next-btn-normal:visited {
  color: #c80000;
  color: var(--btn-warning-normal-color, #c80000);
}

.next-btn-warning.next-btn-normal.hover,
.next-btn-warning.next-btn-normal:focus,
.next-btn-warning.next-btn-normal:hover {
  color: #c80000;
  color: var(--btn-warning-normal-color-hover, #c80000);
  background-color: #faf7f7;
  background-color: var(--btn-warning-normal-bg-hover, #faf7f7);
  border-color: #c80000;
  border-color: var(--btn-warning-normal-border-color-hover, #c80000);
  text-decoration: none;
}

.next-btn-warning.next-btn-normal.active,
.next-btn-warning.next-btn-normal:active {
  color: #c80000;
  color: var(--btn-warning-normal-color-active, #c80000);
  background-color: #faf7f7;
  background-color: var(--btn-warning-normal-bg-active, #faf7f7);
  border-color: #c80000;
  border-color: var(--btn-warning-normal-border-color-active, #c80000);
  text-decoration: none;
}

.next-btn-warning.next-btn-normal.disabled,
.next-btn-warning.next-btn-normal[disabled] {
  background-color: #fff;
  background-color: var(--btn-warning-normal-bg-disabled, #fff);
  border-color: #d1d5d9;
  border-color: var(--btn-warning-normal-border-color-disabled, #d1d5d9);
}

.next-btn-warning.next-btn-normal.disabled,
.next-btn-warning.next-btn-normal.disabled.visited,
.next-btn-warning.next-btn-normal.disabled:link,
.next-btn-warning.next-btn-normal.disabled:visited,
.next-btn-warning.next-btn-normal[disabled],
.next-btn-warning.next-btn-normal[disabled].visited,
.next-btn-warning.next-btn-normal[disabled]:link,
.next-btn-warning.next-btn-normal[disabled]:visited {
  color: #ccc;
  color: var(--btn-warning-normal-color-disabled, #ccc);
}

.next-btn-warning.next-btn-normal.disabled.active,
.next-btn-warning.next-btn-normal.disabled.hover,
.next-btn-warning.next-btn-normal.disabled:active,
.next-btn-warning.next-btn-normal.disabled:focus,
.next-btn-warning.next-btn-normal.disabled:hover,
.next-btn-warning.next-btn-normal[disabled].active,
.next-btn-warning.next-btn-normal[disabled].hover,
.next-btn-warning.next-btn-normal[disabled]:active,
.next-btn-warning.next-btn-normal[disabled]:focus,
.next-btn-warning.next-btn-normal[disabled]:hover {
  color: #ccc;
  color: var(--btn-warning-normal-color-disabled, #ccc);
  background-color: #fff;
  background-color: var(--btn-warning-normal-bg-disabled, #fff);
  border-color: #d1d5d9;
  border-color: var(--btn-warning-normal-border-color-disabled, #d1d5d9);
  text-decoration: none;
}

.next-btn-text {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.next-btn-text,
.next-btn-text.hover,
.next-btn-text:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-btn-text.next-btn-primary {
  background-color: transparent;
  border-color: transparent;
}

.next-btn-text.next-btn-primary,
.next-btn-text.next-btn-primary.visited,
.next-btn-text.next-btn-primary:link,
.next-btn-text.next-btn-primary:visited {
  color: #0064c8;
  color: var(--btn-text-primary-color, #0064c8);
}

.next-btn-text.next-btn-primary.active,
.next-btn-text.next-btn-primary.hover,
.next-btn-text.next-btn-primary:active,
.next-btn-text.next-btn-primary:focus,
.next-btn-text.next-btn-primary:hover {
  color: #0064c8;
  color: var(--btn-text-primary-color-hover, #0064c8);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-btn-text.next-btn-primary.disabled,
.next-btn-text.next-btn-primary[disabled] {
  background-color: transparent;
  border-color: transparent;
}

.next-btn-text.next-btn-primary.disabled,
.next-btn-text.next-btn-primary.disabled.visited,
.next-btn-text.next-btn-primary.disabled:link,
.next-btn-text.next-btn-primary.disabled:visited,
.next-btn-text.next-btn-primary[disabled],
.next-btn-text.next-btn-primary[disabled].visited,
.next-btn-text.next-btn-primary[disabled]:link,
.next-btn-text.next-btn-primary[disabled]:visited {
  color: #ccc;
  color: var(--btn-text-disabled-color, #ccc);
}

.next-btn-text.next-btn-primary.disabled.active,
.next-btn-text.next-btn-primary.disabled.hover,
.next-btn-text.next-btn-primary.disabled:active,
.next-btn-text.next-btn-primary.disabled:focus,
.next-btn-text.next-btn-primary.disabled:hover,
.next-btn-text.next-btn-primary[disabled].active,
.next-btn-text.next-btn-primary[disabled].hover,
.next-btn-text.next-btn-primary[disabled]:active,
.next-btn-text.next-btn-primary[disabled]:focus,
.next-btn-text.next-btn-primary[disabled]:hover {
  color: #ccc;
  color: var(--btn-text-disabled-color, #ccc);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-btn-text.next-btn-secondary {
  background-color: transparent;
  border-color: transparent;
}

.next-btn-text.next-btn-secondary,
.next-btn-text.next-btn-secondary.visited,
.next-btn-text.next-btn-secondary:link,
.next-btn-text.next-btn-secondary:visited {
  color: #333;
  color: var(--btn-text-secondary-color, #333);
}

.next-btn-text.next-btn-secondary.active,
.next-btn-text.next-btn-secondary.hover,
.next-btn-text.next-btn-secondary:active,
.next-btn-text.next-btn-secondary:focus,
.next-btn-text.next-btn-secondary:hover {
  color: #333;
  color: var(--btn-text-secondary-color-hover, #333);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-btn-text.next-btn-secondary.disabled,
.next-btn-text.next-btn-secondary[disabled] {
  background-color: transparent;
  border-color: transparent;
}

.next-btn-text.next-btn-secondary.disabled,
.next-btn-text.next-btn-secondary.disabled.visited,
.next-btn-text.next-btn-secondary.disabled:link,
.next-btn-text.next-btn-secondary.disabled:visited,
.next-btn-text.next-btn-secondary[disabled],
.next-btn-text.next-btn-secondary[disabled].visited,
.next-btn-text.next-btn-secondary[disabled]:link,
.next-btn-text.next-btn-secondary[disabled]:visited {
  color: #ccc;
  color: var(--btn-text-disabled-color, #ccc);
}

.next-btn-text.next-btn-secondary.disabled.active,
.next-btn-text.next-btn-secondary.disabled.hover,
.next-btn-text.next-btn-secondary.disabled:active,
.next-btn-text.next-btn-secondary.disabled:focus,
.next-btn-text.next-btn-secondary.disabled:hover,
.next-btn-text.next-btn-secondary[disabled].active,
.next-btn-text.next-btn-secondary[disabled].hover,
.next-btn-text.next-btn-secondary[disabled]:active,
.next-btn-text.next-btn-secondary[disabled]:focus,
.next-btn-text.next-btn-secondary[disabled]:hover {
  color: #ccc;
  color: var(--btn-text-disabled-color, #ccc);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-btn-text.next-btn-normal {
  background-color: transparent;
  border-color: transparent;
}

.next-btn-text.next-btn-normal,
.next-btn-text.next-btn-normal.visited,
.next-btn-text.next-btn-normal:link,
.next-btn-text.next-btn-normal:visited {
  color: #333;
  color: var(--btn-text-normal-color, #333);
}

.next-btn-text.next-btn-normal.active,
.next-btn-text.next-btn-normal.hover,
.next-btn-text.next-btn-normal:active,
.next-btn-text.next-btn-normal:focus,
.next-btn-text.next-btn-normal:hover {
  color: #0064c8;
  color: var(--btn-text-normal-color-hover, #0064c8);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-btn-text.next-btn-normal.disabled,
.next-btn-text.next-btn-normal[disabled] {
  background-color: transparent;
  border-color: transparent;
}

.next-btn-text.next-btn-normal.disabled,
.next-btn-text.next-btn-normal.disabled.visited,
.next-btn-text.next-btn-normal.disabled:link,
.next-btn-text.next-btn-normal.disabled:visited,
.next-btn-text.next-btn-normal[disabled],
.next-btn-text.next-btn-normal[disabled].visited,
.next-btn-text.next-btn-normal[disabled]:link,
.next-btn-text.next-btn-normal[disabled]:visited {
  color: #ccc;
  color: var(--btn-text-disabled-color, #ccc);
}

.next-btn-text.next-btn-normal.disabled.active,
.next-btn-text.next-btn-normal.disabled.hover,
.next-btn-text.next-btn-normal.disabled:active,
.next-btn-text.next-btn-normal.disabled:focus,
.next-btn-text.next-btn-normal.disabled:hover,
.next-btn-text.next-btn-normal[disabled].active,
.next-btn-text.next-btn-normal[disabled].hover,
.next-btn-text.next-btn-normal[disabled]:active,
.next-btn-text.next-btn-normal[disabled]:focus,
.next-btn-text.next-btn-normal[disabled]:hover {
  color: #ccc;
  color: var(--btn-text-disabled-color, #ccc);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-btn-text.next-large {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
  height: 24px;
  height: var(--btn-text-size-l-height, 24px);
  font-size: 14px;
  font-size: var(--btn-text-size-l-font, 14px);
  border-width: 0;
}

.next-btn-text.next-large > .next-btn-icon.next-icon-first {
  margin-left: 0;
  margin-right: 4px;
}

.next-btn-text.next-large > .next-btn-icon.next-icon-first .next-icon-remote,
.next-btn-text.next-large > .next-btn-icon.next-icon-first:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-btn-text.next-large > .next-btn-icon.next-icon-last {
  margin-left: 4px;
  margin-right: 0;
}

.next-btn-text.next-large > .next-btn-icon.next-icon-alone .next-icon-remote,
.next-btn-text.next-large > .next-btn-icon.next-icon-alone:before,
.next-btn-text.next-large > .next-btn-icon.next-icon-last .next-icon-remote,
.next-btn-text.next-large > .next-btn-icon.next-icon-last:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-btn-text.next-large.next-btn-loading:before {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  left: 0;
  top: 50%;
  text-align: center;
  margin-right: 4px;
}

.next-btn-text.next-large.next-btn-loading > .next-icon {
  display: none;
}

.next-btn-text.next-large > .next-btn-custom-loading-icon {
  opacity: 0;
  width: 0;
}

.next-btn-text.next-large > .next-btn-custom-loading-icon.show {
  width: 16px;
  margin-right: 4px;
  opacity: 1;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-btn-text.next-medium {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
  height: 20px;
  height: var(--btn-text-size-m-height, 20px);
  font-size: 12px;
  font-size: var(--btn-text-size-m-font, 12px);
  border-width: 0;
}

.next-btn-text.next-medium > .next-btn-icon.next-icon-first {
  margin-left: 0;
  margin-right: 4px;
}

.next-btn-text.next-medium > .next-btn-icon.next-icon-first .next-icon-remote,
.next-btn-text.next-medium > .next-btn-icon.next-icon-first:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn-text.next-medium > .next-btn-icon.next-icon-last {
  margin-left: 4px;
  margin-right: 0;
}

.next-btn-text.next-medium > .next-btn-icon.next-icon-alone .next-icon-remote,
.next-btn-text.next-medium > .next-btn-icon.next-icon-alone:before,
.next-btn-text.next-medium > .next-btn-icon.next-icon-last .next-icon-remote,
.next-btn-text.next-medium > .next-btn-icon.next-icon-last:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn-text.next-medium.next-btn-loading:before {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  left: 0;
  top: 50%;
  text-align: center;
  margin-right: 4px;
}

.next-btn-text.next-medium.next-btn-loading > .next-icon {
  display: none;
}

.next-btn-text.next-medium > .next-btn-custom-loading-icon {
  opacity: 0;
  width: 0;
}

.next-btn-text.next-medium > .next-btn-custom-loading-icon.show {
  width: 12px;
  margin-right: 4px;
  opacity: 1;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-btn-text.next-small {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
  height: 16px;
  height: var(--btn-text-size-s-height, 16px);
  font-size: 12px;
  font-size: var(--btn-text-size-s-font, 12px);
  border-width: 0;
}

.next-btn-text.next-small > .next-btn-icon.next-icon-first {
  margin-left: 0;
  margin-right: 4px;
}

.next-btn-text.next-small > .next-btn-icon.next-icon-first .next-icon-remote,
.next-btn-text.next-small > .next-btn-icon.next-icon-first:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn-text.next-small > .next-btn-icon.next-icon-last {
  margin-left: 4px;
  margin-right: 0;
}

.next-btn-text.next-small > .next-btn-icon.next-icon-alone .next-icon-remote,
.next-btn-text.next-small > .next-btn-icon.next-icon-alone:before,
.next-btn-text.next-small > .next-btn-icon.next-icon-last .next-icon-remote,
.next-btn-text.next-small > .next-btn-icon.next-icon-last:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn-text.next-small.next-btn-loading:before {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  left: 0;
  top: 50%;
  text-align: center;
  margin-right: 4px;
}

.next-btn-text.next-small.next-btn-loading > .next-icon {
  display: none;
}

.next-btn-text.next-small > .next-btn-custom-loading-icon {
  opacity: 0;
  width: 0;
}

.next-btn-text.next-small > .next-btn-custom-loading-icon.show {
  width: 12px;
  margin-right: 4px;
  opacity: 1;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-btn-text.next-btn-loading {
  background-color: transparent;
  border-color: transparent;
}

.next-btn-text.next-btn-loading,
.next-btn-text.next-btn-loading.visited,
.next-btn-text.next-btn-loading:link,
.next-btn-text.next-btn-loading:visited {
  color: #111;
  color: var(--btn-text-loading-color, #111);
}

.next-btn-text.next-btn-loading.active,
.next-btn-text.next-btn-loading.hover,
.next-btn-text.next-btn-loading:active,
.next-btn-text.next-btn-loading:focus,
.next-btn-text.next-btn-loading:hover {
  color: #111;
  color: var(--btn-text-loading-color, #111);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-btn-loading {
  pointer-events: none;
}

.next-btn-loading:before {
  font-family: NextIcon;
  content: "\E646";
  content: var(--icon-content-loading, "\E646");
  opacity: 1;
  visibility: visible;
  -webkit-animation: loadingCircle 2s linear infinite;
  -moz-animation: loadingCircle 2s infinite linear;
  -o-animation: loadingCircle 2s infinite linear;
  animation: loadingCircle 2s linear infinite;
}

.next-btn-loading:after {
  content: "";
  display: inline-block;
  position: static;
  height: 100%;
  width: 0;
  vertical-align: middle;
}

.next-btn-custom-loading {
  pointer-events: none;
}

.next-btn-ghost {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--btn-ghost-border-style, solid);
}

.next-btn-ghost.next-btn-dark {
  background-color: rgba(transparent, 0);
  background-color: rgba(var(--btn-ghost-dark-bg, transparent), 0);
  border-color: #fff;
  border-color: var(--btn-ghost-dark-border-color, #fff);
}

.next-btn-ghost.next-btn-dark,
.next-btn-ghost.next-btn-dark.visited,
.next-btn-ghost.next-btn-dark:link,
.next-btn-ghost.next-btn-dark:visited {
  color: #fff;
  color: var(--btn-ghost-dark-color, #fff);
}

.next-btn-ghost.next-btn-dark.active,
.next-btn-ghost.next-btn-dark.hover,
.next-btn-ghost.next-btn-dark:active,
.next-btn-ghost.next-btn-dark:focus,
.next-btn-ghost.next-btn-dark:hover {
  color: #fff;
  color: var(--btn-ghost-dark-color-hover, #fff);
  background-color: rgba(#ffffff, 0.29);
  background-color: rgba(
    var(--btn-ghost-dark-bg-hover-rgb, #ffffff),
    var(--btn-ghost-dark-bg-hover-opacity, 0.29)
  );
  border-color: #fff;
  border-color: var(--btn-ghost-dark-border-color-hover, #fff);
  text-decoration: none;
}

.next-btn-ghost.next-btn-dark.disabled,
.next-btn-ghost.next-btn-dark[disabled] {
  background-color: transparent;
  background-color: var(--btn-ghost-dark-bg-disabled, transparent);
  border-color: rgba(#ffffff, 0.15);
  border-color: rgba(
    var(--btn-ghost-dark-border-color-disabled-rgb, #ffffff),
    var(--btn-ghost-dark-border-color-disabled-opacity, 0.15)
  );
}

.next-btn-ghost.next-btn-dark.disabled,
.next-btn-ghost.next-btn-dark.disabled.visited,
.next-btn-ghost.next-btn-dark.disabled:link,
.next-btn-ghost.next-btn-dark.disabled:visited,
.next-btn-ghost.next-btn-dark[disabled],
.next-btn-ghost.next-btn-dark[disabled].visited,
.next-btn-ghost.next-btn-dark[disabled]:link,
.next-btn-ghost.next-btn-dark[disabled]:visited {
  color: rgba(#ffffff, 0.25);
  color: rgba(
    var(--btn-ghost-dark-color-disabled-rgb, #ffffff),
    var(--btn-ghost-dark-color-disabled-opacity, 0.25)
  );
}

.next-btn-ghost.next-btn-dark.disabled.active,
.next-btn-ghost.next-btn-dark.disabled.hover,
.next-btn-ghost.next-btn-dark.disabled:active,
.next-btn-ghost.next-btn-dark.disabled:focus,
.next-btn-ghost.next-btn-dark.disabled:hover,
.next-btn-ghost.next-btn-dark[disabled].active,
.next-btn-ghost.next-btn-dark[disabled].hover,
.next-btn-ghost.next-btn-dark[disabled]:active,
.next-btn-ghost.next-btn-dark[disabled]:focus,
.next-btn-ghost.next-btn-dark[disabled]:hover {
  color: rgba(#ffffff, 0.25);
  color: rgba(
    var(--btn-ghost-dark-color-disabled-rgb, #ffffff),
    var(--btn-ghost-dark-color-disabled-opacity, 0.25)
  );
  background-color: transparent;
  background-color: var(--btn-ghost-dark-bg-disabled, transparent);
  border-color: rgba(#ffffff, 0.15);
  border-color: rgba(
    var(--btn-ghost-dark-border-color-disabled-rgb, #ffffff),
    var(--btn-ghost-dark-border-color-disabled-opacity, 0.15)
  );
  text-decoration: none;
}

.next-btn-ghost.next-btn-light {
  background-color: rgba(#ffffff, 1);
  background-color: rgba(
    var(--btn-ghost-light-bg, #ffffff),
    var(--btn-ghost-light-bg-opacity, 1)
  );
  border-color: #0064c8;
  border-color: var(--btn-ghost-light-border-color, #0064c8);
}

.next-btn-ghost.next-btn-light,
.next-btn-ghost.next-btn-light.visited,
.next-btn-ghost.next-btn-light:link,
.next-btn-ghost.next-btn-light:visited {
  color: #0064c8;
  color: var(--btn-ghost-light-color, #0064c8);
}

.next-btn-ghost.next-btn-light.active,
.next-btn-ghost.next-btn-light.hover,
.next-btn-ghost.next-btn-light:active,
.next-btn-ghost.next-btn-light:focus,
.next-btn-ghost.next-btn-light:hover {
  color: #fff;
  color: var(--btn-ghost-light-color-hover, #fff);
  background-color: rgba(#0050a0, 1);
  background-color: rgba(
    var(--btn-ghost-light-bg-hover-rgb, #0050a0),
    var(--btn-ghost-light-bg-hover-opacity, 1)
  );
  border-color: transparent;
  border-color: var(--btn-ghost-light-border-color-hover, transparent);
  text-decoration: none;
}

.next-btn-ghost.next-btn-light.disabled,
.next-btn-ghost.next-btn-light[disabled] {
  background-color: #f7f9fa;
  background-color: var(--btn-ghost-light-bg-disabled, #f7f9fa);
  border-color: rgba(#ffffff, 1);
  border-color: rgba(
    var(--btn-ghost-light-border-color-disabled-rgb, #ffffff),
    var(--btn-ghost-light-border-color-disabled-opacity, 1)
  );
}

.next-btn-ghost.next-btn-light.disabled,
.next-btn-ghost.next-btn-light.disabled.visited,
.next-btn-ghost.next-btn-light.disabled:link,
.next-btn-ghost.next-btn-light.disabled:visited,
.next-btn-ghost.next-btn-light[disabled],
.next-btn-ghost.next-btn-light[disabled].visited,
.next-btn-ghost.next-btn-light[disabled]:link,
.next-btn-ghost.next-btn-light[disabled]:visited {
  color: rgba(#c1c1c1, 1);
  color: rgba(
    var(--btn-ghost-light-color-disabled-rgb, #c1c1c1),
    var(--btn-ghost-light-color-disabled-opacity, 1)
  );
}

.next-btn-ghost.next-btn-light.disabled.active,
.next-btn-ghost.next-btn-light.disabled.hover,
.next-btn-ghost.next-btn-light.disabled:active,
.next-btn-ghost.next-btn-light.disabled:focus,
.next-btn-ghost.next-btn-light.disabled:hover,
.next-btn-ghost.next-btn-light[disabled].active,
.next-btn-ghost.next-btn-light[disabled].hover,
.next-btn-ghost.next-btn-light[disabled]:active,
.next-btn-ghost.next-btn-light[disabled]:focus,
.next-btn-ghost.next-btn-light[disabled]:hover {
  color: rgba(#c1c1c1, 1);
  color: rgba(
    var(--btn-ghost-light-color-disabled-rgb, #c1c1c1),
    var(--btn-ghost-light-color-disabled-opacity, 1)
  );
  background-color: #f7f9fa;
  background-color: var(--btn-ghost-light-bg-disabled, #f7f9fa);
  border-color: rgba(#ffffff, 1);
  border-color: rgba(
    var(--btn-ghost-light-border-color-disabled-rgb, #ffffff),
    var(--btn-ghost-light-border-color-disabled-opacity, 1)
  );
  text-decoration: none;
}

.next-btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.next-btn-group > .next-btn {
  position: relative;
  float: left;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-btn-group > .next-btn.active,
.next-btn-group > .next-btn:active,
.next-btn-group > .next-btn:focus,
.next-btn-group > .next-btn:hover {
  z-index: 1;
}

.next-btn-group > .next-btn.disabled,
.next-btn-group > .next-btn[disabled] {
  z-index: 0;
}

.next-btn-group .next-btn.next-btn {
  margin: 0 0 0 -1px;
}

.next-btn-group .next-btn:not(:first-child):not(:last-child) {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.next-btn-group > .next-btn:first-child {
  margin: 0;
}

.next-btn-group > .next-btn:first-child:not(:last-child) {
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
}

.next-btn-group > .next-btn:last-child:not(:first-child) {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
}

.next-btn-group > .next-btn-primary:not(:first-child) {
  border-left-color: hsla(0, 0%, 100%, 0.2);
}

.next-btn-group > .next-btn-primary:not(:first-child):hover {
  border-left-color: transparent;
}

.next-btn-group > .next-btn-primary:not(:first-child).disabled,
.next-btn-group > .next-btn-primary:not(:first-child)[disabled] {
  border-left-color: #e3e4e6;
  border-left-color: var(--color-line1-1, #e3e4e6);
}

.next-btn-group[dir="rtl"] > .next-btn {
  float: right;
}

.next-btn-group[dir="rtl"] .next-btn.next-btn {
  margin: 0 -1px 0 0;
}

.next-btn-group[dir="rtl"] > .next-btn:first-child:not(:last-child) {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
}

.next-btn-group[dir="rtl"] > .next-btn:last-child:not(:first-child) {
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
}

.next-btn-group[dir="rtl"] > .next-btn-primary:not(:first-child) {
  border-right-color: hsla(0, 0%, 100%, 0.2);
}

.next-btn-group[dir="rtl"] > .next-btn-primary:not(:first-child):hover {
  border-right-color: transparent;
}

.next-btn-group[dir="rtl"] > .next-btn-primary:not(:first-child).disabled,
.next-btn-group[dir="rtl"] > .next-btn-primary:not(:first-child)[disabled] {
  border-right-color: #e3e4e6;
  border-right-color: var(--color-line1-1, #e3e4e6);
}

.next-btn.next-small[dir="rtl"] {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--btn-size-s-corner, 2px);
  -moz-border-radius: var(--btn-size-s-corner, 2px);
  border-radius: var(--btn-size-s-corner, 2px);
}

.next-btn.next-small[dir="rtl"] > .next-btn-icon.next-icon-first {
  margin-left: 8px;
  margin-right: 0;
}

.next-btn.next-small[dir="rtl"]
  > .next-btn-icon.next-icon-first
  .next-icon-remote,
.next-btn.next-small[dir="rtl"] > .next-btn-icon.next-icon-first:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn.next-small[dir="rtl"] > .next-btn-icon.next-icon-last {
  margin-left: 0;
  margin-right: 8px;
}

.next-btn.next-small[dir="rtl"]
  > .next-btn-icon.next-icon-last
  .next-icon-remote,
.next-btn.next-small[dir="rtl"] > .next-btn-icon.next-icon-last:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn.next-small[dir="rtl"].next-btn-loading {
  padding-left: 8px;
  padding-left: var(--btn-size-s-padding, 8px);
  padding-right: 28px;
  padding-right: calc(var(--btn-size-s-padding, 8px) + 20px);
}

.next-btn.next-small[dir="rtl"].next-btn-loading:after {
  right: 8px;
  right: var(--btn-size-s-padding, 8px);
  top: 50%;
  margin-right: 0;
  margin-left: 8px;
}

.next-btn.next-medium[dir="rtl"] {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--btn-size-m-corner, 2px);
  -moz-border-radius: var(--btn-size-m-corner, 2px);
  border-radius: var(--btn-size-m-corner, 2px);
}

.next-btn.next-medium[dir="rtl"] > .next-btn-icon.next-icon-first {
  margin-left: 8px;
  margin-right: 0;
}

.next-btn.next-medium[dir="rtl"]
  > .next-btn-icon.next-icon-first
  .next-icon-remote,
.next-btn.next-medium[dir="rtl"] > .next-btn-icon.next-icon-first:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn.next-medium[dir="rtl"] > .next-btn-icon.next-icon-last {
  margin-left: 0;
  margin-right: 8px;
}

.next-btn.next-medium[dir="rtl"]
  > .next-btn-icon.next-icon-last
  .next-icon-remote,
.next-btn.next-medium[dir="rtl"] > .next-btn-icon.next-icon-last:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn.next-medium[dir="rtl"].next-btn-loading {
  padding-left: 16px;
  padding-left: var(--btn-size-m-padding, 16px);
  padding-right: 36px;
  padding-right: calc(var(--btn-size-m-padding, 16px) + 20px);
}

.next-btn.next-medium[dir="rtl"].next-btn-loading:after {
  right: 16px;
  right: var(--btn-size-m-padding, 16px);
  top: 50%;
  margin-right: 0;
  margin-left: 8px;
}

.next-btn.next-large[dir="rtl"] {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--btn-size-l-corner, 2px);
  -moz-border-radius: var(--btn-size-l-corner, 2px);
  border-radius: var(--btn-size-l-corner, 2px);
}

.next-btn.next-large[dir="rtl"] > .next-btn-icon.next-icon-first {
  margin-left: 8px;
  margin-right: 0;
}

.next-btn.next-large[dir="rtl"]
  > .next-btn-icon.next-icon-first
  .next-icon-remote,
.next-btn.next-large[dir="rtl"] > .next-btn-icon.next-icon-first:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-btn.next-large[dir="rtl"] > .next-btn-icon.next-icon-last {
  margin-left: 0;
  margin-right: 8px;
}

.next-btn.next-large[dir="rtl"]
  > .next-btn-icon.next-icon-last
  .next-icon-remote,
.next-btn.next-large[dir="rtl"] > .next-btn-icon.next-icon-last:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-btn.next-large[dir="rtl"].next-btn-loading {
  padding-left: 16px;
  padding-left: var(--btn-size-l-padding, 16px);
  padding-right: 40px;
  padding-right: calc(var(--btn-size-l-padding, 16px) + 24px);
}

.next-btn.next-large[dir="rtl"].next-btn-loading:after {
  right: 16px;
  right: var(--btn-size-l-padding, 16px);
  top: 50%;
  margin-right: 0;
  margin-left: 8px;
}

.next-btn-text[dir="rtl"].next-large {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.next-btn-text[dir="rtl"].next-large > .next-btn-icon.next-icon-first {
  margin-left: 4px;
  margin-right: 0;
}

.next-btn-text[dir="rtl"].next-large
  > .next-btn-icon.next-icon-first
  .next-icon-remote,
.next-btn-text[dir="rtl"].next-large > .next-btn-icon.next-icon-first:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-btn-text[dir="rtl"].next-large > .next-btn-icon.next-icon-last {
  margin-left: 0;
  margin-right: 4px;
}

.next-btn-text[dir="rtl"].next-large
  > .next-btn-icon.next-icon-last
  .next-icon-remote,
.next-btn-text[dir="rtl"].next-large > .next-btn-icon.next-icon-last:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-btn-text[dir="rtl"].next-large.next-btn-loading {
  padding-left: 0;
  padding-right: 20px;
}

.next-btn-text[dir="rtl"].next-large.next-btn-loading:after {
  right: 0;
  top: 50%;
  margin-right: 0;
  margin-left: 4px;
}

.next-btn-text[dir="rtl"].next-medium {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.next-btn-text[dir="rtl"].next-medium > .next-btn-icon.next-icon-first {
  margin-left: 4px;
  margin-right: 0;
}

.next-btn-text[dir="rtl"].next-medium
  > .next-btn-icon.next-icon-first
  .next-icon-remote,
.next-btn-text[dir="rtl"].next-medium > .next-btn-icon.next-icon-first:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn-text[dir="rtl"].next-medium > .next-btn-icon.next-icon-last {
  margin-left: 0;
  margin-right: 4px;
}

.next-btn-text[dir="rtl"].next-medium
  > .next-btn-icon.next-icon-last
  .next-icon-remote,
.next-btn-text[dir="rtl"].next-medium > .next-btn-icon.next-icon-last:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn-text[dir="rtl"].next-medium.next-btn-loading {
  padding-left: 0;
  padding-right: 16px;
}

.next-btn-text[dir="rtl"].next-medium.next-btn-loading:after {
  right: 0;
  top: 50%;
  margin-right: 0;
  margin-left: 4px;
}

.next-btn-text[dir="rtl"].next-small {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.next-btn-text[dir="rtl"].next-small > .next-btn-icon.next-icon-first {
  margin-left: 4px;
  margin-right: 0;
}

.next-btn-text[dir="rtl"].next-small
  > .next-btn-icon.next-icon-first
  .next-icon-remote,
.next-btn-text[dir="rtl"].next-small > .next-btn-icon.next-icon-first:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn-text[dir="rtl"].next-small > .next-btn-icon.next-icon-last {
  margin-left: 0;
  margin-right: 4px;
}

.next-btn-text[dir="rtl"].next-small
  > .next-btn-icon.next-icon-last
  .next-icon-remote,
.next-btn-text[dir="rtl"].next-small > .next-btn-icon.next-icon-last:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-btn-text[dir="rtl"].next-small.next-btn-loading {
  padding-left: 0;
  padding-right: 16px;
}

.next-btn-text[dir="rtl"].next-small.next-btn-loading:after {
  right: 0;
  top: 50%;
  margin-right: 0;
  margin-left: 4px;
}

.next-radio-button-large[dir="rtl"] > label:first-child {
  margin-left: -1px;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--radio-button-corner-large, 2px);
  -moz-border-radius-topright: var(--radio-button-corner-large, 2px);
  border-top-right-radius: var(--radio-button-corner-large, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--radio-button-corner-large, 2px);
  -moz-border-radius-bottomright: var(--radio-button-corner-large, 2px);
  border-bottom-right-radius: var(--radio-button-corner-large, 2px);
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}

.next-radio-button-large[dir="rtl"] > label:last-child {
  margin-left: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--radio-button-corner-large, 2px);
  -moz-border-radius-topleft: var(--radio-button-corner-large, 2px);
  border-top-left-radius: var(--radio-button-corner-large, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--radio-button-corner-large, 2px);
  -moz-border-radius-bottomleft: var(--radio-button-corner-large, 2px);
  border-bottom-left-radius: var(--radio-button-corner-large, 2px);
}

.next-radio-button-large[dir="rtl"] .next-radio-label {
  height: 34px;
  height: -webkit-calc(var(--radio-button-height-large, 36px) - 2px);
  height: -moz-calc(var(--radio-button-height-large, 36px) - 2px);
  height: calc(var(--radio-button-height-large, 36px) - 2px);
  line-height: 34px;
  line-height: -webkit-calc(var(--radio-button-height-large, 36px) - 2px);
  line-height: -moz-calc(var(--radio-button-height-large, 36px) - 2px);
  line-height: calc(var(--radio-button-height-large, 36px) - 2px);
  font-size: 14px;
  font-size: var(--radio-button-font-size-large, 14px);
}

.next-radio-button-medium[dir="rtl"] > label:first-child {
  margin-left: -1px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--radio-button-corner-medium, 2px);
  -moz-border-radius-topright: var(--radio-button-corner-medium, 2px);
  border-top-right-radius: var(--radio-button-corner-medium, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--radio-button-corner-medium, 2px);
  -moz-border-radius-bottomright: var(--radio-button-corner-medium, 2px);
  border-bottom-right-radius: var(--radio-button-corner-medium, 2px);
}

.next-radio-button-medium[dir="rtl"] > label:last-child {
  margin-left: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--radio-button-corner-medium, 2px);
  -moz-border-radius-topleft: var(--radio-button-corner-medium, 2px);
  border-top-left-radius: var(--radio-button-corner-medium, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--radio-button-corner-medium, 2px);
  -moz-border-radius-bottomleft: var(--radio-button-corner-medium, 2px);
  border-bottom-left-radius: var(--radio-button-corner-medium, 2px);
}

.next-radio-button-small[dir="rtl"] > label:first-child {
  margin-left: -1px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--radio-button-corner-small, 2px);
  -moz-border-radius-topright: var(--radio-button-corner-small, 2px);
  border-top-right-radius: var(--radio-button-corner-small, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--radio-button-corner-small, 2px);
  -moz-border-radius-bottomright: var(--radio-button-corner-small, 2px);
  border-bottom-right-radius: var(--radio-button-corner-small, 2px);
}

.next-radio-button-small[dir="rtl"] > label:last-child {
  margin-left: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--radio-button-corner-small, 2px);
  -moz-border-radius-topleft: var(--radio-button-corner-small, 2px);
  border-top-left-radius: var(--radio-button-corner-small, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--radio-button-corner-small, 2px);
  -moz-border-radius-bottomleft: var(--radio-button-corner-small, 2px);
  border-bottom-left-radius: var(--radio-button-corner-small, 2px);
}

.next-radio-wrapper[dir="rtl"] .next-radio-label {
  margin-left: 0;
  margin-right: 4px;
}

.next-radio-group[dir="rtl"] .next-radio-label {
  margin-right: 4px;
  margin-left: 16px;
}

.next-radio-button[dir="rtl"] > label .next-radio-label {
  margin: 0;
}

.next-radio-wrapper {
  outline: 0;
}

.next-radio-wrapper .next-radio {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  line-height: 1;
}

.next-radio-wrapper .next-radio,
.next-radio-wrapper .next-radio *,
.next-radio-wrapper .next-radio :after,
.next-radio-wrapper .next-radio :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-radio-wrapper .next-radio input[type="radio"] {
  opacity: 0;
  position: absolute;
  vertical-align: middle;
  top: 0;
  left: 0;
  width: 16px;
  width: var(--radio-width, 16px);
  height: 16px;
  height: var(--radio-width, 16px);
  margin: 0;
  cursor: pointer;
}

.next-radio-wrapper .next-radio-inner {
  display: block;
  width: 16px;
  width: var(--radio-width, 16px);
  height: 16px;
  height: var(--radio-width, 16px);
  background: #fff;
  background: var(--radio-bg-color, #fff);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--radio-radius-size, 50%);
  -moz-border-radius: var(--radio-radius-size, 50%);
  border-radius: var(--radio-radius-size, 50%);
  border: 1px solid #577899;
  border: var(--radio-circle-border-width, 1px) solid
    var(--radio-border-color, #577899);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--radio-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--radio-shadow, none);
  box-shadow: var(--radio-shadow, none);
}

.next-radio-wrapper .next-radio-inner,
.next-radio-wrapper .next-radio-inner:after {
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-radio-wrapper .next-radio-inner:after {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--radio-radius-size, 50%);
  -moz-border-radius: var(--radio-radius-size, 50%);
  border-radius: var(--radio-radius-size, 50%);
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--radio-circle-size, 8px) / 2);
  left: 50%;
  margin-left: -4px;
  margin-left: calc(0px - var(--radio-circle-size, 8px) / 2);
  background: #fff;
  content: "";
}

.next-radio-wrapper.checked .next-radio-inner {
  border-color: #004080;
  border-color: var(--radio-checked-border-color, #004080);
  background: #fff;
  background: var(--radio-checked-bg-color, #fff);
}

.next-radio-wrapper.checked .next-radio-inner:after {
  width: 8px;
  width: var(--radio-circle-size, 8px);
  height: 8px;
  height: var(--radio-circle-size, 8px);
  font-weight: 700;
  background: #0064c8;
  background: var(--radio-checked-circle-color, #0064c8);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.next-radio-wrapper.checked.hovered .next-radio-inner,
.next-radio-wrapper.checked:hover .next-radio-inner {
  border-color: #004080;
  border-color: var(--radio-checked-hovered-border-color, #004080);
}

.next-radio-wrapper.disabled input[type="radio"] {
  cursor: not-allowed;
}

.next-radio-wrapper.disabled .next-radio-inner {
  border-color: #c0c6cc;
  border-color: var(--radio-disabled-border-color, #c0c6cc);
  background: #f7f9fa;
  background: var(--radio-disabled-bg-color, #f7f9fa);
}

.next-radio-wrapper.disabled .next-radio-inner:after {
  background: #ccc;
  background: var(--radio-disabled-circle-color, #ccc);
}

.next-radio-wrapper.disabled .next-radio-inner.hovered,
.next-radio-wrapper.disabled .next-radio-inner:hover {
  border-color: #c0c6cc;
  border-color: var(--radio-disabled-border-color, #c0c6cc);
}

.next-radio-wrapper.disabled.checked .next-radio-inner {
  border-color: #c0c6cc;
  border-color: var(--radio-checked-disabled-border-color, #c0c6cc);
  background: #f7f9fa;
  background: var(--radio-checked-disabled-bg-color, #f7f9fa);
}

.next-radio-wrapper.disabled.checked .next-radio-inner:after {
  background: #ccc;
  background: var(--radio-checked-disabled-circle-color, #ccc);
}

.next-radio-wrapper.disabled .next-radio-label {
  color: #ccc;
  color: var(--radio-normal-font-color-disabled, #ccc);
}

.next-radio-wrapper:not(.disabled).hovered .next-radio-inner,
.next-radio-wrapper:not(.disabled):hover .next-radio-inner {
  border-color: #004080;
  border-color: var(--radio-hovered-border-color, #004080);
  background-color: transparent;
  background-color: var(--radio-hovered-bg-color, transparent);
}

.next-radio-wrapper:not(.disabled).hovered .next-radio-label,
.next-radio-wrapper:not(.disabled):hover .next-radio-label {
  cursor: pointer;
}

.next-radio-wrapper.checked:not(.disabled).hovered .next-radio-inner,
.next-radio-wrapper.checked:not(.disabled):hover .next-radio-inner {
  border-color: #004080;
  border-color: var(--radio-checked-hovered-border-color, #004080);
  background: #fff;
  background: var(--radio-checked-hovered-bg-color, #fff);
}

.next-radio-wrapper.checked:not(.disabled).hovered .next-radio-inner:after,
.next-radio-wrapper.checked:not(.disabled):hover .next-radio-inner:after {
  background: #0064c8;
  background: var(--radio-checked-hovered-circle-color, #0064c8);
}

.next-radio-button .next-radio,
.next-radio-button input[type="radio"] {
  width: 0;
  height: 0;
}

.next-radio-button > label {
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  margin: 0 0 0 -1px;
  border: 1px solid #c0c6cc;
  border: 1px solid var(--radio-button-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--radio-button-bg-color, #fff);
  vertical-align: middle;
}

.next-radio-button > label,
.next-radio-button > label .next-radio-label {
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-radio-button > label .next-radio-label {
  display: block;
  color: #555;
  color: var(--radio-button-font-color, #555);
  margin: 0;
}

.next-radio-button > label.hovered,
.next-radio-button > label:hover {
  z-index: 10;
  border-color: #d1d5d9;
  border-color: var(--radio-button-border-color-hovered, #d1d5d9);
  background-color: #fff;
  background-color: var(--radio-button-bg-color-hovered, #fff);
}

.next-radio-button > label.hovered .next-radio-label,
.next-radio-button > label:hover .next-radio-label {
  color: #111;
  color: var(--radio-button-font-color-hovered, #111);
}

.next-radio-button > label.checked {
  z-index: 11;
  border-color: #0064c8;
  border-color: var(--radio-button-border-color-checked, #0064c8);
  background-color: #fff;
  background-color: var(--radio-button-bg-color-checked, #fff);
}

.next-radio-button > label.checked .next-radio-label {
  color: #0064c8;
  color: var(--radio-button-font-color-checked, #0064c8);
}

.next-radio-button > label.disabled {
  z-index: 0;
  cursor: not-allowed;
  border-color: #e3e4e6;
  border-color: var(--radio-button-border-color-disabled, #e3e4e6);
  background-color: #f8f8f9;
  background-color: var(--radio-button-bg-color-disabled, #f8f8f9);
}

.next-radio-button > label.disabled .next-radio-label {
  color: #c1c1c1;
  color: var(--radio-button-font-color-disabled, #c1c1c1);
}

.next-radio-button > label.checked.disabled {
  z-index: 0;
  border-color: #e3e4e6;
  border-color: var(--radio-button-border-color-checked-disabled, #e3e4e6);
  background-color: #004080;
  background-color: var(--radio-button-bg-color-checked-disabled, #004080);
}

.next-radio-button > label.checked.disabled .next-radio-label {
  color: #c1c1c1;
  color: var(--radio-button-font-color-checked-disabled, #c1c1c1);
}

.next-radio-button-large > label {
  padding: 0 8px;
  padding: 0 var(--radio-button-padding-large, 8px);
  height: 36px;
  height: var(--radio-button-height-large, 36px);
  line-height: 36px;
  line-height: var(--radio-button-height-large, 36px);
}

.next-radio-button-large > label:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--radio-button-corner-large, 2px);
  -moz-border-radius-topleft: var(--radio-button-corner-large, 2px);
  border-top-left-radius: var(--radio-button-corner-large, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--radio-button-corner-large, 2px);
  -moz-border-radius-bottomleft: var(--radio-button-corner-large, 2px);
  border-bottom-left-radius: var(--radio-button-corner-large, 2px);
}

.next-radio-button-large > label:last-child {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--radio-button-corner-large, 2px);
  -moz-border-radius-topright: var(--radio-button-corner-large, 2px);
  border-top-right-radius: var(--radio-button-corner-large, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--radio-button-corner-large, 2px);
  -moz-border-radius-bottomright: var(--radio-button-corner-large, 2px);
  border-bottom-right-radius: var(--radio-button-corner-large, 2px);
}

.next-radio-button-large .next-radio-label {
  height: 34px;
  height: -webkit-calc(var(--radio-button-height-large, 36px) - 2px);
  height: -moz-calc(var(--radio-button-height-large, 36px) - 2px);
  height: calc(var(--radio-button-height-large, 36px) - 2px);
  line-height: 34px;
  line-height: -webkit-calc(var(--radio-button-height-large, 36px) - 2px);
  line-height: -moz-calc(var(--radio-button-height-large, 36px) - 2px);
  line-height: calc(var(--radio-button-height-large, 36px) - 2px);
  font-size: 14px;
  font-size: var(--radio-button-font-size-large, 14px);
}

.next-radio-button-medium > label {
  padding: 0 8px;
  padding: 0 var(--radio-button-padding-medium, 8px);
  height: 32px;
  height: var(--radio-button-height-medium, 32px);
  line-height: 32px;
  line-height: var(--radio-button-height-medium, 32px);
}

.next-radio-button-medium > label:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--radio-button-corner-medium, 2px);
  -moz-border-radius-topleft: var(--radio-button-corner-medium, 2px);
  border-top-left-radius: var(--radio-button-corner-medium, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--radio-button-corner-medium, 2px);
  -moz-border-radius-bottomleft: var(--radio-button-corner-medium, 2px);
  border-bottom-left-radius: var(--radio-button-corner-medium, 2px);
}

.next-radio-button-medium > label:last-child {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--radio-button-corner-medium, 2px);
  -moz-border-radius-topright: var(--radio-button-corner-medium, 2px);
  border-top-right-radius: var(--radio-button-corner-medium, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--radio-button-corner-medium, 2px);
  -moz-border-radius-bottomright: var(--radio-button-corner-medium, 2px);
  border-bottom-right-radius: var(--radio-button-corner-medium, 2px);
}

.next-radio-button-medium .next-radio-label {
  height: 30px;
  height: -webkit-calc(var(--radio-button-height-medium, 32px) - 2px);
  height: -moz-calc(var(--radio-button-height-medium, 32px) - 2px);
  height: calc(var(--radio-button-height-medium, 32px) - 2px);
  line-height: 30px;
  line-height: -webkit-calc(var(--radio-button-height-medium, 32px) - 2px);
  line-height: -moz-calc(var(--radio-button-height-medium, 32px) - 2px);
  line-height: calc(var(--radio-button-height-medium, 32px) - 2px);
  font-size: 12px;
  font-size: var(--radio-button-font-size-medium, 12px);
}

.next-radio-button-small > label {
  padding: 0 8px;
  padding: 0 var(--radio-button-padding-small, 8px);
  height: 24px;
  height: var(--radio-button-height-small, 24px);
  line-height: 24px;
  line-height: var(--radio-button-height-small, 24px);
}

.next-radio-button-small > label:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--radio-button-corner-small, 2px);
  -moz-border-radius-topleft: var(--radio-button-corner-small, 2px);
  border-top-left-radius: var(--radio-button-corner-small, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--radio-button-corner-small, 2px);
  -moz-border-radius-bottomleft: var(--radio-button-corner-small, 2px);
  border-bottom-left-radius: var(--radio-button-corner-small, 2px);
}

.next-radio-button-small > label:last-child {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--radio-button-corner-small, 2px);
  -moz-border-radius-topright: var(--radio-button-corner-small, 2px);
  border-top-right-radius: var(--radio-button-corner-small, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--radio-button-corner-small, 2px);
  -moz-border-radius-bottomright: var(--radio-button-corner-small, 2px);
  border-bottom-right-radius: var(--radio-button-corner-small, 2px);
}

.next-radio-button-small .next-radio-label {
  height: 22px;
  height: -webkit-calc(var(--radio-button-height-small, 24px) - 2px);
  height: -moz-calc(var(--radio-button-height-small, 24px) - 2px);
  height: calc(var(--radio-button-height-small, 24px) - 2px);
  line-height: 22px;
  line-height: -webkit-calc(var(--radio-button-height-small, 24px) - 2px);
  line-height: -moz-calc(var(--radio-button-height-small, 24px) - 2px);
  line-height: calc(var(--radio-button-height-small, 24px) - 2px);
  font-size: 12px;
  font-size: var(--radio-button-font-size-small, 12px);
}

.next-radio-single-input input[type="radio"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

.next-radio-group {
  display: inline-block;
}

.next-radio-group .next-radio-wrapper {
  margin-right: 12px;
}

.next-radio-group .next-radio-wrapper:last-child {
  margin-right: 0;
}

.next-radio-group .next-radio-label {
  color: #333;
  color: var(--radio-normal-font-color, #333);
}

.next-radio-group.disabled .next-radio-label {
  color: #ccc;
  color: var(--radio-normal-font-color-disabled, #ccc);
}

.next-radio-group.next-radio-button .next-radio-wrapper {
  margin-right: 0;
}

.next-radio-group-ver .next-radio-wrapper {
  display: block;
  margin-bottom: 8px;
}

.next-radio-label {
  margin: 0 0 0 8px;
  margin-left: var(--radio-font-margin-left, 8px);
  margin-right: 8px;
  margin-right: var(--radio-font-margin-left, 8px);
  font-size: 12px;
  font-size: var(--radio-font-size, 12px);
  vertical-align: middle;
  line-height: 1;
  color: #333;
  color: var(--radio-normal-font-color, #333);
}

@-moz-document url-prefix() {
  .next-radio {
    margin-top: -1px;
  }

  @supports ((-webkit-animation: calc(0s)) or (animation: calc(0s))) {
    .next-radio {
      margin-top: -3px;
    }
  }
}

@-webkit-keyframes fadeInRightForTag {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translateX(20px);
    transform: rotate(45deg) translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: rotate(45deg) translateX(0);
    transform: rotate(45deg) translateX(0);
  }
}

@-moz-keyframes fadeInRightForTag {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translateX(20px);
    -moz-transform: rotate(45deg) translateX(20px);
    transform: rotate(45deg) translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: rotate(45deg) translateX(0);
    -moz-transform: rotate(45deg) translateX(0);
    transform: rotate(45deg) translateX(0);
  }
}

@-o-keyframes fadeInRightForTag {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translateX(20px);
    -o-transform: rotate(45deg) translateX(20px);
    transform: rotate(45deg) translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: rotate(45deg) translateX(0);
    -o-transform: rotate(45deg) translateX(0);
    transform: rotate(45deg) translateX(0);
  }
}

@keyframes fadeInRightForTag {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translateX(20px);
    -moz-transform: rotate(45deg) translateX(20px);
    -o-transform: rotate(45deg) translateX(20px);
    transform: rotate(45deg) translateX(20px);
  }

  to {
    opacity: 1;
    -webkit-transform: rotate(45deg) translateX(0);
    -moz-transform: rotate(45deg) translateX(0);
    -o-transform: rotate(45deg) translateX(0);
    transform: rotate(45deg) translateX(0);
  }
}

.next-tag > .next-tag-body {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.next-tag-checkable.next-tag-level-secondary {
  color: #111;
  color: var(--tag-normal-text-color, #111);
  border-color: transparent;
  background-color: transparent;
}

.next-tag-checkable.next-tag-level-secondary:not(.disabled):not([disabled]).hover,
.next-tag-checkable.next-tag-level-secondary:not(.disabled):not([disabled]):focus,
.next-tag-checkable.next-tag-level-secondary:not(.disabled):not([disabled]):hover {
  color: #0064c8;
  color: var(--tag-normal-text-color-hover, #0064c8);
}

.next-tag-default.next-tag-level-primary {
  color: #555;
  color: var(--tag-fill-text-color, #555);
  border-color: #c0c6cc;
  border-color: var(--tag-fill-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--tag-fill-bg-color, #fff);
}

.next-tag-default.next-tag-level-primary:not(.disabled):not([disabled]).hover,
.next-tag-default.next-tag-level-primary:not(.disabled):not([disabled]):focus,
.next-tag-default.next-tag-level-primary:not(.disabled):not([disabled]):hover {
  color: #333;
  color: var(--tag-fill-text-color-hover, #333);
  border-color: #c0c6cc;
  border-color: var(--tag-fill-border-color-hover, #c0c6cc);
  background-color: #fff;
  background-color: var(--tag-fill-bg-color-hover, #fff);
}

.next-tag-default.next-tag-level-primary:not(.disabled):not([disabled]).hover
  > .next-tag-close-btn,
.next-tag-default.next-tag-level-primary:not(.disabled):not([disabled]):focus
  > .next-tag-close-btn,
.next-tag-default.next-tag-level-primary:not(.disabled):not([disabled]):hover
  > .next-tag-close-btn {
  color: #333;
  color: var(--tag-fill-tail-color-hover, #333);
}

.disabled.next-tag-default.next-tag-level-primary,
.next-tag-default.next-tag-level-primary[disabled] {
  color: #ccc;
  color: var(--tag-fill-text-color-disabled, #ccc);
  border-color: #d1d5d9;
  border-color: var(--tag-fill-border-color-disabled, #d1d5d9);
  background-color: #fff;
  background-color: var(--tag-fill-bg-color-disabled, #fff);
}

.disabled.next-tag-default.next-tag-level-primary > .next-tag-close-btn,
.next-tag-default.next-tag-level-primary[disabled] > .next-tag-close-btn {
  color: #ccc;
  color: var(--tag-fill-tail-color-disabled, #ccc);
}

.next-tag-default.next-tag-level-primary > .next-tag-close-btn {
  color: #333;
  color: var(--tag-fill-tail-color, #333);
}

.next-tag-closable.next-tag-level-primary {
  color: #333;
  color: var(--tag-closable-primary-fill-text-color, #333);
  border-color: #c0c6cc;
  border-color: var(--tag-closable-primary-fill-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--tag-closable-primary-fill-bg-color, #fff);
}

.next-tag-closable.next-tag-level-primary:not(.disabled):not([disabled]).hover,
.next-tag-closable.next-tag-level-primary:not(.disabled):not([disabled]):focus,
.next-tag-closable.next-tag-level-primary:not(.disabled):not([disabled]):hover {
  color: #0064c8;
  color: var(--tag-closable-primary-fill-text-color-hover, #0064c8);
  border-color: #c0c6cc;
  border-color: var(--tag-closable-primary-fill-border-color-hover, #c0c6cc);
  background-color: #f7f9fa;
  background-color: var(--tag-closable-primary-fill-bg-color-hover, #f7f9fa);
}

.next-tag-closable.next-tag-level-primary:not(.disabled):not([disabled]).hover
  > .next-tag-close-btn,
.next-tag-closable.next-tag-level-primary:not(.disabled):not([disabled]):focus
  > .next-tag-close-btn,
.next-tag-closable.next-tag-level-primary:not(.disabled):not([disabled]):hover
  > .next-tag-close-btn {
  color: #333;
  color: var(--tag-fill-tail-color-hover, #333);
}

.disabled.next-tag-closable.next-tag-level-primary,
.next-tag-closable.next-tag-level-primary[disabled] {
  color: #ccc;
  color: var(--tag-closable-primary-fill-text-color-disabled, #ccc);
  border-color: #d1d5d9;
  border-color: var(--tag-closable-primary-fill-border-color-disabled, #d1d5d9);
  background-color: #fff;
  background-color: var(--tag-closable-primary-fill-bg-color-disabled, #fff);
}

.disabled.next-tag-closable.next-tag-level-primary > .next-tag-close-btn,
.next-tag-closable.next-tag-level-primary[disabled] > .next-tag-close-btn {
  color: #ccc;
  color: var(--tag-fill-tail-color-disabled, #ccc);
}

.next-tag-closable.next-tag-level-primary > .next-tag-close-btn {
  color: #333;
  color: var(--tag-fill-tail-color, #333);
}

.next-tag-checkable.next-tag-level-primary {
  color: #333;
  color: var(--tag-checkable-fill-text-color, #333);
  border-color: #c0c6cc;
  border-color: var(--tag-checkable-fill-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--tag-checkable-fill-bg-color, #fff);
}

.next-tag-checkable.next-tag-level-primary:not(.disabled):not([disabled]).hover,
.next-tag-checkable.next-tag-level-primary:not(.disabled):not([disabled]):focus,
.next-tag-checkable.next-tag-level-primary:not(.disabled):not([disabled]):hover {
  color: #0064c8;
  color: var(--tag-checkable-fill-text-color-hover, #0064c8);
  border-color: #c0c6cc;
  border-color: var(--tag-checkable-fill-border-color-hover, #c0c6cc);
  background-color: #f7f9fa;
  background-color: var(--tag-checkable-fill-bg-color-hover, #f7f9fa);
}

.next-tag-checkable.next-tag-level-primary:not(.disabled):not([disabled]).hover
  > .next-tag-close-btn,
.next-tag-checkable.next-tag-level-primary:not(.disabled):not([disabled]):focus
  > .next-tag-close-btn,
.next-tag-checkable.next-tag-level-primary:not(.disabled):not([disabled]):hover
  > .next-tag-close-btn {
  color: #111;
  color: var(--tag-checkable-fill-tail-color-hover, #111);
}

.disabled.next-tag-checkable.next-tag-level-primary,
.next-tag-checkable.next-tag-level-primary[disabled] {
  color: #ccc;
  color: var(--tag-checkable-fill-text-color-disabled, #ccc);
  border-color: #d1d5d9;
  border-color: var(--tag-checkable-fill-border-color-disabled, #d1d5d9);
  background-color: #fff;
  background-color: var(--tag-checkable-fill-bg-color-disabled, #fff);
}

.disabled.next-tag-checkable.next-tag-level-primary > .next-tag-close-btn,
.next-tag-checkable.next-tag-level-primary[disabled] > .next-tag-close-btn {
  color: #c1c1c1;
  color: var(--tag-checkable-fill-tail-color-disabled, #c1c1c1);
}

.next-tag-checkable.next-tag-level-primary > .next-tag-close-btn {
  color: #555;
  color: var(--tag-checkable-fill-tail-color, #555);
}

.next-tag-checkable.next-tag-level-primary.checked {
  color: #0064c8;
  color: var(--tag-checkable-primary-text-color, #0064c8);
  border-color: #0064c8;
  border-color: var(--tag-checkable-primary-border-color, #0064c8);
  background-color: #fff;
  background-color: var(--tag-checkable-primary-bg-color, #fff);
}

.next-tag-checkable.next-tag-level-primary.checked:not(.disabled):not([disabled]).hover,
.next-tag-checkable.next-tag-level-primary.checked:not(.disabled):not([disabled]):focus,
.next-tag-checkable.next-tag-level-primary.checked:not(.disabled):not([disabled]):hover {
  color: #0064c8;
  color: var(--tag-checkable-primary-text-color-hover, #0064c8);
  border-color: #0064c8;
  border-color: var(--tag-checkable-primary-border-color-hover, #0064c8);
  background-color: #f7f9fa;
  background-color: var(--tag-checkable-primary-bg-color-hover, #f7f9fa);
}

.next-tag-checkable.next-tag-level-primary.checked:not(.disabled):not([disabled]).hover
  > .next-tag-close-btn,
.next-tag-checkable.next-tag-level-primary.checked:not(.disabled):not([disabled]):focus
  > .next-tag-close-btn,
.next-tag-checkable.next-tag-level-primary.checked:not(.disabled):not([disabled]):hover
  > .next-tag-close-btn {
  color: #fff;
  color: var(--tag-checkable-primary-tail-color-hover, #fff);
}

.disabled.next-tag-checkable.next-tag-level-primary.checked,
.next-tag-checkable.next-tag-level-primary.checked[disabled] {
  color: #ccc;
  color: var(--tag-checkable-primary-text-color-disabled, #ccc);
  border-color: #d1d5d9;
  border-color: var(--tag-checkable-primary-border-color-disabled, #d1d5d9);
  background-color: #fff;
  background-color: var(--tag-checkable-primary-bg-color-disabled, #fff);
}

.disabled.next-tag-checkable.next-tag-level-primary.checked
  > .next-tag-close-btn,
.next-tag-checkable.next-tag-level-primary.checked > .next-tag-close-btn,
.next-tag-checkable.next-tag-level-primary.checked[disabled]
  > .next-tag-close-btn {
  color: #fff;
  color: var(--tag-checkable-primary-tail-color, #fff);
}

.next-tag-default.next-tag-level-normal {
  color: #333;
  color: var(--tag-bordered-text-color, #333);
  border-color: #c0c6cc;
  border-color: var(--tag-bordered-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--tag-bordered-bg, #fff);
}

.next-tag-default.next-tag-level-normal:not(.disabled):not([disabled]).hover,
.next-tag-default.next-tag-level-normal:not(.disabled):not([disabled]):focus,
.next-tag-default.next-tag-level-normal:not(.disabled):not([disabled]):hover {
  color: #333;
  color: var(--tag-bordered-text-color-hover, #333);
  border-color: #c0c6cc;
  border-color: var(--tag-bordered-border-color-hover, #c0c6cc);
  background-color: #fff;
  background-color: var(--tag-bordered-bg-hover, #fff);
}

.next-tag-default.next-tag-level-normal:not(.disabled):not([disabled]).hover
  > .next-tag-close-btn,
.next-tag-default.next-tag-level-normal:not(.disabled):not([disabled]):focus
  > .next-tag-close-btn,
.next-tag-default.next-tag-level-normal:not(.disabled):not([disabled]):hover
  > .next-tag-close-btn {
  color: #111;
  color: var(--tag-bordered-tail-color-hover, #111);
}

.disabled.next-tag-default.next-tag-level-normal,
.next-tag-default.next-tag-level-normal[disabled] {
  color: #ccc;
  color: var(--tag-bordered-text-color-disabled, #ccc);
  border-color: #d1d5d9;
  border-color: var(--tag-bordered-border-color-disabled, #d1d5d9);
  background-color: #fff;
  background-color: var(--tag-bordered-bg-disabled, #fff);
}

.disabled.next-tag-default.next-tag-level-normal > .next-tag-close-btn,
.next-tag-default.next-tag-level-normal[disabled] > .next-tag-close-btn {
  color: #c1c1c1;
  color: var(--tag-bordered-tail-color-disabled, #c1c1c1);
}

.next-tag-default.next-tag-level-normal > .next-tag-close-btn {
  color: #555;
  color: var(--tag-bordered-tail-color, #555);
}

.next-tag-closable.next-tag-level-normal {
  color: #333;
  color: var(--tag-closable-bordered-text-color, #333);
  border-color: #c0c6cc;
  border-color: var(--tag-closable-bordered-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--tag-closable-bordered-bg, #fff);
}

.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]).hover,
.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]):focus,
.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]):hover {
  color: #0064c8;
  color: var(--tag-closable-bordered-text-color-hover, #0064c8);
  border-color: #c0c6cc;
  border-color: var(--tag-closable-bordered-border-color-hover, #c0c6cc);
  background-color: #f7f9fa;
  background-color: var(--tag-closable-bordered-bg-hover, #f7f9fa);
}

.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]).hover
  > .next-tag-close-btn,
.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]):focus
  > .next-tag-close-btn,
.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]):hover
  > .next-tag-close-btn {
  color: #333;
  color: var(--tag-closable-bordered-tail-color-hover, #333);
}

.disabled.next-tag-closable.next-tag-level-normal,
.next-tag-closable.next-tag-level-normal[disabled] {
  color: #ccc;
  color: var(--tag-closable-bordered-text-color-disabled, #ccc);
  border-color: #d1d5d9;
  border-color: var(--tag-closable-bordered-border-color-disabled, #d1d5d9);
  background-color: transparent;
  background-color: var(--tag-closable-bordered-bg-disabled, transparent);
}

.disabled.next-tag-closable.next-tag-level-normal > .next-tag-close-btn,
.next-tag-closable.next-tag-level-normal[disabled] > .next-tag-close-btn {
  color: #c1c1c1;
  color: var(--tag-closable-bordered-tail-color-disabled, #c1c1c1);
}

.next-tag-closable.next-tag-level-normal > .next-tag-close-btn {
  color: #333;
  color: var(--tag-closable-bordered-tail-color, #333);
}

.next-tag-checkable.next-tag-level-normal.checked {
  color: #0064c8;
  color: var(--tag-secondary-text-color, #0064c8);
  border-color: #0064c8;
  border-color: var(--tag-secondary-border-color, #0064c8);
  background-color: transparent;
  background-color: var(--tag-secondary-bg, transparent);
}

.next-tag-checkable.next-tag-level-normal.checked:not(.disabled):not([disabled]).hover,
.next-tag-checkable.next-tag-level-normal.checked:not(.disabled):not([disabled]):focus,
.next-tag-checkable.next-tag-level-normal.checked:not(.disabled):not([disabled]):hover {
  color: #0064c8;
  color: var(--tag-secondary-text-color-hover, #0064c8);
  border-color: #0064c8;
  border-color: var(--tag-secondary-border-color-hover, #0064c8);
  background-color: #f7f9fa;
  background-color: var(--tag-secondary-bg-hover, #f7f9fa);
}

.next-tag-checkable.next-tag-level-secondary.checked {
  color: #0064c8;
  color: var(--tag-secondary-text-color, #0064c8);
  border-color: #0064c8;
  border-color: var(--tag-secondary-border-color, #0064c8);
  background-color: transparent;
  background-color: var(--tag-secondary-bg, transparent);
}

.next-tag-checkable.next-tag-level-secondary.checked:not(.disabled):not([disabled]).hover,
.next-tag-checkable.next-tag-level-secondary.checked:not(.disabled):not([disabled]):focus,
.next-tag-checkable.next-tag-level-secondary.checked:not(.disabled):not([disabled]):hover {
  color: #0064c8;
  color: var(--tag-secondary-text-color-hover, #0064c8);
  border-color: #0064c8;
  border-color: var(--tag-secondary-border-color-hover, #0064c8);
  background-color: #f7f9fa;
  background-color: var(--tag-secondary-bg-hover, #f7f9fa);
}

.next-tag-checkable.next-tag-level-secondary.checked:before {
  position: absolute;
  content: "";
  -webkit-font-smoothing: antialiased;
  background-color: #0064c8;
  background-color: var(--tag-checkable-secondary-bg-selected, #0064c8);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.next-tag-checkable.next-tag-level-secondary.checked:after {
  position: absolute;
  font-family: NextIcon;
  -webkit-font-smoothing: antialiased;
  content: "\E632";
  content: var(--icon-content-select, "\E632");
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
  color: #fff;
  color: var(--tag-checkable-secondary-icon-color-selected, #fff);
}

.next-tag-checkable.next-tag-level-secondary.checked:not(.disabled):not([disabled]).hover:before,
.next-tag-checkable.next-tag-level-secondary.checked:not(.disabled):not([disabled]):focus:before,
.next-tag-checkable.next-tag-level-secondary.checked:not(.disabled):not([disabled]):hover:before {
  background-color: #002850;
  background-color: var(--tag-checkable-secondary-bg-selected-hover, #002850);
}

.next-tag-checkable.next-tag-level-secondary.checked:not(.disabled):not([disabled]).hover:after,
.next-tag-checkable.next-tag-level-secondary.checked:not(.disabled):not([disabled]):focus:after,
.next-tag-checkable.next-tag-level-secondary.checked:not(.disabled):not([disabled]):hover:after {
  color: #fff;
  color: var(--tag-checkable-secondary-icon-color-selected-hover, #fff);
}

.next-tag-checkable.next-tag-level-secondary.checked:disabled:before,
.next-tag-checkable.next-tag-level-secondary.checked[disabled]:before {
  background-color: #e3e4e6;
  background-color: var(
    --tag-checkable-secondary-bg-selected-disabled,
    #e3e4e6
  );
}

.next-tag-checkable.next-tag-level-secondary.checked:disabled:after,
.next-tag-checkable.next-tag-level-secondary.checked[disabled]:after {
  color: #fff;
  color: var(--tag-checkable-secondary-icon-color-selected-disabled, #fff);
}

.next-tag-checkable.next-tag-level-normal {
  color: #333;
  color: var(--tag-checkable-normal-text-color, #333);
  border-color: #c0c6cc;
  border-color: var(--tag-checkable-normal-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--tag-checkable-normal-bg, #fff);
}

.next-tag-checkable.next-tag-level-normal:not(.disabled):not([disabled]).hover,
.next-tag-checkable.next-tag-level-normal:not(.disabled):not([disabled]):focus,
.next-tag-checkable.next-tag-level-normal:not(.disabled):not([disabled]):hover {
  color: #0064c8;
  color: var(--tag-checkable-normal-text-color-hover, #0064c8);
  border-color: #c0c6cc;
  border-color: var(--tag-checkable-normal-border-color-hover, #c0c6cc);
  background-color: #f7f9fa;
  background-color: var(--tag-checkable-normal-bg-hover, #f7f9fa);
}

.disabled.next-tag-checkable.next-tag-level-normal,
.next-tag-checkable.next-tag-level-normal[disabled] {
  color: #ccc;
  color: var(--tag-checkable-normal-text-selected-disabled, #ccc);
  border-color: #d1d5d9;
  border-color: var(--tag-checkable-normal-border-selected-disabled, #d1d5d9);
  background-color: #fff;
  background-color: var(--tag-checkable-normal-bg-selected-disabled, #fff);
}

.next-tag-checkable.next-tag-level-normal.checked:before {
  position: absolute;
  content: "";
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  background-color: var(--tag-checkable-normal-bg-selected, #fff);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.next-tag-checkable.next-tag-level-normal.checked:after {
  position: absolute;
  font-family: NextIcon;
  -webkit-font-smoothing: antialiased;
  content: "\E632";
  content: var(--icon-content-select, "\E632");
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
  color: transparent;
  color: var(--tag-checkable-normal-icon-color-selected, transparent);
}

.next-tag-checkable.next-tag-level-normal.checked:not(.disabled):not([disabled]).hover:before,
.next-tag-checkable.next-tag-level-normal.checked:not(.disabled):not([disabled]):focus:before,
.next-tag-checkable.next-tag-level-normal.checked:not(.disabled):not([disabled]):hover:before {
  background-color: transparent;
  background-color: var(--tag-checkable-normal-bg-selected-hover, transparent);
}

.next-tag-checkable.next-tag-level-normal.checked:not(.disabled):not([disabled]).hover:after,
.next-tag-checkable.next-tag-level-normal.checked:not(.disabled):not([disabled]):focus:after,
.next-tag-checkable.next-tag-level-normal.checked:not(.disabled):not([disabled]):hover:after {
  color: transparent;
  color: var(--tag-checkable-normal-icon-color-selected-hover, transparent);
}

.next-tag-checkable.next-tag-level-normal.checked:disabled:before,
.next-tag-checkable.next-tag-level-normal.checked[disabled]:before {
  background-color: #e3e4e6;
  background-color: var(
    --tag-checkable-normal-tick-bg-selected-disabled,
    #e3e4e6
  );
}

.next-tag-checkable.next-tag-level-normal.checked:disabled:after,
.next-tag-checkable.next-tag-level-normal.checked[disabled]:after {
  color: #fff;
  color: var(--tag-checkable-normal-icon-color-selected-disabled, #fff);
}

.next-tag-closable.next-tag-level-normal:before {
  position: absolute;
  content: "";
  -webkit-font-smoothing: antialiased;
  background-color: #577899;
  background-color: var(--tag-closable-normal-bg, #577899);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.next-tag-closable.next-tag-level-normal:after {
  position: absolute;
  font-family: NextIcon;
  -webkit-font-smoothing: antialiased;
  content: "\E626";
  content: var(--icon-content-close, "\E626");
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
  color: #fff;
  color: var(--tag-closable-normal-icon-color, #fff);
}

.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]).hover:before,
.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]):focus:before,
.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]):hover:before {
  background-color: #f7f9fa;
  background-color: var(--tag-closable-normal-bg-hover, #f7f9fa);
}

.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]).hover:after,
.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]):focus:after,
.next-tag-closable.next-tag-level-normal:not(.disabled):not([disabled]):hover:after {
  color: #333;
  color: var(--tag-closable-normal-icon-color-hover, #333);
}

.next-tag-closable.next-tag-level-normal:disabled:before,
.next-tag-closable.next-tag-level-normal[disabled]:before {
  background-color: #e3e4e6;
  background-color: var(--tag-closable-normal-bg-disabled, #e3e4e6);
}

.next-tag-closable.next-tag-level-normal:disabled:after,
.next-tag-closable.next-tag-level-normal[disabled]:after {
  color: #fff;
  color: var(--tag-closable-normal-icon-color-disabled, #fff);
}

.next-tag-group .next-tag-large,
.next-tag-group .next-tag-medium {
  margin-right: 8px;
  margin-bottom: 8px;
}

.next-tag-group .next-tag-small {
  margin-right: 4px;
  margin-bottom: 4px;
}

.next-tag {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
  border-width: 1px;
  border-width: var(--tag-border-width, 1px);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-border-radius: var(--tag-corner-radius, 12px);
  -moz-border-radius: var(--tag-corner-radius, 12px);
  border-radius: var(--tag-corner-radius, 12px);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--tag-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--tag-shadow, none);
  box-shadow: var(--tag-shadow, none);
  border-style: solid;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  font-size: 0;
  outline: 0;
}

.next-tag,
.next-tag *,
.next-tag :after,
.next-tag :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-tag > .next-tag-body {
  position: relative;
  display: inline-block;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
  cursor: default;
}

.next-tag > .next-tag-body > a {
  text-decoration: none;
  color: inherit;
}

.next-tag > .next-tag-body > a:before {
  content: " ";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.next-tag > .next-tag-body .next-icon {
  line-height: 1;
  vertical-align: middle;
}

.next-tag > .next-tag-body .next-icon:before {
  font-size: inherit;
}

.next-tag.next-tag-body-pointer {
  cursor: pointer;
}

.next-tag.disabled,
.next-tag[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

.next-tag-blue {
  background-color: #4494f9;
  border-color: #4494f9;
  color: #fff;
}

.next-tag-blue-inverse {
  background-color: rgba(68, 148, 249, 0.25);
  border-color: #4494f9;
  color: #4494f9;
}

.next-tag-green {
  background-color: #46bc15;
  border-color: #46bc15;
  color: #fff;
}

.next-tag-green-inverse {
  background-color: rgba(70, 188, 21, 0.25);
  border-color: #46bc15;
  color: #46bc15;
}

.next-tag-orange {
  background-color: #ff9300;
  border-color: #ff9300;
  color: #fff;
}

.next-tag-orange-inverse {
  background-color: rgba(255, 147, 0, 0.25);
  border-color: #ff9300;
  color: #ff9300;
}

.next-tag-red {
  background-color: #ff3000;
  border-color: #ff3000;
  color: #fff;
}

.next-tag-red-inverse {
  background-color: rgba(255, 48, 0, 0.25);
  border-color: #ff3000;
  color: #ff3000;
}

.next-tag-turquoise {
  background-color: #01c1b2;
  border-color: #01c1b2;
  color: #fff;
}

.next-tag-turquoise-inverse {
  background-color: rgba(1, 193, 178, 0.25);
  border-color: #01c1b2;
  color: #01c1b2;
}

.next-tag-yellow {
  background-color: #fccc12;
  border-color: #fccc12;
  color: #fff;
}

.next-tag-yellow-inverse {
  background-color: rgba(252, 204, 18, 0.25);
  border-color: #fccc12;
  color: #fccc12;
}

.next-tag-large {
  height: 28px;
  height: var(--tag-size-l-height, 28px);
  padding: 0;
  line-height: 26px;
  line-height: -webkit-calc(
    var(--tag-size-l-height, 28px) - var(--tag-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--tag-size-l-height, 28px) - var(--tag-border-width, 1px) * 2
  );
  line-height: calc(
    var(--tag-size-l-height, 28px) - var(--tag-border-width, 1px) * 2
  );
  font-size: 0;
}

.next-tag-large > .next-tag-body {
  font-size: 14px;
  font-size: var(--tag-size-l-text-size, 14px);
  padding: 0 16px;
  padding: 0 var(--tag-size-l-padding-lr, 16px);
  min-width: 48px;
  min-width: var(--tag-size-l-content-min-width, 48px);
}

.next-tag-large.next-tag-closable > .next-tag-body {
  padding: 0 0 0 16px;
  padding: 0 0 0 var(--tag-size-l-padding-lr, 16px);
  max-width: -webkit-calc(100% - 40px);
  max-width: -moz-calc(100% - 40px);
  max-width: calc(100% - 40px);
  max-width: -webkit-calc(
    100% - var(--tag-size-l-icon-font, 12px) - var(--tag-size-l-icon-font, 12px) -
      var(--tag-size-l-padding-lr, 16px)
  );
  max-width: -moz-calc(
    100% - var(--tag-size-l-icon-font, 12px) - var(--tag-size-l-icon-font, 12px) -
      var(--tag-size-l-padding-lr, 16px)
  );
  max-width: calc(
    100% - var(--tag-size-l-icon-font, 12px) - var(--tag-size-l-icon-font, 12px) -
      var(--tag-size-l-padding-lr, 16px)
  );
}

.next-tag-large[dir="rtl"].next-tag-closable > .next-tag-body {
  padding: 0 16px 0 0;
  padding: 0 var(--tag-size-l-padding-lr, 16px) 0 0;
}

.next-tag-large.next-tag-closable > .next-tag-close-btn {
  margin-left: 12px;
  margin-left: var(--tag-size-l-icon-font, 12px);
  padding-right: 16px;
  padding-right: var(--tag-size-l-padding-lr, 16px);
}

.next-tag-large.next-tag-closable
  > .next-tag-close-btn
  .next-icon
  .next-icon-remote,
.next-tag-large.next-tag-closable > .next-tag-close-btn .next-icon:before {
  width: 12px;
  width: var(--tag-size-l-icon-font, 12px);
  font-size: 12px;
  font-size: var(--tag-size-l-icon-font, 12px);
  line-height: inherit;
}

.next-tag-large[dir="rtl"] > .next-tag-close-btn {
  margin-right: 12px;
  margin-right: var(--tag-size-l-icon-font, 12px);
  margin-left: 0;
  padding-right: 0;
  padding-left: 16px;
  padding-left: var(--tag-size-l-padding-lr, 16px);
}

.next-tag-medium {
  height: 24px;
  height: var(--tag-size-m-height, 24px);
  padding: 0;
  line-height: 22px;
  line-height: -webkit-calc(
    var(--tag-size-m-height, 24px) - var(--tag-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--tag-size-m-height, 24px) - var(--tag-border-width, 1px) * 2
  );
  line-height: calc(
    var(--tag-size-m-height, 24px) - var(--tag-border-width, 1px) * 2
  );
  font-size: 0;
}

.next-tag-medium > .next-tag-body {
  font-size: 12px;
  font-size: var(--tag-size-m-text-size, 12px);
  padding: 0 8px;
  padding: 0 var(--tag-size-m-padding-lr, 8px);
  min-width: 40px;
  min-width: var(--tag-size-m-content-min-width, 40px);
}

.next-tag-medium.next-tag-closable > .next-tag-body {
  padding: 0 0 0 8px;
  padding: 0 0 0 var(--tag-size-m-padding-lr, 8px);
  max-width: -webkit-calc(100% - 32px);
  max-width: -moz-calc(100% - 32px);
  max-width: calc(100% - 32px);
  max-width: -webkit-calc(
    100% - var(--tag-size-m-icon-font, 12px) - var(--tag-size-m-icon-font, 12px) -
      var(--tag-size-m-padding-lr, 8px)
  );
  max-width: -moz-calc(
    100% - var(--tag-size-m-icon-font, 12px) - var(--tag-size-m-icon-font, 12px) -
      var(--tag-size-m-padding-lr, 8px)
  );
  max-width: calc(
    100% - var(--tag-size-m-icon-font, 12px) - var(--tag-size-m-icon-font, 12px) -
      var(--tag-size-m-padding-lr, 8px)
  );
}

.next-tag-medium[dir="rtl"].next-tag-closable > .next-tag-body {
  padding: 0 8px 0 0;
  padding: 0 var(--tag-size-m-padding-lr, 8px) 0 0;
}

.next-tag-medium.next-tag-closable > .next-tag-close-btn {
  margin-left: 12px;
  margin-left: var(--tag-size-m-icon-font, 12px);
  padding-right: 8px;
  padding-right: var(--tag-size-m-padding-lr, 8px);
}

.next-tag-medium.next-tag-closable
  > .next-tag-close-btn
  .next-icon
  .next-icon-remote,
.next-tag-medium.next-tag-closable > .next-tag-close-btn .next-icon:before {
  width: 12px;
  width: var(--tag-size-m-icon-font, 12px);
  font-size: 12px;
  font-size: var(--tag-size-m-icon-font, 12px);
  line-height: inherit;
}

.next-tag-medium[dir="rtl"] > .next-tag-close-btn {
  margin-right: 12px;
  margin-right: var(--tag-size-m-icon-font, 12px);
  margin-left: 0;
  padding-right: 0;
  padding-left: 8px;
  padding-left: var(--tag-size-m-padding-lr, 8px);
}

.next-tag-small {
  height: 20px;
  height: var(--tag-size-s-height, 20px);
  padding: 0;
  line-height: 18px;
  line-height: -webkit-calc(
    var(--tag-size-s-height, 20px) - var(--tag-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--tag-size-s-height, 20px) - var(--tag-border-width, 1px) * 2
  );
  line-height: calc(
    var(--tag-size-s-height, 20px) - var(--tag-border-width, 1px) * 2
  );
  font-size: 0;
}

.next-tag-small > .next-tag-body {
  font-size: 12px;
  font-size: var(--tag-size-s-text-size, 12px);
  padding: 0 8px;
  padding: 0 var(--tag-size-s-padding-lr, 8px);
  min-width: 28px;
  min-width: var(--tag-size-s-content-min-width, 28px);
}

.next-tag-small.next-tag-closable > .next-tag-body {
  padding: 0 0 0 8px;
  padding: 0 0 0 var(--tag-size-s-padding-lr, 8px);
  max-width: -webkit-calc(100% - 32px);
  max-width: -moz-calc(100% - 32px);
  max-width: calc(100% - 32px);
  max-width: -webkit-calc(
    100% - var(--tag-size-s-icon-font, 12px) - var(--tag-size-s-icon-font, 12px) -
      var(--tag-size-s-padding-lr, 8px)
  );
  max-width: -moz-calc(
    100% - var(--tag-size-s-icon-font, 12px) - var(--tag-size-s-icon-font, 12px) -
      var(--tag-size-s-padding-lr, 8px)
  );
  max-width: calc(
    100% - var(--tag-size-s-icon-font, 12px) - var(--tag-size-s-icon-font, 12px) -
      var(--tag-size-s-padding-lr, 8px)
  );
}

.next-tag-small[dir="rtl"].next-tag-closable > .next-tag-body {
  padding: 0 8px 0 0;
  padding: 0 var(--tag-size-s-padding-lr, 8px) 0 0;
}

.next-tag-small.next-tag-closable > .next-tag-close-btn {
  margin-left: 12px;
  margin-left: var(--tag-size-s-icon-font, 12px);
  padding-right: 8px;
  padding-right: var(--tag-size-s-padding-lr, 8px);
}

.next-tag-small.next-tag-closable
  > .next-tag-close-btn
  .next-icon
  .next-icon-remote,
.next-tag-small.next-tag-closable > .next-tag-close-btn .next-icon:before {
  width: 12px;
  width: var(--tag-size-s-icon-font, 12px);
  font-size: 12px;
  font-size: var(--tag-size-s-icon-font, 12px);
  line-height: inherit;
}

.next-tag-small[dir="rtl"] > .next-tag-close-btn {
  margin-right: 12px;
  margin-right: var(--tag-size-s-icon-font, 12px);
  margin-left: 0;
  padding-right: 0;
  padding-left: 8px;
  padding-left: var(--tag-size-s-padding-lr, 8px);
}

.next-tag-default {
  cursor: default;
}

.next-tag-closable {
  position: relative;
}

.next-tag-closable > .next-tag-close-btn {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

.next-tag-checkable {
  cursor: pointer;
  position: relative;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-border-radius: var(--tag-corner-radius, 12px);
  -moz-border-radius: var(--tag-corner-radius, 12px);
  border-radius: var(--tag-corner-radius, 12px);
}

.next-tag-checkable.checked:before {
  -webkit-animation: fadeInRightForTag 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -moz-animation: fadeInRightForTag 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -o-animation: fadeInRightForTag 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation: fadeInRightForTag 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.next-tag-checkable.checked:after {
  -webkit-animation: zoomIn 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -moz-animation: zoomIn 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -o-animation: zoomIn 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation: zoomIn 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.next-tag-checkable.next-tag-small:not(.next-tag-level-primary):before {
  right: 0;
  right: -webkit-calc(0 - var(--tag-size-s-tick-icon-bg-size, 0) / 2);
  right: -moz-calc(0 - var(--tag-size-s-tick-icon-bg-size, 0) / 2);
  right: calc(0px - var(--tag-size-s-tick-icon-bg-size, 0px) / 2);
  bottom: 0;
  bottom: -webkit-calc(0 - var(--tag-size-s-tick-icon-bg-size, 0) / 2);
  bottom: -moz-calc(0 - var(--tag-size-s-tick-icon-bg-size, 0) / 2);
  bottom: calc(0px - var(--tag-size-s-tick-icon-bg-size, 0px) / 2);
  width: 0;
  width: var(--tag-size-s-tick-icon-bg-size, 0);
  height: 0;
  height: var(--tag-size-s-tick-icon-bg-size, 0);
}

.next-tag-checkable.next-tag-small:not(.next-tag-level-primary):after {
  font-size: 8px;
  line-height: 8px;
  right: 0;
  bottom: 0;
}

.next-tag-checkable.next-tag-medium:not(.next-tag-level-primary):before {
  right: 0;
  right: -webkit-calc(0 - var(--tag-size-m-tick-icon-bg-size, 0) / 2);
  right: -moz-calc(0 - var(--tag-size-m-tick-icon-bg-size, 0) / 2);
  right: calc(0px - var(--tag-size-m-tick-icon-bg-size, 0px) / 2);
  bottom: 0;
  bottom: -webkit-calc(0 - var(--tag-size-m-tick-icon-bg-size, 0) / 2);
  bottom: -moz-calc(0 - var(--tag-size-m-tick-icon-bg-size, 0) / 2);
  bottom: calc(0px - var(--tag-size-m-tick-icon-bg-size, 0px) / 2);
  width: 0;
  width: var(--tag-size-m-tick-icon-bg-size, 0);
  height: 0;
  height: var(--tag-size-m-tick-icon-bg-size, 0);
}

.next-tag-checkable.next-tag-medium:not(.next-tag-level-primary):after {
  font-size: 12px;
  line-height: 12px;
  right: 0;
  bottom: 0;
}

.next-tag-checkable.next-tag-large:not(.next-tag-level-primary):before {
  right: -18px;
  right: -webkit-calc(0 - var(--tag-size-l-tick-icon-bg-size, 36px) / 2);
  right: -moz-calc(0 - var(--tag-size-l-tick-icon-bg-size, 36px) / 2);
  right: calc(0px - var(--tag-size-l-tick-icon-bg-size, 36px) / 2);
  bottom: -18px;
  bottom: -webkit-calc(0 - var(--tag-size-l-tick-icon-bg-size, 36px) / 2);
  bottom: -moz-calc(0 - var(--tag-size-l-tick-icon-bg-size, 36px) / 2);
  bottom: calc(0px - var(--tag-size-l-tick-icon-bg-size, 36px) / 2);
  width: 36px;
  width: var(--tag-size-l-tick-icon-bg-size, 36px);
  height: 36px;
  height: var(--tag-size-l-tick-icon-bg-size, 36px);
}

.next-tag-checkable.next-tag-large:not(.next-tag-level-primary):after {
  font-size: 16px;
  line-height: 16px;
  right: 0;
  bottom: 0;
}

.next-tag-checkable.next-tag-level-secondary.disabled,
.next-tag-checkable.next-tag-level-secondary[disabled] {
  color: #c1c1c1;
  color: var(--tag-text-color-disabled, #c1c1c1);
  border-color: #e3e4e6;
  border-color: var(--tag-border-color-disabled, #e3e4e6);
  background-color: #fff;
  background-color: var(--tag-fill-bg-color-disabled, #fff);
}

.next-tag-zoom-appear,
.next-tag-zoom-enter {
  -webkit-animation: fadeInLeft 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -moz-animation: fadeInLeft 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -o-animation: fadeInLeft 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation: fadeInLeft 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.next-tag-zoom-leave {
  -webkit-animation: zoomOut 0.3s ease-in;
  -moz-animation: zoomOut 0.3s ease-in;
  -o-animation: zoomOut 0.3s ease-in;
  animation: zoomOut 0.3s ease-in;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.next-checkbox-wrapper[dir="rtl"] {
  margin-right: 8px;
  margin-left: 0;
}

.next-checkbox-wrapper[dir="rtl"]:first-child {
  margin-right: 0;
}

.next-checkbox-wrapper[dir="rtl"] > .next-checkbox-label {
  margin-right: 8px;
  margin-right: var(--checkbox-margin-left, 8px);
  margin-left: 0;
}

.next-checkbox-wrapper {
  display: inline-block;
}

.next-checkbox-wrapper,
.next-checkbox-wrapper *,
.next-checkbox-wrapper :after,
.next-checkbox-wrapper :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-checkbox-wrapper .next-checkbox {
  display: inline-block;
  position: relative;
  line-height: 1;
  vertical-align: middle;
}

.next-checkbox-wrapper input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  width: var(--checkbox-size, 16px);
  height: 16px;
  height: var(--checkbox-size, 16px);
  margin: 0;
  cursor: pointer;
}

.next-checkbox-wrapper .next-checkbox-inner {
  display: block;
  width: 16px;
  width: var(--checkbox-size, 16px);
  height: 16px;
  height: var(--checkbox-size, 16px);
  background: #fff;
  background: var(--checkbox-bg-color, #fff);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--checkbox-border-radius, 2px);
  -moz-border-radius: var(--checkbox-border-radius, 2px);
  border-radius: var(--checkbox-border-radius, 2px);
  border: 1px solid #577899;
  border: var(--checkbox-border-width, 1px) solid
    var(--checkbox-border-color, #577899);
  text-align: left;
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--checkbox-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--checkbox-shadow, none);
  box-shadow: var(--checkbox-shadow, none);
}

.next-checkbox-wrapper .next-checkbox-inner,
.next-checkbox-wrapper .next-checkbox-inner > .next-icon {
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-checkbox-wrapper .next-checkbox-inner > .next-icon {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  position: absolute;
  top: 0;
  opacity: 0;
  line-height: 16px;
  line-height: var(--checkbox-size, 16px);
  color: #fff;
  color: var(--checkbox-checked-circle-color, #fff);
  left: 4px;
  left: -webkit-calc(
    var(--checkbox-size, 16px) / 2 - var(--checkbox-circle-size, 8px) / 2
  );
  left: -moz-calc(
    var(--checkbox-size, 16px) / 2 - var(--checkbox-circle-size, 8px) / 2
  );
  left: calc(
    var(--checkbox-size, 16px) / 2 - var(--checkbox-circle-size, 8px) / 2
  );
  margin-left: 0;
}

.next-checkbox-wrapper .next-checkbox-inner > .next-icon .next-icon-remote,
.next-checkbox-wrapper .next-checkbox-inner > .next-icon:before {
  width: 8px;
  width: var(--checkbox-circle-size, 8px);
  font-size: 8px;
  font-size: var(--checkbox-circle-size, 8px);
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-checkbox-wrapper .next-checkbox-inner > .next-icon {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -4px;
    margin-left: calc(-8px + var(--checkbox-circle-size, 8px) / 2);
    margin-right: -4px;
    margin-right: calc(-8px + var(--checkbox-circle-size, 8px) / 2);
  }

  .next-checkbox-wrapper .next-checkbox-inner > .next-icon:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-checkbox-wrapper .next-checkbox-inner > .next-icon:before {
  vertical-align: top;
  margin-top: 0;
}

.next-checkbox-wrapper
  .next-checkbox-inner
  > .next-checkbox-select-icon:before {
  content: "\E632";
  content: var(--checkbox-select-icon-content, "\E632");
}

.next-checkbox-wrapper
  .next-checkbox-inner
  > .next-checkbox-semi-select-icon:before {
  content: "\E633";
  content: var(--checkbox-semi-select-icon-content, "\E633");
}

.next-checkbox-wrapper.checked.focused > .next-checkbox > .next-checkbox-inner,
.next-checkbox-wrapper.checked > .next-checkbox > .next-checkbox-inner {
  border-color: #004080;
  border-color: var(--checkbox-checked-border-color, #004080);
  background-color: #0064c8;
  background-color: var(--checkbox-checked-bg-color, #0064c8);
}

.next-checkbox-wrapper.checked.focused
  > .next-checkbox
  > .next-checkbox-inner.hovered,
.next-checkbox-wrapper.checked.focused
  > .next-checkbox
  > .next-checkbox-inner:hover,
.next-checkbox-wrapper.checked > .next-checkbox > .next-checkbox-inner.hovered,
.next-checkbox-wrapper.checked > .next-checkbox > .next-checkbox-inner:hover {
  border-color: #004080;
  border-color: var(--checkbox-checked-border-color, #004080);
}

.next-checkbox-wrapper.checked.focused
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon,
.next-checkbox-wrapper.checked
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  margin-left: 0;
}

.next-checkbox-wrapper.checked.focused
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon
  .next-icon-remote,
.next-checkbox-wrapper.checked.focused
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon:before,
.next-checkbox-wrapper.checked
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon
  .next-icon-remote,
.next-checkbox-wrapper.checked
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon:before {
  width: 8px;
  width: var(--checkbox-circle-size, 8px);
  font-size: 8px;
  font-size: var(--checkbox-circle-size, 8px);
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-checkbox-wrapper.checked.focused
    > .next-checkbox
    > .next-checkbox-inner
    > .next-icon,
  .next-checkbox-wrapper.checked
    > .next-checkbox
    > .next-checkbox-inner
    > .next-icon {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -4px;
    margin-left: calc(-8px + var(--checkbox-circle-size, 8px) / 2);
    margin-right: -4px;
    margin-right: calc(-8px + var(--checkbox-circle-size, 8px) / 2);
  }

  .next-checkbox-wrapper.checked.focused
    > .next-checkbox
    > .next-checkbox-inner
    > .next-icon:before,
  .next-checkbox-wrapper.checked
    > .next-checkbox
    > .next-checkbox-inner
    > .next-icon:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-checkbox-wrapper.indeterminate.focused
  > .next-checkbox
  > .next-checkbox-inner,
.next-checkbox-wrapper.indeterminate > .next-checkbox > .next-checkbox-inner {
  border-color: #004080;
  border-color: var(--checkbox-checked-border-color, #004080);
  background-color: #0064c8;
  background-color: var(--checkbox-checked-bg-color, #0064c8);
}

.next-checkbox-wrapper.indeterminate.focused
  > .next-checkbox
  > .next-checkbox-inner.hovered,
.next-checkbox-wrapper.indeterminate.focused
  > .next-checkbox
  > .next-checkbox-inner:hover,
.next-checkbox-wrapper.indeterminate
  > .next-checkbox
  > .next-checkbox-inner.hovered,
.next-checkbox-wrapper.indeterminate
  > .next-checkbox
  > .next-checkbox-inner:hover {
  border-color: #004080;
  border-color: var(--checkbox-checked-border-color, #004080);
}

.next-checkbox-wrapper.indeterminate.focused
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon,
.next-checkbox-wrapper.indeterminate
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon {
  opacity: 1;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1);
  margin-left: 0;
}

.next-checkbox-wrapper.indeterminate.focused
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon
  .next-icon-remote,
.next-checkbox-wrapper.indeterminate.focused
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon:before,
.next-checkbox-wrapper.indeterminate
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon
  .next-icon-remote,
.next-checkbox-wrapper.indeterminate
  > .next-checkbox
  > .next-checkbox-inner
  > .next-icon:before {
  width: 8px;
  width: var(--checkbox-circle-size, 8px);
  font-size: 8px;
  font-size: var(--checkbox-circle-size, 8px);
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-checkbox-wrapper.indeterminate.focused
    > .next-checkbox
    > .next-checkbox-inner
    > .next-icon,
  .next-checkbox-wrapper.indeterminate
    > .next-checkbox
    > .next-checkbox-inner
    > .next-icon {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -4px;
    margin-left: calc(-8px + var(--checkbox-circle-size, 8px) / 2);
    margin-right: -4px;
    margin-right: calc(-8px + var(--checkbox-circle-size, 8px) / 2);
  }

  .next-checkbox-wrapper.indeterminate.focused
    > .next-checkbox
    > .next-checkbox-inner
    > .next-icon:before,
  .next-checkbox-wrapper.indeterminate
    > .next-checkbox
    > .next-checkbox-inner
    > .next-icon:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-checkbox-wrapper.focused > .next-checkbox > .next-checkbox-inner,
.next-checkbox-wrapper.hovered > .next-checkbox > .next-checkbox-inner,
.next-checkbox-wrapper:not(.disabled):hover
  > .next-checkbox
  > .next-checkbox-inner {
  border-color: #004080;
  border-color: var(--checkbox-hovered-border-color, #004080);
  background-color: transparent;
  background-color: var(--checkbox-hovered-bg-color, transparent);
}

.next-checkbox-wrapper.focused .next-checkbox-label,
.next-checkbox-wrapper.hovered .next-checkbox-label,
.next-checkbox-wrapper:not(.disabled):hover .next-checkbox-label {
  cursor: pointer;
}

.next-checkbox-wrapper.checked:not(.disabled).hovered
  > .next-checkbox
  .next-checkbox-inner,
.next-checkbox-wrapper.checked:not(.disabled):hover
  > .next-checkbox
  .next-checkbox-inner,
.next-checkbox-wrapper.indeterminate:not(.disabled).hovered
  > .next-checkbox
  .next-checkbox-inner,
.next-checkbox-wrapper.indeterminate:not(.disabled):hover
  > .next-checkbox
  .next-checkbox-inner {
  border-color: #004080;
  border-color: var(--checkbox-checked-hovered-border-color, #004080);
  background-color: #0064c8;
  background-color: var(--checkbox-checked-hovered-bg-color, #0064c8);
}

.next-checkbox-wrapper.checked:not(.disabled).hovered
  > .next-checkbox
  .next-checkbox-inner
  > .next-icon,
.next-checkbox-wrapper.checked:not(.disabled):hover
  > .next-checkbox
  .next-checkbox-inner
  > .next-icon,
.next-checkbox-wrapper.indeterminate:not(.disabled).hovered
  > .next-checkbox
  .next-checkbox-inner
  > .next-icon,
.next-checkbox-wrapper.indeterminate:not(.disabled):hover
  > .next-checkbox
  .next-checkbox-inner
  > .next-icon {
  color: #fff;
  color: var(--checkbox-checked-hovered-circle-color, #fff);
  opacity: 1;
}

.next-checkbox-wrapper.disabled input[type="checkbox"] {
  cursor: not-allowed;
}

.next-checkbox-wrapper.disabled.checked .next-checkbox-inner,
.next-checkbox-wrapper.disabled.indeterminate .next-checkbox-inner,
.next-checkbox-wrapper.disabled .next-checkbox-inner {
  border-color: #c0c6cc;
  border-color: var(--checkbox-disabled-border-color, #c0c6cc);
  background: #f7f9fa;
  background: var(--checkbox-disabled-bg-color, #f7f9fa);
}

.next-checkbox-wrapper.disabled.checked .next-checkbox-inner.hovered,
.next-checkbox-wrapper.disabled.checked .next-checkbox-inner:hover,
.next-checkbox-wrapper.disabled.indeterminate .next-checkbox-inner.hovered,
.next-checkbox-wrapper.disabled.indeterminate .next-checkbox-inner:hover {
  border-color: #c0c6cc;
  border-color: var(--checkbox-disabled-border-color, #c0c6cc);
}

.next-checkbox-wrapper.disabled.checked .next-checkbox-inner > .next-icon,
.next-checkbox-wrapper.disabled.indeterminate
  .next-checkbox-inner
  > .next-icon {
  color: #555;
  color: var(--checkbox-disabled-circle-color, #555);
  opacity: 1;
}

.next-checkbox-wrapper.disabled.checked.focused .next-checkbox-inner {
  border-color: #c0c6cc;
  border-color: var(--checkbox-disabled-border-color, #c0c6cc);
  background: #f7f9fa;
  background: var(--checkbox-disabled-bg-color, #f7f9fa);
}

.next-checkbox-wrapper.disabled.checked.focused
  .next-checkbox-inner
  > .next-icon {
  color: #555;
  color: var(--checkbox-disabled-circle-color, #555);
  opacity: 1;
}

.next-checkbox-wrapper.disabled .next-checkbox-label {
  color: #ccc;
  color: var(--checkbox-disabled-label-color, #ccc);
  cursor: not-allowed;
}

.next-checkbox-group .next-checkbox-wrapper {
  display: inline-block;
  margin-right: 12px;
}

.next-checkbox-group .next-checkbox-wrapper:last-child {
  margin-right: 0;
}

.next-checkbox-group-ver .next-checkbox-wrapper {
  display: block;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 8px;
}

.next-checkbox-label {
  font-size: 12px;
  font-size: var(--checkbox-font-size, 12px);
  color: #333;
  color: var(--checkbox-label-color, #333);
  vertical-align: middle;
  margin: 0 0 0 8px;
  margin-left: var(--checkbox-margin-left, 8px);
  margin-right: 8px;
  margin-right: var(--checkbox-margin-left, 8px);
  line-height: 1;
}

.next-menu[dir="rtl"] .next-menu-item-helper {
  float: left;
}

.next-menu[dir="rtl"] .next-menu-item .next-checkbox,
.next-menu[dir="rtl"] .next-menu-item .next-radio {
  margin-left: 4px;
  margin-right: 0;
}

.next-menu[dir="rtl"] .next-menu-hoz-right {
  float: left;
}

.next-menu[dir="rtl"] .next-menu-hoz-icon-arrow.next-icon {
  left: 6px;
  right: auto;
}

.next-menu[dir="rtl"] .next-menu-icon-selected.next-icon {
  margin-left: 0;
  margin-right: -14px;
  margin-right: calc(-6px - var(--menu-padding-horizontal, 16px) / 2);
}

.next-menu[dir="rtl"] .next-menu-icon-selected.next-icon .next-icon-remote,
.next-menu[dir="rtl"] .next-menu-icon-selected.next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-menu[dir="rtl"] .next-menu-icon-selected.next-icon.next-menu-icon-right {
  right: auto;
  left: 8px;
}

.next-menu[dir="rtl"] .next-menu-icon-arrow.next-icon {
  left: 10px;
  right: auto;
}

.next-menu {
  position: relative;
  min-width: 100px;
  margin: 0;
  list-style: none;
  border: 1px solid #c0c6cc;
  border: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #c0c6cc);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--popup-local-corner, 2px);
  -moz-border-radius: var(--popup-local-corner, 2px);
  border-radius: var(--popup-local-corner, 2px);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --popup-local-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  background: #fff;
  background: var(--menu-background, #fff);
  line-height: 32px;
  line-height: var(--menu-line-height, 32px);
  font-size: 12px;
  font-size: var(--menu-font-size, 12px);
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
}

.next-menu,
.next-menu *,
.next-menu :after,
.next-menu :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-menu:focus,
.next-menu :focus {
  outline: 0;
}

.next-menu.next-ver {
  padding: 4px;
  padding: var(--menu-padding-ver-padding-tb, 4px)
    var(--menu-padding-ver-padding-lr, 4px);
}

.next-menu.next-ver .next-menu-item {
  padding: 0 24px;
}

.next-menu.next-hoz {
  padding: 8px 0;
}

.next-menu.next-hoz .next-menu-item {
  padding: 0 24px;
}

.next-menu-embeddable,
.next-menu-embeddable .next-menu-item.next-disabled,
.next-menu-embeddable .next-menu-item.next-disabled .next-menu-item-text > a {
  background: transparent;
  border: none;
}

.next-menu-embeddable {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-menu-embeddable .next-menu-item-inner {
  height: 100%;
}

.next-menu-content {
  position: relative;
}

.next-menu-content,
.next-menu-sub-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.next-menu-sub-menu.next-expand-enter {
  overflow: hidden;
}

.next-menu-sub-menu.next-expand-enter-active {
  -webkit-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  transition: height 0.3s ease;
}

.next-menu-sub-menu.next-expand-leave {
  overflow: hidden;
}

.next-menu-sub-menu.next-expand-leave-active {
  -webkit-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  transition: height 0.3s ease;
}

.next-menu-item {
  position: relative;
  -webkit-transition: background 0.1s linear;
  -o-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  transition: background 0.1s linear;
  color: #333;
  color: var(--menu-color, #333);
  cursor: pointer;
}

.next-menu-item-helper {
  float: right;
  color: #888;
  color: var(--color-text1-2, #888);
  font-style: normal;
  font-size: 12px;
  font-size: var(--menu-font-size, 12px);
}

.next-menu-item .next-checkbox,
.next-menu-item .next-radio {
  margin-right: 4px;
}

.next-menu-item.next-selected {
  color: #333;
  color: var(--menu-color-selected, #333);
  background-color: #fff;
  background-color: var(--menu-background-selected, #fff);
}

.next-menu-item.next-selected .next-menu-icon-arrow {
  color: #333;
  color: var(--menu-arrow-color, #333);
}

.next-menu-item.next-selected .next-menu-icon-selected {
  color: #333;
}

.next-menu-item.next-disabled,
.next-menu-item.next-disabled .next-menu-item-text > a {
  color: #ccc;
  color: var(--menu-color-disabled, #ccc);
  background-color: #fff;
  background-color: var(--menu-background, #fff);
  cursor: not-allowed;
}

.next-menu-item.next-disabled .next-menu-icon-arrow,
.next-menu-item.next-disabled .next-menu-icon-selected,
.next-menu-item.next-disabled .next-menu-item-text > a .next-menu-icon-arrow,
.next-menu-item.next-disabled
  .next-menu-item-text
  > a
  .next-menu-icon-selected {
  color: #ccc;
  color: var(--menu-color-disabled, #ccc);
}

.next-menu-item:not(.next-disabled).next-focused,
.next-menu-item:not(.next-disabled).next-selected.next-focused,
.next-menu-item:not(.next-disabled).next-selected.next-focused:hover,
.next-menu-item:not(.next-disabled).next-selected:focus,
.next-menu-item:not(.next-disabled).next-selected:focus:hover,
.next-menu-item:not(.next-disabled).next-selected:hover,
.next-menu-item:not(.next-disabled):hover {
  color: #333;
  color: var(--menu-color-hover, #333);
  background-color: #f7f9fa;
  background-color: var(--menu-background-hover, #f7f9fa);
}

.next-menu-item:not(.next-disabled).next-focused .next-menu-icon-arrow,
.next-menu-item:not(.next-disabled).next-selected.next-focused
  .next-menu-icon-arrow,
.next-menu-item:not(.next-disabled).next-selected.next-focused:hover
  .next-menu-icon-arrow,
.next-menu-item:not(.next-disabled).next-selected:focus .next-menu-icon-arrow,
.next-menu-item:not(.next-disabled).next-selected:focus:hover
  .next-menu-icon-arrow,
.next-menu-item:not(.next-disabled).next-selected:hover .next-menu-icon-arrow,
.next-menu-item:not(.next-disabled):hover .next-menu-icon-arrow {
  color: #333;
  color: var(--menu-arrow-color-hover, #333);
}

.next-menu-item:not(.next-disabled).next-focused .next-menu-icon-selected,
.next-menu-item:not(.next-disabled).next-selected.next-focused
  .next-menu-icon-selected,
.next-menu-item:not(.next-disabled).next-selected.next-focused:hover
  .next-menu-icon-selected,
.next-menu-item:not(.next-disabled).next-selected:focus
  .next-menu-icon-selected,
.next-menu-item:not(.next-disabled).next-selected:focus:hover
  .next-menu-icon-selected,
.next-menu-item:not(.next-disabled).next-selected:hover
  .next-menu-icon-selected,
.next-menu-item:not(.next-disabled):hover .next-menu-icon-selected {
  color: #333;
}

.next-menu-item-inner {
  height: 32px;
  height: var(--menu-line-height, 32px);
  font-size: 12px;
  font-size: var(--menu-font-size, 12px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.next-menu-item .next-menu-item-text {
  vertical-align: middle;
}

.next-menu-item .next-menu-item-text > a {
  display: inline-block;
  text-decoration: none;
  color: #333;
  color: var(--menu-color, #333);
}

.next-menu-item .next-menu-item-text > a:before {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
}

.next-menu.next-hoz {
  padding: 0;
}

.next-menu.next-hoz.next-menu-nowrap {
  overflow: hidden;
  white-space: nowrap;
}

.next-menu.next-hoz.next-menu-nowrap .next-menu-more {
  text-align: center;
}

.next-menu.next-hoz .next-menu-content > .next-menu-item,
.next-menu.next-hoz > .next-menu-item,
.next-menu.next-hoz > .next-menu-sub-menu-wrapper {
  display: inline-block;
  vertical-align: top;
}

.next-menu.next-hoz .next-menu-content,
.next-menu.next-hoz .next-menu-footer,
.next-menu.next-hoz .next-menu-header {
  display: inline-block;
}

.next-menu-hoz-right {
  float: right;
}

.next-menu-group-label {
  padding: 0 12px;
  padding: 0 var(--menu-padding-title-horizontal, 12px);
  color: #888;
  color: var(--color-text1-2, #888);
}

.next-menu-divider {
  margin: 8px 12px;
  margin: var(--menu-divider-margin-ver, 8px)
    var(--menu-divider-margin-hoz, 12px);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--menu-divider-width, 1px) var(--menu-divider-style, solid)
    var(--menu-divider-color, #e3e4e6);
}

.next-menu .next-menu-icon-selected.next-icon {
  position: absolute;
  top: 0;
  margin-left: -8px;
  margin-left: calc(8px - var(--menu-item-padding-ver-padding-l, 16px));
}

.next-menu .next-menu-icon-selected.next-icon .next-icon-remote,
.next-menu .next-menu-icon-selected.next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-menu .next-menu-icon-selected.next-icon.next-menu-icon-right {
  right: 8px;
}

.next-menu .next-menu-symbol-icon-selected.next-menu-icon-selected:before {
  content: "\E8C0";
  content: var(--menu-select-icon-content, "\E8C0");
}

.next-menu .next-menu-icon-arrow.next-icon {
  position: absolute;
  top: 0;
  right: 10px;
  color: #333;
  color: var(--menu-arrow-color, #333);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-menu .next-menu-icon-arrow.next-icon .next-icon-remote,
.next-menu .next-menu-icon-arrow.next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-menu .next-menu-icon-arrow-down:before {
  content: "\E63D";
  content: var(--menu-fold-icon-content, "\E63D");
}

.next-menu .next-menu-icon-arrow-down.next-open {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.next-menu .next-menu-icon-arrow-down.next-open .next-icon-remote,
.next-menu .next-menu-icon-arrow-down.next-open:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-menu .next-menu-symbol-popupfold:before {
  content: "\E619";
  content: var(--menu-popupfold-icon-content, "\E619");
}

.next-menu .next-menu-icon-arrow-right.next-open {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.next-menu .next-menu-icon-arrow-right.next-open .next-icon-remote,
.next-menu .next-menu-icon-arrow-right.next-open:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-menu .next-menu-hoz-icon-arrow.next-icon {
  position: absolute;
  top: 0;
  right: 6px;
  color: #333;
  color: var(--menu-arrow-color, #333);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-menu .next-menu-hoz-icon-arrow.next-icon .next-icon-remote,
.next-menu .next-menu-hoz-icon-arrow.next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-menu .next-menu-hoz-icon-arrow.next-icon:before {
  content: "\E63D";
  content: var(--menu-fold-icon-content, "\E63D");
}

.next-menu-unfold-icon:before {
  content: "";
  content: var(--menu-unfold-icon-content, "");
}

.next-menu .next-menu-hoz-icon-arrow.next-open {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.next-menu .next-menu-hoz-icon-arrow.next-open .next-icon-remote,
.next-menu .next-menu-hoz-icon-arrow.next-open:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-menu.next-context {
  line-height: 24px;
}

.next-menu.next-context .next-menu-item-inner {
  height: 24px;
}

.next-input {
  vertical-align: middle;
  display: inline-table;
  border-collapse: separate;
  font-size: 0;
  line-height: 1;
  /* width: 200px; */
  border-spacing: 0;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  border: 1px solid #c0c6cc;
  border: var(--input-border-width, 1px) solid
    var(--input-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--input-bg-color, #fff);
}

.next-input,
.next-input *,
.next-input :after,
.next-input :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-input input {
  height: 100%;
}

.next-input input[type="reset"],
.next-input input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

.next-input input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.next-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  -webkit-box-shadow: 0 0 0 1000px var(--input-bg-color, #fff) inset;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-large-corner, 2px);
  border-radius: var(--form-element-large-corner, 2px);
}

.next-input textarea {
  resize: none;
}

.next-input input,
.next-input textarea {
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-weight: 400;
  vertical-align: middle;
  background-color: transparent;
  color: #333;
  color: var(--input-text-color, #333);
}

.next-input input::-ms-clear,
.next-input textarea::-ms-clear {
  display: none;
}

.next-input.next-small {
  height: 24px;
  height: var(--form-element-small-height, 24px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-small-corner, 2px);
  -moz-border-radius: var(--form-element-small-corner, 2px);
  border-radius: var(--form-element-small-corner, 2px);
}

.next-input.next-small .next-input-label {
  padding-left: 8px;
  padding-left: var(--input-s-label-padding-left, 8px);
}

.next-input.next-small .next-input-inner,
.next-input.next-small .next-input-label {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-input.next-small .next-input-control {
  padding-right: 4px;
  padding-right: var(--input-s-icon-padding-right, 4px);
}

.next-input.next-small input {
  height: 22px;
  height: -webkit-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  line-height: 22px \0;
  line-height: -webkit-calc(
      var(--form-element-small-height, 24px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: -moz-calc(
      var(--form-element-small-height, 24px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: calc(
      var(--form-element-small-height, 24px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  padding: 0 12px;
  padding: 0 var(--input-s-padding, 12px);
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-input.next-small input::-webkit-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-input.next-small input::-moz-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-input.next-small input::-ms-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-input.next-small input:-moz-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-input.next-small input:-ms-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-input.next-small input::placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-input.next-small .next-input-text-field {
  padding: 0 12px;
  padding: 0 var(--input-s-padding, 12px);
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
  height: 22px;
  height: -webkit-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  line-height: 22px;
  line-height: -webkit-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  line-height: calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
}

.next-input.next-small .next-icon .next-icon-remote,
.next-input.next-small .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-input.next-small .next-input-control {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--form-element-small-corner, 2px)
    var(--form-element-small-corner, 2px) 0;
  -moz-border-radius: 0 var(--form-element-small-corner, 2px)
    var(--form-element-small-corner, 2px) 0;
  border-radius: 0 var(--form-element-small-corner, 2px)
    var(--form-element-small-corner, 2px) 0;
}

.next-input.next-medium {
  height: 32px;
  height: var(--form-element-medium-height, 32px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-medium-corner, 2px);
  -moz-border-radius: var(--form-element-medium-corner, 2px);
  border-radius: var(--form-element-medium-corner, 2px);
}

.next-input.next-medium .next-input-label {
  padding-left: 16px;
  padding-left: var(--input-m-label-padding-left, 16px);
}

.next-input.next-medium .next-input-inner,
.next-input.next-medium .next-input-label {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-input.next-medium .next-input-control {
  padding-right: 8px;
  padding-right: var(--input-m-icon-padding-right, 8px);
}

.next-input.next-medium input {
  height: 30px;
  height: -webkit-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  line-height: 30px \0;
  line-height: -webkit-calc(
      var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: -moz-calc(
      var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: calc(
      var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  padding: 0 12px;
  padding: 0 var(--input-m-padding, 12px);
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-input.next-medium input::-webkit-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-input.next-medium input::-moz-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-input.next-medium input::-ms-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-input.next-medium input:-moz-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-input.next-medium input:-ms-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-input.next-medium input::placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-input.next-medium .next-input-text-field {
  padding: 0 12px;
  padding: 0 var(--input-m-padding, 12px);
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
  height: 30px;
  height: -webkit-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  line-height: 30px;
  line-height: -webkit-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  line-height: calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
}

.next-input.next-medium .next-icon .next-icon-remote,
.next-input.next-medium .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-input.next-medium .next-input-control {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--form-element-medium-corner, 2px)
    var(--form-element-medium-corner, 2px) 0;
  -moz-border-radius: 0 var(--form-element-medium-corner, 2px)
    var(--form-element-medium-corner, 2px) 0;
  border-radius: 0 var(--form-element-medium-corner, 2px)
    var(--form-element-medium-corner, 2px) 0;
}

.next-input.next-large {
  height: 36px;
  height: var(--form-element-large-height, 36px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-large-corner, 2px);
  -moz-border-radius: var(--form-element-large-corner, 2px);
  border-radius: var(--form-element-large-corner, 2px);
}

.next-input.next-large .next-input-label {
  padding-left: 16px;
  padding-left: var(--input-l-label-padding-left, 16px);
}

.next-input.next-large .next-input-inner,
.next-input.next-large .next-input-label {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-input.next-large .next-input-control {
  padding-right: 12px;
  padding-right: var(--input-l-icon-padding-right, 12px);
}

.next-input.next-large input {
  height: 34px;
  height: -webkit-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  line-height: 34px \0;
  line-height: -webkit-calc(
      var(--form-element-large-height, 36px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: -moz-calc(
      var(--form-element-large-height, 36px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: calc(
      var(--form-element-large-height, 36px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  padding: 0 12px;
  padding: 0 var(--input-l-padding, 12px);
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-input.next-large input::-webkit-input-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-input.next-large input::-moz-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-input.next-large input::-ms-input-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-input.next-large input:-moz-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-input.next-large input:-ms-input-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-input.next-large input::placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-input.next-large .next-input-text-field {
  padding: 0 12px;
  padding: 0 var(--input-l-padding, 12px);
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
  height: 34px;
  height: -webkit-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  line-height: 34px;
  line-height: -webkit-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  line-height: calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
}

.next-input.next-large .next-icon .next-icon-remote,
.next-input.next-large .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-input.next-large .next-input-control {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--form-element-large-corner, 2px)
    var(--form-element-large-corner, 2px) 0;
  -moz-border-radius: 0 var(--form-element-large-corner, 2px)
    var(--form-element-large-corner, 2px) 0;
  border-radius: 0 var(--form-element-large-corner, 2px)
    var(--form-element-large-corner, 2px) 0;
}

.next-input.next-input-textarea {
  height: auto;
  font-size: 0;
}

.next-input.next-input-textarea,
.next-input.next-input-textarea textarea {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--input-multiple-corner, 2px);
  -moz-border-radius: var(--input-multiple-corner, 2px);
  border-radius: var(--input-multiple-corner, 2px);
}

.next-input.next-input-textarea textarea {
  color: #333;
  color: var(--input-text-color, #333);
  padding: 12px 16px;
  padding: var(--input-multiple-padding-tb, 12px)
    var(--input-multiple-padding-lr, 16px);
  font-size: 12px;
  font-size: var(--input-multiple-font-size, 12px);
}

.next-input.next-input-textarea.next-small textarea {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-input.next-input-textarea.next-large textarea {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-input.next-input-textarea .next-input-control {
  display: block;
  width: auto;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--input-multiple-corner, 2px);
  -moz-border-radius: var(--input-multiple-corner, 2px);
  border-radius: var(--input-multiple-corner, 2px);
}

.next-input.next-input-textarea .next-input-len {
  padding: 0 12px 4px;
  padding: 0 var(--input-l-icon-padding-right, 12px) 4px;
  display: block;
  text-align: right;
  width: auto;
}

.next-input-hint-wrap {
  color: #888;
  color: var(--input-hint-color, #888);
  position: relative;
}

.next-input-hint-wrap .next-input-clear {
  opacity: 0;
  z-index: 1;
  position: absolute;
}

.next-input-hint-wrap .next-input-hint {
  opacity: 1;
}

.next-input .next-icon-eye-close:hover,
.next-input .next-icon-eye:hover,
.next-input .next-input-clear-icon:hover {
  cursor: pointer;
  color: #555;
  color: var(--input-hint-hover-color, #555);
}

.next-input.next-focus,
.next-input:hover {
  border-color: #c0c6cc;
  border-color: var(--input-hover-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--input-hover-bg-color, #fff);
}

.next-input.next-focus .next-input-clear,
.next-input:hover .next-input-clear {
  opacity: 1;
}

.next-input.next-focus .next-input-clear + .next-input-hint,
.next-input:hover .next-input-clear + .next-input-hint {
  opacity: 0;
}

.next-input .next-input-clear:focus {
  opacity: 1;
}

.next-input .next-input-clear:focus + .next-input-hint {
  opacity: 0;
}

.next-input.next-focus {
  border-color: #0064c8;
  border-color: var(--input-focus-border-color, #0064c8);
  background-color: #fff;
  background-color: var(--input-focus-bg-color, #fff);
  -webkit-box-shadow: 0 0 0 0 rgba(0, 100, 200, 0.2);
  -moz-box-shadow: 0 0 0 0 rgba(0, 100, 200, 0.2);
  box-shadow: 0 0 0 0 rgba(0, 100, 200, 0.2);
}

.next-input.next-warning {
  background-color: #fff;
  background-color: var(--input-feedback-warning-bg-color, #fff);
}

.next-input.next-warning,
.next-input.next-warning.next-focus,
.next-input.next-warning:hover {
  border-color: #fff3b4;
  border-color: var(--input-feedback-warning-border-color, #fff3b4);
}

.next-input.next-warning.next-focus {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 243, 180, 0.2);
  -moz-box-shadow: 0 0 0 0 rgba(255, 243, 180, 0.2);
  box-shadow: 0 0 0 0 rgba(255, 243, 180, 0.2);
}

.next-input.next-error {
  background-color: #fff;
  background-color: var(--input-feedback-error-bg-color, #fff);
}

.next-input.next-error,
.next-input.next-error.next-focus,
.next-input.next-error:hover {
  border-color: #c80000;
  border-color: var(--input-feedback-error-border-color, #c80000);
}

.next-input.next-error.next-focus {
  -webkit-box-shadow: 0 0 0 0 rgba(200, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0 0 rgba(200, 0, 0, 0.2);
  box-shadow: 0 0 0 0 rgba(200, 0, 0, 0.2);
}

.next-input.next-hidden {
  display: none;
}

.next-input.next-noborder {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-input-control .next-input-len {
  font-size: 12px;
  font-size: var(--input-maxlen-font-size, 12px);
  line-height: 12px;
  line-height: var(--input-maxlen-font-size, 12px);
  color: #888;
  color: var(--input-maxlen-color, #888);
  display: table-cell;
  width: 1px;
  vertical-align: bottom;
}

.next-input-control .next-input-len.next-error {
  color: #f8efef;
  color: var(--input-maxlen-error-color, #f8efef);
}

.next-input-control .next-input-len.next-warning {
  color: #fff3b4;
  color: var(--input-maxlen-warning-color, #fff3b4);
}

.next-input-control > * {
  display: table-cell;
  width: 1%;
  top: 0;
}

.next-input-control > :not(:last-child) {
  padding-right: 4px;
}

.next-input-control .next-icon {
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  color: #888;
  color: var(--input-hint-color, #888);
}

.next-input-control .next-input-warning-icon {
  color: #fff3b4;
  color: var(--input-feedback-warning-color, #fff3b4);
}

.next-input-control .next-input-warning-icon:before {
  content: "\E60B";
  content: var(--input-feedback-warning-icon, "\E60B");
}

.next-input-control .next-input-success-icon {
  color: #c1f0c2;
  color: var(--input-feedback-success-color, #c1f0c2);
}

.next-input-control .next-input-success-icon:before {
  content: "\E63A";
  content: var(--input-feedback-success-icon, "\E63A");
}

.next-input-control .next-input-loading-icon {
  color: #888;
  color: var(--input-feedback-loading-color, #888);
}

.next-input-control .next-input-loading-icon:before {
  content: "\E646";
  content: var(--input-feedback-loading-icon, "\E646");
  -webkit-animation: loadingCircle 1s linear infinite;
  -moz-animation: loadingCircle 1s infinite linear;
  -o-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s linear infinite;
}

.next-input-control .next-input-clear-icon:before {
  content: "\E697";
  content: var(--input-feedback-clear-icon, "\E697");
}

.next-input-label {
  color: #555;
  color: var(--input-label-color, #555);
}

.next-input input::-moz-placeholder,
.next-input textarea::-moz-placeholder {
  color: #666;
  color: var(--input-placeholder-color, #666);
  opacity: 1;
}

.next-input input:-ms-input-placeholder,
.next-input textarea:-ms-input-placeholder {
  color: #666;
  color: var(--input-placeholder-color, #666);
}

.next-input input::-webkit-input-placeholder,
.next-input textarea::-webkit-input-placeholder {
  color: #666;
  color: var(--input-placeholder-color, #666);
}

.next-input.next-disabled {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
}

.next-input.next-disabled,
.next-input.next-disabled:hover {
  border-color: #c0c6cc;
  border-color: var(--input-disabled-border-color, #c0c6cc);
  background-color: #f7f9fa;
  background-color: var(--input-disabled-bg-color, #f7f9fa);
}

.next-input.next-disabled input,
.next-input.next-disabled textarea {
  -webkit-text-fill-color: #ccc;
  -webkit-text-fill-color: var(--input-disabled-color, #ccc);
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
}

.next-input.next-disabled input::-moz-placeholder,
.next-input.next-disabled textarea::-moz-placeholder {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
  opacity: 1;
}

.next-input.next-disabled input:-ms-input-placeholder,
.next-input.next-disabled textarea:-ms-input-placeholder {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
}

.next-input.next-disabled input::-webkit-input-placeholder,
.next-input.next-disabled textarea::-webkit-input-placeholder {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
}

.next-input.next-disabled .next-input-hint-wrap,
.next-input.next-disabled .next-input-label,
.next-input.next-disabled .next-input-len {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
}

.next-input.next-disabled .next-input-hint-wrap .next-input-clear {
  opacity: 0;
}

.next-input.next-disabled .next-input-hint-wrap .next-input-hint {
  opacity: 1;
}

.next-input.next-disabled .next-input-hint-wrap .next-input-clear-icon:hover {
  cursor: not-allowed;
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
}

.next-input.next-disabled .next-icon {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
}

.next-input-control,
.next-input-inner,
.next-input-label {
  display: table-cell;
  width: 1px;
  vertical-align: middle;
  line-height: 1;
  background-color: transparent;
  white-space: nowrap;
}

.next-input-group {
  display: inline-table;
  border-collapse: separate;
  border-spacing: 0;
  line-height: 0;
  width: 100%;
}

.next-input-group,
.next-input-group *,
.next-input-group :after,
.next-input-group :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-input-group-auto-width {
  width: 100%;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.next-input-group > .next-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.next-input-group > .next-input.next-focus {
  position: relative;
  z-index: 1;
}

.next-input-group > .next-input:first-child.next-small {
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(--form-element-small-corner, 2px) !important;
  border-top-left-radius: var(--form-element-small-corner, 2px) !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --form-element-small-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(--form-element-small-corner, 2px) !important;
}

.next-input-group > .next-input:first-child.next-medium {
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(--form-element-medium-corner, 2px) !important;
  border-top-left-radius: var(--form-element-medium-corner, 2px) !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --form-element-medium-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(--form-element-medium-corner, 2px) !important;
}

.next-input-group > .next-input:first-child.next-large {
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(--form-element-large-corner, 2px) !important;
  border-top-left-radius: var(--form-element-large-corner, 2px) !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --form-element-large-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(--form-element-large-corner, 2px) !important;
}

.next-input-group > .next-input:last-child.next-small {
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(--form-element-small-corner, 2px) !important;
  border-top-right-radius: var(--form-element-small-corner, 2px) !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --form-element-small-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(--form-element-small-corner, 2px) !important;
}

.next-input-group > .next-input:last-child.next-medium {
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(
    --form-element-medium-corner,
    2px
  ) !important;
  border-top-right-radius: var(--form-element-medium-corner, 2px) !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --form-element-medium-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(--form-element-medium-corner, 2px) !important;
}

.next-input-group > .next-input:last-child.next-large {
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(--form-element-large-corner, 2px) !important;
  border-top-right-radius: var(--form-element-large-corner, 2px) !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --form-element-large-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(--form-element-large-corner, 2px) !important;
}

.next-input-group-addon {
  width: 1px;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
}

.next-input-group-addon:first-child,
.next-input-group-addon:first-child > * {
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  border-bottom-right-radius: 0 !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  border-top-right-radius: 0 !important;
}

.next-input-group-addon:first-child > * {
  margin-right: -1px;
  margin-right: calc(0px - var(--input-border-width, 1px));
}

.next-input-group-addon:first-child > .next-focus {
  position: relative;
  z-index: 1;
}

.next-input-group-addon:first-child > * > .next-input {
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  border-bottom-right-radius: 0 !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  border-top-right-radius: 0 !important;
}

.next-input-group-addon:first-child > * > .next-input.next-focus {
  position: relative;
  z-index: 1;
}

.next-input-group-addon:last-child,
.next-input-group-addon:last-child > * {
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-bottom-left-radius: 0 !important;
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-left-radius: 0 !important;
}

.next-input-group-addon:last-child > * {
  margin-left: -1px;
  margin-left: calc(0px - var(--input-border-width, 1px));
}

.next-input-group-addon:last-child > * > .next-input {
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-bottom-left-radius: 0 !important;
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-left-radius: 0 !important;
}

.next-input-group-text {
  color: #888;
  color: var(--input-addon-text-color, #888);
  background-color: #f8f8f9;
  background-color: var(--input-addon-bg-color, #f8f8f9);
  text-align: center;
  border: 1px solid #c0c6cc;
  border: var(--input-border-width, 1px) solid
    var(--input-border-color, #c0c6cc);
  padding: 0 8px;
  padding: 0 var(--input-addon-padding, 8px);
}

.next-input-group-text:first-child {
  border-right-width: 0;
}

.next-input-group-text:last-child {
  border-left-width: 0;
}

.next-input-group-text.next-disabled {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
  cursor: not-allowed;
}

.next-input-group-text.next-disabled,
.next-input-group-text.next-disabled:hover {
  border-color: #c0c6cc;
  border-color: var(--input-disabled-border-color, #c0c6cc);
  background-color: #f7f9fa;
  background-color: var(--input-disabled-bg-color, #f7f9fa);
}

.next-input-group-text.next-small {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-small-corner, 2px);
  -moz-border-radius: var(--form-element-small-corner, 2px);
  border-radius: var(--form-element-small-corner, 2px);
}

.next-input-group-text.next-medium {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-medium-corner, 2px);
  -moz-border-radius: var(--form-element-medium-corner, 2px);
  border-radius: var(--form-element-medium-corner, 2px);
}

.next-input-group-text.next-large {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-large-corner, 2px);
  -moz-border-radius: var(--form-element-large-corner, 2px);
  border-radius: var(--form-element-large-corner, 2px);
}

.next-input[dir="rtl"].next-small .next-input-label {
  padding-left: 0;
  padding-right: 8px;
  padding-right: var(--input-s-label-padding-left, 8px);
}

.next-input[dir="rtl"].next-small .next-input-control {
  padding-right: 0;
  padding-left: 4px;
  padding-left: var(--input-s-icon-padding-right, 4px);
}

.next-input[dir="rtl"].next-medium .next-input-label {
  padding-left: 0;
  padding-right: 16px;
  padding-right: var(--input-m-label-padding-left, 16px);
}

.next-input[dir="rtl"].next-medium .next-input-control {
  padding-right: 0;
  padding-left: 8px;
  padding-left: var(--input-m-icon-padding-right, 8px);
}

.next-input[dir="rtl"].next-large .next-input-label {
  padding-left: 0;
  padding-right: 16px;
  padding-right: var(--input-l-label-padding-left, 16px);
}

.next-input[dir="rtl"].next-large .next-input-control {
  padding-right: 0;
  padding-left: 12px;
  padding-left: var(--input-l-icon-padding-right, 12px);
}

.next-input[dir="rtl"].next-input-textarea .next-input-len {
  text-align: left;
}

.next-input[dir="rtl"] .next-input-control > :not(:last-child) {
  padding-left: 4px;
  padding-right: 0;
}

.next-input-group[dir="rtl"] > .next-input:first-child.next-small {
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-left-radius: 0 !important;
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-bottom-left-radius: 0 !important;
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(--form-element-small-corner, 2px) !important;
  border-top-right-radius: var(--form-element-small-corner, 2px) !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --form-element-small-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(--form-element-small-corner, 2px) !important;
}

.next-input-group[dir="rtl"] > .next-input:first-child.next-medium {
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-left-radius: 0 !important;
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-bottom-left-radius: 0 !important;
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(
    --form-element-medium-corner,
    2px
  ) !important;
  border-top-right-radius: var(--form-element-medium-corner, 2px) !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --form-element-medium-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(--form-element-medium-corner, 2px) !important;
}

.next-input-group[dir="rtl"] > .next-input:first-child.next-large {
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-left-radius: 0 !important;
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-bottom-left-radius: 0 !important;
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(--form-element-large-corner, 2px) !important;
  border-top-right-radius: var(--form-element-large-corner, 2px) !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --form-element-large-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(--form-element-large-corner, 2px) !important;
}

.next-input-group[dir="rtl"] > .next-input:last-child.next-small {
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(--form-element-small-corner, 2px) !important;
  border-top-left-radius: var(--form-element-small-corner, 2px) !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --form-element-small-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(--form-element-small-corner, 2px) !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  border-top-right-radius: 0 !important;
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.next-input-group[dir="rtl"] > .next-input:last-child.next-medium {
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(--form-element-medium-corner, 2px) !important;
  border-top-left-radius: var(--form-element-medium-corner, 2px) !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --form-element-medium-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(--form-element-medium-corner, 2px) !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  border-top-right-radius: 0 !important;
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.next-input-group[dir="rtl"] > .next-input:last-child.next-large {
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(--form-element-large-corner, 2px) !important;
  border-top-left-radius: var(--form-element-large-corner, 2px) !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --form-element-large-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(--form-element-large-corner, 2px) !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  border-top-right-radius: 0 !important;
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:first-child,
.next-input-group[dir="rtl"]
  .next-input-group-addon:first-child
  > *
  > .next-input,
.next-input-group[dir="rtl"] .next-input-group-addon:first-child > .next-input {
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-bottom-left-radius: 0 !important;
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-left-radius: 0 !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:first-child.next-small,
.next-input-group[dir="rtl"]
  .next-input-group-addon:first-child
  > *
  > .next-input.next-small,
.next-input-group[dir="rtl"]
  .next-input-group-addon:first-child
  > .next-input.next-small {
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --form-element-small-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(--form-element-small-corner, 2px) !important;
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(--form-element-small-corner, 2px) !important;
  border-top-right-radius: var(--form-element-small-corner, 2px) !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:first-child.next-medium,
.next-input-group[dir="rtl"]
  .next-input-group-addon:first-child
  > *
  > .next-input.next-medium,
.next-input-group[dir="rtl"]
  .next-input-group-addon:first-child
  > .next-input.next-medium {
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --form-element-medium-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(--form-element-medium-corner, 2px) !important;
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(
    --form-element-medium-corner,
    2px
  ) !important;
  border-top-right-radius: var(--form-element-medium-corner, 2px) !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:first-child.next-large,
.next-input-group[dir="rtl"]
  .next-input-group-addon:first-child
  > *
  > .next-input.next-large,
.next-input-group[dir="rtl"]
  .next-input-group-addon:first-child
  > .next-input.next-large {
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --form-element-large-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(--form-element-large-corner, 2px) !important;
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(--form-element-large-corner, 2px) !important;
  border-top-right-radius: var(--form-element-large-corner, 2px) !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:first-child > * {
  margin-left: -1px;
  margin-left: calc(0px - var(--input-border-width, 1px));
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-bottom-left-radius: 0 !important;
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-left-radius: 0 !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:last-child,
.next-input-group[dir="rtl"]
  .next-input-group-addon:last-child
  > *
  > .next-input,
.next-input-group[dir="rtl"] .next-input-group-addon:last-child > .next-input {
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  border-bottom-right-radius: 0 !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  border-top-right-radius: 0 !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:last-child.next-small,
.next-input-group[dir="rtl"]
  .next-input-group-addon:last-child
  > *
  > .next-input.next-small,
.next-input-group[dir="rtl"]
  .next-input-group-addon:last-child
  > .next-input.next-small {
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --form-element-small-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(--form-element-small-corner, 2px) !important;
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --form-element-small-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(--form-element-small-corner, 2px) !important;
  border-top-left-radius: var(--form-element-small-corner, 2px) !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:last-child.next-medium,
.next-input-group[dir="rtl"]
  .next-input-group-addon:last-child
  > *
  > .next-input.next-medium,
.next-input-group[dir="rtl"]
  .next-input-group-addon:last-child
  > .next-input.next-medium {
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --form-element-medium-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(--form-element-medium-corner, 2px) !important;
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --form-element-medium-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(--form-element-medium-corner, 2px) !important;
  border-top-left-radius: var(--form-element-medium-corner, 2px) !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:last-child.next-large,
.next-input-group[dir="rtl"]
  .next-input-group-addon:last-child
  > *
  > .next-input.next-large,
.next-input-group[dir="rtl"]
  .next-input-group-addon:last-child
  > .next-input.next-large {
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --form-element-large-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(--form-element-large-corner, 2px) !important;
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --form-element-large-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(--form-element-large-corner, 2px) !important;
  border-top-left-radius: var(--form-element-large-corner, 2px) !important;
}

.next-input-group[dir="rtl"] .next-input-group-addon:last-child > * {
  margin-right: -1px;
  margin-right: calc(0px - var(--input-border-width, 1px));
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  border-bottom-right-radius: 0 !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-radius-topright: 0 !important;
  border-top-right-radius: 0 !important;
}

.next-input-group[dir="rtl"] .next-input-group-text:first-child {
  border-right-width: 1px;
  border-right-width: var(--input-border-width, 1px);
  border-left: 0;
}

.next-input-group[dir="rtl"] .next-input-group-text:last-child {
  border-left-width: 1px;
  border-left-width: var(--input-border-width, 1px);
  border-right: 0;
}

.next-select {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

.next-select,
.next-select *,
.next-select :after,
.next-select :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-select-trigger {
  min-width: 100px;
  outline: 0;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-select-trigger .next-input-label {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
}

.next-select-trigger .next-select-values {
  display: block;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  -webkit-flex: 1 1 0;
  -moz-box-flex: 1;
  flex: 1 1 0;
  overflow: hidden;
}

.next-select-trigger .next-select-values > em {
  font-style: inherit;
}

.next-select-trigger .next-select-values input {
  padding-left: 0;
  padding-right: 0;
}

.next-select-trigger .next-input-control {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
}

.next-select-trigger .next-input-control > * {
  display: inline-block;
  width: auto;
}

.next-select-trigger .next-input-control > .next-select-arrow {
  padding-right: 0;
}

.next-select-trigger .next-input.next-disabled em {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
}

.next-select-trigger .next-input.next-disabled .next-select-arrow {
  cursor: not-allowed;
}

.next-select-trigger .next-select-clear {
  display: none;
}

.next-select-trigger.next-has-clear:hover .next-select-clear {
  display: inline-block;
}

.next-select-trigger.next-has-clear:hover .next-select-arrow {
  display: none;
}

.next-select .next-select-inner {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
  min-width: 100px;
  outline: 0;
  color: #333;
  color: var(--select-color, #333);
}

.next-select .next-select-inner .next-tag {
  line-height: 1;
  margin-right: 4px;
  margin-bottom: 3px;
  padding-left: 0;
  padding-right: 0;
}

.next-select .next-select-inner .next-input-inner {
  width: auto;
}

.next-select-trigger-search {
  position: relative;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

.next-select-trigger-search > input,
.next-select-trigger-search > span {
  display: block;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  white-space: nowrap;
  overflow: hidden;
}

.next-select-trigger-search input {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100% !important;
  z-index: 1;
  left: 0;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  cursor: inherit;
}

.next-select-trigger-search > span {
  position: relative;
  visibility: hidden;
  white-space: pre;
  max-width: 100%;
  z-index: -1;
}

.next-select-single.next-no-search {
  cursor: pointer;
}

.next-select-single.next-has-search.next-active .next-select-values > em {
  display: none;
}

.next-select-single.next-inactive
  .next-select-values
  > em
  + .next-select-trigger-search,
.next-select-single.next-no-search
  .next-select-values
  > em
  + .next-select-trigger-search {
  width: 1px;
  opacity: 0;
  filter: alpha(opacity=0);
}

.next-select-single .next-select-values {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
}

.next-select-single .next-select-values > em {
  vertical-align: middle;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.next-select-multiple .next-select-compact {
  position: relative;
  white-space: nowrap;
}

.next-select-multiple .next-select-compact .next-select-trigger-search {
  width: auto;
}

.next-select-multiple .next-select-compact .next-select-tag-compact {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 0 4px 0 16px;
  color: #333;
  color: var(--input-text-color, #333);
  background: -webkit-linear-gradient(left, transparent, #fff 10px);
  background: -moz-linear-gradient(left, transparent, #fff 10px);
  background: -o-linear-gradient(left, transparent, #fff 10px);
  background: linear-gradient(90deg, transparent, #fff 10px);
  background: -webkit-linear-gradient(
    left,
    transparent,
    var(--input-bg-color, #fff) 10px
  );
  background: -moz-linear-gradient(
    left,
    transparent,
    var(--input-bg-color, #fff) 10px
  );
  background: -o-linear-gradient(
    left,
    transparent,
    var(--input-bg-color, #fff) 10px
  );
  background: linear-gradient(
    90deg,
    transparent,
    var(--input-bg-color, #fff) 10px
  );
}

.next-select-multiple .next-disabled .next-select-tag-compact {
  background: -webkit-linear-gradient(left, transparent, #f7f9fa 10px);
  background: -moz-linear-gradient(left, transparent, #f7f9fa 10px);
  background: -o-linear-gradient(left, transparent, #f7f9fa 10px);
  background: linear-gradient(90deg, transparent, #f7f9fa 10px);
  background: -webkit-linear-gradient(
    left,
    transparent,
    var(--input-disabled-bg-color, #f7f9fa) 10px
  );
  background: -moz-linear-gradient(
    left,
    transparent,
    var(--input-disabled-bg-color, #f7f9fa) 10px
  );
  background: -o-linear-gradient(
    left,
    transparent,
    var(--input-disabled-bg-color, #f7f9fa) 10px
  );
  background: linear-gradient(
    90deg,
    transparent,
    var(--input-disabled-bg-color, #f7f9fa) 10px
  );
}

.next-select-multiple .next-select-values,
.next-select-tag .next-select-values {
  margin-bottom: -3px;
  height: auto !important;
}

.next-select-multiple .next-select-trigger-search,
.next-select-tag .next-select-trigger-search {
  margin-bottom: 3px;
}

.next-select-multiple .next-tag + .next-select-trigger-search,
.next-select-tag .next-tag + .next-select-trigger-search {
  width: auto;
  min-width: 1px;
}

.next-select-multiple .next-input,
.next-select-tag .next-input {
  height: auto;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: start;
  -moz-box-align: start;
  align-items: start;
}

.next-select-multiple.next-small .next-select-values,
.next-select-tag.next-small .next-select-values {
  min-height: 22px;
  min-height: -webkit-calc(var(--form-element-small-height, 24px) - 2px);
  min-height: -moz-calc(var(--form-element-small-height, 24px) - 2px);
  min-height: calc(var(--form-element-small-height, 24px) - 2px);
  padding-top: 2px;
  padding-top: calc(
    var(--form-element-small-height, 24px) / 2 -
      var(--select-s-lineheight, 18px) / 2 - 1px
  );
  padding-bottom: 2px;
  padding-bottom: calc(
    var(--form-element-small-height, 24px) / 2 -
      var(--select-s-lineheight, 18px) / 2 - 1px
  );
  line-height: 18px;
  line-height: var(--select-s-lineheight, 18px);
}

.next-select-multiple.next-small .next-select-values-compact,
.next-select-tag.next-small .next-select-values-compact {
  height: 24px !important;
  height: var(--form-element-small-height, 24px) !important;
}

.next-select-multiple.next-small .next-tag,
.next-select-tag.next-small .next-tag {
  border: 0;
  padding-top: 2px;
  padding-top: calc(var(--select-s-lineheight, 18px) / 2 - 7px);
  padding-bottom: 2px;
  padding-bottom: calc(var(--select-s-lineheight, 18px) / 2 - 7px);
  height: 18px;
  height: var(--select-s-lineheight, 18px);
}

.next-select-multiple.next-small .next-tag .next-tag-body,
.next-select-multiple.next-small .next-tag .next-tag-close-btn,
.next-select-tag.next-small .next-tag .next-tag-body,
.next-select-tag.next-small .next-tag .next-tag-close-btn {
  line-height: 14px;
  line-height: -webkit-calc(
    var(--select-s-lineheight, 18px) - var(--select-s-lineheight, 18px) / 2 * 2 +
      14px
  );
  line-height: -moz-calc(
    var(--select-s-lineheight, 18px) - var(--select-s-lineheight, 18px) / 2 * 2 +
      14px
  );
  line-height: calc(
    var(--select-s-lineheight, 18px) - var(--select-s-lineheight, 18px) / 2 * 2 +
      14px
  );
}

.next-select-multiple.next-small .next-tag-body,
.next-select-tag.next-small .next-tag-body {
  line-height: 18px;
  line-height: var(--select-s-lineheight, 18px);
}

.next-select-multiple.next-small .next-input-control,
.next-select-multiple.next-small .next-input-label,
.next-select-multiple.next-small .next-select-tag-compact,
.next-select-tag.next-small .next-input-control,
.next-select-tag.next-small .next-input-label,
.next-select-tag.next-small .next-select-tag-compact {
  line-height: 22px;
  line-height: -webkit-calc(var(--form-element-small-height, 24px) - 2px);
  line-height: -moz-calc(var(--form-element-small-height, 24px) - 2px);
  line-height: calc(var(--form-element-small-height, 24px) - 2px);
}

.next-select-multiple.next-medium .next-select-values,
.next-select-tag.next-medium .next-select-values {
  min-height: 30px;
  min-height: -webkit-calc(var(--form-element-medium-height, 32px) - 2px);
  min-height: -moz-calc(var(--form-element-medium-height, 32px) - 2px);
  min-height: calc(var(--form-element-medium-height, 32px) - 2px);
  padding-top: 4px;
  padding-top: calc(
    var(--form-element-medium-height, 32px) / 2 -
      var(--select-m-lineheight, 22px) / 2 - 1px
  );
  padding-bottom: 4px;
  padding-bottom: calc(
    var(--form-element-medium-height, 32px) / 2 -
      var(--select-m-lineheight, 22px) / 2 - 1px
  );
  line-height: 22px;
  line-height: var(--select-m-lineheight, 22px);
}

.next-select-multiple.next-medium .next-select-values-compact,
.next-select-tag.next-medium .next-select-values-compact {
  height: 32px !important;
  height: var(--form-element-medium-height, 32px) !important;
}

.next-select-multiple.next-medium .next-tag,
.next-select-tag.next-medium .next-tag {
  padding-top: 2px;
  padding-top: calc(var(--select-m-lineheight, 22px) / 2 - 9px);
  padding-bottom: 2px;
  padding-bottom: calc(var(--select-m-lineheight, 22px) / 2 - 9px);
  height: 22px;
  height: var(--select-m-lineheight, 22px);
}

.next-select-multiple.next-medium .next-tag .next-tag-body,
.next-select-multiple.next-medium .next-tag .next-tag-close-btn,
.next-select-tag.next-medium .next-tag .next-tag-body,
.next-select-tag.next-medium .next-tag .next-tag-close-btn {
  line-height: 18px;
  line-height: -webkit-calc(
    var(--select-m-lineheight, 22px) - var(--select-m-lineheight, 22px) / 2 * 2 +
      18px
  );
  line-height: -moz-calc(
    var(--select-m-lineheight, 22px) - var(--select-m-lineheight, 22px) / 2 * 2 +
      18px
  );
  line-height: calc(
    var(--select-m-lineheight, 22px) - var(--select-m-lineheight, 22px) / 2 * 2 +
      18px
  );
}

.next-select-multiple.next-medium .next-input-control,
.next-select-multiple.next-medium .next-input-label,
.next-select-multiple.next-medium .next-select-tag-compact,
.next-select-tag.next-medium .next-input-control,
.next-select-tag.next-medium .next-input-label,
.next-select-tag.next-medium .next-select-tag-compact {
  line-height: 30px;
  line-height: -webkit-calc(var(--form-element-medium-height, 32px) - 2px);
  line-height: -moz-calc(var(--form-element-medium-height, 32px) - 2px);
  line-height: calc(var(--form-element-medium-height, 32px) - 2px);
}

.next-select-multiple.next-large .next-select-values,
.next-select-tag.next-large .next-select-values {
  min-height: 34px;
  min-height: -webkit-calc(var(--form-element-large-height, 36px) - 2px);
  min-height: -moz-calc(var(--form-element-large-height, 36px) - 2px);
  min-height: calc(var(--form-element-large-height, 36px) - 2px);
  padding-top: 6px;
  padding-top: calc(
    var(--form-element-large-height, 36px) / 2 -
      var(--select-l-lineheight, 22px) / 2 - 1px
  );
  padding-bottom: 6px;
  padding-bottom: calc(
    var(--form-element-large-height, 36px) / 2 -
      var(--select-l-lineheight, 22px) / 2 - 1px
  );
  line-height: 22px;
  line-height: var(--select-l-lineheight, 22px);
}

.next-select-multiple.next-large .next-select-values-compact,
.next-select-tag.next-large .next-select-values-compact {
  height: 36px !important;
  height: var(--form-element-large-height, 36px) !important;
}

.next-select-multiple.next-large .next-tag,
.next-select-tag.next-large .next-tag {
  padding-top: 2px;
  padding-top: calc(var(--select-l-lineheight, 22px) / 2 - 9px);
  padding-bottom: 2px;
  padding-bottom: calc(var(--select-l-lineheight, 22px) / 2 - 9px);
  height: 22px;
  height: var(--select-l-lineheight, 22px);
}

.next-select-multiple.next-large .next-tag .next-tag-body,
.next-select-multiple.next-large .next-tag .next-tag-close-btn,
.next-select-tag.next-large .next-tag .next-tag-body,
.next-select-tag.next-large .next-tag .next-tag-close-btn {
  line-height: 18px;
  line-height: -webkit-calc(
    var(--select-l-lineheight, 22px) - var(--select-l-lineheight, 22px) / 2 * 2 +
      18px
  );
  line-height: -moz-calc(
    var(--select-l-lineheight, 22px) - var(--select-l-lineheight, 22px) / 2 * 2 +
      18px
  );
  line-height: calc(
    var(--select-l-lineheight, 22px) - var(--select-l-lineheight, 22px) / 2 * 2 +
      18px
  );
}

.next-select-multiple.next-large .next-input-control,
.next-select-multiple.next-large .next-input-label,
.next-select-multiple.next-large .next-select-tag-compact,
.next-select-tag.next-large .next-input-control,
.next-select-tag.next-large .next-input-label,
.next-select-tag.next-large .next-select-tag-compact {
  line-height: 34px;
  line-height: -webkit-calc(var(--form-element-large-height, 36px) - 2px);
  line-height: -moz-calc(var(--form-element-large-height, 36px) - 2px);
  line-height: calc(var(--form-element-large-height, 36px) - 2px);
}

.next-select-auto-complete {
  width: 160px;
}

.next-select-auto-complete .next-input {
  width: 100%;
}

.next-select-auto-complete .next-input .next-input-hint-wrap {
  padding-right: 1px;
}

.next-select-auto-complete .next-input .next-select-arrow {
  padding-left: 0;
}

.next-select.next-active .next-select-arrow .next-icon-arrow-down {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.next-select .next-select-unfold-icon:before {
  content: "";
  content: var(--select-unfold-icon-content, "");
}

.next-select-symbol-fold:before {
  content: "\E63D";
  content: var(--select-fold-icon-content, "\E63D");
}

.next-select-arrow {
  cursor: pointer;
  width: auto !important;
  text-align: center;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-select-popup-wrap {
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
}

.next-select-menu-wrapper {
  max-height: 260px;
  overflow: auto;
  border: 1px solid #c0c6cc;
  border: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #c0c6cc);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--popup-local-corner, 2px);
  -moz-border-radius: var(--popup-local-corner, 2px);
  border-radius: var(--popup-local-corner, 2px);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --popup-local-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
}

.next-select-menu-wrapper .next-select-menu {
  max-height: none;
  border: none;
}

.next-select-menu {
  max-height: 260px;
  overflow: auto;
}

.next-select-menu .next-select-menu-empty-content {
  padding-left: 8px;
  padding-right: 8px;
  color: #888;
  color: var(--select-hint-color, #888);
}

.next-select-menu.next-select-auto-complete-menu.next-select-menu-empty {
  display: none;
}

.next-select-menu .next-menu-item-text .next-icon {
  vertical-align: middle;
}

.next-select-all {
  display: block;
  cursor: pointer;
  padding: 0 8px;
  margin: 0 12px 8px;
  border-bottom: 1px solid #c0c6cc;
  border-bottom: 1px solid var(--color-line1-2, #c0c6cc);
}

.next-select-all:hover {
  color: #0064c8;
  color: var(--color-link-3, #0064c8);
}

.next-select-all .next-menu-icon-selected.next-icon {
  display: inline-block !important;
  top: auto;
  color: #0064c8;
  color: var(--color-brand1-6, #0064c8);
}

.next-select-highlight {
  color: #0064c8;
  color: var(--select-highlight-color, #0064c8);
  font-size: 12px;
  font-size: var(--select-highlight-font, 12px);
}

.next-select-in-ie.next-select-trigger .next-select-values {
  overflow: visible;
}

.next-select-in-ie.next-select-trigger .next-input-control,
.next-select-in-ie.next-select-trigger .next-input-label {
  width: 1px;
}

.next-select-in-ie.next-select-trigger .next-input-control > * {
  display: table-cell;
  width: 1%;
}

.next-select-in-ie.next-select-trigger .next-select-arrow {
  display: table-cell;
}

.next-select-in-ie.next-select-trigger .next-select-clear {
  display: none;
}

.next-select-in-ie.next-select-trigger.next-select-multiple .next-select-inner,
.next-select-in-ie.next-select-trigger.next-select-tag .next-select-inner {
  vertical-align: top;
}

.next-select-in-ie.next-select-trigger .next-select-inner,
.next-select-in-ie.next-select-trigger.next-select-single .next-select-values {
  display: inline-table;
}

.next-select-in-ie.next-select-trigger.next-select-single
  .next-input.next-small
  .next-select-values {
  line-height: 24px;
  line-height: var(--form-element-small-height, 24px);
}

.next-select-in-ie.next-select-trigger.next-select-single
  .next-input.next-medium
  .next-select-values {
  line-height: 32px;
  line-height: var(--form-element-medium-height, 32px);
}

.next-select-in-ie.next-select-trigger.next-select-single
  .next-input.next-large
  .next-select-values {
  line-height: 36px;
  line-height: var(--form-element-large-height, 36px);
}

.next-select-in-ie.next-select-trigger .next-select-trigger-search > span {
  max-width: 100px;
}

.next-select-in-ie.next-select-trigger.next-select-single.next-select-in-ie-fixwidth
  .next-select-values {
  position: relative;
}

.next-select-in-ie.next-select-trigger.next-select-single.next-select-in-ie-fixwidth
  .next-select-values
  > em {
  position: absolute;
  display: inline-block;
  height: 100%;
  line-height: 1;
  vertical-align: middle;
  overflow: hidden;
  left: 4px;
  right: 0;
  top: 30%;
}

.next-select-in-ie.next-select-trigger.next-select-single.next-inactive
  .next-select-values
  > em
  + .next-select-trigger-search,
.next-select-in-ie.next-select-trigger.next-select-single.next-no-search
  .next-select-values
  > em
  + .next-select-trigger-search {
  filter: alpha(opacity=0);
  font-size: 0;
}

.next-select-in-ie.next-select-trigger.next-no-search
  .next-select-trigger-search
  input {
  color: inherit;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .next-select-multiple .next-select-compact .next-select-tag-compact {
    background: -webkit-linear-gradient(left, hsla(0, 0%, 100%, 0), #fff 10px);
    background: -moz-linear-gradient(left, hsla(0, 0%, 100%, 0), #fff 10px);
    background: -o-linear-gradient(left, hsla(0, 0%, 100%, 0), #fff 10px);
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 10px);
    background: -webkit-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0),
      var(--input-bg-color, #fff) 10px
    );
    background: -moz-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0),
      var(--input-bg-color, #fff) 10px
    );
    background: -o-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0),
      var(--input-bg-color, #fff) 10px
    );
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      var(--input-bg-color, #fff) 10px
    );
  }

  .next-select-multiple .next-disabled .next-select-tag-compact {
    background: -webkit-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0),
      #f7f9fa 10px
    );
    background: -moz-linear-gradient(left, hsla(0, 0%, 100%, 0), #f7f9fa 10px);
    background: -o-linear-gradient(left, hsla(0, 0%, 100%, 0), #f7f9fa 10px);
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #f7f9fa 10px);
    background: -webkit-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0),
      var(--input-disabled-bg-color, #f7f9fa) 10px
    );
    background: -moz-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0),
      var(--input-disabled-bg-color, #f7f9fa) 10px
    );
    background: -o-linear-gradient(
      left,
      hsla(0, 0%, 100%, 0),
      var(--input-disabled-bg-color, #f7f9fa) 10px
    );
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      var(--input-disabled-bg-color, #f7f9fa) 10px
    );
  }
}

.next-select.next-select-multiple[dir="rtl"]
  .next-select-compact
  .next-select-tag-compact {
  left: 0;
  right: auto;
  padding: 0 16px 0 4px;
  background: -webkit-linear-gradient(right, hsla(0, 0%, 100%, 0), #fff 10px);
  background: -moz-linear-gradient(right, hsla(0, 0%, 100%, 0), #fff 10px);
  background: -o-linear-gradient(right, hsla(0, 0%, 100%, 0), #fff 10px);
  background: linear-gradient(270deg, hsla(0, 0%, 100%, 0), #fff 10px);
  background: -webkit-linear-gradient(
    right,
    hsla(0, 0%, 100%, 0),
    var(--input-bg-color, #fff) 10px
  );
  background: -moz-linear-gradient(
    right,
    hsla(0, 0%, 100%, 0),
    var(--input-bg-color, #fff) 10px
  );
  background: -o-linear-gradient(
    right,
    hsla(0, 0%, 100%, 0),
    var(--input-bg-color, #fff) 10px
  );
  background: linear-gradient(
    270deg,
    hsla(0, 0%, 100%, 0),
    var(--input-bg-color, #fff) 10px
  );
}

.next-calendar,
.next-calendar *,
.next-calendar :after,
.next-calendar :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-calendar table {
  border-collapse: collapse;
  border-spacing: 0;
}

.next-calendar td,
.next-calendar th {
  padding: 0;
}

@-webkit-keyframes cellZoomIn {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes cellZoomIn {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    transform: scale(0.5);
  }

  to {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes cellZoomIn {
  0% {
    -webkit-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  to {
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes cellZoomIn {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  to {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes cellHover {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes cellHover {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes cellHover {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes cellHover {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes enterToLeft {
  0% {
    -webkit-transform: translate(-40%);
    transform: translate(-40%);
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-moz-keyframes enterToLeft {
  0% {
    -webkit-transform: translate(-40%);
    -moz-transform: translate(-40%);
    transform: translate(-40%);
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    transform: translate(0);
  }
}

@-o-keyframes enterToLeft {
  0% {
    -webkit-transform: translate(-40%);
    -o-transform: translate(-40%);
    transform: translate(-40%);
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
    -webkit-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes enterToLeft {
  0% {
    -webkit-transform: translate(-40%);
    -moz-transform: translate(-40%);
    -o-transform: translate(-40%);
    transform: translate(-40%);
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes enterToRight {
  0% {
    -webkit-transform: translate(40%);
    transform: translate(40%);
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-moz-keyframes enterToRight {
  0% {
    -webkit-transform: translate(40%);
    -moz-transform: translate(40%);
    transform: translate(40%);
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    transform: translate(0);
  }
}

@-o-keyframes enterToRight {
  0% {
    -webkit-transform: translate(40%);
    -o-transform: translate(40%);
    transform: translate(40%);
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
    -webkit-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes enterToRight {
  0% {
    -webkit-transform: translate(40%);
    -moz-transform: translate(40%);
    -o-transform: translate(40%);
    transform: translate(40%);
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
  }
}

.next-calendar-card .next-calendar-header,
.next-calendar-fullscreen .next-calendar-header {
  text-align: right;
}

.next-calendar-card .next-calendar-header .next-select,
.next-calendar-fullscreen .next-calendar-header .next-select {
  margin-right: 4px;
  vertical-align: top;
}

.next-calendar-card .next-calendar-header .next-menu,
.next-calendar-fullscreen .next-calendar-header .next-menu {
  text-align: left;
}

.next-calendar-fullscreen .next-calendar-header {
  margin-bottom: 8px;
  margin-bottom: var(--calendar-fullscreen-header-margin-bottom, 8px);
}

.next-calendar-card .next-calendar-header {
  margin-bottom: 8px;
  margin-bottom: var(--calendar-card-header-margin-bottom, 8px);
}

.next-calendar-panel-header {
  position: relative;
  background: transparent;
  background: var(--calendar-panel-header-background, transparent);
  margin-bottom: 16px;
  margin-bottom: var(--calendar-panel-header-margin-bottom, 16px);
  border-bottom: 1px solid transparent;
  border-bottom: var(--calendar-panel-header-border-bottom-width, 1px) solid
    var(--calendar-panel-header-border-bottom-color, transparent);
}

.next-calendar-panel-header-full,
.next-calendar-panel-header-left,
.next-calendar-panel-header-right {
  height: 32px;
  height: var(--calendar-panel-header-height, 32px);
  line-height: 32px;
  line-height: var(--calendar-panel-header-height, 32px);
}

.next-calendar-panel-header-full .next-calendar-btn,
.next-calendar-panel-header-left .next-calendar-btn,
.next-calendar-panel-header-right .next-calendar-btn {
  vertical-align: top;
  font-weight: 500;
  font-weight: var(--calendar-btn-date-font-weight, 500);
  margin: 0 4px;
  margin: 0 var(--calendar-btn-date-margin-lr, 4px);
  background-color: transparent;
  border-color: transparent;
}

.next-calendar-panel-header-full .next-calendar-btn,
.next-calendar-panel-header-full .next-calendar-btn.visited,
.next-calendar-panel-header-full .next-calendar-btn:link,
.next-calendar-panel-header-full .next-calendar-btn:visited,
.next-calendar-panel-header-left .next-calendar-btn,
.next-calendar-panel-header-left .next-calendar-btn.visited,
.next-calendar-panel-header-left .next-calendar-btn:link,
.next-calendar-panel-header-left .next-calendar-btn:visited,
.next-calendar-panel-header-right .next-calendar-btn,
.next-calendar-panel-header-right .next-calendar-btn.visited,
.next-calendar-panel-header-right .next-calendar-btn:link,
.next-calendar-panel-header-right .next-calendar-btn:visited {
  color: #333;
  color: var(--calendar-btn-date-color, #333);
}

.next-calendar-panel-header-full .next-calendar-btn.active,
.next-calendar-panel-header-full .next-calendar-btn.hover,
.next-calendar-panel-header-full .next-calendar-btn:active,
.next-calendar-panel-header-full .next-calendar-btn:focus,
.next-calendar-panel-header-full .next-calendar-btn:hover,
.next-calendar-panel-header-left .next-calendar-btn.active,
.next-calendar-panel-header-left .next-calendar-btn.hover,
.next-calendar-panel-header-left .next-calendar-btn:active,
.next-calendar-panel-header-left .next-calendar-btn:focus,
.next-calendar-panel-header-left .next-calendar-btn:hover,
.next-calendar-panel-header-right .next-calendar-btn.active,
.next-calendar-panel-header-right .next-calendar-btn.hover,
.next-calendar-panel-header-right .next-calendar-btn:active,
.next-calendar-panel-header-right .next-calendar-btn:focus,
.next-calendar-panel-header-right .next-calendar-btn:hover {
  color: #0064c8;
  color: var(--calendar-btn-date-color-hover, #0064c8);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-calendar-panel-header-left,
.next-calendar-panel-header-right {
  display: inline-block;
  width: 50%;
  text-align: center;
}

.next-calendar-panel-header-full {
  width: 100%;
  text-align: center;
}

.next-calendar-panel-menu {
  max-height: 210px;
  overflow: auto;
  text-align: left;
}

.next-calendar-btn {
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  outline: none;
  height: 100%;
}

.next-calendar-btn > .next-icon.next-icon .next-icon-remote,
.next-calendar-btn > .next-icon.next-icon:before {
  width: 12px;
  width: var(--calendar-btn-arrow-size, 12px);
  font-size: 12px;
  font-size: var(--calendar-btn-arrow-size, 12px);
  line-height: inherit;
}

.next-calendar-btn .next-icon {
  margin-left: 4px;
}

.next-calendar-btn-next-decade,
.next-calendar-btn-next-month,
.next-calendar-btn-next-year,
.next-calendar-btn-prev-decade,
.next-calendar-btn-prev-month,
.next-calendar-btn-prev-year {
  position: absolute;
  top: 0;
  background-color: transparent;
  border-color: transparent;
}

.next-calendar-btn-next-decade,
.next-calendar-btn-next-decade.visited,
.next-calendar-btn-next-decade:link,
.next-calendar-btn-next-decade:visited,
.next-calendar-btn-next-month,
.next-calendar-btn-next-month.visited,
.next-calendar-btn-next-month:link,
.next-calendar-btn-next-month:visited,
.next-calendar-btn-next-year,
.next-calendar-btn-next-year.visited,
.next-calendar-btn-next-year:link,
.next-calendar-btn-next-year:visited,
.next-calendar-btn-prev-decade,
.next-calendar-btn-prev-decade.visited,
.next-calendar-btn-prev-decade:link,
.next-calendar-btn-prev-decade:visited,
.next-calendar-btn-prev-month,
.next-calendar-btn-prev-month.visited,
.next-calendar-btn-prev-month:link,
.next-calendar-btn-prev-month:visited,
.next-calendar-btn-prev-year,
.next-calendar-btn-prev-year.visited,
.next-calendar-btn-prev-year:link,
.next-calendar-btn-prev-year:visited {
  color: #333;
  color: var(--calendar-btn-arrow-color, #333);
}

.next-calendar-btn-next-decade.active,
.next-calendar-btn-next-decade.hover,
.next-calendar-btn-next-decade:active,
.next-calendar-btn-next-decade:focus,
.next-calendar-btn-next-decade:hover,
.next-calendar-btn-next-month.active,
.next-calendar-btn-next-month.hover,
.next-calendar-btn-next-month:active,
.next-calendar-btn-next-month:focus,
.next-calendar-btn-next-month:hover,
.next-calendar-btn-next-year.active,
.next-calendar-btn-next-year.hover,
.next-calendar-btn-next-year:active,
.next-calendar-btn-next-year:focus,
.next-calendar-btn-next-year:hover,
.next-calendar-btn-prev-decade.active,
.next-calendar-btn-prev-decade.hover,
.next-calendar-btn-prev-decade:active,
.next-calendar-btn-prev-decade:focus,
.next-calendar-btn-prev-decade:hover,
.next-calendar-btn-prev-month.active,
.next-calendar-btn-prev-month.hover,
.next-calendar-btn-prev-month:active,
.next-calendar-btn-prev-month:focus,
.next-calendar-btn-prev-month:hover,
.next-calendar-btn-prev-year.active,
.next-calendar-btn-prev-year.hover,
.next-calendar-btn-prev-year:active,
.next-calendar-btn-prev-year:focus,
.next-calendar-btn-prev-year:hover {
  color: #0064c8;
  color: var(--calendar-btn-arrow-color-hover, #0064c8);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-calendar-btn-prev-decade,
.next-calendar-btn-prev-year {
  left: 16px;
  left: var(--calendar-btn-arrow-double-offset-lr, 16px);
}

.next-calendar-btn-prev-month {
  left: 36px;
  left: var(--calendar-btn-arrow-single-offset-lr, 36px);
}

.next-calendar-btn-next-month {
  right: 36px;
  right: var(--calendar-btn-arrow-single-offset-lr, 36px);
}

.next-calendar-btn-next-decade,
.next-calendar-btn-next-year {
  right: 16px;
  right: var(--calendar-btn-arrow-double-offset-lr, 16px);
}

.next-calendar-fullscreen .next-calendar-th {
  text-align: right;
  color: #888;
  color: var(--calendar-fullscreen-table-head-color, #888);
  font-size: 14px;
  font-size: var(--calendar-fullscreen-table-head-font-size, 14px);
  font-weight: 700;
  font-weight: var(--calendar-fullscreen-table-head-font-weight, bold);
  padding-right: 12px;
  padding-right: var(--calendar-fullscreen-table-head-padding-r, 12px);
  padding-bottom: 4px;
  padding-bottom: var(--calendar-fullscreen-table-head-padding-b, 4px);
}

.next-calendar-fullscreen .next-calendar-cell {
  font-size: 14px;
  font-size: var(--calendar-fullscreen-table-cell-font-size, 14px);
}

.next-calendar-fullscreen .next-calendar-cell.next-selected .next-calendar-date,
.next-calendar-fullscreen
  .next-calendar-cell.next-selected
  .next-calendar-month {
  font-weight: 700;
  font-weight: var(--calendar-fullscreen-table-cell-select-font-weight, bold);
  background: #f7f9fa;
  background: var(--calendar-fullscreen-table-cell-select-background, #f7f9fa);
  color: #0064c8;
  color: var(--calendar-fullscreen-table-cell-select-color, #0064c8);
  border-color: #0064c8;
  border-color: var(
    --calendar-fullscreen-table-cell-select-border-color,
    #0064c8
  );
}

.next-calendar-fullscreen .next-calendar-cell.next-disabled .next-calendar-date,
.next-calendar-fullscreen
  .next-calendar-cell.next-disabled
  .next-calendar-month {
  cursor: not-allowed;
  background: #f7f9fa;
  background: var(
    --calendar-fullscreen-table-cell-disabled-background,
    #f7f9fa
  );
  color: #c1c1c1;
  color: var(--calendar-fullscreen-table-cell-disabled-color, #c1c1c1);
  border-color: #e3e4e6;
  border-color: var(
    --calendar-fullscreen-table-cell-disabled-border-color,
    #e3e4e6
  );
}

.next-calendar-fullscreen .next-calendar-date,
.next-calendar-fullscreen .next-calendar-month {
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin: 0 4px;
  margin: var(--calendar-fullscreen-table-cell-margin-tb, 0)
    var(--calendar-fullscreen-table-cell-margin-lr, 4px);
  padding: 4px 8px;
  padding: var(--calendar-fullscreen-table-cell-padding-tb, 4px)
    var(--calendar-fullscreen-table-cell-padding-lr, 8px);
  min-height: 80px;
  min-height: var(--calendar-fullscreen-table-cell-min-height, 80px);
  border-top: 2px solid;
  border-top: var(--calendar-fullscreen-table-cell-boder-top-width, 2px)
    var(--line-solid, solid);
  -webkit-transition: background 0.1s linear;
  -o-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  transition: background 0.1s linear;
  background: #fff;
  background: var(--calendar-fullscreen-table-cell-normal-background, #fff);
  color: #ccc;
  color: var(--calendar-fullscreen-table-cell-normal-color, #ccc);
  border-color: #e3e4e6;
  border-color: var(
    --calendar-fullscreen-table-cell-normal-border-color,
    #e3e4e6
  );
}

.next-calendar-fullscreen .next-calendar-date:hover,
.next-calendar-fullscreen .next-calendar-month:hover {
  background: #f7f9fa;
  background: var(--calendar-fullscreen-table-cell-hover-background, #f7f9fa);
  color: #0064c8;
  color: var(--calendar-fullscreen-table-cell-hover-color, #0064c8);
  border-color: #0064c8;
  border-color: var(
    --calendar-fullscreen-table-cell-hover-border-color,
    #0064c8
  );
}

.next-calendar-fullscreen .next-calendar-cell-next-month .next-calendar-date,
.next-calendar-fullscreen .next-calendar-cell-prev-month .next-calendar-date {
  background: transparent;
  background: var(
    --calendar-fullscreen-table-cell-other-background,
    transparent
  );
  color: #c1c1c1;
  color: var(--calendar-fullscreen-table-cell-other-color, #c1c1c1);
  border-color: transparent;
  border-color: var(
    --calendar-fullscreen-table-cell-other-border-color,
    transparent
  );
}

.next-calendar-fullscreen .next-calendar-cell-current .next-calendar-date,
.next-calendar-fullscreen .next-calendar-cell-current .next-calendar-month {
  font-weight: 700;
  font-weight: var(--calendar-fullscreen-table-cell-current-font-weight, bold);
  background: #fff;
  background: var(--calendar-fullscreen-table-cell-current-background, #fff);
  color: #0064c8;
  color: var(--calendar-fullscreen-table-cell-current-color, #0064c8);
  border-color: #0064c8;
  border-color: var(
    --calendar-fullscreen-table-cell-current-border-color,
    #0064c8
  );
}

.next-calendar-card .next-calendar-th,
.next-calendar-panel .next-calendar-th,
.next-calendar-range .next-calendar-th {
  text-align: center;
  color: #666;
  color: var(--calendar-card-table-head-color, #666);
  font-size: 12px;
  font-size: var(--calendar-card-table-head-font-size, 12px);
  font-weight: 400;
  font-weight: var(--calendar-card-table-head-font-weight, normal);
}

.next-calendar-card .next-calendar-cell,
.next-calendar-panel .next-calendar-cell,
.next-calendar-range .next-calendar-cell {
  text-align: center;
  font-size: 12px;
  font-size: var(--calendar-card-table-cell-font-size, 12px);
}

.next-calendar-card .next-calendar-cell.next-selected .next-calendar-date,
.next-calendar-card .next-calendar-cell.next-selected .next-calendar-month,
.next-calendar-card .next-calendar-cell.next-selected .next-calendar-year,
.next-calendar-panel .next-calendar-cell.next-selected .next-calendar-date,
.next-calendar-panel .next-calendar-cell.next-selected .next-calendar-month,
.next-calendar-panel .next-calendar-cell.next-selected .next-calendar-year,
.next-calendar-range .next-calendar-cell.next-selected .next-calendar-date,
.next-calendar-range .next-calendar-cell.next-selected .next-calendar-month,
.next-calendar-range .next-calendar-cell.next-selected .next-calendar-year {
  -webkit-animation: cellZoomIn 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-animation: cellZoomIn 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  -o-animation: cellZoomIn 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  animation: cellZoomIn 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  font-weight: 400;
  font-weight: var(--calendar-card-table-cell-select-font-weight, normal);
  background: #0064c8;
  background: var(--calendar-card-table-cell-select-background, #0064c8);
  color: #fff;
  color: var(--calendar-card-table-cell-select-color, #fff);
  border-color: #0064c8;
  border-color: var(--calendar-card-table-cell-select-border-color, #0064c8);
}

.next-calendar-card .next-calendar-cell.next-disabled .next-calendar-date,
.next-calendar-card .next-calendar-cell.next-disabled .next-calendar-month,
.next-calendar-card .next-calendar-cell.next-disabled .next-calendar-year,
.next-calendar-panel .next-calendar-cell.next-disabled .next-calendar-date,
.next-calendar-panel .next-calendar-cell.next-disabled .next-calendar-month,
.next-calendar-panel .next-calendar-cell.next-disabled .next-calendar-year,
.next-calendar-range .next-calendar-cell.next-disabled .next-calendar-date,
.next-calendar-range .next-calendar-cell.next-disabled .next-calendar-month,
.next-calendar-range .next-calendar-cell.next-disabled .next-calendar-year {
  cursor: not-allowed;
  background: transparent;
  background: var(--calendar-card-table-cell-disabled-background, transparent);
  color: #ccc;
  color: var(--calendar-card-table-cell-disabled-color, #ccc);
  border-color: transparent;
  border-color: var(
    --calendar-card-table-cell-disabled-border-color,
    transparent
  );
}

.next-calendar-card .next-calendar-cell.next-inrange .next-calendar-date,
.next-calendar-panel .next-calendar-cell.next-inrange .next-calendar-date,
.next-calendar-range .next-calendar-cell.next-inrange .next-calendar-date {
  background: #eff3f8;
  background: var(--calendar-card-table-cell-inrange-background, #eff3f8);
  color: #333;
  color: var(--calendar-card-table-cell-inrange-color, #333);
  border-color: transparent;
  border-color: var(
    --calendar-card-table-cell-inrange-border-color,
    transparent
  );
}

.next-calendar-card .next-calendar-date,
.next-calendar-card .next-calendar-month,
.next-calendar-card .next-calendar-year,
.next-calendar-panel .next-calendar-date,
.next-calendar-panel .next-calendar-month,
.next-calendar-panel .next-calendar-year,
.next-calendar-range .next-calendar-date,
.next-calendar-range .next-calendar-month,
.next-calendar-range .next-calendar-year {
  text-align: center;
  border: 1px solid;
  border: var(--line-1, 1px) var(--line-solid, solid);
  background: #fff;
  background: var(--calendar-card-table-cell-normal-background, #fff);
  color: #333;
  color: var(--calendar-card-table-cell-normal-color, #333);
  border-color: #fff;
  border-color: var(--calendar-card-table-cell-normal-border-color, #fff);
}

.next-calendar-card .next-calendar-date:hover,
.next-calendar-card .next-calendar-month:hover,
.next-calendar-card .next-calendar-year:hover,
.next-calendar-panel .next-calendar-date:hover,
.next-calendar-panel .next-calendar-month:hover,
.next-calendar-panel .next-calendar-year:hover,
.next-calendar-range .next-calendar-date:hover,
.next-calendar-range .next-calendar-month:hover,
.next-calendar-range .next-calendar-year:hover {
  cursor: pointer;
  background: #f7f9fa;
  background: var(--calendar-card-table-cell-hover-background, #f7f9fa);
  color: #333;
  color: var(--calendar-card-table-cell-hover-color, #333);
  border-color: transparent;
  border-color: var(--calendar-card-table-cell-hover-border-color, transparent);
}

.next-calendar-card .next-calendar-date,
.next-calendar-panel .next-calendar-date,
.next-calendar-range .next-calendar-date {
  width: 24px;
  width: var(--calendar-card-table-cell-date-width, 24px);
  height: 24px;
  height: var(--calendar-card-table-cell-date-height, 24px);
  line-height: 22px;
  line-height: -webkit-calc(
    var(--calendar-card-table-cell-date-height, 24px) - 2px
  );
  line-height: -moz-calc(
    var(--calendar-card-table-cell-date-height, 24px) - 2px
  );
  line-height: calc(var(--calendar-card-table-cell-date-height, 24px) - 2px);
  margin: 4px auto;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(
    --calendar-card-table-cell-date-border-radius,
    2px
  );
  -moz-border-radius: var(--calendar-card-table-cell-date-border-radius, 2px);
  border-radius: var(--calendar-card-table-cell-date-border-radius, 2px);
}

.next-calendar-card .next-calendar-month,
.next-calendar-panel .next-calendar-month,
.next-calendar-range .next-calendar-month {
  width: 60px;
  width: var(--calendar-card-table-cell-month-width, 60px);
  height: 24px;
  height: var(--calendar-card-table-cell-month-height, 24px);
  line-height: 22px;
  line-height: -webkit-calc(
    var(--calendar-card-table-cell-month-height, 24px) - 2px
  );
  line-height: -moz-calc(
    var(--calendar-card-table-cell-month-height, 24px) - 2px
  );
  line-height: calc(var(--calendar-card-table-cell-month-height, 24px) - 2px);
  margin: 8px auto;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(
    --calendar-card-table-cell-month-border-radius,
    2px
  );
  -moz-border-radius: var(--calendar-card-table-cell-month-border-radius, 2px);
  border-radius: var(--calendar-card-table-cell-month-border-radius, 2px);
}

.next-calendar-card .next-calendar-year,
.next-calendar-panel .next-calendar-year,
.next-calendar-range .next-calendar-year {
  width: 48px;
  width: var(--calendar-card-table-cell-year-width, 48px);
  height: 24px;
  height: var(--calendar-card-table-cell-year-height, 24px);
  line-height: 22px;
  line-height: -webkit-calc(
    var(--calendar-card-table-cell-year-height, 24px) - 2px
  );
  line-height: -moz-calc(
    var(--calendar-card-table-cell-year-height, 24px) - 2px
  );
  line-height: calc(var(--calendar-card-table-cell-year-height, 24px) - 2px);
  margin: 8px auto;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(
    --calendar-card-table-cell-year-border-radius,
    2px
  );
  -moz-border-radius: var(--calendar-card-table-cell-year-border-radius, 2px);
  border-radius: var(--calendar-card-table-cell-year-border-radius, 2px);
}

.next-calendar-card .next-calendar-cell-next-month .next-calendar-date,
.next-calendar-card .next-calendar-cell-prev-month .next-calendar-date,
.next-calendar-panel .next-calendar-cell-next-month .next-calendar-date,
.next-calendar-panel .next-calendar-cell-prev-month .next-calendar-date,
.next-calendar-range .next-calendar-cell-next-month .next-calendar-date,
.next-calendar-range .next-calendar-cell-prev-month .next-calendar-date {
  background: #fff;
  background: var(--calendar-card-table-cell-other-background, #fff);
  color: #555;
  color: var(--calendar-card-table-cell-other-color, #555);
  border-color: #fff;
  border-color: var(--calendar-card-table-cell-other-border-color, #fff);
}

.next-calendar-card .next-calendar-cell-current .next-calendar-date,
.next-calendar-card .next-calendar-cell-current .next-calendar-month,
.next-calendar-card .next-calendar-cell-current .next-calendar-year,
.next-calendar-panel .next-calendar-cell-current .next-calendar-date,
.next-calendar-panel .next-calendar-cell-current .next-calendar-month,
.next-calendar-panel .next-calendar-cell-current .next-calendar-year,
.next-calendar-range .next-calendar-cell-current .next-calendar-date,
.next-calendar-range .next-calendar-cell-current .next-calendar-month,
.next-calendar-range .next-calendar-cell-current .next-calendar-year {
  font-weight: 400;
  font-weight: var(--calendar-card-table-cell-current-font-weight, normal);
  background: #fff;
  background: var(--calendar-card-table-cell-current-background, #fff);
  color: #0064c8;
  color: var(--calendar-card-table-cell-current-color, #0064c8);
  border-color: #0064c8;
  border-color: var(--calendar-card-table-cell-current-border-color, #0064c8);
}

.next-calendar-panel.next-calendar-week .next-calendar-tbody tr {
  cursor: pointer;
}

.next-calendar-panel.next-calendar-week
  .next-calendar-tbody
  tr:hover
  .next-calendar-cell
  .next-calendar-date {
  background: #f7f9fa;
  background: var(--calendar-card-table-cell-hover-background, #f7f9fa);
  color: #333;
  color: var(--calendar-card-table-cell-hover-color, #333);
  border-color: transparent;
  border-color: var(--calendar-card-table-cell-hover-border-color, transparent);
}

.next-calendar-panel.next-calendar-week
  .next-calendar-tbody
  .next-calendar-cell.next-selected
  .next-calendar-date {
  font-weight: 400;
  background: transparent;
  border-color: transparent;
}

.next-calendar-panel.next-calendar-week
  .next-calendar-tbody
  .next-calendar-week-active-date {
  position: relative;
  color: #333;
  color: var(--calendar-card-table-cell-inrange-color, #333);
}

.next-calendar-panel.next-calendar-week
  .next-calendar-tbody
  .next-calendar-week-active-date:before {
  content: "";
  position: absolute;
  left: -1px;
  left: -webkit-calc(0 - var(--line-1, 1px));
  left: -moz-calc(0 - var(--line-1, 1px));
  left: calc(0px - var(--line-1, 1px));
  top: -1px;
  top: -webkit-calc(0 - var(--line-1, 1px));
  top: -moz-calc(0 - var(--line-1, 1px));
  top: calc(0px - var(--line-1, 1px));
  bottom: -1px;
  bottom: -webkit-calc(0 - var(--line-1, 1px));
  bottom: -moz-calc(0 - var(--line-1, 1px));
  bottom: calc(0px - var(--line-1, 1px));
  right: -1px;
  right: -webkit-calc(0 - var(--line-1, 1px));
  right: -moz-calc(0 - var(--line-1, 1px));
  right: calc(0px - var(--line-1, 1px));
  border: 1px solid;
  border: var(--line-1, 1px) var(--line-solid, solid);
  background: #eff3f8;
  background: var(--calendar-card-table-cell-inrange-background, #eff3f8);
  border-color: transparent;
  border-color: var(
    --calendar-card-table-cell-inrange-border-color,
    transparent
  );
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(
    --calendar-card-table-cell-date-border-radius,
    2px
  );
  -moz-border-radius: var(--calendar-card-table-cell-date-border-radius, 2px);
  border-radius: var(--calendar-card-table-cell-date-border-radius, 2px);
}

.next-calendar-panel.next-calendar-week
  .next-calendar-tbody
  .next-calendar-week-active-date
  > span {
  position: relative;
}

.next-calendar-panel.next-calendar-week
  .next-calendar-tbody
  .next-calendar-week-active-end,
.next-calendar-panel.next-calendar-week
  .next-calendar-tbody
  .next-calendar-week-active-start {
  color: #fff;
  color: var(--calendar-card-table-cell-select-color, #fff);
}

.next-calendar-panel.next-calendar-week
  .next-calendar-tbody
  .next-calendar-week-active-end:before,
.next-calendar-panel.next-calendar-week
  .next-calendar-tbody
  .next-calendar-week-active-start:before {
  background: #0064c8;
  background: var(--calendar-card-table-cell-select-background, #0064c8);
  border-color: #0064c8;
  border-color: var(--calendar-card-table-cell-select-border-color, #0064c8);
}

.next-calendar[dir="rtl"] .next-calendar-header {
  text-align: left;
}

.next-calendar[dir="rtl"] .next-calendar-header .next-select {
  margin-right: 0;
  margin-left: 4px;
}

.next-calendar[dir="rtl"] .next-calendar-header .next-menu {
  text-align: right;
}

.next-calendar[dir="rtl"] .next-calendar-btn-prev-decade,
.next-calendar[dir="rtl"] .next-calendar-btn-prev-year {
  left: auto;
  right: 16px;
  right: var(--calendar-btn-arrow-double-offset-lr, 16px);
}

.next-calendar[dir="rtl"] .next-calendar-btn-prev-month {
  left: auto;
  right: 36px;
  right: var(--calendar-btn-arrow-single-offset-lr, 36px);
}

.next-calendar[dir="rtl"] .next-calendar-btn-next-month {
  right: auto;
  left: 36px;
  left: var(--calendar-btn-arrow-single-offset-lr, 36px);
}

.next-calendar[dir="rtl"] .next-calendar-btn-next-decade,
.next-calendar[dir="rtl"] .next-calendar-btn-next-year {
  right: auto;
  left: 16px;
  left: var(--calendar-btn-arrow-double-offset-lr, 16px);
}

.next-calendar-fullscreen[dir="rtl"] .next-calendar-th {
  text-align: left;
  padding-left: 12px;
  padding-left: var(--calendar-fullscreen-table-head-padding-r, 12px);
  padding-right: 0;
}

.next-calendar-fullscreen[dir="rtl"] .next-calendar-date,
.next-calendar-fullscreen[dir="rtl"] .next-calendar-month {
  text-align: left;
}

.next-calendar-range[dir="rtl"] .next-calendar-body-left,
.next-calendar-range[dir="rtl"] .next-calendar-body-right {
  float: right;
}

.next-calendar-range[dir="rtl"] .next-calendar-body-left {
  padding-right: 0;
  padding-left: 8px;
}

.next-calendar-range[dir="rtl"] .next-calendar-body-right {
  padding-left: 0;
  padding-right: 8px;
}

.next-calendar-table {
  width: 100%;
  table-layout: fixed;
}

.next-calendar-range .next-calendar-body-left,
.next-calendar-range .next-calendar-body-right {
  float: left;
  width: 50%;
}

.next-calendar-range .next-calendar-body-left {
  padding-right: 8px;
}

.next-calendar-range .next-calendar-body-right {
  padding-left: 8px;
}

.next-calendar-range .next-calendar-body:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-calendar-symbol-prev:before {
  content: "\E61D";
  content: var(--calendar-btn-arrow-content-prev, "\E61D");
}

.next-calendar-symbol-next:before {
  content: "\E619";
  content: var(--calendar-btn-arrow-content-next, "\E619");
}

.next-calendar-symbol-prev-super:before {
  content: "\E659";
  content: var(--calendar-btn-arrow-content-prev-super, "\E659");
}

.next-calendar-symbol-next-super:before {
  content: "\E65E";
  content: var(--calendar-btn-arrow-content-next-super, "\E65E");
}

.next-card,
.next-card:after,
.next-card:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-card[dir="rtl"] .next-card-extra {
  left: 0;
  right: auto;
}

.next-card[dir="rtl"] .next-card-title:before {
  right: 0;
  left: auto;
}

.next-card[dir="rtl"] .next-card-subtitle {
  float: left;
  padding-right: 8px;
  padding-right: var(--card-sub-title-padding-left, 8px);
  padding-left: 0;
}

.next-card[dir="rtl"] .next-card-head-show-bullet .next-card-title {
  padding-left: 0;
  padding-right: 8px;
  padding-right: var(--card-title-padding-left, 8px);
}

.next-card,
.next-card *,
.next-card :after,
.next-card :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-card {
  min-width: 100px;
  border: 1px solid #e3e4e6;
  border: var(--card-border-width, 1px) var(--card-border-style, solid)
    var(--card-border-color, #e3e4e6);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-border-radius: var(--card-corner, 4px);
  -moz-border-radius: var(--card-corner, 4px);
  border-radius: var(--card-corner, 4px);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--card-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--card-shadow, none);
  box-shadow: var(--card-shadow, none);
  background: #fff;
  background: var(--card-background, #fff);
  overflow: hidden;
}

.next-card-head {
  background: #fff;
  background: var(--card-header-background, #fff);
  padding-left: 16px;
  padding-left: var(--card-padding-lr, 16px);
  padding-right: 16px;
  padding-right: var(--card-padding-lr, 16px);
}

.next-card-head-show-bullet .next-card-title {
  padding-left: 8px;
  padding-left: var(--card-title-padding-left, 8px);
}

.next-card-head-show-bullet .next-card-title:before {
  content: "";
  display: inline-block;
  height: 16px;
  height: var(--card-title-bullet-height, 16px);
  width: 3px;
  width: var(--card-title-bullet-width, 3px);
  background: #0064c8;
  background: var(--card-title-bullet-color, #0064c8);
  position: absolute;
  left: 0;
  top: -webkit-calc(50% - 8px);
  top: -moz-calc(50% - 8px);
  top: calc(50% - 8px);
  top: -webkit-calc(50% - var(--card-title-bullet-height, 16px) / 2);
  top: -moz-calc(50% - var(--card-title-bullet-height, 16px) / 2);
  top: calc(50% - var(--card-title-bullet-height, 16px) / 2);
}

.next-card-head-main {
  position: relative;
  margin-top: 4px;
  margin-top: var(--card-head-main-margin-top, 4px);
  margin-bottom: 4px;
  margin-bottom: var(--card-head-main-margin-bottom, 4px);
  height: 48px;
  height: var(--card-head-main-height, 48px);
  line-height: 48px;
  line-height: var(--card-head-main-height, 48px);
}

.next-card-title {
  display: inline-block;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  height: 100%;
  color: #333;
  color: var(--card-title-color, #333);
  font-size: 14px;
  font-size: var(--card-title-font-size, 14px);
  font-weight: 500;
  font-weight: var(--card-title-font-weight, 500);
}

.next-card-subtitle {
  font-size: 12px;
  font-size: var(--card-sub-title-font-size, 12px);
  color: #555;
  color: var(--card-sub-title-color, #555);
  padding-left: 8px;
  padding-left: var(--card-sub-title-padding-left, 8px);
}

.next-card-extra {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 12px;
  font-size: var(--card-title-extra-font-size, 12px);
  color: #0064c8;
  color: var(--card-title-extra-color, #0064c8);
}

.next-card-body {
  padding-bottom: 16px;
  padding-bottom: var(--card-body-padding-bottom, 16px);
  padding-left: 16px;
  padding-left: var(--card-padding-lr, 16px);
  padding-right: 16px;
  padding-right: var(--card-padding-lr, 16px);
}

.next-card-show-divider .next-card-head-main {
  border-bottom: 1px solid transparent;
  border-bottom: var(--card-head-bottom-border-width, 1px)
    var(--card-border-style, solid)
    var(--card-head-bottom-border-color, transparent);
}

.next-card-show-divider .next-card-body {
  padding-top: 16px;
  padding-top: var(--card-body-show-divider-padding-top, 16px);
}

.next-card-hide-divider .next-card-body {
  padding-top: 0;
  padding-top: var(--card-body-hide-divider-padding-top, 0);
}

.next-card—free {
  padding: 0;
}

.next-card-content {
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
}

.next-card-footer .next-icon {
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-card-footer .next-icon.expand {
  -webkit-transform-origin: 50% 47%;
  -moz-transform-origin: 50% 47%;
  -o-transform-origin: 50% 47%;
  transform-origin: 50% 47%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.next-card-header {
  background: #fff;
  background: var(--card-header-background, #fff);
  padding: 0 16px;
  padding: 0 var(--card-padding-lr, 16px);
  margin-bottom: 16px;
  margin-bottom: var(--card-body-show-divider-padding-top, 16px);
  margin-top: 16px;
  margin-top: var(--card-body-padding-bottom, 16px);
}

.next-card-media,
.next-card-media > * {
  display: block;
  -moz-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.next-card-header-titles {
  overflow: hidden;
}

.next-card-header-extra {
  float: right;
  text-align: right;
}

.next-card-header-extra .next--btn {
  margin-left: 12px;
  vertical-align: middle;
}

.next-card-header-title {
  color: #333;
  color: var(--card-title-color, #333);
  font-size: 14px;
  font-size: var(--card-title-font-size, 14px);
  font-weight: 500;
  font-weight: var(--card-title-font-weight, 500);
  line-height: 1.5;
}

.next-card-header-subtitle {
  font-size: 12px;
  font-size: var(--card-sub-title-font-size, 12px);
  color: #555;
  color: var(--card-sub-title-color, #555);
}

.next-card-actions {
  display: block;
  padding: 16px;
  padding: var(--card-body-show-divider-padding-top, 16px)
    var(--card-padding-lr, 16px) var(--card-body-padding-bottom, 16px);
}

.next-card-actions .next-btn:not(:last-child) {
  margin-right: 12px;
  vertical-align: middle;
}

.next-card-divider {
  border-style: none;
  width: 100%;
  margin: 0;
  position: relative;
}

.next-card-divider:before {
  content: "";
  display: block;
  border-bottom: 1px solid transparent;
  border-bottom: var(--card-head-bottom-border-width, 1px)
    var(--card-border-style, solid)
    var(--card-head-bottom-border-color, transparent);
}

.next-card-divider--inset {
  padding: 0 16px;
  padding: 0 var(--card-padding-lr, 16px);
}

.next-card-content-container {
  margin-top: 16px;
  margin-top: var(--card-body-show-divider-padding-top, 16px);
  padding-bottom: 16px;
  padding-bottom: var(--card-body-padding-bottom, 16px);
  padding-left: 16px;
  padding-left: var(--card-padding-lr, 16px);
  padding-right: 16px;
  padding-right: var(--card-padding-lr, 16px);
  font-size: 12px;
  font-size: var(--card-content-font-size, 12px);
  line-height: 1.5;
  line-height: var(--card-content-line-height, 1.5);
  color: #555;
  color: var(--card-content-color, #555);
}

.next-cascader {
  display: inline-block;
  overflow: auto;
  border: 1px solid #e3e4e6;
  border: var(--cascader-menu-border-width, 1px) var(--line-solid, solid)
    var(--cascader-menu-border-color, #e3e4e6);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--cascader-menu-border-radius, 2px);
  -moz-border-radius: var(--cascader-menu-border-radius, 2px);
  border-radius: var(--cascader-menu-border-radius, 2px);
}

.next-cascader,
.next-cascader *,
.next-cascader :after,
.next-cascader :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-cascader-inner:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-cascader-menu-wrapper {
  float: left;
  overflow: auto;
  width: 100px;
  width: var(--cascader-menu-width, 100px);
  height: 192px;
  height: var(--cascader-menu-height, 192px);
  overflow-x: hidden;
  overflow-y: auto;
}

.next-cascader-menu-wrapper + .next-cascader-menu-wrapper {
  border-left: 1px solid #e3e4e6;
  border-left: var(--cascader-menu-border-width, 1px) var(--line-solid, solid)
    var(--cascader-menu-border-color, #e3e4e6);
}

.next-cascader-menu {
  position: relative;
  padding: 0;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  min-width: auto;
  min-height: 100%;
}

.next-cascader-menu.next-has-right-border {
  border-right: 1px solid #e3e4e6;
  border-right: var(--cascader-menu-border-width, 1px) var(--line-solid, solid)
    var(--cascader-menu-border-color, #e3e4e6);
}

.next-cascader-menu-item.next-expanded {
  color: #333;
  color: var(--cascader-menu-item-expanded-color, #333);
  background-color: #f7f9fa;
  background-color: var(
    --cascader-menu-item-expanded-background-color,
    #f7f9fa
  );
}

.next-cascader-menu-icon-right {
  position: absolute;
  top: 0;
  right: 10px;
  color: #333;
  color: var(--cascader-menu-icon-expand-color, #333);
}

.next-cascader-menu-icon-right:hover {
  color: #333;
  color: var(--cascader-menu-icon-hover-expand-color, #333);
}

.next-cascader-menu-icon-expand.next-icon .next-icon-remote,
.next-cascader-menu-icon-expand.next-icon:before {
  width: 8px;
  width: var(--cascader-menu-icon-expand-size, 8px);
  font-size: 8px;
  font-size: var(--cascader-menu-icon-expand-size, 8px);
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-cascader-menu-icon-expand.next-icon {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -4px;
    margin-left: calc(-8px + var(--cascader-menu-icon-expand-size, 8px) / 2);
    margin-right: -4px;
    margin-right: calc(-8px + var(--cascader-menu-icon-expand-size, 8px) / 2);
  }

  .next-cascader-menu-icon-expand.next-icon:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-cascader-menu-icon-loading.next-icon .next-icon-remote,
.next-cascader-menu-icon-loading.next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-cascader-menu-item.next-expanded .next-cascader-menu-icon-right {
  color: #333;
  color: var(--cascader-menu-icon-hover-expand-color, #333);
}

.next-cascader-menu-item.next-expanded .next-cascader-menu-icon-loading {
  color: #0064c8;
  color: var(--color-brand1-6, #0064c8);
}

.next-cascader-filtered-list {
  height: 192px;
  padding: 0;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: auto;
}

.next-cascader-filtered-list .next-menu-item-inner {
  overflow: visible;
}

.next-cascader-filtered-item em {
  color: #0064c8;
  color: var(--color-brand1-6, #0064c8);
  font-style: normal;
}

.next-cascader[dir="rtl"] .next-cascader-menu-wrapper {
  float: right;
  border-left: none;
  border-right: 1px solid #e3e4e6;
  border-right: var(--cascader-menu-border-width, 1px) var(--line-solid, solid)
    var(--cascader-menu-border-color, #e3e4e6);
}

.next-cascader[dir="rtl"] .next-cascader-menu-wrapper:first-child {
  border-right: none;
}

.next-cascader[dir="rtl"] .next-cascader-menu.next-has-right-border {
  border-right: none;
  border-left: 1px solid #e3e4e6;
  border-left: var(--cascader-menu-border-width, 1px) var(--line-solid, solid)
    var(--cascader-menu-border-color, #e3e4e6);
}

.next-cascader[dir="rtl"] .next-cascader-menu-icon-right {
  right: auto;
  left: 10px;
}

.next-cascader-select,
.next-cascader-select *,
.next-cascader-select-dropdown,
.next-cascader-select :after,
.next-cascader-select :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-cascader-select-dropdown {
  border: 1px solid #c0c6cc;
  border: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #c0c6cc);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--popup-local-corner, 2px);
  -moz-border-radius: var(--popup-local-corner, 2px);
  border-radius: var(--popup-local-corner, 2px);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --popup-local-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
}

.next-cascader-select-dropdown *,
.next-cascader-select-dropdown :after,
.next-cascader-select-dropdown :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-cascader-select-dropdown .next-cascader {
  display: block;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-cascader-select-not-found {
  padding: 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: auto;
  color: #888;
  color: var(--color-text1-2, #888);
}

.next-cascader-select-not-found .next-menu-item:hover {
  color: #888;
  color: var(--color-text1-2, #888);
  background: #fff;
  cursor: default;
}

.next-collapse[dir="rtl"] .next-collapse-panel-title {
  padding: 16px 36px 16px 0;
  padding: var(--collapse-title-padding-tb, 16px) 36px
    var(--collapse-title-padding-tb, 16px) 0;
}

.next-collapse[dir="rtl"] .next-collapse-panel-icon {
  left: inherit;
  right: 16px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: 0;
}

.next-collapse[dir="rtl"] .next-collapse-panel-icon .next-icon-remote,
.next-collapse[dir="rtl"] .next-collapse-panel-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-collapse {
  border: 1px solid #e3e4e6;
  border: var(--collapse-border-width, 1px) solid
    var(--collapse-border-color, #e3e4e6);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--collapse-border-corner, 2px);
  -moz-border-radius: var(--collapse-border-corner, 2px);
  border-radius: var(--collapse-border-corner, 2px);
}

.next-collapse,
.next-collapse *,
.next-collapse :after,
.next-collapse :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-collapse:focus,
.next-collapse :focus {
  outline: 0;
}

.next-collapse-panel:not(:first-child) {
  border-top: 1px solid #e3e4e6;
  border-top: var(--collapse-title-border-width, 1px) solid
    var(--collapse-panel-border-color, #e3e4e6);
}

.next-collapse .next-collapse-panel-icon {
  position: absolute;
  color: #333;
  color: var(--collapse-icon-color, #333);
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: -o-transform 0.1s linear;
  -moz-transition: transform 0.1s linear, -moz-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear,
    -moz-transform 0.1s linear, -o-transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  left: 16px;
  margin-top: -2px;
  margin-left: 0;
  margin-right: 0;
}

.next-collapse .next-collapse-panel-icon .next-icon-remote,
.next-collapse .next-collapse-panel-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-collapse-panel-title {
  position: relative;
  line-height: 20px;
  line-height: var(--collapse-title-height, 20px);
  background: #f7f9fa;
  background: var(--collapse-title-bg-color, #f7f9fa);
  font-size: 12px;
  font-size: var(--collapse-title-font-size, 12px);
  font-weight: 400;
  font-weight: var(--collapse-title-font-weight, normal);
  color: #333;
  color: var(--collapse-title-font-color, #333);
  cursor: pointer;
  padding: 16px 0 16px 36px;
  padding: var(--collapse-title-padding-tb, 16px) 0
    var(--collapse-title-padding-tb, 16px) 36px;
  -webkit-transition: background 0.1s linear;
  -o-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  transition: background 0.1s linear;
}

.next-collapse-panel-title:hover {
  background: #eff3f8;
  background: var(--collapse-title-hover-bg-color, #eff3f8);
  color: #333;
  color: var(--collapse-title-hover-font-color, #333);
  font-weight: 400;
  font-weight: var(--collapse-title-hover-font-weight, normal);
}

.next-collapse-panel-title:hover .next-collapse-panel-icon {
  color: #333;
  color: var(--collapse-icon-hover-color, #333);
}

.next-collapse-panel-content {
  height: 0;
  padding: 0 16px;
  padding: 0 var(--collapse-content-padding-x, 16px);
  background: #fff;
  background: var(--collapse-content-bg-color, #fff);
  font-size: 12px;
  font-size: var(--collapse-content-font-size, 12px);
  color: #333;
  color: var(--collapse-content-color, #333);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
}

.next-collapse-panel-expanded > .next-collapse-panel-content {
  display: block;
  padding: 16px;
  padding: var(--collapse-content-padding-y, 16px)
    var(--collapse-content-padding-x, 16px);
  height: auto;
  opacity: 1;
}

.next-collapse .next-collapse-unfold-icon:before {
  content: "";
  content: var(--collapse-unfold-icon-content, "");
}

.next-collapse-panel-hidden > .next-collapse-panel-content {
  overflow: hidden;
}

.next-collapse .next-collapse-panel-icon:before {
  content: "\E619";
  content: var(--collapse-fold-icon-content, "\E619");
}

.next-collapse .next-collapse-panel-icon.next-collapse-panel-icon-expanded {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-left: 0;
  margin-right: 0;
}

.next-collapse
  .next-collapse-panel-icon.next-collapse-panel-icon-expanded
  .next-icon-remote,
.next-collapse
  .next-collapse-panel-icon.next-collapse-panel-icon-expanded:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-collapse-disabled,
.next-collapse-panel-disabled:not(:first-child) {
  border-color: #e3e4e6;
  border-color: var(--collapse-disabled-border-color, #e3e4e6);
}

.next-collapse-panel-disabled > .next-collapse-panel-title {
  cursor: not-allowed;
  background: #f7f9fa;
  background: var(--collapse-title-disabled-bg-color, #f7f9fa);
}

.next-collapse-panel-disabled .next-collapse-panel-icon,
.next-collapse-panel-disabled > .next-collapse-panel-title {
  color: #ccc;
  color: var(--collapse-title-font-disabled-color, #ccc);
}

.next-collapse-panel-disabled .next-collapse-panel-title:hover {
  font-weight: 400;
  font-weight: var(--collapse-title-font-weight, normal);
}

.next-collapse-panel-disabled
  .next-collapse-panel-title:hover
  .next-collapse-panel-icon,
.next-collapse-panel-disabled:hover {
  color: #ccc;
  color: var(--collapse-title-font-disabled-color, #ccc);
}

.next-collapse-panel-disabled:hover {
  background: #f7f9fa;
  background: var(--collapse-title-disabled-bg-color, #f7f9fa);
}

.next-time-picker-menu {
  float: left;
  text-align: center;
}

.next-time-picker-menu:not(:last-child) {
  border-right: 1px solid #c0c6cc;
  border-right: var(--time-picker-menu-border-width, 1px)
    var(--line-solid, solid) var(--time-picker-menu-border-color, #c0c6cc);
}

.next-time-picker-menu-title {
  cursor: default;
  height: 36px;
  height: var(--time-picker-menu-title-height, 36px);
  line-height: 36px;
  line-height: var(--time-picker-menu-title-height, 36px);
  font-size: 12px;
  font-size: var(--time-picker-menu-title-font-size, 12px);
  font-weight: 400;
  font-weight: var(--time-pikcer-menu-title-font-weight, normal);
  color: #333;
  color: var(--time-picker-menu-title-color, #333);
  background: #fff;
  background: var(--time-picker-menu-title-background, #fff);
}

.next-time-picker-menu ul {
  position: relative;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-size: var(--time-picker-menu-item-font-size, 12px);
  height: 224px;
  height: -webkit-calc(var(--time-picker-menu-item-height, 32px) * 7);
  height: -moz-calc(var(--time-picker-menu-item-height, 32px) * 7);
  height: calc(var(--time-picker-menu-item-height, 32px) * 7);
}

.next-time-picker-menu-item {
  cursor: pointer;
  height: 32px;
  height: var(--time-picker-menu-item-height, 32px);
  line-height: 32px;
  line-height: var(--time-picker-menu-item-height, 32px);
  -webkit-transition: background 0.1s linear;
  -o-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  transition: background 0.1s linear;
  color: #333;
  color: var(--time-picker-menu-item-color, #333);
  background: #fff;
  background: var(--time-picker-menu-item-background, #fff);
  outline: none;
}

.next-time-picker-menu-item:hover {
  color: #333;
  color: var(--time-picker-menu-item-color-hover, #333);
  background: #f7f9fa;
  background: var(--time-picker-menu-item-background-hover, #f7f9fa);
}

.next-time-picker-menu-item.next-selected {
  font-weight: 400;
  font-weight: var(--time-picker-menu-item-font-weight-selected, normal);
  color: #fff;
  color: var(--time-picker-menu-item-color-selected, #fff);
  background: #0064c8;
  background: var(--time-picker-menu-item-background-selected, #0064c8);
}

.next-time-picker-menu-item.next-disabled {
  cursor: not-allowed;
  color: #555;
  color: var(--time-picker-menu-item-color-disabled, #555);
  background: #fff;
  background: var(--time-picker-menu-item-background-disabled, #fff);
}

.next-time-picker-panel,
.next-time-picker-panel *,
.next-time-picker-panel :after,
.next-time-picker-panel :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-time-picker-panel:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-time-picker-panel-header {
  border-bottom: 1px solid #c0c6cc;
  border-bottom: var(--line-1, 1px) var(--line-solid, solid)
    var(--popup-local-border-color, #c0c6cc);
}

.next-time-picker-panel-input.next-input {
  width: 100%;
  padding: 6px;
  border-color: transparent;
  vertical-align: middle;
}

.next-time-picker-panel-col-3 .next-time-picker-menu {
  width: 33.33333%;
}

.next-time-picker-panel-col-2 .next-time-picker-menu {
  width: 50%;
}

.next-time-picker-panel-col-1 .next-time-picker-menu {
  width: 100%;
}

.next-time-picker-body[dir="rtl"] .next-time-picker-menu {
  float: right;
}

.next-time-picker-body[dir="rtl"] .next-time-picker-menu:not(:last-child) {
  border-right: none;
  border-left: 1px solid #c0c6cc;
  border-left: var(--time-picker-menu-border-width, 1px)
    var(--line-solid, solid) var(--time-picker-menu-border-color, #c0c6cc);
}

.next-time-picker {
  display: inline-block;
  width: 200px;
}

.next-time-picker,
.next-time-picker *,
.next-time-picker :after,
.next-time-picker :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-time-picker-trigger .next-input {
  width: 100%;
}

.next-time-picker-body {
  overflow: hidden;
  width: 200px;
  width: var(--time-picker-panel-width, 200px);
  border: 1px solid #c0c6cc;
  border: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #c0c6cc);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--popup-local-corner, 2px);
  -moz-border-radius: var(--popup-local-corner, 2px);
  border-radius: var(--popup-local-corner, 2px);
  background: #fff;
  background: var(--time-picker-panel-background, #fff);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --popup-local-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
}

.next-time-picker-symbol-clock-icon:before {
  content: "\E621";
  content: var(--time-picker-clock-icon, "\E621");
}

.next-range-picker-panel-input-separator,
.next-range-picker-trigger-separator {
  cursor: default;
  display: inline-block;
  text-align: center;
  color: #c1c1c1;
  color: var(--color-text1-1, #c1c1c1);
  width: 16px;
  font-size: 12px;
  font-size: var(--font-size-caption, 12px);
  vertical-align: middle;
}

.next-date-picker,
.next-month-picker,
.next-week-picker,
.next-year-picker {
  display: inline-block;
  width: 200px;
}

.next-date-picker-input,
.next-month-picker-input,
.next-week-picker-input,
.next-year-picker-input {
  width: 100%;
}

.next-date-picker-body,
.next-month-picker-body,
.next-week-picker-body,
.next-year-picker-body {
  width: 288px;
}

.next-date-picker-panel-input.next-input,
.next-month-picker-panel-input.next-input,
.next-week-picker-panel-input.next-input,
.next-year-picker-panel-input.next-input {
  width: 100%;
  background: transparent;
}

.next-date-picker-body.next-date-picker-body-show-time
  .next-date-picker-panel-input.next-input {
  width: 49%;
}

.next-date-picker-body.next-date-picker-body-show-time
  .next-date-picker-panel-input.next-input:first-child {
  margin-right: 2%;
}

.next-range-picker {
  display: inline-block;
  width: 336px;
}

.next-range-picker-input {
  width: 100%;
}

.next-range-picker-trigger {
  border: 1px solid #c0c6cc;
  border: var(--input-border-width, 1px) solid
    var(--input-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--input-bg-color, #fff);
}

.next-range-picker-trigger:hover {
  border-color: #c0c6cc;
  border-color: var(--input-hover-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--input-hover-bg-color, #fff);
}

.next-range-picker-trigger.next-error {
  border-color: #c80000;
  border-color: var(--input-feedback-error-border-color, #c80000);
}

.next-range-picker-trigger-input.next-input {
  height: auto;
  width: -webkit-calc(50% - 8px);
  width: -moz-calc(50% - 8px);
  width: calc(50% - 8px);
}

.next-range-picker.next-disabled .next-range-picker-trigger {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
  cursor: not-allowed;
}

.next-range-picker.next-disabled .next-range-picker-trigger,
.next-range-picker.next-disabled .next-range-picker-trigger:hover {
  border-color: #c0c6cc;
  border-color: var(--input-disabled-border-color, #c0c6cc);
  background-color: #f7f9fa;
  background-color: var(--input-disabled-bg-color, #f7f9fa);
}

.next-range-picker.next-large .next-range-picker-panel-input,
.next-range-picker.next-large .next-range-picker-trigger {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-large-corner, 2px);
  -moz-border-radius: var(--form-element-large-corner, 2px);
  border-radius: var(--form-element-large-corner, 2px);
}

.next-range-picker.next-medium .next-range-picker-panel-input,
.next-range-picker.next-medium .next-range-picker-trigger {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-medium-corner, 2px);
  -moz-border-radius: var(--form-element-medium-corner, 2px);
  border-radius: var(--form-element-medium-corner, 2px);
}

.next-range-picker.next-small .next-range-picker-panel-input,
.next-range-picker.next-small .next-range-picker-trigger {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-small-corner, 2px);
  -moz-border-radius: var(--form-element-small-corner, 2px);
  border-radius: var(--form-element-small-corner, 2px);
}

.next-range-picker-body {
  width: 600px;
}

.next-range-picker-panel-input-end-date.next-input,
.next-range-picker-panel-input-start-date.next-input {
  width: -webkit-calc(50% - 8px);
  width: -moz-calc(50% - 8px);
  width: calc(50% - 8px);
}

.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-input-end-date,
.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-input-end-time,
.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-input-start-date,
.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-input-start-time {
  width: -webkit-calc(25% - 8px);
  width: -moz-calc(25% - 8px);
  width: calc(25% - 8px);
}

.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-input-start-date {
  margin-right: 8px;
}

.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-input-end-time {
  margin-left: 8px;
}

.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-time:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-time-end,
.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-time-start {
  width: 50%;
  float: left;
}

.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-time-start {
  border-right: 1px solid #c0c6cc;
  border-right: var(--line-1, 1px) var(--line-solid, solid)
    var(--date-picker-panel-time-panel-separator-color, #c0c6cc);
}

.next-range-picker-body.next-range-picker-body-show-time
  .next-range-picker-panel-time-end {
  border-left: 1px solid #c0c6cc;
  border-left: var(--line-1, 1px) var(--line-solid, solid)
    var(--date-picker-panel-time-panel-separator-color, #c0c6cc);
}

.next-date-picker-body[dir="rtl"] .next-date-picker-panel-footer {
  text-align: left;
}

.next-date-picker-body[dir="rtl"]
  .next-date-picker-panel-footer
  > .next-btn:not(:last-child) {
  margin-right: 0;
  margin-left: 16px;
}

.next-date-picker-body[dir="rtl"].next-date-picker-body-show-time
  .next-date-picker-panel-input.next-input:first-child {
  margin-left: 2%;
  margin-right: 0;
}

.next-date-picker-body[dir="rtl"].next-date-picker-body-show-time
  .next-time-picker-menu {
  float: right;
}

.next-date-picker-body[dir="rtl"].next-date-picker-body-show-time
  .next-time-picker-menu:not(:last-child) {
  border-right: none;
  border-left: 1px solid #c0c6cc;
  border-left: var(--time-picker-menu-border-width, 1px)
    var(--line-solid, solid) var(--time-picker-menu-border-color, #c0c6cc);
}

.next-range-picker-body[dir="rtl"] .next-range-picker-panel-input {
  text-align: right;
}

.next-range-picker-body[dir="rtl"] .next-date-picker-panel-footer {
  text-align: left;
}

.next-range-picker-body[dir="rtl"]
  .next-date-picker-panel-footer
  > .next-btn:not(:last-child) {
  margin-right: 0;
  margin-left: 16px;
}

.next-range-picker-body[dir="rtl"].next-range-picker-body-show-time
  .next-range-picker-panel-input-start-date {
  margin-right: 0;
  margin-left: 8px;
}

.next-range-picker-body[dir="rtl"].next-range-picker-body-show-time
  .next-range-picker-panel-input-end-time {
  margin-left: 0;
  margin-right: 8px;
}

.next-range-picker-body[dir="rtl"].next-range-picker-body-show-time
  .next-range-picker-panel-time-end,
.next-range-picker-body[dir="rtl"].next-range-picker-body-show-time
  .next-range-picker-panel-time-start {
  float: right;
}

.next-range-picker-body[dir="rtl"].next-range-picker-body-show-time
  .next-range-picker-panel-time-start {
  border-right: none;
  border-left: 1px solid #c0c6cc;
  border-left: var(--line-1, 1px) var(--line-solid, solid)
    var(--date-picker-panel-time-panel-separator-color, #c0c6cc);
}

.next-range-picker-body[dir="rtl"].next-range-picker-body-show-time
  .next-range-picker-panel-time-end {
  border-left: none;
  border-right: 1px solid #c0c6cc;
  border-right: var(--line-1, 1px) var(--line-solid, solid)
    var(--date-picker-panel-time-panel-separator-color, #c0c6cc);
}

.next-range-picker-body[dir="rtl"].next-range-picker-body-show-time
  .next-time-picker-menu {
  float: right;
}

.next-range-picker-body[dir="rtl"].next-range-picker-body-show-time
  .next-time-picker-menu:not(:last-child) {
  border-right: none;
  border-left: 1px solid #c0c6cc;
  border-left: var(--time-picker-menu-border-width, 1px)
    var(--line-solid, solid) var(--time-picker-menu-border-color, #c0c6cc);
}

.next-date-picker,
.next-date-picker *,
.next-date-picker :after,
.next-date-picker :before,
.next-month-picker,
.next-month-picker *,
.next-month-picker :after,
.next-month-picker :before,
.next-range-picker,
.next-range-picker *,
.next-range-picker :after,
.next-range-picker :before,
.next-week-picker,
.next-week-picker *,
.next-week-picker :after,
.next-week-picker :before,
.next-year-picker,
.next-year-picker *,
.next-year-picker :after,
.next-year-picker :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-date-picker-body,
.next-month-picker-body,
.next-range-picker-body,
.next-week-picker-body,
.next-year-picker-body {
  border: 1px solid #c0c6cc;
  border: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #c0c6cc);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--popup-local-corner, 2px);
  -moz-border-radius: var(--popup-local-corner, 2px);
  border-radius: var(--popup-local-corner, 2px);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --popup-local-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  background: #fff;
  background: var(--date-picker-panel-background, #fff);
}

.next-date-picker-panel-header,
.next-month-picker-panel-header,
.next-range-picker-panel-header,
.next-week-picker-panel-header,
.next-year-picker-panel-header {
  padding: 6px;
  text-align: center;
}

.next-date-picker-panel-time,
.next-month-picker-panel-time,
.next-range-picker-panel-time,
.next-week-picker-panel-time,
.next-year-picker-panel-time {
  border-top: 1px solid #c0c6cc;
  border-top: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #c0c6cc);
}

.next-date-picker-panel-footer,
.next-month-picker-panel-footer,
.next-range-picker-panel-footer,
.next-week-picker-panel-footer,
.next-year-picker-panel-footer {
  text-align: right;
  padding: 8px 16px;
  padding: var(--date-picker-panel-footer-padding-tb, 8px)
    var(--date-picker-panel-footer-padding-lr, 16px);
  border-top: 1px solid #c0c6cc;
  border-top: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #c0c6cc);
}

.next-date-picker-panel-footer > .next-btn:not(:last-child),
.next-date-picker-panel-tools > .next-btn:not(:last-child),
.next-month-picker-panel-footer > .next-btn:not(:last-child),
.next-month-picker-panel-tools > .next-btn:not(:last-child),
.next-range-picker-panel-footer > .next-btn:not(:last-child),
.next-range-picker-panel-tools > .next-btn:not(:last-child),
.next-week-picker-panel-footer > .next-btn:not(:last-child),
.next-week-picker-panel-tools > .next-btn:not(:last-child),
.next-year-picker-panel-footer > .next-btn:not(:last-child),
.next-year-picker-panel-tools > .next-btn:not(:last-child) {
  margin-right: 16px;
}

.next-date-picker-panel-tools,
.next-month-picker-panel-tools,
.next-range-picker-panel-tools,
.next-week-picker-panel-tools,
.next-year-picker-panel-tools {
  float: left;
}

.next-date-picker .next-calendar-panel-header,
.next-month-picker .next-calendar-panel-header,
.next-range-picker .next-calendar-panel-header,
.next-week-picker .next-calendar-panel-header,
.next-year-picker .next-calendar-panel-header {
  margin-left: -1px;
  margin-left: calc(0px - var(--popup-local-border-width, 1px));
  margin-right: -1px;
  margin-right: calc(0px - var(--popup-local-border-width, 1px));
}

.next-date-picker .next-input input,
.next-month-picker .next-input input,
.next-range-picker .next-input input,
.next-week-picker .next-input input,
.next-year-picker .next-input input {
  vertical-align: baseline;
}

.next-date-picker-symbol-calendar-icon:before,
.next-month-picker-symbol-calendar-icon:before,
.next-range-picker-symbol-calendar-icon:before,
.next-week-picker-symbol-calendar-icon:before,
.next-year-picker-symbol-calendar-icon:before {
  content: "\E6FB";
  content: var(--date-picker-calendar-icon, "\E6FB");
}

.next-range-picker-panel-body .next-calendar {
  display: inline-block;
  width: 50%;
}

.next-message {
  position: relative;
  display: block;
  vertical-align: baseline;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.next-message,
.next-message *,
.next-message :after,
.next-message :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-message:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-message .next-message-close {
  color: #333;
  color: var(--message-close-icon-color, #333);
  font-size: 0;
  position: absolute;
  cursor: pointer;
}

.next-message .next-message-close .next-icon-close {
  width: 12px;
  height: 12px;
  line-height: 1em;
}

.next-message .next-message-close .next-icon-close:before {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 1em;
}

.next-message .next-message-close:hover {
  color: #333;
  color: var(--message-hover-close-icon-color, #333);
}

.next-message.next-message-success.next-inline {
  background-color: #d8f5d8;
  background-color: var(--message-success-color-bg-inline, #d8f5d8);
  border-color: transparent;
  border-color: var(--message-success-color-border-inline, transparent);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style, solid);
}

.next-message.next-message-success.next-inline .next-message-title {
  color: #333;
  color: var(--message-success-color-title-inline, #333);
}

.next-message.next-message-success.next-inline .next-message-content {
  color: #333;
  color: var(--message-success-color-content-inline, #333);
}

.next-message.next-message-success.next-inline .next-message-symbol {
  color: #00a700;
  color: var(--message-success-color-icon-inline, #00a700);
}

.next-message.next-message-success.next-inline
  .next-message-symbol-icon:before {
  content: "\E8C4";
  content: var(--message-success-icon-content, "\E8C4");
}

.next-message.next-message-success.next-addon {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-success.next-addon .next-message-title {
  color: #111;
  color: var(--message-success-color-title-addon, #111);
}

.next-message.next-message-success.next-addon .next-message-content {
  color: #555;
  color: var(--message-success-color-content-addon, #555);
}

.next-message.next-message-success.next-addon .next-message-symbol {
  color: #32c832;
  color: var(--message-success-color-icon-addon, #32c832);
}

.next-message.next-message-success.next-addon .next-message-symbol-icon:before {
  content: "\E8C4";
  content: var(--message-success-icon-content, "\E8C4");
}

.next-message.next-message-success.next-toast {
  background-color: #fff;
  background-color: var(--message-success-color-bg-toast, #fff);
  border-color: #c0c6cc;
  border-color: var(--message-success-color-border-toast, #c0c6cc);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-success.next-toast .next-message-title {
  color: #333;
  color: var(--message-success-color-title-toast, #333);
}

.next-message.next-message-success.next-toast .next-message-content {
  color: #333;
  color: var(--message-success-color-content-toast, #333);
}

.next-message.next-message-success.next-toast .next-message-symbol {
  color: #00a700;
  color: var(--message-success-color-icon-toast, #00a700);
}

.next-message.next-message-success.next-toast .next-message-symbol-icon:before {
  content: "\E8C4";
  content: var(--message-success-icon-content, "\E8C4");
}

.next-message.next-message-warning.next-inline {
  background-color: #fff7d1;
  background-color: var(--message-warning-color-bg-inline, #fff7d1);
  border-color: transparent;
  border-color: var(--message-warning-color-border-inline, transparent);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style, solid);
}

.next-message.next-message-warning.next-inline .next-message-title {
  color: #333;
  color: var(--message-warning-color-title-inline, #333);
}

.next-message.next-message-warning.next-inline .next-message-content {
  color: #333;
  color: var(--message-warning-color-content-inline, #333);
}

.next-message.next-message-warning.next-inline .next-message-symbol {
  color: #fac800;
  color: var(--message-warning-color-icon-inline, #fac800);
}

.next-message.next-message-warning.next-inline
  .next-message-symbol-icon:before {
  content: "\E8C3";
  content: var(--message-warning-icon-content, "\E8C3");
}

.next-message.next-message-warning.next-addon {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-warning.next-addon .next-message-title {
  color: #111;
  color: var(--message-warning-color-title-addon, #111);
}

.next-message.next-message-warning.next-addon .next-message-content {
  color: #555;
  color: var(--message-warning-color-content-addon, #555);
}

.next-message.next-message-warning.next-addon .next-message-symbol {
  color: #ffde5c;
  color: var(--message-warning-color-icon-addon, #ffde5c);
}

.next-message.next-message-warning.next-addon .next-message-symbol-icon:before {
  content: "\E8C3";
  content: var(--message-warning-icon-content, "\E8C3");
}

.next-message.next-message-warning.next-toast {
  background-color: #fff;
  background-color: var(--message-warning-color-bg-toast, #fff);
  border-color: #c0c6cc;
  border-color: var(--message-warning-color-border-toast, #c0c6cc);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-warning.next-toast .next-message-title {
  color: #333;
  color: var(--message-warning-color-title-toast, #333);
}

.next-message.next-message-warning.next-toast .next-message-content {
  color: #333;
  color: var(--message-warning-color-content-toast, #333);
}

.next-message.next-message-warning.next-toast .next-message-symbol {
  color: #fac800;
  color: var(--message-warning-color-icon-toast, #fac800);
}

.next-message.next-message-warning.next-toast .next-message-symbol-icon:before {
  content: "\E8C3";
  content: var(--message-warning-icon-content, "\E8C3");
}

.next-message.next-message-error.next-inline {
  background-color: #f8efef;
  background-color: var(--message-error-color-bg-inline, #f8efef);
  border-color: transparent;
  border-color: var(--message-error-color-border-inline, transparent);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style, solid);
}

.next-message.next-message-error.next-inline .next-message-title {
  color: #333;
  color: var(--message-error-color-title-inline, #333);
}

.next-message.next-message-error.next-inline .next-message-content {
  color: #333;
  color: var(--message-error-color-content-inline, #333);
}

.next-message.next-message-error.next-inline .next-message-symbol {
  color: #c80000;
  color: var(--message-error-color-icon-inline, #c80000);
}

.next-message.next-message-error.next-inline .next-message-symbol-icon:before {
  content: "\E8C3";
  content: var(--message-error-icon-content, "\E8C3");
}

.next-message.next-message-error.next-addon {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-error.next-addon .next-message-title {
  color: #111;
  color: var(--message-error-color-title-addon, #111);
}

.next-message.next-message-error.next-addon .next-message-content {
  color: #555;
  color: var(--message-error-color-content-addon, #555);
}

.next-message.next-message-error.next-addon .next-message-symbol {
  color: #c80000;
  color: var(--message-error-color-icon-addon, #c80000);
}

.next-message.next-message-error.next-addon .next-message-symbol-icon:before {
  content: "\E8C3";
  content: var(--message-error-icon-content, "\E8C3");
}

.next-message.next-message-error.next-toast {
  background-color: #fff;
  background-color: var(--message-error-color-bg-toast, #fff);
  border-color: #c0c6cc;
  border-color: var(--message-error-color-border-toast, #c0c6cc);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-error.next-toast .next-message-title {
  color: #333;
  color: var(--message-error-color-title-toast, #333);
}

.next-message.next-message-error.next-toast .next-message-content {
  color: #333;
  color: var(--message-error-color-content-toast, #333);
}

.next-message.next-message-error.next-toast .next-message-symbol {
  color: #c80000;
  color: var(--message-error-color-icon-toast, #c80000);
}

.next-message.next-message-error.next-toast .next-message-symbol-icon:before {
  content: "\E8C3";
  content: var(--message-error-icon-content, "\E8C3");
}

.next-message.next-message-notice.next-inline {
  background-color: #eff3f8;
  background-color: var(--message-notice-color-bg-inline, #eff3f8);
  border-color: transparent;
  border-color: var(--message-notice-color-border-inline, transparent);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style, solid);
}

.next-message.next-message-notice.next-inline .next-message-title {
  color: #333;
  color: var(--message-notice-color-title-inline, #333);
}

.next-message.next-message-notice.next-inline .next-message-content {
  color: #333;
  color: var(--message-notice-color-content-inline, #333);
}

.next-message.next-message-notice.next-inline .next-message-symbol {
  color: #0064c8;
  color: var(--message-notice-color-icon-inline, #0064c8);
}

.next-message.next-message-notice.next-inline .next-message-symbol-icon:before {
  content: "\E8C9";
  content: var(--message-notice-icon-content, "\E8C9");
}

.next-message.next-message-notice.next-addon {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-notice.next-addon .next-message-title {
  color: #111;
  color: var(--message-notice-color-title-addon, #111);
}

.next-message.next-message-notice.next-addon .next-message-content {
  color: #555;
  color: var(--message-notice-color-content-addon, #555);
}

.next-message.next-message-notice.next-addon .next-message-symbol {
  color: #0070cc;
  color: var(--message-notice-color-icon-addon, #0070cc);
}

.next-message.next-message-notice.next-addon .next-message-symbol-icon:before {
  content: "\E8C9";
  content: var(--message-notice-icon-content, "\E8C9");
}

.next-message.next-message-notice.next-toast {
  background-color: #fff;
  background-color: var(--message-notice-color-bg-toast, #fff);
  border-color: #fff;
  border-color: var(--message-notice-color-border-toast, #fff);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-notice.next-toast .next-message-title {
  color: #333;
  color: var(--message-notice-color-title-toast, #333);
}

.next-message.next-message-notice.next-toast .next-message-content {
  color: #666;
  color: var(--message-notice-color-content-toast, #666);
}

.next-message.next-message-notice.next-toast .next-message-symbol {
  color: #0070cc;
  color: var(--message-notice-color-icon-toast, #0070cc);
}

.next-message.next-message-notice.next-toast .next-message-symbol-icon:before {
  content: "\E8C9";
  content: var(--message-notice-icon-content, "\E8C9");
}

.next-message.next-message-help.next-inline {
  background-color: #f8f8f9;
  background-color: var(--message-help-color-bg-inline, #f8f8f9);
  border-color: transparent;
  border-color: var(--message-help-color-border-inline, transparent);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style, solid);
}

.next-message.next-message-help.next-inline .next-message-title {
  color: #333;
  color: var(--message-help-color-title-inline, #333);
}

.next-message.next-message-help.next-inline .next-message-content {
  color: #333;
  color: var(--message-help-color-content-inline, #333);
}

.next-message.next-message-help.next-inline .next-message-symbol {
  color: #333;
  color: var(--message-help-color-icon-inline, #333);
}

.next-message.next-message-help.next-inline .next-message-symbol-icon:before {
  content: "\E8BE";
  content: var(--message-help-icon-content, "\E8BE");
}

.next-message.next-message-help.next-addon {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-help.next-addon .next-message-title {
  color: #111;
  color: var(--message-help-color-title-addon, #111);
}

.next-message.next-message-help.next-addon .next-message-content {
  color: #555;
  color: var(--message-help-color-content-addon, #555);
}

.next-message.next-message-help.next-addon .next-message-symbol {
  color: #333;
  color: var(--message-help-color-icon-addon, #333);
}

.next-message.next-message-help.next-addon .next-message-symbol-icon:before {
  content: "\E8BE";
  content: var(--message-help-icon-content, "\E8BE");
}

.next-message.next-message-help.next-toast {
  background-color: #fff;
  background-color: var(--message-help-color-bg-toast, #fff);
  border-color: #fff;
  border-color: var(--message-help-color-border-toast, #fff);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-help.next-toast .next-message-title {
  color: #333;
  color: var(--message-help-color-title-toast, #333);
}

.next-message.next-message-help.next-toast .next-message-content {
  color: #555;
  color: var(--message-help-color-content-toast, #555);
}

.next-message.next-message-help.next-toast .next-message-symbol {
  color: #888;
  color: var(--message-help-color-icon-toast, #888);
}

.next-message.next-message-help.next-toast .next-message-symbol-icon:before {
  content: "\E8BE";
  content: var(--message-help-icon-content, "\E8BE");
}

.next-message.next-message-loading.next-inline {
  background-color: #fff;
  background-color: var(--message-loading-color-bg-inline, #fff);
  border-color: #e3e4e6;
  border-color: var(--message-loading-color-border-inline, #e3e4e6);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style, solid);
}

.next-message.next-message-loading.next-inline .next-message-title {
  color: #333;
  color: var(--message-loading-color-title-inline, #333);
}

.next-message.next-message-loading.next-inline .next-message-content {
  color: #333;
  color: var(--message-loading-color-content-inline, #333);
}

.next-message.next-message-loading.next-inline .next-message-symbol {
  color: #333;
  color: var(--message-loading-color-icon-inline, #333);
}

.next-message.next-message-loading.next-inline
  .next-message-symbol-icon:before {
  content: "\E646";
  content: var(--message-loading-icon-content, "\E646");
  -webkit-animation: loadingCircle 1s linear infinite;
  -moz-animation: loadingCircle 1s infinite linear;
  -o-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s linear infinite;
}

.next-message.next-message-loading.next-addon {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-loading.next-addon .next-message-title {
  color: #111;
  color: var(--message-loading-color-title-addon, #111);
}

.next-message.next-message-loading.next-addon .next-message-content {
  color: #555;
  color: var(--message-loading-color-content-addon, #555);
}

.next-message.next-message-loading.next-addon .next-message-symbol {
  color: #1c1c1c;
  color: var(--message-loading-color-icon-addon, #1c1c1c);
}

.next-message.next-message-loading.next-addon .next-message-symbol-icon:before {
  content: "\E646";
  content: var(--message-loading-icon-content, "\E646");
  -webkit-animation: loadingCircle 1s linear infinite;
  -moz-animation: loadingCircle 1s infinite linear;
  -o-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s linear infinite;
}

.next-message.next-message-loading.next-toast {
  background-color: #fff;
  background-color: var(--message-loading-color-bg-toast, #fff);
  border-color: #fff;
  border-color: var(--message-loading-color-border-toast, #fff);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border-style: solid;
  border-style: var(--message-border-style-toast, solid);
}

.next-message.next-message-loading.next-toast .next-message-title {
  color: #333;
  color: var(--message-loading-color-title-toast, #333);
}

.next-message.next-message-loading.next-toast .next-message-content {
  color: #555;
  color: var(--message-loading-color-content-toast, #555);
}

.next-message.next-message-loading.next-toast .next-message-symbol {
  color: #888;
  color: var(--message-loading-color-icon-toast, #888);
}

.next-message.next-message-loading.next-toast .next-message-symbol-icon:before {
  content: "\E646";
  content: var(--message-loading-icon-content, "\E646");
  -webkit-animation: loadingCircle 1s linear infinite;
  -moz-animation: loadingCircle 1s infinite linear;
  -o-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s linear infinite;
}

.next-message.next-medium {
  border-width: 1px;
  border-width: var(--message-size-m-border-width, 1px);
  padding: 8px;
  padding: var(--message-size-m-padding, 8px);
}

.next-message.next-medium .next-message-symbol {
  float: left;
  line-height: 16px;
  line-height: var(--message-size-m-icon, 16px);
}

.next-message.next-medium .next-message-symbol .next-icon-remote,
.next-message.next-medium .next-message-symbol:before {
  width: 16px;
  width: var(--message-size-m-icon, 16px);
  font-size: 16px;
  font-size: var(--message-size-m-icon, 16px);
  line-height: inherit;
}

.next-message.next-medium .next-message-title {
  font-size: 12px;
  font-size: var(--message-size-m-title-font, 12px);
  line-height: 12px;
  line-height: var(--message-size-m-title-font, 12px);
}

.next-message.next-medium .next-message-content,
.next-message.next-medium .next-message-title {
  padding: 0 20px 0 24px;
  padding: 0 var(--message-size-m-title-content-padding-right, 20px) 0
    calc(
      var(--message-size-m-title-content-padding-left, 8px) +
        var(--message-size-m-icon, 16px)
    );
}

.next-message.next-medium .next-message-content {
  margin-top: 8px;
  margin-top: var(--message-size-m-content-margin-top, 8px);
  font-size: 12px;
  font-size: var(--message-size-m-content-font, 12px);
  line-height: 12px;
  line-height: var(--message-size-m-content-font, 12px);
}

.next-message.next-medium .next-message-symbol + .next-message-content {
  margin-top: 0;
}

.next-message.next-medium.next-only-content .next-message-content,
.next-message.next-medium.next-title-content .next-message-title {
  line-height: 16px;
  line-height: var(--message-size-m-icon, 16px);
}

.next-message.next-medium .next-message-close {
  top: 12px;
  top: var(--message-size-m-close-top, 12px);
  right: 12px;
  right: var(--message-size-m-close-right, 12px);
}

.next-message.next-medium.next-inline {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--message-size-m-border-radius, 2px);
  -moz-border-radius: var(--message-size-m-border-radius, 2px);
  border-radius: var(--message-size-m-border-radius, 2px);
}

.next-message.next-medium.next-toast {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--message-size-m-border-radius-toast, 2px);
  -moz-border-radius: var(--message-size-m-border-radius-toast, 2px);
  border-radius: var(--message-size-m-border-radius-toast, 2px);
}

.next-message.next-large {
  border-width: 1px;
  border-width: var(--message-size-l-border-width, 1px);
  padding: 16px;
  padding: var(--message-size-l-padding, 16px);
  line-height: 18px;
}

.next-message.next-large .next-message-symbol {
  float: left;
  line-height: 16px;
  line-height: var(--message-size-l-icon, 16px);
}

.next-message.next-large .next-message-symbol .next-icon-remote,
.next-message.next-large .next-message-symbol:before {
  width: 16px;
  width: var(--message-size-l-icon, 16px);
  font-size: 16px;
  font-size: var(--message-size-l-icon, 16px);
  line-height: inherit;
}

.next-message.next-large .next-message-title {
  font-size: 14px;
  font-size: var(--message-size-l-title-font, 14px);
  line-height: 14px;
  line-height: var(--message-size-l-title-font, 14px);
}

.next-message.next-large .next-message-content,
.next-message.next-large .next-message-title {
  padding: 0 16px 0 24px;
  padding: 0 var(--message-size-l-title-content-padding-right, 16px) 0
    calc(
      var(--message-size-l-title-content-padding-left, 8px) +
        var(--message-size-l-icon, 16px)
    );
}

.next-message.next-large .next-message-content {
  margin-top: 8px;
  margin-top: var(--message-size-l-content-margin-top, 8px);
  font-size: 12px;
  font-size: var(--message-size-l-content-font, 12px);
  line-height: 12px;
  line-height: var(--message-size-l-content-font, 12px);
}

.next-message.next-large .next-message-symbol + .next-message-content {
  margin-top: 0;
}

.next-message.next-large.next-only-content .next-message-content,
.next-message.next-large.next-title-content .next-message-title {
  line-height: 16px;
  line-height: var(--message-size-l-icon, 16px);
}

.next-message.next-large .next-message-close {
  top: 16px;
  top: var(--message-size-l-close-top, 16px);
  right: 16px;
  right: var(--message-size-l-close-right, 16px);
}

.next-message.next-large.next-inline {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--message-size-l-border-radius, 2px);
  -moz-border-radius: var(--message-size-l-border-radius, 2px);
  border-radius: var(--message-size-l-border-radius, 2px);
}

.next-message.next-large.next-toast {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--message-size-l-border-radius-toast, 2px);
  -moz-border-radius: var(--message-size-l-border-radius-toast, 2px);
  border-radius: var(--message-size-l-border-radius-toast, 2px);
}

.next-message[dir="rtl"] .next-message-symbol {
  float: right;
}

.next-message[dir="rtl"].next-medium .next-message-title {
  padding: 0 24px 0 20px;
  padding: 0
    calc(
      var(--message-size-m-title-content-padding-left, 8px) +
        var(--message-size-m-icon, 16px)
    )
    0 var(--message-size-m-title-content-padding-right, 20px);
}

.next-message[dir="rtl"].next-medium .next-message-close {
  left: 12px;
  left: var(--message-size-m-close-right, 12px);
  right: auto;
}

.next-message[dir="rtl"].next-large .next-message-title {
  padding: 0 24px 0 16px;
  padding: 0
    calc(
      var(--message-size-l-title-content-padding-left, 8px) +
        var(--message-size-l-icon, 16px)
    )
    0 var(--message-size-l-title-content-padding-right, 16px);
}

.next-message[dir="rtl"].next-large .next-message-close {
  left: 16px;
  left: var(--message-size-l-close-right, 16px);
  right: auto;
}

.next-dialog[dir="rtl"],
.next-dialog[dir="rtl"] .next-dialog-footer.next-align-left {
  text-align: right;
}

.next-dialog[dir="rtl"] .next-dialog-footer.next-align-center {
  text-align: center;
}

.next-dialog[dir="rtl"] .next-dialog-footer.next-align-right {
  text-align: left;
}

.next-dialog[dir="rtl"] .next-dialog-btn + .next-dialog-btn {
  margin-right: 4px;
  margin-left: 0;
}

.next-dialog[dir="rtl"] .next-dialog-close {
  left: 16px;
  left: var(--dialog-close-right, 16px);
  right: auto;
}

.next-dialog {
  position: fixed;
  z-index: 1001;
  background: #fff;
  background: var(--dialog-bg, #fff);
  border: 1px solid #c0c6cc;
  border: var(--dialog-border-width, 1px) var(--dialog-border-style, solid)
    var(--dialog-border-color, #c0c6cc);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-border-radius: var(--dialog-corner, 4px);
  -moz-border-radius: var(--dialog-corner, 4px);
  border-radius: var(--dialog-corner, 4px);
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: var(--dialog-shadow, 0 8px 16px 0 rgba(0, 0, 0, 0.2));
  -moz-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: var(--dialog-shadow, 0 8px 16px 0 rgba(0, 0, 0, 0.2));
  box-shadow: var(--dialog-shadow, 0 8px 16px 0 rgba(0, 0, 0, 0.2));
  text-align: left;
  overflow: hidden;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  max-width: 90%;
}

.next-dialog,
.next-dialog *,
.next-dialog :after,
.next-dialog :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-dialog-header {
  padding: 24px 24px 16px;
  padding: var(--dialog-title-padding-top, 24px)
    var(--dialog-title-padding-left-right, 24px)
    var(--dialog-title-padding-bottom, 16px)
    var(--dialog-title-padding-left-right, 24px);
  border-bottom: 0 solid #c0c6cc;
  border-bottom: var(--dialog-title-border-width, 0) var(--line-solid, solid)
    var(--dialog-title-border-color, #c0c6cc);
  font-size: 16px;
  font-size: var(--dialog-title-font-size, 16px);
  background: #fff;
  background: var(--dialog-title-bg-color, #fff);
  color: #333;
  color: var(--dialog-title-color, #333);
}

.next-dialog-body {
  padding: 0 24px 16px;
  padding: var(--dialog-content-padding-top, 0)
    var(--dialog-content-padding-left-right, 24px)
    var(--dialog-content-padding-bottom, 16px)
    var(--dialog-content-padding-left-right, 24px);
  font-size: 12px;
  font-size: var(--dialog-content-font-size, 12px);
  color: #333;
  color: var(--dialog-content-color, #333);
}

.next-dialog-footer {
  padding: 16px 24px 24px;
  padding: var(--dialog-footer-padding-top, 16px)
    var(--dialog-footer-padding-left-right, 24px)
    var(--dialog-footer-padding-bottom, 24px)
    var(--dialog-footer-padding-left-right, 24px);
  border-top: 0 solid #c0c6cc;
  border-top: var(--dialog-footer-border-width, 0) var(--line-solid, solid)
    var(--dialog-footer-border-color, #c0c6cc);
  background: #fff;
  background: var(--dialog-footer-bg-color, #fff);
}

.next-dialog-footer.next-align-left {
  text-align: left;
}

.next-dialog-footer.next-align-center {
  text-align: center;
}

.next-dialog-footer.next-align-right {
  text-align: right;
}

.next-dialog-footer-fixed-height {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.next-dialog-btn + .next-dialog-btn {
  margin-left: 8px;
  margin-left: var(--dialog-footer-button-spacing, 8px);
}

.next-dialog-close {
  position: absolute;
  top: 16px;
  top: var(--dialog-close-top, 16px);
  right: 16px;
  right: var(--dialog-close-right, 16px);
  width: 16px;
  width: var(--dialog-close-width, 16px);
  cursor: pointer;
}

.next-dialog-close,
.next-dialog-close:link,
.next-dialog-close:visited {
  height: 16px;
  height: var(--dialog-close-width, 16px);
  color: #666;
  color: var(--dialog-close-color, #666);
}

.next-dialog-close:hover {
  background: #fff;
  background: var(--dialog-close-bg-hovered, #fff);
  color: #333;
  color: var(--dialog-close-color-hovered, #333);
}

.next-dialog-close .next-dialog-close-icon.next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-top: calc(0px - var(--dialog-close-size, 16px) / 2);
  margin-left: -8px;
  margin-left: calc(0px - var(--dialog-close-size, 16px) / 2);
  width: 16px;
  width: var(--dialog-close-size, 16px);
  height: 16px;
  height: var(--dialog-close-size, 16px);
  line-height: 1em;
}

.next-dialog-close .next-dialog-close-icon.next-icon:before {
  width: 16px;
  width: var(--dialog-close-size, 16px);
  height: 16px;
  height: var(--dialog-close-size, 16px);
  font-size: 16px;
  font-size: var(--dialog-close-size, 16px);
  line-height: 1em;
}

.next-dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  padding: 40px;
  overflow: auto;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-dialog-container:before {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
  content: "";
}

.next-dialog-container .next-dialog {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.next-dialog-quick .next-dialog-body {
  padding: 48px 24px 16px;
  padding: var(--dialog-message-content-padding-top, 48px)
    var(--dialog-message-content-padding-left-right, 24px)
    var(--dialog-message-content-padding-bottom, 16px)
    var(--dialog-message-content-padding-left-right, 24px);
}

.next-dialog .next-dialog-message.next-message {
  min-width: 300px;
  padding: 0;
}

.next-drawer {
  position: fixed;
  z-index: 1001;
  background: #fff;
  background: var(--drawer-bg, #fff);
  border: 1px solid #c0c6cc;
  border: var(--drawer-border-width, 1px) var(--drawer-border-style, solid)
    var(--drawer-border-color, #c0c6cc);
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: var(--drawer-shadow, 0 8px 16px 0 rgba(0, 0, 0, 0.2));
  -moz-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: var(--drawer-shadow, 0 8px 16px 0 rgba(0, 0, 0, 0.2));
  box-shadow: var(--drawer-shadow, 0 8px 16px 0 rgba(0, 0, 0, 0.2));
  overflow: auto;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.next-drawer,
.next-drawer *,
.next-drawer :after,
.next-drawer :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-drawer-left,
.next-drawer-right {
  height: 100%;
  max-width: 80%;
  width: 240px;
}

.next-drawer-bottom,
.next-drawer-top {
  width: 100%;
}

.next-drawer-header {
  padding: 16px;
  padding: var(--drawer-title-padding-top, 16px)
    var(--drawer-title-padding-left-right, 16px)
    var(--drawer-title-padding-bottom, 16px)
    var(--drawer-title-padding-left-right, 16px);
  border-bottom: 0 solid transparent;
  border-bottom: var(--drawer-title-border-width, 0) var(--line-solid, solid)
    var(--drawer-title-border-color, transparent);
  font-size: 14px;
  font-size: var(--drawer-title-font-size, 14px);
  background: #fff;
  background: var(--drawer-title-bg-color, #fff);
  color: #333;
  color: var(--drawer-title-color, #333);
}

.next-drawer-no-title {
  padding: 0;
  border-bottom: 0;
}

.next-drawer-body {
  padding: 0 16px 8px;
  padding: var(--drawer-content-padding-top, 0)
    var(--drawer-content-padding-left-right, 16px)
    var(--drawer-content-padding-bottom, 8px)
    var(--drawer-content-padding-left-right, 16px);
  font-size: 12px;
  font-size: var(--drawer-content-font-size, 12px);
  color: #333;
  color: var(--drawer-content-color, #333);
}

.next-drawer-close {
  position: absolute;
  top: 16px;
  top: var(--drawer-close-top, 16px);
  right: 16px;
  right: var(--drawer-close-right, 16px);
  width: 16px;
  cursor: pointer;
}

.next-drawer-close,
.next-drawer-close:link,
.next-drawer-close:visited {
  height: 16px;
  color: #666;
  color: var(--drawer-close-color, #666);
}

.next-drawer-close:hover {
  background: transparent;
  background: var(--drawer-close-bg-hovered, transparent);
  color: #333;
  color: var(--drawer-close-color-hovered, #333);
}

.next-drawer-close .next-drawer-close-icon.next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-top: calc(0px - var(--drawer-close-size, 16px) / 2);
  margin-left: -8px;
  margin-left: calc(0px - var(--drawer-close-size, 16px) / 2);
  width: 16px;
  width: var(--drawer-close-size, 16px);
  height: 16px;
  height: var(--drawer-close-size, 16px);
  line-height: 1em;
}

.next-drawer-close .next-drawer-close-icon.next-icon:before {
  width: 16px;
  width: var(--drawer-close-size, 16px);
  height: 16px;
  height: var(--drawer-close-size, 16px);
  font-size: 16px;
  font-size: var(--drawer-close-size, 16px);
  line-height: 1em;
}

.next-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.next-row,
.next-row *,
.next-row :after,
.next-row :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-row.next-row-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 320px) {
  .next-row.next-row-fixed {
    width: 320px;
  }
}

@media (min-width: 480px) {
  .next-row.next-row-fixed {
    width: 480px;
  }
}

@media (min-width: 720px) {
  .next-row.next-row-fixed {
    width: 720px;
  }
}

@media (min-width: 990px) {
  .next-row.next-row-fixed {
    width: 990px;
  }
}

@media (min-width: 1200px) {
  .next-row.next-row-fixed {
    width: 1200px;
  }
}

@media (min-width: 1500px) {
  .next-row.next-row-fixed {
    width: 1500px;
  }
}

.next-row.next-row-fixed-xxs {
  width: 320px;
}

.next-row.next-row-fixed-xs {
  width: 480px;
}

.next-row.next-row-fixed-s {
  width: 720px;
}

.next-row.next-row-fixed-m {
  width: 990px;
}

.next-row.next-row-fixed-l {
  width: 1200px;
}

.next-row.next-row-fixed-xl {
  width: 1500px;
}

.next-row.next-row-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  justify-content: flex-start;
}

.next-row.next-row-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  justify-content: flex-end;
}

.next-row.next-row-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.next-row.next-row-justify-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
}

.next-row.next-row-justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.next-row.next-row-align-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  align-items: flex-start;
}

.next-row.next-row-align-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  align-items: flex-end;
}

.next-row.next-row-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
}

.next-row.next-row-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  -moz-box-align: baseline;
  align-items: baseline;
}

.next-row.next-row-align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  align-items: stretch;
}

.next-col {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  flex: 1;
}

.next-col.next-col-top {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.next-col.next-col-bottom {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
}

.next-col.next-col-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
}

@media (min-width: 0\0) and (min-resolution: 0.001dpcm) {
  .next-row {
    display: table;
    width: 100%;
  }

  .next-col {
    display: table-cell;
    vertical-align: top;
  }
}

.next-col-1 {
  -ms-flex: 0 0 4.16667%;
  -webkit-flex: 0 0 4.16667%;
  flex: 0 0 4.16667%;
  width: 4.16667%;
  max-width: 4.16667%;
}

.next-col-1,
.next-col-2 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-2 {
  -ms-flex: 0 0 8.33333%;
  -webkit-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  width: 8.33333%;
  max-width: 8.33333%;
}

.next-col-3 {
  -ms-flex: 0 0 12.5%;
  -webkit-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  width: 12.5%;
  max-width: 12.5%;
}

.next-col-3,
.next-col-4 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-4 {
  -ms-flex: 0 0 16.66667%;
  -webkit-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  width: 16.66667%;
  max-width: 16.66667%;
}

.next-col-5 {
  -ms-flex: 0 0 20.83333%;
  -webkit-flex: 0 0 20.83333%;
  flex: 0 0 20.83333%;
  width: 20.83333%;
  max-width: 20.83333%;
}

.next-col-5,
.next-col-6 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-6 {
  -ms-flex: 0 0 25%;
  -webkit-flex: 0 0 25%;
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
}

.next-col-7 {
  -ms-flex: 0 0 29.16667%;
  -webkit-flex: 0 0 29.16667%;
  flex: 0 0 29.16667%;
  width: 29.16667%;
  max-width: 29.16667%;
}

.next-col-7,
.next-col-8 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-8 {
  -ms-flex: 0 0 33.33333%;
  -webkit-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  width: 33.33333%;
  max-width: 33.33333%;
}

.next-col-9 {
  -ms-flex: 0 0 37.5%;
  -webkit-flex: 0 0 37.5%;
  flex: 0 0 37.5%;
  width: 37.5%;
  max-width: 37.5%;
}

.next-col-9,
.next-col-10 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-10 {
  -ms-flex: 0 0 41.66667%;
  -webkit-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  width: 41.66667%;
  max-width: 41.66667%;
}

.next-col-11 {
  -ms-flex: 0 0 45.83333%;
  -webkit-flex: 0 0 45.83333%;
  flex: 0 0 45.83333%;
  width: 45.83333%;
  max-width: 45.83333%;
}

.next-col-11,
.next-col-12 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-12 {
  -ms-flex: 0 0 50%;
  -webkit-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
}

.next-col-13 {
  -ms-flex: 0 0 54.16667%;
  -webkit-flex: 0 0 54.16667%;
  flex: 0 0 54.16667%;
  width: 54.16667%;
  max-width: 54.16667%;
}

.next-col-13,
.next-col-14 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-14 {
  -ms-flex: 0 0 58.33333%;
  -webkit-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  width: 58.33333%;
  max-width: 58.33333%;
}

.next-col-15 {
  -ms-flex: 0 0 62.5%;
  -webkit-flex: 0 0 62.5%;
  flex: 0 0 62.5%;
  width: 62.5%;
  max-width: 62.5%;
}

.next-col-15,
.next-col-16 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-16 {
  -ms-flex: 0 0 66.66667%;
  -webkit-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  width: 66.66667%;
  max-width: 66.66667%;
}

.next-col-17 {
  -ms-flex: 0 0 70.83333%;
  -webkit-flex: 0 0 70.83333%;
  flex: 0 0 70.83333%;
  width: 70.83333%;
  max-width: 70.83333%;
}

.next-col-17,
.next-col-18 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-18 {
  -ms-flex: 0 0 75%;
  -webkit-flex: 0 0 75%;
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
}

.next-col-19 {
  -ms-flex: 0 0 79.16667%;
  -webkit-flex: 0 0 79.16667%;
  flex: 0 0 79.16667%;
  width: 79.16667%;
  max-width: 79.16667%;
}

.next-col-19,
.next-col-20 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-20 {
  -ms-flex: 0 0 83.33333%;
  -webkit-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  width: 83.33333%;
  max-width: 83.33333%;
}

.next-col-21 {
  -ms-flex: 0 0 87.5%;
  -webkit-flex: 0 0 87.5%;
  flex: 0 0 87.5%;
  width: 87.5%;
  max-width: 87.5%;
}

.next-col-21,
.next-col-22 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-22 {
  -ms-flex: 0 0 91.66667%;
  -webkit-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  width: 91.66667%;
  max-width: 91.66667%;
}

.next-col-23 {
  -ms-flex: 0 0 95.83333%;
  -webkit-flex: 0 0 95.83333%;
  flex: 0 0 95.83333%;
  width: 95.83333%;
  max-width: 95.83333%;
}

.next-col-23,
.next-col-24 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-24 {
  -ms-flex: 0 0 100%;
  -webkit-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 320px) {
  .next-col-xxs-1 {
    -ms-flex: 0 0 4.16667%;
    -webkit-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    width: 4.16667%;
    max-width: 4.16667%;
  }

  .next-col-xxs-1,
  .next-col-xxs-2 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-2 {
    -ms-flex: 0 0 8.33333%;
    -webkit-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }

  .next-col-xxs-3 {
    -ms-flex: 0 0 12.5%;
    -webkit-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
  }

  .next-col-xxs-3,
  .next-col-xxs-4 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-4 {
    -ms-flex: 0 0 16.66667%;
    -webkit-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }

  .next-col-xxs-5 {
    -ms-flex: 0 0 20.83333%;
    -webkit-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    width: 20.83333%;
    max-width: 20.83333%;
  }

  .next-col-xxs-5,
  .next-col-xxs-6 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-6 {
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  .next-col-xxs-7 {
    -ms-flex: 0 0 29.16667%;
    -webkit-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    width: 29.16667%;
    max-width: 29.16667%;
  }

  .next-col-xxs-7,
  .next-col-xxs-8 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-8 {
    -ms-flex: 0 0 33.33333%;
    -webkit-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }

  .next-col-xxs-9 {
    -ms-flex: 0 0 37.5%;
    -webkit-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    width: 37.5%;
    max-width: 37.5%;
  }

  .next-col-xxs-9,
  .next-col-xxs-10 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-10 {
    -ms-flex: 0 0 41.66667%;
    -webkit-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }

  .next-col-xxs-11 {
    -ms-flex: 0 0 45.83333%;
    -webkit-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    width: 45.83333%;
    max-width: 45.83333%;
  }

  .next-col-xxs-11,
  .next-col-xxs-12 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-12 {
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  .next-col-xxs-13 {
    -ms-flex: 0 0 54.16667%;
    -webkit-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    width: 54.16667%;
    max-width: 54.16667%;
  }

  .next-col-xxs-13,
  .next-col-xxs-14 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-14 {
    -ms-flex: 0 0 58.33333%;
    -webkit-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }

  .next-col-xxs-15 {
    -ms-flex: 0 0 62.5%;
    -webkit-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    width: 62.5%;
    max-width: 62.5%;
  }

  .next-col-xxs-15,
  .next-col-xxs-16 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-16 {
    -ms-flex: 0 0 66.66667%;
    -webkit-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }

  .next-col-xxs-17 {
    -ms-flex: 0 0 70.83333%;
    -webkit-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    width: 70.83333%;
    max-width: 70.83333%;
  }

  .next-col-xxs-17,
  .next-col-xxs-18 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-18 {
    -ms-flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }

  .next-col-xxs-19 {
    -ms-flex: 0 0 79.16667%;
    -webkit-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    width: 79.16667%;
    max-width: 79.16667%;
  }

  .next-col-xxs-19,
  .next-col-xxs-20 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-20 {
    -ms-flex: 0 0 83.33333%;
    -webkit-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }

  .next-col-xxs-21 {
    -ms-flex: 0 0 87.5%;
    -webkit-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    width: 87.5%;
    max-width: 87.5%;
  }

  .next-col-xxs-21,
  .next-col-xxs-22 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-22 {
    -ms-flex: 0 0 91.66667%;
    -webkit-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }

  .next-col-xxs-23 {
    -ms-flex: 0 0 95.83333%;
    -webkit-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    width: 95.83333%;
    max-width: 95.83333%;
  }

  .next-col-xxs-23,
  .next-col-xxs-24 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xxs-24 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 480px) {
  .next-col-xs-1 {
    -ms-flex: 0 0 4.16667%;
    -webkit-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    width: 4.16667%;
    max-width: 4.16667%;
  }

  .next-col-xs-1,
  .next-col-xs-2 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-2 {
    -ms-flex: 0 0 8.33333%;
    -webkit-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }

  .next-col-xs-3 {
    -ms-flex: 0 0 12.5%;
    -webkit-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
  }

  .next-col-xs-3,
  .next-col-xs-4 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-4 {
    -ms-flex: 0 0 16.66667%;
    -webkit-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }

  .next-col-xs-5 {
    -ms-flex: 0 0 20.83333%;
    -webkit-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    width: 20.83333%;
    max-width: 20.83333%;
  }

  .next-col-xs-5,
  .next-col-xs-6 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-6 {
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  .next-col-xs-7 {
    -ms-flex: 0 0 29.16667%;
    -webkit-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    width: 29.16667%;
    max-width: 29.16667%;
  }

  .next-col-xs-7,
  .next-col-xs-8 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-8 {
    -ms-flex: 0 0 33.33333%;
    -webkit-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }

  .next-col-xs-9 {
    -ms-flex: 0 0 37.5%;
    -webkit-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    width: 37.5%;
    max-width: 37.5%;
  }

  .next-col-xs-9,
  .next-col-xs-10 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-10 {
    -ms-flex: 0 0 41.66667%;
    -webkit-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }

  .next-col-xs-11 {
    -ms-flex: 0 0 45.83333%;
    -webkit-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    width: 45.83333%;
    max-width: 45.83333%;
  }

  .next-col-xs-11,
  .next-col-xs-12 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-12 {
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  .next-col-xs-13 {
    -ms-flex: 0 0 54.16667%;
    -webkit-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    width: 54.16667%;
    max-width: 54.16667%;
  }

  .next-col-xs-13,
  .next-col-xs-14 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-14 {
    -ms-flex: 0 0 58.33333%;
    -webkit-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }

  .next-col-xs-15 {
    -ms-flex: 0 0 62.5%;
    -webkit-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    width: 62.5%;
    max-width: 62.5%;
  }

  .next-col-xs-15,
  .next-col-xs-16 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-16 {
    -ms-flex: 0 0 66.66667%;
    -webkit-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }

  .next-col-xs-17 {
    -ms-flex: 0 0 70.83333%;
    -webkit-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    width: 70.83333%;
    max-width: 70.83333%;
  }

  .next-col-xs-17,
  .next-col-xs-18 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-18 {
    -ms-flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }

  .next-col-xs-19 {
    -ms-flex: 0 0 79.16667%;
    -webkit-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    width: 79.16667%;
    max-width: 79.16667%;
  }

  .next-col-xs-19,
  .next-col-xs-20 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-20 {
    -ms-flex: 0 0 83.33333%;
    -webkit-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }

  .next-col-xs-21 {
    -ms-flex: 0 0 87.5%;
    -webkit-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    width: 87.5%;
    max-width: 87.5%;
  }

  .next-col-xs-21,
  .next-col-xs-22 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-22 {
    -ms-flex: 0 0 91.66667%;
    -webkit-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }

  .next-col-xs-23 {
    -ms-flex: 0 0 95.83333%;
    -webkit-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    width: 95.83333%;
    max-width: 95.83333%;
  }

  .next-col-xs-23,
  .next-col-xs-24 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-24 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 720px) {
  .next-col-s-1 {
    -ms-flex: 0 0 4.16667%;
    -webkit-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    width: 4.16667%;
    max-width: 4.16667%;
  }

  .next-col-s-1,
  .next-col-s-2 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-2 {
    -ms-flex: 0 0 8.33333%;
    -webkit-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }

  .next-col-s-3 {
    -ms-flex: 0 0 12.5%;
    -webkit-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
  }

  .next-col-s-3,
  .next-col-s-4 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-4 {
    -ms-flex: 0 0 16.66667%;
    -webkit-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }

  .next-col-s-5 {
    -ms-flex: 0 0 20.83333%;
    -webkit-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    width: 20.83333%;
    max-width: 20.83333%;
  }

  .next-col-s-5,
  .next-col-s-6 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-6 {
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  .next-col-s-7 {
    -ms-flex: 0 0 29.16667%;
    -webkit-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    width: 29.16667%;
    max-width: 29.16667%;
  }

  .next-col-s-7,
  .next-col-s-8 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-8 {
    -ms-flex: 0 0 33.33333%;
    -webkit-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }

  .next-col-s-9 {
    -ms-flex: 0 0 37.5%;
    -webkit-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    width: 37.5%;
    max-width: 37.5%;
  }

  .next-col-s-9,
  .next-col-s-10 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-10 {
    -ms-flex: 0 0 41.66667%;
    -webkit-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }

  .next-col-s-11 {
    -ms-flex: 0 0 45.83333%;
    -webkit-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    width: 45.83333%;
    max-width: 45.83333%;
  }

  .next-col-s-11,
  .next-col-s-12 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-12 {
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  .next-col-s-13 {
    -ms-flex: 0 0 54.16667%;
    -webkit-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    width: 54.16667%;
    max-width: 54.16667%;
  }

  .next-col-s-13,
  .next-col-s-14 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-14 {
    -ms-flex: 0 0 58.33333%;
    -webkit-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }

  .next-col-s-15 {
    -ms-flex: 0 0 62.5%;
    -webkit-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    width: 62.5%;
    max-width: 62.5%;
  }

  .next-col-s-15,
  .next-col-s-16 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-16 {
    -ms-flex: 0 0 66.66667%;
    -webkit-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }

  .next-col-s-17 {
    -ms-flex: 0 0 70.83333%;
    -webkit-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    width: 70.83333%;
    max-width: 70.83333%;
  }

  .next-col-s-17,
  .next-col-s-18 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-18 {
    -ms-flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }

  .next-col-s-19 {
    -ms-flex: 0 0 79.16667%;
    -webkit-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    width: 79.16667%;
    max-width: 79.16667%;
  }

  .next-col-s-19,
  .next-col-s-20 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-20 {
    -ms-flex: 0 0 83.33333%;
    -webkit-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }

  .next-col-s-21 {
    -ms-flex: 0 0 87.5%;
    -webkit-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    width: 87.5%;
    max-width: 87.5%;
  }

  .next-col-s-21,
  .next-col-s-22 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-22 {
    -ms-flex: 0 0 91.66667%;
    -webkit-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }

  .next-col-s-23 {
    -ms-flex: 0 0 95.83333%;
    -webkit-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    width: 95.83333%;
    max-width: 95.83333%;
  }

  .next-col-s-23,
  .next-col-s-24 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-24 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 990px) {
  .next-col-m-1 {
    -ms-flex: 0 0 4.16667%;
    -webkit-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    width: 4.16667%;
    max-width: 4.16667%;
  }

  .next-col-m-1,
  .next-col-m-2 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-2 {
    -ms-flex: 0 0 8.33333%;
    -webkit-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }

  .next-col-m-3 {
    -ms-flex: 0 0 12.5%;
    -webkit-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
  }

  .next-col-m-3,
  .next-col-m-4 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-4 {
    -ms-flex: 0 0 16.66667%;
    -webkit-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }

  .next-col-m-5 {
    -ms-flex: 0 0 20.83333%;
    -webkit-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    width: 20.83333%;
    max-width: 20.83333%;
  }

  .next-col-m-5,
  .next-col-m-6 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-6 {
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  .next-col-m-7 {
    -ms-flex: 0 0 29.16667%;
    -webkit-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    width: 29.16667%;
    max-width: 29.16667%;
  }

  .next-col-m-7,
  .next-col-m-8 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-8 {
    -ms-flex: 0 0 33.33333%;
    -webkit-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }

  .next-col-m-9 {
    -ms-flex: 0 0 37.5%;
    -webkit-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    width: 37.5%;
    max-width: 37.5%;
  }

  .next-col-m-9,
  .next-col-m-10 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-10 {
    -ms-flex: 0 0 41.66667%;
    -webkit-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }

  .next-col-m-11 {
    -ms-flex: 0 0 45.83333%;
    -webkit-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    width: 45.83333%;
    max-width: 45.83333%;
  }

  .next-col-m-11,
  .next-col-m-12 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-12 {
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  .next-col-m-13 {
    -ms-flex: 0 0 54.16667%;
    -webkit-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    width: 54.16667%;
    max-width: 54.16667%;
  }

  .next-col-m-13,
  .next-col-m-14 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-14 {
    -ms-flex: 0 0 58.33333%;
    -webkit-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }

  .next-col-m-15 {
    -ms-flex: 0 0 62.5%;
    -webkit-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    width: 62.5%;
    max-width: 62.5%;
  }

  .next-col-m-15,
  .next-col-m-16 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-16 {
    -ms-flex: 0 0 66.66667%;
    -webkit-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }

  .next-col-m-17 {
    -ms-flex: 0 0 70.83333%;
    -webkit-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    width: 70.83333%;
    max-width: 70.83333%;
  }

  .next-col-m-17,
  .next-col-m-18 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-18 {
    -ms-flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }

  .next-col-m-19 {
    -ms-flex: 0 0 79.16667%;
    -webkit-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    width: 79.16667%;
    max-width: 79.16667%;
  }

  .next-col-m-19,
  .next-col-m-20 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-20 {
    -ms-flex: 0 0 83.33333%;
    -webkit-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }

  .next-col-m-21 {
    -ms-flex: 0 0 87.5%;
    -webkit-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    width: 87.5%;
    max-width: 87.5%;
  }

  .next-col-m-21,
  .next-col-m-22 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-22 {
    -ms-flex: 0 0 91.66667%;
    -webkit-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }

  .next-col-m-23 {
    -ms-flex: 0 0 95.83333%;
    -webkit-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    width: 95.83333%;
    max-width: 95.83333%;
  }

  .next-col-m-23,
  .next-col-m-24 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-24 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .next-col-l-1 {
    -ms-flex: 0 0 4.16667%;
    -webkit-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    width: 4.16667%;
    max-width: 4.16667%;
  }

  .next-col-l-1,
  .next-col-l-2 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-2 {
    -ms-flex: 0 0 8.33333%;
    -webkit-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }

  .next-col-l-3 {
    -ms-flex: 0 0 12.5%;
    -webkit-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
  }

  .next-col-l-3,
  .next-col-l-4 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-4 {
    -ms-flex: 0 0 16.66667%;
    -webkit-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }

  .next-col-l-5 {
    -ms-flex: 0 0 20.83333%;
    -webkit-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    width: 20.83333%;
    max-width: 20.83333%;
  }

  .next-col-l-5,
  .next-col-l-6 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-6 {
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  .next-col-l-7 {
    -ms-flex: 0 0 29.16667%;
    -webkit-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    width: 29.16667%;
    max-width: 29.16667%;
  }

  .next-col-l-7,
  .next-col-l-8 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-8 {
    -ms-flex: 0 0 33.33333%;
    -webkit-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }

  .next-col-l-9 {
    -ms-flex: 0 0 37.5%;
    -webkit-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    width: 37.5%;
    max-width: 37.5%;
  }

  .next-col-l-9,
  .next-col-l-10 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-10 {
    -ms-flex: 0 0 41.66667%;
    -webkit-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }

  .next-col-l-11 {
    -ms-flex: 0 0 45.83333%;
    -webkit-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    width: 45.83333%;
    max-width: 45.83333%;
  }

  .next-col-l-11,
  .next-col-l-12 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-12 {
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  .next-col-l-13 {
    -ms-flex: 0 0 54.16667%;
    -webkit-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    width: 54.16667%;
    max-width: 54.16667%;
  }

  .next-col-l-13,
  .next-col-l-14 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-14 {
    -ms-flex: 0 0 58.33333%;
    -webkit-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }

  .next-col-l-15 {
    -ms-flex: 0 0 62.5%;
    -webkit-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    width: 62.5%;
    max-width: 62.5%;
  }

  .next-col-l-15,
  .next-col-l-16 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-16 {
    -ms-flex: 0 0 66.66667%;
    -webkit-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }

  .next-col-l-17 {
    -ms-flex: 0 0 70.83333%;
    -webkit-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    width: 70.83333%;
    max-width: 70.83333%;
  }

  .next-col-l-17,
  .next-col-l-18 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-18 {
    -ms-flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }

  .next-col-l-19 {
    -ms-flex: 0 0 79.16667%;
    -webkit-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    width: 79.16667%;
    max-width: 79.16667%;
  }

  .next-col-l-19,
  .next-col-l-20 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-20 {
    -ms-flex: 0 0 83.33333%;
    -webkit-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }

  .next-col-l-21 {
    -ms-flex: 0 0 87.5%;
    -webkit-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    width: 87.5%;
    max-width: 87.5%;
  }

  .next-col-l-21,
  .next-col-l-22 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-22 {
    -ms-flex: 0 0 91.66667%;
    -webkit-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }

  .next-col-l-23 {
    -ms-flex: 0 0 95.83333%;
    -webkit-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    width: 95.83333%;
    max-width: 95.83333%;
  }

  .next-col-l-23,
  .next-col-l-24 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-24 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1500px) {
  .next-col-xl-1 {
    -ms-flex: 0 0 4.16667%;
    -webkit-flex: 0 0 4.16667%;
    flex: 0 0 4.16667%;
    width: 4.16667%;
    max-width: 4.16667%;
  }

  .next-col-xl-1,
  .next-col-xl-2 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-2 {
    -ms-flex: 0 0 8.33333%;
    -webkit-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }

  .next-col-xl-3 {
    -ms-flex: 0 0 12.5%;
    -webkit-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
  }

  .next-col-xl-3,
  .next-col-xl-4 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-4 {
    -ms-flex: 0 0 16.66667%;
    -webkit-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }

  .next-col-xl-5 {
    -ms-flex: 0 0 20.83333%;
    -webkit-flex: 0 0 20.83333%;
    flex: 0 0 20.83333%;
    width: 20.83333%;
    max-width: 20.83333%;
  }

  .next-col-xl-5,
  .next-col-xl-6 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-6 {
    -ms-flex: 0 0 25%;
    -webkit-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  .next-col-xl-7 {
    -ms-flex: 0 0 29.16667%;
    -webkit-flex: 0 0 29.16667%;
    flex: 0 0 29.16667%;
    width: 29.16667%;
    max-width: 29.16667%;
  }

  .next-col-xl-7,
  .next-col-xl-8 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-8 {
    -ms-flex: 0 0 33.33333%;
    -webkit-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }

  .next-col-xl-9 {
    -ms-flex: 0 0 37.5%;
    -webkit-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    width: 37.5%;
    max-width: 37.5%;
  }

  .next-col-xl-9,
  .next-col-xl-10 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-10 {
    -ms-flex: 0 0 41.66667%;
    -webkit-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }

  .next-col-xl-11 {
    -ms-flex: 0 0 45.83333%;
    -webkit-flex: 0 0 45.83333%;
    flex: 0 0 45.83333%;
    width: 45.83333%;
    max-width: 45.83333%;
  }

  .next-col-xl-11,
  .next-col-xl-12 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-12 {
    -ms-flex: 0 0 50%;
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  .next-col-xl-13 {
    -ms-flex: 0 0 54.16667%;
    -webkit-flex: 0 0 54.16667%;
    flex: 0 0 54.16667%;
    width: 54.16667%;
    max-width: 54.16667%;
  }

  .next-col-xl-13,
  .next-col-xl-14 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-14 {
    -ms-flex: 0 0 58.33333%;
    -webkit-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }

  .next-col-xl-15 {
    -ms-flex: 0 0 62.5%;
    -webkit-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    width: 62.5%;
    max-width: 62.5%;
  }

  .next-col-xl-15,
  .next-col-xl-16 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-16 {
    -ms-flex: 0 0 66.66667%;
    -webkit-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }

  .next-col-xl-17 {
    -ms-flex: 0 0 70.83333%;
    -webkit-flex: 0 0 70.83333%;
    flex: 0 0 70.83333%;
    width: 70.83333%;
    max-width: 70.83333%;
  }

  .next-col-xl-17,
  .next-col-xl-18 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-18 {
    -ms-flex: 0 0 75%;
    -webkit-flex: 0 0 75%;
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }

  .next-col-xl-19 {
    -ms-flex: 0 0 79.16667%;
    -webkit-flex: 0 0 79.16667%;
    flex: 0 0 79.16667%;
    width: 79.16667%;
    max-width: 79.16667%;
  }

  .next-col-xl-19,
  .next-col-xl-20 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-20 {
    -ms-flex: 0 0 83.33333%;
    -webkit-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }

  .next-col-xl-21 {
    -ms-flex: 0 0 87.5%;
    -webkit-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    width: 87.5%;
    max-width: 87.5%;
  }

  .next-col-xl-21,
  .next-col-xl-22 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-22 {
    -ms-flex: 0 0 91.66667%;
    -webkit-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }

  .next-col-xl-23 {
    -ms-flex: 0 0 95.83333%;
    -webkit-flex: 0 0 95.83333%;
    flex: 0 0 95.83333%;
    width: 95.83333%;
    max-width: 95.83333%;
  }

  .next-col-xl-23,
  .next-col-xl-24 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-24 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

.next-col-1p5 {
  -ms-flex: 0 0 20%;
  -webkit-flex: 0 0 20%;
  flex: 0 0 20%;
  width: 20%;
  max-width: 20%;
}

.next-col-1p5,
.next-col-2p5 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-2p5 {
  -ms-flex: 0 0 40%;
  -webkit-flex: 0 0 40%;
  flex: 0 0 40%;
  width: 40%;
  max-width: 40%;
}

.next-col-3p5 {
  -ms-flex: 0 0 60%;
  -webkit-flex: 0 0 60%;
  flex: 0 0 60%;
  width: 60%;
  max-width: 60%;
}

.next-col-3p5,
.next-col-4p5 {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
}

.next-col-4p5 {
  -ms-flex: 0 0 80%;
  -webkit-flex: 0 0 80%;
  flex: 0 0 80%;
  width: 80%;
  max-width: 80%;
}

.next-col-5p5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 320px) {
  .next-col-xxs-1p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    -moz-box-flex: 0;
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
  }

  .next-col-xxs-2p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    -webkit-flex: 0 0 40%;
    -moz-box-flex: 0;
    flex: 0 0 40%;
    width: 40%;
    max-width: 40%;
  }

  .next-col-xxs-3p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    -webkit-flex: 0 0 60%;
    -moz-box-flex: 0;
    flex: 0 0 60%;
    width: 60%;
    max-width: 60%;
  }

  .next-col-xxs-4p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    -webkit-flex: 0 0 80%;
    -moz-box-flex: 0;
    flex: 0 0 80%;
    width: 80%;
    max-width: 80%;
  }

  .next-col-xxs-5p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 480px) {
  .next-col-xs-1p5 {
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
  }

  .next-col-xs-1p5,
  .next-col-xs-2p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-2p5 {
    -ms-flex: 0 0 40%;
    -webkit-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 40%;
    max-width: 40%;
  }

  .next-col-xs-3p5 {
    -ms-flex: 0 0 60%;
    -webkit-flex: 0 0 60%;
    flex: 0 0 60%;
    width: 60%;
    max-width: 60%;
  }

  .next-col-xs-3p5,
  .next-col-xs-4p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xs-4p5 {
    -ms-flex: 0 0 80%;
    -webkit-flex: 0 0 80%;
    flex: 0 0 80%;
    width: 80%;
    max-width: 80%;
  }

  .next-col-xs-5p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 720px) {
  .next-col-s-1p5 {
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
  }

  .next-col-s-1p5,
  .next-col-s-2p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-2p5 {
    -ms-flex: 0 0 40%;
    -webkit-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 40%;
    max-width: 40%;
  }

  .next-col-s-3p5 {
    -ms-flex: 0 0 60%;
    -webkit-flex: 0 0 60%;
    flex: 0 0 60%;
    width: 60%;
    max-width: 60%;
  }

  .next-col-s-3p5,
  .next-col-s-4p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-s-4p5 {
    -ms-flex: 0 0 80%;
    -webkit-flex: 0 0 80%;
    flex: 0 0 80%;
    width: 80%;
    max-width: 80%;
  }

  .next-col-s-5p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 990px) {
  .next-col-m-1p5 {
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
  }

  .next-col-m-1p5,
  .next-col-m-2p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-2p5 {
    -ms-flex: 0 0 40%;
    -webkit-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 40%;
    max-width: 40%;
  }

  .next-col-m-3p5 {
    -ms-flex: 0 0 60%;
    -webkit-flex: 0 0 60%;
    flex: 0 0 60%;
    width: 60%;
    max-width: 60%;
  }

  .next-col-m-3p5,
  .next-col-m-4p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-m-4p5 {
    -ms-flex: 0 0 80%;
    -webkit-flex: 0 0 80%;
    flex: 0 0 80%;
    width: 80%;
    max-width: 80%;
  }

  .next-col-m-5p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .next-col-l-1p5 {
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
  }

  .next-col-l-1p5,
  .next-col-l-2p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-2p5 {
    -ms-flex: 0 0 40%;
    -webkit-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 40%;
    max-width: 40%;
  }

  .next-col-l-3p5 {
    -ms-flex: 0 0 60%;
    -webkit-flex: 0 0 60%;
    flex: 0 0 60%;
    width: 60%;
    max-width: 60%;
  }

  .next-col-l-3p5,
  .next-col-l-4p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-l-4p5 {
    -ms-flex: 0 0 80%;
    -webkit-flex: 0 0 80%;
    flex: 0 0 80%;
    width: 80%;
    max-width: 80%;
  }

  .next-col-l-5p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1500px) {
  .next-col-xl-1p5 {
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
  }

  .next-col-xl-1p5,
  .next-col-xl-2p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-2p5 {
    -ms-flex: 0 0 40%;
    -webkit-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 40%;
    max-width: 40%;
  }

  .next-col-xl-3p5 {
    -ms-flex: 0 0 60%;
    -webkit-flex: 0 0 60%;
    flex: 0 0 60%;
    width: 60%;
    max-width: 60%;
  }

  .next-col-xl-3p5,
  .next-col-xl-4p5 {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
  }

  .next-col-xl-4p5 {
    -ms-flex: 0 0 80%;
    -webkit-flex: 0 0 80%;
    flex: 0 0 80%;
    width: 80%;
    max-width: 80%;
  }

  .next-col-xl-5p5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

.next-col-fixed-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  -webkit-flex: 0 0 20px;
  -moz-box-flex: 0;
  flex: 0 0 20px;
  width: 20px;
  max-width: 20px;
}

.next-col-fixed-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40px;
  -webkit-flex: 0 0 40px;
  -moz-box-flex: 0;
  flex: 0 0 40px;
  width: 40px;
  max-width: 40px;
}

.next-col-fixed-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60px;
  -webkit-flex: 0 0 60px;
  -moz-box-flex: 0;
  flex: 0 0 60px;
  width: 60px;
  max-width: 60px;
}

.next-col-fixed-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  -webkit-flex: 0 0 80px;
  -moz-box-flex: 0;
  flex: 0 0 80px;
  width: 80px;
  max-width: 80px;
}

.next-col-fixed-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100px;
  -webkit-flex: 0 0 100px;
  -moz-box-flex: 0;
  flex: 0 0 100px;
  width: 100px;
  max-width: 100px;
}

.next-col-fixed-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 120px;
  -webkit-flex: 0 0 120px;
  -moz-box-flex: 0;
  flex: 0 0 120px;
  width: 120px;
  max-width: 120px;
}

.next-col-fixed-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 140px;
  -webkit-flex: 0 0 140px;
  -moz-box-flex: 0;
  flex: 0 0 140px;
  width: 140px;
  max-width: 140px;
}

.next-col-fixed-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 160px;
  -webkit-flex: 0 0 160px;
  -moz-box-flex: 0;
  flex: 0 0 160px;
  width: 160px;
  max-width: 160px;
}

.next-col-fixed-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 180px;
  -webkit-flex: 0 0 180px;
  -moz-box-flex: 0;
  flex: 0 0 180px;
  width: 180px;
  max-width: 180px;
}

.next-col-fixed-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 200px;
  -webkit-flex: 0 0 200px;
  -moz-box-flex: 0;
  flex: 0 0 200px;
  width: 200px;
  max-width: 200px;
}

.next-col-fixed-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 220px;
  -webkit-flex: 0 0 220px;
  -moz-box-flex: 0;
  flex: 0 0 220px;
  width: 220px;
  max-width: 220px;
}

.next-col-fixed-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 240px;
  -webkit-flex: 0 0 240px;
  -moz-box-flex: 0;
  flex: 0 0 240px;
  width: 240px;
  max-width: 240px;
}

.next-col-fixed-13 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 260px;
  -webkit-flex: 0 0 260px;
  -moz-box-flex: 0;
  flex: 0 0 260px;
  width: 260px;
  max-width: 260px;
}

.next-col-fixed-14 {
  /* -webkit-box-flex: 0;
  -ms-flex: 0 0 280px;
  -webkit-flex: 0 0 280px;
  -moz-box-flex: 0; */
  /* flex: 0 0 280px;
  width: 280px;
  max-width: 280px; */
}

.next-col-fixed-15 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 300px;
  -webkit-flex: 0 0 300px;
  -moz-box-flex: 0;
  flex: 0 0 300px;
  width: 300px;
  max-width: 300px;
}

.next-col-fixed-16 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 320px;
  -webkit-flex: 0 0 320px;
  -moz-box-flex: 0;
  flex: 0 0 320px;
  width: 320px;
  max-width: 320px;
}

.next-col-fixed-17 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 340px;
  -webkit-flex: 0 0 340px;
  -moz-box-flex: 0;
  flex: 0 0 340px;
  width: 340px;
  max-width: 340px;
}

.next-col-fixed-18 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 360px;
  -webkit-flex: 0 0 360px;
  -moz-box-flex: 0;
  flex: 0 0 360px;
  width: 360px;
  max-width: 360px;
}

.next-col-fixed-19 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 380px;
  -webkit-flex: 0 0 380px;
  -moz-box-flex: 0;
  flex: 0 0 380px;
  width: 380px;
  max-width: 380px;
}

.next-col-fixed-20 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 400px;
  -webkit-flex: 0 0 400px;
  -moz-box-flex: 0;
  flex: 0 0 400px;
  width: 400px;
  max-width: 400px;
}

.next-col-fixed-21 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 420px;
  -webkit-flex: 0 0 420px;
  -moz-box-flex: 0;
  flex: 0 0 420px;
  width: 420px;
  max-width: 420px;
}

.next-col-fixed-22 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 440px;
  -webkit-flex: 0 0 440px;
  -moz-box-flex: 0;
  flex: 0 0 440px;
  width: 440px;
  max-width: 440px;
}

.next-col-fixed-23 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 460px;
  -webkit-flex: 0 0 460px;
  -moz-box-flex: 0;
  flex: 0 0 460px;
  width: 460px;
  max-width: 460px;
}

.next-col-fixed-24 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 480px;
  -webkit-flex: 0 0 480px;
  -moz-box-flex: 0;
  flex: 0 0 480px;
  width: 480px;
  max-width: 480px;
}

.next-col-fixed-25 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 500px;
  -webkit-flex: 0 0 500px;
  -moz-box-flex: 0;
  flex: 0 0 500px;
  width: 500px;
  max-width: 500px;
}

.next-col-fixed-26 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 520px;
  -webkit-flex: 0 0 520px;
  -moz-box-flex: 0;
  flex: 0 0 520px;
  width: 520px;
  max-width: 520px;
}

.next-col-fixed-27 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 540px;
  -webkit-flex: 0 0 540px;
  -moz-box-flex: 0;
  flex: 0 0 540px;
  width: 540px;
  max-width: 540px;
}

.next-col-fixed-28 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 560px;
  -webkit-flex: 0 0 560px;
  -moz-box-flex: 0;
  flex: 0 0 560px;
  width: 560px;
  max-width: 560px;
}

.next-col-fixed-29 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 580px;
  -webkit-flex: 0 0 580px;
  -moz-box-flex: 0;
  flex: 0 0 580px;
  width: 580px;
  max-width: 580px;
}

.next-col-fixed-30 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 600px;
  -webkit-flex: 0 0 600px;
  -moz-box-flex: 0;
  flex: 0 0 600px;
  width: 600px;
  max-width: 600px;
}

.next-col-offset-1 {
  margin-left: 4.16667%;
}

.next-col-offset-2 {
  margin-left: 8.33333%;
}

.next-col-offset-3 {
  margin-left: 12.5%;
}

.next-col-offset-4 {
  margin-left: 16.66667%;
}

.next-col-offset-5 {
  margin-left: 20.83333%;
}

.next-col-offset-6 {
  margin-left: 25%;
}

.next-col-offset-7 {
  margin-left: 29.16667%;
}

.next-col-offset-8 {
  margin-left: 33.33333%;
}

.next-col-offset-9 {
  margin-left: 37.5%;
}

.next-col-offset-10 {
  margin-left: 41.66667%;
}

.next-col-offset-11 {
  margin-left: 45.83333%;
}

.next-col-offset-12 {
  margin-left: 50%;
}

.next-col-offset-13 {
  margin-left: 54.16667%;
}

.next-col-offset-14 {
  margin-left: 58.33333%;
}

.next-col-offset-15 {
  margin-left: 62.5%;
}

.next-col-offset-16 {
  margin-left: 66.66667%;
}

.next-col-offset-17 {
  margin-left: 70.83333%;
}

.next-col-offset-18 {
  margin-left: 75%;
}

.next-col-offset-19 {
  margin-left: 79.16667%;
}

.next-col-offset-20 {
  margin-left: 83.33333%;
}

.next-col-offset-21 {
  margin-left: 87.5%;
}

.next-col-offset-22 {
  margin-left: 91.66667%;
}

.next-col-offset-23 {
  margin-left: 95.83333%;
}

.next-col-offset-24 {
  margin-left: 100%;
}

@media (min-width: 320px) {
  .next-col-xxs-offset-1 {
    margin-left: 4.16667%;
  }

  .next-col-xxs-offset-2 {
    margin-left: 8.33333%;
  }

  .next-col-xxs-offset-3 {
    margin-left: 12.5%;
  }

  .next-col-xxs-offset-4 {
    margin-left: 16.66667%;
  }

  .next-col-xxs-offset-5 {
    margin-left: 20.83333%;
  }

  .next-col-xxs-offset-6 {
    margin-left: 25%;
  }

  .next-col-xxs-offset-7 {
    margin-left: 29.16667%;
  }

  .next-col-xxs-offset-8 {
    margin-left: 33.33333%;
  }

  .next-col-xxs-offset-9 {
    margin-left: 37.5%;
  }

  .next-col-xxs-offset-10 {
    margin-left: 41.66667%;
  }

  .next-col-xxs-offset-11 {
    margin-left: 45.83333%;
  }

  .next-col-xxs-offset-12 {
    margin-left: 50%;
  }

  .next-col-xxs-offset-13 {
    margin-left: 54.16667%;
  }

  .next-col-xxs-offset-14 {
    margin-left: 58.33333%;
  }

  .next-col-xxs-offset-15 {
    margin-left: 62.5%;
  }

  .next-col-xxs-offset-16 {
    margin-left: 66.66667%;
  }

  .next-col-xxs-offset-17 {
    margin-left: 70.83333%;
  }

  .next-col-xxs-offset-18 {
    margin-left: 75%;
  }

  .next-col-xxs-offset-19 {
    margin-left: 79.16667%;
  }

  .next-col-xxs-offset-20 {
    margin-left: 83.33333%;
  }

  .next-col-xxs-offset-21 {
    margin-left: 87.5%;
  }

  .next-col-xxs-offset-22 {
    margin-left: 91.66667%;
  }

  .next-col-xxs-offset-23 {
    margin-left: 95.83333%;
  }

  .next-col-xxs-offset-24 {
    margin-left: 100%;
  }
}

@media (min-width: 480px) {
  .next-col-xs-offset-1 {
    margin-left: 4.16667%;
  }

  .next-col-xs-offset-2 {
    margin-left: 8.33333%;
  }

  .next-col-xs-offset-3 {
    margin-left: 12.5%;
  }

  .next-col-xs-offset-4 {
    margin-left: 16.66667%;
  }

  .next-col-xs-offset-5 {
    margin-left: 20.83333%;
  }

  .next-col-xs-offset-6 {
    margin-left: 25%;
  }

  .next-col-xs-offset-7 {
    margin-left: 29.16667%;
  }

  .next-col-xs-offset-8 {
    margin-left: 33.33333%;
  }

  .next-col-xs-offset-9 {
    margin-left: 37.5%;
  }

  .next-col-xs-offset-10 {
    margin-left: 41.66667%;
  }

  .next-col-xs-offset-11 {
    margin-left: 45.83333%;
  }

  .next-col-xs-offset-12 {
    margin-left: 50%;
  }

  .next-col-xs-offset-13 {
    margin-left: 54.16667%;
  }

  .next-col-xs-offset-14 {
    margin-left: 58.33333%;
  }

  .next-col-xs-offset-15 {
    margin-left: 62.5%;
  }

  .next-col-xs-offset-16 {
    margin-left: 66.66667%;
  }

  .next-col-xs-offset-17 {
    margin-left: 70.83333%;
  }

  .next-col-xs-offset-18 {
    margin-left: 75%;
  }

  .next-col-xs-offset-19 {
    margin-left: 79.16667%;
  }

  .next-col-xs-offset-20 {
    margin-left: 83.33333%;
  }

  .next-col-xs-offset-21 {
    margin-left: 87.5%;
  }

  .next-col-xs-offset-22 {
    margin-left: 91.66667%;
  }

  .next-col-xs-offset-23 {
    margin-left: 95.83333%;
  }

  .next-col-xs-offset-24 {
    margin-left: 100%;
  }
}

@media (min-width: 720px) {
  .next-col-s-offset-1 {
    margin-left: 4.16667%;
  }

  .next-col-s-offset-2 {
    margin-left: 8.33333%;
  }

  .next-col-s-offset-3 {
    margin-left: 12.5%;
  }

  .next-col-s-offset-4 {
    margin-left: 16.66667%;
  }

  .next-col-s-offset-5 {
    margin-left: 20.83333%;
  }

  .next-col-s-offset-6 {
    margin-left: 25%;
  }

  .next-col-s-offset-7 {
    margin-left: 29.16667%;
  }

  .next-col-s-offset-8 {
    margin-left: 33.33333%;
  }

  .next-col-s-offset-9 {
    margin-left: 37.5%;
  }

  .next-col-s-offset-10 {
    margin-left: 41.66667%;
  }

  .next-col-s-offset-11 {
    margin-left: 45.83333%;
  }

  .next-col-s-offset-12 {
    margin-left: 50%;
  }

  .next-col-s-offset-13 {
    margin-left: 54.16667%;
  }

  .next-col-s-offset-14 {
    margin-left: 58.33333%;
  }

  .next-col-s-offset-15 {
    margin-left: 62.5%;
  }

  .next-col-s-offset-16 {
    margin-left: 66.66667%;
  }

  .next-col-s-offset-17 {
    margin-left: 70.83333%;
  }

  .next-col-s-offset-18 {
    margin-left: 75%;
  }

  .next-col-s-offset-19 {
    margin-left: 79.16667%;
  }

  .next-col-s-offset-20 {
    margin-left: 83.33333%;
  }

  .next-col-s-offset-21 {
    margin-left: 87.5%;
  }

  .next-col-s-offset-22 {
    margin-left: 91.66667%;
  }

  .next-col-s-offset-23 {
    margin-left: 95.83333%;
  }

  .next-col-s-offset-24 {
    margin-left: 100%;
  }
}

@media (min-width: 990px) {
  .next-col-m-offset-1 {
    margin-left: 4.16667%;
  }

  .next-col-m-offset-2 {
    margin-left: 8.33333%;
  }

  .next-col-m-offset-3 {
    margin-left: 12.5%;
  }

  .next-col-m-offset-4 {
    margin-left: 16.66667%;
  }

  .next-col-m-offset-5 {
    margin-left: 20.83333%;
  }

  .next-col-m-offset-6 {
    margin-left: 25%;
  }

  .next-col-m-offset-7 {
    margin-left: 29.16667%;
  }

  .next-col-m-offset-8 {
    margin-left: 33.33333%;
  }

  .next-col-m-offset-9 {
    margin-left: 37.5%;
  }

  .next-col-m-offset-10 {
    margin-left: 41.66667%;
  }

  .next-col-m-offset-11 {
    margin-left: 45.83333%;
  }

  .next-col-m-offset-12 {
    margin-left: 50%;
  }

  .next-col-m-offset-13 {
    margin-left: 54.16667%;
  }

  .next-col-m-offset-14 {
    margin-left: 58.33333%;
  }

  .next-col-m-offset-15 {
    margin-left: 62.5%;
  }

  .next-col-m-offset-16 {
    margin-left: 66.66667%;
  }

  .next-col-m-offset-17 {
    margin-left: 70.83333%;
  }

  .next-col-m-offset-18 {
    margin-left: 75%;
  }

  .next-col-m-offset-19 {
    margin-left: 79.16667%;
  }

  .next-col-m-offset-20 {
    margin-left: 83.33333%;
  }

  .next-col-m-offset-21 {
    margin-left: 87.5%;
  }

  .next-col-m-offset-22 {
    margin-left: 91.66667%;
  }

  .next-col-m-offset-23 {
    margin-left: 95.83333%;
  }

  .next-col-m-offset-24 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .next-col-l-offset-1 {
    margin-left: 4.16667%;
  }

  .next-col-l-offset-2 {
    margin-left: 8.33333%;
  }

  .next-col-l-offset-3 {
    margin-left: 12.5%;
  }

  .next-col-l-offset-4 {
    margin-left: 16.66667%;
  }

  .next-col-l-offset-5 {
    margin-left: 20.83333%;
  }

  .next-col-l-offset-6 {
    margin-left: 25%;
  }

  .next-col-l-offset-7 {
    margin-left: 29.16667%;
  }

  .next-col-l-offset-8 {
    margin-left: 33.33333%;
  }

  .next-col-l-offset-9 {
    margin-left: 37.5%;
  }

  .next-col-l-offset-10 {
    margin-left: 41.66667%;
  }

  .next-col-l-offset-11 {
    margin-left: 45.83333%;
  }

  .next-col-l-offset-12 {
    margin-left: 50%;
  }

  .next-col-l-offset-13 {
    margin-left: 54.16667%;
  }

  .next-col-l-offset-14 {
    margin-left: 58.33333%;
  }

  .next-col-l-offset-15 {
    margin-left: 62.5%;
  }

  .next-col-l-offset-16 {
    margin-left: 66.66667%;
  }

  .next-col-l-offset-17 {
    margin-left: 70.83333%;
  }

  .next-col-l-offset-18 {
    margin-left: 75%;
  }

  .next-col-l-offset-19 {
    margin-left: 79.16667%;
  }

  .next-col-l-offset-20 {
    margin-left: 83.33333%;
  }

  .next-col-l-offset-21 {
    margin-left: 87.5%;
  }

  .next-col-l-offset-22 {
    margin-left: 91.66667%;
  }

  .next-col-l-offset-23 {
    margin-left: 95.83333%;
  }

  .next-col-l-offset-24 {
    margin-left: 100%;
  }
}

@media (min-width: 1500px) {
  .next-col-xl-offset-1 {
    margin-left: 4.16667%;
  }

  .next-col-xl-offset-2 {
    margin-left: 8.33333%;
  }

  .next-col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .next-col-xl-offset-4 {
    margin-left: 16.66667%;
  }

  .next-col-xl-offset-5 {
    margin-left: 20.83333%;
  }

  .next-col-xl-offset-6 {
    margin-left: 25%;
  }

  .next-col-xl-offset-7 {
    margin-left: 29.16667%;
  }

  .next-col-xl-offset-8 {
    margin-left: 33.33333%;
  }

  .next-col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .next-col-xl-offset-10 {
    margin-left: 41.66667%;
  }

  .next-col-xl-offset-11 {
    margin-left: 45.83333%;
  }

  .next-col-xl-offset-12 {
    margin-left: 50%;
  }

  .next-col-xl-offset-13 {
    margin-left: 54.16667%;
  }

  .next-col-xl-offset-14 {
    margin-left: 58.33333%;
  }

  .next-col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .next-col-xl-offset-16 {
    margin-left: 66.66667%;
  }

  .next-col-xl-offset-17 {
    margin-left: 70.83333%;
  }

  .next-col-xl-offset-18 {
    margin-left: 75%;
  }

  .next-col-xl-offset-19 {
    margin-left: 79.16667%;
  }

  .next-col-xl-offset-20 {
    margin-left: 83.33333%;
  }

  .next-col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .next-col-xl-offset-22 {
    margin-left: 91.66667%;
  }

  .next-col-xl-offset-23 {
    margin-left: 95.83333%;
  }

  .next-col-xl-offset-24 {
    margin-left: 100%;
  }
}

.next-col-offset-fixed-1 {
  margin-left: 20px;
}

.next-col-offset-fixed-2 {
  margin-left: 40px;
}

.next-col-offset-fixed-3 {
  margin-left: 60px;
}

.next-col-offset-fixed-4 {
  margin-left: 80px;
}

.next-col-offset-fixed-5 {
  margin-left: 100px;
}

.next-col-offset-fixed-6 {
  margin-left: 120px;
}

.next-col-offset-fixed-7 {
  margin-left: 140px;
}

.next-col-offset-fixed-8 {
  margin-left: 160px;
}

.next-col-offset-fixed-9 {
  margin-left: 180px;
}

.next-col-offset-fixed-10 {
  margin-left: 200px;
}

.next-col-offset-fixed-11 {
  margin-left: 220px;
}

.next-col-offset-fixed-12 {
  margin-left: 240px;
}

.next-col-offset-fixed-13 {
  margin-left: 260px;
}

.next-col-offset-fixed-14 {
  margin-left: 280px;
}

.next-col-offset-fixed-15 {
  margin-left: 300px;
}

.next-col-offset-fixed-16 {
  margin-left: 320px;
}

.next-col-offset-fixed-17 {
  margin-left: 340px;
}

.next-col-offset-fixed-18 {
  margin-left: 360px;
}

.next-col-offset-fixed-19 {
  margin-left: 380px;
}

.next-col-offset-fixed-20 {
  margin-left: 400px;
}

.next-col-offset-fixed-21 {
  margin-left: 420px;
}

.next-col-offset-fixed-22 {
  margin-left: 440px;
}

.next-col-offset-fixed-23 {
  margin-left: 460px;
}

.next-col-offset-fixed-24 {
  margin-left: 480px;
}

.next-col-offset-fixed-25 {
  margin-left: 500px;
}

.next-col-offset-fixed-26 {
  margin-left: 520px;
}

.next-col-offset-fixed-27 {
  margin-left: 540px;
}

.next-col-offset-fixed-28 {
  margin-left: 560px;
}

.next-col-offset-fixed-29 {
  margin-left: 580px;
}

.next-col-offset-fixed-30 {
  margin-left: 600px;
}

.next-col-offset-fixed-xxs-1 {
  margin-left: 20px;
}

.next-col-offset-fixed-xxs-2 {
  margin-left: 40px;
}

.next-col-offset-fixed-xxs-3 {
  margin-left: 60px;
}

.next-col-offset-fixed-xxs-4 {
  margin-left: 80px;
}

.next-col-offset-fixed-xxs-5 {
  margin-left: 100px;
}

.next-col-offset-fixed-xxs-6 {
  margin-left: 120px;
}

.next-col-offset-fixed-xxs-7 {
  margin-left: 140px;
}

.next-col-offset-fixed-xxs-8 {
  margin-left: 160px;
}

.next-col-offset-fixed-xxs-9 {
  margin-left: 180px;
}

.next-col-offset-fixed-xxs-10 {
  margin-left: 200px;
}

.next-col-offset-fixed-xxs-11 {
  margin-left: 220px;
}

.next-col-offset-fixed-xxs-12 {
  margin-left: 240px;
}

.next-col-offset-fixed-xxs-13 {
  margin-left: 260px;
}

.next-col-offset-fixed-xxs-14 {
  margin-left: 280px;
}

.next-col-offset-fixed-xxs-15 {
  margin-left: 300px;
}

.next-col-offset-fixed-xxs-16 {
  margin-left: 320px;
}

.next-col-offset-fixed-xxs-17 {
  margin-left: 340px;
}

.next-col-offset-fixed-xxs-18 {
  margin-left: 360px;
}

.next-col-offset-fixed-xxs-19 {
  margin-left: 380px;
}

.next-col-offset-fixed-xxs-20 {
  margin-left: 400px;
}

.next-col-offset-fixed-xxs-21 {
  margin-left: 420px;
}

.next-col-offset-fixed-xxs-22 {
  margin-left: 440px;
}

.next-col-offset-fixed-xxs-23 {
  margin-left: 460px;
}

.next-col-offset-fixed-xxs-24 {
  margin-left: 480px;
}

.next-col-offset-fixed-xxs-25 {
  margin-left: 500px;
}

.next-col-offset-fixed-xxs-26 {
  margin-left: 520px;
}

.next-col-offset-fixed-xxs-27 {
  margin-left: 540px;
}

.next-col-offset-fixed-xxs-28 {
  margin-left: 560px;
}

.next-col-offset-fixed-xxs-29 {
  margin-left: 580px;
}

.next-col-offset-fixed-xxs-30 {
  margin-left: 600px;
}

.next-col-offset-fixed-xs-1 {
  margin-left: 20px;
}

.next-col-offset-fixed-xs-2 {
  margin-left: 40px;
}

.next-col-offset-fixed-xs-3 {
  margin-left: 60px;
}

.next-col-offset-fixed-xs-4 {
  margin-left: 80px;
}

.next-col-offset-fixed-xs-5 {
  margin-left: 100px;
}

.next-col-offset-fixed-xs-6 {
  margin-left: 120px;
}

.next-col-offset-fixed-xs-7 {
  margin-left: 140px;
}

.next-col-offset-fixed-xs-8 {
  margin-left: 160px;
}

.next-col-offset-fixed-xs-9 {
  margin-left: 180px;
}

.next-col-offset-fixed-xs-10 {
  margin-left: 200px;
}

.next-col-offset-fixed-xs-11 {
  margin-left: 220px;
}

.next-col-offset-fixed-xs-12 {
  margin-left: 240px;
}

.next-col-offset-fixed-xs-13 {
  margin-left: 260px;
}

.next-col-offset-fixed-xs-14 {
  margin-left: 280px;
}

.next-col-offset-fixed-xs-15 {
  margin-left: 300px;
}

.next-col-offset-fixed-xs-16 {
  margin-left: 320px;
}

.next-col-offset-fixed-xs-17 {
  margin-left: 340px;
}

.next-col-offset-fixed-xs-18 {
  margin-left: 360px;
}

.next-col-offset-fixed-xs-19 {
  margin-left: 380px;
}

.next-col-offset-fixed-xs-20 {
  margin-left: 400px;
}

.next-col-offset-fixed-xs-21 {
  margin-left: 420px;
}

.next-col-offset-fixed-xs-22 {
  margin-left: 440px;
}

.next-col-offset-fixed-xs-23 {
  margin-left: 460px;
}

.next-col-offset-fixed-xs-24 {
  margin-left: 480px;
}

.next-col-offset-fixed-xs-25 {
  margin-left: 500px;
}

.next-col-offset-fixed-xs-26 {
  margin-left: 520px;
}

.next-col-offset-fixed-xs-27 {
  margin-left: 540px;
}

.next-col-offset-fixed-xs-28 {
  margin-left: 560px;
}

.next-col-offset-fixed-xs-29 {
  margin-left: 580px;
}

.next-col-offset-fixed-xs-30 {
  margin-left: 600px;
}

.next-col-offset-fixed-s-1 {
  margin-left: 20px;
}

.next-col-offset-fixed-s-2 {
  margin-left: 40px;
}

.next-col-offset-fixed-s-3 {
  margin-left: 60px;
}

.next-col-offset-fixed-s-4 {
  margin-left: 80px;
}

.next-col-offset-fixed-s-5 {
  margin-left: 100px;
}

.next-col-offset-fixed-s-6 {
  margin-left: 120px;
}

.next-col-offset-fixed-s-7 {
  margin-left: 140px;
}

.next-col-offset-fixed-s-8 {
  margin-left: 160px;
}

.next-col-offset-fixed-s-9 {
  margin-left: 180px;
}

.next-col-offset-fixed-s-10 {
  margin-left: 200px;
}

.next-col-offset-fixed-s-11 {
  margin-left: 220px;
}

.next-col-offset-fixed-s-12 {
  margin-left: 240px;
}

.next-col-offset-fixed-s-13 {
  margin-left: 260px;
}

.next-col-offset-fixed-s-14 {
  margin-left: 280px;
}

.next-col-offset-fixed-s-15 {
  margin-left: 300px;
}

.next-col-offset-fixed-s-16 {
  margin-left: 320px;
}

.next-col-offset-fixed-s-17 {
  margin-left: 340px;
}

.next-col-offset-fixed-s-18 {
  margin-left: 360px;
}

.next-col-offset-fixed-s-19 {
  margin-left: 380px;
}

.next-col-offset-fixed-s-20 {
  margin-left: 400px;
}

.next-col-offset-fixed-s-21 {
  margin-left: 420px;
}

.next-col-offset-fixed-s-22 {
  margin-left: 440px;
}

.next-col-offset-fixed-s-23 {
  margin-left: 460px;
}

.next-col-offset-fixed-s-24 {
  margin-left: 480px;
}

.next-col-offset-fixed-s-25 {
  margin-left: 500px;
}

.next-col-offset-fixed-s-26 {
  margin-left: 520px;
}

.next-col-offset-fixed-s-27 {
  margin-left: 540px;
}

.next-col-offset-fixed-s-28 {
  margin-left: 560px;
}

.next-col-offset-fixed-s-29 {
  margin-left: 580px;
}

.next-col-offset-fixed-s-30 {
  margin-left: 600px;
}

.next-col-offset-fixed-m-1 {
  margin-left: 20px;
}

.next-col-offset-fixed-m-2 {
  margin-left: 40px;
}

.next-col-offset-fixed-m-3 {
  margin-left: 60px;
}

.next-col-offset-fixed-m-4 {
  margin-left: 80px;
}

.next-col-offset-fixed-m-5 {
  margin-left: 100px;
}

.next-col-offset-fixed-m-6 {
  margin-left: 120px;
}

.next-col-offset-fixed-m-7 {
  margin-left: 140px;
}

.next-col-offset-fixed-m-8 {
  margin-left: 160px;
}

.next-col-offset-fixed-m-9 {
  margin-left: 180px;
}

.next-col-offset-fixed-m-10 {
  margin-left: 200px;
}

.next-col-offset-fixed-m-11 {
  margin-left: 220px;
}

.next-col-offset-fixed-m-12 {
  margin-left: 240px;
}

.next-col-offset-fixed-m-13 {
  margin-left: 260px;
}

.next-col-offset-fixed-m-14 {
  margin-left: 280px;
}

.next-col-offset-fixed-m-15 {
  margin-left: 300px;
}

.next-col-offset-fixed-m-16 {
  margin-left: 320px;
}

.next-col-offset-fixed-m-17 {
  margin-left: 340px;
}

.next-col-offset-fixed-m-18 {
  margin-left: 360px;
}

.next-col-offset-fixed-m-19 {
  margin-left: 380px;
}

.next-col-offset-fixed-m-20 {
  margin-left: 400px;
}

.next-col-offset-fixed-m-21 {
  margin-left: 420px;
}

.next-col-offset-fixed-m-22 {
  margin-left: 440px;
}

.next-col-offset-fixed-m-23 {
  margin-left: 460px;
}

.next-col-offset-fixed-m-24 {
  margin-left: 480px;
}

.next-col-offset-fixed-m-25 {
  margin-left: 500px;
}

.next-col-offset-fixed-m-26 {
  margin-left: 520px;
}

.next-col-offset-fixed-m-27 {
  margin-left: 540px;
}

.next-col-offset-fixed-m-28 {
  margin-left: 560px;
}

.next-col-offset-fixed-m-29 {
  margin-left: 580px;
}

.next-col-offset-fixed-m-30 {
  margin-left: 600px;
}

.next-col-offset-fixed-l-1 {
  margin-left: 20px;
}

.next-col-offset-fixed-l-2 {
  margin-left: 40px;
}

.next-col-offset-fixed-l-3 {
  margin-left: 60px;
}

.next-col-offset-fixed-l-4 {
  margin-left: 80px;
}

.next-col-offset-fixed-l-5 {
  margin-left: 100px;
}

.next-col-offset-fixed-l-6 {
  margin-left: 120px;
}

.next-col-offset-fixed-l-7 {
  margin-left: 140px;
}

.next-col-offset-fixed-l-8 {
  margin-left: 160px;
}

.next-col-offset-fixed-l-9 {
  margin-left: 180px;
}

.next-col-offset-fixed-l-10 {
  margin-left: 200px;
}

.next-col-offset-fixed-l-11 {
  margin-left: 220px;
}

.next-col-offset-fixed-l-12 {
  margin-left: 240px;
}

.next-col-offset-fixed-l-13 {
  margin-left: 260px;
}

.next-col-offset-fixed-l-14 {
  margin-left: 280px;
}

.next-col-offset-fixed-l-15 {
  margin-left: 300px;
}

.next-col-offset-fixed-l-16 {
  margin-left: 320px;
}

.next-col-offset-fixed-l-17 {
  margin-left: 340px;
}

.next-col-offset-fixed-l-18 {
  margin-left: 360px;
}

.next-col-offset-fixed-l-19 {
  margin-left: 380px;
}

.next-col-offset-fixed-l-20 {
  margin-left: 400px;
}

.next-col-offset-fixed-l-21 {
  margin-left: 420px;
}

.next-col-offset-fixed-l-22 {
  margin-left: 440px;
}

.next-col-offset-fixed-l-23 {
  margin-left: 460px;
}

.next-col-offset-fixed-l-24 {
  margin-left: 480px;
}

.next-col-offset-fixed-l-25 {
  margin-left: 500px;
}

.next-col-offset-fixed-l-26 {
  margin-left: 520px;
}

.next-col-offset-fixed-l-27 {
  margin-left: 540px;
}

.next-col-offset-fixed-l-28 {
  margin-left: 560px;
}

.next-col-offset-fixed-l-29 {
  margin-left: 580px;
}

.next-col-offset-fixed-l-30 {
  margin-left: 600px;
}

.next-col-offset-fixed-xl-1 {
  margin-left: 20px;
}

.next-col-offset-fixed-xl-2 {
  margin-left: 40px;
}

.next-col-offset-fixed-xl-3 {
  margin-left: 60px;
}

.next-col-offset-fixed-xl-4 {
  margin-left: 80px;
}

.next-col-offset-fixed-xl-5 {
  margin-left: 100px;
}

.next-col-offset-fixed-xl-6 {
  margin-left: 120px;
}

.next-col-offset-fixed-xl-7 {
  margin-left: 140px;
}

.next-col-offset-fixed-xl-8 {
  margin-left: 160px;
}

.next-col-offset-fixed-xl-9 {
  margin-left: 180px;
}

.next-col-offset-fixed-xl-10 {
  margin-left: 200px;
}

.next-col-offset-fixed-xl-11 {
  margin-left: 220px;
}

.next-col-offset-fixed-xl-12 {
  margin-left: 240px;
}

.next-col-offset-fixed-xl-13 {
  margin-left: 260px;
}

.next-col-offset-fixed-xl-14 {
  margin-left: 280px;
}

.next-col-offset-fixed-xl-15 {
  margin-left: 300px;
}

.next-col-offset-fixed-xl-16 {
  margin-left: 320px;
}

.next-col-offset-fixed-xl-17 {
  margin-left: 340px;
}

.next-col-offset-fixed-xl-18 {
  margin-left: 360px;
}

.next-col-offset-fixed-xl-19 {
  margin-left: 380px;
}

.next-col-offset-fixed-xl-20 {
  margin-left: 400px;
}

.next-col-offset-fixed-xl-21 {
  margin-left: 420px;
}

.next-col-offset-fixed-xl-22 {
  margin-left: 440px;
}

.next-col-offset-fixed-xl-23 {
  margin-left: 460px;
}

.next-col-offset-fixed-xl-24 {
  margin-left: 480px;
}

.next-col-offset-fixed-xl-25 {
  margin-left: 500px;
}

.next-col-offset-fixed-xl-26 {
  margin-left: 520px;
}

.next-col-offset-fixed-xl-27 {
  margin-left: 540px;
}

.next-col-offset-fixed-xl-28 {
  margin-left: 560px;
}

.next-col-offset-fixed-xl-29 {
  margin-left: 580px;
}

.next-col-offset-fixed-xl-30 {
  margin-left: 600px;
}

.next-col.next-col-hidden {
  display: none;
}

@media (min-width: 320px) and (max-width: 479px) {
  .next-col.next-col-xxs-hidden {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 719px) {
  .next-col.next-col-xs-hidden {
    display: none;
  }
}

@media (min-width: 720px) and (max-width: 989px) {
  .next-col.next-col-s-hidden {
    display: none;
  }
}

@media (min-width: 990px) and (max-width: 1199px) {
  .next-col.next-col-m-hidden {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .next-col.next-col-l-hidden {
    display: none;
  }
}

@media (min-width: 1500px) {
  .next-col.next-col-xl-hidden {
    display: none;
  }
}

.next-row.next-row-hidden {
  display: none;
}

@media (min-width: 320px) and (max-width: 479px) {
  .next-row.next-row-xxs-hidden {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 719px) {
  .next-row.next-row-xs-hidden {
    display: none;
  }
}

@media (min-width: 720px) and (max-width: 989px) {
  .next-row.next-row-s-hidden {
    display: none;
  }
}

@media (min-width: 990px) and (max-width: 1199px) {
  .next-row.next-row-m-hidden {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .next-row.next-row-l-hidden {
    display: none;
  }
}

@media (min-width: 1500px) {
  .next-row.next-row-xl-hidden {
    display: none;
  }
}

.next-col-offset-1[dir="rtl"] {
  margin-right: 4.16667%;
  margin-left: auto;
}

.next-col-offset-2[dir="rtl"] {
  margin-right: 8.33333%;
  margin-left: auto;
}

.next-col-offset-3[dir="rtl"] {
  margin-right: 12.5%;
  margin-left: auto;
}

.next-col-offset-4[dir="rtl"] {
  margin-right: 16.66667%;
  margin-left: auto;
}

.next-col-offset-5[dir="rtl"] {
  margin-right: 20.83333%;
  margin-left: auto;
}

.next-col-offset-6[dir="rtl"] {
  margin-right: 25%;
  margin-left: auto;
}

.next-col-offset-7[dir="rtl"] {
  margin-right: 29.16667%;
  margin-left: auto;
}

.next-col-offset-8[dir="rtl"] {
  margin-right: 33.33333%;
  margin-left: auto;
}

.next-col-offset-9[dir="rtl"] {
  margin-right: 37.5%;
  margin-left: auto;
}

.next-col-offset-10[dir="rtl"] {
  margin-right: 41.66667%;
  margin-left: auto;
}

.next-col-offset-11[dir="rtl"] {
  margin-right: 45.83333%;
  margin-left: auto;
}

.next-col-offset-12[dir="rtl"] {
  margin-right: 50%;
  margin-left: auto;
}

.next-col-offset-13[dir="rtl"] {
  margin-right: 54.16667%;
  margin-left: auto;
}

.next-col-offset-14[dir="rtl"] {
  margin-right: 58.33333%;
  margin-left: auto;
}

.next-col-offset-15[dir="rtl"] {
  margin-right: 62.5%;
  margin-left: auto;
}

.next-col-offset-16[dir="rtl"] {
  margin-right: 66.66667%;
  margin-left: auto;
}

.next-col-offset-17[dir="rtl"] {
  margin-right: 70.83333%;
  margin-left: auto;
}

.next-col-offset-18[dir="rtl"] {
  margin-right: 75%;
  margin-left: auto;
}

.next-col-offset-19[dir="rtl"] {
  margin-right: 79.16667%;
  margin-left: auto;
}

.next-col-offset-20[dir="rtl"] {
  margin-right: 83.33333%;
  margin-left: auto;
}

.next-col-offset-21[dir="rtl"] {
  margin-right: 87.5%;
  margin-left: auto;
}

.next-col-offset-22[dir="rtl"] {
  margin-right: 91.66667%;
  margin-left: auto;
}

.next-col-offset-23[dir="rtl"] {
  margin-right: 95.83333%;
  margin-left: auto;
}

.next-col-offset-24[dir="rtl"] {
  margin-right: 100%;
  margin-left: auto;
}

@media (min-width: 320px) {
  .next-col-xxs-offset-1[dir="rtl"] {
    margin-right: 4.16667%;
    margin-left: auto;
  }

  .next-col-xxs-offset-2[dir="rtl"] {
    margin-right: 8.33333%;
    margin-left: auto;
  }

  .next-col-xxs-offset-3[dir="rtl"] {
    margin-right: 12.5%;
    margin-left: auto;
  }

  .next-col-xxs-offset-4[dir="rtl"] {
    margin-right: 16.66667%;
    margin-left: auto;
  }

  .next-col-xxs-offset-5[dir="rtl"] {
    margin-right: 20.83333%;
    margin-left: auto;
  }

  .next-col-xxs-offset-6[dir="rtl"] {
    margin-right: 25%;
    margin-left: auto;
  }

  .next-col-xxs-offset-7[dir="rtl"] {
    margin-right: 29.16667%;
    margin-left: auto;
  }

  .next-col-xxs-offset-8[dir="rtl"] {
    margin-right: 33.33333%;
    margin-left: auto;
  }

  .next-col-xxs-offset-9[dir="rtl"] {
    margin-right: 37.5%;
    margin-left: auto;
  }

  .next-col-xxs-offset-10[dir="rtl"] {
    margin-right: 41.66667%;
    margin-left: auto;
  }

  .next-col-xxs-offset-11[dir="rtl"] {
    margin-right: 45.83333%;
    margin-left: auto;
  }

  .next-col-xxs-offset-12[dir="rtl"] {
    margin-right: 50%;
    margin-left: auto;
  }

  .next-col-xxs-offset-13[dir="rtl"] {
    margin-right: 54.16667%;
    margin-left: auto;
  }

  .next-col-xxs-offset-14[dir="rtl"] {
    margin-right: 58.33333%;
    margin-left: auto;
  }

  .next-col-xxs-offset-15[dir="rtl"] {
    margin-right: 62.5%;
    margin-left: auto;
  }

  .next-col-xxs-offset-16[dir="rtl"] {
    margin-right: 66.66667%;
    margin-left: auto;
  }

  .next-col-xxs-offset-17[dir="rtl"] {
    margin-right: 70.83333%;
    margin-left: auto;
  }

  .next-col-xxs-offset-18[dir="rtl"] {
    margin-right: 75%;
    margin-left: auto;
  }

  .next-col-xxs-offset-19[dir="rtl"] {
    margin-right: 79.16667%;
    margin-left: auto;
  }

  .next-col-xxs-offset-20[dir="rtl"] {
    margin-right: 83.33333%;
    margin-left: auto;
  }

  .next-col-xxs-offset-21[dir="rtl"] {
    margin-right: 87.5%;
    margin-left: auto;
  }

  .next-col-xxs-offset-22[dir="rtl"] {
    margin-right: 91.66667%;
    margin-left: auto;
  }

  .next-col-xxs-offset-23[dir="rtl"] {
    margin-right: 95.83333%;
    margin-left: auto;
  }

  .next-col-xxs-offset-24[dir="rtl"] {
    margin-right: 100%;
    margin-left: auto;
  }
}

@media (min-width: 480px) {
  .next-col-xs-offset-1[dir="rtl"] {
    margin-right: 4.16667%;
    margin-left: auto;
  }

  .next-col-xs-offset-2[dir="rtl"] {
    margin-right: 8.33333%;
    margin-left: auto;
  }

  .next-col-xs-offset-3[dir="rtl"] {
    margin-right: 12.5%;
    margin-left: auto;
  }

  .next-col-xs-offset-4[dir="rtl"] {
    margin-right: 16.66667%;
    margin-left: auto;
  }

  .next-col-xs-offset-5[dir="rtl"] {
    margin-right: 20.83333%;
    margin-left: auto;
  }

  .next-col-xs-offset-6[dir="rtl"] {
    margin-right: 25%;
    margin-left: auto;
  }

  .next-col-xs-offset-7[dir="rtl"] {
    margin-right: 29.16667%;
    margin-left: auto;
  }

  .next-col-xs-offset-8[dir="rtl"] {
    margin-right: 33.33333%;
    margin-left: auto;
  }

  .next-col-xs-offset-9[dir="rtl"] {
    margin-right: 37.5%;
    margin-left: auto;
  }

  .next-col-xs-offset-10[dir="rtl"] {
    margin-right: 41.66667%;
    margin-left: auto;
  }

  .next-col-xs-offset-11[dir="rtl"] {
    margin-right: 45.83333%;
    margin-left: auto;
  }

  .next-col-xs-offset-12[dir="rtl"] {
    margin-right: 50%;
    margin-left: auto;
  }

  .next-col-xs-offset-13[dir="rtl"] {
    margin-right: 54.16667%;
    margin-left: auto;
  }

  .next-col-xs-offset-14[dir="rtl"] {
    margin-right: 58.33333%;
    margin-left: auto;
  }

  .next-col-xs-offset-15[dir="rtl"] {
    margin-right: 62.5%;
    margin-left: auto;
  }

  .next-col-xs-offset-16[dir="rtl"] {
    margin-right: 66.66667%;
    margin-left: auto;
  }

  .next-col-xs-offset-17[dir="rtl"] {
    margin-right: 70.83333%;
    margin-left: auto;
  }

  .next-col-xs-offset-18[dir="rtl"] {
    margin-right: 75%;
    margin-left: auto;
  }

  .next-col-xs-offset-19[dir="rtl"] {
    margin-right: 79.16667%;
    margin-left: auto;
  }

  .next-col-xs-offset-20[dir="rtl"] {
    margin-right: 83.33333%;
    margin-left: auto;
  }

  .next-col-xs-offset-21[dir="rtl"] {
    margin-right: 87.5%;
    margin-left: auto;
  }

  .next-col-xs-offset-22[dir="rtl"] {
    margin-right: 91.66667%;
    margin-left: auto;
  }

  .next-col-xs-offset-23[dir="rtl"] {
    margin-right: 95.83333%;
    margin-left: auto;
  }

  .next-col-xs-offset-24[dir="rtl"] {
    margin-right: 100%;
    margin-left: auto;
  }
}

@media (min-width: 720px) {
  .next-col-s-offset-1[dir="rtl"] {
    margin-right: 4.16667%;
    margin-left: auto;
  }

  .next-col-s-offset-2[dir="rtl"] {
    margin-right: 8.33333%;
    margin-left: auto;
  }

  .next-col-s-offset-3[dir="rtl"] {
    margin-right: 12.5%;
    margin-left: auto;
  }

  .next-col-s-offset-4[dir="rtl"] {
    margin-right: 16.66667%;
    margin-left: auto;
  }

  .next-col-s-offset-5[dir="rtl"] {
    margin-right: 20.83333%;
    margin-left: auto;
  }

  .next-col-s-offset-6[dir="rtl"] {
    margin-right: 25%;
    margin-left: auto;
  }

  .next-col-s-offset-7[dir="rtl"] {
    margin-right: 29.16667%;
    margin-left: auto;
  }

  .next-col-s-offset-8[dir="rtl"] {
    margin-right: 33.33333%;
    margin-left: auto;
  }

  .next-col-s-offset-9[dir="rtl"] {
    margin-right: 37.5%;
    margin-left: auto;
  }

  .next-col-s-offset-10[dir="rtl"] {
    margin-right: 41.66667%;
    margin-left: auto;
  }

  .next-col-s-offset-11[dir="rtl"] {
    margin-right: 45.83333%;
    margin-left: auto;
  }

  .next-col-s-offset-12[dir="rtl"] {
    margin-right: 50%;
    margin-left: auto;
  }

  .next-col-s-offset-13[dir="rtl"] {
    margin-right: 54.16667%;
    margin-left: auto;
  }

  .next-col-s-offset-14[dir="rtl"] {
    margin-right: 58.33333%;
    margin-left: auto;
  }

  .next-col-s-offset-15[dir="rtl"] {
    margin-right: 62.5%;
    margin-left: auto;
  }

  .next-col-s-offset-16[dir="rtl"] {
    margin-right: 66.66667%;
    margin-left: auto;
  }

  .next-col-s-offset-17[dir="rtl"] {
    margin-right: 70.83333%;
    margin-left: auto;
  }

  .next-col-s-offset-18[dir="rtl"] {
    margin-right: 75%;
    margin-left: auto;
  }

  .next-col-s-offset-19[dir="rtl"] {
    margin-right: 79.16667%;
    margin-left: auto;
  }

  .next-col-s-offset-20[dir="rtl"] {
    margin-right: 83.33333%;
    margin-left: auto;
  }

  .next-col-s-offset-21[dir="rtl"] {
    margin-right: 87.5%;
    margin-left: auto;
  }

  .next-col-s-offset-22[dir="rtl"] {
    margin-right: 91.66667%;
    margin-left: auto;
  }

  .next-col-s-offset-23[dir="rtl"] {
    margin-right: 95.83333%;
    margin-left: auto;
  }

  .next-col-s-offset-24[dir="rtl"] {
    margin-right: 100%;
    margin-left: auto;
  }
}

@media (min-width: 990px) {
  .next-col-m-offset-1[dir="rtl"] {
    margin-right: 4.16667%;
    margin-left: auto;
  }

  .next-col-m-offset-2[dir="rtl"] {
    margin-right: 8.33333%;
    margin-left: auto;
  }

  .next-col-m-offset-3[dir="rtl"] {
    margin-right: 12.5%;
    margin-left: auto;
  }

  .next-col-m-offset-4[dir="rtl"] {
    margin-right: 16.66667%;
    margin-left: auto;
  }

  .next-col-m-offset-5[dir="rtl"] {
    margin-right: 20.83333%;
    margin-left: auto;
  }

  .next-col-m-offset-6[dir="rtl"] {
    margin-right: 25%;
    margin-left: auto;
  }

  .next-col-m-offset-7[dir="rtl"] {
    margin-right: 29.16667%;
    margin-left: auto;
  }

  .next-col-m-offset-8[dir="rtl"] {
    margin-right: 33.33333%;
    margin-left: auto;
  }

  .next-col-m-offset-9[dir="rtl"] {
    margin-right: 37.5%;
    margin-left: auto;
  }

  .next-col-m-offset-10[dir="rtl"] {
    margin-right: 41.66667%;
    margin-left: auto;
  }

  .next-col-m-offset-11[dir="rtl"] {
    margin-right: 45.83333%;
    margin-left: auto;
  }

  .next-col-m-offset-12[dir="rtl"] {
    margin-right: 50%;
    margin-left: auto;
  }

  .next-col-m-offset-13[dir="rtl"] {
    margin-right: 54.16667%;
    margin-left: auto;
  }

  .next-col-m-offset-14[dir="rtl"] {
    margin-right: 58.33333%;
    margin-left: auto;
  }

  .next-col-m-offset-15[dir="rtl"] {
    margin-right: 62.5%;
    margin-left: auto;
  }

  .next-col-m-offset-16[dir="rtl"] {
    margin-right: 66.66667%;
    margin-left: auto;
  }

  .next-col-m-offset-17[dir="rtl"] {
    margin-right: 70.83333%;
    margin-left: auto;
  }

  .next-col-m-offset-18[dir="rtl"] {
    margin-right: 75%;
    margin-left: auto;
  }

  .next-col-m-offset-19[dir="rtl"] {
    margin-right: 79.16667%;
    margin-left: auto;
  }

  .next-col-m-offset-20[dir="rtl"] {
    margin-right: 83.33333%;
    margin-left: auto;
  }

  .next-col-m-offset-21[dir="rtl"] {
    margin-right: 87.5%;
    margin-left: auto;
  }

  .next-col-m-offset-22[dir="rtl"] {
    margin-right: 91.66667%;
    margin-left: auto;
  }

  .next-col-m-offset-23[dir="rtl"] {
    margin-right: 95.83333%;
    margin-left: auto;
  }

  .next-col-m-offset-24[dir="rtl"] {
    margin-right: 100%;
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .next-col-l-offset-1[dir="rtl"] {
    margin-right: 4.16667%;
    margin-left: auto;
  }

  .next-col-l-offset-2[dir="rtl"] {
    margin-right: 8.33333%;
    margin-left: auto;
  }

  .next-col-l-offset-3[dir="rtl"] {
    margin-right: 12.5%;
    margin-left: auto;
  }

  .next-col-l-offset-4[dir="rtl"] {
    margin-right: 16.66667%;
    margin-left: auto;
  }

  .next-col-l-offset-5[dir="rtl"] {
    margin-right: 20.83333%;
    margin-left: auto;
  }

  .next-col-l-offset-6[dir="rtl"] {
    margin-right: 25%;
    margin-left: auto;
  }

  .next-col-l-offset-7[dir="rtl"] {
    margin-right: 29.16667%;
    margin-left: auto;
  }

  .next-col-l-offset-8[dir="rtl"] {
    margin-right: 33.33333%;
    margin-left: auto;
  }

  .next-col-l-offset-9[dir="rtl"] {
    margin-right: 37.5%;
    margin-left: auto;
  }

  .next-col-l-offset-10[dir="rtl"] {
    margin-right: 41.66667%;
    margin-left: auto;
  }

  .next-col-l-offset-11[dir="rtl"] {
    margin-right: 45.83333%;
    margin-left: auto;
  }

  .next-col-l-offset-12[dir="rtl"] {
    margin-right: 50%;
    margin-left: auto;
  }

  .next-col-l-offset-13[dir="rtl"] {
    margin-right: 54.16667%;
    margin-left: auto;
  }

  .next-col-l-offset-14[dir="rtl"] {
    margin-right: 58.33333%;
    margin-left: auto;
  }

  .next-col-l-offset-15[dir="rtl"] {
    margin-right: 62.5%;
    margin-left: auto;
  }

  .next-col-l-offset-16[dir="rtl"] {
    margin-right: 66.66667%;
    margin-left: auto;
  }

  .next-col-l-offset-17[dir="rtl"] {
    margin-right: 70.83333%;
    margin-left: auto;
  }

  .next-col-l-offset-18[dir="rtl"] {
    margin-right: 75%;
    margin-left: auto;
  }

  .next-col-l-offset-19[dir="rtl"] {
    margin-right: 79.16667%;
    margin-left: auto;
  }

  .next-col-l-offset-20[dir="rtl"] {
    margin-right: 83.33333%;
    margin-left: auto;
  }

  .next-col-l-offset-21[dir="rtl"] {
    margin-right: 87.5%;
    margin-left: auto;
  }

  .next-col-l-offset-22[dir="rtl"] {
    margin-right: 91.66667%;
    margin-left: auto;
  }

  .next-col-l-offset-23[dir="rtl"] {
    margin-right: 95.83333%;
    margin-left: auto;
  }

  .next-col-l-offset-24[dir="rtl"] {
    margin-right: 100%;
    margin-left: auto;
  }
}

@media (min-width: 1500px) {
  .next-col-xl-offset-1[dir="rtl"] {
    margin-right: 4.16667%;
    margin-left: auto;
  }

  .next-col-xl-offset-2[dir="rtl"] {
    margin-right: 8.33333%;
    margin-left: auto;
  }

  .next-col-xl-offset-3[dir="rtl"] {
    margin-right: 12.5%;
    margin-left: auto;
  }

  .next-col-xl-offset-4[dir="rtl"] {
    margin-right: 16.66667%;
    margin-left: auto;
  }

  .next-col-xl-offset-5[dir="rtl"] {
    margin-right: 20.83333%;
    margin-left: auto;
  }

  .next-col-xl-offset-6[dir="rtl"] {
    margin-right: 25%;
    margin-left: auto;
  }

  .next-col-xl-offset-7[dir="rtl"] {
    margin-right: 29.16667%;
    margin-left: auto;
  }

  .next-col-xl-offset-8[dir="rtl"] {
    margin-right: 33.33333%;
    margin-left: auto;
  }

  .next-col-xl-offset-9[dir="rtl"] {
    margin-right: 37.5%;
    margin-left: auto;
  }

  .next-col-xl-offset-10[dir="rtl"] {
    margin-right: 41.66667%;
    margin-left: auto;
  }

  .next-col-xl-offset-11[dir="rtl"] {
    margin-right: 45.83333%;
    margin-left: auto;
  }

  .next-col-xl-offset-12[dir="rtl"] {
    margin-right: 50%;
    margin-left: auto;
  }

  .next-col-xl-offset-13[dir="rtl"] {
    margin-right: 54.16667%;
    margin-left: auto;
  }

  .next-col-xl-offset-14[dir="rtl"] {
    margin-right: 58.33333%;
    margin-left: auto;
  }

  .next-col-xl-offset-15[dir="rtl"] {
    margin-right: 62.5%;
    margin-left: auto;
  }

  .next-col-xl-offset-16[dir="rtl"] {
    margin-right: 66.66667%;
    margin-left: auto;
  }

  .next-col-xl-offset-17[dir="rtl"] {
    margin-right: 70.83333%;
    margin-left: auto;
  }

  .next-col-xl-offset-18[dir="rtl"] {
    margin-right: 75%;
    margin-left: auto;
  }

  .next-col-xl-offset-19[dir="rtl"] {
    margin-right: 79.16667%;
    margin-left: auto;
  }

  .next-col-xl-offset-20[dir="rtl"] {
    margin-right: 83.33333%;
    margin-left: auto;
  }

  .next-col-xl-offset-21[dir="rtl"] {
    margin-right: 87.5%;
    margin-left: auto;
  }

  .next-col-xl-offset-22[dir="rtl"] {
    margin-right: 91.66667%;
    margin-left: auto;
  }

  .next-col-xl-offset-23[dir="rtl"] {
    margin-right: 95.83333%;
    margin-left: auto;
  }

  .next-col-xl-offset-24[dir="rtl"] {
    margin-right: 100%;
    margin-left: auto;
  }
}

.next-col-offset-fixed-1[dir="rtl"] {
  margin-right: 20px;
  margin-left: auto;
}

.next-col-offset-fixed-2[dir="rtl"] {
  margin-right: 40px;
  margin-left: auto;
}

.next-col-offset-fixed-3[dir="rtl"] {
  margin-right: 60px;
  margin-left: auto;
}

.next-col-offset-fixed-4[dir="rtl"] {
  margin-right: 80px;
  margin-left: auto;
}

.next-col-offset-fixed-5[dir="rtl"] {
  margin-right: 100px;
  margin-left: auto;
}

.next-col-offset-fixed-6[dir="rtl"] {
  margin-right: 120px;
  margin-left: auto;
}

.next-col-offset-fixed-7[dir="rtl"] {
  margin-right: 140px;
  margin-left: auto;
}

.next-col-offset-fixed-8[dir="rtl"] {
  margin-right: 160px;
  margin-left: auto;
}

.next-col-offset-fixed-9[dir="rtl"] {
  margin-right: 180px;
  margin-left: auto;
}

.next-col-offset-fixed-10[dir="rtl"] {
  margin-right: 200px;
  margin-left: auto;
}

.next-col-offset-fixed-11[dir="rtl"] {
  margin-right: 220px;
  margin-left: auto;
}

.next-col-offset-fixed-12[dir="rtl"] {
  margin-right: 240px;
  margin-left: auto;
}

.next-col-offset-fixed-13[dir="rtl"] {
  margin-right: 260px;
  margin-left: auto;
}

.next-col-offset-fixed-14[dir="rtl"] {
  margin-right: 280px;
  margin-left: auto;
}

.next-col-offset-fixed-15[dir="rtl"] {
  margin-right: 300px;
  margin-left: auto;
}

.next-col-offset-fixed-16[dir="rtl"] {
  margin-right: 320px;
  margin-left: auto;
}

.next-col-offset-fixed-17[dir="rtl"] {
  margin-right: 340px;
  margin-left: auto;
}

.next-col-offset-fixed-18[dir="rtl"] {
  margin-right: 360px;
  margin-left: auto;
}

.next-col-offset-fixed-19[dir="rtl"] {
  margin-right: 380px;
  margin-left: auto;
}

.next-col-offset-fixed-20[dir="rtl"] {
  margin-right: 400px;
  margin-left: auto;
}

.next-col-offset-fixed-21[dir="rtl"] {
  margin-right: 420px;
  margin-left: auto;
}

.next-col-offset-fixed-22[dir="rtl"] {
  margin-right: 440px;
  margin-left: auto;
}

.next-col-offset-fixed-23[dir="rtl"] {
  margin-right: 460px;
  margin-left: auto;
}

.next-col-offset-fixed-24[dir="rtl"] {
  margin-right: 480px;
  margin-left: auto;
}

.next-col-offset-fixed-25[dir="rtl"] {
  margin-right: 500px;
  margin-left: auto;
}

.next-col-offset-fixed-26[dir="rtl"] {
  margin-right: 520px;
  margin-left: auto;
}

.next-col-offset-fixed-27[dir="rtl"] {
  margin-right: 540px;
  margin-left: auto;
}

.next-col-offset-fixed-28[dir="rtl"] {
  margin-right: 560px;
  margin-left: auto;
}

.next-col-offset-fixed-29[dir="rtl"] {
  margin-right: 580px;
  margin-left: auto;
}

.next-col-offset-fixed-30[dir="rtl"] {
  margin-right: 600px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-1[dir="rtl"] {
  margin-right: 20px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-2[dir="rtl"] {
  margin-right: 40px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-3[dir="rtl"] {
  margin-right: 60px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-4[dir="rtl"] {
  margin-right: 80px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-5[dir="rtl"] {
  margin-right: 100px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-6[dir="rtl"] {
  margin-right: 120px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-7[dir="rtl"] {
  margin-right: 140px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-8[dir="rtl"] {
  margin-right: 160px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-9[dir="rtl"] {
  margin-right: 180px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-10[dir="rtl"] {
  margin-right: 200px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-11[dir="rtl"] {
  margin-right: 220px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-12[dir="rtl"] {
  margin-right: 240px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-13[dir="rtl"] {
  margin-right: 260px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-14[dir="rtl"] {
  margin-right: 280px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-15[dir="rtl"] {
  margin-right: 300px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-16[dir="rtl"] {
  margin-right: 320px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-17[dir="rtl"] {
  margin-right: 340px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-18[dir="rtl"] {
  margin-right: 360px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-19[dir="rtl"] {
  margin-right: 380px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-20[dir="rtl"] {
  margin-right: 400px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-21[dir="rtl"] {
  margin-right: 420px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-22[dir="rtl"] {
  margin-right: 440px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-23[dir="rtl"] {
  margin-right: 460px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-24[dir="rtl"] {
  margin-right: 480px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-25[dir="rtl"] {
  margin-right: 500px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-26[dir="rtl"] {
  margin-right: 520px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-27[dir="rtl"] {
  margin-right: 540px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-28[dir="rtl"] {
  margin-right: 560px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-29[dir="rtl"] {
  margin-right: 580px;
  margin-left: auto;
}

.next-col-offset-fixed-xxs-30[dir="rtl"] {
  margin-right: 600px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-1[dir="rtl"] {
  margin-right: 20px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-2[dir="rtl"] {
  margin-right: 40px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-3[dir="rtl"] {
  margin-right: 60px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-4[dir="rtl"] {
  margin-right: 80px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-5[dir="rtl"] {
  margin-right: 100px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-6[dir="rtl"] {
  margin-right: 120px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-7[dir="rtl"] {
  margin-right: 140px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-8[dir="rtl"] {
  margin-right: 160px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-9[dir="rtl"] {
  margin-right: 180px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-10[dir="rtl"] {
  margin-right: 200px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-11[dir="rtl"] {
  margin-right: 220px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-12[dir="rtl"] {
  margin-right: 240px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-13[dir="rtl"] {
  margin-right: 260px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-14[dir="rtl"] {
  margin-right: 280px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-15[dir="rtl"] {
  margin-right: 300px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-16[dir="rtl"] {
  margin-right: 320px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-17[dir="rtl"] {
  margin-right: 340px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-18[dir="rtl"] {
  margin-right: 360px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-19[dir="rtl"] {
  margin-right: 380px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-20[dir="rtl"] {
  margin-right: 400px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-21[dir="rtl"] {
  margin-right: 420px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-22[dir="rtl"] {
  margin-right: 440px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-23[dir="rtl"] {
  margin-right: 460px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-24[dir="rtl"] {
  margin-right: 480px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-25[dir="rtl"] {
  margin-right: 500px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-26[dir="rtl"] {
  margin-right: 520px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-27[dir="rtl"] {
  margin-right: 540px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-28[dir="rtl"] {
  margin-right: 560px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-29[dir="rtl"] {
  margin-right: 580px;
  margin-left: auto;
}

.next-col-offset-fixed-xs-30[dir="rtl"] {
  margin-right: 600px;
  margin-left: auto;
}

.next-col-offset-fixed-s-1[dir="rtl"] {
  margin-right: 20px;
  margin-left: auto;
}

.next-col-offset-fixed-s-2[dir="rtl"] {
  margin-right: 40px;
  margin-left: auto;
}

.next-col-offset-fixed-s-3[dir="rtl"] {
  margin-right: 60px;
  margin-left: auto;
}

.next-col-offset-fixed-s-4[dir="rtl"] {
  margin-right: 80px;
  margin-left: auto;
}

.next-col-offset-fixed-s-5[dir="rtl"] {
  margin-right: 100px;
  margin-left: auto;
}

.next-col-offset-fixed-s-6[dir="rtl"] {
  margin-right: 120px;
  margin-left: auto;
}

.next-col-offset-fixed-s-7[dir="rtl"] {
  margin-right: 140px;
  margin-left: auto;
}

.next-col-offset-fixed-s-8[dir="rtl"] {
  margin-right: 160px;
  margin-left: auto;
}

.next-col-offset-fixed-s-9[dir="rtl"] {
  margin-right: 180px;
  margin-left: auto;
}

.next-col-offset-fixed-s-10[dir="rtl"] {
  margin-right: 200px;
  margin-left: auto;
}

.next-col-offset-fixed-s-11[dir="rtl"] {
  margin-right: 220px;
  margin-left: auto;
}

.next-col-offset-fixed-s-12[dir="rtl"] {
  margin-right: 240px;
  margin-left: auto;
}

.next-col-offset-fixed-s-13[dir="rtl"] {
  margin-right: 260px;
  margin-left: auto;
}

.next-col-offset-fixed-s-14[dir="rtl"] {
  margin-right: 280px;
  margin-left: auto;
}

.next-col-offset-fixed-s-15[dir="rtl"] {
  margin-right: 300px;
  margin-left: auto;
}

.next-col-offset-fixed-s-16[dir="rtl"] {
  margin-right: 320px;
  margin-left: auto;
}

.next-col-offset-fixed-s-17[dir="rtl"] {
  margin-right: 340px;
  margin-left: auto;
}

.next-col-offset-fixed-s-18[dir="rtl"] {
  margin-right: 360px;
  margin-left: auto;
}

.next-col-offset-fixed-s-19[dir="rtl"] {
  margin-right: 380px;
  margin-left: auto;
}

.next-col-offset-fixed-s-20[dir="rtl"] {
  margin-right: 400px;
  margin-left: auto;
}

.next-col-offset-fixed-s-21[dir="rtl"] {
  margin-right: 420px;
  margin-left: auto;
}

.next-col-offset-fixed-s-22[dir="rtl"] {
  margin-right: 440px;
  margin-left: auto;
}

.next-col-offset-fixed-s-23[dir="rtl"] {
  margin-right: 460px;
  margin-left: auto;
}

.next-col-offset-fixed-s-24[dir="rtl"] {
  margin-right: 480px;
  margin-left: auto;
}

.next-col-offset-fixed-s-25[dir="rtl"] {
  margin-right: 500px;
  margin-left: auto;
}

.next-col-offset-fixed-s-26[dir="rtl"] {
  margin-right: 520px;
  margin-left: auto;
}

.next-col-offset-fixed-s-27[dir="rtl"] {
  margin-right: 540px;
  margin-left: auto;
}

.next-col-offset-fixed-s-28[dir="rtl"] {
  margin-right: 560px;
  margin-left: auto;
}

.next-col-offset-fixed-s-29[dir="rtl"] {
  margin-right: 580px;
  margin-left: auto;
}

.next-col-offset-fixed-s-30[dir="rtl"] {
  margin-right: 600px;
  margin-left: auto;
}

.next-col-offset-fixed-m-1[dir="rtl"] {
  margin-right: 20px;
  margin-left: auto;
}

.next-col-offset-fixed-m-2[dir="rtl"] {
  margin-right: 40px;
  margin-left: auto;
}

.next-col-offset-fixed-m-3[dir="rtl"] {
  margin-right: 60px;
  margin-left: auto;
}

.next-col-offset-fixed-m-4[dir="rtl"] {
  margin-right: 80px;
  margin-left: auto;
}

.next-col-offset-fixed-m-5[dir="rtl"] {
  margin-right: 100px;
  margin-left: auto;
}

.next-col-offset-fixed-m-6[dir="rtl"] {
  margin-right: 120px;
  margin-left: auto;
}

.next-col-offset-fixed-m-7[dir="rtl"] {
  margin-right: 140px;
  margin-left: auto;
}

.next-col-offset-fixed-m-8[dir="rtl"] {
  margin-right: 160px;
  margin-left: auto;
}

.next-col-offset-fixed-m-9[dir="rtl"] {
  margin-right: 180px;
  margin-left: auto;
}

.next-col-offset-fixed-m-10[dir="rtl"] {
  margin-right: 200px;
  margin-left: auto;
}

.next-col-offset-fixed-m-11[dir="rtl"] {
  margin-right: 220px;
  margin-left: auto;
}

.next-col-offset-fixed-m-12[dir="rtl"] {
  margin-right: 240px;
  margin-left: auto;
}

.next-col-offset-fixed-m-13[dir="rtl"] {
  margin-right: 260px;
  margin-left: auto;
}

.next-col-offset-fixed-m-14[dir="rtl"] {
  margin-right: 280px;
  margin-left: auto;
}

.next-col-offset-fixed-m-15[dir="rtl"] {
  margin-right: 300px;
  margin-left: auto;
}

.next-col-offset-fixed-m-16[dir="rtl"] {
  margin-right: 320px;
  margin-left: auto;
}

.next-col-offset-fixed-m-17[dir="rtl"] {
  margin-right: 340px;
  margin-left: auto;
}

.next-col-offset-fixed-m-18[dir="rtl"] {
  margin-right: 360px;
  margin-left: auto;
}

.next-col-offset-fixed-m-19[dir="rtl"] {
  margin-right: 380px;
  margin-left: auto;
}

.next-col-offset-fixed-m-20[dir="rtl"] {
  margin-right: 400px;
  margin-left: auto;
}

.next-col-offset-fixed-m-21[dir="rtl"] {
  margin-right: 420px;
  margin-left: auto;
}

.next-col-offset-fixed-m-22[dir="rtl"] {
  margin-right: 440px;
  margin-left: auto;
}

.next-col-offset-fixed-m-23[dir="rtl"] {
  margin-right: 460px;
  margin-left: auto;
}

.next-col-offset-fixed-m-24[dir="rtl"] {
  margin-right: 480px;
  margin-left: auto;
}

.next-col-offset-fixed-m-25[dir="rtl"] {
  margin-right: 500px;
  margin-left: auto;
}

.next-col-offset-fixed-m-26[dir="rtl"] {
  margin-right: 520px;
  margin-left: auto;
}

.next-col-offset-fixed-m-27[dir="rtl"] {
  margin-right: 540px;
  margin-left: auto;
}

.next-col-offset-fixed-m-28[dir="rtl"] {
  margin-right: 560px;
  margin-left: auto;
}

.next-col-offset-fixed-m-29[dir="rtl"] {
  margin-right: 580px;
  margin-left: auto;
}

.next-col-offset-fixed-m-30[dir="rtl"] {
  margin-right: 600px;
  margin-left: auto;
}

.next-col-offset-fixed-l-1[dir="rtl"] {
  margin-right: 20px;
  margin-left: auto;
}

.next-col-offset-fixed-l-2[dir="rtl"] {
  margin-right: 40px;
  margin-left: auto;
}

.next-col-offset-fixed-l-3[dir="rtl"] {
  margin-right: 60px;
  margin-left: auto;
}

.next-col-offset-fixed-l-4[dir="rtl"] {
  margin-right: 80px;
  margin-left: auto;
}

.next-col-offset-fixed-l-5[dir="rtl"] {
  margin-right: 100px;
  margin-left: auto;
}

.next-col-offset-fixed-l-6[dir="rtl"] {
  margin-right: 120px;
  margin-left: auto;
}

.next-col-offset-fixed-l-7[dir="rtl"] {
  margin-right: 140px;
  margin-left: auto;
}

.next-col-offset-fixed-l-8[dir="rtl"] {
  margin-right: 160px;
  margin-left: auto;
}

.next-col-offset-fixed-l-9[dir="rtl"] {
  margin-right: 180px;
  margin-left: auto;
}

.next-col-offset-fixed-l-10[dir="rtl"] {
  margin-right: 200px;
  margin-left: auto;
}

.next-col-offset-fixed-l-11[dir="rtl"] {
  margin-right: 220px;
  margin-left: auto;
}

.next-col-offset-fixed-l-12[dir="rtl"] {
  margin-right: 240px;
  margin-left: auto;
}

.next-col-offset-fixed-l-13[dir="rtl"] {
  margin-right: 260px;
  margin-left: auto;
}

.next-col-offset-fixed-l-14[dir="rtl"] {
  margin-right: 280px;
  margin-left: auto;
}

.next-col-offset-fixed-l-15[dir="rtl"] {
  margin-right: 300px;
  margin-left: auto;
}

.next-col-offset-fixed-l-16[dir="rtl"] {
  margin-right: 320px;
  margin-left: auto;
}

.next-col-offset-fixed-l-17[dir="rtl"] {
  margin-right: 340px;
  margin-left: auto;
}

.next-col-offset-fixed-l-18[dir="rtl"] {
  margin-right: 360px;
  margin-left: auto;
}

.next-col-offset-fixed-l-19[dir="rtl"] {
  margin-right: 380px;
  margin-left: auto;
}

.next-col-offset-fixed-l-20[dir="rtl"] {
  margin-right: 400px;
  margin-left: auto;
}

.next-col-offset-fixed-l-21[dir="rtl"] {
  margin-right: 420px;
  margin-left: auto;
}

.next-col-offset-fixed-l-22[dir="rtl"] {
  margin-right: 440px;
  margin-left: auto;
}

.next-col-offset-fixed-l-23[dir="rtl"] {
  margin-right: 460px;
  margin-left: auto;
}

.next-col-offset-fixed-l-24[dir="rtl"] {
  margin-right: 480px;
  margin-left: auto;
}

.next-col-offset-fixed-l-25[dir="rtl"] {
  margin-right: 500px;
  margin-left: auto;
}

.next-col-offset-fixed-l-26[dir="rtl"] {
  margin-right: 520px;
  margin-left: auto;
}

.next-col-offset-fixed-l-27[dir="rtl"] {
  margin-right: 540px;
  margin-left: auto;
}

.next-col-offset-fixed-l-28[dir="rtl"] {
  margin-right: 560px;
  margin-left: auto;
}

.next-col-offset-fixed-l-29[dir="rtl"] {
  margin-right: 580px;
  margin-left: auto;
}

.next-col-offset-fixed-l-30[dir="rtl"] {
  margin-right: 600px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-1[dir="rtl"] {
  margin-right: 20px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-2[dir="rtl"] {
  margin-right: 40px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-3[dir="rtl"] {
  margin-right: 60px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-4[dir="rtl"] {
  margin-right: 80px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-5[dir="rtl"] {
  margin-right: 100px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-6[dir="rtl"] {
  margin-right: 120px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-7[dir="rtl"] {
  margin-right: 140px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-8[dir="rtl"] {
  margin-right: 160px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-9[dir="rtl"] {
  margin-right: 180px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-10[dir="rtl"] {
  margin-right: 200px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-11[dir="rtl"] {
  margin-right: 220px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-12[dir="rtl"] {
  margin-right: 240px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-13[dir="rtl"] {
  margin-right: 260px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-14[dir="rtl"] {
  margin-right: 280px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-15[dir="rtl"] {
  margin-right: 300px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-16[dir="rtl"] {
  margin-right: 320px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-17[dir="rtl"] {
  margin-right: 340px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-18[dir="rtl"] {
  margin-right: 360px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-19[dir="rtl"] {
  margin-right: 380px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-20[dir="rtl"] {
  margin-right: 400px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-21[dir="rtl"] {
  margin-right: 420px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-22[dir="rtl"] {
  margin-right: 440px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-23[dir="rtl"] {
  margin-right: 460px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-24[dir="rtl"] {
  margin-right: 480px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-25[dir="rtl"] {
  margin-right: 500px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-26[dir="rtl"] {
  margin-right: 520px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-27[dir="rtl"] {
  margin-right: 540px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-28[dir="rtl"] {
  margin-right: 560px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-29[dir="rtl"] {
  margin-right: 580px;
  margin-left: auto;
}

.next-col-offset-fixed-xl-30[dir="rtl"] {
  margin-right: 600px;
  margin-left: auto;
}

.next-responsive-grid {
  display: grid;
}

.next-responsive-grid,
.next-responsive-grid *,
.next-responsive-grid :after,
.next-responsive-grid :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-responsive-grid-ie {
  display: block;
}

.next-form,
.next-form *,
.next-form :after,
.next-form :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-form-preview.next-form-item.next-medium .next-form-item-label {
  font-size: 12px;
  font-size: var(--form-element-medium-preview-label-font-size, 12px);
  line-height: 16px;
  line-height: var(--form-element-medium-preview-height, 16px);
}

.next-form-preview.next-form-item.next-small .next-form-item-label {
  font-size: 12px;
  font-size: var(--form-element-small-preview-label-font-size, 12px);
  line-height: 16px;
  line-height: var(--form-element-small-preview-height, 16px);
}

.next-form-preview.next-form-item.next-large .next-form-item-label {
  font-size: 14px;
  font-size: var(--form-element-large-preview-label-font-size, 14px);
  line-height: 16px;
  line-height: var(--form-element-large-preview-height, 16px);
}

.next-form-responsive-grid .next-form-item-control {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  flex: 1;
}

.next-form-responsive-grid .next-form-item {
  margin-bottom: 0;
}

.next-form-responsive-grid .next-form-item.next-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.next-form-responsive-grid.next-small .next-responsive-grid {
  gap: 16px;
  gap: var(--form-inline-s-item-margin-r, 16px);
}

.next-form-responsive-grid.next-small
  .next-form-item.next-left
  .next-form-item-label {
  line-height: 1.4;
  margin-top: 6px;
  margin-top: calc(
    var(--form-element-small-height, 24px) / 2 -
      var(--form-element-small-font-size, 12px) / 2
  );
  margin-bottom: 6px;
  margin-bottom: calc(
    var(--form-element-small-height, 24px) / 2 -
      var(--form-element-small-font-size, 12px) / 2
  );
}

.next-form-responsive-grid.next-medium .next-responsive-grid {
  gap: 20px;
  gap: var(--form-inline-m-item-margin-r, 20px);
}

.next-form-responsive-grid.next-medium
  .next-form-item.next-left
  .next-form-item-label {
  line-height: 1.4;
  margin-top: 10px;
  margin-top: calc(
    var(--form-element-medium-height, 32px) / 2 -
      var(--form-element-medium-font-size, 12px) / 2
  );
  margin-bottom: 10px;
  margin-bottom: calc(
    var(--form-element-medium-height, 32px) / 2 -
      var(--form-element-medium-font-size, 12px) / 2
  );
}

.next-form-responsive-grid.next-large .next-responsive-grid {
  gap: 24px;
  gap: var(--form-inline-l-item-margin-r, 24px);
}

.next-form-responsive-grid.next-large
  .next-form-item.next-left
  .next-form-item-label {
  line-height: 1.4;
  margin-top: 11px;
  margin-top: calc(
    var(--form-element-large-height, 36px) / 2 -
      var(--form-element-large-font-size, 14px) / 2
  );
  margin-bottom: 11px;
  margin-bottom: calc(
    var(--form-element-large-height, 36px) / 2 -
      var(--form-element-large-font-size, 14px) / 2
  );
}

.next-form-item {
  margin-bottom: 16px;
  margin-bottom: var(--form-item-m-margin-b, 16px);
}

.next-form-item.has-error > .next-form-item-control > .next-form-item-help {
  color: #c80000;
  color: var(--form-error-color, #c80000);
}

.next-form-item.has-warning > .next-form-item-control > .next-form-item-help {
  color: #fff3b4;
  color: var(--form-warning-color, #fff3b4);
}

.next-form-item .next-form-item-label,
.next-form-item .next-form-text-align,
.next-form-item p {
  line-height: 32px;
  line-height: var(--form-element-medium-height, 32px);
}

.next-form-item .next-form-text-align,
.next-form-item p {
  margin: 0;
}

.next-form-item .next-checkbox-group,
.next-form-item .next-checkbox-wrapper,
.next-form-item .next-radio-group,
.next-form-item .next-radio-wrapper,
.next-form-item .next-rating {
  line-height: 28px;
  line-height: -webkit-calc(var(--form-element-medium-height, 32px) - 4px);
  line-height: -moz-calc(var(--form-element-medium-height, 32px) - 4px);
  line-height: calc(var(--form-element-medium-height, 32px) - 4px);
}

.next-form-item .next-form-preview {
  font-size: 12px;
  font-size: var(--form-element-medium-preview-font-size, 12px);
  line-height: 16px;
  line-height: var(--form-element-medium-preview-height, 16px);
}

.next-form-item .next-form-preview.next-input-textarea > p {
  font-size: 12px;
  font-size: var(--form-element-medium-preview-font-size, 12px);
  text-align: justify;
  min-height: 16.8px;
  min-height: -webkit-calc(
    var(--form-element-medium-preview-font-size, 12px) * 1.4
  );
  min-height: -moz-calc(
    var(--form-element-medium-preview-font-size, 12px) * 1.4
  );
  min-height: calc(var(--form-element-medium-preview-font-size, 12px) * 1.4);
  line-height: 1.4;
  margin-top: -0.4px;
  margin-top: calc(
    var(--form-element-medium-preview-height, 16px) / 2 -
      var(--form-element-medium-preview-font-size, 12px) * 1.4 / 2
  );
}

.next-form-item .next-form-item-label {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-form-item.next-large {
  margin-bottom: 16px;
  margin-bottom: var(--form-item-l-margin-b, 16px);
}

.next-form-item.next-large .next-form-item-label,
.next-form-item.next-large .next-form-text-align,
.next-form-item.next-large p {
  line-height: 36px;
  line-height: var(--form-element-large-height, 36px);
}

.next-form-item.next-large .next-checkbox-group,
.next-form-item.next-large .next-checkbox-wrapper,
.next-form-item.next-large .next-radio-group,
.next-form-item.next-large .next-radio-wrapper,
.next-form-item.next-large .next-rating {
  line-height: 35px;
  line-height: -webkit-calc(var(--form-element-large-height, 36px) - 1px);
  line-height: -moz-calc(var(--form-element-large-height, 36px) - 1px);
  line-height: calc(var(--form-element-large-height, 36px) - 1px);
}

.next-form-item.next-large .next-form-preview {
  font-size: 14px;
  font-size: var(--form-element-large-preview-font-size, 14px);
  line-height: 16px;
  line-height: var(--form-element-large-preview-height, 16px);
}

.next-form-item.next-large .next-form-preview.next-input-textarea > p {
  font-size: 14px;
  font-size: var(--form-element-large-preview-font-size, 14px);
  text-align: justify;
  min-height: 19.6px;
  min-height: -webkit-calc(
    var(--form-element-large-preview-font-size, 14px) * 1.4
  );
  min-height: -moz-calc(
    var(--form-element-large-preview-font-size, 14px) * 1.4
  );
  min-height: calc(var(--form-element-large-preview-font-size, 14px) * 1.4);
  line-height: 1.4;
  margin-top: -1.8px;
  margin-top: calc(
    var(--form-element-large-preview-height, 16px) / 2 -
      var(--form-element-large-preview-font-size, 14px) * 1.4 / 2
  );
}

.next-form-item.next-large .next-switch {
  margin-top: 5px;
  margin-top: calc(var(--form-element-large-height, 36px) / 2 - 13px);
}

.next-form-item.next-large .next-form-item-label {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-form-item.next-small {
  margin-bottom: 16px;
  margin-bottom: var(--form-item-s-margin-b, 16px);
}

.next-form-item.next-small .next-checkbox-group,
.next-form-item.next-small .next-checkbox-wrapper,
.next-form-item.next-small .next-form-item-label,
.next-form-item.next-small .next-form-text-align,
.next-form-item.next-small .next-radio-group,
.next-form-item.next-small .next-radio-wrapper,
.next-form-item.next-small .next-rating,
.next-form-item.next-small p {
  line-height: 24px;
  line-height: var(--form-element-small-height, 24px);
}

.next-form-item.next-small .next-form-preview {
  font-size: 12px;
  font-size: var(--form-element-small-preview-font-size, 12px);
  line-height: 16px;
  line-height: var(--form-element-small-preview-height, 16px);
}

.next-form-item.next-small .next-form-preview.next-input-textarea > p {
  font-size: 12px;
  font-size: var(--form-element-small-preview-font-size, 12px);
  text-align: justify;
  min-height: 16.8px;
  min-height: -webkit-calc(
    var(--form-element-small-preview-font-size, 12px) * 1.4
  );
  min-height: -moz-calc(
    var(--form-element-small-preview-font-size, 12px) * 1.4
  );
  min-height: calc(var(--form-element-small-preview-font-size, 12px) * 1.4);
  line-height: 1.4;
  margin-top: -0.4px;
  margin-top: calc(
    var(--form-element-small-preview-height, 16px) / 2 -
      var(--form-element-small-preview-font-size, 12px) * 1.4 / 2
  );
}

.next-form-item.next-small .next-form-item-label {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-form-item.next-top > .next-form-item-label {
  margin-bottom: 2px;
  margin-bottom: var(--form-top-label-margin-b, 2px);
}

.next-form-item.next-inset .next-form-item-label {
  padding-right: 0;
  padding-left: 0;
  line-height: inherit;
}

.next-form-item-control .next-form-text-align {
  margin: 0;
}

.next-form-item-control > .next-input,
.next-form-item-control > .next-input-group,
.next-form-item-fullwidth .next-form-item-control > .next-date-picker,
.next-form-item-fullwidth .next-form-item-control > .next-input,
.next-form-item-fullwidth .next-form-item-control > .next-input-group,
.next-form-item-fullwidth .next-form-item-control > .next-month-picker,
.next-form-item-fullwidth .next-form-item-control > .next-range-picker,
.next-form-item-fullwidth .next-form-item-control > .next-select,
.next-form-item-fullwidth .next-form-item-control > .next-time-picker,
.next-form-item-fullwidth .next-form-item-control > .next-year-picker {
  width: 100%;
}

.next-form-item-label {
  display: inline-block;
  vertical-align: top;
  color: #555;
  color: var(--form-label-color, #555);
  text-align: right;
  padding-right: 12px;
  padding-right: var(--form-label-padding-r, 12px);
}

.next-form-item-label label[required]:before {
  margin-right: 4px;
  content: "*";
  color: #c80000;
  color: var(--form-error-color, #c80000);
}

.next-form-item-label.has-colon label:after {
  content: ":";
  position: relative;
  top: -0.5px;
  margin: 0 0 0 2px;
}

.next-form-item-label.next-left {
  text-align: left;
}

.next-form-item-label.next-left > label[required]:before {
  display: none;
}

.next-form-item-label.next-left > label[required]:after {
  margin-left: 4px;
  content: "*";
  color: #c80000;
  color: var(--form-error-color, #c80000);
}

.next-form-item-help {
  margin-top: 4px;
  margin-top: var(--form-help-margin-top, 4px);
  font-size: 12px;
  font-size: var(--form-help-font-size, 12px);
  line-height: 1.5;
  line-height: var(--font-lineheight-2, 1.5);
  color: #888;
  color: var(--form-help-color, #888);
}

.next-form.next-inline .next-form-item {
  display: inline-block;
  vertical-align: top;
}

.next-form.next-inline .next-form-item.next-left .next-form-item-control {
  display: inline-block;
  vertical-align: top;
  line-height: 0;
}

.next-form.next-inline .next-form-item:not(:last-child) {
  margin-right: 20px;
  margin-right: var(--form-inline-m-item-margin-r, 20px);
}

.next-form.next-inline .next-form-item.next-large:not(:last-child) {
  margin-right: 24px;
  margin-right: var(--form-inline-l-item-margin-r, 24px);
}

.next-form.next-inline .next-form-item.next-small:not(:last-child) {
  margin-right: 16px;
  margin-right: var(--form-inline-s-item-margin-r, 16px);
}

@media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
  .next-form-item.next-left > .next-form-item-label,
  .next-form.next-inline .next-form-item.next-left .next-form-item-control {
    display: table-cell;
  }
}

.next-form[dir="rtl"] .next-form-item-label {
  text-align: left;
  padding-left: 12px;
  padding-left: var(--form-label-padding-r, 12px);
  padding-right: 0;
}

.next-form[dir="rtl"].next-inline .next-form-item:not(:last-child) {
  margin-left: 20px;
  margin-left: var(--form-inline-m-item-margin-r, 20px);
  margin-right: 0;
}

.next-form[dir="rtl"].next-inline .next-form-item.next-large:not(:last-child) {
  margin-left: 24px;
  margin-left: var(--form-inline-l-item-margin-r, 24px);
  margin-right: 0;
}

.next-form[dir="rtl"].next-inline .next-form-item.next-small:not(:last-child) {
  margin-left: 16px;
  margin-left: var(--form-inline-s-item-margin-r, 16px);
  margin-right: 0;
}

.next-avatar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #004080;
  background: var(--color-fill1-4, #004080);
  width: 40px;
  width: var(--avatar-size-medium, 40px);
  height: 40px;
  height: var(--avatar-size-medium, 40px);
  line-height: 40px;
  line-height: var(--avatar-size-medium, 40px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.next-avatar-image {
  background: transparent;
}

.next-avatar-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  -moz-transform-origin: 0 center;
  -o-transform-origin: 0 center;
  transform-origin: 0 center;
}

.next-avatar-large {
  width: 52px;
  width: var(--avatar-size-large, 52px);
  height: 52px;
  height: var(--avatar-size-large, 52px);
  line-height: 52px;
  line-height: var(--avatar-size-large, 52px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.next-avatar-large-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  -moz-transform-origin: 0 center;
  -o-transform-origin: 0 center;
  transform-origin: 0 center;
}

.next-avatar-small {
  width: 28px;
  width: var(--avatar-size-small, 28px);
  height: 28px;
  height: var(--avatar-size-small, 28px);
  line-height: 28px;
  line-height: var(--avatar-size-small, 28px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.next-avatar-small-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  -moz-transform-origin: 0 center;
  -o-transform-origin: 0 center;
  transform-origin: 0 center;
}

.next-avatar-square {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--avatar-border-radius, 2px);
  -moz-border-radius: var(--avatar-border-radius, 2px);
  border-radius: var(--avatar-border-radius, 2px);
}

.next-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.next-loading-fusion-reactor[dir="rtl"] {
  -webkit-animation-name: nextVectorRouteRTL;
  -moz-animation-name: nextVectorRouteRTL;
  -o-animation-name: nextVectorRouteRTL;
  animation-name: nextVectorRouteRTL;
}

@-webkit-keyframes nextVectorRouteRTL {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  25% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  30% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  50% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  55% {
    -webkit-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }

  75% {
    -webkit-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }

  80% {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }

  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

@-moz-keyframes nextVectorRouteRTL {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  25% {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  30% {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  50% {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  55% {
    -webkit-transform: rotate(-270deg);
    -moz-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }

  75% {
    -webkit-transform: rotate(-270deg);
    -moz-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }

  80% {
    -webkit-transform: rotate(-1turn);
    -moz-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }

  to {
    -webkit-transform: rotate(-1turn);
    -moz-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

@-o-keyframes nextVectorRouteRTL {
  0% {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  25% {
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  30% {
    -webkit-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  50% {
    -webkit-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  55% {
    -webkit-transform: rotate(-270deg);
    -o-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }

  75% {
    -webkit-transform: rotate(-270deg);
    -o-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }

  80% {
    -webkit-transform: rotate(-1turn);
    -o-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }

  to {
    -webkit-transform: rotate(-1turn);
    -o-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

@keyframes nextVectorRouteRTL {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  25% {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  30% {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  50% {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  55% {
    -webkit-transform: rotate(-270deg);
    -moz-transform: rotate(-270deg);
    -o-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }

  75% {
    -webkit-transform: rotate(-270deg);
    -moz-transform: rotate(-270deg);
    -o-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }

  80% {
    -webkit-transform: rotate(-1turn);
    -moz-transform: rotate(-1turn);
    -o-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }

  to {
    -webkit-transform: rotate(-1turn);
    -moz-transform: rotate(-1turn);
    -o-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

.next-list-header {
  border-bottom: 1px solid #c0c6cc;
  border-bottom: var(--line-1, 1px) solid var(--list-divider-color, #c0c6cc);
  color: #111;
  color: var(--list-title-color, #111);
}

.next-list-footer {
  border-top: 1px solid #c0c6cc;
  border-top: var(--line-1, 1px) solid var(--list-divider-color, #c0c6cc);
  color: #555;
  color: var(--list-content-color, #555);
}

.next-list-loading.next-loading {
  display: block;
}

.next-list-empty {
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  color: #d1d5d9;
  color: var(--color-line1-4, #d1d5d9);
  padding: 32px 0;
  text-align: center;
}

.next-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.next-list-item {
  display: table;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  width: 100%;
  color: #555;
  color: var(--list-content-color, #555);
}

.next-list-item-extra,
.next-list-item-media {
  display: table-cell;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  justify-content: flex-start;
  min-width: 1px;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  vertical-align: top;
}

.next-list-item-extra {
  color: #888;
  color: var(--list-extra-color, #888);
}

.next-list-item-content {
  display: table-cell;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  flex: 1;
  width: 100%;
  vertical-align: middle;
}

.next-list-item-title {
  color: #111;
  color: var(--list-title-color, #111);
}

.next-list-medium .next-list-header {
  font-size: 16px;
  font-size: var(--list-size-m-title-font-size, 16px);
  font-weight: 700;
  font-weight: var(--list-size-m-title-font-weight, bold);
}

.next-list-medium .next-list-footer,
.next-list-medium .next-list-header {
  padding: 16px 0;
  padding: var(--list-size-m-item-padding-tb, 16px)
    var(--list-size-m-item-padding-lr, 0);
}

.next-list-medium .next-list-item-media {
  padding-right: 8px;
  padding-right: var(--list-size-m-item-media-margin, 8px);
}

.next-list-medium .next-list-item-extra {
  padding-left: 8px;
  padding-left: var(--list-size-m-item-media-margin, 8px);
}

.next-list-medium .next-list-item {
  font-size: 14px;
  font-size: var(--list-size-m-item-content-font-size, 14px);
  line-height: 1.5;
  line-height: var(--list-size-m-item-content-line-height, 1.5);
  padding: 16px 0;
  padding: var(--list-size-m-item-padding-tb, 16px)
    var(--list-size-m-item-padding-lr, 0);
}

.next-list-medium .next-list-item-title {
  font-weight: 400;
  font-weight: var(--list-size-m-item-title-font-weight, normal);
  font-size: 14px;
  font-size: var(--list-size-m-item-title-font-size, 14px);
  line-height: 1.5;
  line-height: var(--list-size-m-item-title-line-height, 1.5);
}

.next-list-small .next-list-header {
  font-size: 14px;
  font-size: var(--list-size-s-title-font-size, 14px);
  font-weight: 700;
  font-weight: var(--list-size-s-title-font-weight, bold);
}

.next-list-small .next-list-footer,
.next-list-small .next-list-header {
  padding: 8px 0;
  padding: var(--list-size-s-item-padding-tb, 8px)
    var(--list-size-s-item-padding-lr, 0);
}

.next-list-small .next-list-item-media {
  padding-right: 8px;
  padding-right: var(--list-size-s-item-media-margin, 8px);
}

.next-list-small .next-list-item-extra {
  padding-left: 8px;
  padding-left: var(--list-size-s-item-media-margin, 8px);
}

.next-list-small .next-list-item {
  font-size: 12px;
  font-size: var(--list-size-s-item-content-font-size, 12px);
  font-weight: 400;
  font-weight: var(--list-size-s-item-title-font-weight, normal);
  line-height: 1;
  line-height: var(--list-size-s-item-content-line-height, 1);
  padding: 8px 0;
  padding: var(--list-size-s-item-padding-tb, 8px)
    var(--list-size-s-item-padding-lr, 0);
}

.next-list-small .next-list-item-title {
  font-size: 14px;
  font-size: var(--list-size-s-item-title-font-size, 14px);
  line-height: 1.5;
  line-height: var(--list-size-s-item-title-line-height, 1.5);
}

.next-list-divider .next-list-item {
  border-bottom: 1px solid #c0c6cc;
  border-bottom: var(--line-1, 1px) solid var(--list-divider-color, #c0c6cc);
}

.next-list-divider .next-list-item:last-child {
  border-bottom: none;
}

.next-list[dir="rtl"] .next-list-item-media {
  padding-left: 8px;
  padding-left: var(--list-size-m-item-media-margin, 8px);
  padding-right: 0;
}

.next-list[dir="rtl"] .next-list-item-extra {
  padding-right: 8px;
  padding-right: var(--list-size-m-item-media-margin, 8px);
  padding-left: 0;
}

.next-list[dir="rtl"] .next-list-small .next-list-item-media {
  padding-left: 8px;
  padding-left: var(--list-size-s-item-media-margin, 8px);
  padding-right: 0;
}

.next-list[dir="rtl"] .next-list-small .next-list-item-extra {
  padding-right: 8px;
  padding-right: var(--list-size-s-item-media-margin, 8px);
  padding-left: 0;
}

.next-menu-btn {
  display: inline-block;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-menu-btn .next-icon {
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: -o-transform 0.1s linear;
  -moz-transition: transform 0.1s linear, -moz-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear,
    -moz-transform 0.1s linear, -o-transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
}

.next-menu-btn .next-menu-btn-arrow:before {
  content: "\E63D";
  content: var(--menu-btn-fold-icon-content, "\E63D");
}

.next-menu-btn.next-expand .next-menu-btn-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.next-menu-btn-symbol-unfold:before {
  content: "";
  content: var(--menu-btn-unfold-icon-content, "");
}

.next-menu-btn.next-btn-normal .next-menu-btn-arrow {
  color: #888;
  color: var(--menu-btn-pure-text-normal-icon-color, #888);
}

.next-menu-btn.next-btn-normal:hover .next-menu-btn-arrow {
  color: #333;
  color: var(--btn-pure-normal-color-hover, #333);
}

.next-menu-btn.next-btn-secondary .next-menu-btn-arrow {
  color: #0064c8;
  color: var(--menu-btn-pure-text-secondary-icon-color, #0064c8);
}

.next-menu-btn.next-btn-secondary:hover .next-menu-btn-arrow {
  color: #333;
  color: var(--btn-pure-secondary-color-hover, #333);
}

.next-menu-btn.next-btn-secondary.next-btn-text:hover .next-menu-btn-arrow {
  color: #333;
  color: var(--btn-text-secondary-color-hover, #333);
}

.next-menu-btn.next-btn-primary .next-menu-btn-arrow {
  color: #fff;
  color: var(--menu-btn-pure-text-primary-icon-color, #fff);
}

.next-menu-btn.next-btn-primary:hover .next-menu-btn-arrow {
  color: #fff;
  color: var(--btn-pure-primary-color-hover, #fff);
}

.next-menu-btn.next-btn-text.next-btn-normal .next-menu-btn-arrow {
  color: #111;
  color: var(--menu-btn-text-text-normal-icon-color, #111);
}

.next-menu-btn.next-btn-text.next-btn-normal:hover .next-menu-btn-arrow {
  color: #0064c8;
  color: var(--btn-text-normal-color-hover, #0064c8);
}

.next-menu-btn.next-btn-text.next-btn-primary .next-menu-btn-arrow {
  color: #0064c8;
  color: var(--menu-btn-text-text-primary-icon-color, #0064c8);
}

.next-menu-btn.next-btn-text.next-btn-primary:hover .next-menu-btn-arrow {
  color: #0064c8;
  color: var(--btn-text-primary-color-hover, #0064c8);
}

.next-menu-btn.next-btn-ghost.next-btn-light .next-menu-btn-arrow {
  color: #0064c8;
  color: var(--menu-btn-ghost-light-icon-color, #0064c8);
}

.next-menu-btn.next-btn-ghost.next-btn-light:hover .next-menu-btn-arrow {
  color: #fff;
  color: var(--btn-ghost-light-color-hover, #fff);
}

.next-menu-btn.next-btn-ghost.next-btn-dark .next-menu-btn-arrow {
  color: #fff;
  color: var(--menu-btn-ghost-dark-icon-color, #fff);
}

.next-menu-btn.next-btn-ghost.next-btn-dark:hover .next-menu-btn-arrow {
  color: #fff;
  color: var(--btn-ghost-dark-color-hover, #fff);
}

.next-menu-btn.disabled .next-menu-btn-arrow,
.next-menu-btn.next-btn-text.disabled .next-menu-btn-arrow,
.next-menu-btn.next-btn-text[disabled] .next-menu-btn-arrow,
.next-menu-btn[disabled] .next-menu-btn-arrow {
  color: #c1c1c1;
  color: var(--menu-btn-disabled-icon-color, #c1c1c1);
}

.next-menu-btn[disabled].next-btn-ghost.next-btn-dark .next-menu-btn-arrow {
  color: hsla(0, 0%, 100%, 0.25);
  color: var(
    --menu-btn-ghost-dark-disabled-icon-color,
    hsla(0, 0%, 100%, 0.25)
  );
}

.next-menu-btn[disabled].next-btn-ghost.next-btn-light .next-menu-btn-arrow {
  color: #c1c1c1;
  color: var(--menu-btn-ghost-light-disabled-icon-color, #c1c1c1);
}

.next-nav {
  min-width: auto;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.next-nav,
.next-nav *,
.next-nav :after,
.next-nav :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-nav-icon.next-icon {
  margin-right: 4px;
  font-weight: inherit;
}

.next-nav-icon.next-icon .next-icon-remote,
.next-nav-icon.next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-nav-group-label {
  height: 40px;
  height: var(--nav-group-height, 40px);
  line-height: 40px;
  line-height: var(--nav-group-height, 40px);
  font-size: 14px;
  font-size: var(--nav-group-font-size, 14px);
}

.next-nav-item .next-menu-item-text > span,
.next-nav-item .next-nav-group-label > span {
  opacity: 1;
  -webkit-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}

.next-nav-item .next-menu-item-text > a {
  text-decoration: none;
  color: inherit;
}

.next-nav-item.next-focused .next-menu-hoz-icon-arrow.next-icon,
.next-nav-item.next-focused .next-menu-icon-arrow.next-icon,
.next-nav-item .next-menu-hoz-icon-arrow.next-icon,
.next-nav-item .next-menu-icon-arrow.next-icon,
.next-nav-item.next-opened .next-menu-hoz-icon-arrow.next-icon,
.next-nav-item.next-opened .next-menu-icon-arrow.next-icon,
.next-nav-item.next-selected .next-menu-hoz-icon-arrow.next-icon,
.next-nav-item.next-selected .next-menu-icon-arrow.next-icon,
.next-nav-item:hover .next-menu-hoz-icon-arrow.next-icon,
.next-nav-item:hover .next-menu-icon-arrow.next-icon {
  color: inherit;
  top: 0;
  -webkit-transform-origin: center 50%;
  -moz-transform-origin: center 50%;
  -o-transform-origin: center 50%;
  transform-origin: center 50%;
}

.next-nav.next-active .next-nav-item:before {
  position: absolute;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  content: "";
}

.next-nav.next-hoz {
  padding: 0;
  height: 44px;
  height: -webkit-calc(
    var(--nav-hoz-height, 44px) + var(--nav-hoz-item-margin-tb, 0) * 2
  );
  height: -moz-calc(
    var(--nav-hoz-height, 44px) + var(--nav-hoz-item-margin-tb, 0) * 2
  );
  height: calc(
    var(--nav-hoz-height, 44px) + var(--nav-hoz-item-margin-tb, 0px) * 2
  );
  line-height: 42px;
  line-height: -webkit-calc(
    var(--nav-hoz-height, 44px) - var(--popup-local-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--nav-hoz-height, 44px) - var(--popup-local-border-width, 1px) * 2
  );
  line-height: calc(
    var(--nav-hoz-height, 44px) - var(--popup-local-border-width, 1px) * 2
  );
  font-size: 12px;
  font-size: var(--nav-hoz-font-size, 12px);
}

.next-nav.next-hoz .next-menu-item {
  margin-left: 0;
  margin-left: var(--nav-hoz-item-margin-lr, 0);
  margin-right: 0;
  margin-right: var(--nav-hoz-item-margin-lr, 0);
  padding: 0 20px;
  padding: 0 var(--nav-hoz-item-padding-lr, 20px);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--nav-hoz-item-corner, 0);
  -moz-border-radius: var(--nav-hoz-item-corner, 0);
  border-radius: var(--nav-hoz-item-corner, 0);
}

.next-nav.next-hoz .next-menu-item,
.next-nav.next-hoz .next-menu-sub-menu-wrapper > .next-menu-item {
  margin-top: 0;
  margin-top: var(--nav-hoz-item-margin-tb, 0);
  margin-bottom: 0;
  margin-bottom: var(--nav-hoz-item-margin-tb, 0);
}

.next-nav.next-hoz .next-menu-item-inner {
  height: 42px;
  height: -webkit-calc(
    var(--nav-hoz-height, 44px) - var(--popup-local-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--nav-hoz-height, 44px) - var(--popup-local-border-width, 1px) * 2
  );
  height: calc(
    var(--nav-hoz-height, 44px) - var(--popup-local-border-width, 1px) * 2
  );
  font-size: 12px;
  font-size: var(--nav-hoz-font-size, 12px);
}

.next-nav.next-hoz .next-nav-group-label .next-menu-item-inner {
  height: 40px;
  height: var(--nav-group-height, 40px);
  line-height: 40px;
  line-height: var(--nav-group-height, 40px);
  font-size: 14px;
  font-size: var(--nav-group-font-size, 14px);
}

.next-nav.next-hoz .next-menu-header {
  float: left;
}

.next-nav.next-hoz .next-menu-footer,
.next-nav.next-hoz .next-menu-header {
  height: 42px;
  height: -webkit-calc(
    var(--nav-hoz-height, 44px) - var(--popup-local-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--nav-hoz-height, 44px) - var(--popup-local-border-width, 1px) * 2
  );
  height: calc(
    var(--nav-hoz-height, 44px) - var(--popup-local-border-width, 1px) * 2
  );
}

.next-nav.next-hoz .next-menu-footer {
  float: right;
}

.next-nav.next-hoz .next-nav-item:before {
  width: 0;
  left: 50%;
  height: 2px;
  height: var(--nav-hoz-item-selected-active-line, 2px);
}

.next-nav.next-hoz .next-nav-item:hover:before {
  height: 0;
  height: var(--nav-hoz-item-hover-active-line, 0);
}

.next-nav.next-hoz.next-top .next-nav-item:before {
  top: -1px;
  top: -webkit-calc(0 - var(--popup-local-border-width, 1px));
  top: -moz-calc(0 - var(--popup-local-border-width, 1px));
  top: calc(0px - var(--popup-local-border-width, 1px));
}

.next-nav.next-hoz.next-bottom .next-nav-item:before {
  bottom: -1px;
  bottom: -webkit-calc(0 - var(--popup-local-border-width, 1px));
  bottom: -moz-calc(0 - var(--popup-local-border-width, 1px));
  bottom: calc(0px - var(--popup-local-border-width, 1px));
}

.next-nav.next-hoz .next-selected.next-nav-item:before {
  width: 100%;
  left: 0;
  height: 2px;
  height: var(--nav-hoz-item-selected-active-line, 2px);
}

.next-nav.next-ver {
  padding: 0;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  transition: width 0.3s ease;
  line-height: 40px;
  line-height: var(--nav-ver-height, 40px);
  font-size: 12px;
  font-size: var(--nav-ver-font-size, 12px);
}

.next-nav.next-ver .next-menu-item {
  margin-left: 0;
  margin-left: var(--nav-ver-item-margin-lr, 0);
  margin-right: 0;
  margin-right: var(--nav-ver-item-margin-lr, 0);
  padding: 0 20px;
  padding: 0 var(--nav-ver-item-padding-lr, 20px);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--nav-ver-item-corner, 0);
  -moz-border-radius: var(--nav-ver-item-corner, 0);
  border-radius: var(--nav-ver-item-corner, 0);
  padding: 0 24px;
}

.next-nav.next-ver .next-menu-item:not(:first-child),
.next-nav.next-ver
  .next-menu-sub-menu-wrapper:not(:first-child)
  > .next-menu-item {
  margin-top: 0;
  margin-top: var(--nav-ver-item-margin-tb, 0);
}

.next-nav.next-ver .next-menu-item:not(:last-child),
.next-nav.next-ver
  .next-menu-sub-menu-wrapper:not(:last-child)
  > .next-menu-item {
  margin-bottom: 0;
  margin-bottom: var(--nav-ver-item-margin-tb, 0);
}

.next-nav.next-ver .next-menu-item-inner {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.next-nav.next-ver .next-nav-group-label .next-menu-item-inner {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.next-nav.next-ver > .next-menu-item:first-child,
.next-nav.next-ver > .next-menu-sub-menu-wrapper:first-child > .next-menu-item {
  margin-top: 0;
  margin-top: var(--nav-ver-item-margin-tb, 0);
}

.next-nav.next-ver > .next-menu-item:last-child,
.next-nav.next-ver > .next-menu-sub-menu-wrapper:last-child > .next-menu-item {
  margin-bottom: 0;
  margin-bottom: var(--nav-ver-item-margin-tb, 0);
}

.next-nav.next-ver .next-menu-sub-menu {
  line-height: 40px;
  line-height: var(--nav-ver-sub-nav-height, 40px);
}

.next-nav.next-ver .next-menu-sub-menu .next-menu-item-inner {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.next-nav.next-ver .next-nav-item:before {
  height: 0;
  top: 50%;
  width: 2px;
  width: var(--nav-ver-item-selected-active-line, 2px);
}

.next-nav.next-ver .next-nav-item:hover:before {
  width: 0;
  width: var(--nav-ver-item-hover-active-line, 0);
}

.next-nav.next-ver.next-left .next-nav-item:before {
  left: -1px;
  left: -webkit-calc(0 - var(--popup-local-border-width, 1px));
  left: -moz-calc(0 - var(--popup-local-border-width, 1px));
  left: calc(0px - var(--popup-local-border-width, 1px));
}

.next-nav.next-ver.next-right .next-nav-item:before {
  right: 0px;
}

.next-nav.next-ver .next-selected.next-nav-item:before {
  height: 100%;
  top: 0;
  width: 2px;
  width: var(--nav-ver-item-selected-active-line, 2px);
}

.next-nav.next-primary {
  border-width: 0;
  border-width: var(--nav-primary-border-width, 0);
  background-color: #111;
  background-color: var(--nav-primary-bg-color, #111);
  border-color: #111;
  border-color: var(--nav-primary-border-color, #111);
  color: #fff;
  color: var(--nav-primary-text-color, #fff);
  font-weight: 400;
  font-weight: var(--nav-primary-text-style, normal);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --nav-primary-shadow,
    0 4px 8px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--nav-primary-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--nav-primary-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
}

.next-nav.next-primary.next-hoz {
  line-height: var(--nav-hoz-height, 44px);
  line-height: 44px;
  line-height: -webkit-calc(
    var(--nav-hoz-height, 44px) - var(--nav-primary-border-width, 0) * 2
  );
  line-height: -moz-calc(
    var(--nav-hoz-height, 44px) - var(--nav-primary-border-width, 0) * 2
  );
  line-height: calc(
    var(--nav-hoz-height, 44px) - var(--nav-primary-border-width, 0px) * 2
  );
}

.next-nav.next-primary.next-hoz .next-menu-footer,
.next-nav.next-primary.next-hoz .next-menu-header,
.next-nav.next-primary.next-hoz .next-menu-item-inner {
  line-height: 44px;
  line-height: var(--nav-hoz-height, 44px);
  height: 44px;
  height: -webkit-calc(
    var(--nav-hoz-height, 44px) - var(--nav-primary-border-width, 0) * 2
  );
  height: -moz-calc(
    var(--nav-hoz-height, 44px) - var(--nav-primary-border-width, 0) * 2
  );
  height: calc(
    var(--nav-hoz-height, 44px) - var(--nav-primary-border-width, 0px) * 2
  );
}

.next-nav.next-primary.next-hoz.next-top .next-nav-item:before {
  top: 0;
  top: -webkit-calc(0 - var(--nav-primary-border-width, 0));
  top: -moz-calc(0 - var(--nav-primary-border-width, 0));
  top: calc(0px - var(--nav-primary-border-width, 0px));
}

.next-nav.next-primary.next-hoz.next-bottom .next-nav-item:before {
  bottom: 0;
  bottom: -webkit-calc(0 - var(--nav-primary-border-width, 0));
  bottom: -moz-calc(0 - var(--nav-primary-border-width, 0));
  bottom: calc(0px - var(--nav-primary-border-width, 0px));
}

.next-nav.next-primary.next-ver.next-left .next-nav-item:before {
  left: 0;
  left: -webkit-calc(0 - var(--nav-primary-border-width, 0));
  left: -moz-calc(0 - var(--nav-primary-border-width, 0));
  left: calc(0px - var(--nav-primary-border-width, 0px));
}

.next-nav.next-primary.next-ver.next-right .next-nav-item:before {
  right: 0;
  right: -webkit-calc(0 - var(--nav-primary-border-width, 0));
  right: -moz-calc(0 - var(--nav-primary-border-width, 0));
  right: calc(0px - var(--nav-primary-border-width, 0px));
}

.next-nav.next-primary .next-nav-item.next-menu-item {
  background-color: #111;
  background-color: var(--nav-primary-bg-color, #111);
  color: #fff;
  color: var(--nav-primary-text-color, #fff);
}

.next-nav.next-primary .next-nav-item.next-menu-item.next-focused,
.next-nav.next-primary .next-nav-item.next-menu-item:hover {
  background-color: #000;
  background-color: var(--nav-primary-item-hover-bg-color, #000);
  color: #fff;
  color: var(--nav-primary-item-hover-text-color, #fff);
  font-weight: 400;
  font-weight: var(--nav-primary-item-hover-text-style, normal);
}

.next-nav.next-primary .next-nav-item.next-menu-item.next-selected {
  background-color: #000;
  background-color: var(--nav-primary-item-selected-bg-color, #000);
  color: #fff;
  color: var(--nav-primary-item-selected-text-color, #fff);
  font-weight: 700;
  font-weight: var(--nav-primary-item-selected-text-style, bold);
}

.next-nav.next-primary .next-nav-item.next-menu-item.next-child-selected,
.next-nav.next-primary .next-nav-item.next-menu-item.next-opened {
  background-color: #000;
  background-color: var(--nav-primary-item-opened-bg-color, #000);
  color: #fff;
  color: var(--nav-primary-item-opened-text-color, #fff);
}

.next-nav.next-primary .next-nav-item.next-menu-item:before {
  background-color: #0064c8;
  background-color: var(--nav-primary-item-selected-active-color, #0064c8);
}

.next-nav.next-primary .next-nav-item.next-menu-item:hover:before {
  background-color: #0064c8;
  background-color: var(--nav-primary-item-hover-active-color, #0064c8);
}

.next-nav.next-primary .next-menu-sub-menu .next-menu-item.next-opened {
  background-color: #000;
  background-color: var(--nav-primary-item-opened-bg-color, #000);
  color: #fff;
  color: var(--nav-primary-item-opened-text-color, #fff);
}

.next-nav.next-primary .next-nav-group-label {
  color: #888;
  color: var(--nav-primary-group-text-color, #888);
  font-weight: 400;
  font-weight: var(--nav-primary-group-text-style, normal);
}

.next-nav.next-primary .next-menu-sub-menu .next-menu-item {
  background-color: #111;
  background-color: var(--nav-primary-sub-nav-bg-color, #111);
  color: #fff;
  color: var(--nav-primary-sub-nav-text-color, #fff);
  font-weight: 400;
  font-weight: var(--nav-primary-sub-nav-text-style, normal);
}

.next-nav.next-primary .next-menu-sub-menu .next-menu-item.next-focused,
.next-nav.next-primary .next-menu-sub-menu .next-menu-item:hover {
  background-color: #000;
  background-color: var(--nav-primary-sub-nav-hover-bg-color, #000);
  color: #fff;
  color: var(--nav-primary-sub-nav-hover-text-color, #fff);
}

.next-nav.next-primary .next-menu-sub-menu .next-menu-item.next-selected {
  background-color: #000;
  background-color: var(--nav-primary-sub-nav-selected-bg-color, #000);
  color: #fff;
  color: var(--nav-primary-sub-nav-selected-text-color, #fff);
}

.next-nav.next-secondary {
  border-width: 0;
  border-width: var(--nav-secondary-border-width, 0);
  background-color: #0064c8;
  background-color: var(--nav-secondary-bg-color, #0064c8);
  border-color: #0064c8;
  border-color: var(--nav-secondary-border-color, #0064c8);
  color: #fff;
  color: var(--nav-secondary-text-color, #fff);
  font-weight: 400;
  font-weight: var(--nav-secondary-text-style, normal);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --nav-secondary-shadow,
    0 4px 8px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--nav-secondary-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--nav-secondary-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
}

.next-nav.next-secondary.next-hoz {
  line-height: var(--nav-hoz-height, 44px);
  line-height: 44px;
  line-height: -webkit-calc(
    var(--nav-hoz-height, 44px) - var(--nav-secondary-border-width, 0) * 2
  );
  line-height: -moz-calc(
    var(--nav-hoz-height, 44px) - var(--nav-secondary-border-width, 0) * 2
  );
  line-height: calc(
    var(--nav-hoz-height, 44px) - var(--nav-secondary-border-width, 0px) * 2
  );
}

.next-nav.next-secondary.next-hoz .next-menu-footer,
.next-nav.next-secondary.next-hoz .next-menu-header,
.next-nav.next-secondary.next-hoz .next-menu-item-inner {
  line-height: 44px;
  line-height: var(--nav-hoz-height, 44px);
  height: 44px;
  height: -webkit-calc(
    var(--nav-hoz-height, 44px) - var(--nav-secondary-border-width, 0) * 2
  );
  height: -moz-calc(
    var(--nav-hoz-height, 44px) - var(--nav-secondary-border-width, 0) * 2
  );
  height: calc(
    var(--nav-hoz-height, 44px) - var(--nav-secondary-border-width, 0px) * 2
  );
}

.next-nav.next-secondary.next-hoz.next-top .next-nav-item:before {
  top: 0;
  top: -webkit-calc(0 - var(--nav-secondary-border-width, 0));
  top: -moz-calc(0 - var(--nav-secondary-border-width, 0));
  top: calc(0px - var(--nav-secondary-border-width, 0px));
}

.next-nav.next-secondary.next-hoz.next-bottom .next-nav-item:before {
  bottom: 0;
  bottom: -webkit-calc(0 - var(--nav-secondary-border-width, 0));
  bottom: -moz-calc(0 - var(--nav-secondary-border-width, 0));
  bottom: calc(0px - var(--nav-secondary-border-width, 0px));
}

.next-nav.next-secondary.next-ver.next-left .next-nav-item:before {
  left: 0;
  left: -webkit-calc(0 - var(--nav-secondary-border-width, 0));
  left: -moz-calc(0 - var(--nav-secondary-border-width, 0));
  left: calc(0px - var(--nav-secondary-border-width, 0px));
}

.next-nav.next-secondary.next-ver.next-right .next-nav-item:before {
  right: 0;
  right: -webkit-calc(0 - var(--nav-secondary-border-width, 0));
  right: -moz-calc(0 - var(--nav-secondary-border-width, 0));
  right: calc(0px - var(--nav-secondary-border-width, 0px));
}

.next-nav.next-secondary .next-nav-item.next-menu-item {
  background-color: #0064c8;
  background-color: var(--nav-secondary-bg-color, #0064c8);
  color: #fff;
  color: var(--nav-secondary-text-color, #fff);
}

.next-nav.next-secondary .next-nav-item.next-menu-item.next-focused,
.next-nav.next-secondary .next-nav-item.next-menu-item:hover {
  background-color: #002850;
  background-color: var(--nav-secondary-item-hover-bg-color, #002850);
  color: #fff;
  color: var(--nav-secondary-item-hover-text-color, #fff);
  font-weight: 400;
  font-weight: var(--nav-secondary-item-hover-text-style, normal);
}

.next-nav.next-secondary .next-nav-item.next-menu-item.next-selected {
  background-color: #002850;
  background-color: var(--nav-secondary-item-selected-bg-color, #002850);
  color: #fff;
  color: var(--nav-secondary-item-selected-text-color, #fff);
  font-weight: 700;
  font-weight: var(--nav-secondary-item-selected-text-style, bold);
}

.next-nav.next-secondary .next-nav-item.next-menu-item.next-child-selected,
.next-nav.next-secondary .next-nav-item.next-menu-item.next-opened {
  background-color: #002850;
  background-color: var(--nav-secondary-item-opened-bg-color, #002850);
  color: #fff;
  color: var(--nav-secondary-item-opened-text-color, #fff);
}

.next-nav.next-secondary .next-nav-item.next-menu-item:before {
  background-color: #002850;
  background-color: var(--nav-secondary-item-selected-active-color, #002850);
}

.next-nav.next-secondary .next-nav-item.next-menu-item:hover:before {
  background-color: #002850;
  background-color: var(--nav-secondary-item-hover-active-color, #002850);
}

.next-nav.next-secondary .next-menu-sub-menu .next-menu-item.next-opened {
  background-color: #002850;
  background-color: var(--nav-secondary-item-opened-bg-color, #002850);
  color: #fff;
  color: var(--nav-secondary-item-opened-text-color, #fff);
}

.next-nav.next-secondary .next-nav-group-label {
  color: #fff;
  color: var(--nav-secondary-group-text-color, #fff);
  font-weight: 400;
  font-weight: var(--nav-secondary-group-text-style, normal);
}

.next-nav.next-secondary .next-menu-sub-menu .next-menu-item {
  background-color: #0064c8;
  background-color: var(--nav-secondary-sub-nav-bg-color, #0064c8);
  color: #fff;
  color: var(--nav-secondary-sub-nav-text-color, #fff);
  font-weight: 400;
  font-weight: var(--nav-secondary-sub-nav-text-style, normal);
}

.next-nav.next-secondary .next-menu-sub-menu .next-menu-item.next-focused,
.next-nav.next-secondary .next-menu-sub-menu .next-menu-item:hover {
  background-color: #002850;
  background-color: var(--nav-secondary-sub-nav-hover-bg-color, #002850);
  color: #fff;
  color: var(--nav-secondary-sub-nav-hover-text-color, #fff);
}

.next-nav.next-secondary .next-menu-sub-menu .next-menu-item.next-selected {
  background-color: #002850;
  background-color: var(--nav-secondary-sub-nav-selected-bg-color, #002850);
  color: #fff;
  color: var(--nav-secondary-sub-nav-selected-text-color, #fff);
}

.next-nav.next-normal {
  border-color: #e3e4e6;
  border-color: var(--nav-normal-border-color, #e3e4e6);
  font-weight: 400;
  font-weight: var(--nav-normal-text-style, normal);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(--nav-normal-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--nav-normal-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--nav-normal-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.16));
}

.next-nav.next-normal,
.next-nav.next-normal .next-nav-item.next-menu-item {
  background-color: #fff;
  color: #555;
}

.next-nav.next-normal .next-nav-item.next-menu-item.next-focused,
.next-nav.next-normal .next-nav-item.next-menu-item:hover {
  background-color: #f7f9fa;
  color: #0064c8;
  color: var(--nav-normal-item-hover-text-color, #0064c8);
  font-weight: 400;
  font-weight: var(--nav-normal-item-hover-text-style, normal);
}

.next-nav.next-normal .next-nav-item.next-menu-item.next-selected {
  background-color: #f7f9fa;
  background-color: var(--nav-normal-item-selected-bg-color, #f7f9fa);
  color: #0064c8;
  color: var(--nav-normal-item-selected-text-color, #0064c8);
  font-weight: 700;
  font-weight: var(--nav-normal-item-selected-text-style, bold);
}

.next-nav.next-normal .next-nav-item.next-menu-item.next-child-selected,
.next-nav.next-normal .next-nav-item.next-menu-item.next-opened {
  background-color: #f7f9fa;
  background-color: var(--nav-normal-item-opened-bg-color, #f7f9fa);
  color: #0064c8;
  color: var(--nav-normal-item-opened-text-color, #0064c8);
}

.next-nav.next-normal .next-nav-item.next-menu-item:before {
  background-color: #0064c8;
  background-color: var(--nav-normal-item-selected-active-color, #0064c8);
}

.next-nav.next-normal .next-nav-item.next-menu-item:hover:before {
  background-color: #0064c8;
  background-color: var(--nav-normal-item-hover-active-color, #0064c8);
}

.next-nav.next-normal .next-menu-sub-menu .next-menu-item.next-opened {
  background-color: #f7f9fa;
  background-color: var(--nav-normal-item-opened-bg-color, #f7f9fa);
  color: #0064c8;
  color: var(--nav-normal-item-opened-text-color, #0064c8);
}

.next-nav.next-normal .next-nav-group-label {
  color: #888;
  color: var(--nav-normal-group-text-color, #888);
  font-weight: 400;
  font-weight: var(--nav-normal-group-text-style, normal);
}

.next-nav.next-normal .next-menu-sub-menu .next-menu-item {
  background-color: #fff;
  color: #555;
  font-weight: 400;
}

.next-nav.next-normal .next-menu-sub-menu .next-menu-item.next-focused,
.next-nav.next-normal .next-menu-sub-menu .next-menu-item:hover {
  background-color: #f7f9fa;
  background-color: var(--nav-normal-sub-nav-hover-bg-color, #f7f9fa);
  color: #0064c8;
  color: var(--nav-normal-sub-nav-hover-text-color, #0064c8);
}

.next-nav.next-line {
  background-color: transparent;
  background-color: var(--nav-line-bg-color, transparent);
  border-color: #c0c6cc;
  border-color: var(--nav-line-border-color, #c0c6cc);
  color: #111;
  color: var(--nav-line-text-color, #111);
  font-weight: 400;
  font-weight: var(--nav-line-text-style, normal);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-nav.next-line.next-hoz {
  border-right-color: transparent;
  border-right-color: var(--nav-line-bg-color, transparent);
}

.next-nav.next-line.next-hoz,
.next-nav.next-line.next-ver {
  border-top-color: transparent;
  border-top-color: var(--nav-line-bg-color, transparent);
  border-left-color: transparent;
  border-left-color: var(--nav-line-bg-color, transparent);
}

.next-nav.next-line.next-ver {
  border-bottom-color: transparent;
  border-bottom-color: var(--nav-line-bg-color, transparent);
}

.next-nav.next-line .next-nav-item.next-menu-item {
  background-color: transparent;
  background-color: var(--nav-line-bg-color, transparent);
  color: #111;
  color: var(--nav-line-text-color, #111);
}

.next-nav.next-line .next-nav-item.next-menu-item.next-focused,
.next-nav.next-line .next-nav-item.next-menu-item:hover {
  background-color: transparent;
  background-color: var(--nav-line-item-hover-bg-color, transparent);
  color: #0064c8;
  color: var(--nav-line-item-hover-text-color, #0064c8);
  font-weight: 400;
  font-weight: var(--nav-line-item-hover-text-style, normal);
}

.next-nav.next-line .next-nav-item.next-menu-item.next-selected {
  background-color: transparent;
  background-color: var(--nav-line-item-selected-bg-color, transparent);
  color: #0064c8;
  color: var(--nav-line-item-selected-text-color, #0064c8);
  font-weight: 700;
  font-weight: var(--nav-line-item-selected-text-style, bold);
}

.next-nav.next-line .next-nav-item.next-menu-item.next-child-selected,
.next-nav.next-line .next-nav-item.next-menu-item.next-opened {
  background-color: transparent;
  background-color: var(--nav-line-item-opened-bg-color, transparent);
  color: #0064c8;
  color: var(--nav-line-item-opened-text-color, #0064c8);
}

.next-nav.next-line .next-nav-item.next-menu-item:before {
  background-color: #0064c8;
  background-color: var(--nav-line-item-selected-active-color, #0064c8);
}

.next-nav.next-line .next-nav-item.next-menu-item:hover:before {
  background-color: #0064c8;
  background-color: var(--nav-line-item-hover-active-color, #0064c8);
}

.next-nav.next-line .next-menu-sub-menu .next-menu-item.next-opened {
  background-color: transparent;
  background-color: var(--nav-line-item-opened-bg-color, transparent);
  color: #0064c8;
  color: var(--nav-line-item-opened-text-color, #0064c8);
}

.next-nav.next-line .next-nav-group-label {
  color: #888;
  color: var(--nav-line-group-text-color, #888);
  font-weight: 400;
  font-weight: var(--nav-line-group-text-style, normal);
}

.next-nav.next-line .next-menu-sub-menu .next-menu-item {
  background-color: transparent;
  background-color: var(--nav-line-sub-nav-bg-color, transparent);
  color: #111;
  color: var(--nav-line-sub-nav-text-color, #111);
  font-weight: 400;
  font-weight: var(--nav-line-sub-nav-text-style, normal);
}

.next-nav.next-line .next-menu-sub-menu .next-menu-item.next-focused,
.next-nav.next-line .next-menu-sub-menu .next-menu-item:hover {
  background-color: transparent;
  background-color: var(--nav-line-sub-nav-hover-bg-color, transparent);
  color: #0064c8;
  color: var(--nav-line-sub-nav-hover-text-color, #0064c8);
}

.next-nav.next-line .next-menu-sub-menu .next-menu-item.next-selected {
  background-color: transparent;
  background-color: var(--nav-line-sub-nav-selected-bg-color, transparent);
  color: #0064c8;
  color: var(--nav-line-sub-nav-selected-text-color, #0064c8);
}

.next-nav.next-icon-only.next-custom-icon-only-width {
  text-align: center;
}

.next-nav.next-icon-only .next-menu-item-inner {
  -o-text-overflow: clip;
  text-overflow: clip;
}

.next-nav.next-icon-only.next-normal .next-nav-icon.next-icon {
  margin-left: 0;
  margin-left: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px) -
      var(--popup-local-border-width, 1px)
  );
  margin-right: 0;
  margin-right: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px) -
      var(--popup-local-border-width, 1px)
  );
}

.next-nav.next-icon-only.next-normal .next-nav-icon.next-icon .next-icon-remote,
.next-nav.next-icon-only.next-normal .next-nav-icon.next-icon:before {
  width: 16px;
  width: var(--nav-icon-only-font-size, 16px);
  font-size: 16px;
  font-size: var(--nav-icon-only-font-size, 16px);
  line-height: inherit;
}

.next-nav.next-icon-only.next-primary .next-nav-icon.next-icon {
  margin-left: 1px;
  margin-left: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px) -
      var(--nav-primary-border-width, 0px)
  );
  margin-right: 1px;
  margin-right: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px) -
      var(--nav-primary-border-width, 0px)
  );
}

.next-nav.next-icon-only.next-primary
  .next-nav-icon.next-icon
  .next-icon-remote,
.next-nav.next-icon-only.next-primary .next-nav-icon.next-icon:before {
  width: 16px;
  width: var(--nav-icon-only-font-size, 16px);
  font-size: 16px;
  font-size: var(--nav-icon-only-font-size, 16px);
  line-height: inherit;
}

.next-nav.next-icon-only.next-secondary .next-nav-icon.next-icon {
  margin-left: 1px;
  margin-left: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px) -
      var(--nav-secondary-border-width, 0px)
  );
  margin-right: 1px;
  margin-right: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px) -
      var(--nav-secondary-border-width, 0px)
  );
}

.next-nav.next-icon-only.next-secondary
  .next-nav-icon.next-icon
  .next-icon-remote,
.next-nav.next-icon-only.next-secondary .next-nav-icon.next-icon:before {
  width: 16px;
  width: var(--nav-icon-only-font-size, 16px);
  font-size: 16px;
  font-size: var(--nav-icon-only-font-size, 16px);
  line-height: inherit;
}

.next-nav.next-icon-only .next-nav-icon-only-arrow.next-icon {
  margin-left: 1px;
  margin-left: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px)
  );
  margin-right: 1px;
  margin-right: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px)
  );
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  -webkit-transform-origin: center 50%;
  -moz-transform-origin: center 50%;
  -o-transform-origin: center 50%;
  transform-origin: center 50%;
}

.next-nav.next-icon-only .next-nav-icon-only-arrow.next-icon .next-icon-remote,
.next-nav.next-icon-only .next-nav-icon-only-arrow.next-icon:before {
  width: 16px;
  width: var(--nav-icon-only-font-size, 16px);
  font-size: 16px;
  font-size: var(--nav-icon-only-font-size, 16px);
  line-height: inherit;
}

.next-nav.next-icon-only
  .next-nav-item.next-opened
  .next-nav-icon-only-arrow.next-icon-arrow-down {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-left: 1px;
  margin-left: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px)
  );
  margin-right: 1px;
  margin-right: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px)
  );
}

.next-nav.next-icon-only
  .next-nav-item.next-opened
  .next-nav-icon-only-arrow.next-icon-arrow-down
  .next-icon-remote,
.next-nav.next-icon-only
  .next-nav-item.next-opened
  .next-nav-icon-only-arrow.next-icon-arrow-down:before {
  width: 16px;
  width: var(--nav-icon-only-font-size, 16px);
  font-size: 16px;
  font-size: var(--nav-icon-only-font-size, 16px);
  line-height: inherit;
}

.next-nav.next-icon-only .next-nav-item.next-selected .next-nav-icon.next-icon {
  -webkit-animation: pulse 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-animation: pulse 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -o-animation: pulse 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  animation: pulse 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.next-nav.next-icon-only .next-menu-hoz-icon-arrow,
.next-nav.next-icon-only .next-menu-icon-arrow {
  display: none;
}

.next-nav-embeddable.next-normal,
.next-nav-embeddable.next-primary,
.next-nav-embeddable.next-secondary {
  height: 100%;
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
}

.next-nav-embeddable.next-normal .next-menu-sub-menu .next-menu-item,
.next-nav-embeddable.next-normal .next-nav-item.next-menu-item,
.next-nav-embeddable.next-primary .next-menu-sub-menu .next-menu-item,
.next-nav-embeddable.next-primary .next-nav-item.next-menu-item,
.next-nav-embeddable.next-secondary .next-menu-sub-menu .next-menu-item,
.next-nav-embeddable.next-secondary .next-nav-item.next-menu-item {
  background: transparent;
}

.next-nav-embeddable.next-normal.next-icon-only .next-nav-icon.next-icon,
.next-nav-embeddable.next-primary.next-icon-only .next-nav-icon.next-icon,
.next-nav-embeddable.next-secondary.next-icon-only .next-nav-icon.next-icon {
  margin-left: 1px;
  margin-left: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px)
  );
  margin-right: 1px;
  margin-right: calc(
    29px - var(--nav-icon-only-font-size, 16px) / 2 -
      var(--nav-ver-item-padding-lr, 20px)
  );
}

.next-nav-embeddable.next-normal.next-icon-only
  .next-nav-icon.next-icon
  .next-icon-remote,
.next-nav-embeddable.next-normal.next-icon-only .next-nav-icon.next-icon:before,
.next-nav-embeddable.next-primary.next-icon-only
  .next-nav-icon.next-icon
  .next-icon-remote,
.next-nav-embeddable.next-primary.next-icon-only
  .next-nav-icon.next-icon:before,
.next-nav-embeddable.next-secondary.next-icon-only
  .next-nav-icon.next-icon
  .next-icon-remote,
.next-nav-embeddable.next-secondary.next-icon-only
  .next-nav-icon.next-icon:before {
  width: 16px;
  width: var(--nav-icon-only-font-size, 16px);
  font-size: 16px;
  font-size: var(--nav-icon-only-font-size, 16px);
  line-height: inherit;
}

.next-nav-embeddable.next-nav.next-hoz .next-menu-item-inner,
.next-nav-embeddable.next-nav.next-hoz .next-menu-sub-menu .next-menu-item,
.next-nav-embeddable.next-nav.next-hoz .next-nav-item.next-menu-item {
  height: 100%;
}

.next-nav-embeddable,
.next-nav-embeddable .next-nav-item.next-disabled,
.next-nav-embeddable .next-nav-item.next-disabled .next-menu-item-text > a {
  background: transparent;
  border: none;
}

.next-nav[dir="rtl"] .next-nav-icon.next-icon {
  margin-left: 4px;
  margin-right: 0;
}

.next-nav[dir="rtl"] .next-nav-icon.next-icon .next-icon-remote,
.next-nav[dir="rtl"] .next-nav-icon.next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-nav[dir="rtl"].next-hoz .next-menu-header {
  float: right;
}

.next-nav[dir="rtl"].next-hoz .next-menu-footer {
  float: left;
}

.next-nav[dir="rtl"].next-hoz .next-nav-item:before {
  width: 0;
  left: 50%;
}

.next-nav[dir="rtl"].next-hoz .next-selected.next-nav-item:before {
  width: 100%;
  left: auto;
  right: 0;
}

.next-nav[dir="rtl"].next-ver.next-left .next-nav-item:before {
  right: 0;
  right: -1px;
  right: -webkit-calc(0 - var(--popup-local-border-width, 1px));
  right: -moz-calc(0 - var(--popup-local-border-width, 1px));
  right: calc(0px - var(--popup-local-border-width, 1px));
  left: auto;
}

.next-nav[dir="rtl"].next-ver.next-right .next-nav-item:before {
  left: 0;
  left: -1px;
  left: -webkit-calc(0 - var(--popup-local-border-width, 1px));
  left: -moz-calc(0 - var(--popup-local-border-width, 1px));
  left: calc(0px - var(--popup-local-border-width, 1px));
  right: auto;
}

.next-nav[dir="rtl"].next-primary.next-ver.next-left .next-nav-item:before {
  right: 0;
  right: -webkit-calc(0 - var(--nav-primary-border-width, 0));
  right: -moz-calc(0 - var(--nav-primary-border-width, 0));
  right: calc(0px - var(--nav-primary-border-width, 0px));
  left: auto;
}

.next-nav[dir="rtl"].next-primary.next-ver.next-right .next-nav-item:before {
  left: 0;
  left: -webkit-calc(0 - var(--nav-primary-border-width, 0));
  left: -moz-calc(0 - var(--nav-primary-border-width, 0));
  left: calc(0px - var(--nav-primary-border-width, 0px));
  right: auto;
}

.next-nav[dir="rtl"].next-secondary.next-ver.next-left .next-nav-item:before {
  right: 0;
  right: -webkit-calc(0 - var(--nav-secondary-border-width, 0));
  right: -moz-calc(0 - var(--nav-secondary-border-width, 0));
  right: calc(0px - var(--nav-secondary-border-width, 0px));
  left: auto;
}

.next-nav[dir="rtl"].next-secondary.next-ver.next-right .next-nav-item:before {
  left: 0;
  left: -webkit-calc(0 - var(--nav-secondary-border-width, 0));
  left: -moz-calc(0 - var(--nav-secondary-border-width, 0));
  left: calc(0px - var(--nav-secondary-border-width, 0px));
  right: auto;
}

.next-nav[dir="rtl"] .next-nav.next-line.next-ver {
  border-top-color: transparent;
  border-top-color: var(--nav-line-bg-color, transparent);
  border-right-color: transparent;
  border-right-color: var(--nav-line-bg-color, transparent);
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-bottom-color: var(--nav-line-bg-color, transparent);
}

.next-nav[dir="rtl"].next-icon-only .next-nav-icon-only-arrow.next-icon,
.next-nav[dir="rtl"].next-icon-only .next-nav-icon.next-icon,
.next-nav[dir="rtl"].next-icon-only
  .next-nav-item.next-opened
  .next-nav-icon-only-arrow.next-icon-arrow-down {
  margin-left: 0;
  margin-right: 1px;
  margin-right: calc(9px - var(--nav-icon-only-font-size, 16px) / 2);
}

.next-nav[dir="rtl"].next-icon-only
  .next-nav-icon-only-arrow.next-icon
  .next-icon-remote,
.next-nav[dir="rtl"].next-icon-only .next-nav-icon-only-arrow.next-icon:before,
.next-nav[dir="rtl"].next-icon-only .next-nav-icon.next-icon .next-icon-remote,
.next-nav[dir="rtl"].next-icon-only .next-nav-icon.next-icon:before,
.next-nav[dir="rtl"].next-icon-only
  .next-nav-item.next-opened
  .next-nav-icon-only-arrow.next-icon-arrow-down
  .next-icon-remote,
.next-nav[dir="rtl"].next-icon-only
  .next-nav-item.next-opened
  .next-nav-icon-only-arrow.next-icon-arrow-down:before {
  width: 16px;
  width: var(--nav-icon-only-font-size, 16px);
  font-size: 16px;
  font-size: var(--nav-icon-only-font-size, 16px);
  line-height: inherit;
}

.next-number-picker {
  display: inline-block;
}

.next-number-picker,
.next-number-picker *,
.next-number-picker :after,
.next-number-picker :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-number-picker .next-btn {
  padding: 0 !important;
  line-height: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.next-number-picker-normal .next-input {
  width: 100%;
}

.next-number-picker-normal .next-input .next-input-control {
  padding-right: 0;
  height: 100%;
}

.next-number-picker-normal:not(.next-number-picker-no-trigger)
  .next-input
  input {
  padding-right: 2px;
}

.next-number-picker-normal .next-btn {
  display: block;
}

.next-number-picker-normal .next-btn:hover {
  z-index: 1;
}

.next-number-picker-normal .next-btn:first-child {
  border-right: none;
  border-top: none;
  height: 50%;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}

.next-number-picker-normal .next-btn:last-child {
  border-right: none;
  border-bottom: none;
  margin-top: -1px;
  height: -webkit-calc(50% + 1px);
  height: -moz-calc(50% + 1px);
  height: calc(50% + 1px);
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
}

.next-number-picker-normal .next-number-picker-handler {
  -webkit-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
  height: 100%;
  display: block;
}

.next-number-picker-normal:not(.next-number-picker-show-trigger)
  .next-number-picker-handler {
  opacity: 0;
}

.next-number-picker-normal.hover .next-number-picker-handler,
.next-number-picker-normal:hover .next-number-picker-handler {
  opacity: 1;
}

.next-number-picker-normal
  .next-input.next-disabled
  .next-number-picker-handler {
  opacity: 0;
}

.next-number-picker-normal .next-number-picker-up-icon:before {
  content: "\E844";
  content: var(--number-picker-normal-up-icon, "\E844");
}

.next-number-picker-normal .next-number-picker-down-icon:before {
  content: "\E843";
  content: var(--number-picker-normal-down-icon, "\E843");
}

.next-number-picker-normal.next-small {
  width: 68px;
}

.next-number-picker-normal.next-small .next-btn {
  width: 20px;
  width: var(--number-picker-normal-s-button-width, 20px);
}

.next-number-picker-normal.next-small .next-btn:first-child {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--form-element-small-corner, 2px);
  -moz-border-radius-topright: var(--form-element-small-corner, 2px);
  border-top-right-radius: var(--form-element-small-corner, 2px);
}

.next-number-picker-normal.next-small .next-btn:last-child {
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--form-element-small-corner, 2px);
  -moz-border-radius-bottomright: var(--form-element-small-corner, 2px);
  border-bottom-right-radius: var(--form-element-small-corner, 2px);
}

.next-number-picker-normal.next-small .next-icon .next-icon-remote,
.next-number-picker-normal.next-small .next-icon:before {
  width: 8px;
  font-size: 8px;
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-number-picker-normal.next-small .next-icon {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -4px;
    margin-right: -4px;
  }

  .next-number-picker-normal.next-small .next-icon:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-number-picker-normal.next-medium {
  width: 140px;
  width: var(--number-picker-normal-m-input-width, 140px);
}

.next-number-picker-normal.next-medium .next-btn {
  width: 40px;
  width: var(--number-picker-normal-m-button-width, 40px);
}

.next-number-picker-normal.next-medium .next-btn:first-child {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--form-element-medium-corner, 2px);
  -moz-border-radius-topright: var(--form-element-medium-corner, 2px);
  border-top-right-radius: var(--form-element-medium-corner, 2px);
}

.next-number-picker-normal.next-medium .next-btn:last-child {
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--form-element-medium-corner, 2px);
  -moz-border-radius-bottomright: var(--form-element-medium-corner, 2px);
  border-bottom-right-radius: var(--form-element-medium-corner, 2px);
}

.next-number-picker-normal.next-medium .next-icon .next-icon-remote,
.next-number-picker-normal.next-medium .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-number-picker-normal.next-large {
  width: 80px;
  width: var(--number-picker-normal-l-input-width, 80px);
}

.next-number-picker-normal.next-large .next-btn {
  width: 20px;
  width: var(--number-picker-normal-l-button-width, 20px);
}

.next-number-picker-normal.next-large .next-btn:first-child {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--form-element-large-corner, 2px);
  -moz-border-radius-topright: var(--form-element-large-corner, 2px);
  border-top-right-radius: var(--form-element-large-corner, 2px);
}

.next-number-picker-normal.next-large .next-btn:last-child {
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--form-element-large-corner, 2px);
  -moz-border-radius-bottomright: var(--form-element-large-corner, 2px);
  border-bottom-right-radius: var(--form-element-large-corner, 2px);
}

.next-number-picker-normal.next-large .next-icon .next-icon-remote,
.next-number-picker-normal.next-large .next-icon:before {
  width: 8px;
  font-size: 8px;
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-number-picker-normal.next-large .next-icon {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -4px;
    margin-right: -4px;
  }

  .next-number-picker-normal.next-large .next-icon:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-number-picker-inline input {
  text-align: center;
}

.next-number-picker-inline .next-input input {
  padding: 0;
}

.next-number-picker-inline .next-number-picker-add-icon:before {
  content: "\E740";
  content: var(--number-picker-inline-add-icon, "\E740");
}

.next-number-picker-inline .next-number-picker-minus-icon:before {
  content: "\E741";
  content: var(--number-picker-inline-minus-icon, "\E741");
}

.next-number-picker-inline.next-small {
  width: 68px;
  min-width: 72px;
  min-width: -webkit-calc(var(--form-element-small-height, 24px) * 3);
  min-width: -moz-calc(var(--form-element-small-height, 24px) * 3);
  min-width: calc(var(--form-element-small-height, 24px) * 3);
}

.next-number-picker-inline.next-small .next-icon .next-icon-remote,
.next-number-picker-inline.next-small .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-number-picker-inline.next-small .next-btn {
  height: 24px;
  height: var(--form-element-small-height, 24px);
}

.next-number-picker-inline.next-small .next-before .next-btn {
  margin-right: 2px;
  margin-right: var(--number-picker-inline-s-button-margin, 2px);
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  );
  -moz-border-radius-topleft: var(--number-picker-inline-s-button-corner, 2px);
  border-top-left-radius: var(--number-picker-inline-s-button-corner, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  );
  -moz-border-radius-bottomleft: var(
    --number-picker-inline-s-button-corner,
    2px
  );
  border-bottom-left-radius: var(--number-picker-inline-s-button-corner, 2px);
}

.next-number-picker-inline.next-small .next-after .next-btn {
  margin-left: 2px;
  margin-left: var(--number-picker-inline-s-button-margin, 2px);
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  );
  -moz-border-radius-topright: var(--number-picker-inline-s-button-corner, 2px);
  border-top-right-radius: var(--number-picker-inline-s-button-corner, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  );
  -moz-border-radius-bottomright: var(
    --number-picker-inline-s-button-corner,
    2px
  );
  border-bottom-right-radius: var(--number-picker-inline-s-button-corner, 2px);
}

.next-number-picker-inline.next-medium {
  width: 140px;
  width: var(--number-picker-inline-m-input-width, 140px);
  min-width: 96px;
  min-width: -webkit-calc(var(--form-element-medium-height, 32px) * 3);
  min-width: -moz-calc(var(--form-element-medium-height, 32px) * 3);
  min-width: calc(var(--form-element-medium-height, 32px) * 3);
}

.next-number-picker-inline.next-medium .next-icon .next-icon-remote,
.next-number-picker-inline.next-medium .next-icon:before {
  width: 32px;
  font-size: 32px;
  line-height: inherit;
}

.next-number-picker-inline.next-medium .next-btn {
  height: 32px;
  height: var(--form-element-medium-height, 32px);
}

.next-number-picker-inline.next-medium .next-before .next-btn {
  margin-right: 0;
  margin-right: var(--number-picker-inline-m-button-margin, 0);
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  );
  -moz-border-radius-topleft: var(--number-picker-inline-m-button-corner, 2px);
  border-top-left-radius: var(--number-picker-inline-m-button-corner, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  );
  -moz-border-radius-bottomleft: var(
    --number-picker-inline-m-button-corner,
    2px
  );
  border-bottom-left-radius: var(--number-picker-inline-m-button-corner, 2px);
}

.next-number-picker-inline.next-medium .next-after .next-btn {
  margin-left: 0;
  margin-left: var(--number-picker-inline-m-button-margin, 0);
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  );
  -moz-border-radius-topright: var(--number-picker-inline-m-button-corner, 2px);
  border-top-right-radius: var(--number-picker-inline-m-button-corner, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  );
  -moz-border-radius-bottomright: var(
    --number-picker-inline-m-button-corner,
    2px
  );
  border-bottom-right-radius: var(--number-picker-inline-m-button-corner, 2px);
}

.next-number-picker-inline.next-large {
  width: 128px;
  width: var(--number-picker-inline-l-input-width, 128px);
  min-width: 108px;
  min-width: -webkit-calc(var(--form-element-large-height, 36px) * 3);
  min-width: -moz-calc(var(--form-element-large-height, 36px) * 3);
  min-width: calc(var(--form-element-large-height, 36px) * 3);
}

.next-number-picker-inline.next-large .next-icon .next-icon-remote,
.next-number-picker-inline.next-large .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-number-picker-inline.next-large .next-btn {
  height: 36px;
  height: var(--form-element-large-height, 36px);
}

.next-number-picker-inline.next-large .next-before .next-btn {
  margin-right: 2px;
  margin-right: var(--number-picker-inline-l-button-margin, 2px);
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  );
  -moz-border-radius-topleft: var(--number-picker-inline-l-button-corner, 2px);
  border-top-left-radius: var(--number-picker-inline-l-button-corner, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  );
  -moz-border-radius-bottomleft: var(
    --number-picker-inline-l-button-corner,
    2px
  );
  border-bottom-left-radius: var(--number-picker-inline-l-button-corner, 2px);
}

.next-number-picker-inline.next-large .next-after .next-btn {
  margin-left: 2px;
  margin-left: var(--number-picker-inline-l-button-margin, 2px);
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  );
  -moz-border-radius-topright: var(--number-picker-inline-l-button-corner, 2px);
  border-top-right-radius: var(--number-picker-inline-l-button-corner, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  );
  -moz-border-radius-bottomright: var(
    --number-picker-inline-l-button-corner,
    2px
  );
  border-bottom-right-radius: var(--number-picker-inline-l-button-corner, 2px);
}

.next-number-picker-inline .next-btn.next-small {
  width: 24px;
  width: var(--form-element-small-height, 24px);
}

.next-number-picker-inline .next-btn.next-medium {
  width: 32px;
  width: var(--form-element-medium-height, 32px);
}

.next-number-picker-inline .next-btn.next-large {
  width: 36px;
  width: var(--form-element-large-height, 36px);
}

@-moz-document url-prefix() {
  .next-number-picker-normal.next-small .next-number-picker-handler {
    height: 22px;
    height: -moz-calc(var(--form-element-small-height, 24px) - 2px);
    height: calc(var(--form-element-small-height, 24px) - 2px);
  }

  .next-number-picker-normal.next-medium .next-number-picker-handler {
    height: 30px;
    height: -moz-calc(var(--form-element-medium-height, 32px) - 2px);
    height: calc(var(--form-element-medium-height, 32px) - 2px);
  }

  .next-number-picker-normal.next-large .next-number-picker-handler {
    height: 34px;
    height: -moz-calc(var(--form-element-large-height, 36px) - 2px);
    height: calc(var(--form-element-large-height, 36px) - 2px);
  }
}

.next-number-picker-normal[dir="rtl"] .next-btn:first-child {
  border-right: 1px solid #577899;
  border-right: 1px solid var(--color-line1-3, #577899);
  border-left: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
}

.next-number-picker-normal[dir="rtl"] .next-btn:first-child.next-small {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--form-element-small-corner, 2px);
  -moz-border-radius-topleft: var(--form-element-small-corner, 2px);
  border-top-left-radius: var(--form-element-small-corner, 2px);
}

.next-number-picker-normal[dir="rtl"] .next-btn:first-child.next-medium {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--form-element-medium-corner, 2px);
  -moz-border-radius-topleft: var(--form-element-medium-corner, 2px);
  border-top-left-radius: var(--form-element-medium-corner, 2px);
}

.next-number-picker-normal[dir="rtl"] .next-btn:first-child.next-large {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--form-element-large-corner, 2px);
  -moz-border-radius-topleft: var(--form-element-large-corner, 2px);
  border-top-left-radius: var(--form-element-large-corner, 2px);
}

.next-number-picker-normal[dir="rtl"] .next-btn:last-child {
  border-right: 1px solid #577899;
  border-right: 1px solid var(--color-line1-3, #577899);
  border-left: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}

.next-number-picker-normal[dir="rtl"] .next-btn:last-child.next-small {
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--form-element-small-corner, 2px);
  -moz-border-radius-bottomleft: var(--form-element-small-corner, 2px);
  border-bottom-left-radius: var(--form-element-small-corner, 2px);
}

.next-number-picker-normal[dir="rtl"] .next-btn:last-child.next-medium {
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--form-element-medium-corner, 2px);
  -moz-border-radius-bottomleft: var(--form-element-medium-corner, 2px);
  border-bottom-left-radius: var(--form-element-medium-corner, 2px);
}

.next-number-picker-normal[dir="rtl"] .next-btn:last-child.next-large {
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--form-element-large-corner, 2px);
  -moz-border-radius-bottomleft: var(--form-element-large-corner, 2px);
  border-bottom-left-radius: var(--form-element-large-corner, 2px);
}

.next-number-picker-normal[dir="rtl"] .next-input .next-input-control {
  padding-left: 0;
}

.next-number-picker-inline[dir="rtl"] .next-before .next-btn {
  margin-right: 0;
}

.next-number-picker-inline[dir="rtl"] .next-before .next-btn.next-small {
  margin-left: 2px;
  margin-left: var(--number-picker-inline-s-button-margin, 2px);
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  border-top-right-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
}

.next-number-picker-inline[dir="rtl"] .next-before .next-btn.next-medium {
  margin-left: 0;
  margin-left: var(--number-picker-inline-m-button-margin, 0);
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  border-top-right-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
}

.next-number-picker-inline[dir="rtl"] .next-before .next-btn.next-large {
  margin-left: 2px;
  margin-left: var(--number-picker-inline-l-button-margin, 2px);
  -webkit-border-top-right-radius: 2px !important;
  -moz-border-radius-topright: 2px !important;
  border-top-right-radius: 2px !important;
  -webkit-border-top-right-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  -moz-border-radius-topright: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  border-top-right-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  -moz-border-radius-bottomright: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  border-bottom-right-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
}

.next-number-picker-inline[dir="rtl"] .next-after .next-btn {
  margin-left: 0;
}

.next-number-picker-inline[dir="rtl"] .next-after .next-btn.next-small {
  margin-right: 2px;
  margin-right: var(--number-picker-inline-s-button-margin, 2px);
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  border-top-left-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(
    --number-picker-inline-s-button-corner,
    2px
  ) !important;
}

.next-number-picker-inline[dir="rtl"] .next-after .next-btn.next-medium {
  margin-right: 0;
  margin-right: var(--number-picker-inline-m-button-margin, 0);
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  border-top-left-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(
    --number-picker-inline-m-button-corner,
    2px
  ) !important;
}

.next-number-picker-inline[dir="rtl"] .next-after .next-btn.next-large {
  margin-right: 2px;
  margin-right: var(--number-picker-inline-l-button-margin, 2px);
  -webkit-border-top-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  border-top-left-radius: 2px !important;
  -webkit-border-top-left-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  -moz-border-radius-topleft: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  border-top-left-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-bottom-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  -moz-border-radius-bottomleft: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
  border-bottom-left-radius: var(
    --number-picker-inline-l-button-corner,
    2px
  ) !important;
}

.next-pagination[dir="rtl"] .next-pagination-total {
  margin-right: 0;
  margin-left: 16px;
}

.next-pagination[dir="rtl"] .next-pagination-jump-go {
  margin-left: 0;
  margin-right: 4px;
}

.next-pagination[dir="rtl"] .next-pagination-size-selector-title {
  margin-right: 0;
  margin-left: 4px;
  margin-left: var(--pagination-size-selector-title-margin-right, 4px);
}

.next-pagination[dir="rtl"]
  .next-pagination-size-selector-btn.next-btn-text
  + .next-pagination-size-selector-btn {
  border-left: none;
  border-right: 1px solid #c0c6cc;
  border-right: var(--line-1, 1px) solid var(--color-line1-2, #c0c6cc);
}

.next-pagination[dir="rtl"]
  .next-pagination-pages
  + .next-pagination-size-selector,
.next-pagination[dir="rtl"]
  .next-pagination-size-selector
  + .next-pagination-pages {
  margin-left: 0;
  margin-right: 40px;
}

.next-pagination[dir="rtl"].next-start .next-pagination-pages {
  float: left;
}

.next-pagination[dir="rtl"].next-end .next-pagination-pages,
.next-pagination[dir="rtl"].next-start .next-pagination-size-selector {
  float: right;
}

.next-pagination[dir="rtl"].next-end .next-pagination-size-selector {
  float: left;
}

.next-pagination[dir="rtl"].next-small .next-pagination-list {
  margin: 0 4px;
  margin: 0 var(--pagination-small-item-split, 4px);
}

.next-pagination[dir="rtl"].next-small .next-pagination-total {
  line-height: 24px;
  line-height: var(--form-element-small-height, 24px);
  vertical-align: middle;
}

.next-pagination[dir="rtl"].next-small .next-pagination-item {
  padding: 0 6px;
  border-width: 1px;
  border-width: var(--pagination-small-item-border-width, 1px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--pagination-small-item-corner, 2px);
  -moz-border-radius: var(--pagination-small-item-corner, 2px);
  border-radius: var(--pagination-small-item-corner, 2px);
}

.next-pagination[dir="rtl"].next-small
  .next-pagination-item
  + .next-pagination-item {
  margin: 0 4px 0 0;
  margin: 0 var(--pagination-small-item-split, 4px) 0 0;
}

.next-pagination[dir="rtl"].next-small .next-pagination-ellipsis {
  height: 24px;
  height: var(--form-element-small-height, 24px);
  line-height: 24px;
  line-height: var(--form-element-small-height, 24px);
  margin-left: 8px;
  margin-left: var(--pagination-ellipsis-margin, 8px);
  margin-right: 8px;
  margin-right: var(--pagination-ellipsis-margin, 8px);
}

.next-pagination[dir="rtl"].next-small
  .next-pagination-ellipsis
  .next-icon-remote,
.next-pagination[dir="rtl"].next-small .next-pagination-ellipsis:before {
  width: 8px;
  width: var(--pagination-small-ellipsis-size, 8px);
  font-size: 8px;
  font-size: var(--pagination-small-ellipsis-size, 8px);
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-pagination[dir="rtl"].next-small .next-pagination-ellipsis {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: 4px;
    margin-left: calc(
      var(--pagination-ellipsis-margin, 8px) - 8px +
        var(--pagination-small-ellipsis-size, 8px) / 2
    );
    margin-right: 4px;
    margin-right: calc(
      var(--pagination-ellipsis-margin, 8px) - 8px +
        var(--pagination-small-ellipsis-size, 8px) / 2
    );
  }

  .next-pagination[dir="rtl"].next-small .next-pagination-ellipsis:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-pagination[dir="rtl"].next-small .next-pagination-display {
  font-size: 12px;
  font-size: var(--pagination-small-total-font-size, 12px);
}

.next-pagination[dir="rtl"].next-small .next-pagination-display em {
  font-size: 12px;
  font-size: var(--pagination-small-current-font-size, 12px);
}

.next-pagination[dir="rtl"].next-small .next-pagination-jump-text {
  font-size: 12px;
  font-size: var(--pagination-small-jump-font-size, 12px);
}

.next-pagination[dir="rtl"].next-small .next-pagination-jump-input {
  width: 28px;
  width: var(--pagination-small-input-width, 28px);
}

.next-pagination[dir="rtl"].next-small .next-pagination-size-selector-title {
  height: 24px;
  height: var(--form-element-small-height, 24px);
  line-height: 24px;
  line-height: var(--form-element-small-height, 24px);
  font-size: 12px;
  font-size: var(--pagination-small-jump-font-size, 12px);
  vertical-align: middle;
}

.next-pagination[dir="rtl"].next-small .next-pagination-size-selector-btn {
  padding: 0 8px;
  padding: 0 var(--pagination-small-size-selector-number-padding, 8px);
}

.next-pagination[dir="rtl"].next-small
  .next-pagination-item.next-next:not([disabled])
  i,
.next-pagination[dir="rtl"].next-small
  .next-pagination-item.next-prev:not([disabled])
  i {
  color: #333;
  color: var(--pagination-arrow-color, #333);
}

.next-pagination[dir="rtl"].next-small
  .next-pagination-item:hover.next-next:not([disabled])
  i,
.next-pagination[dir="rtl"].next-small
  .next-pagination-item:hover.next-prev:not([disabled])
  i {
  color: #555;
  color: var(--pagination-arrow-hover-color, #555);
}

.next-pagination[dir="rtl"].next-medium .next-pagination-list {
  margin: 0 8px;
  margin: 0 var(--pagination-item-split, 8px);
}

.next-pagination[dir="rtl"].next-medium .next-pagination-total {
  line-height: 32px;
  line-height: var(--form-element-medium-height, 32px);
  vertical-align: middle;
}

.next-pagination[dir="rtl"].next-medium .next-pagination-item {
  padding: 0 10px;
  border-width: 1px;
  border-width: var(--pagination-item-border-width, 1px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--pagination-item-corner, 2px);
  -moz-border-radius: var(--pagination-item-corner, 2px);
  border-radius: var(--pagination-item-corner, 2px);
}

.next-pagination[dir="rtl"].next-medium
  .next-pagination-item
  + .next-pagination-item {
  margin: 0 8px 0 0;
  margin: 0 var(--pagination-item-split, 8px) 0 0;
}

.next-pagination[dir="rtl"].next-medium .next-pagination-ellipsis {
  height: 32px;
  height: var(--form-element-medium-height, 32px);
  line-height: 32px;
  line-height: var(--form-element-medium-height, 32px);
  margin-left: 8px;
  margin-left: var(--pagination-ellipsis-margin, 8px);
  margin-right: 8px;
  margin-right: var(--pagination-ellipsis-margin, 8px);
}

.next-pagination[dir="rtl"].next-medium
  .next-pagination-ellipsis
  .next-icon-remote,
.next-pagination[dir="rtl"].next-medium .next-pagination-ellipsis:before {
  width: 12px;
  width: var(--pagination-ellipsis-size, 12px);
  font-size: 12px;
  font-size: var(--pagination-ellipsis-size, 12px);
  line-height: inherit;
}

.next-pagination[dir="rtl"].next-medium .next-pagination-display {
  font-size: 12px;
  font-size: var(--pagination-total-font-size, 12px);
}

.next-pagination[dir="rtl"].next-medium .next-pagination-display em {
  font-size: 12px;
  font-size: var(--pagination-current-font-size, 12px);
}

.next-pagination[dir="rtl"].next-medium .next-pagination-jump-text {
  font-size: 12px;
  font-size: var(--pagination-jump-font-size, 12px);
}

.next-pagination[dir="rtl"].next-medium .next-pagination-jump-input {
  width: 40px;
  width: var(--pagination-input-width, 40px);
}

.next-pagination[dir="rtl"].next-medium .next-pagination-size-selector-title {
  height: 32px;
  height: var(--form-element-medium-height, 32px);
  line-height: 32px;
  line-height: var(--form-element-medium-height, 32px);
  font-size: 12px;
  font-size: var(--pagination-jump-font-size, 12px);
  vertical-align: middle;
}

.next-pagination[dir="rtl"].next-medium .next-pagination-size-selector-btn {
  padding: 0 12px;
  padding: 0 var(--pagination-size-selector-number-padding, 12px);
}

.next-pagination[dir="rtl"].next-medium
  .next-pagination-item.next-next:not([disabled])
  i,
.next-pagination[dir="rtl"].next-medium
  .next-pagination-item.next-prev:not([disabled])
  i {
  color: #333;
  color: var(--pagination-arrow-color, #333);
}

.next-pagination[dir="rtl"].next-medium
  .next-pagination-item:hover.next-next:not([disabled])
  i,
.next-pagination[dir="rtl"].next-medium
  .next-pagination-item:hover.next-prev:not([disabled])
  i {
  color: #555;
  color: var(--pagination-arrow-hover-color, #555);
}

.next-pagination[dir="rtl"].next-large .next-pagination-list {
  margin: 0 8px;
  margin: 0 var(--pagination-large-item-split, 8px);
}

.next-pagination[dir="rtl"].next-large .next-pagination-total {
  line-height: 36px;
  line-height: var(--form-element-large-height, 36px);
  vertical-align: middle;
}

.next-pagination[dir="rtl"].next-large .next-pagination-item {
  padding: 0 15px;
  border-width: 1px;
  border-width: var(--pagination-large-item-border-width, 1px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--pagination-large-item-corner, 2px);
  -moz-border-radius: var(--pagination-large-item-corner, 2px);
  border-radius: var(--pagination-large-item-corner, 2px);
}

.next-pagination[dir="rtl"].next-large
  .next-pagination-item
  + .next-pagination-item {
  margin: 0 8px 0 0;
  margin: 0 var(--pagination-large-item-split, 8px) 0 0;
}

.next-pagination[dir="rtl"].next-large .next-pagination-ellipsis {
  height: 36px;
  height: var(--form-element-large-height, 36px);
  line-height: 36px;
  line-height: var(--form-element-large-height, 36px);
  margin-left: 8px;
  margin-left: var(--pagination-ellipsis-margin, 8px);
  margin-right: 8px;
  margin-right: var(--pagination-ellipsis-margin, 8px);
}

.next-pagination[dir="rtl"].next-large
  .next-pagination-ellipsis
  .next-icon-remote,
.next-pagination[dir="rtl"].next-large .next-pagination-ellipsis:before {
  width: 12px;
  width: var(--pagination-large-ellipsis-size, 12px);
  font-size: 12px;
  font-size: var(--pagination-large-ellipsis-size, 12px);
  line-height: inherit;
}

.next-pagination[dir="rtl"].next-large .next-pagination-display {
  font-size: 14px;
  font-size: var(--pagination-large-total-font-size, 14px);
}

.next-pagination[dir="rtl"].next-large .next-pagination-display em {
  font-size: 14px;
  font-size: var(--pagination-large-current-font-size, 14px);
}

.next-pagination[dir="rtl"].next-large .next-pagination-jump-text {
  font-size: 14px;
  font-size: var(--pagination-large-jump-font-size, 14px);
}

.next-pagination[dir="rtl"].next-large .next-pagination-jump-input {
  width: 48px;
  width: var(--pagination-large-input-width, 48px);
}

.next-pagination[dir="rtl"].next-large .next-pagination-size-selector-title {
  height: 36px;
  height: var(--form-element-large-height, 36px);
  line-height: 36px;
  line-height: var(--form-element-large-height, 36px);
  font-size: 14px;
  font-size: var(--pagination-large-jump-font-size, 14px);
  vertical-align: middle;
}

.next-pagination[dir="rtl"].next-large .next-pagination-size-selector-btn {
  padding: 0 16px;
  padding: 0 var(--pagination-large-size-selector-number-padding, 16px);
}

.next-pagination[dir="rtl"].next-large
  .next-pagination-item.next-next:not([disabled])
  i,
.next-pagination[dir="rtl"].next-large
  .next-pagination-item.next-prev:not([disabled])
  i {
  color: #333;
  color: var(--pagination-arrow-color, #333);
}

.next-pagination[dir="rtl"].next-large
  .next-pagination-item:hover.next-next:not([disabled])
  i,
.next-pagination[dir="rtl"].next-large
  .next-pagination-item:hover.next-prev:not([disabled])
  i {
  color: #555;
  color: var(--pagination-arrow-hover-color, #555);
}

.next-pagination {
  font-size: 0;
}

.next-pagination,
.next-pagination *,
.next-pagination :after,
.next-pagination :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-pagination:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-pagination-total {
  display: inline-block;
  font-size: 14px;
  font-size: var(--font-size-body-2, 14px);
  margin-right: 16px;
}

.next-pagination-pages {
  display: inline-block;
}

.next-pagination-list {
  display: inline-block;
  vertical-align: top;
}

.next-pagination .next-pagination-item:not([disabled]) {
  display: inline-block;
  border-style: solid;
  border-style: var(--pagination-item-border-style, solid);
  border-color: transparent;
  border-color: var(--pagination-item-border-color, transparent);
  background: transparent;
  background: var(--pagination-item-bg, transparent);
  color: #333;
  color: var(--pagination-item-color, #333);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--pagination-item-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--pagination-item-shadow, none);
  box-shadow: var(--pagination-item-shadow, none);
}

.next-pagination .next-pagination-item {
  -webkit-transition: none;
  -o-transition: none;
  -moz-transition: none;
  transition: none;
}

.next-pagination .next-pagination-item.next-current {
  border-color: #0064c8;
  border-color: var(--pagination-item-current-border-color, #0064c8);
  background: #fff;
  background: var(--pagination-item-current-bg, #fff);
  color: #0064c8;
  color: var(--pagination-item-current-color, #0064c8);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--pagination-item-current-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--pagination-item-current-shadow, none);
  box-shadow: var(--pagination-item-current-shadow, none);
}

.next-pagination .next-pagination-item.next-current:focus,
.next-pagination .next-pagination-item.next-current:hover {
  border-color: #0064c8;
  border-color: var(--pagination-item-current-hover-border-color, #0064c8);
  background: #fff;
  background: var(--pagination-item-current-hover-bg, #fff);
  color: #333;
  color: var(--pagination-item-current-hover-color, #333);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--pagination-item-current-hover-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--pagination-item-current-hover-shadow, none);
  box-shadow: var(--pagination-item-current-hover-shadow, none);
}

.next-pagination-ellipsis {
  display: inline-block;
  color: #333;
  color: var(--pagination-ellipsis-color, #333);
  vertical-align: top;
}

.next-pagination-display {
  display: inline-block;
  margin: 0 16px;
  color: #333;
  color: var(--pagination-total-color, #333);
  vertical-align: middle;
}

.next-pagination-display em {
  font-style: normal;
  color: #0064c8;
  color: var(--pagination-current-color, #0064c8);
}

.next-pagination-jump-text {
  display: inline-block;
  vertical-align: middle;
  color: #333;
  color: var(--pagination-jump-color, #333);
}

.next-pagination-jump-input {
  margin: 0 4px;
  margin: 0 var(--pagination-input-margin, 4px);
  vertical-align: top;
}

.next-pagination-jump-go {
  margin-left: 4px;
  vertical-align: top;
}

.next-pagination-size-selector {
  display: inline-block;
  position: relative;
}

.next-pagination-size-selector-title {
  margin-right: 4px;
  margin-right: var(--pagination-size-selector-title-margin-right, 4px);
  color: #888;
  color: var(--pagination-size-selector-title-color, #888);
}

.next-pagination-size-selector-filter {
  display: inline-block;
  vertical-align: middle;
}

.next-pagination-size-selector-dropdown {
  vertical-align: top;
  min-width: 64px;
}

.next-pagination-size-selector-dropdown .next-select-inner,
.next-pagination-size-selector-popup {
  min-width: 64px;
}

.next-pagination-size-selector-btn.next-btn-text {
  height: auto;
  line-height: normal;
  color: #555;
  color: var(--pagination-size-selector-filter-color, #555);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.next-pagination-size-selector-btn.next-btn-text.next-current {
  color: #0064c8;
  color: var(--pagination-size-selector-filter-current-color, #0064c8);
}

.next-pagination-size-selector-btn.next-btn-text
  + .next-pagination-size-selector-btn {
  border-left: 1px solid #c0c6cc;
  border-left: var(--line-1, 1px) solid var(--color-line1-2, #c0c6cc);
}

.next-pagination-pages + .next-pagination-size-selector,
.next-pagination-size-selector + .next-pagination-pages {
  margin-left: 40px;
}

.next-pagination.next-hide {
  display: none;
}

.next-pagination.next-start .next-pagination-pages {
  float: right;
}

.next-pagination.next-end .next-pagination-pages,
.next-pagination.next-start .next-pagination-size-selector {
  float: left;
}

.next-pagination.next-end .next-pagination-size-selector {
  float: right;
}

.next-pagination.next-small .next-pagination-list {
  margin: 0 4px;
  margin: 0 var(--pagination-small-item-split, 4px);
}

.next-pagination.next-small .next-pagination-total {
  line-height: 24px;
  line-height: var(--form-element-small-height, 24px);
  vertical-align: middle;
}

.next-pagination.next-small .next-pagination-item {
  padding: 0 6px;
  border-width: 1px;
  border-width: var(--pagination-small-item-border-width, 1px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--pagination-small-item-corner, 2px);
  -moz-border-radius: var(--pagination-small-item-corner, 2px);
  border-radius: var(--pagination-small-item-corner, 2px);
}

.next-pagination.next-small .next-pagination-item + .next-pagination-item {
  margin: 0 0 0 4px;
  margin: 0 0 0 var(--pagination-small-item-split, 4px);
}

.next-pagination.next-small .next-pagination-ellipsis {
  height: 24px;
  height: var(--form-element-small-height, 24px);
  line-height: 24px;
  line-height: var(--form-element-small-height, 24px);
  margin-left: 8px;
  margin-left: var(--pagination-ellipsis-margin, 8px);
  margin-right: 8px;
  margin-right: var(--pagination-ellipsis-margin, 8px);
}

.next-pagination.next-small .next-pagination-ellipsis .next-icon-remote,
.next-pagination.next-small .next-pagination-ellipsis:before {
  width: 8px;
  width: var(--pagination-small-ellipsis-size, 8px);
  font-size: 8px;
  font-size: var(--pagination-small-ellipsis-size, 8px);
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-pagination.next-small .next-pagination-ellipsis {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: 4px;
    margin-left: calc(
      var(--pagination-ellipsis-margin, 8px) - 8px +
        var(--pagination-small-ellipsis-size, 8px) / 2
    );
    margin-right: 4px;
    margin-right: calc(
      var(--pagination-ellipsis-margin, 8px) - 8px +
        var(--pagination-small-ellipsis-size, 8px) / 2
    );
  }

  .next-pagination.next-small .next-pagination-ellipsis:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-pagination.next-small .next-pagination-display {
  font-size: 12px;
  font-size: var(--pagination-small-total-font-size, 12px);
}

.next-pagination.next-small .next-pagination-display em {
  font-size: 12px;
  font-size: var(--pagination-small-current-font-size, 12px);
}

.next-pagination.next-small .next-pagination-jump-text {
  font-size: 12px;
  font-size: var(--pagination-small-jump-font-size, 12px);
}

.next-pagination.next-small .next-pagination-jump-input {
  width: 28px;
  width: var(--pagination-small-input-width, 28px);
}

.next-pagination.next-small .next-pagination-size-selector-title {
  height: 24px;
  height: var(--form-element-small-height, 24px);
  line-height: 24px;
  line-height: var(--form-element-small-height, 24px);
  font-size: 12px;
  font-size: var(--pagination-small-jump-font-size, 12px);
  vertical-align: middle;
}

.next-pagination.next-small .next-pagination-size-selector-btn {
  padding: 0 8px;
  padding: 0 var(--pagination-small-size-selector-number-padding, 8px);
}

.next-pagination.next-small .next-pagination-item.next-next:not([disabled]) i,
.next-pagination.next-small .next-pagination-item.next-prev:not([disabled]) i {
  color: #333;
  color: var(--pagination-arrow-color, #333);
}

.next-pagination.next-small
  .next-pagination-item:hover.next-next:not([disabled])
  i,
.next-pagination.next-small
  .next-pagination-item:hover.next-prev:not([disabled])
  i {
  color: #555;
  color: var(--pagination-arrow-hover-color, #555);
}

.next-pagination.next-small.next-arrow-only .next-pagination-item.next-next,
.next-pagination.next-small.next-arrow-only .next-pagination-item.next-prev {
  width: 20px;
  padding: 0;
}

.next-pagination.next-small.next-arrow-only
  .next-pagination-item.next-next
  .next-icon,
.next-pagination.next-small.next-arrow-only
  .next-pagination-item.next-prev
  .next-icon {
  margin: 0 auto;
}

.next-pagination.next-small.next-arrow-prev-only
  .next-pagination-item.next-prev {
  width: 20px;
  padding: 0;
}

.next-pagination.next-small.next-arrow-prev-only
  .next-pagination-item.next-prev
  .next-icon {
  margin: 0 auto;
}

.next-pagination.next-small.next-no-border .next-pagination-item.next-next,
.next-pagination.next-small.next-no-border .next-pagination-item.next-prev {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-pagination.next-small.next-no-border
  .next-pagination-item.next-next
  .next-icon,
.next-pagination.next-small.next-no-border
  .next-pagination-item.next-prev
  .next-icon {
  margin: 0;
}

.next-pagination.next-small.next-no-border
  .next-pagination-item.next-next:not([disabled]):hover
  i,
.next-pagination.next-small.next-no-border
  .next-pagination-item.next-prev:not([disabled]):hover
  i {
  color: #0064c8;
  color: var(--pagination-arrow-hover-color-noboder, #0064c8);
}

.next-pagination.next-small.next-no-border .next-pagination-display {
  margin: 0 8px;
}

.next-pagination.next-small.next-mini .next-pagination-item.next-prev {
  margin-right: 4px;
}

.next-pagination.next-small.next-mini .next-pagination-item.next-next {
  margin-left: 4px;
}

.next-pagination.next-medium .next-pagination-list {
  margin: 0 8px;
  margin: 0 var(--pagination-item-split, 8px);
}

.next-pagination.next-medium .next-pagination-total {
  line-height: 32px;
  line-height: var(--form-element-medium-height, 32px);
  vertical-align: middle;
}

.next-pagination.next-medium .next-pagination-item {
  padding: 0 10px;
  border-width: 1px;
  border-width: var(--pagination-item-border-width, 1px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--pagination-item-corner, 2px);
  -moz-border-radius: var(--pagination-item-corner, 2px);
  border-radius: var(--pagination-item-corner, 2px);
}

.next-pagination.next-medium .next-pagination-item + .next-pagination-item {
  margin: 0 0 0 8px;
  margin: 0 0 0 var(--pagination-item-split, 8px);
}

.next-pagination.next-medium .next-pagination-ellipsis {
  height: 32px;
  height: var(--form-element-medium-height, 32px);
  line-height: 32px;
  line-height: var(--form-element-medium-height, 32px);
  margin-left: 8px;
  margin-left: var(--pagination-ellipsis-margin, 8px);
  margin-right: 8px;
  margin-right: var(--pagination-ellipsis-margin, 8px);
}

.next-pagination.next-medium .next-pagination-ellipsis .next-icon-remote,
.next-pagination.next-medium .next-pagination-ellipsis:before {
  width: 12px;
  width: var(--pagination-ellipsis-size, 12px);
  font-size: 12px;
  font-size: var(--pagination-ellipsis-size, 12px);
  line-height: inherit;
}

.next-pagination.next-medium .next-pagination-display {
  font-size: 12px;
  font-size: var(--pagination-total-font-size, 12px);
}

.next-pagination.next-medium .next-pagination-display em {
  font-size: 12px;
  font-size: var(--pagination-current-font-size, 12px);
}

.next-pagination.next-medium .next-pagination-jump-text {
  font-size: 12px;
  font-size: var(--pagination-jump-font-size, 12px);
}

.next-pagination.next-medium .next-pagination-jump-input {
  width: 40px;
  width: var(--pagination-input-width, 40px);
}

.next-pagination.next-medium .next-pagination-size-selector-title {
  height: 32px;
  height: var(--form-element-medium-height, 32px);
  line-height: 32px;
  line-height: var(--form-element-medium-height, 32px);
  font-size: 12px;
  font-size: var(--pagination-jump-font-size, 12px);
  vertical-align: middle;
}

.next-pagination.next-medium .next-pagination-size-selector-btn {
  padding: 0 12px;
  padding: 0 var(--pagination-size-selector-number-padding, 12px);
}

.next-pagination.next-medium .next-pagination-item.next-next:not([disabled]) i,
.next-pagination.next-medium .next-pagination-item.next-prev:not([disabled]) i {
  color: #333;
  color: var(--pagination-arrow-color, #333);
}

.next-pagination.next-medium
  .next-pagination-item:hover.next-next:not([disabled])
  i,
.next-pagination.next-medium
  .next-pagination-item:hover.next-prev:not([disabled])
  i {
  color: #555;
  color: var(--pagination-arrow-hover-color, #555);
}

.next-pagination.next-medium.next-arrow-only .next-pagination-item.next-next,
.next-pagination.next-medium.next-arrow-only .next-pagination-item.next-prev {
  width: 28px;
  padding: 0;
}

.next-pagination.next-medium.next-arrow-only
  .next-pagination-item.next-next
  .next-icon,
.next-pagination.next-medium.next-arrow-only
  .next-pagination-item.next-prev
  .next-icon {
  margin: 0 auto;
}

.next-pagination.next-medium.next-arrow-prev-only
  .next-pagination-item.next-prev {
  width: 28px;
  padding: 0;
}

.next-pagination.next-medium.next-arrow-prev-only
  .next-pagination-item.next-prev
  .next-icon {
  margin: 0 auto;
}

.next-pagination.next-medium.next-no-border .next-pagination-item.next-next,
.next-pagination.next-medium.next-no-border .next-pagination-item.next-prev {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-pagination.next-medium.next-no-border
  .next-pagination-item.next-next
  .next-icon,
.next-pagination.next-medium.next-no-border
  .next-pagination-item.next-prev
  .next-icon {
  margin: 0;
}

.next-pagination.next-medium.next-no-border
  .next-pagination-item.next-next:not([disabled]):hover
  i,
.next-pagination.next-medium.next-no-border
  .next-pagination-item.next-prev:not([disabled]):hover
  i {
  color: #0064c8;
  color: var(--pagination-arrow-hover-color-noboder, #0064c8);
}

.next-pagination.next-medium.next-no-border .next-pagination-display {
  margin: 0 12px;
}

.next-pagination.next-medium.next-mini .next-pagination-item.next-prev {
  margin-right: 4px;
}

.next-pagination.next-medium.next-mini .next-pagination-item.next-next {
  margin-left: 4px;
}

.next-pagination.next-large .next-pagination-list {
  margin: 0 8px;
  margin: 0 var(--pagination-large-item-split, 8px);
}

.next-pagination.next-large .next-pagination-total {
  line-height: 36px;
  line-height: var(--form-element-large-height, 36px);
  vertical-align: middle;
}

.next-pagination.next-large .next-pagination-item {
  padding: 0 15px;
  border-width: 1px;
  border-width: var(--pagination-large-item-border-width, 1px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--pagination-large-item-corner, 2px);
  -moz-border-radius: var(--pagination-large-item-corner, 2px);
  border-radius: var(--pagination-large-item-corner, 2px);
}

.next-pagination.next-large .next-pagination-item + .next-pagination-item {
  margin: 0 0 0 8px;
  margin: 0 0 0 var(--pagination-large-item-split, 8px);
}

.next-pagination.next-large .next-pagination-ellipsis {
  height: 36px;
  height: var(--form-element-large-height, 36px);
  line-height: 36px;
  line-height: var(--form-element-large-height, 36px);
  margin-left: 8px;
  margin-left: var(--pagination-ellipsis-margin, 8px);
  margin-right: 8px;
  margin-right: var(--pagination-ellipsis-margin, 8px);
}

.next-pagination.next-large .next-pagination-ellipsis .next-icon-remote,
.next-pagination.next-large .next-pagination-ellipsis:before {
  width: 12px;
  width: var(--pagination-large-ellipsis-size, 12px);
  font-size: 12px;
  font-size: var(--pagination-large-ellipsis-size, 12px);
  line-height: inherit;
}

.next-pagination.next-large .next-pagination-display {
  font-size: 14px;
  font-size: var(--pagination-large-total-font-size, 14px);
}

.next-pagination.next-large .next-pagination-display em {
  font-size: 14px;
  font-size: var(--pagination-large-current-font-size, 14px);
}

.next-pagination.next-large .next-pagination-jump-text {
  font-size: 14px;
  font-size: var(--pagination-large-jump-font-size, 14px);
}

.next-pagination.next-large .next-pagination-jump-input {
  width: 48px;
  width: var(--pagination-large-input-width, 48px);
}

.next-pagination.next-large .next-pagination-size-selector-title {
  height: 36px;
  height: var(--form-element-large-height, 36px);
  line-height: 36px;
  line-height: var(--form-element-large-height, 36px);
  font-size: 14px;
  font-size: var(--pagination-large-jump-font-size, 14px);
  vertical-align: middle;
}

.next-pagination.next-large .next-pagination-size-selector-btn {
  padding: 0 16px;
  padding: 0 var(--pagination-large-size-selector-number-padding, 16px);
}

.next-pagination.next-large .next-pagination-item.next-next:not([disabled]) i,
.next-pagination.next-large .next-pagination-item.next-prev:not([disabled]) i {
  color: #333;
  color: var(--pagination-arrow-color, #333);
}

.next-pagination.next-large
  .next-pagination-item:hover.next-next:not([disabled])
  i,
.next-pagination.next-large
  .next-pagination-item:hover.next-prev:not([disabled])
  i {
  color: #555;
  color: var(--pagination-arrow-hover-color, #555);
}

.next-pagination.next-large.next-arrow-only .next-pagination-item.next-next,
.next-pagination.next-large.next-arrow-only .next-pagination-item.next-prev {
  width: 40px;
  padding: 0;
}

.next-pagination.next-large.next-arrow-only
  .next-pagination-item.next-next
  .next-icon,
.next-pagination.next-large.next-arrow-only
  .next-pagination-item.next-prev
  .next-icon {
  margin: 0 auto;
}

.next-pagination.next-large.next-arrow-prev-only
  .next-pagination-item.next-prev {
  width: 40px;
  padding: 0;
}

.next-pagination.next-large.next-arrow-prev-only
  .next-pagination-item.next-prev
  .next-icon {
  margin: 0 auto;
}

.next-pagination.next-large.next-no-border .next-pagination-item.next-next,
.next-pagination.next-large.next-no-border .next-pagination-item.next-prev {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-pagination.next-large.next-no-border
  .next-pagination-item.next-next
  .next-icon,
.next-pagination.next-large.next-no-border
  .next-pagination-item.next-prev
  .next-icon {
  margin: 0;
}

.next-pagination.next-large.next-no-border
  .next-pagination-item.next-next:not([disabled]):hover
  i,
.next-pagination.next-large.next-no-border
  .next-pagination-item.next-prev:not([disabled]):hover
  i {
  color: #0064c8;
  color: var(--pagination-arrow-hover-color-noboder, #0064c8);
}

.next-pagination.next-large.next-no-border .next-pagination-display {
  margin: 0 16px;
}

.next-pagination.next-large.next-mini .next-pagination-item.next-prev {
  margin-right: 8px;
}

.next-pagination.next-large.next-mini .next-pagination-item.next-next {
  margin-left: 8px;
}

.next-pagination-icon-prev:before {
  content: "\E61D";
  content: var(--pagination-icon-prev-content, "\E61D");
}

.next-pagination-icon-next:before {
  content: "\E619";
  content: var(--pagination-icon-next-content, "\E619");
}

.next-pagination-icon-ellipsis:before {
  content: "\E654";
  content: var(--pagination-icon-ellipsis-content, "\E654");
}

.next-paragraph {
  color: #111;
  color: var(--paragraph-text-color, #111);
}

.next-paragraph-short {
  line-height: 1.5;
  line-height: var(--paragraph-s-line-height, 1.5);
}

.next-paragraph-long {
  line-height: 1.6;
  line-height: var(--paragraph-l-line-height, 1.6);
}

.next-paragraph-small {
  font-size: 12px;
  font-size: var(--paragraph-s-font-size, 12px);
}

.next-paragraph-medium {
  font-size: 14px;
  font-size: var(--paragraph-m-font-size, 14px);
}

.next-progress-circle[dir="rtl"] .next-progress-circle-container {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.next-progress-line[dir="rtl"] .next-progress-line-overlay {
  left: auto;
  right: 0;
}

.next-progress-line,
.next-progress-line *,
.next-progress-line :after,
.next-progress-line :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-progress-line {
  width: 100%;
  display: inline-block;
  position: relative;
}

.next-progress-line-container {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.next-progress-line-underlay {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #e3e4e6;
  background-color: var(--progress-line-underlay-color, #e3e4e6);
}

.next-progress-line-overlay {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.next-progress-line-overlay-normal {
  background-color: #0064c8;
  background-color: var(--progress-line-normal-color, #0064c8);
}

.next-progress-line-overlay-success {
  background-color: #00a700;
  background-color: var(--progress-line-success-color, #00a700);
}

.next-progress-line-overlay-error {
  background-color: #c80000;
  background-color: var(--progress-line-error-color, #c80000);
}

.next-progress-line-overlay-started {
  background-color: #0064c8;
  background-color: var(--progress-line-started-color, #0064c8);
}

.next-progress-line-overlay-middle {
  background-color: #0064c8;
  background-color: var(--progress-line-middle-color, #0064c8);
}

.next-progress-line-overlay-finishing {
  background-color: #0064c8;
  background-color: var(--progress-line-finishing-color, #0064c8);
}

.next-progress-line.next-small .next-progress-line-overlay,
.next-progress-line.next-small .next-progress-line-underlay {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--progress-line-radius-s, 2px);
  -moz-border-radius: var(--progress-line-radius-s, 2px);
  border-radius: var(--progress-line-radius-s, 2px);
  height: 4px;
  height: var(--progress-line-height-size-s, 4px);
}

.next-progress-line.next-small .next-progress-line-overlay {
  top: 50%;
  margin-top: -2px;
  margin-top: calc(0px - var(--progress-line-height-size-s, 4px) / 2);
}

.next-progress-line.next-small .next-progress-line-text {
  font-size: 12px;
  font-size: var(--progress-line-font-s, 12px);
  line-height: 4px;
  line-height: var(--progress-line-height-size-s, 4px);
}

.next-progress-line.next-medium .next-progress-line-overlay,
.next-progress-line.next-medium .next-progress-line-underlay {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--progress-line-radius-m, 2px);
  -moz-border-radius: var(--progress-line-radius-m, 2px);
  border-radius: var(--progress-line-radius-m, 2px);
  height: 4px;
  height: var(--progress-line-height-size-m, 4px);
}

.next-progress-line.next-medium .next-progress-line-overlay {
  top: 50%;
  margin-top: -2px;
  margin-top: calc(0px - var(--progress-line-height-size-m, 4px) / 2);
}

.next-progress-line.next-medium .next-progress-line-text {
  font-size: 12px;
  font-size: var(--progress-line-font-m, 12px);
  line-height: 4px;
  line-height: var(--progress-line-height-size-m, 4px);
}

.next-progress-line.next-large .next-progress-line-overlay,
.next-progress-line.next-large .next-progress-line-underlay {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--progress-line-radius-l, 2px);
  -moz-border-radius: var(--progress-line-radius-l, 2px);
  border-radius: var(--progress-line-radius-l, 2px);
  height: 8px;
  height: var(--progress-line-height-size-l, 8px);
}

.next-progress-line.next-large .next-progress-line-overlay {
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--progress-line-height-size-l, 8px) / 2);
}

.next-progress-line.next-large .next-progress-line-text {
  font-size: 12px;
  font-size: var(--progress-line-font-l, 12px);
  line-height: 8px;
  line-height: var(--progress-line-height-size-l, 8px);
}

.next-progress-line-show-info .next-progress-line-container {
  padding-right: 60px;
  margin-right: -60px;
}

.next-progress-line-show-info .next-progress-line-text {
  width: 50px;
  text-align: left;
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
  color: #333;
  color: var(--progress-line-font-color, #333);
}

.next-progress-line-show-border .next-progress-line-underlay {
  border: 1px solid #c0c6cc;
  border: var(--progress-line-underlay-border-width, 1px) solid
    var(--progress-line-underlay-border-color, #c0c6cc);
}

.next-progress-line-show-border.next-small .next-progress-line-underlay {
  height: 6px;
  height: -webkit-calc(
    var(--progress-line-height-size-s, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--progress-line-height-size-s, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  height: calc(
    var(--progress-line-height-size-s, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
}

.next-progress-line-show-border.next-small .next-progress-line-overlay,
.next-progress-line-show-border.next-small .next-progress-line-underlay {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--progress-line-radius-s, 2px);
  -moz-border-radius: var(--progress-line-radius-s, 2px);
  border-radius: var(--progress-line-radius-s, 2px);
}

.next-progress-line-show-border.next-small .next-progress-line-overlay {
  height: 4px;
  height: var(--progress-line-height-size-s, 4px);
  top: 50%;
  margin-top: -2px;
  margin-top: calc(0px - var(--progress-line-height-size-s, 4px) / 2);
}

.next-progress-line-show-border.next-small .next-progress-line-text {
  font-size: 12px;
  font-size: var(--progress-line-font-s, 12px);
  line-height: 6px;
  line-height: -webkit-calc(
    var(--progress-line-height-size-s, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--progress-line-height-size-s, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  line-height: calc(
    var(--progress-line-height-size-s, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
}

.next-progress-line-show-border.next-medium .next-progress-line-underlay {
  height: 6px;
  height: -webkit-calc(
    var(--progress-line-height-size-m, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--progress-line-height-size-m, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  height: calc(
    var(--progress-line-height-size-m, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
}

.next-progress-line-show-border.next-medium .next-progress-line-overlay,
.next-progress-line-show-border.next-medium .next-progress-line-underlay {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--progress-line-radius-m, 2px);
  -moz-border-radius: var(--progress-line-radius-m, 2px);
  border-radius: var(--progress-line-radius-m, 2px);
}

.next-progress-line-show-border.next-medium .next-progress-line-overlay {
  height: 4px;
  height: var(--progress-line-height-size-m, 4px);
  top: 50%;
  margin-top: -2px;
  margin-top: calc(0px - var(--progress-line-height-size-m, 4px) / 2);
}

.next-progress-line-show-border.next-medium .next-progress-line-text {
  font-size: 12px;
  font-size: var(--progress-line-font-m, 12px);
  line-height: 6px;
  line-height: -webkit-calc(
    var(--progress-line-height-size-m, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--progress-line-height-size-m, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  line-height: calc(
    var(--progress-line-height-size-m, 4px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
}

.next-progress-line-show-border.next-large .next-progress-line-underlay {
  height: 10px;
  height: -webkit-calc(
    var(--progress-line-height-size-l, 8px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--progress-line-height-size-l, 8px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  height: calc(
    var(--progress-line-height-size-l, 8px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
}

.next-progress-line-show-border.next-large .next-progress-line-overlay,
.next-progress-line-show-border.next-large .next-progress-line-underlay {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--progress-line-radius-l, 2px);
  -moz-border-radius: var(--progress-line-radius-l, 2px);
  border-radius: var(--progress-line-radius-l, 2px);
}

.next-progress-line-show-border.next-large .next-progress-line-overlay {
  height: 8px;
  height: var(--progress-line-height-size-l, 8px);
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--progress-line-height-size-l, 8px) / 2);
}

.next-progress-line-show-border.next-large .next-progress-line-text {
  font-size: 12px;
  font-size: var(--progress-line-font-l, 12px);
  line-height: 10px;
  line-height: -webkit-calc(
    var(--progress-line-height-size-l, 8px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--progress-line-height-size-l, 8px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
  line-height: calc(
    var(--progress-line-height-size-l, 8px) +
      var(--progress-line-underlay-border-width, 1px) * 2
  );
}

.next-progress-circle,
.next-progress-circle *,
.next-progress-circle :after,
.next-progress-circle :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-progress-circle {
  position: relative;
  display: inline-block;
}

.next-progress-circle-underlay {
  stroke-width: 4px;
  stroke-width: var(--progress-circle-underlay-width, 4px);
  stroke: #e3e4e6;
  stroke: var(--progress-circle-underlay-color, #e3e4e6);
}

.next-progress-circle-overlay {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  stroke-linecap: round;
  stroke-linecap: var(--progress-circle-corner, round);
  stroke-width: 4px;
  stroke-width: var(--progress-circle-overlay-width, 4px);
}

.next-progress-circle-overlay-normal {
  stroke: #0064c8;
  stroke: var(--progress-circle-normal-color, #0064c8);
}

.next-progress-circle-overlay-success {
  stroke: #00a700;
  stroke: var(--progress-circle-success-color, #00a700);
}

.next-progress-circle-overlay-error {
  stroke: #c80000;
  stroke: var(--progress-circle-error-color, #c80000);
}

.next-progress-circle-overlay-started {
  stroke: #0064c8;
  stroke: var(--progress-circle-started-color, #0064c8);
}

.next-progress-circle-overlay-middle {
  stroke: #0064c8;
  stroke: var(--progress-circle-middle-color, #0064c8);
}

.next-progress-circle-overlay-finishing {
  stroke: #0064c8;
  stroke: var(--progress-circle-finishing-color, #0064c8);
}

.next-progress-circle.next-small {
  width: 100px;
  width: var(--progress-circle-size-s, 100px);
  height: 100px;
  height: var(--progress-circle-size-s, 100px);
  font-size: 14px;
  font-size: var(--progress-circle-font-s, 14px);
}

.next-progress-circle.next-medium {
  width: 116px;
  width: var(--progress-circle-size-m, 116px);
  height: 116px;
  height: var(--progress-circle-size-m, 116px);
  font-size: 14px;
  font-size: var(--progress-circle-font-m, 14px);
}

.next-progress-circle.next-large {
  width: 132px;
  width: var(--progress-circle-size-l, 132px);
  height: 132px;
  height: var(--progress-circle-size-l, 132px);
  font-size: 20px;
  font-size: var(--progress-circle-font-l, 20px);
}

.next-progress-circle-text {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  text-align: center;
  line-height: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: -o-transform 0.3s ease;
  -moz-transition: transform 0.3s ease, -moz-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease,
    -moz-transform 0.3s ease, -o-transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  color: #666;
  color: var(--progress-circle-text-color, #666);
}

.next-range {
  width: 100%;
  font-family: inherit;
  font-weight: 400;
  font-size: inherit;
  line-height: inherit;
  vertical-align: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  cursor: pointer;
}

.next-range,
.next-range *,
.next-range :after,
.next-range :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-range .next-range-inner {
  position: relative;
}

.next-range .next-range-inner:only-child {
  margin-top: auto;
  margin-bottom: auto;
}

.next-range .next-range-track {
  width: 100%;
}

.next-range .next-range-selected,
.next-range .next-range-track {
  position: absolute;
  top: 50%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--range-size-m-track-radius, 0);
  -moz-border-radius: var(--range-size-m-track-radius, 0);
  border-radius: var(--range-size-m-track-radius, 0);
}

.next-range .next-range-selected {
  width: 0;
  left: 0;
}

.next-range .next-range-scale {
  position: relative;
  width: 100%;
  height: 12px;
  height: var(--range-size-m-scale-height, 12px);
}

.next-range .next-range-scale .next-range-scale-item {
  position: absolute;
  left: 0;
  width: 2px;
  border: 1px solid;
  border: var(--range-size-m-scale-item-border-width, 1px) solid;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--range-size-m-scale-radius, 0);
  -moz-border-radius: var(--range-size-m-scale-radius, 0);
  border-radius: var(--range-size-m-scale-radius, 0);
}

.next-range .next-range-scale .next-range-scale-item:last-child {
  margin-left: -2px;
  margin-left: calc(0px - var(--range-size-m-scale-item-border-width, 1px) * 2);
}

.next-range .next-range-slider {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.next-range .next-range-slider-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid #577899;
  border: var(--range-size-slider-border-width, 1px)
    var(--range-size-slider-border-style, solid)
    var(--range-normal-slider-border-color, #577899);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: border-color 0.1s linear, -webkit-transform 0.1s linear;
  transition: border-color 0.1s linear, -webkit-transform 0.1s linear;
  -o-transition: border-color 0.1s linear, -o-transform 0.1s linear;
  -moz-transition: transform 0.1s linear, border-color 0.1s linear,
    -moz-transform 0.1s linear;
  transition: transform 0.1s linear, border-color 0.1s linear;
  transition: transform 0.1s linear, border-color 0.1s linear,
    -webkit-transform 0.1s linear, -moz-transform 0.1s linear,
    -o-transform 0.1s linear;
  transition: transform 0.1s linear, border-color 0.1s linear,
    -webkit-transform 0.1s linear;
}

.next-range
  .next-range-frag.next-range-active
  .next-range-slider
  .next-range-slider-inner,
.next-range
  .next-range-slider.next-range-slider-moving
  .next-range-slider-inner {
  border: 2px solid #0064c8;
  border: var(--line-2, 2px) var(--line-solid, solid)
    var(--range-normal-selected-color, #0064c8);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.next-range .next-range-mark {
  position: relative;
  cursor: auto;
}

.next-range .next-range-mark .next-range-mark-text {
  position: absolute;
  left: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  padding-left: 2px;
  text-align: center;
}

.next-range .next-range-frag {
  position: absolute;
  top: 0;
}

.next-range .next-range-frag .next-range-slider {
  left: 0;
}

.next-range .next-range-frag .next-range-slider:nth-child(2) {
  left: 100%;
}

.next-range .next-range-frag .next-range-selected {
  width: 100%;
}

.next-range.disabled {
  cursor: not-allowed;
}

.next-range.disabled .next-range-mark {
  cursor: auto;
}

.next-range .next-range-track {
  background-color: #e3e4e6;
  background-color: var(--range-normal-unselected-color, #e3e4e6);
}

.next-range .next-range-track:hover {
  background-color: #e3e4e6;
  background-color: var(--range-normal-unselected-color-hover, #e3e4e6);
}

.next-range .next-range-selected {
  background-color: #0064c8;
  background-color: var(--range-normal-selected-color, #0064c8);
}

.next-range .next-range-selected:hover {
  background-color: #0064c8;
  background-color: var(--range-normal-selected-color-hover, #0064c8);
}

.next-range .next-range-scale .next-range-scale-item {
  border-color: #e3e4e6;
  border-color: var(--range-normal-unselected-color, #e3e4e6);
  background-color: #e3e4e6;
  background-color: var(--range-normal-unselected-color, #e3e4e6);
}

.next-range .next-range-scale .next-range-scale-item:hover {
  border-color: #e3e4e6;
  border-color: var(--range-normal-unselected-color-hover, #e3e4e6);
}

.next-range .next-range-scale .next-range-scale-item.activated {
  border-color: #0064c8;
  border-color: var(--range-normal-selected-color, #0064c8);
  background-color: #0064c8;
  background-color: var(--range-normal-selected-color, #0064c8);
}

.next-range .next-range-scale .next-range-scale-item.activated:hover {
  border-color: #0064c8;
  border-color: var(--range-normal-selected-color-hover, #0064c8);
}

.next-range .next-range-slider-inner {
  background-color: #fff;
  background-color: var(--range-normal-slider-bg-color, #fff);
  border-color: #577899;
  border-color: var(--range-normal-slider-border-color, #577899);
}

.next-range .next-range-slider-inner:hover {
  background-color: #fff;
  background-color: var(--range-normal-slider-bg-color-hover, #fff);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.next-range .next-range-mark .next-range-mark-text {
  color: #333;
  color: var(--range-normal-mark-color, #333);
}

.next-range .next-range-mark .next-range-mark-text:hover {
  color: #888;
  color: var(--range-normal-mark-color-hover, #888);
}

.next-range .next-range-mark .next-range-mark-text.activated {
  color: #333;
  color: var(--range-normal-mark-selected-color, #333);
}

.next-range .next-range-mark .next-range-mark-text.activated:hover {
  color: #111;
  color: var(--range-normal-mark-selected-color-hover, #111);
}

.next-range.disabled .next-range-track {
  background-color: #e3e4e6;
  background-color: var(--range-normal-unselected-color-disabled, #e3e4e6);
}

.next-range.disabled .next-range-selected {
  background-color: #ccc;
  background-color: var(--range-normal-selected-color-disabled, #ccc);
}

.next-range.disabled .next-range-scale-item {
  border-color: #e3e4e6;
  border-color: var(--range-normal-unselected-color-disabled, #e3e4e6);
}

.next-range.disabled .next-range-scale-item.activated {
  border-color: #ccc;
  border-color: var(--range-normal-selected-color-disabled, #ccc);
}

.next-range.disabled .next-range-slider-inner {
  background-color: #fff;
  background-color: var(--range-normal-slider-bg-color-disabled, #fff);
  border-color: #c0c6cc;
  border-color: var(--range-normal-slider-border-color-disabled, #c0c6cc);
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-range.disabled .next-range-mark-text {
  color: #ccc;
  color: var(--range-normal-mark-color-disabled, #ccc);
}

.next-range.disabled .next-range-mark-text.activated {
  color: #ccc;
  color: var(--range-normal-mark-selected-color-disabled, #ccc);
}

.next-range .next-range-selected,
.next-range .next-range-track {
  height: 4px;
  height: var(--range-size-m-track-height, 4px);
  margin-top: -2px;
  margin-top: calc(0px - var(--range-size-m-track-height, 4px) / 2);
}

.next-range .next-range-frag {
  margin-top: 4px;
  margin-top: calc(
    var(--range-size-m-scale-height, 12px) / 2 -
      var(--range-size-m-track-height, 4px) / 2
  );
  height: 4px;
  height: var(--range-size-m-track-height, 4px);
}

.next-range .next-range-slider {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --range-size-m-slider-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(
    --range-size-m-slider-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  box-shadow: var(
    --range-size-m-slider-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
}

.next-range .next-range-slider,
.next-range .next-range-slider-inner {
  height: 16px;
  height: var(--range-size-m-slider-hw, 16px);
  width: 16px;
  width: var(--range-size-m-slider-hw, 16px);
  margin-top: -8px;
  margin-top: calc(0px - var(--range-size-m-slider-hw, 16px) / 2);
  margin-left: -8px;
  margin-left: calc(0px - var(--range-size-m-slider-hw, 16px) / 2);
}

.next-range .next-range-mark {
  display: block;
}

.next-range .next-range-mark .next-range-mark-text {
  font-size: 12px;
  font-size: var(--range-size-m-mark-font-size, 12px);
  font-weight: 400;
  font-weight: var(--range-size-m-mark-font-weight, normal);
  line-height: 20px;
  line-height: var(--range-size-m-mark-font-lineheight, 20px);
  height: 20px;
  height: var(--range-size-m-mark-font-lineheight, 20px);
}

.next-range .next-range-mark.next-range-mark-below {
  height: 30px;
  height: var(--range-size-m-mark-top, 30px);
}

.next-range .next-range-mark.next-range-mark-below .next-range-mark-text {
  bottom: 0;
}

.next-range .next-range-mark.next-range-mark-above {
  height: 30px;
  height: var(--range-size-m-mark-top, 30px);
}

.next-range .next-range-scale .next-range-scale-item {
  height: 12px;
  height: var(--range-size-m-scale-height, 12px);
}

.next-range.simulation-hover > .next-range-slider-inner {
  background-color: #fff;
  background-color: var(--range-normal-slider-bg-color, #fff);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.next-range.simulation-click > .next-range-slider-inner {
  border: 2px solid #0064c8;
  border: 2px solid var(--range-normal-selected-color, #0064c8);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.next-range[dir="rtl"] .next-range-mark {
  position: relative;
  cursor: auto;
}

.next-range[dir="rtl"] .next-range-mark .next-range-mark-text {
  position: absolute;
  right: 0;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -o-transform: translateX(50%);
  transform: translateX(50%);
  padding-right: 2px;
  text-align: center;
}

.next-rating[dir="rtl"] .next-rating-overlay {
  right: 0;
  left: auto;
}

.next-rating[dir="rtl"] .next-rating-overlay .next-rating-icon,
.next-rating[dir="rtl"] .next-rating-underlay .next-rating-icon {
  margin-right: 4px;
  margin-left: 0;
}

.next-rating[dir="rtl"] .next-rating-overlay .next-rating-icon:last-child,
.next-rating[dir="rtl"] .next-rating-underlay .next-rating-icon:last-child {
  margin-left: 4px;
}

.next-rating {
  vertical-align: top;
  display: inline-block;
  position: relative;
}

.next-rating:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-rating-base,
.next-rating-text {
  float: left;
}

.next-rating-base-disabled,
.next-rating-base-disabled .next-rating-overlay .next-rating-icon,
.next-rating-base-disabled .next-rating-underlay .next-rating-icon {
  cursor: not-allowed;
}

.next-rating-symbol-icon:before {
  content: "\E814";
  content: var(--rating-grade-icon-content, "\E814");
}

.next-rating-underlay {
  white-space: nowrap;
  overflow: hidden;
}

.next-rating-underlay .next-icon {
  color: #fff;
  color: var(--rating-normal-underlay-color, #fff);
}

.next-rating-stroke-mode .next-rating-underlay .next-icon {
  color: transparent;
  -webkit-text-stroke: 1px #0064c8;
  -webkit-text-stroke: 1px var(--rating-normal-overlay-color, #0064c8);
}

.next-rating-overlay {
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  width: 0;
  top: 0;
  left: 0;
}

.next-rating-overlay .next-icon {
  color: #0064c8;
  color: var(--rating-normal-overlay-color, #0064c8);
}

.next-rating-overlay .next-rating-icon,
.next-rating-underlay .next-rating-icon {
  cursor: pointer;
  margin-left: 4px;
}

.next-rating-overlay .next-rating-icon:last-child,
.next-rating-underlay .next-rating-icon:last-child {
  margin-right: 4px;
}

.next-rating-overlay .next-icon,
.next-rating-underlay .next-icon {
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-rating-overlay .next-icon.hover,
.next-rating-underlay .next-icon.hover {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  -moz-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.next-rating-overlay .next-icon.clicked,
.next-rating-underlay .next-icon.clicked {
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  -moz-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
}

.next-rating-info {
  position: absolute;
  top: -webkit-calc(100% + 4px);
  top: -moz-calc(100% + 4px);
  top: calc(100% + 4px);
  left: 0;
  border: 1px solid #004080;
  border: 1px solid var(--color-fill1-4, #004080);
  background: #fff;
  padding: 4px 8px 3px;
  font-size: 12px;
  white-space: nowrap;
}

.next-rating-info:after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  border-left: 1px solid #004080;
  border-top: 1px solid #004080;
  border: 1px solid var(--color-fill1-4, #004080);
  background: #fff;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  top: -3px;
  left: 4px;
}

.next-rating.hover,
.next-rating:focus {
  outline: none;
}

.next-rating.hover .next-rating-overlay .next-icon,
.next-rating:focus .next-rating-overlay .next-icon {
  color: #fff;
  color: var(--rating-normal-overlay-hover-color, #fff);
}

.next-rating-grade-low.hover .next-rating-overlay .next-icon,
.next-rating-grade-low .next-rating-overlay .next-icon {
  color: #fac800;
  color: var(--rating-grade-low-overlay-color, #fac800);
}

.next-rating-grade-high .next-rating-overlay .next-icon {
  color: #fac800;
  color: var(--rating-grade-high-overlay-color, #fac800);
}

.next-rating-grade-high.hover .next-rating-overlay .next-icon {
  color: #fac800;
  color: var(--rating-grade-high-overlay-hover-color, #fac800);
}

.next-rating-small {
  font-size: 12px;
  font-size: var(--rating-small-font-size, 12px);
}

.next-rating-small .next-icon .next-icon-remote,
.next-rating-small .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-rating-small .next-rating-text {
  margin-left: 8px;
  margin-left: var(--rating-small-text-margin-left, 8px);
}

.next-rating-medium {
  font-size: 12px;
  font-size: var(--rating-medium-font-size, 12px);
}

.next-rating-medium .next-icon .next-icon-remote,
.next-rating-medium .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-rating-medium .next-rating-text {
  margin-left: 12px;
  margin-left: var(--rating-medium-text-margin-left, 12px);
}

.next-rating-large {
  font-size: 14px;
  font-size: var(--rating-large-font-size, 14px);
}

.next-rating-large .next-icon .next-icon-remote,
.next-rating-large .next-icon:before {
  width: 20px;
  font-size: 20px;
  line-height: inherit;
}

.next-rating-large .next-rating-text {
  margin-left: 16px;
  margin-left: var(--rating-large-text-margin-left, 16px);
}

.next-search-simple[dir="rtl"].next-large .next-search-icon {
  margin-left: 12px;
  margin-right: 0;
}

.next-search-simple[dir="rtl"].next-medium .next-search-icon {
  margin-left: 8px;
  margin-right: 0;
}

.next-search-simple[dir="rtl"].next-normal
  .next-search-left
  .next-search-left-addon {
  border-left: 1px solid #e3e4e6;
  border-left: 1px solid var(--search-simple-normal-split-color, #e3e4e6);
  border-right: none;
}

.next-search-simple[dir="rtl"].next-dark .next-search-left {
  border-color: #577899;
  border-color: var(--search-simple-dark-color, #577899);
}

.next-search-simple[dir="rtl"].next-dark
  .next-search-left
  .next-search-left-addon {
  border-right: 1px solid #577899;
  border-right: 1px solid var(--search-simple-dark-split-color, #577899);
}

.next-search-simple[dir="rtl"].next-dark:hover .next-search-left {
  border-color: #577899;
  border-color: var(--search-simple-dark-hover-color, #577899);
}

.next-search-simple[dir="rtl"].next-dark .next-search-icon {
  color: #888;
  color: var(--search-simple-dark-icon-text-color, #888);
}

.next-search-simple[dir="rtl"].next-dark .next-search-icon:hover {
  color: #555;
  color: var(--search-simple-dark-icon-hover-text-color, #555);
}

.next-search-normal[dir="rtl"] .next-search-left {
  border-left: none;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-right-radius: var(--search-normal-corner, 2px);
  -moz-border-radius-topright: var(--search-normal-corner, 2px);
  border-top-right-radius: var(--search-normal-corner, 2px);
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-right-radius: var(--search-normal-corner, 2px);
  -moz-border-radius-bottomright: var(--search-normal-corner, 2px);
  border-bottom-right-radius: var(--search-normal-corner, 2px);
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}

.next-search-normal[dir="rtl"] .next-search-btn.next-btn {
  -webkit-border-radius: 2px 0 0 2px !important;
  -moz-border-radius: 2px 0 0 2px !important;
  border-radius: 2px 0 0 2px !important;
  -webkit-border-radius: var(--search-normal-corner, 2px) 0 0
    var(--search-normal-corner, 2px) !important;
  -moz-border-radius: var(--search-normal-corner, 2px) 0 0
    var(--search-normal-corner, 2px) !important;
  border-radius: var(--search-normal-corner, 2px) 0 0
    var(--search-normal-corner, 2px) !important;
}

.next-search-normal[dir="rtl"] .next-input {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--search-normal-corner, 2px)
    var(--search-normal-corner, 2px) 0;
  -moz-border-radius: 0 var(--search-normal-corner, 2px)
    var(--search-normal-corner, 2px) 0;
  border-radius: 0 var(--search-normal-corner, 2px)
    var(--search-normal-corner, 2px) 0;
}

.next-search-normal[dir="rtl"].next-primary .next-input {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 1px;
  -moz-border-radius-topright: 1px;
  border-top-right-radius: 1px;
  -webkit-border-top-right-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -moz-border-radius-topright: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  border-top-right-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -webkit-border-bottom-right-radius: 1px;
  -moz-border-radius-bottomright: 1px;
  border-bottom-right-radius: 1px;
  -webkit-border-bottom-right-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -moz-border-radius-bottomright: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  border-bottom-right-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
}

.next-search-normal[dir="rtl"].next-primary
  .next-search-left
  .next-search-left-addon {
  border-left: 1px solid #c0c6cc;
  border-left: 1px solid var(--search-normal-primary-split-color, #c0c6cc);
  border-right: none;
}

.next-search-normal[dir="rtl"].next-secondary .next-input {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 1px;
  -moz-border-radius-topright: 1px;
  border-top-right-radius: 1px;
  -webkit-border-top-right-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -moz-border-radius-topright: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  border-top-right-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -webkit-border-bottom-right-radius: 1px;
  -moz-border-radius-bottomright: 1px;
  border-bottom-right-radius: 1px;
  -webkit-border-bottom-right-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -moz-border-radius-bottomright: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  border-bottom-right-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
}

.next-search-normal[dir="rtl"].next-secondary
  .next-search-left
  .next-search-left-addon {
  border-left: 1px solid #c0c6cc;
  border-left: 1px solid var(--search-normal-secondary-split-color, #c0c6cc);
  border-right: none;
}

.next-search-normal[dir="rtl"].next-normal .next-input {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 1px;
  -moz-border-radius-topright: 1px;
  border-top-right-radius: 1px;
  -webkit-border-top-right-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -moz-border-radius-topright: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  border-top-right-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -webkit-border-bottom-right-radius: 1px;
  -moz-border-radius-bottomright: 1px;
  border-bottom-right-radius: 1px;
  -webkit-border-bottom-right-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -moz-border-radius-bottomright: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  border-bottom-right-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
}

.next-search-normal[dir="rtl"].next-normal
  .next-search-left
  .next-search-left-addon {
  border-left: 1px solid #e3e4e6;
  border-left: 1px solid var(--search-normal-normal-split-color, #e3e4e6);
  border-right: none;
}

.next-search-normal[dir="rtl"].next-dark
  .next-search-left
  .next-search-left-addon {
  border-left: 1px solid #0064c8;
  border-left: 1px solid var(--search-normal-dark-color, #0064c8);
  border-right: none;
}

.next-search {
  width: 100%;
  display: inline-block;
}

.next-search,
.next-search *,
.next-search :after,
.next-search :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-search .next-input,
.next-search .next-select {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-search .next-select .next-input,
.next-search .next-select .next-input .next-input-text-field {
  height: auto;
}

.next-search .next-search-left {
  border-style: solid;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-search .next-search-left-addon .next-input,
.next-search .next-search-left-addon .next-select-trigger-search {
  min-height: 100%;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
}

.next-search .next-search-left-addon .next-select-values {
  line-height: 1;
}

.next-search .next-search-left-addon.next-input-group-addon .next-select {
  margin: 0;
}

.next-search .next-search-left-addon + .next-search-input .next-input {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
}

.next-search .next-search-input {
  width: 100%;
}

.next-search .next-search-btn {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-search .next-search-symbol-icon:before {
  content: "\E656";
  content: var(--search-search-icon-content, "\E656");
}

.next-search-normal {
  width: 600px;
}

.next-search-normal .next-search-left {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-top-left-radius: var(--search-normal-corner, 2px);
  -moz-border-radius-topleft: var(--search-normal-corner, 2px);
  border-top-left-radius: var(--search-normal-corner, 2px);
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-left-radius: var(--search-normal-corner, 2px);
  -moz-border-radius-bottomleft: var(--search-normal-corner, 2px);
  border-bottom-left-radius: var(--search-normal-corner, 2px);
}

.next-search-normal .next-input {
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  -webkit-border-radius: var(--search-normal-corner, 2px) 0 0
    var(--search-normal-corner, 2px);
  -moz-border-radius: var(--search-normal-corner, 2px) 0 0
    var(--search-normal-corner, 2px);
  border-radius: var(--search-normal-corner, 2px) 0 0
    var(--search-normal-corner, 2px);
}

.next-search-normal .next-btn {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--search-normal-corner, 2px)
    var(--search-normal-corner, 2px) 0;
  -moz-border-radius: 0 var(--search-normal-corner, 2px)
    var(--search-normal-corner, 2px) 0;
  border-radius: 0 var(--search-normal-corner, 2px)
    var(--search-normal-corner, 2px) 0;
}

.next-search-normal.next-primary .next-search-left {
  border-color: #c0c6cc;
  border-color: var(--search-normal-primary-color, #c0c6cc);
}

.next-search-normal.next-primary .next-search-left .next-search-left-addon {
  border-right: 1px solid #c0c6cc;
  border-right: 1px solid var(--search-normal-primary-split-color, #c0c6cc);
}

.next-search-normal.next-primary:hover .next-btn,
.next-search-normal.next-primary:hover .next-search-left {
  border-color: #c0c6cc;
  border-color: var(--search-normal-primary-hover-color, #c0c6cc);
}

.next-search-normal.next-primary .next-search-btn {
  background: #c0c6cc;
  background: var(--search-normal-primary-color, #c0c6cc);
  border-color: #c0c6cc;
  border-color: var(--search-normal-primary-color, #c0c6cc);
  color: #333;
  color: var(--search-normal-primary-btn-text-color, #333);
}

.next-search-normal.next-primary .next-search-btn:hover {
  background: #c0c6cc;
  background: var(--search-normal-primary-btn-hover-bg-color, #c0c6cc);
  border-color: #c0c6cc;
  border-color: var(--search-normal-primary-hover-color, #c0c6cc);
  color: #333;
  color: var(--search-normal-primary-btn-hover-text-color, #333);
}

.next-search-normal.next-primary .next-search-btn .next-icon {
  color: #333;
  color: var(--search-normal-primary-btn-text-color, #333);
}

.next-search-normal.next-primary .next-search-btn .next-icon:hover {
  color: #333;
  color: var(--search-normal-primary-btn-hover-text-color, #333);
}

.next-search-normal.next-primary.next-large {
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--search-normal-primary-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--search-normal-primary-shadow, none);
  box-shadow: var(--search-normal-primary-shadow, none);
}

.next-search-normal.next-primary.next-large .next-search-btn,
.next-search-normal.next-primary.next-large .next-search-left {
  border-width: 1px;
  border-width: var(--search-normal-primary-border-width, 1px);
  height: 36px;
  height: var(--search-normal-primary-l-height, 36px);
}

.next-search-normal.next-primary.next-large .next-search-input {
  overflow-y: hidden;
}

.next-search-normal.next-primary.next-large .next-search-input,
.next-search-normal.next-primary.next-large .next-search-input input {
  height: 34px;
  height: -webkit-calc(
    var(--search-normal-primary-l-height, 36px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-primary-l-height, 36px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-primary-l-height, 36px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
}

.next-search-normal.next-primary.next-large .next-search-input input {
  line-height: 34px \0;
  line-height: -webkit-calc(
      var(--search-normal-primary-l-height, 36px) -
        var(--search-normal-primary-border-width, 1px) * 2
    )
    \0;
  line-height: -moz-calc(
      var(--search-normal-primary-l-height, 36px) -
        var(--search-normal-primary-border-width, 1px) * 2
    )
    \0;
  line-height: calc(
      var(--search-normal-primary-l-height, 36px) -
        var(--search-normal-primary-border-width, 1px) * 2
    )
    \0;
}

.next-search-normal.next-primary.next-large .next-select {
  height: 34px;
  height: -webkit-calc(
    var(--search-normal-primary-l-height, 36px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-primary-l-height, 36px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-primary-l-height, 36px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
}

.next-search-normal.next-primary.next-large .next-search-btn {
  font-size: 14px;
  font-size: var(--search-normal-primary-l-btn-text-size, 14px);
}

.next-search-normal.next-primary.next-large
  .next-search-btn
  .next-icon
  .next-icon-remote,
.next-search-normal.next-primary.next-large .next-search-btn .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-search-normal.next-primary.next-large
  .next-search-btn
  .next-search-btn-text {
  display: inline-block;
  padding-left: 0;
  padding-left: var(--search-normal-primary-btn-text-padding-left, 0);
}

.next-search-normal.next-primary.next-medium {
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--search-normal-primary-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--search-normal-primary-shadow, none);
  box-shadow: var(--search-normal-primary-shadow, none);
}

.next-search-normal.next-primary.next-medium .next-search-btn,
.next-search-normal.next-primary.next-medium .next-search-left {
  border-width: 1px;
  border-width: var(--search-normal-primary-border-width, 1px);
  height: 32px;
  height: var(--search-normal-primary-m-height, 32px);
}

.next-search-normal.next-primary.next-medium .next-search-input {
  overflow-y: hidden;
}

.next-search-normal.next-primary.next-medium .next-search-input,
.next-search-normal.next-primary.next-medium .next-search-input input {
  height: 30px;
  height: -webkit-calc(
    var(--search-normal-primary-m-height, 32px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-primary-m-height, 32px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-primary-m-height, 32px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
}

.next-search-normal.next-primary.next-medium .next-search-input input {
  line-height: 30px \0;
  line-height: -webkit-calc(
      var(--search-normal-primary-m-height, 32px) -
        var(--search-normal-primary-border-width, 1px) * 2
    )
    \0;
  line-height: -moz-calc(
      var(--search-normal-primary-m-height, 32px) -
        var(--search-normal-primary-border-width, 1px) * 2
    )
    \0;
  line-height: calc(
      var(--search-normal-primary-m-height, 32px) -
        var(--search-normal-primary-border-width, 1px) * 2
    )
    \0;
}

.next-search-normal.next-primary.next-medium .next-select {
  height: 30px;
  height: -webkit-calc(
    var(--search-normal-primary-m-height, 32px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-primary-m-height, 32px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-primary-m-height, 32px) -
      var(--search-normal-primary-border-width, 1px) * 2
  );
}

.next-search-normal.next-primary.next-medium .next-search-btn {
  font-size: 12px;
  font-size: var(--search-normal-primary-m-btn-text-size, 12px);
}

.next-search-normal.next-primary.next-medium
  .next-search-btn
  .next-icon
  .next-icon-remote,
.next-search-normal.next-primary.next-medium
  .next-search-btn
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-search-normal.next-primary.next-medium
  .next-search-btn
  .next-search-btn-text {
  display: inline-block;
  padding-left: 0;
  padding-left: var(--search-normal-primary-btn-text-padding-left, 0);
}

.next-search-normal.next-primary .next-input {
  -webkit-border-top-left-radius: 1px;
  -moz-border-radius-topleft: 1px;
  border-top-left-radius: 1px;
  -webkit-border-top-left-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -moz-border-radius-topleft: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  border-top-left-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -webkit-border-bottom-left-radius: 1px;
  -moz-border-radius-bottomleft: 1px;
  border-bottom-left-radius: 1px;
  -webkit-border-bottom-left-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  -moz-border-radius-bottomleft: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
  border-bottom-left-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-primary-border-width, 1px)
  );
}

.next-search-normal.next-secondary .next-search-left {
  border-color: #c0c6cc;
  border-color: var(--search-normal-secondary-color, #c0c6cc);
}

.next-search-normal.next-secondary .next-search-left .next-search-left-addon {
  border-right: 1px solid #c0c6cc;
  border-right: 1px solid var(--search-normal-secondary-split-color, #c0c6cc);
}

.next-search-normal.next-secondary:hover .next-btn,
.next-search-normal.next-secondary:hover .next-search-left {
  border-color: #c0c6cc;
  border-color: var(--search-normal-secondary-hover-color, #c0c6cc);
}

.next-search-normal.next-secondary .next-search-btn {
  background: #c0c6cc;
  background: var(--search-normal-secondary-btn-bg-color, #c0c6cc);
  border-color: #c0c6cc;
  border-color: var(--search-normal-secondary-btn-bg-color, #c0c6cc);
  color: #333;
  color: var(--search-normal-secondary-btn-text-color, #333);
}

.next-search-normal.next-secondary .next-search-btn:hover {
  background: #c0c6cc;
  background: var(--search-normal-secondary-btn-hover-bg-color, #c0c6cc);
  border-color: #c0c6cc;
  border-color: var(--search-normal-secondary-hover-color, #c0c6cc);
  color: #333;
  color: var(--search-normal-secondary-btn-hover-text-color, #333);
}

.next-search-normal.next-secondary .next-search-btn .next-icon {
  color: #333;
  color: var(--search-normal-secondary-btn-text-color, #333);
}

.next-search-normal.next-secondary .next-search-btn .next-icon:hover {
  color: #333;
  color: var(--search-normal-secondary-btn-hover-text-color, #333);
}

.next-search-normal.next-secondary.next-large {
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--search-normal-secondary-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--search-normal-secondary-shadow, none);
  box-shadow: var(--search-normal-secondary-shadow, none);
}

.next-search-normal.next-secondary.next-large .next-search-btn,
.next-search-normal.next-secondary.next-large .next-search-left {
  border-width: 1px;
  border-width: var(--search-normal-secondary-border-width, 1px);
  height: 36px;
  height: var(--search-normal-secondary-l-height, 36px);
}

.next-search-normal.next-secondary.next-large .next-search-input {
  overflow-y: hidden;
}

.next-search-normal.next-secondary.next-large .next-search-input,
.next-search-normal.next-secondary.next-large .next-search-input input {
  height: 34px;
  height: -webkit-calc(
    var(--search-normal-secondary-l-height, 36px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-secondary-l-height, 36px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-secondary-l-height, 36px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
}

.next-search-normal.next-secondary.next-large .next-search-input input {
  line-height: 34px \0;
  line-height: -webkit-calc(
      var(--search-normal-secondary-l-height, 36px) -
        var(--search-normal-secondary-border-width, 1px) * 2
    )
    \0;
  line-height: -moz-calc(
      var(--search-normal-secondary-l-height, 36px) -
        var(--search-normal-secondary-border-width, 1px) * 2
    )
    \0;
  line-height: calc(
      var(--search-normal-secondary-l-height, 36px) -
        var(--search-normal-secondary-border-width, 1px) * 2
    )
    \0;
}

.next-search-normal.next-secondary.next-large .next-select {
  height: 34px;
  height: -webkit-calc(
    var(--search-normal-secondary-l-height, 36px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-secondary-l-height, 36px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-secondary-l-height, 36px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
}

.next-search-normal.next-secondary.next-large .next-search-btn {
  font-size: 14px;
  font-size: var(--search-normal-secondary-l-btn-text-size, 14px);
}

.next-search-normal.next-secondary.next-large
  .next-search-btn
  .next-icon
  .next-icon-remote,
.next-search-normal.next-secondary.next-large
  .next-search-btn
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-search-normal.next-secondary.next-large
  .next-search-btn
  .next-search-btn-text {
  display: inline-block;
  padding-left: 0;
  padding-left: var(--search-normal-secondary-btn-text-padding-left, 0);
}

.next-search-normal.next-secondary.next-medium {
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--search-normal-secondary-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--search-normal-secondary-shadow, none);
  box-shadow: var(--search-normal-secondary-shadow, none);
}

.next-search-normal.next-secondary.next-medium .next-search-btn,
.next-search-normal.next-secondary.next-medium .next-search-left {
  border-width: 1px;
  border-width: var(--search-normal-secondary-border-width, 1px);
  height: 32px;
  height: var(--search-normal-secondary-m-height, 32px);
}

.next-search-normal.next-secondary.next-medium .next-search-input {
  overflow-y: hidden;
}

.next-search-normal.next-secondary.next-medium .next-search-input,
.next-search-normal.next-secondary.next-medium .next-search-input input {
  height: 30px;
  height: -webkit-calc(
    var(--search-normal-secondary-m-height, 32px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-secondary-m-height, 32px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-secondary-m-height, 32px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
}

.next-search-normal.next-secondary.next-medium .next-search-input input {
  line-height: 30px \0;
  line-height: -webkit-calc(
      var(--search-normal-secondary-m-height, 32px) -
        var(--search-normal-secondary-border-width, 1px) * 2
    )
    \0;
  line-height: -moz-calc(
      var(--search-normal-secondary-m-height, 32px) -
        var(--search-normal-secondary-border-width, 1px) * 2
    )
    \0;
  line-height: calc(
      var(--search-normal-secondary-m-height, 32px) -
        var(--search-normal-secondary-border-width, 1px) * 2
    )
    \0;
}

.next-search-normal.next-secondary.next-medium .next-select {
  height: 30px;
  height: -webkit-calc(
    var(--search-normal-secondary-m-height, 32px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-secondary-m-height, 32px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-secondary-m-height, 32px) -
      var(--search-normal-secondary-border-width, 1px) * 2
  );
}

.next-search-normal.next-secondary.next-medium .next-search-btn {
  font-size: 12px;
  font-size: var(--search-normal-secondary-m-btn-text-size, 12px);
}

.next-search-normal.next-secondary.next-medium
  .next-search-btn
  .next-icon
  .next-icon-remote,
.next-search-normal.next-secondary.next-medium
  .next-search-btn
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-search-normal.next-secondary.next-medium
  .next-search-btn
  .next-search-btn-text {
  display: inline-block;
  padding-left: 0;
  padding-left: var(--search-normal-secondary-btn-text-padding-left, 0);
}

.next-search-normal.next-secondary .next-input {
  -webkit-border-top-left-radius: 1px;
  -moz-border-radius-topleft: 1px;
  border-top-left-radius: 1px;
  -webkit-border-top-left-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-secondary-border-width, 1px)
  );
  -moz-border-radius-topleft: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-secondary-border-width, 1px)
  );
  border-top-left-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-secondary-border-width, 1px)
  );
  -webkit-border-bottom-left-radius: 1px;
  -moz-border-radius-bottomleft: 1px;
  border-bottom-left-radius: 1px;
  -webkit-border-bottom-left-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-secondary-border-width, 1px)
  );
  -moz-border-radius-bottomleft: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-secondary-border-width, 1px)
  );
  border-bottom-left-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-secondary-border-width, 1px)
  );
}

.next-search-normal.next-normal .next-search-left {
  border-color: #e3e4e6;
  border-color: var(--search-normal-normal-color, #e3e4e6);
}

.next-search-normal.next-normal .next-search-left .next-search-left-addon {
  border-right: 1px solid #e3e4e6;
  border-right: 1px solid var(--search-normal-normal-split-color, #e3e4e6);
}

.next-search-normal.next-normal:hover .next-btn,
.next-search-normal.next-normal:hover .next-search-left {
  border-color: #d1d5d9;
  border-color: var(--search-normal-normal-hover-color, #d1d5d9);
}

.next-search-normal.next-normal .next-search-btn {
  background: #f8f8f9;
  background: var(--search-normal-normal-btn-bg-color, #f8f8f9);
  border-color: #e3e4e6;
  border-color: var(--search-normal-normal-color, #e3e4e6);
  color: #333;
  color: var(--search-normal-normal-btn-text-color, #333);
}

.next-search-normal.next-normal .next-search-btn:hover {
  background: #f8f8f9;
  background: var(--search-normal-normal-btn-hover-bg-color, #f8f8f9);
  border-color: #d1d5d9;
  border-color: var(--search-normal-normal-hover-color, #d1d5d9);
  color: #333;
  color: var(--search-normal-normal-btn-hover-text-color, #333);
}

.next-search-normal.next-normal .next-search-btn .next-icon {
  color: #333;
  color: var(--search-normal-normal-btn-text-color, #333);
}

.next-search-normal.next-normal .next-search-btn .next-icon:hover {
  color: #333;
  color: var(--search-normal-normal-btn-hover-text-color, #333);
}

.next-search-normal.next-normal.next-large {
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--search-normal-normal-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--search-normal-normal-shadow, none);
  box-shadow: var(--search-normal-normal-shadow, none);
}

.next-search-normal.next-normal.next-large .next-search-btn,
.next-search-normal.next-normal.next-large .next-search-left {
  border-width: 1px;
  border-width: var(--search-normal-normal-border-width, 1px);
  height: 36px;
  height: var(--search-normal-normal-l-height, 36px);
}

.next-search-normal.next-normal.next-large .next-search-input {
  overflow-y: hidden;
}

.next-search-normal.next-normal.next-large .next-search-input,
.next-search-normal.next-normal.next-large .next-search-input input {
  height: 34px;
  height: -webkit-calc(
    var(--search-normal-normal-l-height, 36px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-normal-l-height, 36px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-normal-l-height, 36px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
}

.next-search-normal.next-normal.next-large .next-search-input input {
  line-height: 34px \0;
  line-height: -webkit-calc(
      var(--search-normal-normal-l-height, 36px) -
        var(--search-normal-normal-border-width, 1px) * 2
    )
    \0;
  line-height: -moz-calc(
      var(--search-normal-normal-l-height, 36px) -
        var(--search-normal-normal-border-width, 1px) * 2
    )
    \0;
  line-height: calc(
      var(--search-normal-normal-l-height, 36px) -
        var(--search-normal-normal-border-width, 1px) * 2
    )
    \0;
}

.next-search-normal.next-normal.next-large .next-select {
  height: 34px;
  height: -webkit-calc(
    var(--search-normal-normal-l-height, 36px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-normal-l-height, 36px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-normal-l-height, 36px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
}

.next-search-normal.next-normal.next-large .next-search-btn {
  font-size: 14px;
  font-size: var(--search-normal-normal-l-btn-text-size, 14px);
}

.next-search-normal.next-normal.next-large
  .next-search-btn
  .next-icon
  .next-icon-remote,
.next-search-normal.next-normal.next-large .next-search-btn .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-search-normal.next-normal.next-large
  .next-search-btn
  .next-search-btn-text {
  display: inline-block;
  padding-left: 0;
  padding-left: var(--search-normal-normal-btn-text-padding-left, 0);
}

.next-search-normal.next-normal.next-medium {
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--search-normal-normal-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--search-normal-normal-shadow, none);
  box-shadow: var(--search-normal-normal-shadow, none);
}

.next-search-normal.next-normal.next-medium .next-search-btn,
.next-search-normal.next-normal.next-medium .next-search-left {
  border-width: 1px;
  border-width: var(--search-normal-normal-border-width, 1px);
  height: 32px;
  height: var(--search-normal-normal-m-height, 32px);
}

.next-search-normal.next-normal.next-medium .next-search-input {
  overflow-y: hidden;
}

.next-search-normal.next-normal.next-medium .next-search-input,
.next-search-normal.next-normal.next-medium .next-search-input input {
  height: 30px;
  height: -webkit-calc(
    var(--search-normal-normal-m-height, 32px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-normal-m-height, 32px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-normal-m-height, 32px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
}

.next-search-normal.next-normal.next-medium .next-search-input input {
  line-height: 30px \0;
  line-height: -webkit-calc(
      var(--search-normal-normal-m-height, 32px) -
        var(--search-normal-normal-border-width, 1px) * 2
    )
    \0;
  line-height: -moz-calc(
      var(--search-normal-normal-m-height, 32px) -
        var(--search-normal-normal-border-width, 1px) * 2
    )
    \0;
  line-height: calc(
      var(--search-normal-normal-m-height, 32px) -
        var(--search-normal-normal-border-width, 1px) * 2
    )
    \0;
}

.next-search-normal.next-normal.next-medium .next-select {
  height: 30px;
  height: -webkit-calc(
    var(--search-normal-normal-m-height, 32px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-normal-m-height, 32px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-normal-m-height, 32px) -
      var(--search-normal-normal-border-width, 1px) * 2
  );
}

.next-search-normal.next-normal.next-medium .next-search-btn {
  font-size: 14px;
  font-size: var(--search-normal-normal-m-btn-text-size, 14px);
}

.next-search-normal.next-normal.next-medium
  .next-search-btn
  .next-icon
  .next-icon-remote,
.next-search-normal.next-normal.next-medium .next-search-btn .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-search-normal.next-normal.next-medium
  .next-search-btn
  .next-search-btn-text {
  display: inline-block;
  padding-left: 0;
  padding-left: var(--search-normal-normal-btn-text-padding-left, 0);
}

.next-search-normal.next-normal .next-input {
  -webkit-border-top-left-radius: 1px;
  -moz-border-radius-topleft: 1px;
  border-top-left-radius: 1px;
  -webkit-border-top-left-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-normal-border-width, 1px)
  );
  -moz-border-radius-topleft: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-normal-border-width, 1px)
  );
  border-top-left-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-normal-border-width, 1px)
  );
  -webkit-border-bottom-left-radius: 1px;
  -moz-border-radius-bottomleft: 1px;
  border-bottom-left-radius: 1px;
  -webkit-border-bottom-left-radius: -webkit-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-normal-border-width, 1px)
  );
  -moz-border-radius-bottomleft: -moz-calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-normal-border-width, 1px)
  );
  border-bottom-left-radius: calc(
    var(--search-normal-corner, 2px) -
      var(--search-normal-normal-border-width, 1px)
  );
}

.next-search-normal.next-dark .next-search-left {
  border-color: #0064c8;
  border-color: var(--search-normal-dark-color, #0064c8);
}

.next-search-normal.next-dark .next-search-left .next-search-left-addon {
  border-right: 1px solid #0064c8;
  border-right: 1px solid var(--search-normal-dark-color, #0064c8);
}

.next-search-normal.next-dark:hover .next-btn,
.next-search-normal.next-dark:hover .next-search-left {
  border-color: #0050a0;
  border-color: var(--search-normal-dark-hover-color, #0050a0);
}

.next-search-normal.next-dark .next-search-btn {
  background: #0064c8;
  background: var(--search-normal-dark-color, #0064c8);
  border-color: #0064c8;
  border-color: var(--search-normal-dark-color, #0064c8);
  color: #fff;
  color: var(--search-normal-dark-btn-text-color, #fff);
}

.next-search-normal.next-dark .next-search-btn:hover {
  background: #0050a0;
  background: var(--search-normal-dark-btn-hover-bg-color, #0050a0);
  border-color: #0050a0;
  border-color: var(--search-normal-dark-hover-color, #0050a0);
  color: #fff;
  color: var(--search-normal-dark-btn-hover-text-color, #fff);
}

.next-search-normal.next-dark .next-search-btn .next-icon {
  color: #fff;
  color: var(--search-normal-dark-btn-text-color, #fff);
}

.next-search-normal.next-dark .next-search-btn .next-icon:hover {
  color: #fff;
  color: var(--search-normal-dark-btn-hover-text-color, #fff);
}

.next-search-normal.next-dark .next-select-inner,
.next-search-normal.next-dark input {
  color: #fff;
  color: var(--search-normal-dark-text-color, #fff);
}

.next-search-normal.next-dark .next-input,
.next-search-normal.next-dark .next-select {
  background: hsla(0, 0%, 100%, 0);
}

.next-search-normal.next-dark.next-large {
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--search-normal-dark-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--search-normal-dark-shadow, none);
  box-shadow: var(--search-normal-dark-shadow, none);
}

.next-search-normal.next-dark.next-large .next-search-btn,
.next-search-normal.next-dark.next-large .next-search-left {
  border-width: 1px;
  border-width: var(--search-normal-dark-border-width, 1px);
  height: 36px;
  height: var(--search-normal-dark-l-height, 36px);
}

.next-search-normal.next-dark.next-large .next-search-input {
  overflow-y: hidden;
}

.next-search-normal.next-dark.next-large .next-search-input,
.next-search-normal.next-dark.next-large .next-search-input input {
  height: 34px;
  height: -webkit-calc(
    var(--search-normal-dark-l-height, 36px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-dark-l-height, 36px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-dark-l-height, 36px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
}

.next-search-normal.next-dark.next-large .next-search-input input {
  line-height: 34px \0;
  line-height: -webkit-calc(
      var(--search-normal-dark-l-height, 36px) -
        var(--search-normal-dark-border-width, 1px) * 2
    )
    \0;
  line-height: -moz-calc(
      var(--search-normal-dark-l-height, 36px) -
        var(--search-normal-dark-border-width, 1px) * 2
    )
    \0;
  line-height: calc(
      var(--search-normal-dark-l-height, 36px) -
        var(--search-normal-dark-border-width, 1px) * 2
    )
    \0;
}

.next-search-normal.next-dark.next-large .next-select {
  height: 34px;
  height: -webkit-calc(
    var(--search-normal-dark-l-height, 36px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-dark-l-height, 36px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-dark-l-height, 36px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
}

.next-search-normal.next-dark.next-large .next-search-btn {
  font-size: 14px;
  font-size: var(--search-normal-dark-l-btn-text-size, 14px);
}

.next-search-normal.next-dark.next-large
  .next-search-btn
  .next-icon
  .next-icon-remote,
.next-search-normal.next-dark.next-large .next-search-btn .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-search-normal.next-dark.next-large
  .next-search-btn
  .next-search-btn-text {
  display: inline-block;
  padding-left: 0;
  padding-left: var(--search-normal-dark-btn-text-padding-left, 0);
}

.next-search-normal.next-dark.next-medium {
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--search-normal-dark-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--search-normal-dark-shadow, none);
  box-shadow: var(--search-normal-dark-shadow, none);
}

.next-search-normal.next-dark.next-medium .next-search-btn,
.next-search-normal.next-dark.next-medium .next-search-left {
  border-width: 1px;
  border-width: var(--search-normal-dark-border-width, 1px);
  height: 32px;
  height: var(--search-normal-dark-m-height, 32px);
}

.next-search-normal.next-dark.next-medium .next-search-input {
  overflow-y: hidden;
}

.next-search-normal.next-dark.next-medium .next-search-input,
.next-search-normal.next-dark.next-medium .next-search-input input {
  height: 30px;
  height: -webkit-calc(
    var(--search-normal-dark-m-height, 32px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-dark-m-height, 32px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-dark-m-height, 32px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
}

.next-search-normal.next-dark.next-medium .next-search-input input {
  line-height: 30px \0;
  line-height: -webkit-calc(
      var(--search-normal-dark-m-height, 32px) -
        var(--search-normal-dark-border-width, 1px) * 2
    )
    \0;
  line-height: -moz-calc(
      var(--search-normal-dark-m-height, 32px) -
        var(--search-normal-dark-border-width, 1px) * 2
    )
    \0;
  line-height: calc(
      var(--search-normal-dark-m-height, 32px) -
        var(--search-normal-dark-border-width, 1px) * 2
    )
    \0;
}

.next-search-normal.next-dark.next-medium .next-select {
  height: 30px;
  height: -webkit-calc(
    var(--search-normal-dark-m-height, 32px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--search-normal-dark-m-height, 32px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
  height: calc(
    var(--search-normal-dark-m-height, 32px) -
      var(--search-normal-dark-border-width, 1px) * 2
  );
}

.next-search-normal.next-dark.next-medium .next-search-btn {
  font-size: 14px;
  font-size: var(--search-normal-dark-m-btn-text-size, 14px);
}

.next-search-normal.next-dark.next-medium
  .next-search-btn
  .next-icon
  .next-icon-remote,
.next-search-normal.next-dark.next-medium .next-search-btn .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-search-normal.next-dark.next-medium
  .next-search-btn
  .next-search-btn-text {
  display: inline-block;
  padding-left: 0;
  padding-left: var(--search-normal-dark-btn-text-padding-left, 0);
}

.next-search-normal:not([dir="rtl"]) .next-search-left {
  border-right: none;
}

.next-search-simple {
  width: 300px;
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--search-simple-normal-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--search-simple-normal-shadow, none);
  box-shadow: var(--search-simple-normal-shadow, none);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--search-simple-corner, 2px);
  -moz-border-radius: var(--search-simple-corner, 2px);
  border-radius: var(--search-simple-corner, 2px);
}

.next-search-simple .next-search-icon {
  cursor: pointer;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-search-simple .next-input,
.next-search-simple .next-search-left {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--search-simple-corner, 2px);
  -moz-border-radius: var(--search-simple-corner, 2px);
  border-radius: var(--search-simple-corner, 2px);
}

.next-search-simple.next-large .next-search-icon {
  margin-right: 12px;
}

.next-search-simple.next-medium .next-search-icon {
  margin-right: 8px;
}

.next-search-simple.next-normal .next-search-left {
  border-color: #e3e4e6;
  border-color: var(--search-simple-normal-color, #e3e4e6);
}

.next-search-simple.next-normal .next-search-left .next-search-left-addon {
  border-right: 1px solid #e3e4e6;
  border-right: 1px solid var(--search-simple-normal-split-color, #e3e4e6);
}

.next-search-simple.next-normal:hover .next-search-left {
  border-color: #d1d5d9;
  border-color: var(--search-simple-normal-hover-color, #d1d5d9);
}

.next-search-simple.next-normal .next-search-icon {
  color: #888;
  color: var(--search-simple-normal-icon-text-color, #888);
}

.next-search-simple.next-normal .next-search-icon:hover {
  color: #555;
  color: var(--search-simple-normal-icon-hover-text-color, #555);
}

.next-search-simple.next-normal .next-search-left {
  border-width: 1px;
  border-width: var(--search-simple-normal-border-width, 1px);
}

.next-search-simple.next-normal.next-large .next-search-icon .next-icon-remote,
.next-search-simple.next-normal.next-large .next-search-icon:before,
.next-search-simple.next-normal.next-medium .next-search-icon .next-icon-remote,
.next-search-simple.next-normal.next-medium .next-search-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-search-simple.next-dark .next-search-left {
  border-color: #577899;
  border-color: var(--search-simple-dark-color, #577899);
}

.next-search-simple.next-dark .next-search-left .next-search-left-addon {
  border-right: 1px solid #577899;
  border-right: 1px solid var(--search-simple-dark-split-color, #577899);
}

.next-search-simple.next-dark:hover .next-search-left {
  border-color: #577899;
  border-color: var(--search-simple-dark-hover-color, #577899);
}

.next-search-simple.next-dark .next-search-icon {
  color: #888;
  color: var(--search-simple-dark-icon-text-color, #888);
}

.next-search-simple.next-dark .next-search-icon:hover {
  color: #555;
  color: var(--search-simple-dark-icon-hover-text-color, #555);
}

.next-search-simple.next-dark .next-select-inner,
.next-search-simple.next-dark input {
  color: #fff;
  color: var(--search-simple-dark-text-color, #fff);
}

.next-search-simple.next-dark .next-input,
.next-search-simple.next-dark .next-select {
  background: hsla(0, 0%, 100%, 0);
}

.next-search-simple.next-dark .next-search-left {
  border-width: 1px;
  border-width: var(--search-simple-dark-border-width, 1px);
}

.next-search-simple.next-dark.next-large .next-search-icon .next-icon-remote,
.next-search-simple.next-dark.next-large .next-search-icon:before,
.next-search-simple.next-dark.next-medium .next-search-icon .next-icon-remote,
.next-search-simple.next-dark.next-medium .next-search-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-search-simple .next-select.next-large {
  height: 34px;
  height: -webkit-calc(var(--form-element-large-height, 36px) - 2px);
  height: -moz-calc(var(--form-element-large-height, 36px) - 2px);
  height: calc(var(--form-element-large-height, 36px) - 2px);
}

.next-search-simple .next-select.next-medium {
  height: 30px;
  height: -webkit-calc(var(--form-element-medium-height, 32px) - 2px);
  height: -moz-calc(var(--form-element-medium-height, 32px) - 2px);
  height: calc(var(--form-element-medium-height, 32px) - 2px);
}

.next-slick {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.next-slick,
.next-slick *,
.next-slick :after,
.next-slick :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-slick-initialized .next-slick-slide {
  display: block;
}

.next-slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}

.next-slick-list:focus {
  outline: none;
}

.next-slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.next-slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}

.next-slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: 0;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-slick[dir="rtl"] .next-slick-slide {
  float: right;
}

.next-slick-slide img {
  display: block;
}

.next-slick-arrow {
  display: block;
  position: absolute;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-slick-arrow.inner {
  color: #fff;
  color: var(--slick-arrow-icon-color-normal, #fff);
  background: #000;
  background: var(--slick-arrow-bg-color-normal, #000);
  opacity: 0.2;
  opacity: var(--slick-arrow-bg-opacity-normal, 0.2);
  padding: 0;
  border: none;
}

.next-slick-arrow.inner:focus,
.next-slick-arrow.inner:hover {
  color: #fff;
  color: var(--slick-arrow-icon-color-hover, #fff);
  background: #000;
  background: var(--slick-arrow-bg-color-hover, #000);
  opacity: 0.4;
  opacity: var(--slick-arrow-bg-opacity-hover, 0.4);
}

.next-slick-arrow.inner.disabled {
  color: #c1c1c1;
  color: var(--slick-arrow-icon-color-disabled, #c1c1c1);
  background: #000;
  background: var(--slick-arrow-bg-color-disabled, #000);
  opacity: 0.05;
  opacity: var(--slick-arrow-bg-opacity-disabled, 0.05);
}

.next-slick-arrow.outer {
  color: #555;
  color: var(--slick-arrow-icon-color-normal-outer, #555);
  background: transparent;
  background: var(--slick-arrow-bg-color-normal-outer, transparent);
  opacity: 0.32;
  opacity: var(--slick-arrow-bg-opacity-normal-outer, 0.32);
  padding: 0;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--slick-arrow-corner-radius-outer, 0);
  -moz-border-radius: var(--slick-arrow-corner-radius-outer, 0);
  border-radius: var(--slick-arrow-corner-radius-outer, 0);
}

.next-slick-arrow.outer:focus,
.next-slick-arrow.outer:hover {
  color: #111;
  color: var(--slick-arrow-icon-color-hover-outer, #111);
  background: transparent;
  background: var(--slick-arrow-bg-color-hover-outer, transparent);
  opacity: 0.32;
  opacity: var(--slick-arrow-bg-opacity-hover-outer, 0.32);
}

.next-slick-arrow.outer.disabled {
  color: #c1c1c1;
  color: var(--slick-arrow-icon-color-disabled-outer, #c1c1c1);
  background: transparent;
  background: var(--slick-arrow-bg-color-disabled-outer, transparent);
  opacity: 0.32;
  opacity: var(--slick-arrow-bg-opacity-disabled-outer, 0.32);
}

.next-slick-arrow.disabled {
  cursor: not-allowed;
}

.next-slick-dots {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
}

.next-slick-dots-item {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.next-slick-dots-item button {
  cursor: pointer;
  border: 0 solid #fff;
  border-width: var(--slick-dots-border-width, 0);
  outline: none;
  padding: 0;
  height: 8px;
  height: var(--slick-dots-height, 8px);
  width: 8px;
  width: var(--slick-dots-width, 8px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--slick-dots-border-radius, 50%);
  -moz-border-radius: var(--slick-dots-border-radius, 50%);
  border-radius: var(--slick-dots-border-radius, 50%);
  background: rgba(0, 0, 0, 0.32);
}

.next-slick-dots-item button:hover {
  background-color: rgba(0, 0, 0, 0.32);
  border-color: #fff;
}

.next-slick-dots-item button:focus,
.next-slick-dots-item button:hover {
  background: hsla(0, 0%, 100%, 0.5);
}

.next-slick-dots-item.active button {
  background: #0064c8;
  border-color: #fff;
  -webkit-animation: zoom 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  -moz-animation: zoom 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  -o-animation: zoom 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  animation: zoom 0.3s cubic-bezier(0.86, 0, 0.07, 1);
}

.next-slick-dots.hoz {
  width: 100%;
  bottom: 12px;
  bottom: var(--slick-dots-position-bottom, 12px);
  left: 0;
  text-align: center;
}

.next-slick-dots.hoz .next-slick-dots-item {
  margin: 0 4px;
  margin: 0 var(--slick-dots-margin-lr, 4px);
}

.next-slick-dots.ver {
  width: 16px;
  width: -webkit-calc(var(--slick-dots-width, 8px) + 8px);
  width: -moz-calc(var(--slick-dots-width, 8px) + 8px);
  width: calc(var(--slick-dots-width, 8px) + 8px);
  top: 0;
  right: 20px;
  right: var(--slick-dots-position-right, 20px);
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.next-slick-dots.ver .next-slick-dots-item {
  margin: 0;
  margin: var(--slick-dots-margin-tb, 0) 0;
}

.next-slick.next-slick-hoz.next-slick-outer {
  padding: 0 24px;
  padding: 0 var(--slick-track-padding-lr, 24px);
}

.next-slick.next-slick-hoz .next-slick-arrow.medium {
  width: 28px;
  width: var(--slick-arrow-width-m, 28px);
  height: 56px;
  height: var(--slick-arrow-height-m, 56px);
  line-height: 56px;
  line-height: var(--slick-arrow-height-m, 56px);
}

.next-slick.next-slick-hoz
  .next-slick-arrow.medium
  .next-icon
  .next-icon-remote,
.next-slick.next-slick-hoz .next-slick-arrow.medium .next-icon:before {
  width: 20px;
  font-size: 20px;
  line-height: inherit;
}

.next-slick.next-slick-hoz .next-slick-arrow.medium.inner {
  top: -webkit-calc(50% - 28px);
  top: -moz-calc(50% - 28px);
  top: calc(50% - 28px);
  top: -webkit-calc(50% - var(--slick-arrow-height-m, 56px) / 2);
  top: -moz-calc(50% - var(--slick-arrow-height-m, 56px) / 2);
  top: calc(50% - var(--slick-arrow-height-m, 56px) / 2);
}

.next-slick.next-slick-hoz .next-slick-arrow.medium.inner.next-slick-prev {
  left: 0;
}

.next-slick.next-slick-hoz .next-slick-arrow.medium.inner.next-slick-next {
  right: 0;
}

.next-slick.next-slick-hoz .next-slick-arrow.medium.outer {
  top: -webkit-calc(50% - 28px);
  top: -moz-calc(50% - 28px);
  top: calc(50% - 28px);
  top: -webkit-calc(50% - var(--slick-arrow-height-m, 56px) / 2);
  top: -moz-calc(50% - var(--slick-arrow-height-m, 56px) / 2);
  top: calc(50% - var(--slick-arrow-height-m, 56px) / 2);
}

.next-slick.next-slick-hoz .next-slick-arrow.medium.outer.next-slick-prev {
  left: -4px;
  left: -webkit-calc(0 - var(--slick-arrow-position-lr-m, 4px));
  left: -moz-calc(0 - var(--slick-arrow-position-lr-m, 4px));
  left: calc(0px - var(--slick-arrow-position-lr-m, 4px));
}

.next-slick.next-slick-hoz .next-slick-arrow.medium.outer.next-slick-next {
  right: -4px;
  right: -webkit-calc(0 - var(--slick-arrow-position-lr-m, 4px));
  right: -moz-calc(0 - var(--slick-arrow-position-lr-m, 4px));
  right: calc(0px - var(--slick-arrow-position-lr-m, 4px));
}

.next-slick.next-slick-hoz .next-slick-arrow.large {
  width: 48px;
  width: var(--slick-arrow-width-l, 48px);
  height: 96px;
  height: var(--slick-arrow-height-l, 96px);
  line-height: 96px;
  line-height: var(--slick-arrow-height-l, 96px);
}

.next-slick.next-slick-hoz .next-slick-arrow.large .next-icon .next-icon-remote,
.next-slick.next-slick-hoz .next-slick-arrow.large .next-icon:before {
  width: 32px;
  font-size: 32px;
  line-height: inherit;
}

.next-slick.next-slick-hoz .next-slick-arrow.large.inner {
  top: -webkit-calc(50% - 48px);
  top: -moz-calc(50% - 48px);
  top: calc(50% - 48px);
  top: -webkit-calc(50% - var(--slick-arrow-height-l, 96px) / 2);
  top: -moz-calc(50% - var(--slick-arrow-height-l, 96px) / 2);
  top: calc(50% - var(--slick-arrow-height-l, 96px) / 2);
}

.next-slick.next-slick-hoz .next-slick-arrow.large.inner.next-slick-prev {
  left: 0;
}

.next-slick.next-slick-hoz .next-slick-arrow.large.inner.next-slick-next {
  right: 0;
}

.next-slick.next-slick-hoz .next-slick-arrow.large.outer {
  top: -webkit-calc(50% - 48px);
  top: -moz-calc(50% - 48px);
  top: calc(50% - 48px);
  top: -webkit-calc(50% - var(--slick-arrow-height-l, 96px) / 2);
  top: -moz-calc(50% - var(--slick-arrow-height-l, 96px) / 2);
  top: calc(50% - var(--slick-arrow-height-l, 96px) / 2);
}

.next-slick.next-slick-hoz .next-slick-arrow.large.outer.next-slick-prev {
  left: -8px;
  left: -webkit-calc(0 - var(--slick-arrow-position-lr-l, 8px));
  left: -moz-calc(0 - var(--slick-arrow-position-lr-l, 8px));
  left: calc(0px - var(--slick-arrow-position-lr-l, 8px));
}

.next-slick.next-slick-hoz .next-slick-arrow.large.outer.next-slick-next {
  right: -8px;
  right: -webkit-calc(0 - var(--slick-arrow-position-lr-l, 8px));
  right: -moz-calc(0 - var(--slick-arrow-position-lr-l, 8px));
  right: calc(0px - var(--slick-arrow-position-lr-l, 8px));
}

.next-slick.next-slick-ver.next-slick-outer {
  padding: 24px 0;
  padding: var(--slick-ver-track-padding-tb, 24px) 0;
}

.next-slick.next-slick-ver .next-slick-slide {
  display: block;
  height: auto;
}

.next-slick.next-slick-ver .next-slick-arrow.medium {
  width: 56px;
  width: var(--slick-ver-arrow-width-m, 56px);
  height: 28px;
  height: var(--slick-ver-arrow-height-m, 28px);
  line-height: 28px;
  line-height: var(--slick-ver-arrow-height-m, 28px);
}

.next-slick.next-slick-ver
  .next-slick-arrow.medium
  .next-icon
  .next-icon-remote,
.next-slick.next-slick-ver .next-slick-arrow.medium .next-icon:before {
  width: 20px;
  font-size: 20px;
  line-height: inherit;
}

.next-slick.next-slick-ver .next-slick-arrow.medium.inner {
  left: -webkit-calc(50% - 28px);
  left: -moz-calc(50% - 28px);
  left: calc(50% - 28px);
  left: -webkit-calc(50% - var(--slick-ver-arrow-width-m, 56px) / 2);
  left: -moz-calc(50% - var(--slick-ver-arrow-width-m, 56px) / 2);
  left: calc(50% - var(--slick-ver-arrow-width-m, 56px) / 2);
}

.next-slick.next-slick-ver .next-slick-arrow.medium.inner.next-slick-prev {
  top: 0;
}

.next-slick.next-slick-ver .next-slick-arrow.medium.inner.next-slick-next {
  bottom: 0;
}

.next-slick.next-slick-ver .next-slick-arrow.medium.outer {
  left: -webkit-calc(50% - 28px);
  left: -moz-calc(50% - 28px);
  left: calc(50% - 28px);
  left: -webkit-calc(50% - var(--slick-ver-arrow-width-m, 56px) / 2);
  left: -moz-calc(50% - var(--slick-ver-arrow-width-m, 56px) / 2);
  left: calc(50% - var(--slick-ver-arrow-width-m, 56px) / 2);
}

.next-slick.next-slick-ver .next-slick-arrow.medium.outer.next-slick-prev {
  top: -4px;
  top: -webkit-calc(0 - var(--slick-ver-arrow-position-tb-m, 4px));
  top: -moz-calc(0 - var(--slick-ver-arrow-position-tb-m, 4px));
  top: calc(0px - var(--slick-ver-arrow-position-tb-m, 4px));
}

.next-slick.next-slick-ver .next-slick-arrow.medium.outer.next-slick-next {
  bottom: -4px;
  bottom: -webkit-calc(0 - var(--slick-ver-arrow-position-tb-m, 4px));
  bottom: -moz-calc(0 - var(--slick-ver-arrow-position-tb-m, 4px));
  bottom: calc(0px - var(--slick-ver-arrow-position-tb-m, 4px));
}

.next-slick.next-slick-ver .next-slick-arrow.large {
  width: 96px;
  width: var(--slick-ver-arrow-width-l, 96px);
  height: 48px;
  height: var(--slick-ver-arrow-height-l, 48px);
  line-height: 48px;
  line-height: var(--slick-ver-arrow-height-l, 48px);
}

.next-slick.next-slick-ver .next-slick-arrow.large .next-icon .next-icon-remote,
.next-slick.next-slick-ver .next-slick-arrow.large .next-icon:before {
  width: 32px;
  font-size: 32px;
  line-height: inherit;
}

.next-slick.next-slick-ver .next-slick-arrow.large.inner {
  left: -webkit-calc(50% - 48px);
  left: -moz-calc(50% - 48px);
  left: calc(50% - 48px);
  left: -webkit-calc(50% - var(--slick-ver-arrow-width-l, 96px) / 2);
  left: -moz-calc(50% - var(--slick-ver-arrow-width-l, 96px) / 2);
  left: calc(50% - var(--slick-ver-arrow-width-l, 96px) / 2);
}

.next-slick.next-slick-ver .next-slick-arrow.large.inner.next-slick-prev {
  top: 0;
}

.next-slick.next-slick-ver .next-slick-arrow.large.inner.next-slick-next {
  bottom: 0;
}

.next-slick.next-slick-ver .next-slick-arrow.large.outer {
  left: -webkit-calc(50% - 48px);
  left: -moz-calc(50% - 48px);
  left: calc(50% - 48px);
  left: -webkit-calc(50% - var(--slick-ver-arrow-width-l, 96px) / 2);
  left: -moz-calc(50% - var(--slick-ver-arrow-width-l, 96px) / 2);
  left: calc(50% - var(--slick-ver-arrow-width-l, 96px) / 2);
}

.next-slick.next-slick-ver .next-slick-arrow.large.outer.next-slick-prev {
  top: -16px;
  top: -webkit-calc(0 - var(--slick-ver-arrow-position-tb-l, 16px));
  top: -moz-calc(0 - var(--slick-ver-arrow-position-tb-l, 16px));
  top: calc(0px - var(--slick-ver-arrow-position-tb-l, 16px));
}

.next-slick.next-slick-ver .next-slick-arrow.large.outer.next-slick-next {
  bottom: -16px;
  bottom: -webkit-calc(0 - var(--slick-ver-arrow-position-tb-l, 16px));
  bottom: -moz-calc(0 - var(--slick-ver-arrow-position-tb-l, 16px));
  bottom: calc(0px - var(--slick-ver-arrow-position-tb-l, 16px));
}

.next-split-btn {
  display: inline-block;
  position: relative;
}

.next-split-btn-trigger .next-icon {
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: -o-transform 0.1s linear;
  -moz-transition: transform 0.1s linear, -moz-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear,
    -moz-transform 0.1s linear, -o-transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
}

.next-split-btn-trigger.next-expand .next-split-btn-symbol-fold {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.next-split-btn-trigger.next-btn-normal:not(:disabled):not(.disabled)
  .next-icon {
  color: #888;
  color: var(--split-btn-trigger-normal-icon-color, #888);
}

.next-split-btn-trigger.next-small {
  padding-left: 4px;
  padding-right: 4px;
}

.next-split-btn-trigger.next-medium {
  padding-left: 8px;
  padding-right: 8px;
}

.next-split-btn-symbol-fold:before {
  content: "\E63D";
  content: var(--split-btn-fold-icon-content, "\E63D");
}

.next-split-btn-symbol-unfold:before {
  content: "";
  content: var(--split-btn-unfold-icon-content, "");
}

.next-step,
.next-step *,
.next-step:after,
.next-step :after,
.next-step:before,
.next-step :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-step {
  width: 100%;
  position: relative;
  border: none;
}

.next-step-item {
  position: relative;
  vertical-align: middle;
  outline: 0;
  height: 100%;
}

.next-step-item-body {
  outline: 0;
}

.next-step-item-node {
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-step-item-node.clicked {
  -webkit-transform: scale3d(0.8, 0.8, 0.8);
  -moz-transform: scale3d(0.8, 0.8, 0.8);
  transform: scale3d(0.8, 0.8, 0.8);
}

.next-step-horizontal {
  overflow: hidden;
}

.next-step-horizontal > .next-step-item {
  display: inline-block;
  text-align: left;
}

.next-step-vertical > .next-step-item {
  display: block;
  text-align: center;
}

.next-step-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.next-step-arrow .next-step-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  flex: 1;
  height: 32px;
  height: var(--step-arrow-item-height, 32px);
  line-height: 32px;
  line-height: var(--step-arrow-item-height, 32px);
  margin-left: 16px;
  margin-left: calc(var(--step-arrow-item-height, 32px) / 2);
  margin-right: 4px;
  margin-right: calc(var(--step-arrow-item-height, 32px) / 8);
}

.next-step-arrow .next-step-item:before {
  content: "";
  position: absolute;
  left: -16px;
  left: -webkit-calc(0 - var(--step-arrow-item-height, 32px) / 2);
  left: -moz-calc(0 - var(--step-arrow-item-height, 32px) / 2);
  left: calc(0px - var(--step-arrow-item-height, 32px) / 2);
  top: 0;
  z-index: 1;
  border: 16px solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
}

.next-step-arrow .next-step-item:after {
  content: "";
  position: absolute;
  right: -16px;
  right: -webkit-calc(0 - var(--step-arrow-item-height, 32px) / 2);
  right: -moz-calc(0 - var(--step-arrow-item-height, 32px) / 2);
  right: calc(0px - var(--step-arrow-item-height, 32px) / 2);
  top: 0;
  z-index: 1;
  border-top: 16px solid transparent;
  border-top: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border-bottom: 16px solid transparent;
  border-bottom: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border-left: 16px solid transparent;
  border-left: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
}

.next-step-arrow .next-step-item .next-step-item-container {
  min-width: 100px;
  height: 32px;
  height: var(--step-arrow-item-height, 32px);
  cursor: pointer;
}

.next-step-arrow
  .next-step-item
  .next-step-item-container
  .next-step-item-title {
  height: 32px;
  height: var(--step-arrow-item-height, 32px);
  line-height: 32px;
  line-height: var(--step-arrow-item-height, 32px);
  font-weight: 400;
  font-weight: var(--step-arrow-item-title-weight, normal);
  font-size: 14px;
  font-size: var(--step-arrow-item-title-size, 14px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.next-step-arrow > .next-step-item-wait {
  background: #f7f9fa;
  background: var(--step-arrow-item-wait-background, #f7f9fa);
}

.next-step-arrow > .next-step-item-wait .next-step-item-tail-overlay {
  background: #000;
}

.next-step-arrow > .next-step-item-wait .next-step-item-tail-underlay {
  background: #c1c1c1;
  background: var(--color-text1-1, #c1c1c1);
}

.next-step-arrow
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-progress {
  width: 32px;
  height: 32px;
}

.next-step-arrow
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-node {
  color: #000;
}

.next-step-arrow
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-arrow
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #f7f9fa;
  background: var(--step-arrow-item-wait-background, #f7f9fa);
  border-color: #000;
}

.next-step-arrow > .next-step-item-wait .next-step-item-content,
.next-step-arrow > .next-step-item-wait .next-step-item-title {
  color: #888;
  color: var(--step-arrow-item-title-wait-color, #888);
  word-break: break-word;
}

.next-step-arrow > .next-step-item-wait .next-step-item-content {
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-arrow > .next-step-item-wait .next-step-item-node-placeholder {
  width: 32px;
  height: 32px;
  position: relative;
}

.next-step-arrow > .next-step-item-wait .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-arrow > .next-step-item-wait .next-step-item-node-circle {
  display: block;
  width: 32px;
  height: 32px;
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 30px;
  line-height: -webkit-calc(
    32px - var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    32px - var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(32px - var(--step-circle-item-node-border-width, 1px) * 2);
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-arrow > .next-step-item-wait .next-step-item-node-circle .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-arrow
  > .next-step-item-wait
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-arrow
  > .next-step-item-wait
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-arrow > .next-step-item-wait:before {
  border: 16px solid #f7f9fa;
  border-style: solid;
  border-width: -webkit-calc(var(--step-arrow-item-height, 32px) / 2);
  border-left: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid
    var(--step-arrow-item-wait-background, #f7f9fa);
}

.next-step-arrow > .next-step-item-wait:after {
  border-left-color: #f7f9fa;
  border-left-color: var(--step-arrow-item-wait-background, #f7f9fa);
}

.next-step-arrow > .next-step-item-process {
  background: #0064c8;
  background: var(--step-arrow-item-process-background, #0064c8);
}

.next-step-arrow > .next-step-item-process .next-step-item-tail-overlay {
  background: #000;
}

.next-step-arrow > .next-step-item-process .next-step-item-tail-underlay {
  background: #c1c1c1;
  background: var(--color-text1-1, #c1c1c1);
}

.next-step-arrow
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-progress {
  width: 32px;
  height: 32px;
}

.next-step-arrow
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-node {
  color: #000;
}

.next-step-arrow
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-arrow
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #0064c8;
  background: var(--step-arrow-item-process-background, #0064c8);
  border-color: #000;
}

.next-step-arrow > .next-step-item-process .next-step-item-content,
.next-step-arrow > .next-step-item-process .next-step-item-title {
  color: #fff;
  color: var(--step-arrow-item-title-process-color, #fff);
  word-break: break-word;
}

.next-step-arrow > .next-step-item-process .next-step-item-content {
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-arrow > .next-step-item-process .next-step-item-node-placeholder {
  width: 32px;
  height: 32px;
  position: relative;
}

.next-step-arrow > .next-step-item-process .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-arrow > .next-step-item-process .next-step-item-node-circle {
  display: block;
  width: 32px;
  height: 32px;
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 30px;
  line-height: -webkit-calc(
    32px - var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    32px - var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(32px - var(--step-circle-item-node-border-width, 1px) * 2);
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-arrow
  > .next-step-item-process
  .next-step-item-node-circle
  .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-arrow
  > .next-step-item-process
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-arrow
  > .next-step-item-process
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-arrow > .next-step-item-process:before {
  border: 16px solid #0064c8;
  border-style: solid;
  border-width: -webkit-calc(var(--step-arrow-item-height, 32px) / 2);
  border-left: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid
    var(--step-arrow-item-process-background, #0064c8);
}

.next-step-arrow > .next-step-item-process:after {
  border-left-color: #0064c8;
  border-left-color: var(--step-arrow-item-process-background, #0064c8);
}

.next-step-arrow > .next-step-item-finish {
  background: #0064c8;
  background: var(--step-arrow-item-finish-background, #0064c8);
}

.next-step-arrow > .next-step-item-finish .next-step-item-tail-overlay {
  background: #000;
}

.next-step-arrow > .next-step-item-finish .next-step-item-tail-underlay {
  background: #c1c1c1;
  background: var(--color-text1-1, #c1c1c1);
}

.next-step-arrow
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-progress {
  width: 32px;
  height: 32px;
}

.next-step-arrow
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-node {
  color: #000;
}

.next-step-arrow
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-arrow
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #0064c8;
  background: var(--step-arrow-item-finish-background, #0064c8);
  border-color: #000;
}

.next-step-arrow > .next-step-item-finish .next-step-item-content,
.next-step-arrow > .next-step-item-finish .next-step-item-title {
  color: #fff;
  color: var(--step-arrow-item-title-finish-color, #fff);
  word-break: break-word;
}

.next-step-arrow > .next-step-item-finish .next-step-item-content {
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-arrow > .next-step-item-finish .next-step-item-node-placeholder {
  width: 32px;
  height: 32px;
  position: relative;
}

.next-step-arrow > .next-step-item-finish .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-arrow > .next-step-item-finish .next-step-item-node-circle {
  display: block;
  width: 32px;
  height: 32px;
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 30px;
  line-height: -webkit-calc(
    32px - var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    32px - var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(32px - var(--step-circle-item-node-border-width, 1px) * 2);
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-arrow
  > .next-step-item-finish
  .next-step-item-node-circle
  .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-arrow
  > .next-step-item-finish
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-arrow
  > .next-step-item-finish
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-arrow > .next-step-item-finish:before {
  border: 16px solid #0064c8;
  border-style: solid;
  border-width: -webkit-calc(var(--step-arrow-item-height, 32px) / 2);
  border-left: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid
    var(--step-arrow-item-finish-background, #0064c8);
}

.next-step-arrow > .next-step-item-finish:after {
  border-left-color: #0064c8;
  border-left-color: var(--step-arrow-item-finish-background, #0064c8);
}

.next-step-arrow .next-step-item-disabled {
  cursor: not-allowed;
  background: #f8f8f9;
  background: var(--step-arrow-item-disabled-background, #f8f8f9);
}

.next-step-arrow .next-step-item-disabled .next-step-item-tail-overlay {
  background: #000;
}

.next-step-arrow .next-step-item-disabled .next-step-item-tail-underlay {
  background: #c1c1c1;
  background: var(--color-text1-1, #c1c1c1);
}

.next-step-arrow
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-progress {
  width: 32px;
  height: 32px;
}

.next-step-arrow
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-node {
  color: #000;
}

.next-step-arrow
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-arrow
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #f8f8f9;
  background: var(--step-arrow-item-disabled-background, #f8f8f9);
  border-color: #000;
}

.next-step-arrow .next-step-item-disabled .next-step-item-content,
.next-step-arrow .next-step-item-disabled .next-step-item-title {
  color: #c1c1c1;
  color: var(--step-arrow-item-title-disabled-color, #c1c1c1);
  word-break: break-word;
}

.next-step-arrow .next-step-item-disabled .next-step-item-content {
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-arrow .next-step-item-disabled .next-step-item-node-placeholder {
  width: 32px;
  height: 32px;
  position: relative;
}

.next-step-arrow .next-step-item-disabled .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-arrow .next-step-item-disabled .next-step-item-node-circle {
  display: block;
  width: 32px;
  height: 32px;
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 30px;
  line-height: -webkit-calc(
    32px - var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    32px - var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(32px - var(--step-circle-item-node-border-width, 1px) * 2);
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-arrow
  .next-step-item-disabled
  .next-step-item-node-circle
  .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-arrow
  .next-step-item-disabled
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-arrow
  .next-step-item-disabled
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-arrow .next-step-item-disabled:before {
  border: 16px solid #f8f8f9;
  border-style: solid;
  border-width: -webkit-calc(var(--step-arrow-item-height, 32px) / 2);
  border-left: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid
    var(--step-arrow-item-disabled-background, #f8f8f9);
}

.next-step-arrow .next-step-item-disabled:after {
  border-left-color: #f8f8f9;
  border-left-color: var(--step-arrow-item-disabled-background, #f8f8f9);
}

.next-step-arrow .next-step-item-disabled .next-step-item-container {
  cursor: not-allowed;
}

.next-step-arrow .next-step-item-read-only,
.next-step-arrow .next-step-item-read-only .next-step-item-container {
  cursor: default;
}

.next-step-arrow .next-step-item-first {
  margin-left: 0;
}

.next-step-arrow .next-step-item-first:before {
  border: 16px solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
}

.next-step-arrow .next-step-item-last {
  margin-right: 0;
}

.next-step-arrow .next-step-item-last:after {
  border: 16px solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
}

.next-step-circle .next-step-item-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 0 12px;
  padding: 0 var(--step-circle-item-node-padding, 12px);
}

.next-step-circle
  .next-step-item-container
  .next-step-item-progress
  .next-progress-circle-text {
  color: #0064c8;
  color: var(--step-circle-item-node-process-percent-color, #0064c8);
  font-size: 12px;
  font-size: var(--step-circle-item-node-process-percent-size, 12px);
}

.next-step-circle
  .next-step-item-container
  .next-step-item-progress
  .next-progress-circle-underlay {
  stroke: #c0c6cc;
  stroke: var(--step-circle-item-node-wait-border-color, #c0c6cc);
  stroke-width: 3px;
  stroke-width: -webkit-calc(
    var(--step-circle-item-node-border-width, 1px) * 3
  );
  stroke-width: -moz-calc(var(--step-circle-item-node-border-width, 1px) * 3);
  stroke-width: calc(var(--step-circle-item-node-border-width, 1px) * 3);
}

.next-step-circle
  .next-step-item-container
  .next-step-item-progress
  .next-progress-circle-overlay-normal {
  stroke: #0064c8;
  stroke: var(--step-circle-item-node-finish-border-color, #0064c8);
  stroke-width: 3px;
  stroke-width: -webkit-calc(
    var(--step-circle-item-node-border-width, 1px) * 3
  );
  stroke-width: -moz-calc(var(--step-circle-item-node-border-width, 1px) * 3);
  stroke-width: calc(var(--step-circle-item-node-border-width, 1px) * 3);
}

.next-step-circle .next-step-item-container .next-step-item-node-placeholder {
  display: inline-block;
}

.next-step-circle > .next-step-item-wait .next-step-item-tail-overlay {
  background: #c0c6cc;
  background: var(--step-circle-item-tail-wait-color, #c0c6cc);
}

.next-step-circle > .next-step-item-wait .next-step-item-tail-underlay {
  background: #fff;
  background: var(--step-circle-item-tail-bg-color, #fff);
}

.next-step-circle
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-progress {
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
}

.next-step-circle
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-node {
  color: #333;
  color: var(--step-circle-item-node-wait-color, #333);
}

.next-step-circle
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-circle
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #fff;
  background: var(--step-circle-item-node-wait-background, #fff);
  border-color: #c0c6cc;
  border-color: var(--step-circle-item-node-wait-border-color, #c0c6cc);
}

.next-step-circle > .next-step-item-wait .next-step-item-content,
.next-step-circle > .next-step-item-wait .next-step-item-title {
  color: #333;
  color: var(--step-circle-item-title-wait-color, #333);
  word-break: break-word;
}

.next-step-circle > .next-step-item-wait .next-step-item-content {
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-circle > .next-step-item-wait .next-step-item-node-placeholder {
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
  position: relative;
}

.next-step-circle > .next-step-item-wait .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-circle > .next-step-item-wait .next-step-item-node-circle {
  display: block;
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 26px;
  line-height: -webkit-calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-circle
  > .next-step-item-wait
  .next-step-item-node-circle
  .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-circle
  > .next-step-item-wait
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-circle
  > .next-step-item-wait
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-circle > .next-step-item-process .next-step-item-tail-overlay {
  background: #c0c6cc;
  background: var(--step-circle-item-tail-process-color, #c0c6cc);
}

.next-step-circle > .next-step-item-process .next-step-item-tail-underlay {
  background: #fff;
  background: var(--step-circle-item-tail-bg-color, #fff);
}

.next-step-circle
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-progress {
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
}

.next-step-circle
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-node {
  color: #fff;
  color: var(--step-circle-item-node-process-color, #fff);
}

.next-step-circle
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-circle
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #0064c8;
  background: var(--step-circle-item-node-process-background, #0064c8);
  border-color: #0064c8;
  border-color: var(--step-circle-item-node-process-border-color, #0064c8);
}

.next-step-circle > .next-step-item-process .next-step-item-content,
.next-step-circle > .next-step-item-process .next-step-item-title {
  color: #333;
  color: var(--step-circle-item-title-process-color, #333);
  word-break: break-word;
}

.next-step-circle > .next-step-item-process .next-step-item-content {
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-circle > .next-step-item-process .next-step-item-node-placeholder {
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
  position: relative;
}

.next-step-circle > .next-step-item-process .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-circle > .next-step-item-process .next-step-item-node-circle {
  display: block;
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 26px;
  line-height: -webkit-calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-circle
  > .next-step-item-process
  .next-step-item-node-circle
  .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-circle
  > .next-step-item-process
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-circle
  > .next-step-item-process
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-circle > .next-step-item-finish .next-step-item-tail-overlay {
  background: #0064c8;
  background: var(--step-circle-item-tail-finish-color, #0064c8);
}

.next-step-circle > .next-step-item-finish .next-step-item-tail-underlay {
  background: #fff;
  background: var(--step-circle-item-tail-bg-color, #fff);
}

.next-step-circle
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-progress {
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
}

.next-step-circle
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-node {
  color: #0064c8;
  color: var(--step-circle-item-node-finish-color, #0064c8);
}

.next-step-circle
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-circle
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #fff;
  background: var(--step-circle-item-node-finish-background, #fff);
  border-color: #0064c8;
  border-color: var(--step-circle-item-node-finish-border-color, #0064c8);
}

.next-step-circle > .next-step-item-finish .next-step-item-content,
.next-step-circle > .next-step-item-finish .next-step-item-title {
  color: #333;
  color: var(--step-circle-item-title-finish-color, #333);
  word-break: break-word;
}

.next-step-circle > .next-step-item-finish .next-step-item-content {
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-circle > .next-step-item-finish .next-step-item-node-placeholder {
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
  position: relative;
}

.next-step-circle > .next-step-item-finish .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-circle > .next-step-item-finish .next-step-item-node-circle {
  display: block;
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 26px;
  line-height: -webkit-calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-circle
  > .next-step-item-finish
  .next-step-item-node-circle
  .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-circle
  > .next-step-item-finish
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-circle
  > .next-step-item-finish
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-circle .next-step-item-disabled .next-step-item-tail-overlay {
  background: #e3e4e6;
  background: var(--step-circle-item-tail-disabled-color, #e3e4e6);
}

.next-step-circle .next-step-item-disabled .next-step-item-tail-underlay {
  background: #fff;
  background: var(--step-circle-item-tail-bg-color, #fff);
}

.next-step-circle
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-progress {
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
}

.next-step-circle
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-node {
  color: #ccc;
  color: var(--step-circle-item-node-disabled-color, #ccc);
}

.next-step-circle
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-circle
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #fff;
  background: var(--step-circle-item-node-disabled-background, #fff);
  border-color: #e3e4e6;
  border-color: var(--step-circle-item-node-disabled-border-color, #e3e4e6);
}

.next-step-circle .next-step-item-disabled .next-step-item-content,
.next-step-circle .next-step-item-disabled .next-step-item-title {
  color: #ccc;
  color: var(--step-circle-item-title-disabled-color, #ccc);
  word-break: break-word;
}

.next-step-circle .next-step-item-disabled .next-step-item-content {
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-circle .next-step-item-disabled .next-step-item-node-placeholder {
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
  position: relative;
}

.next-step-circle .next-step-item-disabled .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-circle .next-step-item-disabled .next-step-item-node-circle {
  display: block;
  width: 28px;
  width: var(--step-circle-item-node-process-size, 28px);
  height: 28px;
  height: var(--step-circle-item-node-process-size, 28px);
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 26px;
  line-height: -webkit-calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-circle
  .next-step-item-disabled
  .next-step-item-node-circle
  .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-circle
  .next-step-item-disabled
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-circle
  .next-step-item-disabled
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-circle .next-step-item-disabled .next-step-item-node,
.next-step-circle .next-step-item-disabled .next-step-item-node-placeholder {
  cursor: not-allowed;
}

.next-step-circle .next-step-item-read-only .next-step-item-node,
.next-step-circle .next-step-item-read-only .next-step-item-node-placeholder {
  cursor: default;
}

.next-step-circle .next-step-item-last .next-step-item-tail {
  display: none;
}

.next-step-circle.next-step-horizontal {
  text-align: center;
  white-space: nowrap;
}

.next-step-circle.next-step-horizontal
  > .next-step-item
  .next-step-item-content,
.next-step-circle.next-step-horizontal > .next-step-item .next-step-item-title {
  white-space: normal;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-wait
  .next-step-item-tail {
  display: inline-block;
  clear: both;
  width: -webkit-calc(100% - 52px);
  width: -moz-calc(100% - 52px);
  width: calc(100% - 52px);
  width: -webkit-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  width: -moz-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  width: calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  vertical-align: middle;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-wait
  .next-step-item-tail
  .next-step-item-tail-underlay {
  display: block;
  height: 1px;
  height: var(--step-circle-item-tail-size, 1px);
  position: relative;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-wait
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 1px;
  height: var(--step-circle-item-tail-size, 1px);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-wait
  > .next-step-item-body {
  width: 96px;
  width: var(--step-circle-item-body-width, 96px);
  left: -22px;
  left: -webkit-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  left: -moz-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  left: calc(
    0px - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2 / 2
  );
  text-align: center;
  position: absolute;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-title {
  font-size: 14px;
  font-size: var(--step-circle-item-title-size, 14px);
  line-height: 18px;
  line-height: -webkit-calc(var(--step-circle-item-title-size, 14px) + 4px);
  line-height: -moz-calc(var(--step-circle-item-title-size, 14px) + 4px);
  line-height: calc(var(--step-circle-item-title-size, 14px) + 4px);
  margin-top: 8px;
  margin-top: var(--step-circle-item-title-margin-top, 8px);
  font-weight: 600;
  font-weight: var(--step-circle-item-title-weight, 600);
}

.next-step-circle.next-step-horizontal
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 8px;
  margin-top: var(--step-circle-item-content-margin-top, 8px);
}

.next-step-circle.next-step-horizontal
  > .next-step-item-process
  .next-step-item-tail {
  display: inline-block;
  clear: both;
  width: -webkit-calc(100% - 52px);
  width: -moz-calc(100% - 52px);
  width: calc(100% - 52px);
  width: -webkit-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  width: -moz-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  width: calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  vertical-align: middle;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-process
  .next-step-item-tail
  .next-step-item-tail-underlay {
  display: block;
  height: 1px;
  height: var(--step-circle-item-tail-size, 1px);
  position: relative;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-process
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 1px;
  height: var(--step-circle-item-tail-size, 1px);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-process
  > .next-step-item-body {
  width: 96px;
  width: var(--step-circle-item-body-width, 96px);
  left: -22px;
  left: -webkit-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  left: -moz-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  left: calc(
    0px - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2 / 2
  );
  text-align: center;
  position: absolute;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-title {
  font-size: 14px;
  font-size: var(--step-circle-item-title-size, 14px);
  line-height: 18px;
  line-height: -webkit-calc(var(--step-circle-item-title-size, 14px) + 4px);
  line-height: -moz-calc(var(--step-circle-item-title-size, 14px) + 4px);
  line-height: calc(var(--step-circle-item-title-size, 14px) + 4px);
  margin-top: 8px;
  margin-top: var(--step-circle-item-title-margin-top, 8px);
  font-weight: 600;
  font-weight: var(--step-circle-item-title-weight, 600);
}

.next-step-circle.next-step-horizontal
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 8px;
  margin-top: var(--step-circle-item-content-margin-top, 8px);
}

.next-step-circle.next-step-horizontal
  > .next-step-item-finish
  .next-step-item-tail {
  display: inline-block;
  clear: both;
  width: -webkit-calc(100% - 52px);
  width: -moz-calc(100% - 52px);
  width: calc(100% - 52px);
  width: -webkit-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  width: -moz-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  width: calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  vertical-align: middle;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-finish
  .next-step-item-tail
  .next-step-item-tail-underlay {
  display: block;
  height: 1px;
  height: var(--step-circle-item-tail-size, 1px);
  position: relative;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-finish
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 1px;
  height: var(--step-circle-item-tail-size, 1px);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-finish
  > .next-step-item-body {
  width: 96px;
  width: var(--step-circle-item-body-width, 96px);
  left: -22px;
  left: -webkit-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  left: -moz-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  left: calc(
    0px - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2 / 2
  );
  text-align: center;
  position: absolute;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-title {
  font-size: 14px;
  font-size: var(--step-circle-item-title-size, 14px);
  line-height: 18px;
  line-height: -webkit-calc(var(--step-circle-item-title-size, 14px) + 4px);
  line-height: -moz-calc(var(--step-circle-item-title-size, 14px) + 4px);
  line-height: calc(var(--step-circle-item-title-size, 14px) + 4px);
  margin-top: 8px;
  margin-top: var(--step-circle-item-title-margin-top, 8px);
  font-weight: 600;
  font-weight: var(--step-circle-item-title-weight, 600);
}

.next-step-circle.next-step-horizontal
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 8px;
  margin-top: var(--step-circle-item-content-margin-top, 8px);
}

.next-step-circle.next-step-horizontal
  > .next-step-item-disabled
  .next-step-item-tail {
  display: inline-block;
  clear: both;
  width: -webkit-calc(100% - 52px);
  width: -moz-calc(100% - 52px);
  width: calc(100% - 52px);
  width: -webkit-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  width: -moz-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  width: calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2
  );
  vertical-align: middle;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-disabled
  .next-step-item-tail
  .next-step-item-tail-underlay {
  display: block;
  height: 1px;
  height: var(--step-circle-item-tail-size, 1px);
  position: relative;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-disabled
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 1px;
  height: var(--step-circle-item-tail-size, 1px);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-disabled
  > .next-step-item-body {
  width: 96px;
  width: var(--step-circle-item-body-width, 96px);
  left: -22px;
  left: -webkit-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  left: -moz-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  left: calc(
    0px - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2 / 2
  );
  text-align: center;
  position: absolute;
}

.next-step-circle.next-step-horizontal
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-title {
  font-size: 14px;
  font-size: var(--step-circle-item-title-size, 14px);
  line-height: 18px;
  line-height: -webkit-calc(var(--step-circle-item-title-size, 14px) + 4px);
  line-height: -moz-calc(var(--step-circle-item-title-size, 14px) + 4px);
  line-height: calc(var(--step-circle-item-title-size, 14px) + 4px);
  margin-top: 8px;
  margin-top: var(--step-circle-item-title-margin-top, 8px);
  font-weight: 600;
  font-weight: var(--step-circle-item-title-weight, 600);
}

.next-step-circle.next-step-horizontal
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 8px;
  margin-top: var(--step-circle-item-content-margin-top, 8px);
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item {
  vertical-align: unset;
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-wait
  .next-step-item:last-child
  .next-step-item-tail {
  display: none;
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-wait
  .next-step-item-body {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  max-width: 96px;
  max-width: var(--step-circle-item-body-width, 96px);
  overflow: hidden;
  vertical-align: top;
  text-align: left;
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-wait
  .next-step-item-body
  .next-step-item-title {
  display: inline-block;
  padding-right: 12px;
  padding-right: var(--step-circle-item-node-padding, 12px);
  margin-top: 7px;
  margin-top: calc(
    var(--step-circle-item-node-process-size, 28px) / 2 -
      var(--step-circle-item-title-size, 14px) / 2
  );
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-wait
  .next-step-item-tail {
  width: -webkit-calc(100% - 148px);
  width: -moz-calc(100% - 148px);
  width: calc(100% - 148px);
  width: -webkit-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  width: -moz-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  width: calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  position: absolute;
  right: 0;
  margin-top: -1px;
  margin-top: calc(0px - var(--step-circle-vertical-item-tail-size, 1px));
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-process
  .next-step-item:last-child
  .next-step-item-tail {
  display: none;
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-process
  .next-step-item-body {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  max-width: 96px;
  max-width: var(--step-circle-item-body-width, 96px);
  overflow: hidden;
  vertical-align: top;
  text-align: left;
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-process
  .next-step-item-body
  .next-step-item-title {
  display: inline-block;
  padding-right: 12px;
  padding-right: var(--step-circle-item-node-padding, 12px);
  margin-top: 7px;
  margin-top: calc(
    var(--step-circle-item-node-process-size, 28px) / 2 -
      var(--step-circle-item-title-size, 14px) / 2
  );
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-process
  .next-step-item-tail {
  width: -webkit-calc(100% - 148px);
  width: -moz-calc(100% - 148px);
  width: calc(100% - 148px);
  width: -webkit-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  width: -moz-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  width: calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  position: absolute;
  right: 0;
  margin-top: -1px;
  margin-top: calc(0px - var(--step-circle-vertical-item-tail-size, 1px));
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-finish
  .next-step-item:last-child
  .next-step-item-tail {
  display: none;
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-finish
  .next-step-item-body {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  max-width: 96px;
  max-width: var(--step-circle-item-body-width, 96px);
  overflow: hidden;
  vertical-align: top;
  text-align: left;
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-finish
  .next-step-item-body
  .next-step-item-title {
  display: inline-block;
  padding-right: 12px;
  padding-right: var(--step-circle-item-node-padding, 12px);
  margin-top: 7px;
  margin-top: calc(
    var(--step-circle-item-node-process-size, 28px) / 2 -
      var(--step-circle-item-title-size, 14px) / 2
  );
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-finish
  .next-step-item-tail {
  width: -webkit-calc(100% - 148px);
  width: -moz-calc(100% - 148px);
  width: calc(100% - 148px);
  width: -webkit-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  width: -moz-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  width: calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  position: absolute;
  right: 0;
  margin-top: -1px;
  margin-top: calc(0px - var(--step-circle-vertical-item-tail-size, 1px));
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-disabled
  .next-step-item:last-child
  .next-step-item-tail {
  display: none;
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-disabled
  .next-step-item-body {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  max-width: 96px;
  max-width: var(--step-circle-item-body-width, 96px);
  overflow: hidden;
  vertical-align: top;
  text-align: left;
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-disabled
  .next-step-item-body
  .next-step-item-title {
  display: inline-block;
  padding-right: 12px;
  padding-right: var(--step-circle-item-node-padding, 12px);
  margin-top: 7px;
  margin-top: calc(
    var(--step-circle-item-node-process-size, 28px) / 2 -
      var(--step-circle-item-title-size, 14px) / 2
  );
}

.next-step-circle.next-step-horizontal.next-step-label-horizontal
  > .next-step-item-disabled
  .next-step-item-tail {
  width: -webkit-calc(100% - 148px);
  width: -moz-calc(100% - 148px);
  width: calc(100% - 148px);
  width: -webkit-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  width: -moz-calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  width: calc(
    100% - var(--step-circle-item-node-process-size, 28px) -
      var(--step-circle-item-node-padding, 12px) * 2 -
      var(--step-circle-item-body-width, 96px)
  );
  position: absolute;
  right: 0;
  margin-top: -1px;
  margin-top: calc(0px - var(--step-circle-vertical-item-tail-size, 1px));
}

.next-step-circle.next-step-vertical {
  font-size: 0;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.next-step-circle.next-step-vertical .next-step-item-container {
  padding: 0;
}

.next-step-circle.next-step-vertical
  > .next-step-item:last-child
  .next-step-item-tail {
  display: block;
  visibility: hidden;
}

.next-step-circle.next-step-vertical
  > .next-step-item-wait
  .next-step-item-tail {
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
  height: 0;
  margin: 12px auto;
  margin: var(--step-circle-item-node-padding, 12px) auto;
}

.next-step-circle.next-step-vertical
  > .next-step-item-wait
  .next-step-item-tail
  .next-step-item-tail-underlay {
  height: 100%;
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
  position: relative;
}

.next-step-circle.next-step-vertical
  > .next-step-item-wait
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body {
  position: absolute;
  top: 0;
  left: 14px;
  left: -webkit-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: -moz-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: calc(var(--step-circle-item-node-process-size, 28px) / 2);
  margin-left: 12px;
  margin-left: var(--step-circle-item-node-padding, 12px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-title {
  margin-top: 8px;
  margin-top: var(--step-circle-vertical-item-title-margin-top, 8px);
  text-align: left;
  font-weight: 600;
  font-weight: var(--step-circle-item-title-weight, 600);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-circle.next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 4px;
  margin-top: var(--step-circle-vertical-item-content-margin-top, 4px);
  min-height: 8px;
  text-align: left;
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--step-circle-item-content-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--step-circle-item-content-font-size, 12px) + 4px);
  line-height: calc(var(--step-circle-item-content-font-size, 12px) + 4px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-process
  .next-step-item-tail {
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
  height: 0;
  margin: 12px auto;
  margin: var(--step-circle-item-node-padding, 12px) auto;
}

.next-step-circle.next-step-vertical
  > .next-step-item-process
  .next-step-item-tail
  .next-step-item-tail-underlay {
  height: 100%;
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
  position: relative;
}

.next-step-circle.next-step-vertical
  > .next-step-item-process
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-process
  > .next-step-item-body {
  position: absolute;
  top: 0;
  left: 14px;
  left: -webkit-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: -moz-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: calc(var(--step-circle-item-node-process-size, 28px) / 2);
  margin-left: 12px;
  margin-left: var(--step-circle-item-node-padding, 12px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-title {
  margin-top: 8px;
  margin-top: var(--step-circle-vertical-item-title-margin-top, 8px);
  text-align: left;
  font-weight: 600;
  font-weight: var(--step-circle-item-title-weight, 600);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-circle.next-step-vertical
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 4px;
  margin-top: var(--step-circle-vertical-item-content-margin-top, 4px);
  min-height: 8px;
  text-align: left;
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--step-circle-item-content-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--step-circle-item-content-font-size, 12px) + 4px);
  line-height: calc(var(--step-circle-item-content-font-size, 12px) + 4px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-finish
  .next-step-item-tail {
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
  height: 0;
  margin: 12px auto;
  margin: var(--step-circle-item-node-padding, 12px) auto;
}

.next-step-circle.next-step-vertical
  > .next-step-item-finish
  .next-step-item-tail
  .next-step-item-tail-underlay {
  height: 100%;
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
  position: relative;
}

.next-step-circle.next-step-vertical
  > .next-step-item-finish
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body {
  position: absolute;
  top: 0;
  left: 14px;
  left: -webkit-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: -moz-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: calc(var(--step-circle-item-node-process-size, 28px) / 2);
  margin-left: 12px;
  margin-left: var(--step-circle-item-node-padding, 12px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-title {
  margin-top: 8px;
  margin-top: var(--step-circle-vertical-item-title-margin-top, 8px);
  text-align: left;
  font-weight: 600;
  font-weight: var(--step-circle-item-title-weight, 600);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-circle.next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 4px;
  margin-top: var(--step-circle-vertical-item-content-margin-top, 4px);
  min-height: 8px;
  text-align: left;
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--step-circle-item-content-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--step-circle-item-content-font-size, 12px) + 4px);
  line-height: calc(var(--step-circle-item-content-font-size, 12px) + 4px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-disabled
  .next-step-item-tail {
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
  height: 0;
  margin: 12px auto;
  margin: var(--step-circle-item-node-padding, 12px) auto;
}

.next-step-circle.next-step-vertical
  > .next-step-item-disabled
  .next-step-item-tail
  .next-step-item-tail-underlay {
  height: 100%;
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
  position: relative;
}

.next-step-circle.next-step-vertical
  > .next-step-item-disabled
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  width: var(--step-circle-vertical-item-tail-size, 1px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body {
  position: absolute;
  top: 0;
  left: 14px;
  left: -webkit-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: -moz-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: calc(var(--step-circle-item-node-process-size, 28px) / 2);
  margin-left: 12px;
  margin-left: var(--step-circle-item-node-padding, 12px);
}

.next-step-circle.next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-title {
  margin-top: 8px;
  margin-top: var(--step-circle-vertical-item-title-margin-top, 8px);
  text-align: left;
  font-weight: 600;
  font-weight: var(--step-circle-item-title-weight, 600);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-circle.next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 4px;
  margin-top: var(--step-circle-vertical-item-content-margin-top, 4px);
  min-height: 8px;
  text-align: left;
  font-size: 12px;
  font-size: var(--step-circle-item-content-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--step-circle-item-content-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--step-circle-item-content-font-size, 12px) + 4px);
  line-height: calc(var(--step-circle-item-content-font-size, 12px) + 4px);
}

.next-step-dot .next-step-item-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 0 8px;
  padding: 0 var(--step-dot-item-dot-padding, 8px);
  margin-top: -1px;
  margin-bottom: -1px;
}

.next-step-dot .next-step-item-container .next-step-item-node-placeholder {
  display: inline-block;
}

.next-step-dot .next-step-item-container .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-dot
  .next-step-item-container
  .next-step-item-node
  .next-icon
  .next-icon-remote,
.next-step-dot
  .next-step-item-container
  .next-step-item-node
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-step-dot > .next-step-item-wait .next-step-item-tail-overlay {
  background: #e3e4e6;
  background: var(--step-dot-item-tail-wait-color, #e3e4e6);
}

.next-step-dot > .next-step-item-wait .next-step-item-tail-underlay {
  background: #f8f8f9;
  background: var(--step-dot-item-tail-bg-color, #f8f8f9);
}

.next-step-dot
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-progress {
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
}

.next-step-dot
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-node {
  color: #888;
  color: var(--step-dot-item-node-wait-color, #888);
}

.next-step-dot
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-dot
  > .next-step-item-wait
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #fff;
  background: var(--step-dot-item-node-wait-background, #fff);
  border-color: #e3e4e6;
  border-color: var(--step-dot-item-node-wait-border-color, #e3e4e6);
}

.next-step-dot > .next-step-item-wait .next-step-item-content,
.next-step-dot > .next-step-item-wait .next-step-item-title {
  color: #888;
  color: var(--step-dot-item-title-wait-color, #888);
  word-break: break-word;
}

.next-step-dot > .next-step-item-wait .next-step-item-content {
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-dot > .next-step-item-wait .next-step-item-node-placeholder {
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  position: relative;
}

.next-step-dot > .next-step-item-wait .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-dot > .next-step-item-wait .next-step-item-node-circle {
  display: block;
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 10px;
  line-height: -webkit-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-dot > .next-step-item-wait .next-step-item-node-circle .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-dot
  > .next-step-item-wait
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-dot
  > .next-step-item-wait
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-dot > .next-step-item-wait .next-step-item-content,
.next-step-dot > .next-step-item-wait .next-step-item-node-dot {
  font-size: 12px;
  font-size: var(--step-dot-item-content-font-size, 12px);
}

.next-step-dot > .next-step-item-wait .next-step-item-node-dot {
  display: block;
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  line-height: 10px;
  line-height: -webkit-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-dot-item-dot-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-dot-item-dot-corner, 50%);
  -moz-border-radius: var(--step-dot-item-dot-corner, 50%);
  border-radius: var(--step-dot-item-dot-corner, 50%);
  -webkit-transition: background-color 0.3s ease, border-color 0.3s ease;
  -o-transition: background-color 0.3s ease, border-color 0.3s ease;
  -moz-transition: background-color 0.3s ease, border-color 0.3s ease;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.next-step-dot > .next-step-item-process .next-step-item-tail-overlay {
  background: #e3e4e6;
  background: var(--step-dot-item-tail-process-color, #e3e4e6);
}

.next-step-dot > .next-step-item-process .next-step-item-tail-underlay {
  background: #f8f8f9;
  background: var(--step-dot-item-tail-bg-color, #f8f8f9);
}

.next-step-dot
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-progress {
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
}

.next-step-dot
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-node {
  color: #0064c8;
  color: var(--step-dot-item-node-process-color, #0064c8);
}

.next-step-dot
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-dot
  > .next-step-item-process
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #0064c8;
  background: var(--step-dot-item-node-process-background, #0064c8);
  border-color: #0064c8;
  border-color: var(--step-dot-item-node-process-border-color, #0064c8);
}

.next-step-dot > .next-step-item-process .next-step-item-content,
.next-step-dot > .next-step-item-process .next-step-item-title {
  color: #ccc;
  color: var(--step-dot-item-title-process-color, #ccc);
  word-break: break-word;
}

.next-step-dot > .next-step-item-process .next-step-item-content {
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-dot > .next-step-item-process .next-step-item-node-placeholder {
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  position: relative;
}

.next-step-dot > .next-step-item-process .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-dot > .next-step-item-process .next-step-item-node-circle {
  display: block;
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 10px;
  line-height: -webkit-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-dot
  > .next-step-item-process
  .next-step-item-node-circle
  .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-dot
  > .next-step-item-process
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-dot
  > .next-step-item-process
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-dot > .next-step-item-process .next-step-item-content,
.next-step-dot > .next-step-item-process .next-step-item-node-dot {
  font-size: 12px;
  font-size: var(--step-dot-item-content-font-size, 12px);
}

.next-step-dot > .next-step-item-process .next-step-item-node-dot {
  display: block;
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  line-height: 10px;
  line-height: -webkit-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-dot-item-dot-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-dot-item-dot-corner, 50%);
  -moz-border-radius: var(--step-dot-item-dot-corner, 50%);
  border-radius: var(--step-dot-item-dot-corner, 50%);
  -webkit-transition: background-color 0.3s ease, border-color 0.3s ease;
  -o-transition: background-color 0.3s ease, border-color 0.3s ease;
  -moz-transition: background-color 0.3s ease, border-color 0.3s ease;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.next-step-dot > .next-step-item-finish .next-step-item-tail-overlay {
  background: #0064c8;
  background: var(--step-dot-item-tail-finish-color, #0064c8);
}

.next-step-dot > .next-step-item-finish .next-step-item-tail-underlay {
  background: #f8f8f9;
  background: var(--step-dot-item-tail-bg-color, #f8f8f9);
}

.next-step-dot
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-progress {
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
}

.next-step-dot
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-node {
  color: #0064c8;
  color: var(--step-dot-item-node-finish-color, #0064c8);
}

.next-step-dot
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-dot
  > .next-step-item-finish
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #fff;
  background: var(--step-dot-item-node-finish-background, #fff);
  border-color: #0064c8;
  border-color: var(--step-dot-item-node-finish-border-color, #0064c8);
}

.next-step-dot > .next-step-item-finish .next-step-item-content,
.next-step-dot > .next-step-item-finish .next-step-item-title {
  color: #ccc;
  color: var(--step-dot-item-title-finish-color, #ccc);
  word-break: break-word;
}

.next-step-dot > .next-step-item-finish .next-step-item-content {
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-dot > .next-step-item-finish .next-step-item-node-placeholder {
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  position: relative;
}

.next-step-dot > .next-step-item-finish .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-dot > .next-step-item-finish .next-step-item-node-circle {
  display: block;
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 10px;
  line-height: -webkit-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-dot > .next-step-item-finish .next-step-item-node-circle .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-dot
  > .next-step-item-finish
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-dot
  > .next-step-item-finish
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-dot > .next-step-item-finish .next-step-item-content,
.next-step-dot > .next-step-item-finish .next-step-item-node-dot {
  font-size: 12px;
  font-size: var(--step-dot-item-content-font-size, 12px);
}

.next-step-dot > .next-step-item-finish .next-step-item-node-dot {
  display: block;
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  line-height: 10px;
  line-height: -webkit-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-dot-item-dot-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-dot-item-dot-corner, 50%);
  -moz-border-radius: var(--step-dot-item-dot-corner, 50%);
  border-radius: var(--step-dot-item-dot-corner, 50%);
  -webkit-transition: background-color 0.3s ease, border-color 0.3s ease;
  -o-transition: background-color 0.3s ease, border-color 0.3s ease;
  -moz-transition: background-color 0.3s ease, border-color 0.3s ease;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.next-step-dot .next-step-item-disabled .next-step-item-tail-overlay {
  background: #e3e4e6;
  background: var(--step-dot-item-tail-disabled-color, #e3e4e6);
}

.next-step-dot .next-step-item-disabled .next-step-item-tail-underlay {
  background: #f8f8f9;
  background: var(--step-dot-item-tail-bg-color, #f8f8f9);
}

.next-step-dot
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-progress {
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
}

.next-step-dot
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-node {
  color: #e3e4e6;
  color: var(--step-dot-item-node-disabled-color, #e3e4e6);
}

.next-step-dot
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-node-circle,
.next-step-dot
  .next-step-item-disabled
  > .next-step-item-container
  .next-step-item-node-dot {
  background: #fff;
  background: var(--step-dot-item-node-disabled-background, #fff);
  border-color: #c0c6cc;
  border-color: var(--step-dot-item-node-disabled-border-color, #c0c6cc);
}

.next-step-dot .next-step-item-disabled .next-step-item-content,
.next-step-dot .next-step-item-disabled .next-step-item-title {
  color: #c1c1c1;
  color: var(--step-dot-item-title-disabled-color, #c1c1c1);
  word-break: break-word;
}

.next-step-dot .next-step-item-disabled .next-step-item-content {
  font-size: var(--step-circle-item-content-font-size, 12px);
}

.next-step-dot .next-step-item-disabled .next-step-item-node-placeholder {
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  position: relative;
}

.next-step-dot .next-step-item-disabled .next-step-item-node {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.next-step-dot .next-step-item-disabled .next-step-item-node-circle {
  display: block;
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  font-size: 12px;
  font-size: var(--step-circle-item-node-font-size, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  line-height: 10px;
  line-height: -webkit-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-circle-item-node-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-circle-item-node-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-circle-item-node-corner, 50%);
  -moz-border-radius: var(--step-circle-item-node-corner, 50%);
  border-radius: var(--step-circle-item-node-corner, 50%);
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-step-dot .next-step-item-disabled .next-step-item-node-circle .next-icon {
  -webkit-animation: zoomIn 0.3s linear;
  -moz-animation: zoomIn 0.3s linear;
  -o-animation: zoomIn 0.3s linear;
  animation: zoomIn 0.3s linear;
}

.next-step-dot
  .next-step-item-disabled
  .next-step-item-node-circle
  .next-icon
  .next-icon-remote,
.next-step-dot
  .next-step-item-disabled
  .next-step-item-node-circle
  .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-step-dot .next-step-item-disabled .next-step-item-content,
.next-step-dot .next-step-item-disabled .next-step-item-node-dot {
  font-size: 12px;
  font-size: var(--step-dot-item-content-font-size, 12px);
}

.next-step-dot .next-step-item-disabled .next-step-item-node-dot {
  display: block;
  width: 12px;
  width: var(--step-dot-item-dot-process-size, 12px);
  height: 12px;
  height: var(--step-dot-item-dot-process-size, 12px);
  line-height: 10px;
  line-height: -webkit-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  line-height: calc(
    var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-border-width, 1px) * 2
  );
  text-align: center;
  border: 1px solid;
  border: var(--step-dot-item-dot-border-width, 1px) solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--step-dot-item-dot-corner, 50%);
  -moz-border-radius: var(--step-dot-item-dot-corner, 50%);
  border-radius: var(--step-dot-item-dot-corner, 50%);
  -webkit-transition: background-color 0.3s ease, border-color 0.3s ease;
  -o-transition: background-color 0.3s ease, border-color 0.3s ease;
  -moz-transition: background-color 0.3s ease, border-color 0.3s ease;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.next-step-dot .next-step-item-disabled .next-step-item-node,
.next-step-dot .next-step-item-disabled .next-step-item-node-placeholder {
  cursor: not-allowed;
}

.next-step-dot .next-step-item-read-only .next-step-item-node,
.next-step-dot .next-step-item-read-only .next-step-item-node-placeholder {
  cursor: default;
}

.next-step-dot .next-step-item-last .next-step-item-tail {
  display: none;
}

.next-step-dot.next-step-horizontal {
  text-align: center;
  white-space: nowrap;
}

.next-step-dot.next-step-horizontal > .next-step-item .next-step-item-content,
.next-step-dot.next-step-horizontal > .next-step-item .next-step-item-title {
  white-space: normal;
}

.next-step-dot.next-step-horizontal .next-step-item-node .next-icon {
  vertical-align: middle;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-wait
  .next-step-item-tail {
  display: inline-block;
  clear: both;
  width: -webkit-calc(100% - 28px);
  width: -moz-calc(100% - 28px);
  width: calc(100% - 28px);
  width: -webkit-calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  width: -moz-calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  width: calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  vertical-align: middle;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-wait
  .next-step-item-tail
  .next-step-item-tail-underlay {
  display: block;
  height: 1px;
  height: var(--step-dot-item-tail-size, 1px);
  position: relative;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-wait
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 1px;
  height: var(--step-dot-item-tail-size, 1px);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-wait
  > .next-step-item-body {
  width: 96px;
  width: var(--step-dot-item-body-width, 96px);
  left: -34px;
  left: -webkit-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  left: -moz-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  left: calc(
    0px - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2 / 2
  );
  text-align: center;
  position: absolute;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-title {
  font-size: 12px;
  font-size: var(--step-dot-item-title-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--step-dot-item-title-size, 12px) + 4px);
  line-height: -moz-calc(var(--step-dot-item-title-size, 12px) + 4px);
  line-height: calc(var(--step-dot-item-title-size, 12px) + 4px);
  margin-top: 8px;
  margin-top: var(--step-dot-item-title-margin-top, 8px);
  font-weight: 700;
  font-weight: var(--step-dot-item-title-weight, bold);
}

.next-step-dot.next-step-horizontal
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 4px;
  margin-top: var(--step-dot-item-content-margin-top, 4px);
}

.next-step-dot.next-step-horizontal
  > .next-step-item-process
  .next-step-item-tail {
  display: inline-block;
  clear: both;
  width: -webkit-calc(100% - 28px);
  width: -moz-calc(100% - 28px);
  width: calc(100% - 28px);
  width: -webkit-calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  width: -moz-calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  width: calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  vertical-align: middle;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-process
  .next-step-item-tail
  .next-step-item-tail-underlay {
  display: block;
  height: 1px;
  height: var(--step-dot-item-tail-size, 1px);
  position: relative;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-process
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 1px;
  height: var(--step-dot-item-tail-size, 1px);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-process
  > .next-step-item-body {
  width: 96px;
  width: var(--step-dot-item-body-width, 96px);
  left: -34px;
  left: -webkit-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  left: -moz-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  left: calc(
    0px - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2 / 2
  );
  text-align: center;
  position: absolute;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-title {
  font-size: 12px;
  font-size: var(--step-dot-item-title-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--step-dot-item-title-size, 12px) + 4px);
  line-height: -moz-calc(var(--step-dot-item-title-size, 12px) + 4px);
  line-height: calc(var(--step-dot-item-title-size, 12px) + 4px);
  margin-top: 8px;
  margin-top: var(--step-dot-item-title-margin-top, 8px);
  font-weight: 700;
  font-weight: var(--step-dot-item-title-weight, bold);
}

.next-step-dot.next-step-horizontal
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 4px;
  margin-top: var(--step-dot-item-content-margin-top, 4px);
}

.next-step-dot.next-step-horizontal
  > .next-step-item-finish
  .next-step-item-tail {
  display: inline-block;
  clear: both;
  width: -webkit-calc(100% - 28px);
  width: -moz-calc(100% - 28px);
  width: calc(100% - 28px);
  width: -webkit-calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  width: -moz-calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  width: calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  vertical-align: middle;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-finish
  .next-step-item-tail
  .next-step-item-tail-underlay {
  display: block;
  height: 1px;
  height: var(--step-dot-item-tail-size, 1px);
  position: relative;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-finish
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 1px;
  height: var(--step-dot-item-tail-size, 1px);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-finish
  > .next-step-item-body {
  width: 96px;
  width: var(--step-dot-item-body-width, 96px);
  left: -34px;
  left: -webkit-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  left: -moz-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  left: calc(
    0px - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2 / 2
  );
  text-align: center;
  position: absolute;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-title {
  font-size: 12px;
  font-size: var(--step-dot-item-title-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--step-dot-item-title-size, 12px) + 4px);
  line-height: -moz-calc(var(--step-dot-item-title-size, 12px) + 4px);
  line-height: calc(var(--step-dot-item-title-size, 12px) + 4px);
  margin-top: 8px;
  margin-top: var(--step-dot-item-title-margin-top, 8px);
  font-weight: 700;
  font-weight: var(--step-dot-item-title-weight, bold);
}

.next-step-dot.next-step-horizontal
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 4px;
  margin-top: var(--step-dot-item-content-margin-top, 4px);
}

.next-step-dot.next-step-horizontal
  > .next-step-item-disabled
  .next-step-item-tail {
  display: inline-block;
  clear: both;
  width: -webkit-calc(100% - 28px);
  width: -moz-calc(100% - 28px);
  width: calc(100% - 28px);
  width: -webkit-calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  width: -moz-calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  width: calc(
    100% - var(--step-dot-item-dot-process-size, 12px) -
      var(--step-dot-item-dot-padding, 8px) * 2
  );
  vertical-align: middle;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-disabled
  .next-step-item-tail
  .next-step-item-tail-underlay {
  display: block;
  height: 1px;
  height: var(--step-dot-item-tail-size, 1px);
  position: relative;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-disabled
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 1px;
  height: var(--step-dot-item-tail-size, 1px);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-disabled
  > .next-step-item-body {
  width: 96px;
  width: var(--step-dot-item-body-width, 96px);
  left: -34px;
  left: -webkit-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  left: -moz-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  left: calc(
    0px - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2 / 2
  );
  text-align: center;
  position: absolute;
}

.next-step-dot.next-step-horizontal
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-title {
  font-size: 12px;
  font-size: var(--step-dot-item-title-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--step-dot-item-title-size, 12px) + 4px);
  line-height: -moz-calc(var(--step-dot-item-title-size, 12px) + 4px);
  line-height: calc(var(--step-dot-item-title-size, 12px) + 4px);
  margin-top: 8px;
  margin-top: var(--step-dot-item-title-margin-top, 8px);
  font-weight: 700;
  font-weight: var(--step-dot-item-title-weight, bold);
}

.next-step-dot.next-step-horizontal
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 4px;
  margin-top: var(--step-dot-item-content-margin-top, 4px);
}

.next-step-dot.next-step-vertical {
  padding: 0 0 0 4px;
  font-size: 0;
  display: table-cell;
  position: relative;
}

.next-step-dot.next-step-vertical .next-step-item-container {
  padding: 0;
}

.next-step-dot.next-step-vertical
  > .next-step-item:last-child
  .next-step-item-tail {
  display: block;
  visibility: hidden;
}

.next-step-dot.next-step-vertical > .next-step-item-wait .next-step-item-tail {
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
  height: 0;
  margin: 8px auto;
  margin: var(--step-dot-item-dot-padding, 8px) auto;
}

.next-step-dot.next-step-vertical
  > .next-step-item-wait
  .next-step-item-tail
  .next-step-item-tail-underlay {
  height: 100%;
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
  position: relative;
}

.next-step-dot.next-step-vertical
  > .next-step-item-wait
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body {
  position: absolute;
  top: 0;
  left: 6px;
  left: -webkit-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: -moz-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  margin-left: 8px;
  margin-left: var(--step-dot-item-dot-padding, 8px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-title {
  margin-top: 0;
  margin-top: var(--step-dot-vertical-item-title-margin-top, 0);
  font-weight: 700;
  font-weight: var(--step-dot-item-title-weight, bold);
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-dot.next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 8px;
  margin-top: var(--step-dot-vertical-item-content-margin-top, 8px);
  min-height: 8px;
  text-align: left;
  font-size: 12px;
  font-size: var(--step-dot-item-content-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--step-dot-item-content-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--step-dot-item-content-font-size, 12px) + 4px);
  line-height: calc(var(--step-dot-item-content-font-size, 12px) + 4px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-process
  .next-step-item-tail {
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
  height: 0;
  margin: 8px auto;
  margin: var(--step-dot-item-dot-padding, 8px) auto;
}

.next-step-dot.next-step-vertical
  > .next-step-item-process
  .next-step-item-tail
  .next-step-item-tail-underlay {
  height: 100%;
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
  position: relative;
}

.next-step-dot.next-step-vertical
  > .next-step-item-process
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-process
  > .next-step-item-body {
  position: absolute;
  top: 0;
  left: 6px;
  left: -webkit-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: -moz-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  margin-left: 8px;
  margin-left: var(--step-dot-item-dot-padding, 8px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-title {
  margin-top: 0;
  margin-top: var(--step-dot-vertical-item-title-margin-top, 0);
  font-weight: 700;
  font-weight: var(--step-dot-item-title-weight, bold);
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-dot.next-step-vertical
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 8px;
  margin-top: var(--step-dot-vertical-item-content-margin-top, 8px);
  min-height: 8px;
  text-align: left;
  font-size: 12px;
  font-size: var(--step-dot-item-content-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--step-dot-item-content-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--step-dot-item-content-font-size, 12px) + 4px);
  line-height: calc(var(--step-dot-item-content-font-size, 12px) + 4px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-finish
  .next-step-item-tail {
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
  height: 0;
  margin: 8px auto;
  margin: var(--step-dot-item-dot-padding, 8px) auto;
}

.next-step-dot.next-step-vertical
  > .next-step-item-finish
  .next-step-item-tail
  .next-step-item-tail-underlay {
  height: 100%;
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
  position: relative;
}

.next-step-dot.next-step-vertical
  > .next-step-item-finish
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body {
  position: absolute;
  top: 0;
  left: 6px;
  left: -webkit-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: -moz-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  margin-left: 8px;
  margin-left: var(--step-dot-item-dot-padding, 8px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-title {
  margin-top: 0;
  margin-top: var(--step-dot-vertical-item-title-margin-top, 0);
  font-weight: 700;
  font-weight: var(--step-dot-item-title-weight, bold);
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-dot.next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 8px;
  margin-top: var(--step-dot-vertical-item-content-margin-top, 8px);
  min-height: 8px;
  text-align: left;
  font-size: 12px;
  font-size: var(--step-dot-item-content-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--step-dot-item-content-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--step-dot-item-content-font-size, 12px) + 4px);
  line-height: calc(var(--step-dot-item-content-font-size, 12px) + 4px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-disabled
  .next-step-item-tail {
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
  height: 0;
  margin: 8px auto;
  margin: var(--step-dot-item-dot-padding, 8px) auto;
}

.next-step-dot.next-step-vertical
  > .next-step-item-disabled
  .next-step-item-tail
  .next-step-item-tail-underlay {
  height: 100%;
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
  position: relative;
}

.next-step-dot.next-step-vertical
  > .next-step-item-disabled
  .next-step-item-tail
  .next-step-item-tail-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  width: var(--step-dot-vertical-item-tail-size, 1px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body {
  position: absolute;
  top: 0;
  left: 6px;
  left: -webkit-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: -moz-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  margin-left: 8px;
  margin-left: var(--step-dot-item-dot-padding, 8px);
}

.next-step-dot.next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-title {
  margin-top: 0;
  margin-top: var(--step-dot-vertical-item-title-margin-top, 0);
  font-weight: 700;
  font-weight: var(--step-dot-item-title-weight, bold);
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-dot.next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-content {
  margin-top: 8px;
  margin-top: var(--step-dot-vertical-item-content-margin-top, 8px);
  min-height: 8px;
  text-align: left;
  font-size: 12px;
  font-size: var(--step-dot-item-content-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--step-dot-item-content-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--step-dot-item-content-font-size, 12px) + 4px);
  line-height: calc(var(--step-dot-item-content-font-size, 12px) + 4px);
}

.next-step-horizontal[dir="rtl"] > .next-step-item {
  text-align: right;
}

.next-step-arrow[dir="rtl"] .next-step-item {
  height: 32px;
  height: var(--step-arrow-item-height, 32px);
  line-height: 32px;
  line-height: var(--step-arrow-item-height, 32px);
  margin-left: 4px;
  margin-left: calc(var(--step-arrow-item-height, 32px) / 8);
  margin-right: 16px;
  margin-right: calc(var(--step-arrow-item-height, 32px) / 2);
}

.next-step-arrow[dir="rtl"] .next-step-item:before {
  right: -16px;
  right: -webkit-calc(0 - var(--step-arrow-item-height, 32px) / 2);
  right: -moz-calc(0 - var(--step-arrow-item-height, 32px) / 2);
  right: calc(0px - var(--step-arrow-item-height, 32px) / 2);
  left: auto;
  border: 16px solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
}

.next-step-arrow[dir="rtl"] .next-step-item:after {
  left: -32px;
  left: -webkit-calc(0 - var(--step-arrow-item-height, 32px) / 2 * 2);
  left: -moz-calc(0 - var(--step-arrow-item-height, 32px) / 2 * 2);
  left: calc(0px - var(--step-arrow-item-height, 32px) / 2 * 2);
  right: auto;
  border-top: 16px solid transparent;
  border-top: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border-bottom: 16px solid transparent;
  border-bottom: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border-right: 16px solid transparent;
  border-right: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
}

.next-step-arrow[dir="rtl"] > .next-step-item-wait {
  background: #f7f9fa;
  background: var(--step-arrow-item-wait-background, #f7f9fa);
}

.next-step-arrow[dir="rtl"] > .next-step-item-wait .next-step-item-node-dot {
  right: 50%;
  left: auto;
}

.next-step-arrow[dir="rtl"] > .next-step-item-wait:before {
  border: 16px solid #f7f9fa;
  border-style: solid;
  border-width: -webkit-calc(var(--step-arrow-item-height, 32px) / 2);
  border-right: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid
    var(--step-arrow-item-wait-background, #f7f9fa);
}

.next-step-arrow[dir="rtl"] > .next-step-item-wait:after {
  border-right-color: #f7f9fa;
  border-right-color: var(--step-arrow-item-wait-background, #f7f9fa);
  border-left-color: transparent;
}

.next-step-arrow[dir="rtl"] > .next-step-item-process {
  background: #0064c8;
  background: var(--step-arrow-item-process-background, #0064c8);
}

.next-step-arrow[dir="rtl"] > .next-step-item-process .next-step-item-node-dot {
  right: 50%;
  left: auto;
}

.next-step-arrow[dir="rtl"] > .next-step-item-process:before {
  border: 16px solid #0064c8;
  border-style: solid;
  border-width: -webkit-calc(var(--step-arrow-item-height, 32px) / 2);
  border-right: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid
    var(--step-arrow-item-process-background, #0064c8);
}

.next-step-arrow[dir="rtl"] > .next-step-item-process:after {
  border-right-color: #0064c8;
  border-right-color: var(--step-arrow-item-process-background, #0064c8);
  border-left-color: transparent;
}

.next-step-arrow[dir="rtl"] > .next-step-item-finish {
  background: #0064c8;
  background: var(--step-arrow-item-finish-background, #0064c8);
}

.next-step-arrow[dir="rtl"] > .next-step-item-finish .next-step-item-node-dot {
  right: 50%;
  left: auto;
}

.next-step-arrow[dir="rtl"] > .next-step-item-finish:before {
  border: 16px solid #0064c8;
  border-style: solid;
  border-width: -webkit-calc(var(--step-arrow-item-height, 32px) / 2);
  border-right: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid
    var(--step-arrow-item-finish-background, #0064c8);
}

.next-step-arrow[dir="rtl"] > .next-step-item-finish:after {
  border-right-color: #0064c8;
  border-right-color: var(--step-arrow-item-finish-background, #0064c8);
  border-left-color: transparent;
}

.next-step-arrow[dir="rtl"] .next-step-item-disabled {
  background: #f8f8f9;
  background: var(--step-arrow-item-disabled-background, #f8f8f9);
}

.next-step-arrow[dir="rtl"] .next-step-item-disabled .next-step-item-node-dot {
  right: 50%;
  left: auto;
}

.next-step-arrow[dir="rtl"] .next-step-item-disabled:before {
  border: 16px solid #f8f8f9;
  border-style: solid;
  border-width: -webkit-calc(var(--step-arrow-item-height, 32px) / 2);
  border-right: calc(var(--step-arrow-item-height, 32px) / 2) solid transparent;
  border: calc(var(--step-arrow-item-height, 32px) / 2) solid
    var(--step-arrow-item-disabled-background, #f8f8f9);
}

.next-step-arrow[dir="rtl"] .next-step-item-disabled:after {
  border-right-color: #f8f8f9;
  border-right-color: var(--step-arrow-item-disabled-background, #f8f8f9);
  border-left-color: transparent;
}

.next-step-arrow[dir="rtl"] .next-step-item-first {
  margin-right: 0;
}

.next-step-arrow[dir="rtl"] .next-step-item-last {
  margin-left: 0;
}

.next-step-circle[dir="rtl"] .next-step-item-disabled .next-step-item-node-dot,
.next-step-circle[dir="rtl"] > .next-step-item-finish .next-step-item-node-dot,
.next-step-circle[dir="rtl"] > .next-step-item-process .next-step-item-node-dot,
.next-step-circle[dir="rtl"] > .next-step-item-wait .next-step-item-node-dot {
  right: 50%;
  left: auto;
}

.next-step-circle[dir="rtl"].next-step-horizontal
  > .next-step-item-disabled
  > .next-step-item-body,
.next-step-circle[dir="rtl"].next-step-horizontal
  > .next-step-item-finish
  > .next-step-item-body,
.next-step-circle[dir="rtl"].next-step-horizontal
  > .next-step-item-process
  > .next-step-item-body,
.next-step-circle[dir="rtl"].next-step-horizontal
  > .next-step-item-wait
  > .next-step-item-body {
  right: -22px;
  right: -webkit-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  right: -moz-calc(
    0 - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2/2
  );
  right: calc(
    0px - var(--step-circle-item-body-width, 96px) / 2 +
      var(--step-circle-item-node-process-size, 28px) / 2 +
      var(--step-circle-item-node-padding, 12px) * 2 / 2
  );
  left: auto;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-wait
  .next-step-item-body {
  left: auto;
  right: 0;
  text-align: right;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-wait
  .next-step-item-body
  .next-step-item-title {
  padding-left: 12px;
  padding-left: var(--step-circle-item-node-padding, 12px);
  padding-right: 0;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-wait
  .next-step-item-tail {
  left: 0;
  right: auto;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-process
  .next-step-item-body {
  left: auto;
  right: 0;
  text-align: right;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-process
  .next-step-item-body
  .next-step-item-title {
  padding-left: 12px;
  padding-left: var(--step-circle-item-node-padding, 12px);
  padding-right: 0;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-process
  .next-step-item-tail {
  left: 0;
  right: auto;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-finish
  .next-step-item-body {
  left: auto;
  right: 0;
  text-align: right;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-finish
  .next-step-item-body
  .next-step-item-title {
  padding-left: 12px;
  padding-left: var(--step-circle-item-node-padding, 12px);
  padding-right: 0;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-finish
  .next-step-item-tail {
  left: 0;
  right: auto;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-disabled
  .next-step-item-body {
  left: auto;
  right: 0;
  text-align: right;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-disabled
  .next-step-item-body
  .next-step-item-title {
  padding-left: 12px;
  padding-left: var(--step-circle-item-node-padding, 12px);
  padding-right: 0;
}

.next-step-circle[dir="rtl"].next-step-horizontal.next-step-label-horizontal
  > .next-step-item-disabled
  .next-step-item-tail {
  left: 0;
  right: auto;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body {
  right: 14px;
  right: -webkit-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  right: -moz-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  right: calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: auto;
  margin-right: 12px;
  margin-right: var(--step-circle-item-node-padding, 12px);
  margin-left: 0;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-title {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-content {
  text-align: right;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-process
  > .next-step-item-body {
  right: 14px;
  right: -webkit-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  right: -moz-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  right: calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: auto;
  margin-right: 12px;
  margin-right: var(--step-circle-item-node-padding, 12px);
  margin-left: 0;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-title {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-content {
  text-align: right;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body {
  right: 14px;
  right: -webkit-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  right: -moz-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  right: calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: auto;
  margin-right: 12px;
  margin-right: var(--step-circle-item-node-padding, 12px);
  margin-left: 0;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-title {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-content {
  text-align: right;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body {
  right: 14px;
  right: -webkit-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  right: -moz-calc(var(--step-circle-item-node-process-size, 28px) / 2);
  right: calc(var(--step-circle-item-node-process-size, 28px) / 2);
  left: auto;
  margin-right: 12px;
  margin-right: var(--step-circle-item-node-padding, 12px);
  margin-left: 0;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-title {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-circle[dir="rtl"].next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-content {
  text-align: right;
}

.next-step-dot[dir="rtl"] .next-step-item-disabled .next-step-item-node-dot,
.next-step-dot[dir="rtl"] > .next-step-item-finish .next-step-item-node-dot,
.next-step-dot[dir="rtl"] > .next-step-item-process .next-step-item-node-dot,
.next-step-dot[dir="rtl"] > .next-step-item-wait .next-step-item-node-dot {
  right: 50%;
  left: auto;
}

.next-step-dot[dir="rtl"].next-step-horizontal
  > .next-step-item-disabled
  > .next-step-item-body,
.next-step-dot[dir="rtl"].next-step-horizontal
  > .next-step-item-finish
  > .next-step-item-body,
.next-step-dot[dir="rtl"].next-step-horizontal
  > .next-step-item-process
  > .next-step-item-body,
.next-step-dot[dir="rtl"].next-step-horizontal
  > .next-step-item-wait
  > .next-step-item-body {
  right: -34px;
  right: -webkit-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  right: -moz-calc(
    0 - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2/2
  );
  right: calc(
    0px - var(--step-dot-item-body-width, 96px) / 2 +
      var(--step-dot-item-dot-process-size, 12px) / 2 +
      var(--step-dot-item-dot-padding, 8px) * 2 / 2
  );
  left: auto;
}

.next-step-dot[dir="rtl"].next-step-vertical {
  padding: 0 4px 0 0;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body {
  right: 6px;
  right: -webkit-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  right: -moz-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  right: calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: auto;
  margin-right: 8px;
  margin-right: var(--step-dot-item-dot-padding, 8px);
  margin-left: 0;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-title {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-wait
  > .next-step-item-body
  > .next-step-item-content {
  text-align: right;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-process
  > .next-step-item-body {
  right: 6px;
  right: -webkit-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  right: -moz-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  right: calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: auto;
  margin-right: 8px;
  margin-right: var(--step-dot-item-dot-padding, 8px);
  margin-left: 0;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-title {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-process
  > .next-step-item-body
  > .next-step-item-content {
  text-align: right;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body {
  right: 6px;
  right: -webkit-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  right: -moz-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  right: calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: auto;
  margin-right: 8px;
  margin-right: var(--step-dot-item-dot-padding, 8px);
  margin-left: 0;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-title {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-finish
  > .next-step-item-body
  > .next-step-item-content {
  text-align: right;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body {
  right: 6px;
  right: -webkit-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  right: -moz-calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  right: calc(var(--step-dot-item-dot-process-size, 12px) / 2);
  left: auto;
  margin-right: 8px;
  margin-right: var(--step-dot-item-dot-padding, 8px);
  margin-left: 0;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-title {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 1.28571;
}

.next-step-dot[dir="rtl"].next-step-vertical
  > .next-step-item-disabled
  > .next-step-item-body
  > .next-step-item-content {
  text-align: right;
}

.next-switch:after[dir="rtl"] {
  content: " ";
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
}

.next-switch-medium[dir="rtl"]:after,
.next-switch-small[dir="rtl"]:after {
  right: 100%;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.next-switch-on[dir="rtl"] > .next-switch-children {
  right: 12px;
  right: -webkit-calc(
    var(--switch-text-on-left, 8px) + var(--switch-border-width-container, 2px) *
      2
  );
  right: -moz-calc(
    var(--switch-text-on-left, 8px) + var(--switch-border-width-container, 2px) *
      2
  );
  right: calc(
    var(--switch-text-on-left, 8px) + var(--switch-border-width-container, 2px) *
      2
  );
  left: auto;
  color: #fff;
  color: var(--switch-normal-on-color-font, #fff);
}

.next-switch-on[disabled][dir="rtl"]:after {
  left: 0;
  right: 100%;
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--switch-on-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--switch-on-shadow, none);
  box-shadow: var(--switch-on-shadow, none);
}

.next-switch-on.next-switch-small[dir="rtl"] > .next-switch-children {
  right: 8px;
  right: -webkit-calc(
    var(--switch-size-s-text-on-left, 4px) +
      var(--switch-border-width-container, 2px) * 2
  );
  right: -moz-calc(
    var(--switch-size-s-text-on-left, 4px) +
      var(--switch-border-width-container, 2px) * 2
  );
  right: calc(
    var(--switch-size-s-text-on-left, 4px) +
      var(--switch-border-width-container, 2px) * 2
  );
  left: auto;
}

.next-switch-off[dir="rtl"]:after {
  right: 0;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --switch-rtl-on-shadow,
    0 0 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--switch-rtl-on-shadow, 0 0 4px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--switch-rtl-on-shadow, 0 0 4px 0 rgba(0, 0, 0, 0.16));
}

.next-switch-off[dir="rtl"] > .next-switch-children {
  left: 12px;
  left: -webkit-calc(
    var(--switch-text-off-right, 8px) +
      var(--switch-border-width-container, 2px) * 2
  );
  left: -moz-calc(
    var(--switch-text-off-right, 8px) +
      var(--switch-border-width-container, 2px) * 2
  );
  left: calc(
    var(--switch-text-off-right, 8px) +
      var(--switch-border-width-container, 2px) * 2
  );
  right: auto;
}

.next-switch-off.next-switch-small[dir="rtl"] > .next-switch-children {
  left: 6px;
  left: -webkit-calc(
    var(--switch-size-s-text-off-right, 4px) +
      var(--switch-border-width-container, 2px)
  );
  left: -moz-calc(
    var(--switch-size-s-text-off-right, 4px) +
      var(--switch-border-width-container, 2px)
  );
  left: calc(
    var(--switch-size-s-text-off-right, 4px) +
      var(--switch-border-width-container, 2px)
  );
  right: auto;
}

.next-switch {
  outline: none;
  text-align: left;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.next-switch,
.next-switch *,
.next-switch :after,
.next-switch :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-switch:after {
  content: " ";
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.next-switch-medium {
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
  border: var(--switch-border-width-container, 2px) solid transparent;
  width: 48px;
  width: var(--switch-size-m-width, 48px);
  height: 24px;
  height: -webkit-calc(
    var(--switch-size-m-trigger, 20px) +
      var(--switch-border-width-container, 2px) * 2
  );
  height: -moz-calc(
    var(--switch-size-m-trigger, 20px) +
      var(--switch-border-width-container, 2px) * 2
  );
  height: calc(
    var(--switch-size-m-trigger, 20px) +
      var(--switch-border-width-container, 2px) * 2
  );
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-border-radius: var(--switch-size-m-radius-container, 12px);
  -moz-border-radius: var(--switch-size-m-radius-container, 12px);
  border-radius: var(--switch-size-m-radius-container, 12px);
}

.next-switch-medium:after {
  border: 1px solid transparent;
  border: var(--switch-border-width-trigger, 1px) solid transparent;
  left: 100%;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  width: 20px;
  width: var(--switch-size-m-trigger, 20px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--switch-size-m-radius-trigger, 50%);
  -moz-border-radius: var(--switch-size-m-radius-trigger, 50%);
  border-radius: var(--switch-size-m-radius-trigger, 50%);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-switch-medium:after,
.next-switch-medium > .next-switch-children {
  position: absolute;
  height: 20px;
  height: var(--switch-size-m-trigger, 20px);
}

.next-switch-medium > .next-switch-children {
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 20px;
  line-height: var(--switch-size-m-trigger, 20px);
}

.next-switch-small {
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
  border: var(--switch-border-width-container, 2px) solid transparent;
  width: 40px;
  width: var(--switch-size-s-width, 40px);
  height: 20px;
  height: -webkit-calc(
    var(--switch-size-s-trigger, 16px) +
      var(--switch-border-width-container, 2px) * 2
  );
  height: -moz-calc(
    var(--switch-size-s-trigger, 16px) +
      var(--switch-border-width-container, 2px) * 2
  );
  height: calc(
    var(--switch-size-s-trigger, 16px) +
      var(--switch-border-width-container, 2px) * 2
  );
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-border-radius: var(--switch-size-s-radius-container, 12px);
  -moz-border-radius: var(--switch-size-s-radius-container, 12px);
  border-radius: var(--switch-size-s-radius-container, 12px);
}

.next-switch-small:after {
  border: 1px solid transparent;
  border: var(--switch-border-width-trigger, 1px) solid transparent;
  left: 100%;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  width: 16px;
  width: var(--switch-size-s-trigger, 16px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: var(--switch-size-s-radius-trigger, 50%);
  -moz-border-radius: var(--switch-size-s-radius-trigger, 50%);
  border-radius: var(--switch-size-s-radius-trigger, 50%);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-switch-small:after,
.next-switch-small > .next-switch-children {
  position: absolute;
  height: 16px;
  height: var(--switch-size-s-trigger, 16px);
}

.next-switch-small > .next-switch-children {
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  line-height: 16px;
  line-height: var(--switch-size-s-trigger, 16px);
}

.next-switch-on {
  background-color: #00a700;
  background-color: var(--switch-normal-on-bg-color, #00a700);
}

.next-switch-on:after {
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--switch-on-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--switch-on-shadow, none);
  box-shadow: var(--switch-on-shadow, none);
  background-color: #fff;
  background-color: var(--switch-normal-on-trigger-bg-color, #fff);
  border-color: transparent;
  border-color: var(--switch-handle-on-border-color, transparent);
}

.next-switch-on > .next-switch-children {
  left: 12px;
  left: -webkit-calc(
    var(--switch-text-on-left, 8px) + var(--switch-border-width-container, 2px) *
      2
  );
  left: -moz-calc(
    var(--switch-text-on-left, 8px) + var(--switch-border-width-container, 2px) *
      2
  );
  left: calc(
    var(--switch-text-on-left, 8px) + var(--switch-border-width-container, 2px) *
      2
  );
  color: #fff;
  color: var(--switch-normal-on-color-font, #fff);
}

.next-switch-on:focus,
.next-switch-on:hover {
  background-color: #00a700;
  background-color: var(--switch-hover-on-bg-color, #00a700);
}

.next-switch-on:focus:after,
.next-switch-on:hover:after {
  background-color: #fff;
  background-color: var(--switch-hover-on-trigger-bg-color, #fff);
}

.next-switch-on.next-switch-small > .next-switch-children {
  left: 8px;
  left: -webkit-calc(
    var(--switch-size-s-text-on-left, 4px) +
      var(--switch-border-width-container, 2px) * 2
  );
  left: -moz-calc(
    var(--switch-size-s-text-on-left, 4px) +
      var(--switch-border-width-container, 2px) * 2
  );
  left: calc(
    var(--switch-size-s-text-on-left, 4px) +
      var(--switch-border-width-container, 2px) * 2
  );
}

.next-switch-on[disabled] {
  background-color: #d1d5d9;
  background-color: var(--switch-disabled-on-bg-color, #d1d5d9);
  cursor: not-allowed;
}

.next-switch-on[disabled]:after {
  right: 0;
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--switch-on-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--switch-on-shadow, none);
  box-shadow: var(--switch-on-shadow, none);
  background-color: #fff;
  background-color: var(--switch-disabled-on-trigger-bg-color, #fff);
  border-color: transparent;
  border-color: var(--switch-handle-disabled-border-color, transparent);
}

.next-switch-on[disabled] > .next-switch-children {
  color: #ccc;
  color: var(--switch-disabled-on-color-font, #ccc);
}

.next-switch-off {
  background-color: #577899;
  background-color: var(--switch-normal-off-bg-color, #577899);
  border-color: transparent;
  border-color: var(--switch-normal-off-border-color, transparent);
}

.next-switch-off:focus,
.next-switch-off:hover {
  background-color: #577899;
  background-color: var(--switch-hover-off-bg-color, #577899);
  border-color: transparent;
  border-color: var(--switch-hover-off-border-color, transparent);
}

.next-switch-off:after {
  left: 0;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--switch-on-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--switch-on-shadow, none);
  box-shadow: var(--switch-on-shadow, none);
  background-color: #fff;
  background-color: var(--switch-normal-off-trigger-bg-color, #fff);
  border-color: transparent;
  border-color: var(--switch-handle-off-border-color, transparent);
}

.next-switch-off:after:focus,
.next-switch-off:after:hover {
  background-color: #fff;
  background-color: var(--switch-hover-off-trigger-bg-color, #fff);
}

.next-switch-off > .next-switch-children {
  right: 12px;
  right: -webkit-calc(
    var(--switch-text-off-right, 8px) +
      var(--switch-border-width-container, 2px) * 2
  );
  right: -moz-calc(
    var(--switch-text-off-right, 8px) +
      var(--switch-border-width-container, 2px) * 2
  );
  right: calc(
    var(--switch-text-off-right, 8px) +
      var(--switch-border-width-container, 2px) * 2
  );
  color: #f8f8f9;
  color: var(--switch-normal-off-color-font, #f8f8f9);
}

.next-switch-off[disabled] {
  background-color: #d1d5d9;
  background-color: var(--switch-disabled-off-bg-color, #d1d5d9);
  cursor: not-allowed;
}

.next-switch-off[disabled]:after {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(--switch-off-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--switch-off-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--switch-off-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  background-color: #f8f8f9;
  background-color: var(--switch-disabled-off-trigger-bg-color, #f8f8f9);
  border-color: transparent;
  border-color: var(--switch-handle-disabled-border-color, transparent);
}

.next-switch-off[disabled] > .next-switch-children {
  color: #555;
  color: var(--switch-disabled-off-color-font, #555);
}

.next-switch-off.next-switch-small > .next-switch-children {
  right: 6px;
  right: -webkit-calc(
    var(--switch-size-s-text-off-right, 4px) +
      var(--switch-border-width-container, 2px)
  );
  right: -moz-calc(
    var(--switch-size-s-text-off-right, 4px) +
      var(--switch-border-width-container, 2px)
  );
  right: calc(
    var(--switch-size-s-text-off-right, 4px) +
      var(--switch-border-width-container, 2px)
  );
}

.next-tabs {
  width: 100%;
}

.next-tabs,
.next-tabs *,
.next-tabs :after,
.next-tabs :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-tabs-bar {
  outline: none;
}

.next-tabs-bar-popup {
  overflow-y: auto;
  max-height: 480px;
}

.next-tabs-nav-container {
  position: relative;
}

.next-tabs-nav-container:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-tabs-nav-wrap {
  overflow: hidden;
}

.next-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}

.next-tabs-scrollable .next-tabs-nav-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.next-tabs-scrollable .next-tabs-nav-scroll::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  opacity: 0 !important;
}

.next-tabs-nav {
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  list-style: none;
  padding: 0;
  margin: 0;
}

.next-tabs-nav-appear,
.next-tabs-nav-enter {
  -webkit-animation: fadeInLeft 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -moz-animation: fadeInLeft 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -o-animation: fadeInLeft 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation: fadeInLeft 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.next-tabs-nav-leave {
  -webkit-animation: fadeOutLeft 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -moz-animation: fadeOutLeft 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -o-animation: fadeOutLeft 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation: fadeOutLeft 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.next-tabs-nav.next-disable-animation .next-tabs-tab:before {
  -webkit-transition: none;
  -o-transition: none;
  -moz-transition: none;
  transition: none;
}

.next-tabs-tab {
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-tabs-tab-inner {
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.next-tabs-tab:before {
  content: "";
  position: absolute;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.next-tabs-tab.active {
  font-weight: 600;
  font-weight: var(--tab-item-font-weight-selected, 600);
}

.next-tabs-tab .next-tabs-tab-close {
  color: #555;
  color: var(--tab-close-icon-color, #555);
}

.next-tabs-tab .next-tabs-tab-close:hover {
  color: #111;
  color: var(--tab-close-icon-color-hover, #111);
}

.next-tabs-tab .next-tabs-tab-close:focus {
  outline: none;
}

.next-tabs-tab.active .next-tabs-tab-close {
  color: #0064c8;
  color: var(--tab-close-icon-color-selected, #0064c8);
}

.next-tabs-tab.disabled .next-tabs-tab-close {
  color: #c0c6cc;
  color: var(--tab-close-icon-color-disabled, #c0c6cc);
}

.next-tabs-tab:focus {
  outline: none;
}

.next-tabs-tabpane {
  visibility: hidden;
  opacity: 0;
}

.next-tabs-tabpane.active {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.next-tabs-tabpane.hidden {
  overflow: hidden;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

.next-tabs-btn-down,
.next-tabs-btn-next,
.next-tabs-btn-prev {
  position: absolute;
  top: 0;
  cursor: pointer;
  padding: 0;
  border: 0;
  outline: none;
  height: 100%;
  background-color: transparent;
  border-color: transparent;
}

.next-tabs-btn-down,
.next-tabs-btn-down.visited,
.next-tabs-btn-down:link,
.next-tabs-btn-down:visited,
.next-tabs-btn-next,
.next-tabs-btn-next.visited,
.next-tabs-btn-next:link,
.next-tabs-btn-next:visited,
.next-tabs-btn-prev,
.next-tabs-btn-prev.visited,
.next-tabs-btn-prev:link,
.next-tabs-btn-prev:visited {
  color: #333;
  color: var(--tab-nav-arrow-color-normal, #333);
}

.next-tabs-btn-down.active,
.next-tabs-btn-down.hover,
.next-tabs-btn-down:active,
.next-tabs-btn-down:focus,
.next-tabs-btn-down:hover,
.next-tabs-btn-next.active,
.next-tabs-btn-next.hover,
.next-tabs-btn-next:active,
.next-tabs-btn-next:focus,
.next-tabs-btn-next:hover,
.next-tabs-btn-prev.active,
.next-tabs-btn-prev.hover,
.next-tabs-btn-prev:active,
.next-tabs-btn-prev:focus,
.next-tabs-btn-prev:hover {
  color: #0064c8;
  color: var(--tab-nav-arrow-color-hover, #0064c8);
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
}

.next-tabs-btn-down.disabled,
.next-tabs-btn-next.disabled,
.next-tabs-btn-prev.disabled {
  cursor: not-allowed;
  color: #555;
  color: var(--tab-nav-arrow-color-disabled, #555);
}

.next-tabs-btn-next {
  right: 12px;
  right: var(--tab-nav-arrow-right-positon-right, 12px);
}

.next-tabs-btn-prev {
  right: 32px;
  right: var(--tab-nav-arrow-left-positon-right, 32px);
}

.next-tabs-btn-down {
  right: 8px;
  right: var(--tab-nav-arrow-down-positon-right, 8px);
}

.next-tabs .next-tab-icon-dropdown:before {
  content: "\E63D";
  content: var(--tab-icon-dropdown-content, "\E63D");
}

.next-tabs .next-tab-icon-prev:before {
  content: "\E61D";
  content: var(--tab-icon-prev-content, "\E61D");
}

.next-tabs .next-tab-icon-next:before {
  content: "\E619";
  content: var(--tab-icon-next-content, "\E619");
}

.next-tabs-content {
  overflow: hidden;
}

.next-tabs-vertical > .next-tabs-bar .next-tabs-nav {
  width: 100%;
}

.next-tabs-vertical > .next-tabs-bar .next-tabs-tab {
  display: block;
}

.next-tabs.next-medium .next-tabs-nav-container-scrolling {
  padding-right: 60px;
  padding-right: var(--tab-nav-scroll-padding-right-m, 60px);
}

.next-tabs.next-medium .next-tabs-tab-inner {
  font-size: 14px;
  font-size: var(--tab-item-text-size-m, 14px);
  padding: 12px 16px;
  padding: var(--tab-item-padding-tb-size-m, 12px)
    var(--tab-item-padding-lr-size-m, 16px);
}

.next-tabs.next-medium .next-tabs-tab-inner .next-icon {
  line-height: 1;
}

.next-tabs.next-medium .next-tabs-tab-inner .next-icon .next-icon-remote,
.next-tabs.next-medium .next-tabs-tab-inner .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-tabs.next-medium .next-tabs-tab-inner .next-tabs-tab-close {
  margin-left: 8px;
  margin-left: var(--tab-nav-close-icon-padding-l-size-m, 8px);
}

.next-tabs.next-medium .next-tabs-btn-down .next-icon .next-icon-remote,
.next-tabs.next-medium .next-tabs-btn-down .next-icon:before,
.next-tabs.next-medium .next-tabs-btn-next .next-icon .next-icon-remote,
.next-tabs.next-medium .next-tabs-btn-next .next-icon:before,
.next-tabs.next-medium .next-tabs-btn-prev .next-icon .next-icon-remote,
.next-tabs.next-medium .next-tabs-btn-prev .next-icon:before,
.next-tabs.next-medium
  .next-tabs-tab-inner
  .next-tabs-tab-close
  .next-icon-remote,
.next-tabs.next-medium .next-tabs-tab-inner .next-tabs-tab-close:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-tabs.next-small .next-tabs-nav-container-scrolling {
  padding-right: 56px;
  padding-right: var(--tab-nav-scroll-padding-right-s, 56px);
}

.next-tabs.next-small .next-tabs-tab-inner {
  font-size: 12px;
  font-size: var(--tab-item-text-size-s, 12px);
  padding: 8px 16px;
  padding: var(--tab-item-padding-tb-size-s, 8px)
    var(--tab-item-padding-lr-size-s, 16px);
}

.next-tabs.next-small .next-tabs-tab-inner .next-icon {
  line-height: 1;
}

.next-tabs.next-small .next-tabs-tab-inner .next-icon .next-icon-remote,
.next-tabs.next-small .next-tabs-tab-inner .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-tabs.next-small .next-tabs-tab-inner .next-tabs-tab-close {
  margin-left: 8px;
  margin-left: var(--tab-nav-close-icon-padding-l-size-s, 8px);
}

.next-tabs.next-small
  .next-tabs-tab-inner
  .next-tabs-tab-close
  .next-icon-remote,
.next-tabs.next-small .next-tabs-tab-inner .next-tabs-tab-close:before {
  width: 8px;
  font-size: 8px;
  line-height: inherit;
}

@media (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .next-tabs.next-small .next-tabs-tab-inner .next-tabs-tab-close {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -4px;
    margin-right: -4px;
  }

  .next-tabs.next-small .next-tabs-tab-inner .next-tabs-tab-close:before {
    width: 16px;
    font-size: 16px;
  }
}

.next-tabs.next-small .next-tabs-btn-down .next-icon .next-icon-remote,
.next-tabs.next-small .next-tabs-btn-down .next-icon:before,
.next-tabs.next-small .next-tabs-btn-next .next-icon .next-icon-remote,
.next-tabs.next-small .next-tabs-btn-next .next-icon:before,
.next-tabs.next-small .next-tabs-btn-prev .next-icon .next-icon-remote,
.next-tabs.next-small .next-tabs-btn-prev .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-tabs-pure > .next-tabs-bar {
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--line-solid, solid)
    var(--tab-pure-divider-line-width, 1px)
    var(--tab-pure-divider-border-color, #e3e4e6);
  background-color: transparent;
  background-color: var(--tab-pure-bg-color, transparent);
}

.next-tabs-pure > .next-tabs-bar .next-tabs-nav-container {
  margin-bottom: -1px;
  margin-bottom: calc(0px - var(--tab-pure-divider-line-width, 1px));
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--tab-pure-divider-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--tab-pure-divider-shadow, none);
  box-shadow: var(--tab-pure-divider-shadow, none);
}

.next-tabs-pure > .next-tabs-bar .next-tabs-nav-container .next-tabs-tab {
  color: #333;
  color: var(--tab-pure-text-color-normal, #333);
  background-color: transparent;
}

.next-tabs-pure > .next-tabs-bar .next-tabs-nav-container .next-tabs-tab:hover {
  cursor: pointer;
  color: #0064c8;
  color: var(--tab-pure-text-color-hover, #0064c8);
  background-color: transparent;
}

.next-tabs-pure
  > .next-tabs-bar
  .next-tabs-nav-container
  .next-tabs-tab.active {
  z-index: 1;
  color: #0064c8;
  color: var(--tab-pure-text-color-selected, #0064c8);
  background-color: transparent;
}

.next-tabs-pure
  > .next-tabs-bar
  .next-tabs-nav-container
  .next-tabs-tab.disabled {
  pointer-events: none;
  cursor: default;
  color: #c0c6cc;
  color: var(--tab-pure-text-color-disabled, #c0c6cc);
  background: transparent;
}

.next-tabs-pure
  > .next-tabs-bar
  .next-tabs-nav-container
  .next-tabs-tab:before {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  width: 0;
  border-bottom: 2px solid #0064c8;
  border-bottom: var(--tab-pure-ink-bar-width, 2px) solid
    var(--tab-pure-ink-bar-color, #0064c8);
  left: 50%;
  bottom: 0;
}

.next-tabs-pure
  > .next-tabs-bar
  .next-tabs-nav-container
  .next-tabs-tab.active:before {
  width: 100%;
  left: 0;
}

.next-tabs-wrapped > .next-tabs-bar {
  background: transparent;
  background: var(--tab-wrapped-bar-bg-color, transparent);
}

.next-tabs-wrapped > .next-tabs-bar .next-tabs-tab {
  color: #333;
  color: var(--tab-wrapped-text-color, #333);
  background-color: #fff;
  background-color: var(--tab-wrapped-bg-color, #fff);
}

.next-tabs-wrapped > .next-tabs-bar .next-tabs-tab:hover {
  cursor: pointer;
  color: #333;
  color: var(--tab-wrapped-text-color-hover, #333);
  background-color: #f8f8f9;
  background-color: var(--tab-wrapped-bg-color-hover, #f8f8f9);
}

.next-tabs-wrapped > .next-tabs-bar .next-tabs-tab.active {
  z-index: 1;
  color: #0064c8;
  color: var(--tab-wrapped-text-color-selected, #0064c8);
  background-color: #fff;
  background-color: var(--tab-wrapped-bg-color-selected, #fff);
}

.next-tabs-wrapped > .next-tabs-bar .next-tabs-tab.disabled {
  pointer-events: none;
  cursor: default;
  color: #c1c1c1;
  color: var(--tab-wrapped-text-color-disabled, #c1c1c1);
  background: #f8f8f9;
  background: var(--tab-wrapped-bg-color-disabled, #f8f8f9);
}

.next-tabs-wrapped > .next-tabs-bar .next-tabs-tab .next-tabs-tab-close {
  color: #555;
  color: var(--tab-close-icon-color, #555);
}

.next-tabs-wrapped > .next-tabs-bar .next-tabs-tab .next-tabs-tab-close:hover {
  color: #111;
  color: var(--tab-close-icon-color-hover, #111);
}

.next-tabs-wrapped > .next-tabs-bar .next-tabs-tab .next-tabs-tab-close:focus {
  outline: none;
}

.next-tabs-wrapped > .next-tabs-bar .next-tabs-tab.active .next-tabs-tab-close {
  color: #0064c8;
  color: var(--tab-close-icon-color-selected, #0064c8);
}

.next-tabs-wrapped
  > .next-tabs-bar
  .next-tabs-tab.disabled
  .next-tabs-tab-close {
  color: #c0c6cc;
  color: var(--tab-close-icon-color-disabled, #c0c6cc);
}

.next-tabs-wrapped:after,
.next-tabs-wrapped:before {
  content: "";
  display: table;
}

.next-tabs-wrapped:after {
  clear: both;
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar,
.next-tabs-wrapped > .next-tabs-content {
  position: relative;
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar .next-tabs-nav-extra {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar .next-tabs-tab {
  margin-right: -1px;
  margin-right: var(--tab-wrapped-tab-margin-right, -1px);
  border: 1px solid #e3e4e6;
  border: var(--tab-wrapped-border-line-style, solid)
    var(--tab-wrapped-border-line-width, 1px)
    var(--tab-wrapped-border-line-color, #e3e4e6);
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  -webkit-border-radius: var(--tab-wrapped-tab-corner-radius, 0)
    var(--tab-wrapped-tab-corner-radius, 0) 0 0;
  -moz-border-radius: var(--tab-wrapped-tab-corner-radius, 0)
    var(--tab-wrapped-tab-corner-radius, 0) 0 0;
  border-radius: var(--tab-wrapped-tab-corner-radius, 0)
    var(--tab-wrapped-tab-corner-radius, 0) 0 0;
  border-width: 1px;
  border-width: var(--tab-wrapped-border-side-width, 1px)
    var(--tab-wrapped-border-side-width, 1px)
    var(--tab-wrapped-border-line-width, 1px);
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar .next-tabs-tab:hover {
  border-color: #e3e4e6;
  border-color: var(--tab-wrapped-border-line-color-hover, #e3e4e6);
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar .next-tabs-tab.active {
  border-color: #e3e4e6 #e3e4e6 #fff;
  border-color: var(--tab-wrapped-border-line-color, #e3e4e6)
    var(--tab-wrapped-border-line-color, #e3e4e6)
    var(--tab-wrapped-bg-color-selected, #fff);
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar .next-tabs-tab:before {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  -moz-border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  width: 0;
  border-top: 2px solid #0064c8;
  border-top: var(--tab-wrapped-ink-bar-width, 2px) solid
    var(--tab-wrapped-ink-bar-color, #0064c8);
  left: 50%;
  top: -1px;
  top: -webkit-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  top: -moz-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  top: calc(0px - var(--tab-wrapped-border-line-width, 1px));
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar .next-tabs-tab.active:before {
  width: 100%;
  left: 0;
  left: var(--tab-wrapped-tab-corner-radius, 0);
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar .next-tabs-tab.active {
  border-width: 1px;
  border-width: var(--tab-wrapped-border-line-width, 1px);
}

.next-tabs-wrapped.next-tabs-top > .next-tabs-bar:before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  height: 0;
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--tab-wrapped-content-border-line-width, 1px)
    var(--tab-wrapped-content-border-line-style, solid)
    var(--tab-wrapped-border-line-color, #e3e4e6);
  -webkit-transform: translateY(-1px);
  -webkit-transform: translateY(
    -webkit-calc(0 - var(--tab-wrapped-content-border-line-width, 1px))
  );
  -moz-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
  -moz-transform: translateY(
    -moz-calc(0 - var(--tab-wrapped-content-border-line-width, 1px))
  );
  -o-transform: translateY(
    calc(0px - var(--tab-wrapped-content-border-line-width, 1px))
  );
  transform: translateY(
    calc(0px - var(--tab-wrapped-content-border-line-width, 1px))
  );
  display: block;
}

.next-tabs-wrapped.next-tabs-bottom > .next-tabs-bar {
  position: relative;
}

.next-tabs-wrapped.next-tabs-bottom > .next-tabs-bar .next-tabs-nav-extra {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.next-tabs-wrapped.next-tabs-bottom > .next-tabs-bar .next-tabs-tab {
  margin-right: -1px;
  margin-right: var(--tab-wrapped-tab-margin-right, -1px);
  border: 1px solid #e3e4e6;
  border: var(--tab-wrapped-border-line-style, solid)
    var(--tab-wrapped-border-line-width, 1px)
    var(--tab-wrapped-border-line-color, #e3e4e6);
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 var(--tab-wrapped-tab-corner-radius, 0)
    var(--tab-wrapped-tab-corner-radius, 0);
  -moz-border-radius: 0 0 var(--tab-wrapped-tab-corner-radius, 0)
    var(--tab-wrapped-tab-corner-radius, 0);
  border-radius: 0 0 var(--tab-wrapped-tab-corner-radius, 0)
    var(--tab-wrapped-tab-corner-radius, 0);
}

.next-tabs-wrapped.next-tabs-bottom > .next-tabs-bar .next-tabs-tab:hover {
  border-color: #e3e4e6;
  border-color: var(--tab-wrapped-border-line-color-hover, #e3e4e6);
}

.next-tabs-wrapped.next-tabs-bottom > .next-tabs-bar .next-tabs-tab.active {
  border-color: #fff #e3e4e6 #e3e4e6;
  border-color: var(--tab-wrapped-bg-color-selected, #fff)
    var(--tab-wrapped-border-line-color, #e3e4e6)
    var(--tab-wrapped-border-line-color, #e3e4e6);
}

.next-tabs-wrapped.next-tabs-bottom > .next-tabs-bar .next-tabs-tab:before {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  -moz-border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  width: 0;
  border-bottom: 2px solid #0064c8;
  border-bottom: var(--tab-wrapped-ink-bar-width, 2px) solid
    var(--tab-wrapped-ink-bar-color, #0064c8);
  left: 50%;
  bottom: -1px;
  bottom: -webkit-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  bottom: -moz-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  bottom: calc(0px - var(--tab-wrapped-border-line-width, 1px));
}

.next-tabs-wrapped.next-tabs-bottom
  > .next-tabs-bar
  .next-tabs-tab.active:before {
  width: 100%;
  left: 0;
  left: var(--tab-wrapped-tab-corner-radius, 0);
}

.next-tabs-wrapped.next-tabs-bottom > .next-tabs-content {
  top: 1px;
  top: var(--tab-wrapped-border-line-width, 1px);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--tab-wrapped-content-border-line-width, 1px)
    var(--tab-wrapped-content-border-line-style, solid)
    var(--tab-wrapped-border-line-color, #e3e4e6);
}

.next-tabs-wrapped.next-tabs-left > .next-tabs-bar {
  float: left;
}

.next-tabs-wrapped.next-tabs-left > .next-tabs-bar .next-tabs-tab {
  float: none;
  margin-bottom: 4px;
  margin-bottom: var(--tab-wrapped-tab-margin-bottom, 4px);
  border: 1px solid #e3e4e6;
  border: var(--tab-wrapped-border-line-style, solid)
    var(--tab-wrapped-border-line-width, 1px)
    var(--tab-wrapped-border-line-color, #e3e4e6);
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  -webkit-border-radius: var(--tab-wrapped-tab-corner-radius, 0) 0 0
    var(--tab-wrapped-tab-corner-radius, 0);
  -moz-border-radius: var(--tab-wrapped-tab-corner-radius, 0) 0 0
    var(--tab-wrapped-tab-corner-radius, 0);
  border-radius: var(--tab-wrapped-tab-corner-radius, 0) 0 0
    var(--tab-wrapped-tab-corner-radius, 0);
  border-width: 1px;
  border-width: var(--tab-wrapped-border-side-width, 1px)
    var(--tab-wrapped-border-line-width, 1px)
    var(--tab-wrapped-border-side-width, 1px)
    var(--tab-wrapped-border-side-width, 1px);
}

.next-tabs-wrapped.next-tabs-left > .next-tabs-bar .next-tabs-tab:hover {
  border-color: #e3e4e6;
  border-color: var(--tab-wrapped-border-line-color-hover, #e3e4e6);
}

.next-tabs-wrapped.next-tabs-left > .next-tabs-bar .next-tabs-tab.active {
  border-color: #e3e4e6 #fff #e3e4e6 #e3e4e6;
  border-color: var(--tab-wrapped-border-line-color, #e3e4e6)
    var(--tab-wrapped-bg-color-selected, #fff)
    var(--tab-wrapped-border-line-color, #e3e4e6)
    var(--tab-wrapped-border-line-color, #e3e4e6);
}

.next-tabs-wrapped.next-tabs-left > .next-tabs-bar .next-tabs-tab:before {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  -moz-border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  height: 0;
  border-left: 2px solid #0064c8;
  border-left: var(--tab-wrapped-ink-bar-width, 2px) solid
    var(--tab-wrapped-ink-bar-color, #0064c8);
  top: 50%;
  left: -1px;
  left: -webkit-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  left: -moz-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  left: calc(0px - var(--tab-wrapped-border-line-width, 1px));
}

.next-tabs-wrapped.next-tabs-left
  > .next-tabs-bar
  .next-tabs-tab.active:before {
  height: 100%;
  top: 0;
  top: var(--tab-wrapped-tab-corner-radius, 0);
}

.next-tabs-wrapped.next-tabs-left > .next-tabs-bar .next-tabs-tab.active {
  border-width: 1px;
  border-width: var(--tab-wrapped-border-line-width, 1px);
}

.next-tabs-wrapped.next-tabs-left > .next-tabs-content {
  right: 1px;
  right: var(--tab-wrapped-border-line-width, 1px);
  border-left: 1px solid #e3e4e6;
  border-left: var(--tab-wrapped-content-border-line-width, 1px)
    var(--tab-wrapped-content-border-line-style, solid)
    var(--tab-wrapped-border-line-color, #e3e4e6);
}

.next-tabs-wrapped.next-tabs-right > .next-tabs-bar {
  float: right;
}

.next-tabs-wrapped.next-tabs-right > .next-tabs-bar .next-tabs-tab {
  float: none;
  margin-bottom: 4px;
  margin-bottom: var(--tab-wrapped-tab-margin-bottom, 4px);
  border: 1px solid #e3e4e6;
  border: var(--tab-wrapped-border-line-style, solid)
    var(--tab-wrapped-border-line-width, 1px)
    var(--tab-wrapped-border-line-color, #e3e4e6);
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  -webkit-border-radius: 0 var(--tab-wrapped-tab-corner-radius, 0)
    var(--tab-wrapped-tab-corner-radius, 0) 0;
  -moz-border-radius: 0 var(--tab-wrapped-tab-corner-radius, 0)
    var(--tab-wrapped-tab-corner-radius, 0) 0;
  border-radius: 0 var(--tab-wrapped-tab-corner-radius, 0)
    var(--tab-wrapped-tab-corner-radius, 0) 0;
  border-width: 1px;
  border-width: var(--tab-wrapped-border-side-width, 1px)
    var(--tab-wrapped-border-side-width, 1px)
    var(--tab-wrapped-border-side-width, 1px)
    var(--tab-wrapped-border-line-width, 1px);
}

.next-tabs-wrapped.next-tabs-right > .next-tabs-bar .next-tabs-tab:hover {
  border-color: #e3e4e6;
  border-color: var(--tab-wrapped-border-line-color-hover, #e3e4e6);
}

.next-tabs-wrapped.next-tabs-right > .next-tabs-bar .next-tabs-tab.active {
  border-color: #e3e4e6 #e3e4e6 #e3e4e6 #fff;
  border-color: var(--tab-wrapped-border-line-color, #e3e4e6)
    var(--tab-wrapped-border-line-color, #e3e4e6)
    var(--tab-wrapped-border-line-color, #e3e4e6)
    var(--tab-wrapped-bg-color-selected, #fff);
}

.next-tabs-wrapped.next-tabs-right > .next-tabs-bar .next-tabs-tab:before {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  -moz-border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  border-radius: var(--tab-wrapped-tab-corner-radius, 0);
  height: 0;
  border-right: 2px solid #0064c8;
  border-right: var(--tab-wrapped-ink-bar-width, 2px) solid
    var(--tab-wrapped-ink-bar-color, #0064c8);
  top: 50%;
  right: -1px;
  right: -webkit-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  right: -moz-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  right: calc(0px - var(--tab-wrapped-border-line-width, 1px));
}

.next-tabs-wrapped.next-tabs-right
  > .next-tabs-bar
  .next-tabs-tab.active:before {
  height: 100%;
  top: 0;
  top: var(--tab-wrapped-tab-corner-radius, 0);
}

.next-tabs-wrapped.next-tabs-right > .next-tabs-bar .next-tabs-tab.active {
  border-width: 1px;
  border-width: var(--tab-wrapped-border-line-width, 1px);
}

.next-tabs-wrapped.next-tabs-right > .next-tabs-content {
  right: -1px;
  right: -webkit-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  right: -moz-calc(0 - var(--tab-wrapped-border-line-width, 1px));
  right: calc(0px - var(--tab-wrapped-border-line-width, 1px));
  border-right: 1px solid #e3e4e6;
  border-right: var(--tab-wrapped-content-border-line-width, 1px)
    var(--tab-wrapped-content-border-line-style, solid)
    var(--tab-wrapped-border-line-color, #e3e4e6);
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-tab {
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
  border: 1px solid #c0c6cc;
  border: var(--tab-capsule-tab-border-line-width, 1px)
    var(--tab-capsule-tab-border-line-style, solid)
    var(--tab-capsule-tab-border-line-color, #c0c6cc);
  border-right-color: transparent;
  margin-right: -1px;
  margin-right: calc(0px - var(--tab-capsule-tab-border-line-width, 1px));
  color: #333;
  color: var(--tab-capsule-text-color, #333);
  background-color: #fff;
  background-color: var(--tab-capsule-bg-color, #fff);
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-tab:first-child {
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  -webkit-border-radius: var(--tab-capsule-corner-radius, 2px) 0 0
    var(--tab-capsule-corner-radius, 2px);
  -moz-border-radius: var(--tab-capsule-corner-radius, 2px) 0 0
    var(--tab-capsule-corner-radius, 2px);
  border-radius: var(--tab-capsule-corner-radius, 2px) 0 0
    var(--tab-capsule-corner-radius, 2px);
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-tab:last-child {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--tab-capsule-corner-radius, 2px)
    var(--tab-capsule-corner-radius, 2px) 0;
  -moz-border-radius: 0 var(--tab-capsule-corner-radius, 2px)
    var(--tab-capsule-corner-radius, 2px) 0;
  border-radius: 0 var(--tab-capsule-corner-radius, 2px)
    var(--tab-capsule-corner-radius, 2px) 0;
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-tab.active,
.next-tabs-capsule > .next-tabs-bar .next-tabs-tab:last-child {
  border-right: 1px solid #c0c6cc;
  border-right: var(--tab-capsule-tab-border-line-width, 1px)
    var(--tab-capsule-tab-border-line-style, solid)
    var(--tab-capsule-tab-border-line-color, #c0c6cc);
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-tab.active {
  border-color: #c0c6cc;
  border-color: var(--tab-capsule-tab-border-line-color-active, #c0c6cc);
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-tab.disabled {
  border-color: #e3e4e6;
  border-color: var(--tab-capsule-tab-border-line-color-disabled, #e3e4e6);
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-tab:hover {
  z-index: 2;
  border-right: 1px solid #c0c6cc;
  border-right: var(--tab-capsule-tab-border-line-width, 1px)
    var(--tab-capsule-tab-border-line-style, solid)
    var(--tab-capsule-tab-border-line-color, #c0c6cc);
  border-color: #c0c6cc;
  border-color: var(--tab-capsule-tab-border-line-color-hover, #c0c6cc);
  cursor: pointer;
  color: #333;
  color: var(--tab-capsule-text-color-hover, #333);
  background-color: #f7f9fa;
  background-color: var(--tab-capsule-bg-color-hover, #f7f9fa);
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-tab.active {
  z-index: 1;
  color: #0064c8;
  color: var(--tab-capsule-text-color-selected, #0064c8);
  background-color: #eff3f8;
  background-color: var(--tab-capsule-bg-color-selected, #eff3f8);
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-tab.disabled {
  pointer-events: none;
  cursor: default;
  color: #c1c1c1;
  color: var(--tab-capsule-text-color-disabled, #c1c1c1);
  background: #f8f8f9;
  background: var(--tab-capsule-bg-color-disabled, #f8f8f9);
}

.next-tabs-text > .next-tabs-bar .next-tabs-tab {
  color: #555;
  color: var(--tab-text-text-color-normal, #555);
  background-color: transparent;
}

.next-tabs-text > .next-tabs-bar .next-tabs-tab:hover {
  cursor: pointer;
  color: #0064c8;
  color: var(--tab-text-text-color-hover, #0064c8);
  background-color: transparent;
}

.next-tabs-text > .next-tabs-bar .next-tabs-tab.active {
  z-index: 1;
  color: #0064c8;
  color: var(--tab-text-text-color-selected, #0064c8);
  background-color: transparent;
}

.next-tabs-text > .next-tabs-bar .next-tabs-tab.disabled {
  pointer-events: none;
  cursor: default;
  color: #c1c1c1;
  color: var(--tab-text-text-color-disabled, #c1c1c1);
  background: transparent;
}

.next-tabs-text > .next-tabs-bar .next-tabs-tab:not(:last-child):after {
  content: "";
  position: absolute;
  right: 0;
  top: -webkit-calc(50% - 6px);
  top: -moz-calc(50% - 6px);
  top: calc(50% - 6px);
  top: -webkit-calc(50% - var(--tab-text-item-sep-height, 12px) / 2);
  top: -moz-calc(50% - var(--tab-text-item-sep-height, 12px) / 2);
  top: calc(50% - var(--tab-text-item-sep-height, 12px) / 2);
  width: 1px;
  width: var(--tab-text-item-sep-width, 1px);
  height: 12px;
  height: var(--tab-text-item-sep-height, 12px);
  background-color: #c0c6cc;
  background-color: var(--tab-text-item-sep-color, #c0c6cc);
}

.next-tabs-pure > .next-tabs-bar {
  position: relative;
}

.next-tabs-pure > .next-tabs-bar .next-tabs-nav-extra {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.next-tabs-capsule > .next-tabs-bar {
  position: relative;
}

.next-tabs-capsule > .next-tabs-bar .next-tabs-nav-extra {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.next-tabs-text > .next-tabs-bar {
  position: relative;
}

.next-tabs-text > .next-tabs-bar .next-tabs-nav-extra {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.next-tabs[dir="rtl"].next-medium .next-tabs-nav-container-scrolling {
  padding-left: 60px;
  padding-left: var(--tab-nav-scroll-padding-right-m, 60px);
  padding-right: 0;
}

.next-tabs[dir="rtl"].next-medium .next-tabs-tab-close {
  padding-right: 8px;
  padding-right: var(--tab-nav-close-icon-padding-l-size-m, 8px);
  padding-left: 0;
}

.next-tabs[dir="rtl"].next-small .next-tabs-nav-container-scrolling {
  padding-left: 56px;
  padding-left: var(--tab-nav-scroll-padding-right-s, 56px);
  padding-right: 0;
}

.next-tabs[dir="rtl"].next-small .next-tabs-tab-close {
  padding-right: 8px;
  padding-right: var(--tab-nav-close-icon-padding-l-size-s, 8px);
  padding-left: 0;
}

.next-tabs[dir="rtl"].next-tabs-wrapped.next-tabs-bottom
  > .next-tabs-bar
  .next-tabs-nav-extra,
.next-tabs[dir="rtl"].next-tabs-wrapped.next-tabs-top
  > .next-tabs-bar
  .next-tabs-nav-extra,
.next-tabs[dir="rtl"] > .next-tabs-bar .next-tabs-nav-extra {
  right: auto;
  left: 0;
}

.next-tabs[dir="rtl"].next-tabs-capsule > .next-tabs-bar .next-tabs-tab {
  border-top: 1px solid #c0c6cc;
  border-bottom: 1px solid #c0c6cc;
  border-right: 1px solid #c0c6cc;
  border: var(--tab-capsule-tab-border-line-width, 1px)
    var(--tab-capsule-tab-border-line-style, solid)
    var(--tab-capsule-tab-border-line-color, #c0c6cc);
  border-left: 0;
}

.next-tabs[dir="rtl"].next-tabs-capsule
  > .next-tabs-bar
  .next-tabs-tab:first-child {
  border-left: 0;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--tab-capsule-corner-radius, 2px)
    var(--tab-capsule-corner-radius, 2px) 0;
  -moz-border-radius: 0 var(--tab-capsule-corner-radius, 2px)
    var(--tab-capsule-corner-radius, 2px) 0;
  border-radius: 0 var(--tab-capsule-corner-radius, 2px)
    var(--tab-capsule-corner-radius, 2px) 0;
}

.next-tabs[dir="rtl"].next-tabs-capsule
  > .next-tabs-bar
  .next-tabs-tab:last-child {
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  -webkit-border-radius: var(--tab-capsule-corner-radius, 2px) 0 0
    var(--tab-capsule-corner-radius, 2px);
  -moz-border-radius: var(--tab-capsule-corner-radius, 2px) 0 0
    var(--tab-capsule-corner-radius, 2px);
  border-radius: var(--tab-capsule-corner-radius, 2px) 0 0
    var(--tab-capsule-corner-radius, 2px);
}

.next-tabs[dir="rtl"].next-tabs-capsule > .next-tabs-bar .next-tabs-tab.active,
.next-tabs[dir="rtl"].next-tabs-capsule
  > .next-tabs-bar
  .next-tabs-tab:last-child {
  border-left: 1px solid #c0c6cc;
  border-left: var(--tab-capsule-tab-border-line-width, 1px)
    var(--tab-capsule-tab-border-line-style, solid)
    var(--tab-capsule-tab-border-line-color, #c0c6cc);
}

.next-tabs[dir="rtl"].next-tabs-capsule > .next-tabs-bar .next-tabs-tab.active {
  margin-left: -1px;
  margin-left: calc(0px - var(--tab-capsule-tab-border-line-width, 1px));
  margin-right: auto;
  border-color: #c0c6cc;
  border-color: var(--tab-capsule-tab-border-line-color-active, #c0c6cc);
}

.next-tabs[dir="rtl"] .next-tabs-btn-next {
  left: 12px;
  left: var(--tab-nav-arrow-right-positon-right, 12px);
  right: auto;
}

.next-tabs[dir="rtl"] .next-tabs-btn-prev {
  left: 32px;
  left: var(--tab-nav-arrow-left-positon-right, 32px);
  right: auto;
}

.next-tabs[dir="rtl"] .next-tabs-btn-down {
  left: 8px;
  left: var(--tab-nav-arrow-down-positon-right, 8px);
  right: auto;
}

.next-tabs-text[dir="rtl"]
  > .next-tabs-bar
  .next-tabs-tab:not(:last-child):after {
  content: "";
  position: absolute;
  left: 0;
  right: auto;
}

.next-table {
  position: relative;
  border-top: 1px solid #e3e4e6;
  border-top: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
  border-left: 1px solid #e3e4e6;
  border-left: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
}

.next-table,
.next-table *,
.next-table :after,
.next-table :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-table.next-table-layout-fixed {
  overflow: auto;
}

.next-table.next-table-layout-fixed table {
  table-layout: fixed;
}

.next-table.next-table-layout-auto table {
  table-layout: auto;
}

.next-table.next-table-small th .next-table-cell-wrapper {
  padding: 8px;
  padding: var(--table-size-s-header-padding-top, 8px)
    var(--table-size-s-header-padding-left, 8px);
}

.next-table.next-table-small td .next-table-cell-wrapper {
  padding: 8px;
  padding: var(--table-size-s-cell-padding-top, 8px)
    var(--table-size-s-cell-padding-left, 8px);
}

.next-table.next-table-small .next-table-prerow .next-table-cell-wrapper {
  padding: 8px;
}

.next-table table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background: #fff;
  background: var(--table-row-bg, #fff);
}

.next-table table tr:first-child td {
  border-top-width: 0;
}

.next-table th {
  padding: 0;
  background: #eff3f8;
  background: var(--table-th-bg, #eff3f8);
  color: #333;
  color: var(--table-th-color, #333);
  text-align: left;
  font-weight: 400;
  font-weight: var(--table-th-font-weight, normal);
  border-right: 1px solid #e3e4e6;
  border-right: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
}

.next-table th .next-table-cell-wrapper {
  padding: 8px 16px;
  padding: var(--table-header-padding-top, 8px)
    var(--table-header-padding-left, 16px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-break: break-all;
}

.next-table th.next-table-prerow .next-table-cell-wrapper {
  padding: 12px 16px;
}

.next-table th.next-table-fix-left,
.next-table th.next-table-fix-right {
  z-index: 1;
}

.next-table-affix {
  z-index: 1;
  overflow: hidden;
}

.next-table-stickylock .next-table-affix {
  z-index: 9;
}

.next-table-header-resizable {
  position: relative;
}

.next-table-header-resizable .next-table-resize-handler {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: transparent;
  cursor: ew-resize;
}

.next-table td {
  padding: 0;
  border-right: 1px solid #e3e4e6;
  border-right: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
}

.next-table td .next-table-cell-wrapper {
  padding: 8px 16px;
  padding: var(--table-cell-padding-top, 8px)
    var(--table-cell-padding-left, 16px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-break: break-all;
}

.next-table
  td
  .next-table-cell-wrapper
  .next-icon-arrow-down.next-table-tree-arrow,
.next-table
  td
  .next-table-cell-wrapper
  .next-icon-arrow-right.next-table-tree-arrow,
.next-table td .next-table-cell-wrapper .next-table-tree-placeholder {
  margin-right: 8px;
  outline: 0;
  cursor: pointer;
}

.next-table
  td
  .next-table-cell-wrapper
  .next-icon-arrow-right.next-table-tree-arrow
  .next-icon-remote,
.next-table
  td
  .next-table-cell-wrapper
  .next-icon-arrow-right.next-table-tree-arrow:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-table
  td
  .next-table-cell-wrapper
  .next-icon-arrow-right.next-table-tree-arrow:before {
  content: "\E61A";
  content: var(--table-tree-fold-icon-content, "\E61A");
}

.next-table
  td
  .next-table-cell-wrapper
  .next-icon-arrow-down.next-table-tree-arrow
  .next-icon-remote,
.next-table
  td
  .next-table-cell-wrapper
  .next-icon-arrow-down.next-table-tree-arrow:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-table
  td
  .next-table-cell-wrapper
  .next-icon-arrow-down.next-table-tree-arrow:before {
  content: "\E618";
  content: var(--table-tree-unfold-icon-content, "\E618");
}

.next-table td.next-table-prerow .next-table-cell-wrapper {
  padding: 12px 16px;
}

.next-table .next-table-expanded .next-table-cell-wrapper,
.next-table .next-table-selection .next-table-cell-wrapper {
  overflow: visible;
}

.next-table.no-header table tr:first-child td {
  border-top-width: 1px;
}

.next-table.only-bottom-border {
  border-width: 0;
}

.next-table.only-bottom-border td,
.next-table.only-bottom-border th {
  border-width: 0 0 1px;
  border-bottom-width: var(--table-normal-border-width, 1px);
  border-right-width: 0;
  border-top-width: 0;
}

.next-table.only-bottom-border table tr td:first-child,
.next-table.only-bottom-border table tr th:first-child {
  border-left-width: 0;
}

.next-table.only-bottom-border .next-table-body tr td:last-child,
.next-table.only-bottom-border .next-table-header tr th:last-child {
  border-right-width: 0;
}

.next-table-loading {
  display: block;
}

.next-table.zebra tr:nth-child(odd) td {
  background: #fff;
  background: var(--table-td-normal, #fff);
}

.next-table.zebra tr:nth-child(2n) td {
  background: #f8f8f9;
  background: var(--table-td-gray, #f8f8f9);
}

.next-table.zebra .next-table-cell.hovered,
.next-table.zebra .next-table-row.hovered td {
  background: #f7f9fa;
  background: var(--table-row-hover-bg, #f7f9fa);
  color: #333;
  color: var(--table-row-hover-color, #333);
}

.next-table.zebra .next-table-row.selected td {
  background: #eff3f8;
  background: var(--table-row-selected-bg, #eff3f8);
  color: #333;
  color: var(--table-row-selected-color, #333);
}

.next-table-empty {
  color: #ccc;
  color: var(--table-empty-color, #ccc);
  padding: 32px 0;
  padding: var(--table-empty-padding, 32px) 0;
  text-align: center;
}

.next-table-expanded-row > td {
  border-width: 0 0 1px;
  border-bottom-width: var(--table-normal-border-width, 1px);
  border-right-width: 0;
  border-top-width: 0;
}

.next-table-expanded-row > td:first-child {
  border-left-width: 1px;
  border-left-width: var(--table-normal-border-width, 1px);
}

.next-table-expanded-row > td:last-child {
  border-right-width: 1px;
  border-right-width: var(--table-normal-border-width, 1px);
}

.next-table-expanded-row:last-child > td {
  border-bottom-width: 1px;
  border-bottom-width: var(--table-normal-border-width, 1px);
}

.next-table-expanded-row .next-table {
  border-top: 0;
  border-left: 0;
}

.next-table-expanded-row .next-table td,
.next-table-expanded-row .next-table th {
  border-right: 1px solid #e3e4e6;
  border-right: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
}

.next-table-expanded-row .next-table.only-bottom-border td,
.next-table-expanded-row .next-table.only-bottom-border th {
  border-right: 0;
}

.next-table-expanded-row .next-table .last td {
  border-bottom: 0;
}

.next-table-expanded-row .next-table td.last,
.next-table-expanded-row .next-table th:last-child {
  border-right: 0;
}

.next-table-filter-footer {
  margin: 10px 10px 0;
}

.next-table-filter-footer button {
  margin-right: 5px;
}

.next-table-row {
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  background: #fff;
  background: var(--table-row-bg, #fff);
  color: #333;
  color: var(--table-row-color, #333);
}

.next-table-row.hidden {
  display: none;
}

.next-table-row.hovered {
  background: #f7f9fa;
  background: var(--table-row-hover-bg, #f7f9fa);
  color: #333;
  color: var(--table-row-hover-color, #333);
}

.next-table-row.selected {
  background: #eff3f8;
  background: var(--table-row-selected-bg, #eff3f8);
  color: #333;
  color: var(--table-row-selected-color, #333);
}

.next-table-cell.hovered {
  background: #f7f9fa;
  background: var(--table-row-hover-bg, #f7f9fa);
  color: #333;
  color: var(--table-row-hover-color, #333);
}

.next-table-tree-placeholder {
  display: inline-block;
  width: 12px;
}

.last .next-table-expanded-row td {
  border-bottom-width: 1px;
}

.next-table-body,
.next-table-header {
  overflow: auto;
  font-size: 12px;
  font-size: var(--table-th-font-size, 12px);
}

.next-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.next-table-header::-webkit-scrollbar {
  display: none;
}

.next-table-body {
  font-size: 12px;
  font-size: var(--table-body-font-size, 12px);
  position: relative;
}

.next-table-fixed {
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
}

.next-table-fixed table {
  table-layout: fixed;
}

.next-table-fixed .next-table-header {
  background: #eff3f8;
  background: var(--table-th-bg, #eff3f8);
}

.next-table-fixed table tr td:first-child,
.next-table-fixed table tr th:first-child {
  border-left-width: 0;
}

.next-table-fixed .next-table-header th {
  border-top-width: 0;
}

.next-table-fixed .next-table-header tr th:last-child {
  border-right-width: 0;
}

.next-table-fixed .next-table-body td {
  border-top-width: 0;
}

.next-table-fixed .next-table-body tr:last-child td {
  border-bottom-width: 0;
}

.next-table-fixed .next-table-body tr td:last-child {
  border-right-width: 0;
}

.next-table-fixed .next-table-body tr td:last-child,
.next-table-fixed .next-table-header tr th:last-child {
  border-right-width: 1px;
  border-right-width: var(--table-normal-border-width, 1px);
}

.next-table-fixed.next-table-group table tr td:first-child,
.next-table-fixed.next-table-group table tr th:first-child {
  border-left-width: 1px;
  border-left-width: var(--table-normal-border-width, 1px);
}

.next-table-fixed.next-table-group .next-table-header th {
  border-top-width: 1px;
  border-top-width: var(--table-normal-border-width, 1px);
}

.next-table-fixed.next-table-group .next-table-header tr th:last-child {
  border-right-width: 1px;
  border-right-width: var(--table-normal-border-width, 1px);
}

.next-table-fixed.next-table-group .next-table-body td {
  border-top-width: 1px;
  border-top-width: var(--table-normal-border-width, 1px);
}

.next-table-fixed.next-table-group .next-table-body tr:last-child td {
  border-bottom-width: 1px;
  border-bottom-width: var(--table-normal-border-width, 1px);
}

.next-table-fixed.next-table-group .next-table-body tr td:last-child {
  border-right-width: 1px;
  border-right-width: var(--table-normal-border-width, 1px);
}

.next-table-lock .next-table-body {
  overflow-x: auto;
  overflow-y: visible;
}

.next-table-group {
  border-width: 0;
}

.next-table-group .next-table-body {
  margin-top: 8px;
  margin-top: var(--table-group-split, 8px);
}

.next-table-group .next-table-body table {
  border-top: 1px solid #e3e4e6;
  border-top: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
  border-left: 1px solid #e3e4e6;
  border-left: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
  margin-bottom: 8px;
  margin-bottom: var(--table-group-split, 8px);
}

.next-table-group .next-table-body table tr:first-child td {
  border-top-width: 1px;
}

.next-table-group .next-table-body table:last-of-type {
  margin-bottom: 0;
}

.next-table-group .next-table-header table {
  border-top: 1px solid #e3e4e6;
  border-top: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
  border-left: 1px solid #e3e4e6;
  border-left: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
}

.next-table-group .next-table-group-header td {
  background: #f7f9fa;
  background: var(--table-group-th-bg, #f7f9fa);
  color: #111;
  color: var(--table-group-th-color, #111);
}

.next-table-group .next-table-group-footer td {
  background: #f7f9fa;
  background: var(--table-group-footer-bg, #f7f9fa);
  color: #111;
  color: var(--table-group-footer-color, #111);
}

.next-table-group .next-table-row.hovered,
.next-table-group .next-table-row.selected {
  background: #fff;
  background: var(--table-row-bg, #fff);
  color: #333;
  color: var(--table-row-color, #333);
}

.next-table-lock {
  position: relative;
}

.next-table-lock table {
  table-layout: fixed;
}

.next-table-header-inner {
  overflow: unset;
}

.next-table-wrap-empty .next-table-lock-left td,
.next-table-wrap-empty .next-table-lock-right td {
  border: none;
}

.next-table-wrap-empty .next-table-lock-left .next-table-empty,
.next-table-wrap-empty .next-table-lock-right .next-table-empty {
  display: none;
}

.next-table-wrap-empty > .next-table-inner > .next-table-body > table {
  table-layout: fixed;
}

.next-table-lock-left,
.next-table-lock-right {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border: 0;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  -o-transition: box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease, -moz-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease,
    -moz-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
  overflow: hidden;
}

.next-table-lock-left table,
.next-table-lock-right table {
  width: auto;
}

.next-table-lock-left .next-table-body,
.next-table-lock-right .next-table-body {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -20px;
  padding-right: 0;
}

.next-table-lock-left.shadow .next-table-body tr td:last-child,
.next-table-lock-left.shadow .next-table-header tr th:last-child,
.next-table-lock-right.shadow .next-table-body tr td:last-child,
.next-table-lock-right.shadow .next-table-header tr th:last-child {
  border-right-width: 0;
}

.next-table-lock-right {
  right: 0;
  left: auto;
}

.next-table-lock-right table tr td:first-child,
.next-table-lock-right table tr th:first-child {
  border-left-width: 1px;
}

.next-table-lock-right.shadow {
  -webkit-box-shadow: -2px 0 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: -2px 0 3px rgba(0, 0, 0, 0.12);
  box-shadow: -2px 0 3px rgba(0, 0, 0, 0.12);
}

.next-table-lock-left.shadow {
  -webkit-box-shadow: 2px 0 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 2px 0 3px rgba(0, 0, 0, 0.12);
  box-shadow: 2px 0 3px rgba(0, 0, 0, 0.12);
}

.next-table-filter {
  line-height: 1;
}

.next-table-sort {
  cursor: pointer;
  position: relative;
  width: 16px;
  display: inline-block;
  line-height: 1;
}

.next-table-sort:focus {
  outline: 0;
}

.next-table-sort > a:before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
}

.next-table-sort .next-icon {
  position: absolute;
  left: -2px;
  color: #111;
  color: var(--table-sort-color, #111);
}

.next-table-sort .next-icon .next-icon-remote,
.next-table-sort .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-table-sort .current .next-icon {
  color: #0064c8;
  color: var(--table-sort-color-current, #0064c8);
}

.next-table-sort .next-icon-ascending {
  left: 2px;
}

.next-table-filter {
  cursor: pointer;
  width: 20px;
  display: inline-block;
}

.next-table-filter:focus {
  outline: 0;
}

.next-table-filter .next-icon {
  color: #111;
  color: var(--table-sort-color, #111);
}

.next-table-filter .next-icon .next-icon-remote,
.next-table-filter .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-table-filter .next-table-filter-active {
  color: #0064c8;
  color: var(--color-brand1-6, #0064c8);
}

.next-table-filter-menu .next-menu-content {
  max-height: 220px;
  overflow: auto;
}

.next-table-header-icon {
  margin-left: 8px;
}

.next-table-expanded-ctrl {
  cursor: pointer;
}

.next-table-expanded-ctrl:focus {
  outline: 0;
}

.next-table-expanded-ctrl.disabled {
  color: #888;
  color: var(--table-expanded-ctrl-disabled-color, #888);
}

.next-table-expanded-ctrl .next-table-expand-unfold .next-icon-remote,
.next-table-expanded-ctrl .next-table-expand-unfold:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-table-expanded-ctrl .next-table-expand-unfold:before {
  content: "\E61B";
  content: var(--table-expand-unfold-icon-content, "\E61B");
}

.next-table-expanded-ctrl .next-table-expand-fold .next-icon-remote,
.next-table-expanded-ctrl .next-table-expand-fold:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-table-expanded-ctrl .next-table-expand-fold:before {
  content: "\E620";
  content: var(--table-expand-fold-icon-content, "\E620");
}

.next-table-fix-left,
.next-table-fix-right {
  background: inherit;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

.next-table-ping-left
  .next-table-expanded-area
  .next-table-fix-left-last:after {
  content: none;
}

.next-table-ping-left
  .next-table-expanded-area
  .next-table-ping-left
  .next-table-fix-left-last,
.next-table-ping-left .next-table-fix-left-last {
  border-right-width: 0;
}

.next-table-ping-left
  .next-table-expanded-area
  .next-table-ping-left
  .next-table-fix-left-last:after,
.next-table-ping-left .next-table-fix-left-last:after {
  -webkit-box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  content: "";
  pointer-events: none;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.next-table-ping-right
  .next-table-expanded-area
  .next-table-fix-right-first:after {
  content: none;
}

.next-table-ping-right
  .next-table-expanded-area
  .next-table-ping-right
  .next-table-fix-right-first:after,
.next-table-ping-right .next-table-fix-right-first:after {
  left: 0;
}

.next-table-fixed.next-table-scrolling-to-right:after,
.next-table-lock.next-table-scrolling-to-right:after,
.next-table-ping-right
  .next-table-expanded-area
  .next-table-ping-right
  .next-table-fix-right-first:after,
.next-table-ping-right .next-table-fix-right-first:after {
  -webkit-box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  content: "";
  pointer-events: none;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.next-table-fixed.next-table-scrolling-to-right:after,
.next-table-lock.next-table-scrolling-to-right:after {
  right: -30px;
}

.next-table-fixed.next-table-scrolling-to-right .next-table-lock-right,
.next-table-lock.next-table-scrolling-to-right .next-table-lock-right {
  border-right: 1px solid #e3e4e6;
  border-right: var(--table-normal-border-width, 1px)
    var(--table-normal-border-style, solid)
    var(--table-normal-border-color, #e3e4e6);
}

.next-table-fixed.next-table-ping-right,
.next-table-fixed.only-bottom-border,
.next-table-lock.next-table-ping-right,
.next-table-lock.only-bottom-border {
  border-right: 0;
}

.next-table[dir="rtl"] th {
  text-align: right;
}

.next-table[dir="rtl"] .next-table-header-resizable .next-table-resize-handler {
  right: auto;
  left: 0;
}

.next-table[dir="rtl"]
  td
  .next-table-cell-wrapper
  .next-icon-arrow-down.next-table-tree-arrow,
.next-table[dir="rtl"]
  td
  .next-table-cell-wrapper
  .next-icon-arrow-right.next-table-tree-arrow,
.next-table[dir="rtl"]
  td
  .next-table-cell-wrapper
  .next-table-tree-placeholder {
  margin-left: 3px;
  margin-right: 0;
  float: right;
}

.next-table[dir="rtl"] .next-table-expanded-row td:first-child {
  border-left-width: 0;
  border-right-width: 1px;
}

.next-table[dir="rtl"] .next-table-expanded-row td:last-child {
  border-left-width: 1px;
  border-right-width: 0;
}

.next-table[dir="rtl"].only-bottom-border .next-table-expanded-row td,
.next-table[dir="rtl"].only-bottom-border .next-table-expanded-row th {
  border-width: 0 0 1px;
  border-bottom-width: var(--table-normal-border-width, 1px);
  border-right-width: 0;
  border-top-width: 0;
}

.next-table[dir="rtl"] .next-table-filter-footer button {
  margin-left: 5px;
  margin-right: 0;
}

.next-table[dir="rtl"] .next-table-lock-left,
.next-table[dir="rtl"] .next-table-lock-right {
  left: auto;
  right: 0;
}

.next-table[dir="rtl"] .next-table-lock-right {
  right: auto;
  left: 0;
}

.next-table[dir="rtl"] .next-table-lock-right table tr td:first-child,
.next-table[dir="rtl"] .next-table-lock-right table tr th:first-child {
  border-right-width: 1px;
}

.next-table[dir="rtl"] .next-table-lock-right.shadow {
  -webkit-box-shadow: 2px 0 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 2px 0 3px rgba(0, 0, 0, 0.12);
  box-shadow: 2px 0 3px rgba(0, 0, 0, 0.12);
}

.next-table[dir="rtl"] .next-table-lock-left.shadow {
  -webkit-box-shadow: -2px 0 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: -2px 0 3px rgba(0, 0, 0, 0.12);
  box-shadow: -2px 0 3px rgba(0, 0, 0, 0.12);
}

.next-table[dir="rtl"] .next-table-sort .next-icon {
  right: 0;
  left: auto;
}

.next-table[dir="rtl"] .next-table-sort .next-icon-ascending {
  right: 4px;
  left: auto;
}

.next-table[dir="rtl"] .next-table-filter {
  margin-right: 5px;
  margin-left: 0;
}

.next-table-fixed[dir="rtl"] table tr td:first-child,
.next-table-fixed[dir="rtl"] table tr th:first-child {
  border-left-width: 1px;
  border-left-width: var(--table-normal-border-width, 1px);
  border-right-width: 0;
}

.next-table-fixed[dir="rtl"] .next-table-body tr td:last-child,
.next-table-fixed[dir="rtl"] .next-table-header tr th:last-child {
  border-left-width: 1px;
  border-left-width: var(--table-normal-border-width, 1px);
}

.next-timeline,
.next-timeline *,
.next-timeline:after,
.next-timeline :after,
.next-timeline:before,
.next-timeline :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-timeline ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.next-timeline p {
  margin: 0;
}

.next-timeline-hide {
  display: none;
}

.next-timeline[dir="rtl"] .next-timeline-item-folder {
  padding-left: 0;
  padding-right: 24px;
  padding-right: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
}

.next-timeline[dir="rtl"] .next-timeline-item-dot-tail {
  left: auto;
  right: 4px;
  right: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  right: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  right: calc(var(--timeline-item-node-size, 8px) / 2);
  border-left: none;
  border-right: 1px dotted #c0c6cc;
  border-right: var(--timeline-item-tail-size, 1px) dotted
    var(--timeline-item-tail-color, #c0c6cc);
}

.next-timeline[dir="rtl"]
  .next-timeline-item-has-left-content.next-timeline-item-folder {
  margin-left: 0;
  margin-right: 80px;
  margin-right: var(--timeline-item-left-content-width, 80px);
}

.next-timeline[dir="rtl"] .next-timeline-item-done {
  position: relative;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-timeline {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  height: 100%;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-node {
  position: relative;
  width: 8px;
  width: var(--timeline-item-node-size, 8px);
  height: 24px;
  height: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  padding: 8px 0;
  padding: var(--timeline-item-node-padding, 8px) 0;
  text-align: center;
  float: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-node.next-timeline-item-node-custom {
  width: 40px;
  width: var(--timeline-item-custom-node-size, 40px);
  height: auto;
  font-size: 12px;
  font-size: var(--timeline-item-custom-node-font-size, 12px);
  word-break: break-all;
  margin-right: -16px;
  margin-right: calc(
    0px - var(--timeline-item-custom-node-size, 40px) / 2 +
      var(--timeline-item-node-size, 8px) / 2
  );
  margin-left: 0;
  line-height: 1;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-dot {
  display: block;
  position: absolute;
  width: 8px;
  width: var(--timeline-item-done-dot-size, 8px);
  height: 8px;
  height: var(--timeline-item-done-dot-size, 8px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--timeline-item-done-dot-size, 8px) / 2);
  left: 50%;
  margin-left: -4px;
  margin-left: calc(0px - var(--timeline-item-done-dot-size, 8px) / 2);
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon
  .next-icon-remote,
.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-tail {
  position: absolute;
  width: auto;
  height: -webkit-calc(100% - 24px);
  height: -moz-calc(100% - 24px);
  height: calc(100% - 24px);
  height: -webkit-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: -moz-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  top: 24px;
  top: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  left: auto;
  right: 4px;
  right: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  right: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  right: calc(var(--timeline-item-node-size, 8px) / 2);
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-tail
  i {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 1px;
  width: var(--timeline-item-tail-size, 1px);
  position: relative;
  background: #c0c6cc;
  background: var(--timeline-item-tail-color, #c0c6cc);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-timeline[dir="rtl"] .next-timeline-item-done .next-timeline-item-content {
  display: inline-block;
  margin-right: 24px;
  margin-right: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
  margin-left: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-content
  .next-timeline-item-title {
  font-size: 12px;
  font-size: var(--timeline-item-title-font-size, 12px);
  font-weight: 600;
  font-weight: var(--timeline-item-title-font-weight, 600);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-title-font-size, 12px) + 4px);
  margin: 4px 0 0;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  color: var(--timeline-item-title-color, #333);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-content
  .next-timeline-item-body {
  margin: 8px 0 0;
  margin-top: var(--timeline-item-body-margin-top, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-body-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-body-font-size, 12px) + 4px);
  color: #333;
  color: var(--timeline-item-body-color, #333);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done
  .next-timeline-item-content
  .next-timeline-item-time {
  margin: 4px 0 0;
  margin-top: var(--timeline-item-time-margin-top, 4px);
  margin-bottom: 8px;
  margin-bottom: var(--timeline-item-time-margin-bottom, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-time-font-size, 12px);
  color: #666;
  color: var(--timeline-item-time-color, #666);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done.next-timeline-item-has-left-content
  > .next-timeline-item-left-content {
  position: absolute;
  width: 80px;
  width: var(--timeline-item-left-content-width, 80px);
  display: inline-block;
  font-size: 12px;
  font-size: var(--timeline-item-left-body-font-size, 12px);
  color: #888;
  color: var(--timeline-item-left-body-color, #888);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--timeline-item-left-body-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  margin-top: 4px;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  text-align: left;
  padding-left: 16px;
  padding-left: var(--timeline-item-content-margin-left, 16px);
  padding-right: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done.next-timeline-item-has-left-content
  > .next-timeline-item-left-content
  p {
  word-break: break-word;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done.next-timeline-item-has-left-content
  > .next-timeline-item-timeline {
  margin-right: 80px;
  margin-right: var(--timeline-item-left-content-width, 80px);
  margin-left: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-done.next-timeline-item-has-left-content
  > .next-timeline-item-content {
  margin-right: 104px;
  margin-right: calc(
    var(--timeline-item-left-content-width, 80px) +
      var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
  margin-left: 0;
}

.next-timeline[dir="rtl"] .next-timeline-item-done .next-timeline-item-dot,
.next-timeline[dir="rtl"] .next-timeline-item-done .next-timeline-item-icon {
  background: #c0c6cc;
  background: var(--timeline-item-done-background, #c0c6cc);
}

.next-timeline[dir="rtl"] .next-timeline-item-done .next-timeline-item-icon {
  color: #fff;
  color: var(--timeline-item-done-color, #fff);
}

.next-timeline[dir="rtl"] .next-timeline-item-process {
  position: relative;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-timeline {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  height: 100%;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-node {
  position: relative;
  width: 8px;
  width: var(--timeline-item-node-size, 8px);
  height: 24px;
  height: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  padding: 8px 0;
  padding: var(--timeline-item-node-padding, 8px) 0;
  text-align: center;
  float: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-node.next-timeline-item-node-custom {
  width: 40px;
  width: var(--timeline-item-custom-node-size, 40px);
  height: auto;
  font-size: 12px;
  font-size: var(--timeline-item-custom-node-font-size, 12px);
  word-break: break-all;
  margin-right: -16px;
  margin-right: calc(
    0px - var(--timeline-item-custom-node-size, 40px) / 2 +
      var(--timeline-item-node-size, 8px) / 2
  );
  margin-left: 0;
  line-height: 1;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-dot {
  display: block;
  position: absolute;
  width: 8px;
  width: var(--timeline-item-process-dot-size, 8px);
  height: 8px;
  height: var(--timeline-item-process-dot-size, 8px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--timeline-item-process-dot-size, 8px) / 2);
  left: 50%;
  margin-left: -4px;
  margin-left: calc(0px - var(--timeline-item-process-dot-size, 8px) / 2);
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon
  .next-icon-remote,
.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-tail {
  position: absolute;
  width: auto;
  height: -webkit-calc(100% - 24px);
  height: -moz-calc(100% - 24px);
  height: calc(100% - 24px);
  height: -webkit-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: -moz-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  top: 24px;
  top: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  left: auto;
  right: 4px;
  right: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  right: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  right: calc(var(--timeline-item-node-size, 8px) / 2);
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-tail
  i {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 1px;
  width: var(--timeline-item-tail-size, 1px);
  position: relative;
  background: #c0c6cc;
  background: var(--timeline-item-tail-color, #c0c6cc);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-content {
  display: inline-block;
  margin-right: 24px;
  margin-right: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
  margin-left: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-content
  .next-timeline-item-title {
  font-size: 12px;
  font-size: var(--timeline-item-title-font-size, 12px);
  font-weight: 600;
  font-weight: var(--timeline-item-title-font-weight, 600);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-title-font-size, 12px) + 4px);
  margin: 4px 0 0;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  color: var(--timeline-item-title-color, #333);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-content
  .next-timeline-item-body {
  margin: 8px 0 0;
  margin-top: var(--timeline-item-body-margin-top, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-body-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-body-font-size, 12px) + 4px);
  color: #333;
  color: var(--timeline-item-body-color, #333);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process
  .next-timeline-item-content
  .next-timeline-item-time {
  margin: 4px 0 0;
  margin-top: var(--timeline-item-time-margin-top, 4px);
  margin-bottom: 8px;
  margin-bottom: var(--timeline-item-time-margin-bottom, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-time-font-size, 12px);
  color: #666;
  color: var(--timeline-item-time-color, #666);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process.next-timeline-item-has-left-content
  > .next-timeline-item-left-content {
  position: absolute;
  width: 80px;
  width: var(--timeline-item-left-content-width, 80px);
  display: inline-block;
  font-size: 12px;
  font-size: var(--timeline-item-left-body-font-size, 12px);
  color: #888;
  color: var(--timeline-item-left-body-color, #888);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--timeline-item-left-body-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  margin-top: 4px;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  text-align: left;
  padding-left: 16px;
  padding-left: var(--timeline-item-content-margin-left, 16px);
  padding-right: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process.next-timeline-item-has-left-content
  > .next-timeline-item-left-content
  p {
  word-break: break-word;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process.next-timeline-item-has-left-content
  > .next-timeline-item-timeline {
  margin-right: 80px;
  margin-right: var(--timeline-item-left-content-width, 80px);
  margin-left: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-process.next-timeline-item-has-left-content
  > .next-timeline-item-content {
  margin-right: 104px;
  margin-right: calc(
    var(--timeline-item-left-content-width, 80px) +
      var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
  margin-left: 0;
}

.next-timeline[dir="rtl"] .next-timeline-item-process .next-timeline-item-dot,
.next-timeline[dir="rtl"] .next-timeline-item-process .next-timeline-item-icon {
  background: #0064c8;
  background: var(--timeline-item-process-background, #0064c8);
}

.next-timeline[dir="rtl"] .next-timeline-item-process .next-timeline-item-icon {
  color: #fff;
  color: var(--timeline-item-process-color, #fff);
}

.next-timeline[dir="rtl"] .next-timeline-item-success {
  position: relative;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-timeline {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  height: 100%;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-node {
  position: relative;
  width: 8px;
  width: var(--timeline-item-node-size, 8px);
  height: 24px;
  height: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  padding: 8px 0;
  padding: var(--timeline-item-node-padding, 8px) 0;
  text-align: center;
  float: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-node.next-timeline-item-node-custom {
  width: 40px;
  width: var(--timeline-item-custom-node-size, 40px);
  height: auto;
  font-size: 12px;
  font-size: var(--timeline-item-custom-node-font-size, 12px);
  word-break: break-all;
  margin-right: -16px;
  margin-right: calc(
    0px - var(--timeline-item-custom-node-size, 40px) / 2 +
      var(--timeline-item-node-size, 8px) / 2
  );
  margin-left: 0;
  line-height: 1;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-dot {
  display: block;
  position: absolute;
  width: 8px;
  width: var(--timeline-item-success-dot-size, 8px);
  height: 8px;
  height: var(--timeline-item-success-dot-size, 8px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--timeline-item-success-dot-size, 8px) / 2);
  left: 50%;
  margin-left: -4px;
  margin-left: calc(0px - var(--timeline-item-success-dot-size, 8px) / 2);
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon
  .next-icon-remote,
.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-tail {
  position: absolute;
  width: auto;
  height: -webkit-calc(100% - 24px);
  height: -moz-calc(100% - 24px);
  height: calc(100% - 24px);
  height: -webkit-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: -moz-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  top: 24px;
  top: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  left: auto;
  right: 4px;
  right: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  right: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  right: calc(var(--timeline-item-node-size, 8px) / 2);
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-tail
  i {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 1px;
  width: var(--timeline-item-tail-size, 1px);
  position: relative;
  background: #c0c6cc;
  background: var(--timeline-item-tail-color, #c0c6cc);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-content {
  display: inline-block;
  margin-right: 24px;
  margin-right: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
  margin-left: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-content
  .next-timeline-item-title {
  font-size: 12px;
  font-size: var(--timeline-item-title-font-size, 12px);
  font-weight: 600;
  font-weight: var(--timeline-item-title-font-weight, 600);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-title-font-size, 12px) + 4px);
  margin: 4px 0 0;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  color: var(--timeline-item-title-color, #333);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-content
  .next-timeline-item-body {
  margin: 8px 0 0;
  margin-top: var(--timeline-item-body-margin-top, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-body-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-body-font-size, 12px) + 4px);
  color: #333;
  color: var(--timeline-item-body-color, #333);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success
  .next-timeline-item-content
  .next-timeline-item-time {
  margin: 4px 0 0;
  margin-top: var(--timeline-item-time-margin-top, 4px);
  margin-bottom: 8px;
  margin-bottom: var(--timeline-item-time-margin-bottom, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-time-font-size, 12px);
  color: #666;
  color: var(--timeline-item-time-color, #666);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success.next-timeline-item-has-left-content
  > .next-timeline-item-left-content {
  position: absolute;
  width: 80px;
  width: var(--timeline-item-left-content-width, 80px);
  display: inline-block;
  font-size: 12px;
  font-size: var(--timeline-item-left-body-font-size, 12px);
  color: #888;
  color: var(--timeline-item-left-body-color, #888);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--timeline-item-left-body-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  margin-top: 4px;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  text-align: left;
  padding-left: 16px;
  padding-left: var(--timeline-item-content-margin-left, 16px);
  padding-right: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success.next-timeline-item-has-left-content
  > .next-timeline-item-left-content
  p {
  word-break: break-word;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success.next-timeline-item-has-left-content
  > .next-timeline-item-timeline {
  margin-right: 80px;
  margin-right: var(--timeline-item-left-content-width, 80px);
  margin-left: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-success.next-timeline-item-has-left-content
  > .next-timeline-item-content {
  margin-right: 104px;
  margin-right: calc(
    var(--timeline-item-left-content-width, 80px) +
      var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
  margin-left: 0;
}

.next-timeline[dir="rtl"] .next-timeline-item-success .next-timeline-item-dot,
.next-timeline[dir="rtl"] .next-timeline-item-success .next-timeline-item-icon {
  background: #00a700;
  background: var(--timeline-item-success-background, #00a700);
}

.next-timeline[dir="rtl"] .next-timeline-item-success .next-timeline-item-icon {
  color: #fff;
  color: var(--timeline-item-success-color, #fff);
}

.next-timeline[dir="rtl"] .next-timeline-item-error {
  position: relative;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-timeline {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  height: 100%;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-node {
  position: relative;
  width: 8px;
  width: var(--timeline-item-node-size, 8px);
  height: 24px;
  height: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  padding: 8px 0;
  padding: var(--timeline-item-node-padding, 8px) 0;
  text-align: center;
  float: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-node.next-timeline-item-node-custom {
  width: 40px;
  width: var(--timeline-item-custom-node-size, 40px);
  height: auto;
  font-size: 12px;
  font-size: var(--timeline-item-custom-node-font-size, 12px);
  word-break: break-all;
  margin-right: -16px;
  margin-right: calc(
    0px - var(--timeline-item-custom-node-size, 40px) / 2 +
      var(--timeline-item-node-size, 8px) / 2
  );
  margin-left: 0;
  line-height: 1;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-dot {
  display: block;
  position: absolute;
  width: 8px;
  width: var(--timeline-item-error-dot-size, 8px);
  height: 8px;
  height: var(--timeline-item-error-dot-size, 8px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--timeline-item-error-dot-size, 8px) / 2);
  left: 50%;
  margin-left: -4px;
  margin-left: calc(0px - var(--timeline-item-error-dot-size, 8px) / 2);
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon
  .next-icon-remote,
.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-tail {
  position: absolute;
  width: auto;
  height: -webkit-calc(100% - 24px);
  height: -moz-calc(100% - 24px);
  height: calc(100% - 24px);
  height: -webkit-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: -moz-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  top: 24px;
  top: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  left: auto;
  right: 4px;
  right: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  right: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  right: calc(var(--timeline-item-node-size, 8px) / 2);
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-tail
  i {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 1px;
  width: var(--timeline-item-tail-size, 1px);
  position: relative;
  background: #c0c6cc;
  background: var(--timeline-item-tail-color, #c0c6cc);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-content {
  display: inline-block;
  margin-right: 24px;
  margin-right: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
  margin-left: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-content
  .next-timeline-item-title {
  font-size: 12px;
  font-size: var(--timeline-item-title-font-size, 12px);
  font-weight: 600;
  font-weight: var(--timeline-item-title-font-weight, 600);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-title-font-size, 12px) + 4px);
  margin: 4px 0 0;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  color: var(--timeline-item-title-color, #333);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-content
  .next-timeline-item-body {
  margin: 8px 0 0;
  margin-top: var(--timeline-item-body-margin-top, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-body-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-body-font-size, 12px) + 4px);
  color: #333;
  color: var(--timeline-item-body-color, #333);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error
  .next-timeline-item-content
  .next-timeline-item-time {
  margin: 4px 0 0;
  margin-top: var(--timeline-item-time-margin-top, 4px);
  margin-bottom: 8px;
  margin-bottom: var(--timeline-item-time-margin-bottom, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-time-font-size, 12px);
  color: #666;
  color: var(--timeline-item-time-color, #666);
  text-align: right;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error.next-timeline-item-has-left-content
  > .next-timeline-item-left-content {
  position: absolute;
  width: 80px;
  width: var(--timeline-item-left-content-width, 80px);
  display: inline-block;
  font-size: 12px;
  font-size: var(--timeline-item-left-body-font-size, 12px);
  color: #888;
  color: var(--timeline-item-left-body-color, #888);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--timeline-item-left-body-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  margin-top: 4px;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  text-align: left;
  padding-left: 16px;
  padding-left: var(--timeline-item-content-margin-left, 16px);
  padding-right: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error.next-timeline-item-has-left-content
  > .next-timeline-item-left-content
  p {
  word-break: break-word;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error.next-timeline-item-has-left-content
  > .next-timeline-item-timeline {
  margin-right: 80px;
  margin-right: var(--timeline-item-left-content-width, 80px);
  margin-left: 0;
}

.next-timeline[dir="rtl"]
  .next-timeline-item-error.next-timeline-item-has-left-content
  > .next-timeline-item-content {
  margin-right: 104px;
  margin-right: calc(
    var(--timeline-item-left-content-width, 80px) +
      var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
  margin-left: 0;
}

.next-timeline[dir="rtl"] .next-timeline-item-error .next-timeline-item-dot,
.next-timeline[dir="rtl"] .next-timeline-item-error .next-timeline-item-icon {
  background: #c80000;
  background: var(--timeline-item-error-background, #c80000);
}

.next-timeline[dir="rtl"] .next-timeline-item-error .next-timeline-item-icon {
  color: #fff;
  color: var(--timeline-item-error-color, #fff);
}

.next-timeline {
  margin: 0;
  padding: 0;
  list-style: none;
}

.next-timeline > li {
  outline: 0;
}

.next-timeline-item-folder {
  padding-left: 24px;
  padding-left: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
  padding-top: 4px;
  padding-top: var(--timeline-item-folder-margin-top, 4px);
  padding-bottom: 4px;
  padding-bottom: var(--timeline-item-folder-margin-bottom, 4px);
  font-size: 12px;
  font-size: var(--timeline-item-folder-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-folder-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-folder-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-folder-font-size, 12px) + 4px);
  position: relative;
}

.next-timeline-item-dot-tail {
  position: absolute;
  top: 0;
  left: 4px;
  left: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  left: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  left: calc(var(--timeline-item-node-size, 8px) / 2);
  height: 100%;
  border: 0;
  border-left: var(--timeline-item-tail-size, 1px) dotted
    var(--timeline-item-tail-color, #c0c6cc);
}

.next-timeline-item-dot-tail-solid {
  border-style: solid;
}

.next-timeline-item-has-left-content.next-timeline-item-folder {
  margin-left: 80px;
  margin-left: var(--timeline-item-left-content-width, 80px);
}

.next-timeline-item-done {
  position: relative;
}

.next-timeline-item-done .next-timeline-item-timeline {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.next-timeline-item-done .next-timeline-item-timeline .next-timeline-item-node {
  position: relative;
  width: 8px;
  width: var(--timeline-item-node-size, 8px);
  height: 24px;
  height: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  padding: 8px 0;
  padding: var(--timeline-item-node-padding, 8px) 0;
  text-align: center;
  float: left;
}

.next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-node.next-timeline-item-node-custom {
  width: 40px;
  width: var(--timeline-item-custom-node-size, 40px);
  height: auto;
  font-size: 12px;
  font-size: var(--timeline-item-custom-node-font-size, 12px);
  word-break: break-all;
  margin-left: -16px;
  margin-left: calc(
    0px - var(--timeline-item-custom-node-size, 40px) / 2 +
      var(--timeline-item-node-size, 8px) / 2
  );
  line-height: 1;
}

.next-timeline-item-done .next-timeline-item-timeline .next-timeline-item-dot {
  display: block;
  position: absolute;
  width: 8px;
  width: var(--timeline-item-done-dot-size, 8px);
  height: 8px;
  height: var(--timeline-item-done-dot-size, 8px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--timeline-item-done-dot-size, 8px) / 2);
  left: 50%;
  margin-left: -4px;
  margin-left: calc(0px - var(--timeline-item-done-dot-size, 8px) / 2);
}

.next-timeline-item-done .next-timeline-item-timeline .next-timeline-item-icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon
  .next-icon-remote,
.next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-timeline-item-done .next-timeline-item-timeline .next-timeline-item-tail {
  position: absolute;
  width: auto;
  height: -webkit-calc(100% - 24px);
  height: -moz-calc(100% - 24px);
  height: calc(100% - 24px);
  height: -webkit-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: -moz-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  top: 24px;
  top: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  left: 4px;
  left: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  left: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  left: calc(var(--timeline-item-node-size, 8px) / 2);
}

.next-timeline-item-done
  .next-timeline-item-timeline
  .next-timeline-item-tail
  i {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 1px;
  width: var(--timeline-item-tail-size, 1px);
  position: relative;
  background: #c0c6cc;
  background: var(--timeline-item-tail-color, #c0c6cc);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-timeline-item-done .next-timeline-item-content {
  display: inline-block;
  margin-left: 24px;
  margin-left: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
}

.next-timeline-item-done .next-timeline-item-content .next-timeline-item-title {
  font-size: 12px;
  font-size: var(--timeline-item-title-font-size, 12px);
  font-weight: 600;
  font-weight: var(--timeline-item-title-font-weight, 600);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-title-font-size, 12px) + 4px);
  margin: 4px 0 0;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  color: var(--timeline-item-title-color, #333);
  text-align: left;
}

.next-timeline-item-done .next-timeline-item-content .next-timeline-item-body {
  margin: 8px 0 0;
  margin-top: var(--timeline-item-body-margin-top, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-body-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-body-font-size, 12px) + 4px);
  color: #333;
  color: var(--timeline-item-body-color, #333);
  text-align: left;
}

.next-timeline-item-done .next-timeline-item-content .next-timeline-item-time {
  margin: 4px 0 0;
  margin-top: var(--timeline-item-time-margin-top, 4px);
  margin-bottom: 8px;
  margin-bottom: var(--timeline-item-time-margin-bottom, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-time-font-size, 12px);
  color: #666;
  color: var(--timeline-item-time-color, #666);
  text-align: left;
}

.next-timeline-item-done.next-timeline-item-has-left-content
  > .next-timeline-item-left-content {
  position: absolute;
  width: 80px;
  width: var(--timeline-item-left-content-width, 80px);
  display: inline-block;
  font-size: 12px;
  font-size: var(--timeline-item-left-body-font-size, 12px);
  color: #888;
  color: var(--timeline-item-left-body-color, #888);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--timeline-item-left-body-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  margin-top: 4px;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  text-align: right;
  padding-right: 16px;
  padding-right: var(--timeline-item-content-margin-left, 16px);
}

.next-timeline-item-done.next-timeline-item-has-left-content
  > .next-timeline-item-left-content
  p {
  word-break: break-word;
}

.next-timeline-item-done.next-timeline-item-has-left-content
  > .next-timeline-item-timeline {
  margin-left: 80px;
  margin-left: var(--timeline-item-left-content-width, 80px);
}

.next-timeline-item-done.next-timeline-item-has-left-content
  > .next-timeline-item-content {
  margin-left: 104px;
  margin-left: calc(
    var(--timeline-item-left-content-width, 80px) +
      var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
}

.next-timeline-item-done .next-timeline-item-dot,
.next-timeline-item-done .next-timeline-item-icon {
  background: #c0c6cc;
  background: var(--timeline-item-done-background, #c0c6cc);
}

.next-timeline-item-done .next-timeline-item-icon {
  color: #fff;
  color: var(--timeline-item-done-color, #fff);
}

.next-timeline-item-process {
  position: relative;
}

.next-timeline-item-process .next-timeline-item-timeline {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-node {
  position: relative;
  width: 8px;
  width: var(--timeline-item-node-size, 8px);
  height: 24px;
  height: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  padding: 8px 0;
  padding: var(--timeline-item-node-padding, 8px) 0;
  text-align: center;
  float: left;
}

.next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-node.next-timeline-item-node-custom {
  width: 40px;
  width: var(--timeline-item-custom-node-size, 40px);
  height: auto;
  font-size: 12px;
  font-size: var(--timeline-item-custom-node-font-size, 12px);
  word-break: break-all;
  margin-left: -16px;
  margin-left: calc(
    0px - var(--timeline-item-custom-node-size, 40px) / 2 +
      var(--timeline-item-node-size, 8px) / 2
  );
  line-height: 1;
}

.next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-dot {
  display: block;
  position: absolute;
  width: 8px;
  width: var(--timeline-item-process-dot-size, 8px);
  height: 8px;
  height: var(--timeline-item-process-dot-size, 8px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--timeline-item-process-dot-size, 8px) / 2);
  left: 50%;
  margin-left: -4px;
  margin-left: calc(0px - var(--timeline-item-process-dot-size, 8px) / 2);
}

.next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon
  .next-icon-remote,
.next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-tail {
  position: absolute;
  width: auto;
  height: -webkit-calc(100% - 24px);
  height: -moz-calc(100% - 24px);
  height: calc(100% - 24px);
  height: -webkit-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: -moz-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  top: 24px;
  top: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  left: 4px;
  left: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  left: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  left: calc(var(--timeline-item-node-size, 8px) / 2);
}

.next-timeline-item-process
  .next-timeline-item-timeline
  .next-timeline-item-tail
  i {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 1px;
  width: var(--timeline-item-tail-size, 1px);
  position: relative;
  background: #c0c6cc;
  background: var(--timeline-item-tail-color, #c0c6cc);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-timeline-item-process .next-timeline-item-content {
  display: inline-block;
  margin-left: 24px;
  margin-left: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
}

.next-timeline-item-process
  .next-timeline-item-content
  .next-timeline-item-title {
  font-size: 12px;
  font-size: var(--timeline-item-title-font-size, 12px);
  font-weight: 600;
  font-weight: var(--timeline-item-title-font-weight, 600);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-title-font-size, 12px) + 4px);
  margin: 4px 0 0;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  color: var(--timeline-item-title-color, #333);
  text-align: left;
}

.next-timeline-item-process
  .next-timeline-item-content
  .next-timeline-item-body {
  margin: 8px 0 0;
  margin-top: var(--timeline-item-body-margin-top, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-body-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-body-font-size, 12px) + 4px);
  color: #333;
  color: var(--timeline-item-body-color, #333);
  text-align: left;
}

.next-timeline-item-process
  .next-timeline-item-content
  .next-timeline-item-time {
  margin: 4px 0 0;
  margin-top: var(--timeline-item-time-margin-top, 4px);
  margin-bottom: 8px;
  margin-bottom: var(--timeline-item-time-margin-bottom, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-time-font-size, 12px);
  color: #666;
  color: var(--timeline-item-time-color, #666);
  text-align: left;
}

.next-timeline-item-process.next-timeline-item-has-left-content
  > .next-timeline-item-left-content {
  position: absolute;
  width: 80px;
  width: var(--timeline-item-left-content-width, 80px);
  display: inline-block;
  font-size: 12px;
  font-size: var(--timeline-item-left-body-font-size, 12px);
  color: #888;
  color: var(--timeline-item-left-body-color, #888);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--timeline-item-left-body-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  margin-top: 4px;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  text-align: right;
  padding-right: 16px;
  padding-right: var(--timeline-item-content-margin-left, 16px);
}

.next-timeline-item-process.next-timeline-item-has-left-content
  > .next-timeline-item-left-content
  p {
  word-break: break-word;
}

.next-timeline-item-process.next-timeline-item-has-left-content
  > .next-timeline-item-timeline {
  margin-left: 80px;
  margin-left: var(--timeline-item-left-content-width, 80px);
}

.next-timeline-item-process.next-timeline-item-has-left-content
  > .next-timeline-item-content {
  margin-left: 104px;
  margin-left: calc(
    var(--timeline-item-left-content-width, 80px) +
      var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
}

.next-timeline-item-process .next-timeline-item-dot,
.next-timeline-item-process .next-timeline-item-icon {
  background: #0064c8;
  background: var(--timeline-item-process-background, #0064c8);
}

.next-timeline-item-process .next-timeline-item-icon {
  color: #fff;
  color: var(--timeline-item-process-color, #fff);
}

.next-timeline-item-success {
  position: relative;
}

.next-timeline-item-success .next-timeline-item-timeline {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-node {
  position: relative;
  width: 8px;
  width: var(--timeline-item-node-size, 8px);
  height: 24px;
  height: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  padding: 8px 0;
  padding: var(--timeline-item-node-padding, 8px) 0;
  text-align: center;
  float: left;
}

.next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-node.next-timeline-item-node-custom {
  width: 40px;
  width: var(--timeline-item-custom-node-size, 40px);
  height: auto;
  font-size: 12px;
  font-size: var(--timeline-item-custom-node-font-size, 12px);
  word-break: break-all;
  margin-left: -16px;
  margin-left: calc(
    0px - var(--timeline-item-custom-node-size, 40px) / 2 +
      var(--timeline-item-node-size, 8px) / 2
  );
  line-height: 1;
}

.next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-dot {
  display: block;
  position: absolute;
  width: 8px;
  width: var(--timeline-item-success-dot-size, 8px);
  height: 8px;
  height: var(--timeline-item-success-dot-size, 8px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--timeline-item-success-dot-size, 8px) / 2);
  left: 50%;
  margin-left: -4px;
  margin-left: calc(0px - var(--timeline-item-success-dot-size, 8px) / 2);
}

.next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon
  .next-icon-remote,
.next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-tail {
  position: absolute;
  width: auto;
  height: -webkit-calc(100% - 24px);
  height: -moz-calc(100% - 24px);
  height: calc(100% - 24px);
  height: -webkit-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: -moz-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  top: 24px;
  top: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  left: 4px;
  left: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  left: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  left: calc(var(--timeline-item-node-size, 8px) / 2);
}

.next-timeline-item-success
  .next-timeline-item-timeline
  .next-timeline-item-tail
  i {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 1px;
  width: var(--timeline-item-tail-size, 1px);
  position: relative;
  background: #c0c6cc;
  background: var(--timeline-item-tail-color, #c0c6cc);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-timeline-item-success .next-timeline-item-content {
  display: inline-block;
  margin-left: 24px;
  margin-left: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
}

.next-timeline-item-success
  .next-timeline-item-content
  .next-timeline-item-title {
  font-size: 12px;
  font-size: var(--timeline-item-title-font-size, 12px);
  font-weight: 600;
  font-weight: var(--timeline-item-title-font-weight, 600);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-title-font-size, 12px) + 4px);
  margin: 4px 0 0;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  color: var(--timeline-item-title-color, #333);
  text-align: left;
}

.next-timeline-item-success
  .next-timeline-item-content
  .next-timeline-item-body {
  margin: 8px 0 0;
  margin-top: var(--timeline-item-body-margin-top, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-body-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-body-font-size, 12px) + 4px);
  color: #333;
  color: var(--timeline-item-body-color, #333);
  text-align: left;
}

.next-timeline-item-success
  .next-timeline-item-content
  .next-timeline-item-time {
  margin: 4px 0 0;
  margin-top: var(--timeline-item-time-margin-top, 4px);
  margin-bottom: 8px;
  margin-bottom: var(--timeline-item-time-margin-bottom, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-time-font-size, 12px);
  color: #666;
  color: var(--timeline-item-time-color, #666);
  text-align: left;
}

.next-timeline-item-success.next-timeline-item-has-left-content
  > .next-timeline-item-left-content {
  position: absolute;
  width: 80px;
  width: var(--timeline-item-left-content-width, 80px);
  display: inline-block;
  font-size: 12px;
  font-size: var(--timeline-item-left-body-font-size, 12px);
  color: #888;
  color: var(--timeline-item-left-body-color, #888);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--timeline-item-left-body-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  margin-top: 4px;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  text-align: right;
  padding-right: 16px;
  padding-right: var(--timeline-item-content-margin-left, 16px);
}

.next-timeline-item-success.next-timeline-item-has-left-content
  > .next-timeline-item-left-content
  p {
  word-break: break-word;
}

.next-timeline-item-success.next-timeline-item-has-left-content
  > .next-timeline-item-timeline {
  margin-left: 80px;
  margin-left: var(--timeline-item-left-content-width, 80px);
}

.next-timeline-item-success.next-timeline-item-has-left-content
  > .next-timeline-item-content {
  margin-left: 104px;
  margin-left: calc(
    var(--timeline-item-left-content-width, 80px) +
      var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
}

.next-timeline-item-success .next-timeline-item-dot,
.next-timeline-item-success .next-timeline-item-icon {
  background: #00a700;
  background: var(--timeline-item-success-background, #00a700);
}

.next-timeline-item-success .next-timeline-item-icon {
  color: #fff;
  color: var(--timeline-item-success-color, #fff);
}

.next-timeline-item-error {
  position: relative;
}

.next-timeline-item-error .next-timeline-item-timeline {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-node {
  position: relative;
  width: 8px;
  width: var(--timeline-item-node-size, 8px);
  height: 24px;
  height: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  height: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  padding: 8px 0;
  padding: var(--timeline-item-node-padding, 8px) 0;
  text-align: center;
  float: left;
}

.next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-node.next-timeline-item-node-custom {
  width: 40px;
  width: var(--timeline-item-custom-node-size, 40px);
  height: auto;
  font-size: 12px;
  font-size: var(--timeline-item-custom-node-font-size, 12px);
  word-break: break-all;
  margin-left: -16px;
  margin-left: calc(
    0px - var(--timeline-item-custom-node-size, 40px) / 2 +
      var(--timeline-item-node-size, 8px) / 2
  );
  line-height: 1;
}

.next-timeline-item-error .next-timeline-item-timeline .next-timeline-item-dot {
  display: block;
  position: absolute;
  width: 8px;
  width: var(--timeline-item-error-dot-size, 8px);
  height: 8px;
  height: var(--timeline-item-error-dot-size, 8px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  margin-top: -4px;
  margin-top: calc(0px - var(--timeline-item-error-dot-size, 8px) / 2);
  left: 50%;
  margin-left: -4px;
  margin-left: calc(0px - var(--timeline-item-error-dot-size, 8px) / 2);
}

.next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-icon {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon
  .next-icon-remote,
.next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-icon
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-tail {
  position: absolute;
  width: auto;
  height: -webkit-calc(100% - 24px);
  height: -moz-calc(100% - 24px);
  height: calc(100% - 24px);
  height: -webkit-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: -moz-calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  height: calc(
    100% - var(--timeline-item-node-size, 8px) -
      var(--timeline-item-node-padding, 8px) * 2
  );
  top: 24px;
  top: -webkit-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: -moz-calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  top: calc(
    var(--timeline-item-node-size, 8px) + var(--timeline-item-node-padding, 8px) *
      2
  );
  left: 4px;
  left: -webkit-calc(var(--timeline-item-node-size, 8px) / 2);
  left: -moz-calc(var(--timeline-item-node-size, 8px) / 2);
  left: calc(var(--timeline-item-node-size, 8px) / 2);
}

.next-timeline-item-error
  .next-timeline-item-timeline
  .next-timeline-item-tail
  i {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  width: 1px;
  width: var(--timeline-item-tail-size, 1px);
  position: relative;
  background: #c0c6cc;
  background: var(--timeline-item-tail-color, #c0c6cc);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-timeline-item-error .next-timeline-item-content {
  display: inline-block;
  margin-left: 24px;
  margin-left: calc(
    var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
}

.next-timeline-item-error
  .next-timeline-item-content
  .next-timeline-item-title {
  font-size: 12px;
  font-size: var(--timeline-item-title-font-size, 12px);
  font-weight: 600;
  font-weight: var(--timeline-item-title-font-weight, 600);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-title-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-title-font-size, 12px) + 4px);
  margin: 4px 0 0;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  color: var(--timeline-item-title-color, #333);
  text-align: left;
}

.next-timeline-item-error .next-timeline-item-content .next-timeline-item-body {
  margin: 8px 0 0;
  margin-top: var(--timeline-item-body-margin-top, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-body-font-size, 12px);
  line-height: 16px;
  line-height: -webkit-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: -moz-calc(var(--timeline-item-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-body-font-size, 12px) + 4px);
  color: #333;
  color: var(--timeline-item-body-color, #333);
  text-align: left;
}

.next-timeline-item-error .next-timeline-item-content .next-timeline-item-time {
  margin: 4px 0 0;
  margin-top: var(--timeline-item-time-margin-top, 4px);
  margin-bottom: 8px;
  margin-bottom: var(--timeline-item-time-margin-bottom, 8px);
  font-size: 12px;
  font-size: var(--timeline-item-time-font-size, 12px);
  color: #666;
  color: var(--timeline-item-time-color, #666);
  text-align: left;
}

.next-timeline-item-error.next-timeline-item-has-left-content
  > .next-timeline-item-left-content {
  position: absolute;
  width: 80px;
  width: var(--timeline-item-left-content-width, 80px);
  display: inline-block;
  font-size: 12px;
  font-size: var(--timeline-item-left-body-font-size, 12px);
  color: #888;
  color: var(--timeline-item-left-body-color, #888);
  line-height: 16px;
  line-height: -webkit-calc(
    var(--timeline-item-left-body-font-size, 12px) + 4px
  );
  line-height: -moz-calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  line-height: calc(var(--timeline-item-left-body-font-size, 12px) + 4px);
  margin-top: 4px;
  margin-top: var(--timeline-item-title-margin-top, 4px);
  text-align: right;
  padding-right: 16px;
  padding-right: var(--timeline-item-content-margin-left, 16px);
}

.next-timeline-item-error.next-timeline-item-has-left-content
  > .next-timeline-item-left-content
  p {
  word-break: break-word;
}

.next-timeline-item-error.next-timeline-item-has-left-content
  > .next-timeline-item-timeline {
  margin-left: 80px;
  margin-left: var(--timeline-item-left-content-width, 80px);
}

.next-timeline-item-error.next-timeline-item-has-left-content
  > .next-timeline-item-content {
  margin-left: 104px;
  margin-left: calc(
    var(--timeline-item-left-content-width, 80px) +
      var(--timeline-item-content-margin-left, 16px) +
      var(--timeline-item-node-size, 8px)
  );
}

.next-timeline-item-error .next-timeline-item-dot,
.next-timeline-item-error .next-timeline-item-icon {
  background: #c80000;
  background: var(--timeline-item-error-background, #c80000);
}

.next-timeline-item-error .next-timeline-item-icon {
  color: #fff;
  color: var(--timeline-item-error-color, #fff);
}

.next-timeline-item-last .next-timeline-item-tail {
  display: none;
}

.next-timeline-item-has-left-content {
  min-height: 48px;
}

.next-timeline-item-folder.next-timeline-item-has-left-content {
  min-height: auto;
}

.next-transfer {
  display: inline-block;
}

.next-transfer,
.next-transfer *,
.next-transfer :after,
.next-transfer :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-transfer-panel {
  display: inline-block;
  border: 1px solid #c0c6cc;
  border: var(--transfer-panel-border-width, 1px) var(--line-solid, solid)
    var(--transfer-panel-border-color, #c0c6cc);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--transfer-panel-border-corner, 2px);
  -moz-border-radius: var(--transfer-panel-border-corner, 2px);
  border-radius: var(--transfer-panel-border-corner, 2px);
  background-color: #fff;
  background-color: var(--transfer-panel-background-color, #fff);
  vertical-align: middle;
}

.next-transfer-panel-header {
  padding: 8px 16px;
  padding: var(--transfer-panel-header-padding-top-bottom, 8px)
    var(--transfer-panel-header-padding-left-right, 16px);
  border-bottom: 1px solid #c0c6cc;
  border-bottom: var(--transfer-panel-border-width, 1px)
    var(--line-solid, solid) var(--transfer-panel-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--transfer-panel-header-background-color, #fff);
  color: #333;
  color: var(--transfer-panel-header-text-color, #333);
  font-size: 12px;
  font-size: var(--transfer-panel-header-text-size, 12px);
}

.next-transfer-panel-search {
  padding: 0 8px;
  padding: 0 var(--transfer-panel-search-margin-left-right, 8px);
  margin-top: 8px;
  margin-top: var(--transfer-panel-search-margin-top, 8px);
  margin-bottom: 8px;
  margin-bottom: var(--transfer-panel-search-margin-bottom, 8px);
  width: 100%;
}

.next-transfer .next-transfer-panel-list {
  width: 180px;
  width: var(--transfer-panel-list-width, 180px);
  height: 160px;
  height: var(--transfer-panel-list-height, 160px);
  padding: 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  overflow-y: auto;
}

.next-transfer-panel-not-found-container {
  display: table;
  width: 100%;
  height: 100%;
}

.next-transfer-panel-not-found {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #888;
  color: var(--color-text1-2, #888);
  font-size: 14px;
  font-size: var(--font-size-body-2, 14px);
}

.next-transfer-panel-item.next-focused {
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.next-transfer-panel-item:not(.next-disabled).next-simple:hover {
  color: #0064c8;
  color: var(--transfer-simple-panel-item-hover-text-color, #0064c8);
}

.next-transfer-panel-item.next-insert-before:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  border-top: 1px solid #0064c8;
  border-top: var(--line-1, 1px) solid var(--color-brand1-6, #0064c8);
}

.next-transfer-panel-item.next-insert-after:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  border-bottom: 1px solid #0064c8;
  border-bottom: var(--line-1, 1px) solid var(--color-brand1-6, #0064c8);
}

.next-transfer-panel-footer {
  position: relative;
  padding: 8px 16px;
  padding: var(--transfer-panel-footer-padding-top-bottom, 8px)
    var(--transfer-panel-footer-padding-left-right, 16px);
  border-top: 1px solid #c0c6cc;
  border-top: var(--transfer-panel-border-width, 1px) var(--line-solid, solid)
    var(--transfer-panel-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--transfer-panel-footer-background-color, #fff);
  font-size: 0;
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--transfer-panel-footer-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--transfer-panel-footer-shadow, none);
  box-shadow: var(--transfer-panel-footer-shadow, none);
}

.next-transfer-panel-count {
  margin-left: 4px;
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  vertical-align: middle;
  color: #333;
  color: var(--transfer-panel-footer-text-color, #333);
}

.next-transfer-panel-move-all {
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  color: #333;
  color: var(--transfer-simple-panel-footer-text-color, #333);
  cursor: pointer;
}

.next-transfer-panel-move-all.next-disabled {
  color: #c1c1c1;
  color: var(--color-text1-1, #c1c1c1);
  cursor: not-allowed;
}

.next-transfer-operations {
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
  margin: 0 var(--transfer-operation-margin-left-right, 8px);
}

.next-transfer-move.next-icon {
  color: #333;
  color: var(--transfer-simple-move-icon-color, #333);
}

.next-transfer-move.next-icon:before {
  content: "\E6B3";
  content: var(--transfer-simple-move-icon, "\E6B3");
}

.next-transfer-operation.next-btn {
  display: block;
}

.next-transfer-operation.next-btn + .next-transfer-operation.next-btn {
  margin-top: 4px;
  margin-top: var(--transfer-operation-margin-gutter, 4px);
}

.next-transfer-operation.next-btn .next-icon .next-icon-remote,
.next-transfer-operation.next-btn .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-tree,
.next-tree *,
.next-tree :after,
.next-tree :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-tree,
.next-tree-child-tree {
  margin: 0;
  padding: 0;
  list-style: none;
}

.next-tree-node {
  white-space: nowrap;
}

.next-tree-node-inner {
  font-size: 0;
  outline: none;
}

.next-tree-node-label-wrapper {
  display: inline-block;
  margin: 0 4px;
  margin: 0 var(--tree-node-title-margin, 4px);
  vertical-align: middle;
}

.next-tree-node-label {
  height: 20px;
  line-height: 20px;
  padding: 0 4px;
  padding: 0 var(--tree-node-title-padding, 4px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--tree-node-title-border-radius, 2px);
  -moz-border-radius: var(--tree-node-title-border-radius, 2px);
  border-radius: var(--tree-node-title-border-radius, 2px);
  font-size: 12px;
  font-size: var(--tree-node-title-font-size, 12px);
}

.next-tree-node-label .next-icon {
  font-size: 14px;
  font-size: -webkit-calc(var(--tree-node-title-font-size, 12px) + 2px);
  font-size: -moz-calc(var(--tree-node-title-font-size, 12px) + 2px);
  font-size: calc(var(--tree-node-title-font-size, 12px) + 2px);
}

.next-tree-node-label .next-icon:before {
  font-size: 12px;
  font-size: var(--tree-node-title-font-size, 12px);
  width: 12px;
  width: var(--tree-node-title-font-size, 12px);
  margin-right: 0.5em;
}

.next-tree-node-input.next-input {
  margin: 0 4px;
  margin: 0 var(--tree-node-title-margin, 4px);
}

.next-tree-node-indent-unit {
  display: inline-block;
  width: 24px;
  vertical-align: middle;
  position: relative;
}

.next-tree-node-indent-unit.next-line:before {
  content: "";
  position: absolute;
  display: inline-block;
  border-left: 1px solid #e3e4e6;
  border-left: var(--tree-line-width, 1px) var(--tree-line-style, solid)
    var(--tree-line-color, #e3e4e6);
  height: 28px;
  height: -webkit-calc(
    var(--tree-node-padding, 4px) + var(--line-2, 2px) * 2 + 20px
  );
  height: -moz-calc(
    var(--tree-node-padding, 4px) + var(--line-2, 2px) * 2 + 20px
  );
  height: calc(var(--tree-node-padding, 4px) + var(--line-2, 2px) * 2 + 20px);
  left: 7.5px;
  left: -webkit-calc(
    var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2
  );
  left: -moz-calc(
    var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2
  );
  left: calc(
    var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2
  );
}

.next-tree-switcher {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  margin-right: var(--tree-switch-margint-right, 4px);
}

.next-tree .next-tree-unfold-icon:before {
  content: "";
  content: var(--tree-unfold-icon-content, "");
}

.next-tree-switcher.next-noline {
  width: 12px;
  width: var(--tree-switch-arrow-size, 12px);
  height: 12px;
  height: var(--tree-switch-arrow-size, 12px);
  line-height: 12px;
  line-height: var(--tree-switch-arrow-size, 12px);
  cursor: pointer;
}

.next-tree-switcher.next-noline .next-tree-switcher-icon {
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: -o-transform 0.1s linear;
  -moz-transition: transform 0.1s linear, -moz-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear,
    -moz-transform 0.1s linear, -o-transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  color: #c0c6cc;
  color: var(--tree-switch-arrow-color, #c0c6cc);
}

.next-tree-switcher.next-noline .next-tree-switcher-icon .next-icon-remote,
.next-tree-switcher.next-noline .next-tree-switcher-icon:before {
  width: 12px;
  width: var(--tree-switch-arrow-size, 12px);
  font-size: 12px;
  font-size: var(--tree-switch-arrow-size, 12px);
  line-height: inherit;
}

.next-tree-switcher.next-noline .next-tree-fold-icon:before {
  content: "\E63D";
  content: var(--tree-fold-icon-content, "\E63D");
}

.next-tree-switcher.next-noline.next-close .next-tree-switcher-icon {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.next-tree-switcher.next-noline.next-close
  .next-tree-switcher-icon
  .next-icon-remote,
.next-tree-switcher.next-noline.next-close .next-tree-switcher-icon:before {
  width: 12px;
  width: var(--tree-switch-arrow-size, 12px);
  font-size: 12px;
  font-size: var(--tree-switch-arrow-size, 12px);
  line-height: inherit;
}

.next-tree-switcher.next-noline:not(.next-disabled):hover
  .next-tree-switcher-icon {
  color: #0064c8;
  color: var(--tree-switch-hover-arrow-color, #0064c8);
}

.next-tree-switcher.next-noline.next-disabled {
  cursor: not-allowed;
}

.next-tree-switcher.next-noline.next-disabled .next-tree-switcher-icon {
  color: #555;
  color: var(--tree-node-disabled-color, #555);
}

.next-tree-switcher.next-noop-noline {
  width: 12px;
  width: var(--tree-switch-arrow-size, 12px);
  height: 12px;
  height: var(--tree-switch-arrow-size, 12px);
}

.next-tree-switcher.next-line {
  width: 16px;
  width: var(--tree-switch-size, 16px);
  height: 16px;
  height: var(--tree-switch-size, 16px);
  line-height: 14px;
  line-height: -webkit-calc(
    var(--tree-switch-size, 16px) - var(--tree-switch-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--tree-switch-size, 16px) - var(--tree-switch-border-width, 1px) * 2
  );
  line-height: calc(
    var(--tree-switch-size, 16px) - var(--tree-switch-border-width, 1px) * 2
  );
  border: 1px solid #003c78;
  border: var(--tree-switch-border-width, 1px) var(--line-solid, solid)
    var(--tree-switch-border-color, #003c78);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--tree-switch-corner, 2px);
  -moz-border-radius: var(--tree-switch-corner, 2px);
  border-radius: var(--tree-switch-corner, 2px);
  background-color: #fff;
  background-color: var(--tree-switch-bg-color, #fff);
  cursor: pointer;
}

.next-tree-switcher.next-line .next-tree-switcher-icon {
  margin-left: 1px;
  margin-left: calc(
    var(--tree-switch-size, 16px) / 2 - 6px -
      var(--tree-switch-border-width, 1px) * 2 / 2
  );
  color: #555;
  color: var(--tree-switch-icon-color, #555);
}

.next-tree-switcher.next-line .next-tree-switcher-icon .next-icon-remote,
.next-tree-switcher.next-line .next-tree-switcher-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-tree-switcher.next-line:not(.next-disabled):hover {
  border-color: #555;
  border-color: var(--tree-switch-hover-border-color, #555);
  background-color: #fff;
  background-color: var(--tree-switch-hover-bg-color, #fff);
}

.next-tree-switcher.next-line:not(.next-disabled):hover
  .next-tree-switcher-icon {
  color: #555;
  color: var(--tree-switch-hover-icon-color, #555);
}

.next-tree-switcher.next-line.next-disabled {
  border-color: #e3e4e6;
  border-color: var(--color-line1-1, #e3e4e6);
  background-color: #fff;
  cursor: not-allowed;
}

.next-tree-switcher.next-line.next-disabled .next-tree-switcher-icon {
  color: #555;
  color: var(--tree-node-disabled-color, #555);
}

.next-tree-switcher.next-noop-line {
  width: 16px;
  width: var(--tree-switch-size, 16px);
  height: 16px;
  height: var(--tree-switch-size, 16px);
}

.next-tree-switcher.next-noop-line-noroot {
  height: 0;
  border-left: 1px solid #e3e4e6;
  border-left: var(--tree-switch-border-width, 1px) var(--line-solid, solid)
    var(--tree-line-color, #e3e4e6);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--tree-line-width, 1px) var(--tree-line-style, solid)
    var(--tree-line-color, #e3e4e6);
}

.next-tree-switcher.next-noop-line-noroot .next-tree-right-angle {
  bottom: -1px;
  bottom: -webkit-calc(0 - var(--tree-line-width, 1px));
  bottom: -moz-calc(0 - var(--tree-line-width, 1px));
  bottom: calc(0px - var(--tree-line-width, 1px));
}

.next-tree-switcher.next-loading.next-loading-noline {
  width: 12px;
  width: var(--tree-switch-arrow-size, 12px);
  height: 12px;
  height: var(--tree-switch-arrow-size, 12px);
  line-height: 12px;
  line-height: var(--tree-switch-arrow-size, 12px);
}

.next-tree-switcher.next-loading.next-loading-line {
  width: 16px;
  width: var(--tree-switch-size, 16px);
  height: 16px;
  height: var(--tree-switch-size, 16px);
  line-height: 14px;
  line-height: -webkit-calc(
    var(--tree-switch-size, 16px) - var(--tree-switch-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--tree-switch-size, 16px) - var(--tree-switch-border-width, 1px) * 2
  );
  line-height: calc(
    var(--tree-switch-size, 16px) - var(--tree-switch-border-width, 1px) * 2
  );
  border: 1px solid transparent;
  border: var(--tree-switch-border-width, 1px) var(--line-solid, solid)
    transparent;
}

.next-tree-switcher.next-loading .next-tree-switcher-icon {
  color: #0064c8;
  color: var(--color-brand1-6, #0064c8);
}

.next-tree-switcher.next-loading .next-tree-switcher-icon .next-icon-remote,
.next-tree-switcher.next-loading .next-tree-switcher-icon:before {
  width: 12px;
  width: var(--tree-switch-arrow-size, 12px);
  font-size: 12px;
  font-size: var(--tree-switch-arrow-size, 12px);
  line-height: inherit;
}

.next-tree-right-angle {
  position: absolute;
  bottom: 6.5px;
  bottom: -webkit-calc(
    var(--tree-switch-size, 16px) / 2 - var(--tree-switch-border-width, 1px) * 2/2 -
      var(--tree-line-width, 1px) / 2
  );
  bottom: -moz-calc(
    var(--tree-switch-size, 16px) / 2 - var(--tree-switch-border-width, 1px) * 2/2 -
      var(--tree-line-width, 1px) / 2
  );
  bottom: calc(
    var(--tree-switch-size, 16px) / 2 - var(--tree-switch-border-width, 1px) * 2 /
      2 - var(--tree-line-width, 1px) / 2
  );
  left: -17.5px;
  left: -webkit-calc(
    -24px + var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2 -
      var(--tree-switch-border-width, 1px)
  );
  left: -moz-calc(
    -24px + var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2 -
      var(--tree-switch-border-width, 1px)
  );
  left: calc(
    -24px + var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2 -
      var(--tree-switch-border-width, 1px)
  );
  display: block;
  width: 16.5px;
  width: -webkit-calc(
    24px - var(--tree-switch-size, 16px) / 2 + var(--tree-line-width, 1px) / 2
  );
  width: -moz-calc(
    24px - var(--tree-switch-size, 16px) / 2 + var(--tree-line-width, 1px) / 2
  );
  width: calc(
    24px - var(--tree-switch-size, 16px) / 2 + var(--tree-line-width, 1px) / 2
  );
  height: 22px;
  height: -webkit-calc(
    20px + var(--tree-node-padding, 4px) - var(--tree-line-width, 1px) * 2
  );
  height: -moz-calc(
    20px + var(--tree-node-padding, 4px) - var(--tree-line-width, 1px) * 2
  );
  height: calc(
    20px + var(--tree-node-padding, 4px) - var(--tree-line-width, 1px) * 2
  );
  border-left: 1px solid #e3e4e6;
  border-left: var(--tree-line-width, 1px) var(--tree-line-style, solid)
    var(--tree-line-color, #e3e4e6);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--tree-line-width, 1px) var(--tree-line-style, solid)
    var(--tree-line-color, #e3e4e6);
}

.next-tree.next-label-block .next-tree-node-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  outline: none;
}

.next-tree.next-label-block .next-tree-node-label-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  flex: 1 1 auto;
}

.next-tree.next-node-indent .next-tree-node .next-tree-node {
  margin-left: 24px;
}

.next-tree.next-node-indent .next-tree-node-inner {
  padding-top: 2px;
  padding-top: calc(var(--tree-node-padding, 4px) / 2);
  padding-bottom: 2px;
  padding-bottom: calc(var(--tree-node-padding, 4px) / 2);
}

.next-tree.next-node-indent .next-tree-node-inner:focus .next-tree-node-label {
  color: #333;
  color: var(--tree-node-hover-color, #333);
  background-color: #f7f9fa;
  background-color: var(--tree-node-hover-bg-color, #f7f9fa);
}

.next-tree.next-node-indent .next-tree-node-label-wrapper {
  border-top: 2px solid transparent;
  border-top: var(--line-2, 2px) solid transparent;
  border-bottom: 2px solid transparent;
  border-bottom: var(--line-2, 2px) solid transparent;
}

.next-tree.next-node-indent .next-tree-node-label {
  -webkit-transition: color 0.1s linear, background-color 0.1s linear;
  -o-transition: color 0.1s linear, background-color 0.1s linear;
  -moz-transition: color 0.1s linear, background-color 0.1s linear;
  transition: color 0.1s linear, background-color 0.1s linear;
  cursor: default;
  color: #333;
  color: var(--tree-node-normal-color, #333);
  background-color: #fff;
  background-color: var(--tree-node-normal-background, #fff);
}

.next-tree.next-node-indent .next-tree-node-label-selectable {
  cursor: pointer;
}

.next-tree.next-node-indent .next-tree-node-label:hover {
  color: #333;
  color: var(--tree-node-hover-color, #333);
  background-color: #f7f9fa;
  background-color: var(--tree-node-hover-bg-color, #f7f9fa);
}

.next-tree.next-node-indent
  .next-tree-node-inner.next-selected
  .next-tree-node-label {
  color: #0064c8;
  color: var(--tree-node-selected-color, #0064c8);
  background-color: transparent;
  background-color: var(--tree-node-selected-background, transparent);
}

.next-tree.next-node-indent
  .next-tree-node-inner.next-disabled
  .next-tree-node-label,
.next-tree.next-node-indent
  .next-tree-node-inner.next-disabled
  .next-tree-node-label:hover {
  color: #555;
  color: var(--tree-node-disabled-color, #555);
  background-color: transparent;
  background-color: var(--tree-node-disabled-background, transparent);
  cursor: not-allowed;
}

.next-tree.next-node-indent
  .next-tree-node-inner.next-drag-over
  .next-tree-node-label {
  background-color: #0064c8;
  background-color: var(--color-brand1-6, #0064c8);
  color: #fff;
  opacity: 0.8;
}

.next-tree.next-node-indent
  .next-tree-node-inner.next-drag-over-gap-top
  .next-tree-node-label-wrapper {
  border-top-color: #0064c8;
  border-top-color: var(--color-brand1-6, #0064c8);
}

.next-tree.next-node-indent
  .next-tree-node-inner.next-drag-over-gap-bottom
  .next-tree-node-label-wrapper {
  border-bottom-color: #0064c8;
  border-bottom-color: var(--color-brand1-6, #0064c8);
}

.next-tree.next-node-block .next-tree-node-inner {
  padding-top: 4px;
  padding-top: var(--tree-node-padding, 4px);
  padding-bottom: 4px;
  padding-bottom: var(--tree-node-padding, 4px);
  -webkit-transition: color 0.1s linear, background-color 0.1s linear;
  -o-transition: color 0.1s linear, background-color 0.1s linear;
  -moz-transition: color 0.1s linear, background-color 0.1s linear;
  transition: color 0.1s linear, background-color 0.1s linear;
  cursor: pointer;
  color: #333;
  color: var(--tree-node-normal-color, #333);
  background-color: #fff;
  background-color: var(--tree-node-normal-background, #fff);
}

.next-tree.next-node-block .next-tree-node-inner:focus,
.next-tree.next-node-block .next-tree-node-inner:hover {
  color: #333;
  color: var(--tree-node-hover-color, #333);
  background-color: #f7f9fa;
  background-color: var(--tree-node-hover-bg-color, #f7f9fa);
}

.next-tree.next-node-block .next-tree-node-inner.next-selected {
  color: #0064c8;
  color: var(--tree-node-selected-color, #0064c8);
  background-color: transparent;
  background-color: var(--tree-node-selected-background, transparent);
}

.next-tree.next-node-block .next-tree-node-inner.next-disabled,
.next-tree.next-node-block .next-tree-node-inner.next-disabled:hover {
  color: #555;
  color: var(--tree-node-disabled-color, #555);
  background-color: transparent;
  background-color: var(--tree-node-disabled-background, transparent);
  cursor: not-allowed;
}

.next-tree.next-show-line .next-tree-node .next-tree-node:not(:last-child) {
  margin-left: 7.5px;
  margin-left: calc(
    var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2
  );
  border-left: 1px solid #e3e4e6;
  border-left: var(--tree-line-width, 1px) var(--tree-line-style, solid)
    var(--tree-line-color, #e3e4e6);
  padding-left: 15.5px;
  padding-left: calc(
    24px - var(--tree-line-width, 1px) - var(--tree-switch-size, 16px) / 2 +
      var(--tree-line-width, 1px) / 2
  );
}

.next-tree-node.next-filtered > .next-tree-node-inner .next-tree-node-label,
.next-tree-node.next-filtered
  > .next-tree-node-inner
  .next-tree-node-label:hover {
  color: #0064c8;
  color: var(--color-brand1-6, #0064c8);
}

.next-tree[dir="rtl"] .next-tree-switcher {
  margin-left: 4px;
  margin-left: var(--tree-switch-margint-right, 4px);
  margin-right: 0;
}

.next-tree[dir="rtl"] .next-tree-switcher.next-noop-line-noroot {
  border-left: none;
  border-right: 1px solid #e3e4e6;
  border-right: var(--tree-switch-border-width, 1px) var(--line-solid, solid)
    var(--tree-line-color, #e3e4e6);
}

.next-tree[dir="rtl"] .next-tree-right-angle {
  left: auto;
  right: -17.5px;
  right: -webkit-calc(
    -24px + var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2 -
      var(--tree-switch-border-width, 1px)
  );
  right: -moz-calc(
    -24px + var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2 -
      var(--tree-switch-border-width, 1px)
  );
  right: calc(
    -24px + var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2 -
      var(--tree-switch-border-width, 1px)
  );
}

.next-tree[dir="rtl"].next-show-line
  .next-tree-node
  .next-tree-node:not(:last-child),
.next-tree[dir="rtl"] .next-tree-right-angle {
  border-left: none;
  border-right: 1px solid #e3e4e6;
  border-right: var(--tree-line-width, 1px) var(--tree-line-style, solid)
    var(--tree-line-color, #e3e4e6);
}

.next-tree[dir="rtl"].next-show-line
  .next-tree-node
  .next-tree-node:not(:last-child) {
  margin-left: 0;
  margin-right: 7.5px;
  margin-right: calc(
    var(--tree-switch-size, 16px) / 2 - var(--tree-line-width, 1px) / 2
  );
  padding-left: 0;
  padding-right: 15.5px;
  padding-right: calc(
    24px - var(--tree-line-width, 1px) - var(--tree-switch-size, 16px) / 2 +
      var(--tree-line-width, 1px) / 2
  );
}

.next-tree[dir="rtl"].next-node-indent .next-tree-node .next-tree-node {
  margin-left: 0;
  margin-right: 24px;
}

.next-tree-select,
.next-tree-select *,
.next-tree-select :after,
.next-tree-select :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-tree-select-dropdown {
  background: #fff;
  background: var(--tree-select-background, #fff);
  border: 1px solid #c0c6cc;
  border: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #c0c6cc);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--popup-local-corner, 2px);
  -moz-border-radius: var(--popup-local-corner, 2px);
  border-radius: var(--popup-local-corner, 2px);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --popup-local-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  max-height: 260px;
  overflow: auto;
}

.next-tree-select-dropdown > .next-tree,
.next-tree-select-dropdown > .next-tree-select-not-found,
.next-tree-select-dropdown > .next-virtual-tree-container {
  padding: 8px 20px;
  padding: var(--tree-select-padding-vertical, 8px)
    var(--tree-select-padding-horizontal, 20px);
}

.next-tree-select-not-found {
  font-size: 12px;
  font-size: var(--font-size-body-1, 12px);
  color: #888;
  color: var(--color-text1-2, #888);
}

.next-upload-list[dir="rtl"].next-upload-list-text .next-upload-list-item {
  padding: 4px 16px;
  padding: 4px var(--upload-text-list-padding-left-right, 16px);
  padding-left: 36px;
  padding-left: calc(var(--upload-text-list-close-icon-right, 12px) * 2 + 12px);
}

.next-upload-list[dir="rtl"].next-upload-list-text .next-icon {
  left: 12px;
  left: var(--upload-text-list-close-icon-right, 12px);
  right: auto;
}

.next-upload-list[dir="rtl"].next-upload-list-image .next-icon-close {
  float: left;
  margin-left: 4px;
  margin-left: var(--upload-image-list-close-icon-right, 4px);
  margin-right: 0;
}

.next-upload-list[dir="rtl"].next-upload-list-image
  .next-upload-list-item-thumbnail {
  float: right;
  margin-left: 8px;
  margin-right: 0;
}

.next-upload-list[dir="rtl"].next-upload-list-image
  .next-upload-list-item-progress {
  margin-right: 56px;
  margin-right: calc(var(--upload-image-list-item-picture-size, 48px) + 8px);
  margin-left: 20px;
  margin-left: calc(12px + var(--upload-image-list-close-icon-right, 4px) * 2);
}

.next-upload,
.next-upload *,
.next-upload :after,
.next-upload :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-upload-inner {
  outline: 0;
  display: inline-block;
}

.next-upload-inner.next-hidden {
  display: none;
}

.next-upload-list {
  overflow: hidden;
}

.next-upload-list,
.next-upload-list *,
.next-upload-list :after,
.next-upload-list :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-upload-list-item {
  position: relative;
}

.next-upload-list-item.next-hidden {
  display: none;
}

.next-upload-list-item-name {
  text-decoration: none;
}

.next-upload.next-disabled {
  border-color: #e3e4e6 !important;
  border-color: var(--color-line1-1, #e3e4e6) !important;
  color: #c1c1c1 !important;
  color: var(--color-text1-1, #c1c1c1) !important;
}

.next-upload.next-disabled .next-icon-close {
  cursor: not-allowed !important;
}

.next-upload.next-disabled .next-upload-inner * {
  color: #c1c1c1 !important;
  color: var(--color-text1-1, #c1c1c1) !important;
  border-color: #e3e4e6 !important;
  border-color: var(--color-line1-1, #e3e4e6) !important;
  cursor: not-allowed !important;
}

.next-upload-list-text .next-upload-list-item {
  background-color: transparent;
  background-color: var(--upload-text-list-bg-color, transparent);
  padding: 4px 16px;
  padding: 4px var(--upload-text-list-padding-left-right, 16px);
  padding-right: 36px;
  padding-right: calc(
    var(--upload-text-list-close-icon-right, 12px) * 2 + 12px
  );
  height: 44px;
  height: var(--upload-text-list-height, 44px);
  line-height: 36px;
  line-height: -webkit-calc(var(--upload-text-list-height, 44px) - 8px);
  line-height: -moz-calc(var(--upload-text-list-height, 44px) - 8px);
  line-height: calc(var(--upload-text-list-height, 44px) - 8px);
  font-size: 12px;
  font-size: var(--upload-text-list-font-size, 12px);
  overflow: hidden;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--upload-text-list-corner, 0);
  -moz-border-radius: var(--upload-text-list-corner, 0);
  border-radius: var(--upload-text-list-corner, 0);
}

.next-upload-list-text .next-upload-list-item:not(:last-child) {
  margin-bottom: 4px;
}

.next-upload-list-text .next-upload-list-item-op {
  position: absolute;
  top: 0;
  right: 12px;
  right: var(--upload-text-list-close-icon-right, 12px);
}

.next-upload-list-text .next-upload-list-item .next-icon-close {
  color: #333;
  color: var(--upload-text-list-close-icon-color, #333);
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  line-height: 44px;
  line-height: var(--upload-text-list-height, 44px);
}

.next-upload-list-text
  .next-upload-list-item
  .next-icon-close
  .next-icon-remote,
.next-upload-list-text .next-upload-list-item .next-icon-close:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-upload-list-text .next-upload-list-item:hover {
  background-color: transparent;
  background-color: var(--upload-text-list-bg-color-hover, transparent);
}

.next-upload-list-text .next-upload-list-item:hover .next-icon {
  color: #0064c8;
  color: var(--upload-text-list-close-icon-color-hover, #0064c8);
}

.next-upload-list-text .next-upload-list-item-name-wrap {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 4px;
}

.next-upload-list-text .next-upload-list-item-name {
  color: #333;
  color: var(--upload-text-list-name-font-color, #333);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-upload-list-text .next-upload-list-item-size {
  color: #666;
  color: var(--upload-text-list-size-font-color, #666);
  margin-left: 8px;
}

.next-upload-list-text .next-upload-list-item-uploading {
  line-height: 18px;
  line-height: -webkit-calc(var(--upload-text-list-height, 44px) / 2 - 4px);
  line-height: -moz-calc(var(--upload-text-list-height, 44px) / 2 - 4px);
  line-height: calc(var(--upload-text-list-height, 44px) / 2 - 4px);
}

.next-upload-list-text
  .next-upload-list-item-uploading
  .next-upload-list-item-progress {
  line-height: 0;
  padding-top: 7px;
  padding-top: calc(
    var(--upload-text-list-height, 44px) / 2 / 2 - 2px -
      var(--upload-text-list-progressbar-height, 4px) / 2
  );
  padding-bottom: 7px;
  padding-bottom: calc(
    var(--upload-text-list-height, 44px) / 2 / 2 - 2px -
      var(--upload-text-list-progressbar-height, 4px) / 2
  );
}

.next-upload-list-text
  .next-upload-list-item-uploading
  .next-upload-list-item-progress
  .next-progress-line-underlay {
  height: 4px;
  height: var(--upload-text-list-progressbar-height, 4px);
}

.next-upload-list-text
  .next-upload-list-item-uploading
  .next-upload-list-item-progress
  .next-progress-line-overlay {
  height: 4px;
  height: var(--upload-text-list-progressbar-height, 4px);
  margin-top: -2px;
  margin-top: calc(0px - var(--upload-text-list-progressbar-height, 4px) / 2);
}

.next-upload-list-text .next-upload-list-item-done {
  line-height: 36px;
  line-height: -webkit-calc(var(--upload-text-list-height, 44px) - 8px);
  line-height: -moz-calc(var(--upload-text-list-height, 44px) - 8px);
  line-height: calc(var(--upload-text-list-height, 44px) - 8px);
}

.next-upload-list-text
  .next-upload-list-item-done:hover
  .next-upload-list-item-name {
  color: #0064c8;
  color: var(--upload-text-list-name-font-color-hover, #0064c8);
}

.next-upload-list-text
  .next-upload-list-item-done:hover
  .next-upload-list-item-size {
  color: #0064c8;
  color: var(--upload-text-list-size-font-color-hover, #0064c8);
}

.next-upload-list-text .next-upload-list-item-error {
  background-color: transparent !important;
  background-color: var(
    --upload-text-list-bg-color-error,
    transparent
  ) !important;
}

.next-upload-list-text
  .next-upload-list-item-error.next-upload-list-item-error-with-msg {
  line-height: 18px;
  line-height: -webkit-calc(var(--upload-text-list-height, 44px) / 2 - 4px);
  line-height: -moz-calc(var(--upload-text-list-height, 44px) / 2 - 4px);
  line-height: calc(var(--upload-text-list-height, 44px) / 2 - 4px);
}

.next-upload-list-text .next-upload-list-item-error-msg {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #c80000;
  color: var(--upload-text-list-error-text-color, #c80000);
}

.next-upload-list-image .next-upload-list-item {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid transparent;
  border: var(--upload-image-list-item-border-width, 1px) solid
    var(--upload-image-list-item-border-color, transparent);
  background-color: #f8f8f9;
  background-color: var(--upload-image-list-item-bg-color, #f8f8f9);
  padding: 8px;
  padding: var(--upload-image-list-item-padding, 8px);
  height: 48px;
  height: var(--upload-image-list-item-picture-size, 48px);
  line-height: 48px;
  line-height: var(--upload-image-list-item-picture-size, 48px);
  font-size: 12px;
  font-size: var(--upload-image-list-item-font-size, 12px);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--upload-card-list-corner, 0);
  -moz-border-radius: var(--upload-card-list-corner, 0);
  border-radius: var(--upload-card-list-corner, 0);
}

.next-upload-list-image .next-upload-list-item:not(:last-child) {
  margin-bottom: 4px;
}

.next-upload-list-image .next-upload-list-item:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-upload-list-image .next-upload-list-item-op {
  float: right;
  margin-right: 4px;
}

.next-upload-list-image .next-upload-list-item .next-icon-close {
  cursor: pointer;
  color: #333;
  color: var(--upload-image-list-close-icon-color, #333);
  text-align: center;
}

.next-upload-list-image
  .next-upload-list-item
  .next-icon-close
  .next-icon-remote,
.next-upload-list-image .next-upload-list-item .next-icon-close:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-upload-list-image .next-upload-list-item:hover {
  border-color: transparent;
  border-color: var(--upload-image-list-item-border-color-hover, transparent);
}

.next-upload-list-image .next-upload-list-item:hover .next-icon-close {
  color: #333;
  color: var(--upload-image-list-close-icon-color-hover, #333);
}

.next-upload-list-image .next-upload-list-item-name {
  display: block;
  color: #333;
  color: var(--upload-text-list-name-font-color, #333);
  margin-left: 56px;
  margin-left: calc(
    var(--upload-image-list-item-picture-size, 48px) +
      var(--upload-image-list-item-padding, 8px)
  );
  margin-right: 20px;
  margin-right: calc(12px + var(--upload-image-list-close-icon-right, 4px) * 2);
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-upload-list-image .next-upload-list-item-size {
  color: #666;
  color: var(--upload-text-list-size-font-color, #666);
  margin-left: 8px;
}

.next-upload-list-image
  .next-upload-list-item-done:hover
  .next-upload-list-item-name {
  color: #0064c8;
  color: var(--upload-text-list-name-font-color-hover, #0064c8);
}

.next-upload-list-image
  .next-upload-list-item-done:hover
  .next-upload-list-item-size {
  color: #0064c8;
  color: var(--upload-text-list-size-font-color-hover, #0064c8);
}

.next-upload-list-image .next-upload-list-item-thumbnail {
  float: left;
  width: 48px;
  width: var(--upload-image-list-item-picture-size, 48px);
  height: 48px;
  height: var(--upload-image-list-item-picture-size, 48px);
  color: #c1c1c1;
  color: var(--upload-image-list-item-thumbnail-font-color, #c1c1c1);
  border: 1px solid #e3e4e6;
  border: var(--upload-image-list-item-picture-border-width, 1px) solid
    var(--upload-image-list-item-picture-border-color, #e3e4e6);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius: var(--upload-image-list-item-picture-corner, 0);
  -moz-border-radius: var(--upload-image-list-item-picture-corner, 0);
  border-radius: var(--upload-image-list-item-picture-corner, 0);
  background-color: #f8f8f9;
  background-color: var(
    --upload-image-list-item-picture-background-color,
    #f8f8f9
  );
  margin-right: 8px;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-upload-list-image .next-upload-list-item-thumbnail img {
  width: 100%;
  height: 100%;
}

.next-upload-list-image .next-upload-list-item-thumbnail .next-icon {
  display: block;
  margin: 0;
  line-height: 48px;
  line-height: var(--upload-image-list-item-picture-size, 48px);
}

.next-upload-list-image
  .next-upload-list-item-thumbnail
  .next-icon
  .next-icon-remote,
.next-upload-list-image .next-upload-list-item-thumbnail .next-icon:before {
  width: 24px;
  font-size: 24px;
  line-height: inherit;
}

.next-upload-list-image .next-upload-list-item-error {
  border-color: transparent !important;
  border-color: var(
    --upload-image-list-item-border-color-error,
    transparent
  ) !important;
  background-color: #fdfbfb;
  background-color: var(--upload-image-list-item-error-bg-color, #fdfbfb);
}

.next-upload-list-image .next-upload-list-item-uploading {
  background-color: #f8f8f9;
  background-color: var(--upload-image-list-item-uploading-bg-color, #f8f8f9);
}

.next-upload-list-image
  .next-upload-list-item-uploading
  .next-upload-list-item-name {
  height: 24px;
  height: -webkit-calc(var(--upload-image-list-item-picture-size, 48px) / 2);
  height: -moz-calc(var(--upload-image-list-item-picture-size, 48px) / 2);
  height: calc(var(--upload-image-list-item-picture-size, 48px) / 2);
  line-height: 24px;
  line-height: -webkit-calc(
    var(--upload-image-list-item-picture-size, 48px) / 2
  );
  line-height: -moz-calc(var(--upload-image-list-item-picture-size, 48px) / 2);
  line-height: calc(var(--upload-image-list-item-picture-size, 48px) / 2);
}

.next-upload-list-image
  .next-upload-list-item-uploading
  .next-upload-list-item-progress {
  margin-left: 56px;
  margin-left: calc(var(--upload-image-list-item-picture-size, 48px) + 8px);
  margin-right: 20px;
  margin-right: calc(12px + var(--upload-image-list-close-icon-right, 4px) * 2);
  line-height: 0;
  padding-top: 10px;
  padding-top: calc(
    var(--upload-image-list-item-picture-size, 48px) / 2 / 2 -
      var(--upload-image-list-progressbar-height, 4px) / 2
  );
  padding-bottom: 10px;
  padding-bottom: calc(
    var(--upload-image-list-item-picture-size, 48px) / 2 / 2 -
      var(--upload-image-list-progressbar-height, 4px) / 2
  );
}

.next-upload-list-image
  .next-upload-list-item-uploading
  .next-upload-list-item-progress
  .next-progress-line-underlay {
  height: 4px;
  height: var(--upload-image-list-progressbar-height, 4px);
}

.next-upload-list-image
  .next-upload-list-item-uploading
  .next-upload-list-item-progress
  .next-progress-line-overlay {
  height: 4px;
  height: var(--upload-image-list-progressbar-height, 4px);
  margin-top: -2px;
  margin-top: calc(0px - var(--upload-image-list-progressbar-height, 4px) / 2);
}

.next-upload-list-image
  .next-upload-list-item-error-with-msg
  .next-upload-list-item-error-msg,
.next-upload-list-image
  .next-upload-list-item-error-with-msg
  .next-upload-list-item-name {
  height: 24px;
  height: -webkit-calc(var(--upload-image-list-item-picture-size, 48px) / 2);
  height: -moz-calc(var(--upload-image-list-item-picture-size, 48px) / 2);
  height: calc(var(--upload-image-list-item-picture-size, 48px) / 2);
  line-height: 24px;
  line-height: -webkit-calc(
    var(--upload-image-list-item-picture-size, 48px) / 2
  );
  line-height: -moz-calc(var(--upload-image-list-item-picture-size, 48px) / 2);
  line-height: calc(var(--upload-image-list-item-picture-size, 48px) / 2);
}

.next-upload-list-image
  .next-upload-list-item-error-with-msg
  .next-upload-list-item-error-msg {
  margin-left: 56px;
  margin-left: calc(var(--upload-image-list-item-picture-size, 48px) + 8px);
  margin-right: 20px;
  margin-right: calc(12px + var(--upload-image-list-close-icon-right, 4px) * 2);
  color: #c80000;
  color: var(--upload-text-list-error-text-color, #c80000);
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.next-upload-list-card {
  display: inline-block;
}

.next-upload-list-card .next-upload-list-item {
  vertical-align: middle;
  float: left;
}

.next-upload-list-card .next-upload-list-item:not(:last-child) {
  margin-right: 12px;
  margin-right: var(--upload-card-list-margin-right, 12px);
}

.next-upload-list-card .next-upload-list-item-wrapper {
  position: relative;
  border: 1px solid #e3e4e6;
  border: 1px solid var(--upload-card-list-border-color, #e3e4e6);
  width: 88px;
  width: var(--upload-card-size, 88px);
  height: 88px;
  height: var(--upload-card-size, 88px);
  padding: 0;
  padding: var(--upload-card-list-padding, 0);
  background-color: transparent;
  background-color: var(--upload-card-list-bg-color, transparent);
}

.next-upload-list-card .next-upload-list-item-thumbnail {
  text-align: center;
  width: 100%;
  height: 100%;
  color: #0064c8;
  color: var(--upload-card-list-thumbnail-font-color, #0064c8);
  font-size: 12px;
  font-size: var(--upload-card-list-thumbnail-font-size, 12px);
}

.next-upload-list-card .next-upload-list-item-thumbnail img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.next-upload-list-card .next-upload-list-item-thumbnail img:focus {
  outline: 0;
}

.next-upload-list-card .next-upload-list-item-thumbnail .next-icon {
  width: 100%;
}

.next-upload-list-card
  .next-upload-list-item-thumbnail
  .next-icon
  .next-icon-remote,
.next-upload-list-card .next-upload-list-item-thumbnail .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-upload-list-card .next-upload-list-item-handler {
  margin-top: 7px;
  margin-top: calc(var(--upload-card-size, 88px) / 2 - 37px);
}

.next-upload-list-card .next-upload-list-item-handler .next-icon-cry {
  margin-top: 10px;
}

.next-upload-list-card .next-upload-list-item-name {
  display: block;
  width: 88px;
  width: var(--upload-card-size, 88px);
  text-align: center;
  margin-top: 4px;
  margin-top: var(--upload-card-list-name-margin-top, 4px);
  font-size: 12px;
  font-size: var(--upload-card-list-name-font-size, 12px);
  color: #333;
  color: var(--upload-card-list-name-font-color, #333);
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.next-upload-list-card .next-upload-list-item-progress {
  position: absolute;
  font-size: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.next-upload-list-card
  .next-upload-list-item-progress
  .next-progress-line-overlay,
.next-upload-list-card
  .next-upload-list-item-progress
  .next-progress-line-underlay {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 4px;
  height: var(--upload-card-list-progressbar-height, 4px);
}

.next-upload-list-card
  .next-upload-list-item-progress
  .next-progress-line-overlay {
  margin-top: -2px;
  margin-top: calc(0px - var(--upload-card-list-progressbar-height, 4px) / 2);
}

.next-upload-list-card
  .next-upload-list-item-uploading
  .next-upload-list-item-wrapper {
  background-color: #fff;
  background-color: var(--upload-card-list-uploading-bg-color, #fff);
}

.next-upload-list-card .next-upload-list-item:hover .next-upload-tool {
  opacity: 0.8;
}

.next-upload-list-card .next-upload-list-item .next-upload-tool {
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  background-color: rgba(
    0,
    0,
    0,
    var(--upload-select-card-tool-bg-opacity, 0.7)
  );
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
  opacity: 0;
  width: 100%;
  height: 28px;
  left: 0;
  bottom: 0;
}

.next-upload-list-card .next-upload-list-item .next-upload-tool .next-icon {
  width: 49%;
  text-align: center;
  line-height: 28px;
  color: #fff;
}

.next-upload-list-card
  .next-upload-list-item
  .next-upload-tool
  .next-icon
  .next-icon-remote,
.next-upload-list-card
  .next-upload-list-item
  .next-upload-tool
  .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-upload-list-card
  .next-upload-list-item
  .next-upload-tool.next-noclose
  .next-icon {
  width: 100%;
}

.next-upload-list-card .next-upload-list-item .next-upload-tool-close {
  cursor: pointer;
}

.next-upload-list-card .next-upload-list-item .next-upload-tool-download-icon {
  border-right: 1px solid #fff;
}

.next-upload-list-card
  .next-upload-list-item-error
  .next-upload-list-item-wrapper {
  border-color: #c80000;
  border-color: var(--upload-card-list-border-color-error, #c80000);
}

.next-upload-card {
  border: 1px dashed #c0c6cc;
  border: 1px var(--upload-card-border-style, dashed)
    var(--upload-card-border-color, #c0c6cc);
  width: 88px;
  width: var(--upload-card-size, 88px);
  height: 88px;
  height: var(--upload-card-size, 88px);
  background-color: #fff;
  background-color: var(--upload-card-bg-color, #fff);
  text-align: center;
  cursor: pointer;
  -webkit-transition: border-color 0.1s linear;
  -o-transition: border-color 0.1s linear;
  -moz-transition: border-color 0.1s linear;
  transition: border-color 0.1s linear;
  display: table-cell;
  vertical-align: middle;
}

.next-upload-card .next-icon {
  color: #333;
  color: var(--upload-card-icon-color, #333);
}

.next-upload-card .next-icon .next-icon-remote,
.next-upload-card .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-upload-card .next-upload-add-icon:before {
  content: "\E655";
  content: var(--upload-card-add-icon, "\E655");
}

.next-upload-card .next-upload-text {
  font-size: 12px;
  font-size: var(--upload-card-add-text-size, 12px);
  margin-top: 8px;
  margin-top: var(--upload-card-add-text-margin-top, 8px);
  color: #333;
  color: var(--upload-card-font-color, #333);
  outline: none;
}

.next-upload-card:hover {
  border-color: #0064c8;
  border-color: var(--upload-card-hover-border-color, #0064c8);
}

.next-upload-card:hover .next-icon,
.next-upload-card:hover .next-upload-text {
  color: #0064c8;
  color: var(--upload-card-hover-font-color, #0064c8);
}

.next-upload-dragable .next-upload-inner {
  display: block;
}

.next-upload-dragable .next-upload-drag {
  border: 1px dashed #d1d5d9;
  border: 1px dashed var(--upload-drag-zone-border-color, #d1d5d9);
  -webkit-transition: border-color 0.1s linear;
  -o-transition: border-color 0.1s linear;
  -moz-transition: border-color 0.1s linear;
  transition: border-color 0.1s linear;
  cursor: pointer;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--upload-drag-zone-corner, 2px);
  -moz-border-radius: var(--upload-drag-zone-corner, 2px);
  border-radius: var(--upload-drag-zone-corner, 2px);
  background-color: transparent;
  background-color: var(--upload-drag-zone-bg-color, transparent);
  text-align: center;
  margin-bottom: 4px;
}

.next-upload-dragable .next-upload-drag-icon {
  margin: 20px 0 0;
  color: #0064c8;
  color: var(--upload-drag-zone-upload-icon-color, #0064c8);
}

.next-upload-dragable
  .next-upload-drag-icon
  .next-upload-drag-upload-icon:before {
  content: "\E7EE";
  content: var(--upload-drag-zone-upload-icon, "\E7EE");
  font-size: 16px;
}

.next-upload-dragable .next-upload-drag-text {
  margin: 12px 0 0;
  font-size: 12px;
  font-size: var(--upload-drag-zone-font-size, 12px);
  color: #333;
  color: var(--upload-drag-zone-upload-normal-title-color, #333);
}

.next-upload-dragable .next-upload-drag-hint {
  margin: 4px 0 20px;
  font-size: 12px;
  font-size: var(--upload-drag-zone-hint-font-size, 12px);
  color: #333;
  color: var(--upload-drag-zone-upload-normal-hint-color, #333);
}

.next-upload-dragable .next-upload-drag-over {
  border-color: #d1d5d9;
  border-color: var(--upload-drag-zone-over-border-color, #d1d5d9);
}

.next-shell {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.next-shell,
.next-shell *,
.next-shell :after,
.next-shell :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-shell-content-wrapper {
  overflow: auto;
}

.next-shell-header {
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  z-index: 9;
}

.next-shell-header,
.next-shell-header .dock-trigger,
.next-shell-header .nav-trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
}

.next-shell-header .dock-trigger,
.next-shell-header .nav-trigger {
  outline: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.next-shell-header .nav-trigger {
  margin-right: 10px;
}

.next-shell-header .dock-trigger {
  margin-left: 10px;
}

.next-shell-header.next-shell-fixed-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.next-shell-header .next-shell-navigation {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  flex: 1 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
}

.next-shell-header .next-shell-action,
.next-shell-header .next-shell-branding,
.next-shell-header .next-shell-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
}

.next-shell-sub-main {
  -ms-flex: 1 1;
  -webkit-flex: 1 1;
  flex: 1 1;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  flex-direction: column;
  outline: 0;
}

.next-shell-main,
.next-shell-sub-main {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  height: 100%;
  overflow: auto;
}

.next-shell-main {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  flex-direction: row;
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.next-shell-main .next-shell-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  flex: 1 1 auto;
}

.next-shell-main .next-shell-content-inner {
  margin: 0 auto;
}

.next-shell-main .next-shell-footer {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
}

.next-shell-main .next-shell-footer,
.next-shell .next-aside-navigation,
.next-shell .next-aside-tooldock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.next-shell .next-aside-navigation.fixed,
.next-shell .next-aside-tooldock.fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.next-shell .next-aside-navigation.fixed {
  left: 0;
}

.next-shell .next-aside-tooldock.fixed {
  right: 0;
}

.next-shell-aside {
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.next-shell-aside .aside-trigger {
  cursor: pointer;
  outline: 0;
  position: absolute;
  right: 0;
  top: 50%;
  width: 20px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  border: 1px solid #ddd;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.next-shell-aside .local-nav-trigger {
  outline: 0;
  border-left: none;
  -webkit-transform: translate(100%, -50%);
  -moz-transform: translate(100%, -50%);
  -o-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
  right: 0;
}

.next-shell-aside .ancillary-trigger {
  outline: 0;
  -webkit-transform: translate(-100%, -50%);
  -moz-transform: translate(-100%, -50%);
  -o-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  border-right: 0;
  left: 1px;
}

.next-shell-aside.next-aside-ancillary,
.next-shell-aside.next-aside-localnavigation {
  position: relative;
}

.next-shell-aside.next-shell-navigation {
  justify-self: flex-start;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.next-shell-aside.next-shell-navigation,
.next-shell-aside.next-shell-tooldock {
  overflow-x: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.next-shell-aside.next-shell-tooldock {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
}

.next-shell-aside .next-shell-tooldockitem {
  width: 100%;
  text-align: center;
}

.next-shell-aside .next-shell-localnavigation {
  position: relative;
}

.next-shell-aside .next-shell-ancillary,
.next-shell-aside .next-shell-localnavigation {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  justify-self: flex-start;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.next-shell-light .next-shell-header .dock-trigger,
.next-shell-light .next-shell-header .nav-trigger {
  background: #fff;
  background: var(--shell-light-header-background, #fff);
}

.next-shell-light .next-shell-aside .local-nav-trigger {
  background: #004080;
  background: var(--shell-light-local-navigation-background, #004080);
}

.next-shell-light .next-shell-aside .ancillary-trigger {
  background: #fff;
  background: var(--shell-light-ancillary-background, #fff);
}

.next-shell-light .next-shell-header {
  color: #000;
  color: var(--shell-light-header-color, #000);
  height: 52px;
  height: var(--shell-light-header-height, 52px);
  background: #fff;
  background: var(--shell-light-header-background, #fff);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--shell-light-header-divider-size, 1px)
    var(--shell-light-header-divider-style, solid)
    var(--shell-light-header-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-light-header-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-light-header-shadow, none);
  box-shadow: var(--shell-light-header-shadow, none);
  padding: 0 16px;
  padding: 0 var(--shell-light-header-paddingLeft, 16px);
}

.next-shell-light .next-shell-header .next-shell-navigation {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  justify-content: flex-end;
  height: 52px;
  height: var(--shell-light-header-height, 52px);
  line-height: 52px;
  line-height: var(--shell-light-header-height, 52px);
  margin: 0 48px;
  margin: 0 var(--shell-light-navigation-hoz-marginLeft, 48px);
}

.next-shell-light .next-shell-task-header {
  width: 100%;
  min-height: 40px;
  min-height: var(--shell-light-multitask-min-height, 40px);
  background: #fff;
  background: var(--shell-light-multitask-background, #fff);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--shell-light-multitask-divider-size, 1px)
    var(--shell-light-multitask-divider-style, solid)
    var(--shell-light-multitask-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-light-multitask-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-light-multitask-shadow, none);
  box-shadow: var(--shell-light-multitask-shadow, none);
  padding: 0;
  padding: 0 var(--shell-light-multitask-paddingLeft, 0);
  overflow: auto;
}

.next-shell-light .next-shell-main {
  background: #eff3f8;
  background: var(--shell-light-content-background, #eff3f8);
}

.next-shell-light .next-shell-main .next-shell-appbar {
  min-height: 48px;
  min-height: var(--shell-light-appbar-min-height, 48px);
  background: #fff;
  background: var(--shell-light-appbar-background, #fff);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--shell-light-appbar-divider-size, 1px)
    var(--shell-light-appbar-divider-style, solid)
    var(--shell-light-appbar-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-light-appbar-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-light-appbar-shadow, none);
  box-shadow: var(--shell-light-appbar-shadow, none);
  padding: 0 24px;
  padding: 0 var(--shell-light-appbar-paddingLeft, 24px);
}

.next-shell-light .next-shell-main .next-shell-content {
  padding: 20px;
  padding: var(--shell-light-content-paddingTop, 20px)
    var(--shell-light-content-paddingLeft, 20px);
}

.next-shell-light .next-shell-main .next-shell-footer {
  background: transparent;
  background: var(--shell-light-footer-background, transparent);
  min-height: 56px;
  min-height: var(--shell-light-footer-min-height, 56px);
  color: #c1c1c1;
  color: var(--shell-light-footer-color, #c1c1c1);
  font-size: 14px;
  font-size: var(--shell-light-footer-font-size, 14px);
}

.next-shell-light .next-shell-aside.next-shell-navigation {
  width: 168px;
  width: var(--shell-light-navigation-ver-width, 168px);
  background: #fff;
  background: var(--shell-light-navigation-ver-background, #fff);
  border-right: 1px solid #e3e4e6;
  border-right: var(--shell-light-navigation-ver-divider-size, 1px)
    var(--shell-light-navigation-ver-divider-style, solid)
    var(--shell-light-navigation-ver-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-light-navigation-ver-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-light-navigation-ver-shadow, none);
  box-shadow: var(--shell-light-navigation-ver-shadow, none);
  padding: 8px 0;
  padding: var(--shell-light-navigation-ver-paddingTop, 8px) 0
    var(--shell-light-navigation-ver-paddingBottom, 8px);
}

.next-shell-light
  .next-shell-aside.next-shell-navigation.next-shell-collapse.next-shell-mini {
  width: 60px;
  width: var(--shell-light-navigation-ver-width-mini, 60px);
}

.next-shell-light .next-shell-aside.next-shell-navigation.next-shell-collapse {
  width: 0;
}

.next-shell-light .next-shell-aside.next-shell-tooldock {
  width: 52px;
  width: var(--shell-light-tooldock-width, 52px);
  background: #004080;
  background: var(--shell-light-tooldock-background, #004080);
  border-left: 1px solid #e3e4e6;
  border-left: var(--shell-light-tooldock-divider-size, 1px)
    var(--shell-light-tooldock-divider-style, solid)
    var(--shell-light-tooldock-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-light-tooldock-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-light-tooldock-shadow, none);
  box-shadow: var(--shell-light-tooldock-shadow, none);
  padding: 8px 0;
  padding: var(--shell-light-tooldock-paddingTop, 8px) 0
    var(--shell-light-tooldock-paddingBottom, 8px);
}

.next-shell-light .next-shell-aside .next-shell-tooldockitem {
  padding: 8px 0;
  padding: var(--shell-light-tooldock-item-paddingTop, 8px) 0;
  color: #555;
  color: var(--shell-light-tooldock-item-color, #555);
  background: transparent;
  background: var(--shell-light-tooldock-item-background, transparent);
}

.next-shell-light .next-shell-aside .next-shell-tooldockitem:hover {
  color: #111;
  color: var(--shell-light-tooldock-item-color-hover, #111);
  background: #eff3f8;
  background: var(--shell-light-tooldock-item-background-hover, #eff3f8);
}

.next-shell-light .next-shell-aside .next-shell-localnavigation {
  width: 168px;
  width: var(--shell-light-local-navigation-width, 168px);
  background: #004080;
  background: var(--shell-light-local-navigation-background, #004080);
  border-right: 1px solid #e3e4e6;
  border-right: var(--shell-light-local-navigation-divider-size, 1px)
    var(--shell-light-local-navigation-divider-style, solid)
    var(--shell-light-local-navigation-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-light-local-navigation-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-light-local-navigation-shadow, none);
  box-shadow: var(--shell-light-local-navigation-shadow, none);
  padding: 8px 0;
  padding: var(--shell-light-local-navigation-paddingTop, 8px) 0
    var(--shell-light-local-navigation-paddingBottom, 8px);
}

.next-shell-light
  .next-shell-aside
  .next-shell-localnavigation.next-shell-collapse {
  width: 0;
}

.next-shell-light .next-shell-aside .next-shell-ancillary {
  width: 168px;
  width: var(--shell-light-ancillary-width, 168px);
  background: #fff;
  background: var(--shell-light-ancillary-background, #fff);
  border-left: 1px solid #e3e4e6;
  border-left: var(--shell-light-ancillary-divider-size, 1px)
    var(--shell-light-ancillary-divider-style, solid)
    var(--shell-light-ancillary-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-light-ancillary-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-light-ancillary-shadow, none);
  box-shadow: var(--shell-light-ancillary-shadow, none);
  padding: 8px 0;
  padding: var(--shell-light-ancillary-paddingTop, 8px) 0
    var(--shell-light-ancillary-paddingBottom, 8px);
}

.next-shell-light .next-shell-aside .next-shell-ancillary.next-shell-collapse {
  width: 0;
}

.next-shell-dark .next-shell-header .dock-trigger,
.next-shell-dark .next-shell-header .nav-trigger {
  background: #000;
  background: var(--shell-dark-header-background, #000);
}

.next-shell-dark .next-shell-aside .local-nav-trigger {
  background: #004080;
  background: var(--shell-dark-local-navigation-background, #004080);
}

.next-shell-dark .next-shell-aside .ancillary-trigger {
  background: #fff;
  background: var(--shell-dark-ancillary-background, #fff);
}

.next-shell-dark .next-shell-header {
  color: #fff;
  color: var(--shell-dark-header-color, #fff);
  height: 52px;
  height: var(--shell-dark-header-height, 52px);
  background: #000;
  background: var(--shell-dark-header-background, #000);
  border-bottom: 1px solid #1f1f1f;
  border-bottom: var(--shell-dark-header-divider-size, 1px)
    var(--shell-dark-header-divider-style, solid)
    var(--shell-dark-header-divider-color, #1f1f1f);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --shell-dark-header-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(
    --shell-dark-header-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  box-shadow: var(--shell-dark-header-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  padding: 0 16px;
  padding: 0 var(--shell-dark-header-paddingLeft, 16px);
}

.next-shell-dark .next-shell-header .next-shell-navigation {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  justify-content: flex-end;
  height: 52px;
  height: var(--shell-dark-header-height, 52px);
  line-height: 52px;
  line-height: var(--shell-dark-header-height, 52px);
  margin: 0 48px;
  margin: 0 var(--shell-dark-navigation-hoz-marginLeft, 48px);
}

.next-shell-dark .next-shell-task-header {
  width: 100%;
  min-height: 40px;
  min-height: var(--shell-dark-multitask-min-height, 40px);
  background: #fff;
  background: var(--shell-dark-multitask-background, #fff);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--shell-dark-multitask-divider-size, 1px)
    var(--shell-dark-multitask-divider-style, solid)
    var(--shell-dark-multitask-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-dark-multitask-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-dark-multitask-shadow, none);
  box-shadow: var(--shell-dark-multitask-shadow, none);
  padding: 0;
  padding: 0 var(--shell-dark-multitask-paddingLeft, 0);
  overflow: auto;
}

.next-shell-dark .next-shell-main {
  background: #eff3f8;
  background: var(--shell-dark-content-background, #eff3f8);
}

.next-shell-dark .next-shell-main .next-shell-appbar {
  min-height: 48px;
  min-height: var(--shell-dark-appbar-min-height, 48px);
  background: #fff;
  background: var(--shell-dark-appbar-background, #fff);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--shell-dark-appbar-divider-size, 1px)
    var(--shell-dark-appbar-divider-style, solid)
    var(--shell-dark-appbar-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-dark-appbar-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-dark-appbar-shadow, none);
  box-shadow: var(--shell-dark-appbar-shadow, none);
  padding: 0 24px;
  padding: 0 var(--shell-dark-appbar-paddingLeft, 24px);
}

.next-shell-dark .next-shell-main .next-shell-content {
  padding: 20px;
  padding: var(--shell-dark-content-paddingTop, 20px)
    var(--shell-dark-content-paddingLeft, 20px);
}

.next-shell-dark .next-shell-main .next-shell-footer {
  background: transparent;
  background: var(--shell-dark-footer-background, transparent);
  min-height: 56px;
  min-height: var(--shell-dark-footer-min-height, 56px);
  color: #c1c1c1;
  color: var(--shell-dark-footer-color, #c1c1c1);
  font-size: 14px;
  font-size: var(--shell-dark-footer-font-size, 14px);
}

.next-shell-dark .next-shell-aside.next-shell-navigation {
  width: 168px;
  width: var(--shell-dark-navigation-ver-width, 168px);
  background: #111;
  background: var(--shell-dark-navigation-ver-background, #111);
  border-right: 1px solid #e3e4e6;
  border-right: var(--shell-dark-navigation-ver-divider-size, 1px)
    var(--shell-dark-navigation-ver-divider-style, solid)
    var(--shell-dark-navigation-ver-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-dark-navigation-ver-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-dark-navigation-ver-shadow, none);
  box-shadow: var(--shell-dark-navigation-ver-shadow, none);
  padding: 8px 0;
  padding: var(--shell-dark-navigation-ver-paddingTop, 8px) 0
    var(--shell-dark-navigation-ver-paddingBottom, 8px);
}

.next-shell-dark
  .next-shell-aside.next-shell-navigation.next-shell-collapse.next-shell-mini {
  width: 60px;
  width: var(--shell-dark-navigation-ver-width-mini, 60px);
}

.next-shell-dark .next-shell-aside.next-shell-navigation.next-shell-collapse {
  width: 0;
}

.next-shell-dark .next-shell-aside.next-shell-tooldock {
  width: 52px;
  width: var(--shell-dark-tooldock-width, 52px);
  background: #004080;
  background: var(--shell-dark-tooldock-background, #004080);
  border-left: 1px solid #e3e4e6;
  border-left: var(--shell-dark-tooldock-divider-size, 1px)
    var(--shell-dark-tooldock-divider-style, solid)
    var(--shell-dark-tooldock-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-dark-tooldock-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-dark-tooldock-shadow, none);
  box-shadow: var(--shell-dark-tooldock-shadow, none);
  padding: 8px 0;
  padding: var(--shell-dark-tooldock-paddingTop, 8px) 0
    var(--shell-dark-tooldock-paddingBottom, 8px);
}

.next-shell-dark .next-shell-aside .next-shell-tooldockitem {
  padding: 8px 0;
  padding: var(--shell-dark-tooldock-item-paddingTop, 8px) 0;
  color: #555;
  color: var(--shell-dark-tooldock-item-color, #555);
  background: transparent;
  background: var(--shell-dark-tooldock-item-background, transparent);
}

.next-shell-dark .next-shell-aside .next-shell-tooldockitem:hover {
  color: #111;
  color: var(--shell-dark-tooldock-item-color-hover, #111);
  background: #eff3f8;
  background: var(--shell-dark-tooldock-item-background-hover, #eff3f8);
}

.next-shell-dark .next-shell-aside .next-shell-localnavigation {
  width: 168px;
  width: var(--shell-dark-local-navigation-width, 168px);
  background: #004080;
  background: var(--shell-dark-local-navigation-background, #004080);
  border-right: 1px solid #e3e4e6;
  border-right: var(--shell-dark-local-navigation-divider-size, 1px)
    var(--shell-dark-local-navigation-divider-style, solid)
    var(--shell-dark-local-navigation-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-dark-local-navigation-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-dark-local-navigation-shadow, none);
  box-shadow: var(--shell-dark-local-navigation-shadow, none);
  padding: 8px 0;
  padding: var(--shell-dark-local-navigation-paddingTop, 8px) 0
    var(--shell-dark-local-navigation-paddingBottom, 8px);
}

.next-shell-dark
  .next-shell-aside
  .next-shell-localnavigation.next-shell-collapse {
  width: 0;
}

.next-shell-dark .next-shell-aside .next-shell-ancillary {
  width: 168px;
  width: var(--shell-dark-ancillary-width, 168px);
  background: #fff;
  background: var(--shell-dark-ancillary-background, #fff);
  border-left: 1px solid #e3e4e6;
  border-left: var(--shell-dark-ancillary-divider-size, 1px)
    var(--shell-dark-ancillary-divider-style, solid)
    var(--shell-dark-ancillary-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-dark-ancillary-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-dark-ancillary-shadow, none);
  box-shadow: var(--shell-dark-ancillary-shadow, none);
  padding: 8px 0;
  padding: var(--shell-dark-ancillary-paddingTop, 8px) 0
    var(--shell-dark-ancillary-paddingBottom, 8px);
}

.next-shell-dark .next-shell-aside .next-shell-ancillary.next-shell-collapse {
  width: 0;
}

.next-shell-brand .next-shell-header .dock-trigger,
.next-shell-brand .next-shell-header .nav-trigger {
  background: #0064c8;
  background: var(--shell-brand-header-background, #0064c8);
}

.next-shell-brand .next-shell-aside .local-nav-trigger {
  background: #004080;
  background: var(--shell-brand-local-navigation-background, #004080);
}

.next-shell-brand .next-shell-aside .ancillary-trigger {
  background: #fff;
  background: var(--shell-brand-ancillary-background, #fff);
}

.next-shell-brand .next-shell-header {
  color: #fff;
  color: var(--shell-brand-header-color, #fff);
  height: 52px;
  height: var(--shell-brand-header-height, 52px);
  background: #0064c8;
  background: var(--shell-brand-header-background, #0064c8);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--shell-brand-header-divider-size, 1px)
    var(--shell-brand-header-divider-style, solid)
    var(--shell-brand-header-divider-color, #e3e4e6);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --shell-brand-header-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(
    --shell-brand-header-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  box-shadow: var(--shell-brand-header-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  padding: 0 16px;
  padding: 0 var(--shell-brand-header-paddingLeft, 16px);
}

.next-shell-brand .next-shell-header .next-shell-navigation {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  justify-content: flex-end;
  height: 52px;
  height: var(--shell-brand-header-height, 52px);
  line-height: 52px;
  line-height: var(--shell-brand-header-height, 52px);
  margin: 0 48px;
  margin: 0 var(--shell-brand-navigation-hoz-marginLeft, 48px);
}

.next-shell-brand .next-shell-task-header {
  width: 100%;
  min-height: 40px;
  min-height: var(--shell-brand-multitask-min-height, 40px);
  background: #fff;
  background: var(--shell-brand-multitask-background, #fff);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--shell-brand-multitask-divider-size, 1px)
    var(--shell-brand-multitask-divider-style, solid)
    var(--shell-brand-multitask-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-brand-multitask-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-brand-multitask-shadow, none);
  box-shadow: var(--shell-brand-multitask-shadow, none);
  padding: 0;
  padding: 0 var(--shell-brand-multitask-paddingLeft, 0);
  overflow: auto;
}

.next-shell-brand .next-shell-main {
  background: #eff3f8;
  background: var(--shell-brand-content-background, #eff3f8);
}

.next-shell-brand .next-shell-main .next-shell-appbar {
  min-height: 48px;
  min-height: var(--shell-brand-appbar-min-height, 48px);
  background: #fff;
  background: var(--shell-brand-appbar-background, #fff);
  border-bottom: 1px solid #e3e4e6;
  border-bottom: var(--shell-brand-appbar-divider-size, 1px)
    var(--shell-brand-appbar-divider-style, solid)
    var(--shell-brand-appbar-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-brand-appbar-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-brand-appbar-shadow, none);
  box-shadow: var(--shell-brand-appbar-shadow, none);
  padding: 0 24px;
  padding: 0 var(--shell-brand-appbar-paddingLeft, 24px);
}

.next-shell-brand .next-shell-main .next-shell-content {
  padding: 20px;
  padding: var(--shell-brand-content-paddingTop, 20px)
    var(--shell-brand-content-paddingLeft, 20px);
}

.next-shell-brand .next-shell-main .next-shell-footer {
  background: transparent;
  background: var(--shell-brand-footer-background, transparent);
  min-height: 56px;
  min-height: var(--shell-brand-footer-min-height, 56px);
  color: #c1c1c1;
  color: var(--shell-brand-footer-color, #c1c1c1);
  font-size: 14px;
  font-size: var(--shell-brand-footer-font-size, 14px);
}

.next-shell-brand .next-shell-aside.next-shell-navigation {
  width: 168px;
  width: var(--shell-brand-navigation-ver-width, 168px);
  background: #fff;
  background: var(--shell-brand-navigation-ver-background, #fff);
  border-right: 1px solid #e3e4e6;
  border-right: var(--shell-brand-navigation-ver-divider-size, 1px)
    var(--shell-brand-navigation-ver-divider-style, solid)
    var(--shell-brand-navigation-ver-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-brand-navigation-ver-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-brand-navigation-ver-shadow, none);
  box-shadow: var(--shell-brand-navigation-ver-shadow, none);
  padding: 8px 0;
  padding: var(--shell-brand-navigation-ver-paddingTop, 8px) 0
    var(--shell-brand-navigation-ver-paddingBottom, 8px);
}

.next-shell-brand
  .next-shell-aside.next-shell-navigation.next-shell-collapse.next-shell-mini {
  width: 60px;
  width: var(--shell-brand-navigation-ver-width-mini, 60px);
}

.next-shell-brand .next-shell-aside.next-shell-navigation.next-shell-collapse {
  width: 0;
}

.next-shell-brand .next-shell-aside.next-shell-tooldock {
  width: 52px;
  width: var(--shell-brand-tooldock-width, 52px);
  background: #004080;
  background: var(--shell-brand-tooldock-background, #004080);
  border-left: 1px solid #e3e4e6;
  border-left: var(--shell-brand-tooldock-divider-size, 1px)
    var(--shell-brand-tooldock-divider-style, solid)
    var(--shell-brand-tooldock-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-brand-tooldock-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-brand-tooldock-shadow, none);
  box-shadow: var(--shell-brand-tooldock-shadow, none);
  padding: 8px 0;
  padding: var(--shell-brand-tooldock-paddingTop, 8px) 0
    var(--shell-brand-tooldock-paddingBottom, 8px);
}

.next-shell-brand .next-shell-aside .next-shell-tooldockitem {
  padding: 8px 0;
  padding: var(--shell-brand-tooldock-item-paddingTop, 8px) 0;
  color: #555;
  color: var(--shell-brand-tooldock-item-color, #555);
  background: transparent;
  background: var(--shell-brand-tooldock-item-background, transparent);
}

.next-shell-brand .next-shell-aside .next-shell-tooldockitem:hover {
  color: #111;
  color: var(--shell-brand-tooldock-item-color-hover, #111);
  background: #eff3f8;
  background: var(--shell-brand-tooldock-item-background-hover, #eff3f8);
}

.next-shell-brand .next-shell-aside .next-shell-localnavigation {
  width: 168px;
  width: var(--shell-brand-local-navigation-width, 168px);
  background: #004080;
  background: var(--shell-brand-local-navigation-background, #004080);
  border-right: 1px solid #e3e4e6;
  border-right: var(--shell-brand-local-navigation-divider-size, 1px)
    var(--shell-brand-local-navigation-divider-style, solid)
    var(--shell-brand-local-navigation-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-brand-local-navigation-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-brand-local-navigation-shadow, none);
  box-shadow: var(--shell-brand-local-navigation-shadow, none);
  padding: 8px 0;
  padding: var(--shell-brand-local-navigation-paddingTop, 8px) 0
    var(--shell-brand-local-navigation-paddingBottom, 8px);
}

.next-shell-brand
  .next-shell-aside
  .next-shell-localnavigation.next-shell-collapse {
  width: 0;
}

.next-shell-brand .next-shell-aside .next-shell-ancillary {
  width: 168px;
  width: var(--shell-brand-ancillary-width, 168px);
  background: #fff;
  background: var(--shell-brand-ancillary-background, #fff);
  border-left: 1px solid #e3e4e6;
  border-left: var(--shell-brand-ancillary-divider-size, 1px)
    var(--shell-brand-ancillary-divider-style, solid)
    var(--shell-brand-ancillary-divider-color, #e3e4e6);
  -webkit-box-shadow: none;
  -webkit-box-shadow: var(--shell-brand-ancillary-shadow, none);
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-box-shadow: var(--shell-brand-ancillary-shadow, none);
  box-shadow: var(--shell-brand-ancillary-shadow, none);
  padding: 8px 0;
  padding: var(--shell-brand-ancillary-paddingTop, 8px) 0
    var(--shell-brand-ancillary-paddingBottom, 8px);
}

.next-shell-brand .next-shell-aside .next-shell-ancillary.next-shell-collapse {
  width: 0;
}

.next-shell-header .next-shell-navigation.next-shell-nav-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  justify-content: flex-start;
}

.next-shell-header .next-shell-navigation.next-shell-nav-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  justify-content: flex-end;
}

.next-shell-header .next-shell-navigation.next-shell-nav-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.next-shell.next-shell-phone .next-aside-navigation {
  width: 100%;
}

.next-shell.next-shell-phone .next-aside-navigation.next-shell-collapse {
  width: 0;
}

.next-shell.next-shell-phone .next-shell-header .next-shell-navigation {
  display: none;
}

.next-shell.next-shell-phone .next-shell-navigation {
  width: 100%;
  height: 100%;
  -webkit-transition: height 0.2s ease;
  -o-transition: height 0.2s ease;
  -moz-transition: height 0.2s ease;
  transition: height 0.2s ease;
}

.next-shell.next-shell-phone .next-shell-navigation.next-shell-collapse {
  padding: 0;
  height: 0;
  -webkit-transition: height 0.2s ease;
  -o-transition: height 0.2s ease;
  -moz-transition: height 0.2s ease;
  transition: height 0.2s ease;
}

.next-shell.next-shell-phone .next-shell-tooldock {
  height: 52px;
  height: var(--shell-dark-tooldock-height, 52px);
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

.next-shell.next-shell-phone .next-shell-tooldock.next-shell-collapse {
  display: none;
  height: 0;
  padding: 0;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.next-shell.next-shell-phone .next-shell-aside.next-aside-ancillary,
.next-shell.next-shell-tablet .next-shell-aside.next-aside-ancillary {
  width: 0;
}

.next-shell.next-shell-phone .next-shell-ancillary,
.next-shell.next-shell-tablet .next-shell-ancillary {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.next-shell.next-shell-phone .next-shell-aside.next-aside-localnavigation,
.next-shell.next-shell-tablet .next-shell-aside.next-aside-localnavigation {
  width: 0;
}

.next-notification {
  width: 384px;
  position: fixed;
  z-index: 1010;
  padding: 0;
  margin: 0;
}

.next-notification .next-message {
  margin-bottom: 16px;
  overflow: hidden;
}

.next-notification-fade-leave {
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
}

.next-notification-fade-leave.next-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
  -moz-animation-name: NotificationFadeOut;
  -o-animation-name: NotificationFadeOut;
  animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }

  to {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

@-moz-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }

  to {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

@-o-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }

  to {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }

  to {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

.next-typography {
  color: #333;
  color: var(--typography-text-color, #333);
}

.next-typography-title {
  font-weight: 600;
  font-weight: var(--typography-title-font-weight, 600);
  margin-bottom: 0.5em;
}

.next-typography + .next-typography-title {
  margin-top: 1.2em;
}

.next-typography-paragraph {
  color: #333;
  color: var(--typography-text-color, #333);
  margin-bottom: 1em;
  font-size: 12px;
  font-size: var(--typography-paragraph-font-size, 12px);
  line-height: 1.5;
  line-height: var(--typography-paragraph-line-height, 1.5);
}

.next-typography mark {
  padding: 0;
  background: #fac800;
  background: var(--typography-text-mark-background, #fac800);
  color: #333;
  color: var(--typography-text-mark-color, #333);
}

.next-typography strong {
  font-weight: 600;
  font-weight: var(--typography-text-strong-font-weight, 600);
}

.next-typography code {
  background-color: #eff3f8;
  background-color: var(--typography-text-code-background, #eff3f8);
  color: #333;
  color: var(--typography-text-code-color, #333);
  border: 1px solid #c0c6cc;
  border: 1px solid var(--typography-text-code-border-color, #c0c6cc);
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--typography-text-code-corner, 2px);
  -moz-border-radius: var(--typography-text-code-corner, 2px);
  border-radius: var(--typography-text-code-corner, 2px);
}

.next-typography ol,
.next-typography ul {
  margin: 0 0 1em;
  padding: 0;
}

.next-typography li {
  list-style-type: circle;
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}

.next-typography a {
  text-decoration: none;
}

.next-typography a:link {
  color: #0064c8;
  color: var(--color-link-1, #0064c8);
}

.next-typography a:visited {
  color: #0064c8;
  color: var(--color-link-2, #0064c8);
}

.next-typography a:active,
.next-typography a:hover {
  color: #0064c8;
  color: var(--color-link-3, #0064c8);
}

.next-typography a:active {
  text-decoration: underline;
}

h1.next-typography-title {
  font-size: 48px;
  font-size: var(--typography-title-h1-font-size, 48px);
}

h2.next-typography-title {
  font-size: 36px;
  font-size: var(--typography-title-h2-font-size, 36px);
}

h3.next-typography-title {
  font-size: 24px;
  font-size: var(--typography-title-h3-font-size, 24px);
}

h4.next-typography-title {
  font-size: 20px;
  font-size: var(--typography-title-h4-font-size, 20px);
}

h5.next-typography-title {
  font-size: 16px;
  font-size: var(--typography-title-h5-font-size, 16px);
}

h6.next-typography-title {
  font-size: 14px;
  font-size: var(--typography-title-h6-font-size, 14px);
}

.next-divider {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  list-style: none;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  background: #c0c6cc;
  background: var(--divider-hoz-color, #c0c6cc);
  border-collapse: separate;
}

.next-divider,
.next-divider *,
.next-divider :after,
.next-divider :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-divider-hoz {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  height: var(--divider-hoz-size, 1px);
  margin: 16px 0;
  margin: var(--divider-hoz-marginTop, 16px) 0;
}

.next-divider-ver {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  width: var(--divider-ver-size, 1px);
  background: #c0c6cc;
  background: var(--divider-ver-color, #c0c6cc);
  height: 0.9em;
  margin: 0 8px;
  margin: 0 var(--divider-ver-marginLeft, 8px);
  vertical-align: middle;
}

.next-divider-hoz.next-divider-with-text-center,
.next-divider-hoz.next-divider-with-text-left,
.next-divider-hoz.next-divider-with-text-right {
  display: table;
  margin: 16px 0;
  margin: var(--divider-hoz-text-marginTop, 16px) 0;
  color: #111;
  color: var(--divider-hoz-text-color, #111);
  font-weight: 400;
  font-weight: var(--divider-hoz-text-weight, normal);
  font-size: 14px;
  font-size: var(--divider-hoz-text-size, 14px);
  white-space: nowrap;
  text-align: center;
  background: transparent;
}

.next-divider-hoz.next-divider-with-text-center:after,
.next-divider-hoz.next-divider-with-text-center:before,
.next-divider-hoz.next-divider-with-text-left:after,
.next-divider-hoz.next-divider-with-text-left:before,
.next-divider-hoz.next-divider-with-text-right:after,
.next-divider-hoz.next-divider-with-text-right:before {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid #c0c6cc;
  border-top: var(--divider-hoz-size, 1px) solid
    var(--divider-hoz-color, #c0c6cc);
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  content: "";
}

.next-divider-hoz.next-divider-with-text-center.next-divider-dashed,
.next-divider-hoz.next-divider-with-text-left.next-divider-dashed,
.next-divider-hoz.next-divider-with-text-right.next-divider-dashed {
  border-top: 0;
}

.next-divider-hoz.next-divider-with-text-center.next-divider-dashed:after,
.next-divider-hoz.next-divider-with-text-center.next-divider-dashed:before,
.next-divider-hoz.next-divider-with-text-left.next-divider-dashed:after,
.next-divider-hoz.next-divider-with-text-left.next-divider-dashed:before,
.next-divider-hoz.next-divider-with-text-right.next-divider-dashed:after,
.next-divider-hoz.next-divider-with-text-right.next-divider-dashed:before {
  border-style: dashed none none;
}

.next-divider-hoz.next-divider-with-text-left .next-divider-inner-text,
.next-divider-hoz.next-divider-with-text-right .next-divider-inner-text {
  display: inline-block;
  padding: 0 16px;
  padding: 0 var(--divider-hoz-text-left-paddingLeft, 16px);
}

.next-divider-hoz.next-divider-with-text-left:before {
  top: 50%;
  width: 5%;
}

.next-divider-hoz.next-divider-with-text-left:after,
.next-divider-hoz.next-divider-with-text-right:before {
  top: 50%;
  width: 95%;
}

.next-divider-hoz.next-divider-with-text-right:after {
  top: 50%;
  width: 5%;
}

.next-divider-inner-text {
  display: inline-block;
  padding: 0 16px;
  padding: 0 var(--divider-hoz-text-center-paddingLeft, 16px);
}

.next-divider-dashed {
  background: none;
  border: 0 dashed #c0c6cc;
  border-color: var(--divider-hoz-color, #c0c6cc);
  border-top: var(--divider-hoz-size, 1px) dashed
    var(--divider-hoz-color, #c0c6cc);
}

.next-divider-dashed.next-divider-ver {
  border-width: 0 0 0 1px;
  border-left-width: var(--divider-ver-size, 1px);
  border-bottom-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}

.next-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.next-box,
.next-box *,
.next-box :after,
.next-box :before,
.next-calendar2,
.next-calendar2 *,
.next-calendar2 :after,
.next-calendar2 :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-calendar2 table {
  border-collapse: collapse;
  border-spacing: 0;
}

.next-calendar2 td,
.next-calendar2 th {
  padding: 0;
}

div[dir="rtl"].next-calendar2-card .next-calendar2-header-actions,
div[dir="rtl"].next-calendar2-fullscreen .next-calendar2-header-actions,
div[dir="rtl"].next-calendar2-panel .next-calendar2-header-actions {
  margin-right: auto;
  margin-left: 0;
}

div[dir="rtl"].next-calendar2-card
  .next-calendar2-header-actions
  > :not(:first-child),
div[dir="rtl"].next-calendar2-card
  .next-calendar2-header-ranges
  > :not(:first-child),
div[dir="rtl"].next-calendar2-fullscreen
  .next-calendar2-header-actions
  > :not(:first-child),
div[dir="rtl"].next-calendar2-fullscreen
  .next-calendar2-header-ranges
  > :not(:first-child),
div[dir="rtl"].next-calendar2-panel
  .next-calendar2-header-actions
  > :not(:first-child),
div[dir="rtl"].next-calendar2-panel
  .next-calendar2-header-ranges
  > :not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}

div[dir="rtl"].next-calendar2-fullscreen .next-calendar2-cell-value,
div[dir="rtl"].next-calendar2-fullscreen .next-calendar2-table th {
  text-align: left;
}

div[dir="rtl"].next-calendar2-fullscreen .next-calendar2-table th {
  padding: 0 0 5px 12px;
}

.next-calendar2 {
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
}

.next-calendar2-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.next-calendar2-table {
  width: 100%;
  table-layout: fixed;
}

.next-calendar2-cell {
  cursor: pointer;
  position: relative;
  -webkit-transition: background-color 0.2s, border 0.2s;
  -o-transition: background-color 0.2s, border 0.2s;
  -moz-transition: background-color 0.2s, border 0.2s;
  transition: background-color 0.2s, border 0.2s;
}

.next-calendar2 .next-calendar2-cell-inner {
  color: #c1c1c1;
  color: var(--color-text1-1, #c1c1c1);
  outline: none;
  min-width: 24px;
  position: relative;
  border: 1px solid transparent;
}

.next-calendar2-cell-disabled:before {
  color: #c1c1c1;
  color: var(--color-text1-1, #c1c1c1);
  background: #f8f8f9;
  background: var(--color-fill1-1, #f8f8f9);
}

.next-calendar2-card .next-calendar2-header-actions,
.next-calendar2-fullscreen .next-calendar2-header-actions,
.next-calendar2-panel .next-calendar2-header-actions {
  margin-left: auto;
}

.next-calendar2-card .next-calendar2-header-actions > :not(:first-child),
.next-calendar2-card .next-calendar2-header-ranges > :not(:first-child),
.next-calendar2-fullscreen .next-calendar2-header-actions > :not(:first-child),
.next-calendar2-fullscreen .next-calendar2-header-ranges > :not(:first-child),
.next-calendar2-panel .next-calendar2-header-actions > :not(:first-child),
.next-calendar2-panel .next-calendar2-header-ranges > :not(:first-child) {
  margin-left: 8px;
}

.next-calendar2-card .next-calendar2-header-select-month,
.next-calendar2-card .next-calendar2-header-select-year,
.next-calendar2-fullscreen .next-calendar2-header-select-month,
.next-calendar2-fullscreen .next-calendar2-header-select-year,
.next-calendar2-panel .next-calendar2-header-select-month,
.next-calendar2-panel .next-calendar2-header-select-year {
  min-width: 88px;
}

.next-calendar2-card .next-calendar2-header-select-month .next-input,
.next-calendar2-card .next-calendar2-header-select-year .next-input,
.next-calendar2-fullscreen .next-calendar2-header-select-month .next-input,
.next-calendar2-fullscreen .next-calendar2-header-select-year .next-input,
.next-calendar2-panel .next-calendar2-header-select-month .next-input,
.next-calendar2-panel .next-calendar2-header-select-year .next-input {
  min-width: auto;
}

.next-calendar2-card .next-calendar2-body,
.next-calendar2-fullscreen .next-calendar2-body,
.next-calendar2-panel .next-calendar2-body {
  padding: 8px 0;
}

.next-calendar2-card .next-calendar2-cell-inner,
.next-calendar2-panel .next-calendar2-cell-inner {
  z-index: 2;
  height: 24px;
  line-height: 22px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  display: inline-block;
}

.next-calendar2-card .next-calendar2,
.next-calendar2-panel .next-calendar2 {
  min-height: 150px;
}

.next-calendar2-card .next-calendar2-table thead > tr,
.next-calendar2-panel .next-calendar2-table thead > tr {
  height: 24px;
  color: #888;
  color: var(--color-text1-2, #888);
}

.next-calendar2-card .next-calendar2-table td,
.next-calendar2-card .next-calendar2-table th,
.next-calendar2-panel .next-calendar2-table td,
.next-calendar2-panel .next-calendar2-table th {
  font-weight: 400;
  text-align: center;
  padding: 4px 0;
}

.next-calendar2-card .next-calendar2-table th,
.next-calendar2-panel .next-calendar2-table th {
  height: 32px;
}

.next-calendar2-card .next-calendar2-table-decade,
.next-calendar2-card .next-calendar2-table-month,
.next-calendar2-card .next-calendar2-table-year,
.next-calendar2-panel .next-calendar2-table-decade,
.next-calendar2-panel .next-calendar2-table-month,
.next-calendar2-panel .next-calendar2-table-year {
  height: 145px;
}

.next-calendar2-card .next-calendar2-table-decade .next-calendar2-cell-inner,
.next-calendar2-card .next-calendar2-table-month .next-calendar2-cell-inner,
.next-calendar2-card .next-calendar2-table-year .next-calendar2-cell-inner,
.next-calendar2-panel .next-calendar2-table-decade .next-calendar2-cell-inner,
.next-calendar2-panel .next-calendar2-table-month .next-calendar2-cell-inner,
.next-calendar2-panel .next-calendar2-table-year .next-calendar2-cell-inner {
  min-width: 56px;
}

.next-calendar2-card .next-calendar2-table-quarter,
.next-calendar2-panel .next-calendar2-table-quarter {
  height: 50px;
}

.next-calendar2-card .next-calendar2-table-quarter .next-calendar2-cell-inner,
.next-calendar2-panel .next-calendar2-table-quarter .next-calendar2-cell-inner {
  min-width: 56px;
}

.next-calendar2-card .next-calendar2-table-decade .next-calendar2-cell-inner,
.next-calendar2-panel .next-calendar2-table-decade .next-calendar2-cell-inner {
  min-width: 80px;
}

.next-calendar2-card
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):not(.next-calendar2-cell-selected):not(.next-calendar2-cell-today)
  .next-calendar2-cell-inner,
.next-calendar2-panel
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):not(.next-calendar2-cell-selected):not(.next-calendar2-cell-today)
  .next-calendar2-cell-inner {
  color: #555;
  color: var(--color-text1-3, #555);
}

.next-calendar2-card
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):not(.next-calendar2-cell-selected):not(.next-calendar2-cell-today):hover:not(.next-calendar2-cell-hover)
  .next-calendar2-cell-inner,
.next-calendar2-panel
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):not(.next-calendar2-cell-selected):not(.next-calendar2-cell-today):hover:not(.next-calendar2-cell-hover)
  .next-calendar2-cell-inner {
  background: #f7f9fa;
  background: var(--color-fill1-2, #f7f9fa);
}

.next-calendar2-card
  .next-calendar2-cell-current.next-calendar2-cell-today:not(.next-calendar2-cell-disabled)
  .next-calendar2-cell-inner,
.next-calendar2-panel
  .next-calendar2-cell-current.next-calendar2-cell-today:not(.next-calendar2-cell-disabled)
  .next-calendar2-cell-inner {
  color: #002850;
  color: var(--color-brand1-9, #002850);
}

.next-calendar2-card
  .next-calendar2-cell-current.next-calendar2-cell-selected:not(.next-calendar2-cell-disabled)
  .next-calendar2-cell-inner,
.next-calendar2-panel
  .next-calendar2-cell-current.next-calendar2-cell-selected:not(.next-calendar2-cell-disabled)
  .next-calendar2-cell-inner {
  color: #fff;
  background: #002850;
  background: var(--color-brand1-9, #002850);
}

.next-calendar2-fullscreen .next-calendar2-cell-value,
.next-calendar2-fullscreen .next-calendar2-table th {
  text-align: right;
}

.next-calendar2-fullscreen .next-calendar2-table th {
  padding: 0 12px 5px 0;
}

.next-calendar2-fullscreen .next-calendar2-cell-inner {
  margin: 0 4px;
  padding: 4px 8px 0;
}

.next-calendar2-fullscreen .next-calendar2-cell-inner,
.next-calendar2-fullscreen td .next-calendar2-cell-inner {
  height: 80px;
  border-top: 2px solid #e3e4e6;
  border-top: 2px solid var(--color-line1-1, #e3e4e6);
}

.next-calendar2-fullscreen
  .next-calendar2-cell-disabled
  .next-calendar2-cell-inner {
  color: #c1c1c1;
  color: var(--color-text1-1, #c1c1c1);
  background: #f8f8f9;
  background: var(--color-fill1-1, #f8f8f9);
}

.next-calendar2-fullscreen
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):not(.next-calendar2-cell-selected):not(.next-calendar2-cell-today)
  .next-calendar2-cell-inner {
  color: #555;
  color: var(--color-text1-3, #555);
}

.next-calendar2-fullscreen
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):not(.next-calendar2-cell-selected):not(.next-calendar2-cell-today):hover
  .next-calendar2-cell-inner {
  background-color: #f7f9fa;
  background-color: var(--color-fill1-2, #f7f9fa);
}

.next-calendar2-fullscreen
  .next-calendar2-cell-current.next-calendar2-cell-today
  .next-calendar2-cell-inner {
  color: #002850;
  color: var(--color-brand1-9, #002850);
}

.next-calendar2-fullscreen
  .next-calendar2-cell-current
  .next-calendar2-cell-inner {
  background-color: #fff;
}

.next-calendar2-fullscreen
  .next-calendar2-cell-current.next-calendar2-cell-selected:not(.next-calendar2-cell-disabled)
  .next-calendar2-cell-inner {
  border-top-color: #0064c8;
  border-top-color: var(--color-brand1-6, #0064c8);
  font-weight: 700;
  color: #0064c8;
  color: var(--color-brand1-6, #0064c8);
  background: #f7f9fa;
  background: var(--color-brand1-1, #f7f9fa);
}

.next-calendar2-card .next-calendar2-header {
  padding: 8px;
  border-bottom: 1px solid #e3e4e6;
  border-bottom: 1px solid var(--color-line1-1, #e3e4e6);
}

.next-calendar2-panel .next-calendar2-header {
  padding: 0 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  border-bottom: 1px solid #e3e4e6;
  border-bottom: 1px solid var(--color-line1-1, #e3e4e6);
}

.next-calendar2-panel .next-calendar2-header-btn {
  min-width: 20px;
  line-height: 20px;
  color: #555;
  color: var(--color-text1-3, #555);
  font-family: inherit;
  vertical-align: initial;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.next-calendar2-panel .next-calendar2-header-btn > span,
.next-calendar2-panel .next-calendar2-header-text-field {
  text-align: center;
  font-size: 14px;
  color: #111;
  color: var(--color-text1-4, #111);
  font-weight: bolder;
  vertical-align: initial;
}

.next-calendar2-panel .next-calendar2-header-btn:hover,
.next-calendar2-panel .next-calendar2-header-btn:hover > span {
  color: #002850;
  color: var(--color-brand1-9, #002850);
}

.next-calendar2-panel .next-calendar2-header-left-btn:hover,
.next-calendar2-panel .next-calendar2-header-right-btn:hover {
  background: #f7f9fa;
  background: var(--color-fill1-2, #f7f9fa);
}

.next-calendar2-panel .next-calendar2-header-text-field {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  flex: 1;
  height: 38px;
  line-height: 38px;
}

.next-calendar2-panel
  .next-calendar2-header-text-field
  .next-calendar2-header-btn:not(:first-child) {
  margin-left: 6px;
}

.next-calendar2-header-select-month-popup,
.next-calendar2-header-select-year-popup {
  min-width: auto;
}

.next-time-picker2-menu {
  float: left;
  text-align: center;
  padding: 8px 0;
}

.next-time-picker2-menu:not(:last-child) {
  border-right: 1px solid #c0c6cc;
  border-right: var(--line-1, 1px) var(--line-solid, solid)
    var(--color-line1-2, #c0c6cc);
}

.next-time-picker2-menu-title {
  cursor: default;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  font-size: var(--font-size-caption, 12px);
  font-weight: 400;
  font-weight: var(--font-weight-2, normal);
  color: #888;
  color: var(--color-text1-2, #888);
  background: #fff;
}

.next-time-picker2-menu ul {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  list-style: none;
  margin: 0;
  width: 54px;
  padding: 0;
  font-size: 12px;
  font-size: var(--font-size-caption, 12px);
  height: 224px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.next-time-picker2-menu ul::-webkit-scrollbar {
  width: 0;
}

.next-time-picker2-menu ul:hover {
  overflow-y: auto;
}

.next-time-picker2-menu ul:after {
  display: block;
  height: 192px;
  content: "";
}

.next-time-picker2-menu-item {
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  -webkit-transition: background 0.1s linear;
  -o-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  transition: background 0.1s linear;
  color: #555;
  color: var(--color-text1-3, #555);
  background: #fff;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.next-time-picker2-menu-item:hover {
  color: #111;
  color: var(--color-text1-4, #111);
  background: #f7f9fa;
  background: var(--color-fill1-2, #f7f9fa);
}

.next-time-picker2-menu-item.next-selected {
  color: #555;
  color: var(--color-text1-3, #555);
  background: #f7f9fa;
  background: var(--color-brand1-1, #f7f9fa);
}

.next-time-picker2-menu-item.next-disabled {
  cursor: not-allowed;
  color: #c1c1c1;
  color: var(--color-text1-1, #c1c1c1);
  background: #f8f8f9;
  background: var(--color-fill1-1, #f8f8f9);
}

.next-time-picker2-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.next-time-picker2-panel,
.next-time-picker2-panel *,
.next-time-picker2-panel :after,
.next-time-picker2-panel :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-time-picker2-panel:after {
  visibility: hidden;
  display: block;
  height: 0;
  font-size: 0;
  content: " ";
  clear: both;
}

.next-time-picker2-panel-header {
  border-bottom: 1px solid #c0c6cc;
  border-bottom: var(--line-1, 1px) var(--line-solid, solid)
    var(--popup-local-border-color, #c0c6cc);
}

.next-time-picker2-panel-input.next-input {
  width: 100%;
  padding: 6px;
  border-color: transparent;
  vertical-align: middle;
}

.next-time-picker2-panel .next-time-picker2-menu {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  flex: 1;
}

.next-time-picker2-footer {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  padding: 4px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  min-height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.next-time-picker2-footer-actions {
  margin-left: auto;
}

.next-time-picker2-wrapper[dir="rtl"] .next-time-picker2-menu {
  float: right;
}

.next-time-picker2-wrapper[dir="rtl"] .next-time-picker2-menu:not(:last-child) {
  border-right: none;
  border-left: 1px solid #c0c6cc;
  border-left: var(--line-1, 1px) var(--line-solid, solid)
    var(--color-line1-2, #c0c6cc);
}

.next-time-picker2 {
  display: inline-block;
}

.next-time-picker2,
.next-time-picker2 *,
.next-time-picker2 :after,
.next-time-picker2 :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.next-time-picker2-trigger .next-input {
  width: 100%;
}

.next-time-picker2-wrapper {
  padding: 4px 0;
}

.next-time-picker2-body {
  overflow: hidden;
  border: 1px solid #c0c6cc;
  border: var(--popup-local-border-width, 1px)
    var(--popup-local-border-style, solid)
    var(--popup-local-border-color, #c0c6cc);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--popup-local-corner, 2px);
  -moz-border-radius: var(--popup-local-corner, 2px);
  border-radius: var(--popup-local-corner, 2px);
  background: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: var(
    --popup-local-shadow,
    0 2px 4px 0 rgba(0, 0, 0, 0.16)
  );
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
  box-shadow: var(--popup-local-shadow, 0 2px 4px 0 rgba(0, 0, 0, 0.16));
}

.next-time-picker2-symbol-clock-icon:before {
  content: "\E621";
  content: var(--icon-content-clock, "\E621");
}

div[dir="rtl"] .next-date-picker2-footer-preset > .next-btn {
  margin-left: 8px;
  margin-right: 0;
}

div[dir="rtl"] .next-date-picker2-footer-actions {
  margin-left: 0;
  margin-right: auto;
}

div[dir="rtl"]
  .next-date-picker2-wrapper
  .next-calendar2-cell:last-child:before {
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  right: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  left: 8px;
}

div[dir="rtl"]
  .next-date-picker2-wrapper
  .next-calendar2-cell:first-child:before {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  left: 0;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  right: 8px;
}

div[dir="rtl"] .next-date-time-picker-wrapper {
  border-right: 1px solid #e3e4e6;
  border-right: 1px solid var(--color-line1-1, #e3e4e6);
  border-left: node;
}

div[dir="rtl"]
  .next-date-time-picker-wrapper
  .next-time-picker2-menu:not(:last-child) {
  border-left: 1px solid #dcdee3;
  border-right: none;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected.next-calendar2-cell-range-begin:before {
  right: 50%;
  left: 0;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected.next-calendar2-cell-range-end:before {
  left: 50%;
  right: 0;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-hover-begin:after,
div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:first-child:after {
  right: 8px;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-hover-begin:not(:last-child):after,
div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:first-child:not(.next-calendar2-cell-hover-end):after {
  left: 0;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-hover-end:after,
div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:last-child:after {
  left: 8px;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-hover-end:not(:first-child):after,
div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:last-child:not(.next-calendar2-cell-hover-begin):after {
  right: 0;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-selected.next-calendar2-cell-hover-begin:after {
  left: 0;
  right: 7px;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-selected.next-calendar2-cell-hover-end:after {
  right: 0;
  left: 7px;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:first-of-type:after {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  border-right: 1px dashed #002850;
  border-right: 1px dashed var(--color-brand1-9, #002850);
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:last-of-type:after {
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  border-left: 1px dashed #002850;
  border-left: 1px dashed var(--color-brand1-9, #002850);
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-edge-end:after,
div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-edge-end:before {
  right: 0;
  left: 8px;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-edge-end.next-calendar2-cell-hover:after {
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  border-left: 1px dashed #002850;
  border-left: 1px dashed var(--color-brand1-9, #002850);
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover-begin:after {
  border-left: none;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  border-right: 1px dashed #002850;
  border-right: 1px dashed var(--color-brand1-9, #002850);
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover-end:after {
  border-right: none;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  border-left: 1px dashed #002850;
  border-left: 1px dashed var(--color-brand1-9, #002850);
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-edge-end
  + .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):after,
div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-edge-end
  + .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):before {
  right: 8px;
  left: 0;
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-edge-end
  + .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:after {
  right: 8px;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  border-right: 1px dashed #002850;
  border-right: 1px dashed var(--color-brand1-9, #002850);
}

div[dir="rtl"]
  .next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-edge-end
  + .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:not(.next-calendar2-cell-hover-end):not(.next-calendar2-cell-hover-begin):after {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

div[dir="rtl"]
  .next-calendar2-table-week
  .next-calendar2-week-current
  .next-calendar2-cell.next-calendar2-cell-selected:nth-child(2):before {
  right: 50%;
  left: 0;
}

div[dir="rtl"]
  .next-calendar2-table-week
  .next-calendar2-week-current
  .next-calendar2-cell.next-calendar2-cell-selected:last-child:before {
  left: 50%;
  right: 0;
}

.next-date-picker2 {
  outline: none;
  display: inline-table;
  position: relative;
  width: inherit;
}

.next-date-picker2-footer {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  border-top: 1px solid #e3e4e6;
  border-top: 1px solid var(--color-line1-1, #e3e4e6);
  padding: 4px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  min-height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.next-date-picker2-footer-preset > .next-btn {
  margin-right: 8px;
}

.next-date-picker2-footer-actions {
  margin-left: auto;
}

.next-date-picker2-footer-preset-only {
  width: 100%;
}

.next-date-picker2-overlay {
  vertical-align: top;
  padding: 4px 0;
}

.next-date-picker2-overlay-range {
  padding: 12px 0;
}

.next-date-picker2-wrapper {
  -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border: 1px solid #e3e4e6;
  border: 1px solid var(--color-line1-1, #e3e4e6);
  border-radius: 2px;
  border-radius: var(--corner-1, 2px);
}

.next-date-picker2-wrapper,
.next-date-picker2-wrapper .next-calendar2-panel {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: var(--corner-1, 2px);
  -moz-border-radius: var(--corner-1, 2px);
}

.next-date-picker2-wrapper .next-calendar2-panel {
  border-radius: 2px;
  border-radius: var(--corner-1, 2px);
}

.next-date-picker2-wrapper .next-calendar2-body {
  width: 272px;
}

.next-date-picker2-wrapper .next-calendar2-cell:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.next-date-picker2-wrapper .next-calendar2-cell:last-child:before {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  right: 8px;
}

.next-date-picker2-wrapper .next-calendar2-cell:first-child:before {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  left: 8px;
}

.next-date-picker2-input {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #c0c6cc;
  border: var(--input-border-width, 1px) solid
    var(--input-border-color, #c0c6cc);
  vertical-align: middle;
  width: inherit;
}

.next-date-picker2-input .next-input {
  border: none;
  width: 100%;
  height: 100%;
}

.next-date-picker2-input .next-input input {
  height: 100%;
}

.next-date-picker2-input.next-date-picker2-input-small {
  height: 24px;
  height: var(--form-element-small-height, 24px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-small-corner, 2px);
  -moz-border-radius: var(--form-element-small-corner, 2px);
  border-radius: var(--form-element-small-corner, 2px);
}

.next-date-picker2-input.next-date-picker2-input-small .next-input-label {
  padding-left: 8px;
  padding-left: var(--input-s-label-padding-left, 8px);
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-small .next-input-inner {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-small .next-input-control {
  padding-right: 4px;
  padding-right: var(--input-s-icon-padding-right, 4px);
}

.next-date-picker2-input.next-date-picker2-input-small input {
  height: 22px;
  height: -webkit-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  line-height: 22px \0;
  line-height: -webkit-calc(
      var(--form-element-small-height, 24px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: -moz-calc(
      var(--form-element-small-height, 24px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: calc(
      var(--form-element-small-height, 24px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  padding: 0 12px;
  padding: 0 var(--input-s-padding, 12px);
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-small
  input::-webkit-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-small input::-moz-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-small
  input::-ms-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-small input:-moz-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-small
  input:-ms-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-small input::placeholder {
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-small .next-input-text-field {
  padding: 0 12px;
  padding: 0 var(--input-s-padding, 12px);
  font-size: 12px;
  font-size: var(--form-element-small-font-size, 12px);
  height: 22px;
  height: -webkit-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  line-height: 22px;
  line-height: -webkit-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
  line-height: calc(
    var(--form-element-small-height, 24px) - var(--input-border-width, 1px) * 2
  );
}

.next-date-picker2-input.next-date-picker2-input-small
  .next-icon
  .next-icon-remote,
.next-date-picker2-input.next-date-picker2-input-small .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-date-picker2-input.next-date-picker2-input-small .next-input-control {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--form-element-small-corner, 2px)
    var(--form-element-small-corner, 2px) 0;
  -moz-border-radius: 0 var(--form-element-small-corner, 2px)
    var(--form-element-small-corner, 2px) 0;
  border-radius: 0 var(--form-element-small-corner, 2px)
    var(--form-element-small-corner, 2px) 0;
}

.next-date-picker2-input.next-date-picker2-input-medium {
  height: 32px;
  height: var(--form-element-medium-height, 32px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-medium-corner, 2px);
  -moz-border-radius: var(--form-element-medium-corner, 2px);
  border-radius: var(--form-element-medium-corner, 2px);
}

.next-date-picker2-input.next-date-picker2-input-medium .next-input-label {
  padding-left: 16px;
  padding-left: var(--input-m-label-padding-left, 16px);
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-medium .next-input-inner {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-medium .next-input-control {
  padding-right: 8px;
  padding-right: var(--input-m-icon-padding-right, 8px);
}

.next-date-picker2-input.next-date-picker2-input-medium input {
  height: 30px;
  height: -webkit-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  line-height: 30px \0;
  line-height: -webkit-calc(
      var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: -moz-calc(
      var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: calc(
      var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  padding: 0 12px;
  padding: 0 var(--input-m-padding, 12px);
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-medium
  input::-webkit-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-medium
  input::-moz-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-medium
  input::-ms-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-medium input:-moz-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-medium
  input:-ms-input-placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-medium input::placeholder {
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
}

.next-date-picker2-input.next-date-picker2-input-medium .next-input-text-field {
  padding: 0 12px;
  padding: 0 var(--input-m-padding, 12px);
  font-size: 12px;
  font-size: var(--form-element-medium-font-size, 12px);
  height: 30px;
  height: -webkit-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  line-height: 30px;
  line-height: -webkit-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
  line-height: calc(
    var(--form-element-medium-height, 32px) - var(--input-border-width, 1px) * 2
  );
}

.next-date-picker2-input.next-date-picker2-input-medium
  .next-icon
  .next-icon-remote,
.next-date-picker2-input.next-date-picker2-input-medium .next-icon:before {
  width: 12px;
  font-size: 12px;
  line-height: inherit;
}

.next-date-picker2-input.next-date-picker2-input-medium .next-input-control {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--form-element-medium-corner, 2px)
    var(--form-element-medium-corner, 2px) 0;
  -moz-border-radius: 0 var(--form-element-medium-corner, 2px)
    var(--form-element-medium-corner, 2px) 0;
  border-radius: 0 var(--form-element-medium-corner, 2px)
    var(--form-element-medium-corner, 2px) 0;
}

.next-date-picker2-input.next-date-picker2-input-large {
  height: 36px;
  height: var(--form-element-large-height, 36px);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: var(--form-element-large-corner, 2px);
  -moz-border-radius: var(--form-element-large-corner, 2px);
  border-radius: var(--form-element-large-corner, 2px);
}

.next-date-picker2-input.next-date-picker2-input-large .next-input-label {
  padding-left: 16px;
  padding-left: var(--input-l-label-padding-left, 16px);
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-date-picker2-input.next-date-picker2-input-large .next-input-inner {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-date-picker2-input.next-date-picker2-input-large .next-input-control {
  padding-right: 12px;
  padding-right: var(--input-l-icon-padding-right, 12px);
}

.next-date-picker2-input.next-date-picker2-input-large input {
  height: 34px;
  height: -webkit-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  line-height: 34px \0;
  line-height: -webkit-calc(
      var(--form-element-large-height, 36px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: -moz-calc(
      var(--form-element-large-height, 36px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  line-height: calc(
      var(--form-element-large-height, 36px) - var(--input-border-width, 1px) *
        2
    )
    \0;
  padding: 0 12px;
  padding: 0 var(--input-l-padding, 12px);
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-date-picker2-input.next-date-picker2-input-large
  input::-webkit-input-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-date-picker2-input.next-date-picker2-input-large input::-moz-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-date-picker2-input.next-date-picker2-input-large
  input::-ms-input-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-date-picker2-input.next-date-picker2-input-large input:-moz-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-date-picker2-input.next-date-picker2-input-large
  input:-ms-input-placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-date-picker2-input.next-date-picker2-input-large input::placeholder {
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
}

.next-date-picker2-input.next-date-picker2-input-large .next-input-text-field {
  padding: 0 12px;
  padding: 0 var(--input-l-padding, 12px);
  font-size: 14px;
  font-size: var(--form-element-large-font-size, 14px);
  height: 34px;
  height: -webkit-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  height: -moz-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  height: calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  line-height: 34px;
  line-height: -webkit-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  line-height: -moz-calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
  line-height: calc(
    var(--form-element-large-height, 36px) - var(--input-border-width, 1px) * 2
  );
}

.next-date-picker2-input.next-date-picker2-input-large
  .next-icon
  .next-icon-remote,
.next-date-picker2-input.next-date-picker2-input-large .next-icon:before {
  width: 16px;
  font-size: 16px;
  line-height: inherit;
}

.next-date-picker2-input.next-date-picker2-input-large .next-input-control {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 var(--form-element-large-corner, 2px)
    var(--form-element-large-corner, 2px) 0;
  -moz-border-radius: 0 var(--form-element-large-corner, 2px)
    var(--form-element-large-corner, 2px) 0;
  border-radius: 0 var(--form-element-large-corner, 2px)
    var(--form-element-large-corner, 2px) 0;
}

.next-date-picker2-input:hover {
  border-color: #c0c6cc;
  border-color: var(--input-hover-border-color, #c0c6cc);
  background-color: #fff;
  background-color: var(--input-hover-bg-color, #fff);
}

.next-date-picker2-input.next-date-picker2-input-focus {
  border-color: #0064c8;
  border-color: var(--input-focus-border-color, #0064c8);
  background-color: #fff;
  background-color: var(--input-focus-bg-color, #fff);
  -webkit-box-shadow: 0 0 0 0 rgba(0, 100, 200, 0.2);
  -moz-box-shadow: 0 0 0 0 rgba(0, 100, 200, 0.2);
  box-shadow: 0 0 0 0 rgba(0, 100, 200, 0.2);
}

.next-date-picker2-input.next-date-picker2-input-noborder {
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.next-date-picker2-input.next-date-picker2-input-disabled {
  color: #ccc;
  color: var(--input-disabled-color, #ccc);
  cursor: not-allowed;
}

.next-date-picker2-input.next-date-picker2-input-disabled,
.next-date-picker2-input.next-date-picker2-input-disabled:hover {
  border-color: #c0c6cc;
  border-color: var(--input-disabled-border-color, #c0c6cc);
  background-color: #f7f9fa;
  background-color: var(--input-disabled-bg-color, #f7f9fa);
}

.next-date-picker2-input-separator {
  color: #c0c6cc;
  color: var(--input-border-color, #c0c6cc);
  font-size: 12px;
  display: inline-block;
  min-width: 16px;
  text-align: center;
}

.next-date-picker2-panel,
.next-range-picker2-panel {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: inline-flex;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-range-picker-left
  .next-calendar2-header-right-btn,
.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-range-picker-right
  .next-calendar2-header-left-btn {
  visibility: hidden;
}

.next-range-picker2-arrow {
  display: block;
  -webkit-transform: translateY(-50%) rotate(-45deg);
  -moz-transform: translateY(-50%) rotate(-45deg);
  -o-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);
  position: absolute;
  z-index: 1;
  width: 10px;
  height: 10px;
  margin-left: 16.5px;
  border-color: #e3e4e6 #e3e4e6 transparent transparent;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 1px solid var(--color-line1-1, #e3e4e6);
  border-top: 1px solid var(--color-line1-1, #e3e4e6);
  -webkit-transition: left 0.3s ease-out;
  -o-transition: left 0.3s ease-out;
  -moz-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
  background: #fff;
}

.next-date-picker2-tl-bl .next-range-picker2-arrow {
  top: 12.5px;
}

.next-date-picker2-bl-tl .next-range-picker2-arrow {
  bottom: 13px;
  -webkit-transform: translateY(50%) rotate(135deg);
  -moz-transform: translateY(50%) rotate(135deg);
  -o-transform: translateY(50%) rotate(135deg);
  transform: translateY(50%) rotate(135deg);
}

.next-date-time-picker-wrapper {
  border-left: 1px solid #e3e4e6;
  border-left: 1px solid var(--color-line1-1, #e3e4e6);
}

.next-date-time-picker-wrapper .next-calendar2-body {
  padding-right: 0;
  padding-left: 0;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-disabled
  .next-calendar2-cell-inner {
  color: #c1c1c1;
  color: var(--color-text1-1, #c1c1c1);
  background: #f8f8f9;
  background: var(--color-fill1-1, #f8f8f9);
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected:before {
  color: #555;
  color: var(--color-text1-3, #555);
  background: #f7f9fa;
  background: var(--color-brand1-1, #f7f9fa);
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected
  .next-calendar2-cell-inner {
  color: #555;
  color: var(--color-text1-3, #555);
  background: transparent;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected.next-calendar2-cell-range-begin
  .next-calendar2-cell-inner,
.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected.next-calendar2-cell-range-end
  .next-calendar2-cell-inner {
  color: #fff;
  background: #002850;
  background: var(--color-brand1-9, #002850);
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected.next-calendar2-cell-range-begin:before {
  left: 50%;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected.next-calendar2-cell-range-end:before {
  right: 50%;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected.next-calendar2-cell-range-begin-single:before,
.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-selected.next-calendar2-cell-range-end-single:before {
  display: none;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 2;
  height: 24px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  border-color: #002850 transparent;
  border-left: 1px dashed transparent;
  border-bottom: 1px dashed var(--color-brand1-9, #002850);
  border-right: 1px dashed transparent;
  border-top: 1px dashed var(--color-brand1-9, #002850);
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-hover-begin:after,
.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:first-child:after {
  left: 8px;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-hover-end:after,
.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:last-child:after {
  right: 8px;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-selected.next-calendar2-cell-hover-begin:after {
  left: 7px;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover.next-calendar2-cell-selected.next-calendar2-cell-hover-end:after {
  right: 7px;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:first-of-type:after {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  border-left: 1px dashed #002850;
  border-left: 1px dashed var(--color-brand1-9, #002850);
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:last-of-type:after {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  border-right: 1px dashed #002850;
  border-right: 1px dashed var(--color-brand1-9, #002850);
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-edge-end:after,
.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-edge-end:before {
  right: 8px;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-edge-end.next-calendar2-cell-hover:after {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  border-right: 1px dashed #002850;
  border-right: 1px dashed var(--color-brand1-9, #002850);
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover-begin:after {
  border-top: 1px dashed #002850;
  border-top: 1px dashed var(--color-brand1-9, #002850);
  border-left: 1px dashed #002850;
  border-left: 1px dashed var(--color-brand1-9, #002850);
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover-end:after {
  border-top: 1px dashed #002850;
  border-top: 1px dashed var(--color-brand1-9, #002850);
  border-right: 1px dashed #002850;
  border-right: 1px dashed var(--color-brand1-9, #002850);
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-edge-end
  + .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):after,
.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-edge-end
  + .next-calendar2-cell-current:not(.next-calendar2-cell-disabled):before {
  left: 8px;
}

.next-range-picker2-panel:not(.next-range-picker2-panel-single)
  .next-calendar2-cell-edge-end
  + .next-calendar2-cell-current:not(.next-calendar2-cell-disabled).next-calendar2-cell-hover:after {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
  border-left: 1px dashed #002850;
  border-left: 1px dashed var(--color-brand1-9, #002850);
}

.next-calendar2-table-week .next-calendar2-cell-hover:after {
  display: none;
}

.next-calendar2-table-week
  tr:hover
  .next-calendar2-cell:not(.next-calendar2-cell-disabled):not(.next-calendar2-cell-selected):before {
  background: #f7f9fa;
  background: var(--color-fill1-2, #f7f9fa);
}

.next-calendar2-table-week
  .next-calendar2-week-current
  .next-calendar2-cell.next-calendar2-cell-selected
  .next-calendar2-cell-inner,
.next-calendar2-table-week
  .next-calendar2-week-current
  .next-calendar2-cell.next-calendar2-cell-selected:before {
  color: #555;
  color: var(--color-text1-3, #555);
  background-color: #f7f9fa;
  background-color: var(--color-brand1-1, #f7f9fa);
}

.next-calendar2-table-week
  .next-calendar2-week-current
  .next-calendar2-cell.next-calendar2-cell-selected:last-child
  .next-calendar2-cell-inner,
.next-calendar2-table-week
  .next-calendar2-week-current
  .next-calendar2-cell.next-calendar2-cell-selected:nth-child(2)
  .next-calendar2-cell-inner {
  color: #fff;
  background: #002850;
  background: var(--color-brand1-9, #002850);
}

.next-calendar2-table-week
  .next-calendar2-week-current
  .next-calendar2-cell.next-calendar2-cell-selected:nth-child(2):before {
  left: 50%;
}

.next-calendar2-table-week
  .next-calendar2-week-current
  .next-calendar2-cell.next-calendar2-cell-selected:last-child:before {
  right: 50%;
}

.next-calendar2-table-week
  tr:not(.next-calendar2-week-current)
  td.next-calendar2-cell.next-calendar2-cell-selected:not(.next-calendar2-cell-disabled)
  .next-calendar2-cell-inner,
.next-calendar2-table-week
  tr:not(.next-calendar2-week-current)
  td.next-calendar2-cell.next-calendar2-cell-selected:not(.next-calendar2-cell-disabled):before {
  background-color: transparent;
  color: #c1c1c1;
  color: var(--color-text1-1, #c1c1c1);
}

.next-range-picker2-panel
  .next-calendar2-week-current
  .next-calendar2-cell-selected:not(.next-calendar2-cell-disabled):last-child
  .next-calendar2-cell-inner,
.next-range-picker2-panel
  .next-calendar2-week-current
  .next-calendar2-cell-selected:not(.next-calendar2-cell-disabled):nth-child(2)
  .next-calendar2-cell-inner {
  background-color: #f7f9fa;
  background-color: var(--color-brand1-1, #f7f9fa);
  color: #555;
  color: var(--color-text1-3, #555);
}

.next-range-picker2-panel
  .next-calendar2-week-current
  .next-calendar2-cell-selected:not(.next-calendar2-cell-disabled).next-calendar2-cell-week-range-begin:last-child
  .next-calendar2-cell-inner,
.next-range-picker2-panel
  .next-calendar2-week-current
  .next-calendar2-cell-selected:not(.next-calendar2-cell-disabled).next-calendar2-cell-week-range-begin:nth-child(2)
  .next-calendar2-cell-inner,
.next-range-picker2-panel
  .next-calendar2-week-current
  .next-calendar2-cell-selected:not(.next-calendar2-cell-disabled).next-calendar2-cell-week-range-end:last-child
  .next-calendar2-cell-inner,
.next-range-picker2-panel
  .next-calendar2-week-current
  .next-calendar2-cell-selected:not(.next-calendar2-cell-disabled).next-calendar2-cell-week-range-end:nth-child(2)
  .next-calendar2-cell-inner {
  color: #fff;
  background: #002850;
  background: var(--color-brand1-9, #002850);
}

.next-range-scale-item.next-range-scale-item.next-range-scale-item.next-range-scale-item {
  top: var(--range-scale-item-position-top, 0);
  width: var(--range-scale-item-width, 1px);
}

.next-range .next-range-slider-inner:hover,
.next-range
  .next-range-slider-moving
  .next-range-slider-inner.next-range-slider-inner.next-range-slider-inner {
  -webkit-transform: scale(1.38);
  -moz-transform: scale(1.38);
  -o-transform: scale(1.38);
  transform: scale(1.38);
}

.next-timeline-item.next-timeline-item-done .next-timeline-item-dot {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: var(--timeline-item-done-background, #c0c6cc);
  border: var(--timeline-item-done-border);
}

.next-balloon.next-balloon.next-balloon:after {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-balloon.next-balloon-bottom-left:after,
.next-balloon.next-balloon-bottom-right:after,
.next-balloon.next-balloon-bottom:after {
  -webkit-border-bottom-right-radius: var(--balloon-arrow-border-radius, 2px);
  -moz-border-radius-bottomright: var(--balloon-arrow-border-radius, 2px);
  border-bottom-right-radius: var(--balloon-arrow-border-radius, 2px);
}

.next-balloon.next-balloon-top-left:after,
.next-balloon.next-balloon-top-right:after,
.next-balloon.next-balloon-top:after {
  -webkit-border-top-left-radius: var(--balloon-arrow-border-radius, 2px);
  -moz-border-radius-topleft: var(--balloon-arrow-border-radius, 2px);
  border-top-left-radius: var(--balloon-arrow-border-radius, 2px);
}

.next-balloon.next-balloon-right-bottom:after,
.next-balloon.next-balloon-right-top:after,
.next-balloon.next-balloon-right:after {
  -webkit-border-top-right-radius: var(--balloon-arrow-border-radius, 2px);
  -moz-border-radius-topright: var(--balloon-arrow-border-radius, 2px);
  border-top-right-radius: var(--balloon-arrow-border-radius, 2px);
}

.next-balloon.next-balloon-left-bottom:after,
.next-balloon.next-balloon-left-top:after,
.next-balloon.next-balloon-left:after {
  -webkit-border-bottom-left-radius: var(--balloon-arrow-border-radius, 2px);
  -moz-border-radius-bottomleft: var(--balloon-arrow-border-radius, 2px);
  border-bottom-left-radius: var(--balloon-arrow-border-radius, 2px);
}

.next-balloon-tooltip.next-balloon-tooltip.next-balloon-tooltip:after {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-balloon-tooltip.next-balloon-tooltip-bottom-left:after,
.next-balloon-tooltip.next-balloon-tooltip-bottom-right:after,
.next-balloon-tooltip.next-balloon-tooltip-bottom:after {
  -webkit-border-bottom-right-radius: var(--balloon-arrow-border-radius, 2px);
  -moz-border-radius-bottomright: var(--balloon-arrow-border-radius, 2px);
  border-bottom-right-radius: var(--balloon-arrow-border-radius, 2px);
}

.next-balloon-tooltip.next-balloon-tooltip-top-left:after,
.next-balloon-tooltip.next-balloon-tooltip-top-right:after,
.next-balloon-tooltip.next-balloon-tooltip-top:after {
  -webkit-border-top-left-radius: var(--balloon-arrow-border-radius, 2px);
  -moz-border-radius-topleft: var(--balloon-arrow-border-radius, 2px);
  border-top-left-radius: var(--balloon-arrow-border-radius, 2px);
}

.next-balloon-tooltip.next-balloon-tooltip-right-bottom:after,
.next-balloon-tooltip.next-balloon-tooltip-right-top:after,
.next-balloon-tooltip.next-balloon-tooltip-right:after {
  -webkit-border-top-right-radius: var(--balloon-arrow-border-radius, 2px);
  -moz-border-radius-topright: var(--balloon-arrow-border-radius, 2px);
  border-top-right-radius: var(--balloon-arrow-border-radius, 2px);
}

.next-balloon-tooltip.next-balloon-tooltip-left-bottom:after,
.next-balloon-tooltip.next-balloon-tooltip-left-top:after,
.next-balloon-tooltip.next-balloon-tooltip-left:after {
  -webkit-border-bottom-left-radius: var(--balloon-arrow-border-radius, 2px);
  -moz-border-radius-bottomleft: var(--balloon-arrow-border-radius, 2px);
  border-bottom-left-radius: var(--balloon-arrow-border-radius, 2px);
}

.next-btn {
  font-weight: var(--btn-font-weight, 400);
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
}

.next-btn[disabled]:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-btn-primary:focus-visible,
.next-btn-primary:hover {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.next-btn-primary:active {
  -webkit-box-shadow: var(--btn-primary-active-shadow);
  -moz-box-shadow: var(--btn-primary-active-shadow);
  box-shadow: var(--btn-primary-active-shadow);
}

.next-btn.next-btn-text:active,
.next-btn.next-btn-text:focus-visible,
.next-btn.next-btn-text:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-btn-normal:focus-visible,
.next-btn-normal:hover {
  -webkit-box-shadow: var(--btn-normal-hover-shadow);
  -moz-box-shadow: var(--btn-normal-hover-shadow);
  box-shadow: var(--btn-normal-hover-shadow);
}

.next-btn-normal:active {
  -webkit-box-shadow: var(--btn-normal-active-shadow);
  -moz-box-shadow: var(--btn-normal-active-shadow);
  box-shadow: var(--btn-normal-active-shadow);
}

.next-btn.next-small:not(.isOnlyIcon):not(.next-btn-text) {
  min-width: var(--btn-small-min-width, 62px);
}

.next-btn.next-small.isTwoToThreeCNCharBtn:not(.next-btn-loading):not(.next-btn-text) {
  width: var(--btn-small-cn2-3-width, 62px);
  padding: 0;
}

.next-btn.next-small.isFourCNCharBtn:not(.next-btn-loading):not(.next-btn-text) {
  width: var(--btn-small-cn4-width, 72px);
  padding: 0;
}

.next-btn.next-small:not(.is-wind).isOnlyIcon {
  width: 24px;
  padding: 0;
}

.next-btn.next-medium:not(.isOnlyIcon):not(.next-btn-text) {
  min-width: var(--btn-medium-min-width, 62px);
}

.next-btn.next-medium.isTwoToThreeCNCharBtn:not(.next-btn-loading):not(.next-btn-text) {
  width: var(--btn-medium-cn2-3-width, 62px);
  padding: 0;
}

.next-btn.next-medium.isFourCNCharBtn:not(.next-btn-loading):not(.next-btn-text) {
  width: var(--btn-medium-cn4-width, 80px);
  padding: 0;
}

.next-btn.next-medium:not(.is-wind).isOnlyIcon {
  width: 32px;
  padding: 0;
}

.next-btn.next-large:not(.isOnlyIcon):not(.next-btn-text) {
  min-width: var(--btn-large-min-width, 64px);
}

.next-btn.next-large.isTwoToThreeCNCharBtn:not(.next-btn-loading):not(.next-btn-text) {
  width: var(--btn-large-cn2-3-width, 64px);
  padding: 0;
}

.next-btn.next-large.isFourCNCharBtn:not(.next-btn-loading):not(.next-btn-text) {
  width: var(--btn-large-cn4-width, 88px);
  padding: 0;
}

.next-btn.next-large:not(.is-wind).isOnlyIcon {
  width: 36px;
  padding: 0;
}

.next-btn-group .next-btn.next-btn.next-btn.next-btn {
  min-width: unset;
}

.next-btn-group .next-btn.next-btn-primary {
  border-style: solid;
  border-color: hsla(0, 0%, 100%, 0.2);
}

.next-btn .teamix-icon {
  vertical-align: middle;
}

.next-btn .teamix-icon-first {
  margin-left: 0;
  margin-right: 4px;
}

.next-btn .teamix-icon-last {
  margin-left: 4px;
  margin-right: 0;
}

.next-btn.next-btn-normal.next-btn-text.next-btn-warning:hover {
  color: var(--color-error-5);
}

.next-btn.next-btn-normal.next-btn-text.next-btn-warning:active {
  color: var(--color-error-6);
}

.next-btn.next-btn-primary.next-btn-text.next-btn-warning {
  color: var(--color-error-5);
}

.next-btn.next-btn-primary.next-btn-text.next-btn-warning:hover {
  color: var(--color-error-4);
}

.next-btn.next-btn-primary.next-btn-text.next-btn-warning:active {
  color: var(--color-error-6);
}

.next-pagination {
  font-size: inherit;
}

.next-pagination .next-pagination-item.next-current.next-current.next-current {
  z-index: 2;
}

.next-pagination .next-pagination-item:not(.next-current):focus-visible,
.next-pagination .next-pagination-item:not(.next-current):hover {
  background-color: var(--pagination-item-hover-bg, #fff);
}

.next-pagination
  .next-pagination-item.next-pagination-item.next-pagination-item.next-btn {
  min-width: 32px;
}

.next-pagination
  .next-pagination-item.next-pagination-item.next-pagination-item.next-btn.next-small {
  min-width: 24px;
}

.next-pagination
  .next-pagination-item.next-pagination-item.next-pagination-item.next-btn.next-next,
.next-pagination
  .next-pagination-item.next-pagination-item.next-pagination-item.next-btn.next-prev {
  border-color: var(--pagination-item-border-color, transparent);
}

.next-pagination
  .next-pagination-item.next-pagination-item.next-pagination-item.next-btn.next-next[disabled]:hover,
.next-pagination
  .next-pagination-item.next-pagination-item.next-pagination-item.next-btn.next-prev[disabled]:hover {
  background-color: var(--pagination-item-disabled-bg, #fff);
}

.next-pagination-size-selector-popup
  .next-select-menu-item.next-select-menu-item.next-select-menu-item.next-select-menu-item.next-select-menu-item {
  padding-right: 0;
}

.next-transfer.next-transfer .next-transfer-panel-list {
  padding: var(--transfer-panel-padding-mb, 0)
    var(--transfer-panel-padding-lr, 8px);
  margin: var(--transfer-panel-margin-mb, 8px) 0;
}

.next-transfer.next-transfer .next-transfer-panel-list::-webkit-scrollbar {
  width: var(--scrollbar-width, 4px);
  height: var(--scrollbar-height, 4px);
}

.next-transfer.next-transfer
  .next-transfer-panel-list::-webkit-scrollbar-track {
  opacity: 0.5;
  background: var(--scrollbar-track-bg, #eaedf0);
  -webkit-border-radius: var(--scrollbar-track-border-radius, 3px);
  border-radius: var(--scrollbar-track-border-radius, 3px);
}

.next-transfer.next-transfer
  .next-transfer-panel-list::-webkit-scrollbar-thumb {
  opacity: 0.5;
  background: var(--scrollbar-thumb-bg, #abb6c0);
  -webkit-border-radius: var(--scrollbar-thumb-border-radius, 3px);
  border-radius: var(--scrollbar-thumb-border-radius, 3px);
}

.next-transfer-operations
  .next-transfer-operation.next-transfer-operation.next-transfer-operation {
  padding: 0;
  width: var(--transfer-operation-width, 24px);
  height: var(--transfer-operation-height, 24px);
}

.next-transfer-operations
  .next-transfer-operation.next-transfer-operation.next-transfer-operation.next-btn {
  min-width: unset;
}

.next-transfer-operations
  .next-transfer-operation.next-transfer-operation.next-transfer-operation[disabled],
.next-transfer-operations
  .next-transfer-operation.next-transfer-operation.next-transfer-operation[disabled]:hover {
  color: var(--btn-pure-primary-color-disabled, #fff);
  background-color: var(--btn-pure-primary-bg-disabled, #d1d5d9);
  border-color: var(--btn-pure-primary-border-color-disabled, transparent);
}

.next-transfer-operations .next-transfer-move:before {
  width: var(--transfer-operation-simple-move-size, 16px);
  height: var(--transfer-operation-simple-move-size, 16px);
  font-size: var(--transfer-operation-simple-move-size, 16px);
}

.next-tabs-pure .next-tabs-tab.next-tabs-tab.next-tabs-tab.next-tabs-tab:hover {
  background-color: var(--tab-pure-hover-bg);
}

.next-date-picker .next-date-picker-trigger {
  -webkit-transition: -webkit-box-shadow var(--active-transition);
  transition: -webkit-box-shadow var(--active-transition);
  -o-transition: box-shadow var(--active-transition);
  -moz-transition: box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition),
    -webkit-box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
}

.next-date-picker .next-date-picker-trigger:hover,
.next-date-picker .next-date-picker-trigger[aria-expanded="true"] {
  -webkit-box-shadow: var(--active-shadow);
  -moz-box-shadow: var(--active-shadow);
  box-shadow: var(--active-shadow);
}

.next-date-picker .next-date-picker-trigger .next-date-picker-trigger-input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-range-picker .next-range-picker-trigger {
  -webkit-transition: -webkit-box-shadow var(--active-transition);
  transition: -webkit-box-shadow var(--active-transition);
  -o-transition: box-shadow var(--active-transition);
  -moz-transition: box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition),
    -webkit-box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
}

.next-range-picker .next-range-picker-trigger:hover,
.next-range-picker .next-range-picker-trigger[aria-expanded="true"] {
  -webkit-box-shadow: var(--active-shadow);
  -moz-box-shadow: var(--active-shadow);
  box-shadow: var(--active-shadow);
}

.next-range-picker .next-range-picker-trigger .next-range-picker-trigger-input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-month-picker .next-month-picker-trigger {
  -webkit-transition: -webkit-box-shadow var(--active-transition);
  transition: -webkit-box-shadow var(--active-transition);
  -o-transition: box-shadow var(--active-transition);
  -moz-transition: box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition),
    -webkit-box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
}

.next-month-picker .next-month-picker-trigger:hover,
.next-month-picker .next-month-picker-trigger[aria-expanded="true"] {
  -webkit-box-shadow: var(--active-shadow);
  -moz-box-shadow: var(--active-shadow);
  box-shadow: var(--active-shadow);
}

.next-month-picker .next-month-picker-trigger .next-month-picker-trigger-input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-year-picker .next-year-picker-trigger {
  -webkit-transition: -webkit-box-shadow var(--active-transition);
  transition: -webkit-box-shadow var(--active-transition);
  -o-transition: box-shadow var(--active-transition);
  -moz-transition: box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition),
    -webkit-box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
}

.next-year-picker .next-year-picker-trigger:hover,
.next-year-picker .next-year-picker-trigger[aria-expanded="true"] {
  -webkit-box-shadow: var(--active-shadow);
  -moz-box-shadow: var(--active-shadow);
  box-shadow: var(--active-shadow);
}

.next-year-picker .next-year-picker-trigger .next-year-picker-trigger-input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-week-picker .next-week-picker-trigger {
  -webkit-transition: -webkit-box-shadow var(--active-transition);
  transition: -webkit-box-shadow var(--active-transition);
  -o-transition: box-shadow var(--active-transition);
  -moz-transition: box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition),
    -webkit-box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
}

.next-week-picker .next-week-picker-trigger:hover,
.next-week-picker .next-week-picker-trigger[aria-expanded="true"] {
  -webkit-box-shadow: var(--active-shadow);
  -moz-box-shadow: var(--active-shadow);
  box-shadow: var(--active-shadow);
}

.next-week-picker .next-week-picker-trigger .next-week-picker-trigger-input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-calendar-panel-header-full .next-calendar-btn,
.next-calendar-panel-header-left .next-calendar-btn,
.next-calendar-panel-header-right .next-calendar-btn {
  line-height: 30px;
}

.next-date-picker-panel-footer,
.next-month-picker-panel-footer,
.next-range-picker-panel-footer,
.next-week-picker-panel-footer,
.next-year-picker-panel-footer {
  --popup-local-border-width: 1px;
}

.next-date-picker-panel-header,
.next-month-picker-panel-header,
.next-range-picker-panel-header,
.next-week-picker-panel-header,
.next-year-picker-panel-header {
  padding: 8px;
}

.next-search {
  -webkit-transition: -webkit-box-shadow var(--active-transition);
  transition: -webkit-box-shadow var(--active-transition);
  -o-transition: box-shadow var(--active-transition);
  -moz-transition: box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition),
    -webkit-box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
}

.next-search.next-search.next-search.focusing,
.next-search.next-search.next-search.visible,
.next-search.next-search.next-search:hover {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.next-search.next-search.next-search.disabled,
.next-search.next-search.next-search .next-btn:hover,
.next-search.next-search.next-search .next-input-group:hover,
.next-search.next-search.next-search .next-input:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-search-simple:not(.is-wind) .next-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.next-search-simple:not(.is-wind) .next-input input {
  padding-left: 0;
}

.next-search-simple:not(.is-wind) .next-input .next-input-inner {
  width: auto;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -moz-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.next-search-simple:not(.is-wind)
  .next-input
  .next-input-inner
  .next-search-icon {
  margin-right: 0;
  padding: 0 12px;
}

.next-search-simple:not(.is-wind) .next-input .next-input-control {
  width: auto;
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
  -moz-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.next-search-simple:not(.is-wind)
  .next-input
  .next-input-control
  .next-input-hint-wrap {
  padding-right: 0;
  width: auto;
}

.next-search.next-normal.disabled .next-input-group,
.next-search.next-search-normal.disabled .next-input-group {
  border-color: var(--search-simple-normal-color, #dedede);
}

.next-search.next-normal .next-after .next-btn-normal,
.next-search.next-search-normal .next-after .next-btn-normal {
  padding: 0 8px;
  margin-right: -1px;
}

.next-search.next-normal .next-after .next-btn-normal .next-icon-search,
.next-search.next-search-normal .next-after .next-btn-normal .next-icon-search {
  margin-right: 0;
}

.next-search.next-normal.custom-search-text .next-after .next-btn-normal {
  padding: 0 16px;
}

.next-input.next-disabled,
.next-input.next-disabled > input,
.next-input.next-disabled > textarea,
.next-search .next-input.next-disabled * {
  cursor: not-allowed;
}

.next-input.next-focus,
.next-input:hover {
   -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.next-input.next-disabled {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-input-group {
  -webkit-transition: -webkit-box-shadow var(--active-transition);
  transition: -webkit-box-shadow var(--active-transition);
  -o-transition: box-shadow var(--active-transition);
  -moz-transition: box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition),
    -webkit-box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
}

.next-input-group:hover {
  -webkit-box-shadow: var(--active-shadow);
  -moz-box-shadow: var(--active-shadow);
  box-shadow: var(--active-shadow);
}

.next-input-group.next-disabled,
.next-input-group .next-input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-input-group > .next-number-picker:first-child .next-input {
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}

.next-input-group > .next-number-picker:first-child .next-input.next-focus {
  position: relative;
  z-index: 1;
}

.next-input-group > .next-number-picker:last-child .next-input {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}

.next-input-group > .next-number-picker:last-child .next-input.next-focus {
  position: relative;
  z-index: 1;
}

.next-input.next-noborder.next-focus,
.next-input.next-noborder:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-select.next-active .next-select-inner {
  -webkit-box-shadow: var(--active-shadow);
  -moz-box-shadow: var(--active-shadow);
  box-shadow: var(--active-shadow);
  border-color: var(--input-focus-border-color, #0064c8);
}

.next-select-menu.next-select-multiple-menu .next-icon-select,
.next-select-menu.next-select-multiple-menu .next-icon-selected,
.next-select-menu.next-select-single-menu .next-icon-select,
.next-select-menu.next-select-single-menu .next-icon-selected,
.next-select-menu.next-select-tag-menu .next-icon-select,
.next-select-menu.next-select-tag-menu .next-icon-selected {
  margin-left: var(--select-menu-select-icon-ml, -20px);
}

.next-select-menu.next-select-multiple-menu
  .next-select-menu-item.next-selected,
.next-select-menu.next-select-single-menu .next-select-menu-item.next-selected,
.next-select-menu.next-select-tag-menu .next-select-menu-item.next-selected {
  font-weight: var(--select-selected-font-weight, 400);
}

.next-menu.next-menu {
  padding: var(--menu-padding);
}

.next-menu.next-menu.next-menu.next-menu.next-menu
  .next-menu-symbol-icon-selected {
  height: 100%;
  overflow: hidden;
}

.next-menu.next-menu.next-menu.next-menu.next-menu
  .next-menu-symbol-icon-selected,
.next-menu.next-menu.next-menu.next-menu.next-menu
  .next-menu-symbol-icon-selected:before {
  width: var(--menu-icon-selected-size, 16px);
  font-size: var(--menu-icon-selected-size, 16px);
}

.next-menu-item {
  -webkit-border-radius: var(--corner-1);
  -moz-border-radius: var(--corner-1);
  border-radius: var(--corner-1);
}

.next-menu-selectable-multiple
  .next-menu-item:not([role="menuitemcheckbox"]):not([role="menuitemradio"]):not(.next-nav-item),
.next-menu-selectable-single
  .next-menu-item:not([role="menuitemcheckbox"]):not([role="menuitemradio"]):not(.next-nav-item) {
  padding: 0 var(--menu-padding-horizontal) 0
    var(--menu-padding-horizontal-selectable, var(--menu-padding-horizontal));
}

.next-menu-item-inner .next-checkbox,
.next-menu-item-inner .next-radio {
  margin-right: var(--menu-checkbox-mr, 8px);
}

.next-menu .next-menu-icon-selected.next-icon {
  margin-left: var(--select-menu-select-icon-ml, -20px);
}

.next-split-btn.next-split-btn > .next-btn {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-number-picker > .next-input .next-number-picker-handler:focus-within {
  opacity: 1;
}

.next-number-picker
  > .next-input
  .next-number-picker-handler
  .next-btn.next-btn.next-btn.next-btn {
  min-width: unset;
  border: var(--number-picker-handler-btn-border, none);
}

.next-number-picker
  > .next-input
  .next-number-picker-handler
  .next-btn.next-btn.next-btn.next-btn:not(.disabled):hover {
  border: var(--number-picker-handler-btn-border-hover, none);
}

.next-number-picker
  > .next-input
  .next-number-picker-handler
  .next-btn.next-btn.next-btn.next-btn.next-btn:first-child {
  border-right: none;
  border-top: none;
}

.next-number-picker
  > .next-input
  .next-number-picker-handler
  .next-btn.next-btn.next-btn.next-btn.next-btn:last-child {
  border-right: none;
  border-bottom: none;
}

.next-number-picker-inline .next-input-group {
  border: var(--number-picker-inline-group-border, 1px solid #c0c6cc);
  -webkit-border-radius: var(--number-picker-border-radius, 2px);
  -moz-border-radius: var(--number-picker-border-radius, 2px);
  border-radius: var(--number-picker-border-radius, 2px);
  padding: var(--number-picker-inline-group-padding, 4px);
}

.next-number-picker-inline .next-input-group .next-input-group-addon .next-btn {
  border: var(--number-picker-inline-group-btn-border, none);
  -webkit-border-radius: var(--number-picker-border-radius, 2px) !important;
  -moz-border-radius: var(--number-picker-border-radius, 2px) !important;
  border-radius: var(--number-picker-border-radius, 2px) !important;
  min-width: unset;
}

.next-number-picker-inline
  .next-input-group
  .next-input-group-addon
  .next-btn:not(.disabled):hover {
  border: var(--number-picker-inline-group-btn-border-hover, none);
}

.next-number-picker-inline .next-input-group .next-input {
  border: var(--number-picker-inline-group-btn-border, none);
}

.next-number-picker-inline .next-input-group .next-input:hover {
  border: var(--number-picker-inline-group-btn-border-hover, none);
}

.next-number-picker.next-disabled {
  background-color: var(--input-disabled-bg-color, #f7f9fa);
}

.next-number-picker.next-disabled > .next-input {
  border: var(--number-picker-normal-border, 1px solid #dedede);
}

.next-number-picker.next-disabled .next-input-group {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.next-number-picker.next-disabled .next-input-group:hover {
  border: var(--number-picker-inline-group-border, 1px solid #c0c6cc);
}

.next-number-picker.next-disabled
  .next-input-group
  .next-input-group-addon
  .next-btn {
  background-color: var(--input-disabled-bg-color, #f7f9fa);
}

.next-number-picker.next-disabled
  .next-input-group
  .next-input-group-addon
  .next-btn:hover,
.next-number-picker.next-disabled .next-input-group .next-input:hover {
  border: var(--number-picker-inline-group-btn-border, none);
}

.next-number-picker-inline.next-medium
  .next-input-group
  .next-input-group-addon
  .next-btn {
  width: var(
    --number-picker-final-height-medium,
    -webkit-calc(var(--number-picker-height-medium) - 10px)
  );
  width: var(
    --number-picker-final-height-medium,
    -moz-calc(var(--number-picker-height-medium) - 10px)
  );
  width: var(
    --number-picker-final-height-medium,
    calc(var(--number-picker-height-medium) - 10px)
  );
  min-width: unset;
}

.next-number-picker-inline.next-medium .next-input-group .next-input,
.next-number-picker-inline.next-medium
  .next-input-group
  .next-input-group-addon
  .next-btn {
  height: var(
    --number-picker-final-height-medium,
    -webkit-calc(var(--number-picker-height-medium) - 10px)
  );
  height: var(
    --number-picker-final-height-medium,
    -moz-calc(var(--number-picker-height-medium) - 10px)
  );
  height: var(
    --number-picker-final-height-medium,
    calc(var(--number-picker-height-medium) - 10px)
  );
}

.next-number-picker-inline.next-medium .next-input-group .next-input {
  display: inline-block;
  line-height: var(
    --number-picker-final-height-medium,
    -webkit-calc(var(--number-picker-height-medium) - 10px)
  );
  line-height: var(
    --number-picker-final-height-medium,
    -moz-calc(var(--number-picker-height-medium) - 10px)
  );
  line-height: var(
    --number-picker-final-height-medium,
    calc(var(--number-picker-height-medium) - 10px)
  );
}

.next-number-picker-inline.next-medium .next-input-group .next-input input {
  display: inline-block;
  height: 100%;
}

.next-number-picker-inline.next-small
  .next-input-group
  .next-input-group-addon
  .next-btn {
  width: var(
    --number-picker-final-height-small,
    -webkit-calc(var(--number-picker-height-small) - 10px)
  );
  width: var(
    --number-picker-final-height-small,
    -moz-calc(var(--number-picker-height-small) - 10px)
  );
  width: var(
    --number-picker-final-height-small,
    calc(var(--number-picker-height-small) - 10px)
  );
  min-width: unset;
}

.next-number-picker-inline.next-small .next-input-group .next-input,
.next-number-picker-inline.next-small
  .next-input-group
  .next-input-group-addon
  .next-btn {
  height: var(
    --number-picker-final-height-small,
    -webkit-calc(var(--number-picker-height-small) - 10px)
  );
  height: var(
    --number-picker-final-height-small,
    -moz-calc(var(--number-picker-height-small) - 10px)
  );
  height: var(
    --number-picker-final-height-small,
    calc(var(--number-picker-height-small) - 10px)
  );
}

.next-number-picker-inline.next-small .next-input-group .next-input {
  display: inline-block;
  line-height: var(
    --number-picker-final-height-small,
    -webkit-calc(var(--number-picker-height-small) - 10px)
  );
  line-height: var(
    --number-picker-final-height-small,
    -moz-calc(var(--number-picker-height-small) - 10px)
  );
  line-height: var(
    --number-picker-final-height-small,
    calc(var(--number-picker-height-small) - 10px)
  );
}

.next-number-picker-inline.next-small .next-input-group .next-input input {
  display: inline-block;
  height: 100%;
}

.next-number-picker-inline.next-large
  .next-input-group
  .next-input-group-addon
  .next-btn {
  width: var(
    --number-picker-final-height-large,
    -webkit-calc(var(--number-picker-height-large) - 10px)
  );
  width: var(
    --number-picker-final-height-large,
    -moz-calc(var(--number-picker-height-large) - 10px)
  );
  width: var(
    --number-picker-final-height-large,
    calc(var(--number-picker-height-large) - 10px)
  );
  min-width: unset;
}

.next-number-picker-inline.next-large .next-input-group .next-input,
.next-number-picker-inline.next-large
  .next-input-group
  .next-input-group-addon
  .next-btn {
  height: var(
    --number-picker-final-height-large,
    -webkit-calc(var(--number-picker-height-large) - 10px)
  );
  height: var(
    --number-picker-final-height-large,
    -moz-calc(var(--number-picker-height-large) - 10px)
  );
  height: var(
    --number-picker-final-height-large,
    calc(var(--number-picker-height-large) - 10px)
  );
}

.next-number-picker-inline.next-large .next-input-group .next-input {
  display: inline-block;
  line-height: var(
    --number-picker-final-height-large,
    -webkit-calc(var(--number-picker-height-large) - 10px)
  );
  line-height: var(
    --number-picker-final-height-large,
    -moz-calc(var(--number-picker-height-large) - 10px)
  );
  line-height: var(
    --number-picker-final-height-large,
    calc(var(--number-picker-height-large) - 10px)
  );
}

.next-number-picker-inline.next-large .next-input-group .next-input input {
  display: inline-block;
  height: 100%;
}

.next-number-picker-normal.next-small.next-small.next-small
  .next-icon
  .next-icon-remote,
.next-number-picker-normal.next-small.next-small.next-small .next-icon:before {
  line-height: inherit;
}

.next-number-picker.next-medium
  > .next-input
  .next-number-picker-handler
  .next-btn.next-btn.next-btn.next-btn {
  height: -webkit-calc(
    var(--form-element-medium-height, 32px) / 2 - var(--input-border-width, 1px) *
      2/2
  );
  height: -moz-calc(
    var(--form-element-medium-height, 32px) / 2 - var(--input-border-width, 1px) *
      2/2
  );
  height: calc(
    var(--form-element-medium-height, 32px) / 2 - var(--input-border-width, 1px) *
      2 / 2
  );
}

.next-card {
  -webkit-transition: -webkit-box-shadow var(--active-transition);
  transition: -webkit-box-shadow var(--active-transition);
  -o-transition: box-shadow var(--active-transition);
  -moz-transition: box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition);
  transition: box-shadow var(--active-transition),
    -webkit-box-shadow var(--active-transition),
    -moz-box-shadow var(--active-transition);
}

.next-card:hover {
  -webkit-box-shadow: var(
    --card-hover-shadow,
    1px 1px 4px 0 rgba(0, 0, 0, 0.13)
  );
  -moz-box-shadow: var(--card-hover-shadow, 1px 1px 4px 0 rgba(0, 0, 0, 0.13));
  box-shadow: var(--card-hover-shadow, 1px 1px 4px 0 rgba(0, 0, 0, 0.13));
  background-color: var(--card-hover-background, var(--card-background));
  border-color: var(--card-hover-border-color, var(--card-border-color));
}

.next-card .next-card-header-extra .next-icon-ellipsis-vertical:before {
  vertical-align: text-bottom;
}

.next-card .next-card-collapsable-tail {
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  padding: 0 var(--card-padding-lr, 16px) var(--card-body-padding-bottom, 16px);
  margin-bottom: var(--card-body-padding-bottom, 16px);
}

.next-card .next-card-collapsable-tail:hover {
  color: var(--color-brand1-6, #0064c8);
  cursor: pointer;
}

.next-card .next-card-collapsable-tail .next-icon {
  margin-left: 4px;
}

.next-card .next-card-collapsable-tail .next-icon:before {
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.next-card .next-card-collapsable-head {
  padding: 0 var(--card-padding-lr, 16px);
  margin: var(--card-body-padding-bottom, 16px) 0;
  line-height: 22px;
  font-size: 14px;
}

.next-card .next-card-collapsable-head:hover {
  color: var(--color-brand1-6, #0064c8);
  cursor: pointer;
}

.next-cascader:not(.multiple) .next-cascader-menu .next-menu-item {
  --menu-padding-horizontal: var(--menu-padding-horizontal-selectable);
  padding: 0 var(--menu-padding-horizontal) 0 var(--menu-padding-horizontal);
}

.next-cascader .next-cascader-menu-wrapper::-webkit-scrollbar {
  width: var(--scrollbar-width, 4px);
  height: var(--scrollbar-height, 4px);
}

.next-cascader .next-cascader-menu-wrapper::-webkit-scrollbar-track {
  opacity: 0.5;
  background: var(--scrollbar-track-bg, #eaedf0);
  -webkit-border-radius: var(--scrollbar-track-border-radius, 3px);
  border-radius: var(--scrollbar-track-border-radius, 3px);
}

.next-cascader .next-cascader-menu-wrapper::-webkit-scrollbar-thumb {
  opacity: 0.5;
  background: var(--scrollbar-thumb-bg, #abb6c0);
  -webkit-border-radius: var(--scrollbar-thumb-border-radius, 3px);
  border-radius: var(--scrollbar-thumb-border-radius, 3px);
}

.next-cascader-select-dropdown {
  background-color: var(--dropdown-bg-color, #fff);
}

.next-cascader-select-dropdown .next-cascader-menu-wrapper {
  border-left: none;
  overflow-y: scroll;
}

.next-cascader-select-dropdown .next-cascader-menu-wrapper::-webkit-scrollbar {
  width: var(--scrollbar-width, 4px);
  height: var(--scrollbar-height, 4px);
}

.next-cascader-select-dropdown
  .next-cascader-menu-wrapper::-webkit-scrollbar-track {
  opacity: 0.5;
  background: var(--scrollbar-track-bg, #eaedf0);
  -webkit-border-radius: var(--scrollbar-track-border-radius, 3px);
  border-radius: var(--scrollbar-track-border-radius, 3px);
}

.next-cascader-select-dropdown
  .next-cascader-menu-wrapper::-webkit-scrollbar-thumb {
  opacity: 0.5;
  background: var(--scrollbar-thumb-bg, #abb6c0);
  -webkit-border-radius: var(--scrollbar-thumb-border-radius, 3px);
  border-radius: var(--scrollbar-thumb-border-radius, 3px);
}

.next-cascader-select-dropdown .next-menu {
  border: none;
}

.next-table-fix-left,
.next-table-fix-right {
  background-clip: padding-box;
}

.next-message-title {
  font-weight: var(--message-title-weight, 500);
}

.next-message .next-message-close .next-icon-close {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.next-message .next-message-close .next-icon-close:before {
  width: var(--message-close-icon-size, 12px);
  height: var(--message-close-icon-size, 12px);
  font-size: var(--message-close-icon-size, 12px);
  line-height: var(--message-close-icon-size, 12px);
}

.next-dialog-footer .next-btn {
  margin-left: var(--s-2, 8px);
}

.next-dialog-footer .next-btn:first-child {
  margin-left: 0;
}

.next-dialog.next-dialog-quick.quick-show .next-dialog-body {
  padding: var(--dialog-content-padding-top, 0)
    var(--dialog-content-padding-left-right, 24px)
    var(--dialog-content-padding-bottom, 16px)
    var(--dialog-content-padding-left-right, 24px);
}

.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner
  > .next-icon {
  -webkit-transform: scale(var(--checkbox-icon-scale, 0.8));
  -moz-transform: scale(var(--checkbox-icon-scale, 0.8));
  -o-transform: scale(var(--checkbox-icon-scale, 0.8));
  transform: scale(var(--checkbox-icon-scale, 0.8));
  margin-top: 0;
}

.next-nav.next-nav.next-nav.next-nav.next-menu {
  padding: 0;
}

.next-modal,
body.next-modal-open {
  overflow: hidden;
}

.next-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  background: var(--color-fill1-2);
}

.next-modal-header {
  position: relative;
  background: var(--color-fill1-1);
  z-index: 1;
  height: 60px;
  -webkit-box-shadow: var(--shadow-1);
  -moz-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: var(--s-2) var(--s-4);
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.next-modal-header .left-part .next-modal-title {
  font-size: var(--font-size-body-2);
  line-height: var(--font-lineheight-1);
  color: var(--color-text1-3);
}

.next-modal-header .left-part .next-modal-title .next-modal-edit-icon {
  margin-left: var(--s-1);
  vertical-align: middle;
}

.next-modal-header .left-part .next-modal-title .edit-title {
  display: inline-block;
  vertical-align: middle;
}

.next-modal-header .left-part .next-modal-description {
  font-size: var(--font-size-body-1);
  line-height: var(--font-lineheight-1);
  color: var(--color-text1-1);
}

.next-modal-header .right-part {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.next-modal-header .right-part .next-modal-operations {
  line-height: var(--s-9);
}

.next-modal-header .right-part .next-modal-side-drawer-switch {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: var(--s-8);
  margin: 2px var(--s-3);
  -webkit-border-radius: var(--corner-1);
  -moz-border-radius: var(--corner-1);
  border-radius: var(--corner-1);
  border: 1px solid var(--color-line1-3);
  padding: 5px var(--s-3);
  font-size: var(--font-size-body-1);
  line-height: var(--font-lineheight-1);
  color: var(--color-text1-3);
}

.next-modal-header .right-part .next-modal-side-drawer-switch-label {
  vertical-align: middle;
  display: inline-block;
}

.next-modal-header .right-part .next-modal-side-drawer-switch .next-switch {
  margin: 2px 0 2px var(--s-2);
  vertical-align: middle;
}

.next-modal-header .right-part .next-modal-close {
  height: var(--s-6);
  margin: 6px 0;
}

.next-modal-body {
  height: -webkit-calc(100% - 60px);
  height: -moz-calc(100% - 60px);
  height: calc(100% - 60px);
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.next-modal-body .next-modal-children,
.next-modal-body .next-modal-side-drawer {
  height: 100%;
  overflow: auto;
}

.next-modal-body .next-modal-children {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: var(--color-fill1-2);
}

.next-modal-body .next-modal-side-drawer {
  background: var(--color-fill1-1);
  overflow: hidden;
  -webkit-transition: width 0.3s ease-out;
  -o-transition: width 0.3s ease-out;
  -moz-transition: width 0.3s ease-out;
  transition: width 0.3s ease-out;
}

.next-modal-body .next-modal-side-drawer.next-modal-side-drawer-visible {
  -webkit-box-shadow: -1px 0 0 0 var(--color-fill1-3);
  -moz-box-shadow: -1px 0 0 0 var(--color-fill1-3);
  box-shadow: -1px 0 0 0 var(--color-fill1-3);
  overflow: auto;
}

.next-modal-header .next-modal-title-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.next-modal-header .next-modal-title-box .next-modal-title-icon {
  color: var(--color-fill1-6, #8b8b8b);
  font-size: var(--font-size-body-2, 12px);
  margin-right: var(--modal-title-icon-margin-right, 12px);
  cursor: pointer;
  -webkit-transform: translateY(var(--modal-title-icon-transform-top, 2px));
  -moz-transform: translateY(var(--modal-title-icon-transform-top, 2px));
  -o-transform: translateY(var(--modal-title-icon-transform-top, 2px));
  transform: translateY(var(--modal-title-icon-transform-top, 2px));
}

.next-badge .next-badge-count,
.next-badge .next-badge-dot {
  -webkit-box-shadow: 0 0 0 2px var(--badge-border-color, #fff);
  -moz-box-shadow: 0 0 0 2px var(--badge-border-color, #fff);
  box-shadow: 0 0 0 2px var(--badge-border-color, #fff);
}

.next-upload-dragable .next-upload-drag {
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.next-upload-dragable .next-upload-drag-over {
  background: var(--color-brand1-1);
}

.next-upload-card,
.next-upload-list-card .next-upload-list-item-wrapper {
  -webkit-border-radius: var(--corner-1);
  -moz-border-radius: var(--corner-1);
  border-radius: var(--corner-1);
}

.next-upload-list-card .next-upload-list-item-wrapper {
  border-style: dashed;
}

.next-upload-list-card
  .next-upload-list-item-progress
  .next-progress-line-overlay {
  -webkit-border-radius: 0 var(--corner-1) var(--corner-1) var(--corner-1);
  -moz-border-radius: 0 var(--corner-1) var(--corner-1) var(--corner-1);
  border-radius: 0 var(--corner-1) var(--corner-1) var(--corner-1);
}

.next-calendar-card .next-calendar-cell.next-selected .next-calendar-date,
.next-calendar-card .next-calendar-cell.next-selected .next-calendar-month,
.next-calendar-card .next-calendar-cell.next-selected .next-calendar-year,
.next-calendar-panel .next-calendar-cell.next-selected .next-calendar-date,
.next-calendar-panel .next-calendar-cell.next-selected .next-calendar-month,
.next-calendar-panel .next-calendar-cell.next-selected .next-calendar-year,
.next-calendar-range .next-calendar-cell.next-selected .next-calendar-date,
.next-calendar-range .next-calendar-cell.next-selected .next-calendar-month,
.next-calendar-range .next-calendar-cell.next-selected .next-calendar-year {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}

.next-drawer-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--drawer-footer-padding-top, 10px)
    var(--drawer-footer-padding-right, 16px)
    var(--drawer-footer-padding-bottom, 10px)
    var(--drawer-footer-padding-left, 16px);
  left: 0;
  background: var(--drawer-title-bg-color, var(--drawer-bg));
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.next-drawer-footer-line {
  border-top: var(--drawer-footer-border-width, 1px) var(--line-solid, solid)
    var(--drawer-footer-border-color, var(--color-line1-1));
}

.next-drawer-footer-left,
.next-drawer-footer-right {
  text-align: right;
}

.next-drawer-footer-center {
  text-align: center;
}

.next-drawer {
  overflow: hidden;
}

.next-drawer .next-drawer-body {
  height: -webkit-calc(100% - var(--drawer-has-footer-margin-bottom, 40px));
  height: -moz-calc(100% - var(--drawer-has-footer-margin-bottom, 40px));
  height: calc(100% - var(--drawer-has-footer-margin-bottom, 40px));
}

.next-drawer-has-footer .next-drawer-body {
  margin-bottom: var(--drawer-has-footer-margin-bottom, 40px);
  padding-bottom: calc(
    var(--drawer-content-padding-bottom, 20px) +
      var(--drawer-has-footer-margin-bottom, 40px) +
      var(--drawer-footer-padding-bottom, 10px)
  );
}

.next-overlay-wrapper .next-overlay-inner::-webkit-scrollbar {
  width: var(--scrollbar-width, 4px);
  height: var(--scrollbar-height, 4px);
}

.next-overlay-wrapper .next-overlay-inner::-webkit-scrollbar-track {
  opacity: 0.5;
  background: var(--scrollbar-track-bg, #eaedf0);
  -webkit-border-radius: var(--scrollbar-track-border-radius, 3px);
  border-radius: var(--scrollbar-track-border-radius, 3px);
}

.next-overlay-wrapper .next-overlay-inner::-webkit-scrollbar-thumb {
  opacity: 0.5;
  background: var(--scrollbar-thumb-bg, #abb6c0);
  -webkit-border-radius: var(--scrollbar-thumb-border-radius, 3px);
  border-radius: var(--scrollbar-thumb-border-radius, 3px);
}

body {
  line-height: 1.5;
}

code,
kbd,
pre,
samp {
  font-family: Consolas, Menlo, Courier, monospace;
}

a:active,
a:hover,
a:link,
a:visited {
  text-decoration: none;
}

body {
  color: var(--color-text1-9);
  background: #fff;
  background: var(--body-bg, var(--color-fill1-1));
}

.next-checkbox-inner {
  position: relative;
}

.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner
  > .next-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%)
    scale(var(--checkbox-icon-scale, 0.8));
  -moz-transform: translate(-50%, -50%) scale(var(--checkbox-icon-scale, 0.8));
  -o-transform: translate(-50%, -50%) scale(var(--checkbox-icon-scale, 0.8));
  transform: translate(-50%, -50%) scale(var(--checkbox-icon-scale, 0.8));
  margin: 0;
}

.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner.next-checkbox-inner
  > .next-icon:before {
  display: inline-block;
  width: var(--checkbox-icon-size, 16px);
  height: var(--checkbox-icon-size, 16px);
  font-size: var(--checkbox-icon-size, 16px);
  line-height: inherit;
}

.next-input .next-input-clear-icon.next-input-clear-icon.next-input-clear-icon,
.next-input .next-input-hint.next-input-hint.next-input-hint {
  color: var(--input-hint-icon-color, #333);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: var(--input-hint-icon-outer-size, 16px);
  height: var(--input-hint-icon-outer-size, 16px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.next-input
  .next-input-clear-icon.next-input-clear-icon.next-input-clear-icon:hover {
  background-color: var(--input-hint-icon-hover-color, #f7f9fa);
}

.next-input
  .next-input-clear-icon.next-input-clear-icon.next-input-clear-icon:before {
  -webkit-transform: var(--input-clear-icon-transform, scale(0.59));
  -moz-transform: var(--input-clear-icon-transform, scale(0.59));
  -o-transform: var(--input-clear-icon-transform, scale(0.59));
  transform: var(--input-clear-icon-transform, scale(0.59));
}

.next-input
  .next-input-clear-icon.next-input-clear-icon.next-input-clear-icon:before,
.next-input .next-input-hint.next-input-hint.next-input-hint:before {
  width: var(--input-hint-icon-inner-size, 12px);
  font-size: var(--input-hint-icon-inner-size, 12px);
  line-height: var(--input-hint-icon-inner-size, 12px);
}

.next-balloon-close {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 18px;
  height: 18px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.next-balloon-close:hover {
  background-color: var(--balloon-hover-bg, #f7f9fa);
}

.next-breadcrumb-icon-sep:before {
  font-family: NextIcon;
}

.next-tag-large {
  -webkit-border-radius: var(--tag-large-corner-radius, 14px);
  -moz-border-radius: var(--tag-large-corner-radius, 14px);
  border-radius: var(--tag-large-corner-radius, 14px);
}

.next-tag:not([disabled]) .next-tag-body {
  cursor: var(--tag-cursor, pointer);
}

.next-tag:not([disabled]).next-tag.next-tag:focus {
  color: var(--tag-focus-color);
  background-color: var(--tag-focus-bg);
}

.next-tag:not([disabled]).next-tag.next-tag:hover {
  color: var(--tag-hover-color);
  background-color: var(--tag-hover-bg);
}

.next-tag:not([disabled]).next-tag.next-tag:hover .next-icon-close:before {
  content: var(--icon-content-delete-filling);
}

.next-tag.wind-tag-colored {
  border: 0;
  cursor: default;
}

.next-tag.wind-tag-colored .next-tag-body {
  top: 0;
}

.next-tag.wind-tag-colored-light-steel-blue,
.next-tag.wind-tag-colored-light-steel-blue:hover {
  background: #edf3f7 !important;
  color: #628099 !important;
}

.next-tag.wind-tag-colored-plum,
.next-tag.wind-tag-colored-plum:hover {
  background: #f0edf7 !important;
  color: #746299 !important;
}

.next-tag.wind-tag-colored-misty-rose,
.next-tag.wind-tag-colored-misty-rose:hover {
  background: #f7eded !important;
  color: #996262 !important;
}

.next-tag.wind-tag-colored-light-goldenrod-yellow,
.next-tag.wind-tag-colored-light-goldenrod-yellow:hover {
  background: #f7f6ed !important;
  color: #999062 !important;
}

.next-tag.wind-tag-colored-pale-green,
.next-tag.wind-tag-colored-pale-green:hover {
  background: #edf7ed !important;
  color: #629962 !important;
}

.next-tag.wind-tag-colored-gray,
.next-tag.wind-tag-colored-gray:hover {
  background: #e5e5e5 !important;
  color: #595959 !important;
}

.next-tag.wind-tag-colored-silver {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #bfbfbf;
}

.next-tag.wind-tag-colored-silver,
.next-tag.wind-tag-colored-silver:hover {
  background: #fafafa !important;
  color: #595959 !important;
}

.next-tag.wind-tag-colored-silver .next-tag-body {
  top: -1px;
}

.next-tag.wind-tag-colored-silver:hover {
  border-color: #bfbfbf !important;
}

.next-tag.is-wind.next-tag-small {
  -webkit-border-radius: -webkit-calc(var(--size-base) * 5/2);
  -moz-border-radius: -moz-calc(var(--size-base) * 5/2);
  border-radius: calc(var(--size-base) * 5 / 2);
  cursor: pointer;
}

.next-tag.is-wind.next-tag-small,
.next-tag.is-wind.next-tag-small .next-tag-body {
  height: -webkit-calc(var(--size-base) * 5);
  height: -moz-calc(var(--size-base) * 5);
  height: calc(var(--size-base) * 5);
  line-height: -webkit-calc(var(--size-base) * 5);
  line-height: -moz-calc(var(--size-base) * 5);
  line-height: calc(var(--size-base) * 5);
  vertical-align: middle;
}

.next-tag.is-wind.next-tag-small .next-tag-body {
  font-size: -webkit-calc(var(--size-base) * 3);
  font-size: -moz-calc(var(--size-base) * 3);
  font-size: calc(var(--size-base) * 3);
  top: -1px;
}

.next-tag.is-wind.next-tag-medium {
  -webkit-border-radius: -webkit-calc(var(--size-base) * 6/2);
  -moz-border-radius: -moz-calc(var(--size-base) * 6/2);
  border-radius: calc(var(--size-base) * 6 / 2);
  cursor: pointer;
}

.next-tag.is-wind.next-tag-medium,
.next-tag.is-wind.next-tag-medium .next-tag-body {
  height: -webkit-calc(var(--size-base) * 6);
  height: -moz-calc(var(--size-base) * 6);
  height: calc(var(--size-base) * 6);
  line-height: -webkit-calc(var(--size-base) * 6);
  line-height: -moz-calc(var(--size-base) * 6);
  line-height: calc(var(--size-base) * 6);
  vertical-align: middle;
}

.next-tag.is-wind.next-tag-medium .next-tag-body {
  font-size: -webkit-calc(var(--size-base) * 3);
  font-size: -moz-calc(var(--size-base) * 3);
  font-size: calc(var(--size-base) * 3);
  top: -1px;
}

.next-tag.is-wind.next-tag-large {
  -webkit-border-radius: -webkit-calc(var(--size-base) * 7/2);
  -moz-border-radius: -moz-calc(var(--size-base) * 7/2);
  border-radius: calc(var(--size-base) * 7 / 2);
  cursor: pointer;
}

.next-tag.is-wind.next-tag-large,
.next-tag.is-wind.next-tag-large .next-tag-body {
  height: -webkit-calc(var(--size-base) * 7);
  height: -moz-calc(var(--size-base) * 7);
  height: calc(var(--size-base) * 7);
  line-height: -webkit-calc(var(--size-base) * 7);
  line-height: -moz-calc(var(--size-base) * 7);
  line-height: calc(var(--size-base) * 7);
  vertical-align: middle;
}

.next-tag.is-wind.next-tag-large .next-tag-body {
  font-size: -webkit-calc(var(--size-base) * 3 + 2px);
  font-size: -moz-calc(var(--size-base) * 3 + 2px);
  font-size: calc(var(--size-base) * 3 + 2px);
  top: -1px;
}

.next-tag.is-wind.next-tag-closable > .next-tag-close-btn {
  color: var(--color-text1-2);
  height: auto;
  position: relative;
  top: -1px;
}

.next-tag.is-wind.next-tag-closable > .next-tag-close-btn .next-icon:before {
  -webkit-transform: scale(0.84);
  -moz-transform: scale(0.84);
  -o-transform: scale(0.84);
  transform: scale(0.84);
}

.next-tag.is-wind .next-tag-body .next-icon {
  vertical-align: baseline;
}

.next-message.next-message-success.next-toast {
  border-left: var(--message-border-width, 4px) solid
    var(--message-success-border-color, #00a700);
}

.next-message.next-message-warning.next-toast {
  border-left: var(--message-border-width, 4px) solid
    var(--message-warning-border-color, #fac800);
}

.next-message.next-message-error.next-toast {
  border-left: var(--message-border-width, 4px) solid
    var(--message-error-border-color, #c80000);
}

.next-message.next-message-notice.next-toast {
  border-left: var(--message-border-width, 4px) solid
    var(--message-notice-border-color, #0064c8);
}

.next-message.next-message-help.next-toast {
  border-left: var(--message-border-width, 4px) solid
    var(--message-help-border-color, #888);
}

.next-message.next-medium {
  padding-left: 16px;
  padding-right: 12px;
}

.next-message.next-medium.next-inline:not(.next-message-loading) {
  border: 0;
}

.next-message.next-medium .next-message-symbol {
  line-height: 21px;
}

.next-message.next-medium .next-message-symbol:before {
  font-size: 14px;
  width: 14px;
  line-height: inherit;
}

.next-message.next-medium.next-title-content .next-message-title {
  font-weight: 600;
  line-height: 22px;
}

.next-message.next-medium .next-message-content {
  line-height: 20px;
  margin-top: 0;
}

.next-message.next-medium.next-only-content .next-message-symbol {
  line-height: 19px;
}

.next-message.next-medium.next-only-content .next-message-symbol:before {
  font-size: 14px;
  width: 14px;
  line-height: inherit;
}

.next-message.next-medium.next-only-content .next-message-content {
  line-height: 20px;
}

.next-message.next-toast.next-overlay-inner.next-message-wrapper {
  z-index: 1031;
}

.next-menu-btn.next-btn-text.next-btn-normal:hover .next-menu-btn-arrow {
  color: var(--btn-text-normal-color-hover, #555);
}

.next-menu-btn.next-btn-text.next-btn-primary .next-menu-btn-arrow {
  color: var(--btn-text-primary-color, #555);
}

.next-menu-btn.next-btn-text.next-btn-primary:hover .next-menu-btn-arrow {
  color: var(--btn-text-primary-color-hover, #555);
}

.next-menu-btn.next-btn-text.next-btn-secondary .next-menu-btn-arrow {
  color: var(--btn-text-secondary-color, #555);
}

.next-menu-btn.next-btn-text.next-btn-secondary:hover .next-menu-btn-arrow {
  color: var(--btn-text-secondary-color-hover, #555);
}

.next-number-picker.is-wind.next-number-picker-inline.next-medium {
  min-width: -webkit-calc(var(--size-base) * 30);
  min-width: -moz-calc(var(--size-base) * 30);
  min-width: calc(var(--size-base) * 30);
}

.next-number-picker.is-wind.next-number-picker-inline .next-input {
  position: relative;
  left: -1px;
  z-index: 1;
}

.next-number-picker.is-wind.next-number-picker-inline
  .next-input-group-addon.next-before {
  position: relative;
}

.next-number-picker.is-wind.next-number-picker-inline
  .next-input-group-addon.next-before:hover {
  z-index: 2;
}

.next-number-picker.is-wind.next-number-picker-inline
  .next-input-group-addon.next-before
  .next-btn {
  margin: 0;
}

.next-number-picker.is-wind.next-number-picker-inline
  .next-input-group-addon.next-after {
  position: relative;
  left: -2px;
}

.next-number-picker.is-wind.next-number-picker-inline
  .next-input-group-addon.next-after:hover {
  z-index: 2;
}

.next-number-picker.is-wind.next-number-picker-inline
  .next-input-group-addon.next-after
  .next-btn {
  margin: 0;
}

.next-number-picker > .next-input {
  border: var(--number-picker-normal-border, 1px solid #dedede);
}

.next-number-picker > .next-input:hover {
  border: var(--number-picker-normal-border-hover, 1px solid #737373);
}

.next-pagination.is-wind
  .next-btn:not(.next-pagination-jump-go):not(.next-current) {
  background: #fff;
}

.next-pagination.is-wind
  .next-btn.next-btn-normal:not([disabled]):not(.next-current):not(.next-pagination-jump-go):hover {
  color: var(--color-text1-5);
  border-color: var(--color-line1-4);
  z-index: 1;
}

.next-pagination.is-wind
  .next-btn.next-btn-normal:not([disabled]):not(.next-current):not(.next-pagination-jump-go):hover
  .next-icon {
  color: var(--color-text1-5);
}

.next-pagination.is-wind .next-btn.next-current {
  border-color: var(--color-brand1-6);
  z-index: 1;
}

.next-pagination.is-wind .next-btn.next-pagination-jump-go {
  margin-left: calc(var(--size-base) * 2);
}

.next-pagination.is-wind .next-btn.next-small {
  min-width: -webkit-calc(var(--size-base) * 6);
  min-width: -moz-calc(var(--size-base) * 6);
  min-width: calc(var(--size-base) * 6);
}

.next-pagination.is-wind .next-btn.next-medium {
  min-width: -webkit-calc(var(--size-base) * 8);
  min-width: -moz-calc(var(--size-base) * 8);
  min-width: calc(var(--size-base) * 8);
}

.next-pagination.is-wind .next-btn.next-large {
  min-width: -webkit-calc(var(--size-base) * 9);
  min-width: -moz-calc(var(--size-base) * 9);
  min-width: calc(var(--size-base) * 9);
}

.next-pagination.is-wind .next-btn.next-btn[disabled] {
  border: 1px solid #ebebeb;
  color: #c1c1c1;
  background: #fafafa;
}

.next-pagination.is-wind .next-btn.next-btn[disabled] .next-icon {
  color: #dedede;
}

.next-pagination.is-wind .next-pagination-item.next-next,
.next-pagination.is-wind .next-pagination-list,
.next-pagination.is-wind .next-pagination-list .next-btn {
  margin: 0 0 0 -1px !important;
}

.next-pagination.is-wind .next-pagination-jump-input.next-input {
  margin: 0 8px;
}

.next-pagination.is-wind .next-pagination-jump-input.next-input input {
  padding: 0 4px;
  text-align: center;
}

.next-pagination.is-wind .next-pagination-size-selector .next-select {
  margin: 0;
}

.next-step.is-wind .next-step-item-content {
  color: var(--color-text1-2) !important;
}

.next-step.is-wind.next-step-circle.next-enhanced-step-size-small.next-step-horizontal
  .next-step-item-node-placeholder,
.next-step.is-wind.next-step-circle.next-enhanced-step-size-small.next-step-vertical
  .next-step-item-node-placeholder {
  height: 24px;
  width: 24px;
}

.next-step.is-wind.next-step-circle.next-enhanced-step-size-small.next-step-horizontal
  .next-step-item-node-placeholder
  .next-step-item-node-circle,
.next-step.is-wind.next-step-circle.next-enhanced-step-size-small.next-step-vertical
  .next-step-item-node-placeholder
  .next-step-item-node-circle {
  height: 24px;
  width: 24px;
  line-height: 22px;
}

.next-step.is-wind.next-step-circle.next-enhanced-step-size-small.next-step-horizontal
  .next-step-item-node-placeholder
  .next-step-item-node-circle
  .next-icon:before,
.next-step.is-wind.next-step-circle.next-enhanced-step-size-small.next-step-vertical
  .next-step-item-node-placeholder
  .next-step-item-node-circle
  .next-icon:before {
  font-size: 12px;
}

.next-step.is-wind.next-step-circle.next-enhanced-step-size-small.next-step-vertical
  .next-step-item-body
  > .next-step-item-title {
  margin-top: 4px;
}

.next-step.is-wind.next-step-circle.next-step-dot-circle-hoz-placement.next-step-label-horizontal.next-enhanced-step-size-small
  > .next-step-item
  .next-step-item-body
  .next-step-item-title {
  margin-top: 3px;
}

.next-step.is-wind.next-step-vertical > .next-step-item {
  height: auto;
}

.next-step.is-wind.next-step-vertical > .next-step-item > .next-step-item-tail {
  margin: 8px auto;
}

.sc-bdfBQB {
  display: flex;
}

.next-menu-item{
  padding: 0 24px;
}

.fJrxuO:hover {
 box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.16);
}

.next-menu{
  border-right:1px solid #c0c6cc !important;
}

.next-nav.next-normal .next-menu-sub-menu .next-menu-item.next-selected {
  background-color: #f7f9fa;
  background-color: var(--nav-normal-sub-nav-selected-bg-color, #f7f9fa);
  color: #0064c8;
  color: var(--nav-normal-sub-nav-selected-text-color, #0064c8);
}
</style>
